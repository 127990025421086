import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, createTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PageWrapper } from "../../components/common";
import LoginPage from "./LoginPage";
import { theme as akordTheme } from "../../theme/mui";
import { useLoginContext } from "../../contexts/LoginContext";
import SnackBarConfirm from "../../components/common/SnackBar/SnackBarConfirm";
import { useOrgContext } from "../../contexts/OrgContextProvider";
import { SIGNUP_VERIFY_REDIRECT_URL } from "../../helpers/env";

type LoginPageContainerProps = {
  location: { state: { from: { search: string } } };
};

type LoginStylesProps = {
  isDesktop: boolean;
};

const useStyles = makeStyles<Theme, LoginStylesProps>({
  main: {
    justifyContent: ({ isDesktop }) => (isDesktop ? "center" : "inherit")
  },
  snackbar: {
    left: "16px",
    right: "16px",
    bottom: "16px",
    "@media (min-width: 700px)": {
      width: "400px",
      left: "50%"
    }
  }
});

const LoginPageContainer: React.FC<LoginPageContainerProps & RouteComponentProps> = ({ location }) => {
  const { error } = useLoginContext();
  const { org, loadingOrg } = useOrgContext();
  const theme = createTheme(akordTheme());
  const fromUrlParams = new URLSearchParams(location?.state?.from?.search);
  const isPasswordlessLogin = !!fromUrlParams.get("wallet");
  const urlParams = new URLSearchParams(location?.search);
  const encoded = urlParams.get("data");
  const code = urlParams.get("code");

  const loader = document.querySelector(".preloader");
  const main = document.querySelector("#main");

  const showLoader = React.useCallback(() => {
    loader?.classList.remove("loader-hide");
    main?.classList.remove("app-show");
  }, [loader, main]);

  const hideLoader = React.useCallback(() => {
    loader?.classList.add("loader-hide");
    main?.classList.add("app-show");
  }, [loader, main]);

  React.useEffect(() => {
    // Scenarios when loader is shown
    SIGNUP_VERIFY_REDIRECT_URL?.length > 1 && encoded && code && showLoader();
    isPasswordlessLogin && showLoader();
    loadingOrg && showLoader();
    return () => hideLoader();
  }, [encoded, code, isPasswordlessLogin, showLoader, hideLoader, loadingOrg]);

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const classes = useStyles({
    isDesktop: isDesktop
  });

  if (isPasswordlessLogin) return null;

  return (
    <PageWrapper error={error} darkMode={true} showBottomNav={false} mainClassName={classes.main} pageTitle="Login">
      <Box width="100%">
        <LoginPage />
      </Box>
      <SnackBarConfirm />
    </PageWrapper>
  );
};

export default withRouter(LoginPageContainer);
