import React, { useState, useEffect } from "react";
import { Auth } from "@akord/akord-js";
import { Steps } from "intro.js-react";
import "./introjs.css";
import { useLocation, matchPath } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { publicSteps, encryptedSteps, OnboardingStep, publicCloudSteps } from "./onboardingSteps";

const Onboarding = () => {
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const handleStepsEnabled = () => setStepsEnabled(!stepsEnabled);
  const [onbaordingSteps, setOnbaordingSteps] = useState<OnboardingStep[]>([]);

  const { userAttributes, onUserAttributes } = useGlobalContext();
  const { isVaultPublic, isCloudStorage } = useVaultContext();
  const { userOnboarding } = userAttributes;

  const location = useLocation();

  useEffect(() => {
    const matchProfile = matchPath(location.pathname, {
      path: `/vaults/:status/:dataRoomId/assets`,
      strict: true,
      exact: true
    });
    if (matchProfile && userOnboarding) handleStepsEnabled();
  }, [userAttributes, location, userOnboarding]);

  useEffect(() => {
    // if (isVaultPublic === undefined) return;
    const onboardSteps = isVaultPublic ? (isCloudStorage ? publicCloudSteps : publicSteps) : encryptedSteps;
    setOnbaordingSteps(onboardSteps);
  }, [isVaultPublic]);

  const handleExit = async () => {
    try {
      handleStepsEnabled();
      onUserAttributes({ userOnboarding: false });
      await Auth.updateUserAttribute("custom:onboarding", "false");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Steps
      enabled={stepsEnabled}
      steps={onbaordingSteps}
      initialStep={0}
      options={{
        showBullets: false,
        hidePrev: true,
        nextLabel: "Next &#8594",
        prevLabel: "&#8592 Previous",
        doneLabel: "",
        nextToDone: true,
        disableInteraction: true,
        scrollToElement: false,
        overlayOpacity: 0.5
      }}
      onExit={() => handleExit()}
    />
  );
};

export default Onboarding;
