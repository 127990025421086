import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import InviteToRoom from "../../pages/vault-options/InviteToRoom";
import ManageAccess from "../../pages/vault-options/manage-access/ManageAccess";
import RevokedFiles from "../../pages/vault-options/RevokedFiles";
import Timeline from "../../pages/timeline";
import Rooms from "../../pages/vault-list";
import Stacks from "../../pages/vault-pages/";
import FileViewerContextProvider from "../../contexts/FileViewerContextProvider";
import DialogNoteViewer from "../common/FileViewer/DialogNoteViewer";
import TimelineContextProvider from "../../contexts/TimelineContextProvider";
import RoutesMint from "./RoutesMint";
import NftContextProvider from "../../contexts/NftContextProvider";
import NFTItemDetails from "../../pages/nft/NFTItemDetails";
import NFTCollectionList from "../../pages/nft/NFTCollectionList";
import RoutesNftCollection from "./RoutesNftCollection";

function RoomDetailsRouter(props) {
  return (
    <NftContextProvider>
      <Switch>
        <Route exact path="/vaults/:dataRoomStatus" render={() => <Rooms />} />
        <Route
          path="/vaults/:dataRoomStatus/:dataRoomId/assets/create-note"
          exact
          render={() => (
            <FileViewerContextProvider>
              <DialogNoteViewer />
            </FileViewerContextProvider>
          )}
        />
        <Route path="/vaults/:dataRoomStatus/:dataRoomId/invite-to-vault" exact render={() => <InviteToRoom {...props} />} />
        <Route path="/vaults/:dataRoomStatus/:dataRoomId/manage-access" exact render={() => <ManageAccess {...props} />} />
        <Route path="/vaults/:dataRoomStatus/:dataRoomId/revoked-files" exact render={() => <RevokedFiles {...props} />} />
        <Route
          path="/vaults/:dataRoomStatus/:dataRoomId/timeline"
          exact
          render={() => (
            <TimelineContextProvider>
              <Timeline {...props} />
            </TimelineContextProvider>
          )}
        />
        <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/collection" render={() => <RoutesNftCollection />} />
        <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/:nftId" exact render={() => <NFTItemDetails />} />
        <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/mint" render={() => <RoutesMint />} />
        <Route path="/vaults/:dataRoomStatus/:dataRoomId" render={() => <Stacks {...props} />} />
      </Switch>
    </NftContextProvider>
  );
}

export default withRouter(RoomDetailsRouter);
