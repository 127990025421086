import React, { useEffect } from "react";
// import { EmptySpace } from "../../components/common";
import VaultListContainer from "./VaultListContainer";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { Redirect, StaticContext } from "react-router";

type LocationState = {
  from?: Location;
  changePassword?: boolean;
};

const Rooms: React.FC<RouteComponentProps<{}, StaticContext, LocationState>> = ({ location }) => {
  const { onSnackbarToShow } = useSnackbarContext();
  const { vaultsIsLoading, activeVaults, archivedVaults, pendingMemberships, processingVaults } = useGlobalContext();

  useEffect(() => {
    if (location.state?.changePassword) onSnackbarToShow("passwordChange");
  }, [location]);

  return vaultsIsLoading ||
    (activeVaults && activeVaults?.length > 0) ||
    (archivedVaults && archivedVaults?.length > 0) ||
    pendingMemberships?.length > 0 ||
    processingVaults?.length > 0 ? (
    <VaultListContainer />
  ) : (
    <Redirect to="/create-vault/setup" />
    // <EmptySpace dataText="newRoomOnBoard" />
  );
};

export default withRouter(Rooms);
