import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  FormControl,
  RadioGroup,
  Radio,
  Box,
  Typography,
  FormControlLabel,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  List,
  ListItem
} from "@mui/material";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { UDL_LICENSE_TX_ID } from "@akord/akord-js";
import { ChevronDownIcon, ChevronUpIcon, InfoIcon } from "@akord/addon-icons";
import { useNftContext } from "../../contexts/NftContextProvider";

import { getMintType, getVaultId } from "../../helpers/helpers";

const UDLForm: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { onUdl, udlChoice, onUDLChoice } = useNftContext();
  const [readMore, setReadMore] = useState(false);
  const [formData, setFormData] = useState({
    udlChoice: udlChoice
  });
  const { isMobile } = useGlobalContext();

  const isCollection = location.pathname.includes("/collection/");
  const vaultId = getVaultId(location.pathname);
  const mintType = getMintType(location.pathname);

  const handleFormChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setFormData({
      ...formData,
      [prop]: value
    });
  };

  const redirect = () => {
    if (formData.udlChoice === "none") {
      onUDLChoice("none");
      onUdl(undefined);
      history.push(`/vaults/active/${vaultId}/nfts/mint/${mintType}/ucm`);
    } else if (formData.udlChoice === "default") {
      onUDLChoice("default");
      onUdl({
        license: UDL_LICENSE_TX_ID
      });
      history.push(`/vaults/active/${vaultId}/nfts/mint/${mintType}/ucm`);
    } else {
      onUDLChoice("custom");
      history.push(`/vaults/active/${vaultId}/nfts/mint/${mintType}/udl-custom`);
    }
  };

  return (
    <SupportScreenWrapper title="Universal Data License" hideChevron fullHeight>
      <Box height="auto" display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"} mt={6}>
        <Box mb={7}>
          <Box mb={5}>
            <Accordion
              elevation={0}
              expanded={readMore}
              sx={{
                width: "100%",
                "& .Mui-expanded": {
                  minHeight: "48px!important",
                  margin: "0!important"
                }
              }}
            >
              <AccordionSummary
                sx={{
                  padding: 0,
                  "& .MuiAccordionSummary-root": {
                    cursor: "default"
                  },
                  "& .MuiAccordionSummary-content": {
                    margin: 0
                  }
                }}
              >
                <Box mb={3}>
                  <Typography variant="h3" color="text.primary">
                    Understanding UDL
                  </Typography>
                  <Typography variant="body2" className="small" sx={{ marginBottom: 3 }}>
                    The Universal Data License (UDL) empowers creators to define and enforce specific terms for the use, distribution, and
                    monetization of their content on the Arweave.
                  </Typography>
                  {!readMore && (
                    <Box mt={3}>
                      <IconButton onClick={() => setReadMore(true)}>
                        <Typography variant="body2" className="small">
                          Read more
                        </Typography>
                        <ChevronDownIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Box mb={4}>
                  <Typography variant="h3" color="text.primary">
                    Default UDL rights
                  </Typography>
                  <Typography variant="body2" className="small">
                    By default, this grants Licensee&apos;s (those consuming the content) the following rights to:
                  </Typography>
                  <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
                    <Typography variant="body2" className="small">
                      <ListItem sx={{ display: "list-item", p: 0, pl: 2 }}>copy</ListItem>
                      <ListItem sx={{ display: "list-item", p: 0, pl: 2 }}>display</ListItem>
                      <ListItem sx={{ display: "list-item", p: 0, pl: 2 }}>make public</ListItem>
                    </Typography>
                  </List>
                </Box>

                <Box mb={4}>
                  <Typography variant="h3" color="text.primary">
                    What&apos;s not included
                  </Typography>
                  <Typography variant="body2" className="small">
                    By default, UDL does not grant the rights to:
                  </Typography>
                  <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
                    <Typography variant="body2" className="small">
                      <ListItem sx={{ display: "list-item", p: 0, pl: 2 }}>distribute the content</ListItem>
                      <ListItem sx={{ display: "list-item", p: 0, pl: 2 }}>make commercial use of the content</ListItem>
                      <ListItem sx={{ display: "list-item", p: 0, pl: 2 }}>make derivative works of the content</ListItem>
                    </Typography>
                  </List>
                </Box>

                <Box mb={4}>
                  <Box display="flex" sx={{ marginBottom: 5 }}>
                    <InfoIcon color="disabled" sx={{ mr: 2 }} />
                    <Typography variant="body2" className={["strong", "small"].join(" ")} color="text.primary">
                      You cannot edit the terms of the UDL once the Atomic NFT is minted. If you decide to include the UDL, please consider
                      your choices carefully.
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton onClick={() => setReadMore(false)}>
                      <Typography variant="body2" className="small">
                        Close
                      </Typography>
                      <ChevronUpIcon />
                    </IconButton>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </Box>
          <Box>
            <Typography variant="h3" color="text.primary" sx={{ marginBottom: 3 }}>
              Choose UDL settings
            </Typography>
            {isCollection && (
              <Box mb={4}>
                <Typography variant="body2" className="small">
                  Settings will be applied to all Atomic NFTs in the collection.
                </Typography>
              </Box>
            )}
            <FormControl>
              <RadioGroup aria-labelledby="udl" name="udl" value={formData.udlChoice} onChange={handleFormChange("udlChoice")}>
                <FormControlLabel value="default" control={<Radio />} label="Default UDL settings" />
                <FormControlLabel value="none" control={<Radio />} label="Remove all UDL settings" />
                <FormControlLabel value="custom" control={<Radio />} label="Customize UDL settings" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <ButtonGroup nextDiasabled={false} handleEnd={() => redirect()} handleBack={() => history.goBack()} nextText="Next" />
      </Box>
    </SupportScreenWrapper>
  );
};

export default withRouter(UDLForm);
