import React from "react";
import {
  AssetDeleted,
  AssetMove,
  AssetRemoved,
  AssetRestore,
  AssetRevoked,
  ErrorOperation,
  FolderCreate,
  FolderDeleted,
  FolderMove,
  FolderRename,
  FolderRestore,
  FolderRevoked,
  MemberInvite,
  MembershipAccept,
  MembershipChangeAccess,
  MembershipConfirm,
  MembershipInviteResend,
  MembershipLeave,
  MembershipReject,
  MembershipRestoreAccess,
  MembershipRevoke,
  MemoWrite,
  NoteCreated,
  StackCreated,
  StackRename,
  StackRevision,
  UserVerified,
  VaultArchive,
  VaultCreate,
  VaultRename,
  VaultRestore
} from "./timeline-actions/";
import VaultUpdate from "./timeline-actions/VaultUpdate";

export const getTimelineAction = (operation, positionedBeforeDate, color, stacks) => {
  switch (operation.actionRef) {
    case "VAULT_CREATE":
      return <VaultCreate operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "VAULT_RENAME":
      return <VaultRename operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "VAULT_ARCHIVE":
      return <VaultArchive operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "VAULT_RESTORE":
      return <VaultRestore operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "VAULT_UPDATE_METADATA":
      return <VaultUpdate operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMO_CREATE":
      return (
        <MemoWrite operation={operation} positionedBeforeDate={positionedBeforeDate} color={color === "#A167FF" ? "#8e5ae4" : color} />
      );
    case "MEMBERSHIP_INVITE":
    case "MEMBERSHIP_INVITE_NEW_USER":
      return <MemberInvite operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMBERSHIP_AIRDROP":
    case "MEMBERSHIP_ACCEPT":
      return <MembershipAccept operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMBERSHIP_REJECT":
      return <MembershipReject operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMBERSHIP_REVOKE":
      return <MembershipRevoke operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMBERSHIP_LEAVE":
      return <MembershipLeave operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMBERSHIP_INVITE_RESEND":
    case "MEMBERSHIP_INVITE_NEW_USER_RESEND":
      return <MembershipInviteResend operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMBERSHIP_CHANGE_ACCESS":
      return <MembershipChangeAccess operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMBERSHIP_RESTORE_ACCESS":
      return <MembershipRestoreAccess operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "MEMBERSHIP_CONFIRM":
      return <MembershipConfirm operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "STACK_CREATE":
      return <StackCreated operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} stacks={stacks} />;
    case "STACK_UPLOAD_REVISION":
      return <StackRevision operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} stacks={stacks} />;
    case "STACK_RENAME":
    case "STACK_UPDATE":
      return <StackRename operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "STACK_REVOKE":
    case "NOTE_REVOKE":
      return <AssetRevoked operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "STACK_RESTORE":
    case "NOTE_RESTORE":
      return <AssetRestore operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "STACK_DELETE":
    case "NOTE_DELETE":
      return <AssetDeleted operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "STACK_MOVE":
    case "NOTE_MOVE":
      return <AssetMove operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "STACK_REMOVE":
    case "NOTE_REMOVE":
      return <AssetRemoved operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "FOLDER_CREATE":
      return <FolderCreate operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "FOLDER_RENAME":
    case "FOLDER_UPDATE":
      return <FolderRename operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "FOLDER_RESTORE":
      return <FolderRestore operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "FOLDER_REVOKE":
      return <FolderRevoked operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "FOLDER_DELETE":
    case "FOLDER_REMOVE":
      return <FolderDeleted operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "FOLDER_MOVE":
      return <FolderMove operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "NOTE_CREATE":
    case "NOTE_UPLOAD_REVISION":
      return <NoteCreated operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    case "USER_VERIFIED":
      return <UserVerified operation={operation} positionedBeforeDate={positionedBeforeDate} color={color} />;
    default:
      return <ErrorOperation operation={operation} positionedBeforeDate={positionedBeforeDate} />;
  }
};
