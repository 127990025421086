import React from "react";
import { Typography, Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { grey, orange } from "@akord/ui";

type LabelStyleProps = {
  smallLabel: boolean;
  noMargins: boolean;
};

const useStyles = makeStyles<Theme, LabelStyleProps>({
  labelBox: {
    height: ({ smallLabel }) => (smallLabel ? "20px" : "24px"),
    border: "1px solid",
    borderRadius: "100px",
    marginLeft: 12,
    marginBottom: ({ smallLabel, noMargins }) => (smallLabel && !noMargins ? "6px" : 0),
    padding: "0 8px"
  }
});

type LabelProps = {
  text: string;
  smallLabel?: boolean;
  secondary?: boolean;
  highlight?: boolean;
  noMargins?: boolean;
};

const Label: React.FC<LabelProps> = ({ text, smallLabel = false, secondary = false, highlight = false, noMargins = false }) => {
  const classes = useStyles({ smallLabel, noMargins });

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.labelBox}
      style={{
        borderColor: highlight ? orange[400] : secondary ? grey[500] : "initial"
      }}
    >
      <Typography
        variant={smallLabel ? "body2" : "caption"}
        className="xs"
        color={highlight ? "primary.light" : secondary ? "text.secondary" : "text.primary"}
        noWrap
      >
        {smallLabel ? text : text.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default Label;
