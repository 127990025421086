import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import NftCollectionContextProvider from "../../contexts/NftCollectionContextProvider";
import NFTCollectionList from "../../pages/nft/NFTCollectionList";
import NFTItemDetails from "../../pages/nft/NFTItemDetails";

function RoutesNftCollection() {
  return (
    <NftCollectionContextProvider>
      <Switch>
        <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/collection/:collectionId/:id" exact render={() => <NFTItemDetails />} />
        <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/collection/:collectionId" exact render={() => <NFTCollectionList />} />
      </Switch>
    </NftCollectionContextProvider>
  );
}

export default withRouter(RoutesNftCollection);
