import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, FormControl, OutlinedInput, InputLabel, Slider } from "@mui/material";
import { PenIcon, AvatarIcon, GlobeIcon, PadlockIcon } from "@akord/addon-icons";
import { dataUrlToArray } from "@akord/crypto";
import { StandAloneScreenWrapper } from "../../components/common";
import { ButtonGroup } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import CopyBox from "../../components/common/InfoDrawer/CopyBox";
import { getContentTypeFromUrl } from "../../helpers/helpers";
import { AVATAR_URL } from "../../helpers/env";
import InfoWarningBox from "../../components/common/InfoWarningBox/InfoWarningBox";
import { isUrlCorrect, uploadAvatar } from "../../helpers/api-helpers";

type AvatarProps = {
  photoFile: File | null;
  photoUrl: string | ArrayBuffer | null;
  croppedFile: string | null;
  originalFile: string | null;
};

const useStyles = makeStyles({
  icon: {
    cursor: "pointer",
    fontSize: "64px",
    fill: "#3592FA"
  },
  avatar: {
    cursor: "pointer",
    width: "64px",
    height: "64px",
    borderRadius: "50%"
  },
  inputDisabled: {
    background: "none!important"
  }
});

const Profile: React.FC<RouteComponentProps> = ({ history }) => {
  const [profileForm, setProfileForm] = useState({
    name: ""
  });
  const [avatar, setAvatar] = React.useState<AvatarProps>({
    photoFile: null,
    photoUrl: null,
    croppedFile: null,
    originalFile: null
  });
  const [walletKeys, setWalletKeys] = useState<{ publicKey?: string; privateKey?: string }>({});
  const [cropperOpen, setCropperOpen] = React.useState(false);
  const [zoom, setZoom] = React.useState(1);

  // button loading state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { akord, darkMode, onTxSpinner, profileDetails, isMobile, wallet, userAttributes } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const classes = useStyles();

  React.useEffect(() => {
    const getAvatarFile = async () => {
      const avatarUrl = `${AVATAR_URL}/${profileDetails?.address}`;
      const isAvatar = await isUrlCorrect(avatarUrl);
      if (isAvatar)
        setAvatar(prevState => ({
          ...prevState,
          croppedFile: avatarUrl,
          originalFile: avatarUrl
        }));
    };
    setProfileForm({
      name: (profileDetails && profileDetails.profileName) || ""
    });
    if (profileDetails && profileDetails.address) {
      getAvatarFile();
    }
  }, [profileDetails]);

  React.useEffect(() => {
    const getWalletAddress = async () => {
      const signingPublicKey = wallet?.signingPublicKey();
      const signingPrivateKey = wallet?.signingPrivateKey();
      setWalletKeys({ publicKey: signingPublicKey, privateKey: signingPrivateKey });
    };
    if (wallet) getWalletAddress();
  }, [wallet]);

  const handleFormChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setProfileForm({ ...profileForm, [e.target.name]: e.target.value });
  };

  const isButtonDisabled = () => {
    if (
      ((profileForm.name !== "" && profileForm.name !== profileDetails?.profileName) || avatar.originalFile !== avatar.croppedFile) &&
      !loading
    )
      return false;
    else return true;
  };

  const handleAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setAvatar({ ...avatar, photoFile: file, photoUrl: fileReader.result });
      setCropperOpen(true);
    };
    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  const editor = React.useRef<AvatarEditor>(null);

  const handleZoomSlider = (event: Event, value: number | number[]) => {
    setZoom(value as number);
  };
  const handleCancelCrop = () => {
    setCropperOpen(false);
  };

  const handleSaveCrop = async () => {
    if (editor.current) {
      const canvasScaled = editor.current.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      setAvatar({ ...avatar, croppedFile: croppedImg });
      setCropperOpen(false);
    }
  };

  const saveProfile = async () => {
    try {
      setLoading(true);
      onTxSpinner(true);
      if (avatar.croppedFile && profileDetails?.address) {
        const avatarCropped = await dataUrlToArray(avatar.croppedFile);
        const contentType = getContentTypeFromUrl(avatar.croppedFile);
        await uploadAvatar(profileDetails?.address, avatarCropped, contentType);
      }
      await akord?.profile.update(profileForm.name);
      setLoading(false);
      history.goBack();
      onSnackbarToShow("profileUpdate");
    } catch (err: any) {
      setLoading(false);
      setError(err.message);
      console.log("Error saving profile: ", err);
    }
  };

  return (
    <StandAloneScreenWrapper title="Profile" subtitle={userAttributes.passwordless ? undefined : profileDetails?.email || undefined}>
      <InfoWarningBox text="Your photo and profile name are stored unencrypted. Please only add data you already use publicly." />

      <Box display="flex" flexDirection="column" width="100%">
        <Box mb={7} display="flex" alignItems="baseline">
          <label htmlFor="profile-avatar" style={{ display: "contents" }}>
            {avatar.croppedFile ? (
              <img alt="preview" src={avatar.croppedFile} className={classes.avatar} />
            ) : (
              <AvatarIcon classes={{ root: classes.icon }} />
            )}
            <Box alignSelf="flex-end">
              <Typography
                variant="body2"
                className="small"
                color="text.secondary"
                style={{
                  display: "flex",
                  cursor: "pointer"
                }}
              >
                <PenIcon color="disabled" fontSize="small" sx={{ marginRight: "4px" }} />
                Edit
              </Typography>
            </Box>
            <input
              name="profile-avatar"
              id="profile-avatar"
              accept="image/*"
              type="file"
              onChange={event => {
                handleAvatar(event);
              }}
              style={{ display: "none" }}
            />
          </label>
        </Box>

        <Box mb={7}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="input-first-name">Name</InputLabel>
            <OutlinedInput
              fullWidth
              id={`enter-name`}
              name="name"
              type="text"
              label="Name"
              value={profileForm.name}
              onChange={handleFormChange}
            />
          </FormControl>
        </Box>

        <FormControl variant="outlined">
          <InputLabel htmlFor="input-first-name">Email address</InputLabel>
          <OutlinedInput
            disabled
            fullWidth
            id={`enter-email`}
            name="email"
            type="email"
            label="Email address"
            value={profileDetails ? profileDetails.email : ""}
            classes={{
              disabled: classes.inputDisabled
            }}
          />
        </FormControl>
        {!userAttributes.passwordless && (
          <Box mt={5}>
            <CopyBox dataKey="Public key" dataValue={walletKeys.publicKey} Icon={GlobeIcon} isMobile={isMobile} />
            <CopyBox dataKey="Private key" dataValue={walletKeys.privateKey} Icon={PadlockIcon} hideValue isMobile={isMobile} />
          </Box>
        )}
      </Box>
      <ButtonGroup
        loading={loading}
        type="submit"
        noMinWidth
        nextText="Save changes"
        nextDiasabled={isButtonDisabled()}
        hideBackButton={true}
        fullWidthNextButton={isMobile ? true : false}
        hideIcon
        errorText={error}
        handleEnd={() => saveProfile()}
        disabledRed={false}
      />
      {cropperOpen && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          width="100%"
          height="100%"
          zIndex="2"
          style={{
            top: 0,
            left: 0,
            background: darkMode ? "rgba(49, 47,55, 0.8)" : "rgba(34, 33, 38, 0.2)"
          }}
        >
          <AvatarEditor
            style={isMobile ? { width: "100%", height: "auto" } : undefined}
            ref={editor}
            image={avatar.photoUrl as string}
            width={300}
            height={300}
            border={50}
            borderRadius={200}
            color={[29, 29, 29, 0.7]}
            scale={zoom}
            rotate={0}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              margin: "10px 0"
            }}
          >
            <Typography variant="caption" style={{ margin: "0 20px 0 0" }}>
              Zoom
            </Typography>
            <Slider min={1} max={10} step={0.1} value={zoom} onChange={handleZoomSlider} style={{ width: 200 }} />
          </Box>
          <Box maxWidth="400px" width={"calc(100% - 32px)"}>
            <ButtonGroup
              type="submit"
              noMinWidth
              hideIcon
              nextText="Save profile picture"
              backText="Cancel"
              handleEnd={() => handleSaveCrop()}
              handleBack={() => handleCancelCrop()}
            />
          </Box>
        </Box>
      )}
    </StandAloneScreenWrapper>
  );
};

export default withRouter(Profile);
