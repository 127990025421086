import React from "react";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { snackbarOptions } from "./snackbar-options";
import { SnackbarNotification } from "@akord/ui";

const SnackBarConfirm: React.FC = () => {
  const { snackbarToShow, onSnackbarClose } = useSnackbarContext();

  return (
    <SnackbarNotification
      snackbarViewProps={snackbarToShow?.type ? snackbarOptions[snackbarToShow.type] : undefined}
      onSnackbarClose={onSnackbarClose}
      snackbarData={snackbarToShow}
      snackbarShow={snackbarToShow.open}
    />
  );
};

export default SnackBarConfirm;
