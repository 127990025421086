import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { confirmationErrorTexts, ConfirmationErrorTypes } from "./Modals/confirmation-errors-texts";
import { sendErrorToSupport } from "../../helpers/api-helpers";
import { NotificationData } from "../../contexts/NotificationsContextProvider";

const dontSendErrorTypes = ["orgInvite"];

interface ConfirmationErrorProps {
  error: any;
  data?: ConfirmationErrorData;
  type: ConfirmationErrorTypes;
}

export interface ConfirmationErrorData extends NotificationData {
  vaultId?: string;
}

const ConfirmationError: React.FC<ConfirmationErrorProps> = ({ error, data, type }) => {
  const [errorSent, setErrorSent] = useState(false);

  const sendErrorReport = async () => {
    setErrorSent(true);
    setTimeout(() => {
      setErrorSent(false);
    }, 1000);
    await sendErrorToSupport(error, data, type);
  };

  return (
    <Box mt={4} display="flex" flexDirection="column">
      <Typography variant="caption" color="error.main">
        {confirmationErrorTexts[type] ? confirmationErrorTexts[type] : confirmationErrorTexts.default}{" "}
        {!dontSendErrorTypes.includes(type) && (
          <Link variant="caption" onClick={() => sendErrorReport()} color="error.main" sx={{ width: "max-content" }}>
            {errorSent ? "Sent!" : "Send an error report."}
          </Link>
        )}
      </Typography>
    </Box>
  );
};

export default ConfirmationError;
