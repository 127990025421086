import React, { useState } from "react";
import { Box, Typography, IconButton, Link } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import { styled, Theme } from "@mui/material/styles";
import { DoubleFileIcon, DoubleTickIcon, InfoIcon, TickAnimatedIcon } from "@akord/addon-icons";
import { Types } from "@akord/gql";
import { copyToClipboard } from "../../../helpers/helpers";
import { getArweaveStatusIcon } from "../../../pages/storage/storage-helper";

type CopyBoxTypes = {
  dataKey?: string;
  dataValue?: string;
  tooltipText?: string;
  lastItem?: boolean;
  isMobile?: boolean;
  Icon?: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
  hideValue?: boolean;
  extraActionText?: string;
  extraActionData?: string;
  marginbottom?: number;
  arweaveStatus?: Types.StorageTransactionStatus;
  showStatus?: boolean;
  infoText?: string[];
};

const useStyles = makeStyles<Theme>(theme => ({
  copyBox: {
    height: "40px",
    background: theme.palette.background.card,
    borderRadius: "4px",
    border: "1px solid",
    borderColor: theme.palette.background.tertiary,
    padding: "8px"
  },
  iconBox: {
    borderLeft: "1px solid",
    borderColor: theme.palette.background.tertiary,
    paddingLeft: "8px",
    height: "40px"
  },
  popper: {
    maxWidth: "700px"
  }
}));

const NoMaxWidthTooltip = styled(Tooltip)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none"
  }
});

const InfoText = ({ textArray }: { textArray: string[] }) => {
  return (
    <Box m={2}>
      {textArray.map((text, index) => {
        return (
          <Typography key={index} variant="body2" className="xs" style={{ marginBottom: 8 }} color="text.primary">
            {text}
          </Typography>
        );
      })}
    </Box>
  );
};

const CopyBox: React.FC<CopyBoxTypes> = ({
  dataKey,
  dataValue,
  tooltipText,
  lastItem,
  isMobile,
  Icon,
  hideValue = false,
  extraActionText,
  extraActionData,
  marginbottom,
  showStatus,
  arweaveStatus,
  infoText
}) => {
  const [copyClick, setCopyClick] = useState(false);
  const handleCopyClick = (value: boolean) => setCopyClick(value);

  const [copyClickText, setCopyClickText] = useState(false);
  const handleCopyClickText = (value: boolean) => setCopyClickText(value);

  // const [isUrlStatusOk, setIsUrlStatusOk] = useState<boolean>(true);

  const classes = useStyles();

  const valueLength = dataValue?.length || 0;
  const truncatedDataValue = dataValue?.substring(0, valueLength / 4) + "..." + dataValue?.substring(dataValue.length - valueLength / 4);

  const hiddenValue = (value: string) => (hideValue ? new Array(isMobile ? 30 : 60).join("*") : value);

  if (!dataValue) return null;

  return (
    <Box mb={lastItem ? 7 : 0}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="end">
          <Typography variant="body2" sx={{ marginRight: 2 }}>
            {dataKey}
          </Typography>
          {showStatus ? (
            arweaveStatus ? (
              getArweaveStatusIcon(arweaveStatus)
            ) : (
              // Show success by default instead of HEAD call to a source, TODO: add to a transaction similar to arweave status
              <DoubleTickIcon fontSize="small" sx={{ color: "success.main" }} />
              // <TickerWithExternalCall statusUrl={dataValue} onIsUrlStatusOk={setIsUrlStatusOk} isUrlStatusOk={isUrlStatusOk} />
            )
          ) : null}
          {infoText?.length && (
            <Tooltip placement="right-start" title={<InfoText textArray={infoText} />}>
              <span>
                <InfoIcon fontSize="small" color="secondary" sx={{ marginLeft: 1 }} />
              </span>
            </Tooltip>
          )}
          {Icon && <Icon fontSize="small" color="secondary" />}
        </Box>
        {extraActionText && (
          <Typography variant="body2" className="small">
            <Link onClick={() => copyToClipboard(extraActionData, handleCopyClickText)} underline="always" color="text.secondary">
              {copyClickText ? "Copied!" : extraActionText}
            </Link>
          </Typography>
        )}
      </Box>
      <NoMaxWidthTooltip
        // For boxes which could have a different tooltip texts based on the item status
        title={
          showStatus
            ? // ? arweaveStatus === ArweaveTransactionStatus.COMMITTED || isUrlStatusOk
              arweaveStatus === Types.StorageTransactionStatus.COMMITTED
              ? tooltipText
              : "Your file is not yet available on the gateway."
            : tooltipText
        }
        arrow
      >
        <Box
          mt={1}
          mb={marginbottom ? marginbottom : 5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.copyBox}
        >
          <Box minWidth="220px">
            <Typography variant="body2" color="text.secondary" className="small" noWrap>
              {isMobile ? hiddenValue(truncatedDataValue) : hiddenValue(dataValue)}
            </Typography>
          </Box>
          <Box className={classes.iconBox}>
            <IconButton sx={{ marginTop: "8px" }} onClick={() => copyToClipboard(dataValue, handleCopyClick)}>
              {copyClick ? <TickAnimatedIcon color="primary" /> : <DoubleFileIcon />}
            </IconButton>
          </Box>
        </Box>
      </NoMaxWidthTooltip>
    </Box>
  );
};

export default CopyBox;
