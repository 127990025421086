import { Auth } from "@akord/akord-js";
import { AkordWallet } from "@akord/crypto";
import { HandleNextProps } from ".";
import { RecoverAccountFormData } from "../../contexts/RecoverAccountContext";
import { RecoverStepsOptions } from "../../types/globalTypes";
import { ZXCVBNResultExtended } from "../../types/signUpTypes";

const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

type StepsDataProps = {
  [K in RecoverStepsOptions]: RecoverStepsData;
};

interface RecoverStepsData {
  isButtonDisabled: (
    formData: RecoverAccountFormData,
    checkBackupPhrase: () => boolean,
    loading: boolean,
    passEvaluation: ZXCVBNResultExtended,
    error: any
  ) => boolean;
  handleNext: (props: HandleNextProps) => void;
  nextText: string;
  hideBackButton: boolean;
  fullWidthNextButton: boolean;
  hideIcon: boolean;
  showBottomNav: boolean;
  darkMode: boolean;
  spaceBetween: boolean;
  disabledRed: boolean;
}

export const stepsData: StepsDataProps = {
  recover: {
    isButtonDisabled: (formData, _, loading) => {
      return !(formData.userEmail.match(emailRegex) !== null) || loading;
    },
    handleNext: async ({ onSnackbarToShow, formData, handleError, setLoading }) => {
      try {
        setLoading(true);
        const { host, protocol } = window.location;
        const verifyUrl = `${protocol}//${host}/recover/backup-phrase`;
        const userEmail = formData.userEmail.toLowerCase().trim();
        await Auth.forgotPassword(userEmail, verifyUrl);
        setLoading(false);
        onSnackbarToShow("linkToVerify");
      } catch (err) {
        console.log(err);
        handleError(err);
      }
    },
    showBottomNav: true,
    spaceBetween: false,
    darkMode: true,
    nextText: "Send verification",
    hideBackButton: false,
    fullWidthNextButton: false,
    hideIcon: true,
    disabledRed: false
  },
  "backup-phrase": {
    isButtonDisabled: (_, checkBackupPhrase, __, ___, error) => {
      return !checkBackupPhrase() || !!error;
    },
    handleNext: async ({ steps, currentStep, history, path, formData, backupPhrase, handleError }) => {
      try {
        // Check if the backup phrase actually matches the account which is being reset
        const phrase = Object.values(backupPhrase).join(" ").toLowerCase();
        const recoveredWallet = await AkordWallet.importFromBackupPhrase(phrase);
        const addressFromPhrase = await recoveredWallet.getAddress();
        if (addressFromPhrase !== formData.address) {
          handleError({ message: "Backup phrase doesn't match the account you are trying to reset." });
          console.error("Backup phrase mismatch");
        } else {
          const nextStepIndex = steps.indexOf(currentStep) + 1;
          const routeName = steps[nextStepIndex];
          history.push(`${path}/${routeName}`);
        }
      } catch (err) {
        handleError(err);
        console.error("Backup phrase error: ", err);
      }
    },
    showBottomNav: true,
    spaceBetween: false,
    darkMode: true,
    nextText: "Next",
    hideBackButton: true,
    fullWidthNextButton: true,
    hideIcon: true,
    disabledRed: false
  },
  "reset-password": {
    isButtonDisabled: (formData, _, loading, passEvaluation, error) => {
      return !(formData.userPassword.length > 8 && passEvaluation.score >= 4) || loading || !!error;
    },
    handleNext: async ({ formData, backupPhrase, history, setLoading, handleError }) => {
      try {
        setLoading(true);
        const phrase = Object.values(backupPhrase).join(" ").toLowerCase();
        await Auth.forgotPasswordSubmit(formData.userName.toLowerCase().trim(), formData.code, phrase, formData.userPassword);
        setLoading(false);
        history.push({
          pathname: "/vaults/active",
          state: { changePassword: true }
        });
      } catch (err) {
        console.log(err);
        setLoading(false);
        handleError({ message: "Invalid recovery phrase." });
      }
    },
    showBottomNav: true,
    spaceBetween: false,
    darkMode: true,
    nextText: "Reset Password",
    hideBackButton: true,
    fullWidthNextButton: true,
    hideIcon: true,
    disabledRed: false
  }
};
