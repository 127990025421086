import React from "react";
interface LogoProps {
  className: string;
  width: string;
  height?: string;
  onClick?: () => void;
}
const LogoBase: React.FC<LogoProps> = props => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M12.2,1.8H8l3.7,11.7H8.3V17h4.5l1.7,5.3h4.2L12.2,1.8z" fill="white" />
      <path d="M6.8,0H5.4V24h1.4V0z" fill="#F26D43" />
    </svg>
  );
};

export default LogoBase;
