import React, { useState, useContext, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getUTokenBalance } from "../helpers/api-helpers";

type RewardsProps = { uBalance: number | undefined; loadTokenBalance: () => void; loadingBalance: boolean };

const RewardsContext = React.createContext<RewardsProps>({} as RewardsProps);

const RewardsContextProvider: React.FC<React.ReactNode & RouteComponentProps> = ({ children }) => {
  const [uBalance, setUBalance] = useState<number>();
  const [loadingBalance, setLoadingBalance] = useState(false);

  const fetchBalance = async () => {
    setLoadingBalance(true);
    const uTokenResponse = await getUTokenBalance();
    setUBalance(uTokenResponse?.balance || undefined);
    setLoadingBalance(false);
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  return (
    <RewardsContext.Provider value={{ uBalance: uBalance, loadTokenBalance: fetchBalance, loadingBalance: loadingBalance }}>
      {children}
    </RewardsContext.Provider>
  );
};
export default withRouter(RewardsContextProvider);

export const useRewardsContext = () => useContext(RewardsContext);
