import React, { useState, createContext, useContext, useCallback } from "react";
import { AssetType, Collection, CollectionMetadata, NFT, NFTMetadata, UDL } from "@akord/akord-js";
import { useGlobalContext } from "./GlobalDataProvider";
import { getComparator, getVaultId, stableSort } from "../helpers/helpers";

type LocalAssetsType = {
  thumbnail?: File;
  banner?: File;
};

type NftContextTypes = {
  nfts: (Collection | NFT)[];
  nftCollections: Collection[];
  nftSingles: NFT[];
  isNftLoading: boolean;
  refreshNfts: (abortController: AbortController) => Promise<void>;
  nftMetadata: NFTMetadata & LocalAssetsType & CollectionMetadata;
  onNFTMetadata: (value: NFTMetadata & LocalAssetsType & CollectionMetadata) => void;
  udl: UDL | undefined;
  onUdl: (value: UDL | undefined) => void;
  udlChoice: UdlOptions;
  onUDLChoice: (value: UdlOptions) => void;
  ucm: UcmOptions;
  onUCM: (value: UcmOptions) => void;
  onFilesCollection: (files: File[]) => void;
  filesCollection?: File[];
  onMintCollectionCancelHook: (controller: AbortController | null) => void;
  mintCollectionCancelHook: AbortController | null;
};

type UcmOptions = "yes" | "no";
type UdlOptions = "none" | "default" | "custom";

const Context = createContext({} as NftContextTypes);

export const initialNftMetadata = {
  name: "",
  topics: [],
  owner: "",
  creator: "",
  types: ["other"] as AssetType[],
  description: "An Atomic NFT secured on Arweave with Akord."
};

export const udlInitialState: UDL = {
  licenseFee: undefined,
  derivations: undefined,
  unknownUsageRights: undefined,
  commercialUses: undefined,
  dataModelTrainings: undefined,
  currency: undefined,
  expires: undefined,
  paymentAddress: undefined
};

const NftContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [nftCollections, setNftCollections] = useState<Collection[]>([]);
  const handleNftCollections = (value: Collection[]) => setNftCollections(value);

  const [nftSingles, setNftSingles] = useState<NFT[]>([]);
  const handleNftSingles = (value: NFT[]) => setNftSingles(value);

  const [isNftLoading, setIsNftLoading] = useState(false);
  const handleIsNftLoading = (value: boolean) => setIsNftLoading(value);

  const [nftMetadata, setNFTMetadata] = useState<NFTMetadata & LocalAssetsType & CollectionMetadata>(initialNftMetadata);
  const handleNFTMetadata = (value: NFTMetadata & LocalAssetsType & CollectionMetadata) => setNFTMetadata(value);

  const [udl, setUdl] = useState<UDL | undefined>(udlInitialState);
  const handleUdl = (value: UDL | undefined) => setUdl(value);

  const [udlChoice, setUDLChoice] = useState<UdlOptions>("default");
  const handleUDLChoice = (value: UdlOptions) => setUDLChoice(value);

  const [ucm, setUCM] = useState<UcmOptions>("yes");
  const handleUCM = (value: UcmOptions) => setUCM(value);

  const [filesCollection, setFilesCollection] = useState<File[]>();

  const [mintCollectionCancelHook, setMintCollectionCancelHook] = useState<AbortController | null>(null);
  const handleMintCollectionCancelHook = (controller: AbortController | null) => setMintCollectionCancelHook(controller);

  const nftsRef = React.useRef<Map<string, NFT>>(new Map());
  const collectionsRef = React.useRef<Map<string, Collection>>(new Map());

  const isNftTab = location.pathname.includes("/nfts");
  const vaultId = getVaultId(location.pathname);

  const { akord, withTxSpinner } = useGlobalContext();

  const loadCollections = React.useCallback(
    async (token: string = "", abortController: AbortController) => {
      const listOptions = {
        parentId: "null",
        shouldDecrypt: false,
        nextToken: token
      };

      const collections = await akord!.collection.list(vaultId!, listOptions);
      if (abortController.signal.aborted) {
        return { items: [], nextToken: "" };
      }
      const collectionItems = collections.items.map((collection) => {
        collectionsRef.current.set(collection.id, collection);
        return collection;
      });
      return { items: collectionItems, nextToken: collections.nextToken };
    },
    [akord, vaultId]
  );

  const loadNfts = React.useCallback(
    async (token: string = "", abortController: AbortController) => {
      const listOptions = {
        parentId: "null",
        shouldDecrypt: false,
        nextToken: token
      };

      const nfts = await akord!.nft.list(vaultId!, listOptions);
      if (abortController.signal.aborted) {
        return { items: [], nextToken: "" };
      }
      const nftItems = nfts.items.map((nft) => {
        nftsRef.current.set(nft.id, nft);
        return nft;
      });
      return { items: nftItems, nextToken: nfts.nextToken };
    },
    [akord, vaultId]
  );

  const loadAllNfts = useCallback(
    async (token: string, abortController: AbortController) => {
      do {
        const { items, nextToken } = await loadNfts(token, abortController);
        if (!items.length) {
          handleIsNftLoading(false);
          break;
        }
        const nftsChunk = Array.from(nftsRef.current.values());
        const filteredNftsChunk = stableSort(nftsChunk, getComparator("asc", "name")) as NFT[];
        handleNftSingles(filteredNftsChunk);
        handleIsNftLoading(false); // do not repeat if already set
        token = nextToken;
      } while (token);
    },
    [loadNfts]
  );

  const loadAllCollections = useCallback(
    async (token: string, abortController: AbortController) => {
      handleIsNftLoading(true);
      do {
        const { items, nextToken } = await loadCollections(token, abortController);
        if (!items.length) {
          handleIsNftLoading(false);
          break;
        }
        const collectionsChunk = Array.from(collectionsRef.current.values());
        const filteredCollectionsChunk = stableSort(collectionsChunk, getComparator("asc", "name")) as Collection[];
        handleNftCollections(filteredCollectionsChunk);
        handleIsNftLoading(false); // do not repeat if already set
        token = nextToken;
      } while (token);
    },
    [loadCollections]
  );

  const fetchNfts = useCallback(
    async (abortController: AbortController) => {
      let token: string = "";

      await Promise.all([loadAllCollections(token, abortController), loadAllNfts(token, abortController)]);
    },
    [loadAllCollections, loadAllNfts]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    if (vaultId && akord && isNftTab) withTxSpinner(() => fetchNfts(abortController));
    return () => {
      abortController.abort();
      collectionsRef.current = new Map();
      nftsRef.current = new Map();
      handleNftCollections([]);
      handleNftSingles([]);
      handleNFTMetadata(initialNftMetadata);
    };
  }, [vaultId, withTxSpinner, akord, isNftTab, fetchNfts]);

  const mintedAssets = React.useMemo(
    () => [...nftCollections, ...nftSingles.sort((a, b) => a.name.localeCompare(b.name))],
    [nftCollections, nftSingles]
  );

  return (
    <Context.Provider
      value={{
        nfts: mintedAssets,
        nftCollections: nftCollections,
        nftSingles: nftSingles,
        isNftLoading: isNftLoading,
        refreshNfts: fetchNfts,
        nftMetadata: nftMetadata,
        onNFTMetadata: handleNFTMetadata,
        udl: udl,
        onUdl: handleUdl,
        udlChoice: udlChoice,
        onUDLChoice: handleUDLChoice,
        ucm: ucm,
        onUCM: handleUCM,
        onFilesCollection: setFilesCollection,
        filesCollection: filesCollection,
        onMintCollectionCancelHook: handleMintCollectionCancelHook,
        mintCollectionCancelHook: mintCollectionCancelHook
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default NftContextProvider;
export const useNftContext = () => useContext(Context);
