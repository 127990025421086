import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, List, ListItem, ListItemIcon, ListItemText, Drawer, Box, Divider, Theme } from "@mui/material";
import { VaultIcon } from "@akord/addon-icons";
import { Props } from "@akord/addon-icons/lib/components/Icon";

type MoreDrawerRootProps = {
  header: string;
  openDrawer: boolean;
  handleMenuClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  isRoomArchived?: boolean;
  isStack?: boolean;
  isFolder?: boolean;
  icon?: (props: Props) => React.ReactElement<any, any>;
  isVaultPublic?: boolean;
};
type MoreDrawerRootStyleProps = {
  isRoomArchived: boolean;
  isVaultPublic: boolean;
};

const useStyles = makeStyles<Theme, MoreDrawerRootStyleProps>(theme => ({
  iconBackGround: {
    background: ({ isRoomArchived, isVaultPublic }) =>
      isRoomArchived ? theme.palette.background.archived : isVaultPublic ? theme.palette.background.stack : theme.palette.background.active,
    boxSizing: "content-box",
    padding: "5px",
    color: ({ isRoomArchived, isVaultPublic }) =>
      isRoomArchived ? theme.palette.text.disabled : isVaultPublic ? theme.palette.info.main : theme.palette.primary.main
  },
  iconFolder: {
    boxSizing: "content-box",
    color: "#8B7872"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const MoreDrawerRoot: React.FC<MoreDrawerRootProps> = ({
  children,
  header,
  openDrawer,
  handleMenuClose,
  isRoomArchived = false,
  isStack,
  isFolder,
  icon: Icon = VaultIcon,
  isVaultPublic = false
}) => {
  const classes = useStyles({
    isVaultPublic: isVaultPublic,
    isRoomArchived: isRoomArchived
  });

  return (
    <Drawer elevation={16} anchor="bottom" open={openDrawer} onClose={handleMenuClose} PaperProps={{ sx: { maxHeight: "80vh" } }}>
      <Box mx={5}>
        <List component="nav" aria-label="active files" style={{ listStyleType: "none" }}>
          <Box my={3}>
            <ListItem disableGutters className={classes.header}>
              <ListItemIcon>
                <Icon fontSize="small" classes={{ root: isFolder ? classes.iconFolder : classes.iconBackGround }} />
              </ListItemIcon>
              <ListItemText>
                <Typography noWrap variant="body2" color="text.primary">
                  {header}
                </Typography>
              </ListItemText>
            </ListItem>
          </Box>
          <Divider />
          {children}
        </List>
      </Box>
    </Drawer>
  );
};

export default MoreDrawerRoot;
