import { Box, Slider, Typography, OutlinedInput, InputAdornment, Link } from "@mui/material";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CopyBox from "../../components/common/InfoDrawer/CopyBox";
import dayjs, { Dayjs } from "dayjs";
import { useStorageContext } from "../../contexts/StorageContextProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { bytesToSize, sizeToBytes } from "../storage/storage-helper";

type AirdropFormProps = {
  airdropInviteDetails: { emailExpirationDate: Dayjs | null; allowedStorage: number | null };
  onAirdropInviteDetails: React.Dispatch<
    React.SetStateAction<{
      emailExpirationDate: Dayjs | null;
      allowedStorage: number | null;
    }>
  >;
  airdropUrl?: string;
  onDateError: React.Dispatch<React.SetStateAction<any>>;
  dateError: any;
  isViewOnly: boolean;
};

function valuetext(value: number) {
  return `${value}%`;
}

const AirdropForm: React.FC<AirdropFormProps> = ({
  airdropInviteDetails,
  onAirdropInviteDetails,
  airdropUrl,
  onDateError,
  dateError,
  isViewOnly
}) => {
  const [sliderValue, setSliderValue] = React.useState(100);
  const [storageUnit, setStorageUnit] = React.useState<"MB" | "GB">("MB");
  const { globalStorage } = useStorageContext();
  const { isCloudStorage } = useVaultContext();

  const handleExpirationDateChange = (date: Dayjs | null) => {
    onAirdropInviteDetails({
      ...airdropInviteDetails,
      emailExpirationDate: date
    });
  };

  const storageAvailable = (isCloudStorage ? globalStorage?.cloud_storage_available : globalStorage?.storage_available) || 0;

  const handleAllowedStoragePercentageChange = (_: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
    const percentToValue = (storageAvailable * (newValue as number)) / 100;
    handleAllowedStorageChange(percentToValue);
  };

  const handleAllowedStorageChange = (allowedStorage: number) => {
    if (allowedStorage === null || allowedStorage >= 0) {
      onAirdropInviteDetails({
        ...airdropInviteDetails,
        allowedStorage: allowedStorage
      });
    } else {
      onAirdropInviteDetails({
        ...airdropInviteDetails,
        allowedStorage: null
      });
    }
  };

  React.useEffect(() => {
    setSliderValue(100);
    onAirdropInviteDetails({
      ...airdropInviteDetails,
      allowedStorage: isViewOnly ? null : storageAvailable
    });
  }, [isViewOnly, storageAvailable]);

  return (
    <Box>
      <Box mt={7}>
        <Typography variant="h3" className="strong">
          Set link expiry
        </Typography>
        <Typography variant="body2" className="small">
          Set a date for your airdrop access to link to expire.
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            slotProps={{
              openPickerButton: { sx: { marginRight: 0 } },
              textField: {
                id: "expiration-date",
                sx: {
                  width: "200px",
                  marginTop: 4,
                  marginBottom: 7,
                  fontSize: "1rem",
                  ".MuiFormHelperText-root": {
                    marginLeft: 0,
                    marginRight: 0
                  }
                },
                placeholder: "dd/mm/yyyy",
                helperText: dateError && "Date cannot be in the past."
              },
              desktopPaper: { sx: { backgroundColor: "background.card" } }
            }}
            value={airdropInviteDetails.emailExpirationDate}
            onChange={(value: Dayjs | null) => handleExpirationDateChange(value)}
            // disablePast
            format="DD/MM/YYYY"
            minDate={dayjs(new Date())}
            onError={(newError) => onDateError(newError)}
          />
        </LocalizationProvider>
      </Box>
      {!isViewOnly && (
        <Box>
          <Typography variant="h3" className="strong">
            Set allowed storage
          </Typography>
          <Typography variant="body2" className="small">
            How much storage do they need? Move the slider to set the storage limit. Putting 0 removes the ability to upload.
          </Typography>
          <Box justifyContent="space-between" mt={4} mb={7}>
            <Box mb={4} mt={6}>
              <OutlinedInput
                id="allowed-storage"
                name="allowed-storage"
                // type="number"
                placeholder="Allowed storage"
                value={bytesToSize(airdropInviteDetails.allowedStorage, storageUnit).size || 0}
                onChange={(ev) => {
                  const valueToPercent = (sizeToBytes(parseInt(ev.target.value), storageUnit).size * 100) / storageAvailable;
                  setSliderValue(valueToPercent || 0);
                  handleAllowedStorageChange(sizeToBytes(parseInt(ev.target.value), storageUnit).size);
                }}
                sx={{
                  width: "200px"
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Box sx={{ whiteSpace: "pre" }}>
                      <Link
                        variant="body2"
                        className={storageUnit === "MB" ? "strong" : ""}
                        color={storageUnit === "MB" ? "text.primary" : "text.secondary"}
                        sx={{ borderBottom: storageUnit === "MB" ? "" : "none" }}
                        onClick={() => setStorageUnit("MB")}
                      >
                        MB
                      </Link>{" "}
                      <Link
                        variant="body2"
                        className={storageUnit === "GB" ? "strong" : ""}
                        color={storageUnit === "GB" ? "text.primary" : "text.secondary"}
                        sx={{ borderBottom: storageUnit === "GB" ? "" : "none" }}
                        onClick={() => setStorageUnit("GB")}
                      >
                        GB
                      </Link>
                    </Box>
                  </InputAdornment>
                }
              />
            </Box>

            <Box width="100%">
              <Slider
                value={sliderValue}
                aria-label="Allowed Storage Slider"
                valueLabelDisplay="auto"
                valueLabelFormat={valuetext}
                min={0}
                max={100}
                onChange={handleAllowedStoragePercentageChange}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2" color="text.primary">
                  0
                </Typography>
                <Typography variant="body2" color="text.primary">
                  100%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {airdropUrl && (
        <Box mb={7}>
          <Typography variant="h3" className="strong" sx={{ marginBottom: 4 }}>
            Airdrop access link
          </Typography>
          <CopyBox dataValue={airdropUrl} />
        </Box>
      )}
    </Box>
  );
};

export default AirdropForm;
