import { KEEP_SIGNED_IN, REQUESTING_SHARED_SESSION, SESSION_SHARING } from './constants'


/**
 * Provides session storage data sharing between browser tabs on same origin using local storage events. 
 * Handler is used for sharing authentication tokens over browser tabs in short user session i.e. keep_signed_in = false
 *  
 * Handler work:
 *      <li>On page load - generate local storage event to request for existing session data in other tabs</li>
 *      <li>If no other, same origin tabs are opened event is ignored</li>
 *      <li>If other, same origin tab(s) is opened - event is catched in other tab(s)</li>
 *      <li>Session data is packed and shared over local storage event (only in case of short user session)</li>
 *      <li>Local storage event with session data is catched by tab that generated sharing request</li>
 * 
 * Sharing flow must end before user authentication on page load
 */
export const registerSharedSessionHandler = () => {
    registerSessionSharing()
    requestSessionData()
}

const registerSessionSharing = () => {
    window.addEventListener('storage', (event) => {
        if (event.key === REQUESTING_SHARED_SESSION && sessionStorage.getItem(KEEP_SIGNED_IN) == 'false') {
            shareSessionData()
        }
        if (event.key === SESSION_SHARING && sessionStorage.getItem(KEEP_SIGNED_IN) === null) {
            restoreSessionData(event)
        }
    })
}

const requestSessionData = () => {
    localStorage.setItem(REQUESTING_SHARED_SESSION, true)
    localStorage.removeItem(REQUESTING_SHARED_SESSION)
}

const shareSessionData = () => {
    localStorage.setItem(SESSION_SHARING, JSON.stringify({ sessionStorage }))
    localStorage.removeItem(SESSION_SHARING)
}

const restoreSessionData = (event) => {
    const sessionData = JSON.parse(event.newValue)?.sessionStorage
    Object.keys(sessionData)
        ?.forEach(key => sessionStorage.setItem(key, sessionData[key]))
}
