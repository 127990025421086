import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Skeleton } from "@mui/material";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import React from "react";
import { useHistory } from "react-router-dom";
import { StripeClimateIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { grey } from "../../theme/colors";
import { setupSubscription } from "../../helpers/api-helpers";
import BillingForm from "./BillingForm";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { SubscriptionPlan, SubscriptionPlanSetupResponse } from "../../types/stripeTypes";

type SubscriptionCardFormStylesProps = {
  isMobile: boolean;
};

const useStyles = makeStyles<Theme, SubscriptionCardFormStylesProps>((theme) => ({
  promoText: {
    backgroundColor: theme.palette.info.light,
    color: grey[900],
    padding: `${theme.spacing(2)}`,
    borderRadius: "0 0 4px 0"
  },
  ccText: {
    fontSize: "16px",
    color: "white",
    "::placeholder": {
      color: grey[400]
    }
  },
  rowForm: {
    display: "flex",
    flexDirection: ({ isMobile }) => (isMobile ? "column" : "row")
  },
  rowField: {
    flex: "1",
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  },
  rowSkeleton: {
    width: "100%",
    marginBottom: 32,
    borderRadius: 4,
    flexGrow: 1,
    "&:nth-child(2)": {
      marginLeft: 16,
      marginRight: 16
    }
  },
  rowFields: {
    "@media (min-width: 660px)": {
      flex: 1
    },
    "@media (min-width: 766px)": {
      flex: 2
    },
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  }
}));

type SubscriptionCardFormProps = {
  product?: SubscriptionPlan;
};

const SubscriptionCardForm: React.FC<SubscriptionCardFormProps> = ({ product }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { isMobile } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { currencyCode, discountCoupon, upcomingInvoice, renderPrice, quantity } = usePaymentContext();

  const history = useHistory();
  const classes = useStyles({ isMobile: isMobile });

  const urlParams = new URLSearchParams(location.search);
  const planName = urlParams.get("plan");

  const handleSubscriptionSetup = async () => {
    if (!stripe || !elements) {
      return;
    }
    const params: { currency: string; quantity: number; coupon?: string } = { currency: currencyCode.toLowerCase(), quantity: quantity };
    if (discountCoupon) {
      params.coupon = discountCoupon;
    }
    const subscription: SubscriptionPlanSetupResponse = await setupSubscription(product?.name, params);
    const paymentIntent = subscription?.currentSubscription?.latest_invoice?.payment_intent;
    if (paymentIntent) {
      if (paymentIntent.status === "requires_action") {
        const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret);
        if (error) {
          console.log(error);
          return;
        }
      }
    }
    if (subscription?.currentSubscription) {
      onSnackbarToShow("subscriptionSetupSuccess");
      history.push("/account/plans-and-payments/pricing-plans");
    }
  };

  return (
    <>
      <Box width="100%">
        <Box>
          <Typography variant="h1" sx={{ marginTop: isMobile ? 7 : "inherit" }}>
            Checkout
          </Typography>
          {product ? (
            <Box mt={6}>
              <BillingForm
                onBillingSaved={() => handleSubscriptionSetup()}
                billingSaveButtonText={`Setup ${planName} ${renderPrice(upcomingInvoice?.total, upcomingInvoice?.currency)}`}
                submitWithoutChanges
                skipMessaging
                subscribe
                allowCoupon
                useMobilePayment
                mobilePaymentButtonText={`Setup ${planName}`}
                mobilePaymentAmount={upcomingInvoice?.total}
                mobilePaymentCurrency={upcomingInvoice?.currency}
              />
              <Box display="flex" alignItems="center" mt={3}>
                <StripeClimateIcon fontSize="small" sx={{ marginRight: "5px" }} />
                <Typography variant="caption" color="text.tertiary" className="small">
                  Akord will contribute 1% of your purchase to remove CO2 from the atmosphere.
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box mt={6} width="100%">
              {Array.from(new Array(9)).map((item, index) =>
                index === 0 ? (
                  <Box key={index} display="flex" flexDirection="row">
                    {Array.from(new Array(3)).map((i, idx) => (
                      <Skeleton key={idx} variant="rectangular" height={56} width="30%" className={classes.rowSkeleton} />
                    ))}
                  </Box>
                ) : (
                  <Skeleton key={index} variant="rectangular" height={56} style={{ marginBottom: 32, borderRadius: 4 }} />
                )
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SubscriptionCardForm;
