import React, { useEffect, useState, useRef } from "react";
import { Fab, Zoom } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowDownIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import { EmptySpace, MemoBar } from "../../components/common";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { filterMemos, PeriodFilterKeys } from "../../helpers/stack-helpers";
import { useChatContext } from "../../contexts/ChatContextProvider";
import VaultChat from "./VaultChat";

const useStyles = makeStyles({
  fabIcon: {
    fontSize: "30px"
  },
  fabUp: {
    position: "fixed",
    right: "16px",
    bottom: "76px"
  }
});

type TabChatProps = {
  periodFilter: PeriodFilterKeys | null;
  actionFilters: string[];
};

const TabChat: React.FC<TabChatProps> = ({ periodFilter, actionFilters }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleShowScrollButton = (value: boolean) => setShowScrollButton(value);

  const { memos, isMemoLoaded, chatItemsLimit, onChatItemsNumber, chatItemsNumber } = useChatContext();
  const { vault, currentMembership } = useVaultContext();
  const classes = useStyles();
  const history = useHistory();

  const topMemoRef = useRef<HTMLDivElement | null>(null);
  const chatRef = useRef<HTMLDivElement | null>(null);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const isMoreMemosLoadedRef = useRef(false);

  const onScroll = () => {
    if (!isMemoLoaded) return;
    const scrollTop = chatRef?.current?.scrollTop || 0;
    const scrollHeight = chatRef?.current?.scrollHeight || 0;
    const clientHeight = chatRef?.current?.clientHeight || 0;

    if (scrollTop + clientHeight === scrollHeight) {
      handleShowScrollButton(false);
    } else if (scrollTop + clientHeight < scrollHeight) {
      handleShowScrollButton(true);
    } else {
      handleShowScrollButton(false);
    }
  };

  const scrollToBottom = () => {
    chatEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const chatItems = memos?.length > 0 ? filterMemos(memos, periodFilter, actionFilters) : [];

  const loadMoreChatItems = () => {
    isMoreMemosLoadedRef.current = true;
    onChatItemsNumber(chatItemsNumber + chatItemsLimit);
  };

  // useEffect(() => {
  //   if (isMemoLoaded) {
  //     topMemoRef?.current?.scrollIntoView();
  //     chatRef.current.scrollTop -= 132;
  //   }
  // }, [topMemoRef.current, isMemoLoaded]);

  useEffect(() => {
    if (vault?.status === "DELETED") {
      history.push("/vaults/active");
    }
  }, [vault, history]);

  return (
    <>
      <div
        ref={chatRef}
        onScroll={onScroll}
        style={{
          width: "100%",
          height: "calc(100% - 53px)",
          overflowY: "auto",
          paddingTop: "24px",
          position: "absolute"
        }}
      >
        {chatItems.length > 0 || !isMemoLoaded ? (
          <VaultChat
            chatItems={chatItems}
            chatEndRef={chatEndRef}
            scrollToBottom={scrollToBottom}
            topMemoRef={topMemoRef}
            loadMoreChatItems={loadMoreChatItems}
            isMoreMemosLoadedRef={isMoreMemosLoadedRef}
          />
        ) : (
          <EmptySpace dataText="emptyMemos" spaceData={currentMembership} />
        )}
        <MemoBar />
      </div>
      <Zoom in={showScrollButton} style={{ transitionDelay: "100ms" }}>
        <Fab size="small" color="secondary" className={classes.fabUp} onClick={scrollToBottom}>
          <ArrowDownIcon classes={{ root: classes.fabIcon }} />
        </Fab>
      </Zoom>
    </>
  );
};

export default TabChat;
