import React from "react";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import { Typography } from "@mui/material";

function MembershipReject(props) {
  const { operation, positionedBeforeDate, color } = props;

  return (
    <div style={{ marginBottom: positionedBeforeDate ? "74px" : "16px" }}>
      <OperationTitleWithIcon actionRef={operation.actionRef} color={color} />
      <Typography noWrap paragraph variant="body2" color="text.secondary" className="small">
        {operation.membership.email}
      </Typography>
    </div>
  );
}

export default MembershipReject;
