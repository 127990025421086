import { grey, orange, blue } from "./colors";
import { PaletteMode, PaletteOptions, SimplePaletteColorOptions, ThemeOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteColor {
    veryDark?: string;
    veryLight?: string;
  }

  interface SimplePaletteColorOptions {
    veryDark?: string;
    veryLight?: string;
  }
}

export interface DefaultPaletteOptions extends PaletteOptions {
  primary?: SimplePaletteColorOptions;
  secondary?: SimplePaletteColorOptions;
  error?: SimplePaletteColorOptions;
  info?: SimplePaletteColorOptions;
  success?: SimplePaletteColorOptions;
}

export const createpaletteOptions = (darkMode = false): DefaultPaletteOptions => ({
  mode: darkMode ? "dark" : ("light" as PaletteMode),
  background: {
    default: darkMode ? "#18181B" : "#FFF",
    secondary: darkMode ? grey[800] : "#FFF",
    tertiary: darkMode ? grey[600] : grey[200],
    switch: grey[500],
    dark: grey[800],
    archived: darkMode ? grey[800] : grey[200],
    active: darkMode ? "rgb(155, 50, 3, 20%)" : orange[100],
    card: darkMode ? grey[800] : grey[100],
    cardBorder: darkMode ? grey[700] : grey[200],
    stack: darkMode ? blue[900] : blue[100]
  },
  text: {
    primary: darkMode ? grey[100] : grey[800],
    secondary: darkMode ? grey[400] : grey[500],
    tertiary: darkMode ? grey[300] : grey[600],
    disabled: darkMode ? grey[500] : grey[400]
  },
  primary: {
    main: orange[600]!,
    light: orange[400],
    dark: orange[700],
    darkest: orange[800],
    veryDark: orange[900],
    iconInvert: darkMode ? grey[900] : grey[100],
    contrastText: "#FFF"
  },
  secondary: {
    main: orange[500]!,
    light: grey[300],
    disabled: darkMode ? grey[500] : grey[400]
  },
  success: {
    main: "#36A342",
    light: "#46C354",
    dark: "#288032"
  },
  warning: {
    main: orange[400]! //not used
  },
  error: {
    main: "#E43C33"
  },
  info: {
    veryLight: blue[100],
    light: blue[400],
    main: blue[500]!,
    dark: blue[600],
    darkest: blue[800],
    veryDark: blue[900]
  }
});

export const theme = (darkMode = false, palette?: DefaultPaletteOptions) => {
  const paletteAkord = createpaletteOptions(darkMode);
  const paletteOptions = palette ? palette : paletteAkord;
  return {
    palette: paletteOptions,
    spacing: [0, 4, 8, 12, 16, 20, 24, 32, 48, 64, 72],
    breakpoints: {
      values: {
        xs: 0,
        sm: 668,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },

    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontWeight: 400,
      fontSize: 16,
      fontStyle: "normal"
    },
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: "20px",
            paddingRight: "20px",
            "@media (min-width:668px)": {
              paddingLeft: "20px",
              paddingRight: "20px"
            },
            "&.MuiContainer-disableGutters": {
              paddingLeft: 0,
              paddingRight: 0
            }
          },
          maxWidthSm: {
            maxWidth: "544px!important"
          }
        }
      },
      // TYPOGRAPHY
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: 24
          },
          paragraph: {
            "&.MuiTypography-body2": {
              marginBottom: "0.75rem",
              "&:last-child": {
                marginBottom: 0
              }
            }
          },
          h1: {
            fontWeight: 700,
            color: paletteOptions?.text?.primary,
            fontSize: "1.375rem",
            letterSpacing: "-0.016em",
            lineHeight: 1.39,
            "&.xl": {
              fontSize: "1.875rem",
              letterSpacing: "-0.02em",
              whiteSpace: "pre"
            },
            "&.xxl": {
              fontSize: "2.1875rem",
              letterSpacing: "-0.02em"
            },
            "&.regular": {
              fontWeight: 400,
              fontVariant: "small-caps"
            }
          },
          h2: {
            fontWeight: 700,
            color: paletteOptions?.text?.primary,
            fontSize: "1.1875rem",
            letterSpacing: "-0.014em",
            lineHeight: 1.4,
            "&.regular": {
              fontWeight: 400
            }
          },
          h3: {
            fontWeight: 700,
            color: paletteOptions?.text?.primary,
            letterSpacing: "-0.013em",
            fontSize: "1.0625rem",
            lineHeight: 1.41
          },
          body1: {
            color: paletteOptions?.text?.tertiary,
            fontSize: "1.0625rem",
            lineHeight: 1.41,
            letterSpacing: "-0.013em",
            fontWeight: 400,
            "&.strong": {
              fontWeight: 700
            }
          },
          body2: {
            color: paletteOptions?.text?.tertiary,
            fontSize: "0.9375rem",
            lineHeight: 1.43,
            letterSpacing: "-0.009em",
            "&.small": {
              transition: ".3s ease",
              fontSize: "0.8625rem",
              lineHeight: "1.45"
            },
            "&.strong": {
              fontWeight: 700
            },
            "&.xs": {
              fontSize: "0.70625rem",
              lineHeight: 1.6
            }
          },
          caption: {
            fontWeight: 400,
            color: darkMode ? grey[300] : grey[600],
            fontSize: "0.8125rem",
            lineHeight: 1.45,
            "&.small": {
              fontSize: "0.7688rem",
              marginBottom: "0",
              lineHeight: "18.5px",
              letterSpacing: 0
            },
            "&.xs": {
              fontSize: "0.75rem",
              lineHeight: 1.6
              // letterSpacing: 1.6
            },
            "&.strong": {
              fontWeight: 700
            }
          }
        }
      },
      // NAVIGATION
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundImage: "none"
          },
          colorSecondary: {
            backgroundColor: paletteOptions?.background?.default,
            color: paletteOptions?.secondary?.main
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            "& .MuiButton-label": {
              fontWeight: 700
            }
          },
          regular: {
            justifyContent: "space-evenly",
            "@media (min-width: 700px)": {
              minHeight: 54,
              justifyContent: "space-around"
            },
            "@media (min-width: 0px) and (orientation: landscape)": {
              minHeight: 54,
              justifyContent: "space-around"
            }
          },
          dense: {
            minHeight: 50
          },
          gutters: {
            "@media (min-width: 768px)": {
              padding: "0 16px"
            }
          }
        }
      },
      // AVATAR
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: "inherit",
            width: 35,
            height: 35,
            paddingTop: 4
          },
          colorDefault: {
            backgroundColor: paletteOptions?.secondary?.main,
            color: "#FFF"
          }
        }
      },
      // BUTTONS
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: "44px",
            padding: "0 1rem",
            lineHeight: "inherit",
            fontSize: "1.125rem",
            textTransform: "none"
          },
          contained: {
            border: "1px solid",
            "&.Mui-disabled": {
              backgroundColor: paletteOptions?.text?.disabled,
              color: darkMode ? grey[400] : grey[200],
              borderColor: paletteOptions?.text?.disabled,
              "&:hover": {
                backgroundColor: paletteOptions?.text?.disabled,
                borderColor: paletteOptions?.text?.disabled,
                "&$disabled": {
                  backgroundColor: paletteOptions?.text?.disabled,
                  // Reset on touch devices, it doesn't add specificity
                  "@media (hover: none)": {
                    backgroundColor: paletteOptions?.text?.disabled
                  }
                }
              }
            }
          },
          textSizeXs: {
            padding: "0 12px",
            height: "32px",
            minWidth: "auto",
            fontSize: "0.9375rem"
          },
          textSizeSmall: {
            padding: "0 12px",
            height: "40px",
            minWidth: "auto"
          },
          outlinedSizeSmall: {
            padding: "0 12px",
            height: "40px",
            minWidth: "auto"
          },
          outlinedSizeXs: {
            padding: "0 12px",
            height: "32px",
            minWidth: "auto",
            fontSize: "0.9375rem"
          },
          containedSizeXs: {
            padding: "0 12px",
            height: "32px",
            minWidth: "auto",
            fontSize: "0.9375rem"
          },
          containedSizeSmall: {
            padding: "0 12px",
            height: "40px",
            minWidth: "auto"
          },
          outlined: {
            border: "1px solid ",
            fontWeight: 700
          },
          outlinedSecondary: {
            borderColor: paletteOptions?.secondary?.main,
            color: "#FFF",
            "&:hover": {
              backgroundColor: paletteOptions?.primary?.veryDark
            }
          },
          outlinedPrimary: {
            borderColor: paletteOptions?.secondary?.main,
            "&:hover": {
              borderColor: paletteOptions?.primary?.dark,
              color: paletteOptions?.primary?.dark,
              backgroundColor: "transparent"
            }
          },
          containedPrimary: {
            borderColor: darkMode ? paletteOptions?.primary?.light : paletteOptions?.primary?.dark,
            boxShadow: "none",
            fontWeight: 700,
            "&:hover": {
              backgroundColor: paletteOptions?.primary?.dark,
              boxShadow: "none",
              borderColor: darkMode ? paletteOptions?.secondary?.main : paletteOptions?.primary?.dark,
              "@media (hover: none)": {
                backgroundColor: paletteOptions?.primary?.main
              },
              "&$disabled": {
                backgroundColor: paletteOptions?.primary?.dark
              }
            },
            minWidth: "224px", // check prev designs if we need it
            padding: "0 16px"
          },
          containedError: {
            backgroundColor: "#DB443C",
            borderColor: "#DB443C",
            boxShadow: "none",
            fontWeight: 700,
            "&:hover": {
              backgroundColor: paletteOptions?.primary?.dark,
              boxShadow: "none",
              borderColor: darkMode ? paletteOptions?.secondary?.main : paletteOptions?.primary?.dark,
              "@media (hover: none)": {
                backgroundColor: "#DB443C"
              },
              "&$disabled": {
                backgroundColor: "#F5A896"
              }
            },
            minWidth: "224px", // check prev designs if we need it
            padding: "0 16px"
          },
          textPrimary: {
            fontWeight: 700,
            color: paletteOptions?.text?.secondary,
            "&:hover": {
              backgroundColor: darkMode ? grey[800] : grey[200]
            }
          },
          text: {
            padding: "0 16px",
            color: paletteOptions?.text?.secondary,
            "&:hover": {
              backgroundColor: darkMode ? grey[800] : grey[200]
            }
          },
          sizeSmall: {
            fontSize: "1rem",
            "& .MuiButton-label": {},
            height: 40
          }
        }
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: darkMode ? grey[500] : grey[400],
            "&:hover": {
              color: paletteOptions?.primary?.dark,
              backgroundColor: "transparent"
            }
          },
          colorSecondary: {
            "&.Mui-checked": {
              "&:hover": {
                backgroundColor: "transparent!important" // hover over Radio button
              }
            }
          }
        }
      },
      // TABS
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            transition: ".3s ease",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 0,
            paddingBottom: 4,
            minHeight: "32px",
            "@media (min-width: 768px)": {
              minWidth: "auto"
            },
            "&.Mui-selected": {
              color: paletteOptions?.text?.primary
            },
            "&:hover": {
              color: darkMode ? "#FFF" : grey[800]
            }
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "28px"
          },
          flexContainer: {
            borderBottom: "1px solid ",
            borderBottomColor: paletteOptions?.primary?.main,
            position: "absolute",
            top: "-5px",
            width: "100%"
          }
        }
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: darkMode ? grey[500] : grey[400],
            "&:hover": {
              color: darkMode ? grey[200] : grey[600],
              backgroundColor: "transparent"
            },
            "&.Mui-disabled": {
              color: paletteOptions?.text?.disabled
            },
            padding: 0,
            fontSize: "1rem"
          },
          sizeSmall: {
            fontSize: "1.3rem"
          },
          colorSecondary: {
            "&:hover": {
              backgroundColor: "transparent"
            }
          }
        }
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            // boxShadow: darkMode
            //   ? "0 5px 10px hsla(255,7%,11%,.3), 0 2px 4px hsla(255,7%,11%,.27)"
            //   : "0 5px 10px hsla(255,7%,11%,.15), 0 2px 4px hsla(255,7%,11%,.12)",
            width: 69,
            height: 69,
            "&:active": {
              boxShadow: "none"
              // boxShadow: darkMode
              //   ? "0 5px 10px hsla(255,7%,11%,.3), 0 2px 4px hsla(255,7%,11%,.27)"
              //   : "0 5px 10px hsla(255,7%,11%,.15), 0 2px 4px hsla(255,7%,11%,.12)"
            },
            "&.Mui-disabled": {
              backgroundColor: paletteOptions?.text?.disabled,
              color: darkMode ? grey[400] : grey[200]
            }
          },
          primary: {
            "&:hover": {
              backgroundColor: paletteOptions?.primary?.dark
            }
          },
          secondary: {
            border: "1px solid",
            borderColor: darkMode ? grey[500] : grey[400],
            color: paletteOptions?.text?.secondary,
            backgroundColor: paletteOptions?.background?.default,
            "&:hover": {
              backgroundColor: paletteOptions?.background?.default,
              "@media (hover: none)": {
                border: "1px solid",
                borderColor: darkMode ? grey[500] : grey[400],
                color: paletteOptions?.text?.secondary,
                backgroundColor: paletteOptions?.background?.default
              }
            }
          },
          sizeMedium: {
            width: "48px",
            height: "48px",
            minHeight: "48px",
            minWidth: "48px"
            // boxShadow: "none"
          },
          sizeSmall: {
            width: "32px",
            height: "32px",
            minHeight: "32px",
            minWidth: "32px"
            // boxShadow: "none"
          },
          extended: {
            textTransform: "none",
            height: "33px!important",
            padding: "0 16px 0 12px!important",
            fontSize: "0.938rem",
            width: "auto!important"
          }
        }
      },
      // LINKS
      MuiLink: {
        styleOverrides: {
          underlineHover: {
            "&:hover": {
              textDecoration: "none"
            }
          },
          underlineNone: {
            borderBottom: "1px solid",
            borderBottomColor: paletteOptions?.text?.secondary,
            "&:hover": {
              color: paletteOptions?.text?.primary
            }
          },
          underlineAlways: {
            textDecoration: "none",
            borderBottom: "1px solid",
            borderBottomColor: paletteOptions?.text?.secondary,
            "&:hover": {
              color: paletteOptions?.text?.primary
            }
          }
        }
      },
      // LISTS
      MuiList: {
        styleOverrides: {
          root: {
            margin: 0,
            listStyleType: "disc"
          },
          padding: {
            paddingTop: 0,
            paddingBottom: 0
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 10,
            paddingBottom: 10,
            "&.Mui-selected": {
              backgroundColor: paletteOptions?.info?.dark,
              color: grey[100],
              "&:hover": {
                backgroundColor: paletteOptions?.info?.dark,
                color: grey[100]
              }
            },
            "&.Mui-disabled": {
              color: grey[100]
            },
            "& a": {
              textDecoration: "none"
            },
            listStyleType: "disc",
            "&:first-of-type": {
              paddingTop: 0
            },
            "@media(max-width:668px)": {
              paddingTop: "0!important",
              paddingBottom: 0
            }
          },
          gutters: {
            paddingLeft: "20px",
            paddingRight: "20px",
            margin: 0
          },
          dense: {
            paddingTop: 0,
            paddingBottom: 0
          },
          button: {
            color: paletteOptions?.text?.secondary,
            "&:hover": {
              backgroundColor: "transparent",
              color: paletteOptions?.text?.primary
            }
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: "10px 20px",
            "&.Mui-selected": {
              backgroundColor: paletteOptions?.info?.dark,
              color: grey[100],
              "&:hover": {
                backgroundColor: paletteOptions?.info?.dark,
                color: grey[100]
              }
            },
            "&.Mui-disabled": {
              color: grey[100]
            },
            "& a": {
              textDecoration: "none"
            },
            listStyleType: "disc",
            "&:first-of-type": {
              paddingTop: 0
            },
            "@media(max-width:668px)": {
              paddingTop: "0!important",
              paddingBottom: 0
            },
            color: paletteOptions?.text?.secondary,
            "&:hover": {
              backgroundColor: "transparent",
              color: paletteOptions?.text?.primary
            }
          },
          dense: {
            paddingTop: 0,
            paddingBottom: 0
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            marginTop: 0,
            // marginBottom: "1px",
            paddingTop: "3px"
          },
          inset: {
            paddingLeft: "32px"
          },
          multiline: {
            marginTop: 0,
            marginBottom: 0
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            transition: ".3s ease",
            minWidth: "auto!important",
            marginRight: "12px",
            "&:last-child": {
              marginRight: "6px"
            }
          },
          alignItemsFlexStart: {
            marginTop: 0
          }
        }
      },
      // Disable Ripple Effect on everything
      MuiTouchRipple: {
        styleOverrides: {
          root: {
            display: "none"
          }
        }
      },
      // Forms
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "0.9375rem",
            lineHeight: 0.875,
            color: darkMode ? grey[400] : grey[500],
            "&.Mui-focused": {
              color: grey[400]
            },
            "&.Mui-error": {
              color: paletteOptions?.error?.main
            },
            "&.shrink": {
              fontSize: "14px",
              transform: "translate(12px, 10px)",
              color: grey[400]
            }
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            minWidth: "auto"
          },
          fullWidth: {
            width: "100%"
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: "0.9375rem",
            color: paletteOptions?.secondary?.light
          },
          root: {
            marginRight: 0
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: "0.9375rem"
            // height: "55px"
            // lineHeight: "1.1876em"
          },
          input: {
            // height: "55px",
            fontSize: "0.9375rem",
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              transitionProperty: "background-color, color"
            },
            "&.Mui-disabled": {
              background: darkMode ? grey[800] : "#F7F6FA"
            },
            "&::-webkit-input-placeholder": {
              opacity: 1
            },
            color: paletteOptions?.text?.primary,
            "&::placeholder": {
              color: paletteOptions?.text?.secondary,
              opacity: 1
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: "translate(14px, 20px) scale(1)",
            height: "1.4375em",
            // overflow: "inherit"
            whiteSpace: "normal"
            // lineHeight: "1.1876em"
          },
          filled: {
            transform: "translate(12px, 26px) scale(1)"
          },
          outlined: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.777)"
            }
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: grey[200],
            "&:hover, &.Mui-focused": {
              backgroundColor: grey[400]
            }
          },
          input: {
            padding: "32px 12px 15px"
          },
          underline: {
            "&:before": {
              borderBottom: "2px solid #D1CFD8"
            },
            "&:hover:before": {
              borderBottom: "2px solid #9D9BA6"
            },
            "&:after": {
              borderBottom: "2px solid #D1CFD8"
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              transitionProperty: "background-color, color",
              WebkitBoxShadow: "none"
            }
          },
          root: {
            height: "55px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid",
              borderColor: darkMode ? grey[600] : grey[300]
            },
            "&.MuiOutlinedInput-root.Mui-disabled": {
              pointerEvents: "none",
              background: paletteOptions?.background?.archived
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "1px solid",
              borderColor: paletteOptions?.secondary?.main
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid",
              borderColor: paletteOptions?.secondary?.main
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: paletteOptions?.error?.main
            }
          },
          multiline: {
            height: "auto",
            padding: "12px 14px 18px"
          },
          adornedEnd: {
            paddingRight: 12
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            marginBottom: 24
          }
        }
      },
      MuiRadio: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: darkMode ? grey[500] : grey[400],
            position: "relative",
            top: -2,
            "&:hover": {
              "&:not(&.Mui-checked)": {
                backgroundColor: "transparent!important", // hover over Radio button
                color: darkMode ? grey[200] : grey[600]
              }
            },
            "&.Mui-checked": {
              "&:hover": {
                backgroundColor: "transparent!important" // hover over Radio button
              }
            }
          },
          colorSecondary: {
            "&.Mui-checked": {
              "&:hover": {
                backgroundColor: "transparent!important" // hover over Radio button
              }
            }
          }
        }
      },
      // Snackbar
      MuiSnackbar: {
        styleOverrides: {
          root: {
            left: "20px",
            right: "20px"
          },
          anchorOriginBottomCenter: {
            bottom: "24px"
          }
        }
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            fontSize: 16
          }
        }
      },
      // MISC
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: 27
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderColor: darkMode ? `${grey[800]}!important` : `${grey[200]}!important`,
            background: "transparent",
            border: "1px solid",
            boxShadow: "none"
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: darkMode ? grey[800] : grey[200]
          },
          light: { borderColor: darkMode ? grey[700] : grey[200] }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            marginTop: 14,
            marginBottom: 0,
            borderRadius: 2
          }
          // colorPrimary: {
          //   backgroundColor: darkMode ? orange[700] : orange[200]
          // }
        }
      },
      MuiMobileStepper: {
        styleOverrides: {
          dot: {
            backgroundColor: grey[600]
          },
          dotActive: {
            backgroundColor: "#FFF"
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "24px",
            transition: ".3s ease",
            lineHeight: 1.43
          },
          colorPrimary: {
            color: darkMode ? "#FFF" : "#403F46"
          },
          colorSecondary: {
            color: darkMode ? grey[500] : grey[400]
          },
          colorAction: {
            color: paletteOptions?.primary?.main
          },
          colorWarning: {
            color: paletteOptions?.primary?.light
          },
          colorInfo: {
            color: paletteOptions?.text?.tertiary
          },
          colorDisabled: {
            color: darkMode ? grey[500] : grey[400]
          },
          colorError: {
            color: paletteOptions?.error?.main
          },
          fontSizeSmall: {
            fontSize: "20px"
          },
          fontSizeLarge: {
            fontSize: "30px"
          }
        }
      },
      MuiMenuItem: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            minHeight: "auto",
            paddingRight: 24,
            paddingLeft: 16,
            paddingTop: 8,
            paddingBottom: 8,
            "&:hover": {
              backgroundColor: "transparent"
            },
            "&:last-of-type": {
              paddingBottom: 16
            },
            "&:first-of-type": {
              paddingTop: 16
            },
            "@media(max-width:668px)": {
              paddingRight: 24,
              paddingLeft: 16,
              paddingTop: 10,
              paddingBottom: 10,
              "&:last-of-type": {
                paddingBottom: 20
              },
              "&:first-of-type": {
                paddingTop: 20
              }
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              backgroundColor: "transparent!important"
            }
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px"
          },
          paperAnchorRight: {
            width: "350px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          },
          paperAnchorDockedRight: {
            borderLeft: "none",
            boxShadow: darkMode
              ? "-10px 10px 20px hsla(255,7%,11%,.25), -1px 3px 6px hsla(255,7%,11%,.23)"
              : "-10px 10px 20px hsla(255,7%,11%,.10), -1px 3px 6px hsla(255,7%,11%,.08)"
          },
          paperAnchorDockedLeft: {
            borderRight: darkMode ? "1px solid #403F46" : "1px solid #D1CFD8"
          }
        }
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            backgroundColor: paletteOptions?.info?.main,
            border: "2px solid ",
            borderColor: darkMode ? grey[900] : "#FFF",
            height: "18px",
            fontSize: "11px",
            fontWeight: 700,
            padding: "0 4px",
            minWidth: "18px",
            lineHeight: "11px",
            right: "auto"
          },
          dot: {
            height: "9px",
            minWidth: "9px",
            borderRadius: "8px",
            padding: 0
          },
          anchorOriginTopRightRectangular: {
            transform: "scale(1) translate(300%, -50%)"
          },
          anchorOriginTopLeft: {
            transform: "scale(1) translate(300%, -50%)"
          },
          anchorOriginTopRightCircular: {
            transform: "scale(1) translate(50%, -50%)",
            top: "20%",
            right: "20%"
          },
          anchorOriginTopLeftCircular: {
            transform: "scale(1) translate(-230%, 40%)",
            top: "auto",
            left: "auto"
          },
          colorSecondary: {
            color: darkMode ? paletteOptions?.info?.light : paletteOptions?.info?.main,
            backgroundColor: darkMode ? paletteOptions?.info?.veryDark : paletteOptions?.info?.veryLight
          },
          colorWarning: {
            backgroundColor: orange[400]
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            backgroundColor: paletteOptions?.background?.default,
            borderRadius: "4px" // need to add square props
          },
          elevation4: {
            boxShadow: darkMode
              ? "0 3px 6px hsla(255,7%,11%,.30), 0 2px 4px hsla(255,7%,11%,.27)"
              : "0 3px 6px hsla(255,7%,11%,.15), 0 2px 4px hsla(255,7%,11%,.12)"
          },
          elevation8: {
            boxShadow: darkMode
              ? "0 10px 20px hsla(255,7%,11%,.3), 0 3px 6px hsla(255,7%,11%,.23)"
              : "0 10px 20px hsla(255,7%,11%,.10), 0 3px 6px hsla(255,7%,11%,.08)"
          },
          elevation16: {
            boxShadow: darkMode
              ? "0 -10px 20px hsla(255,7%,11%,.25), 0 -3px 6px hsla(255,7%,11%,.23)"
              : "0 -10px 20px hsla(255,7%,11%,.10), 0 -3px 6px hsla(255,7%,11%,.08)"
          },
          elevation24: {
            boxShadow: darkMode
              ? "0 15px 30px hsla(255,7%,11%,.30  ), 0 5px 10px hsla(255,7%,11%,.25)"
              : "0 15px 30px hsla(255,7%,11%,.15  ), 0 5px 10px hsla(255,7%,11%,.10)"
          },
          rounded: {
            borderRadius: "8px"
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? "rgba(49, 47, 55, .8)" : "rgba(34, 33, 38, .5)"
          },
          invisible: {
            backgroundColor: "transparent"
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            padding: 12,
            paddingRight: 16,
            borderRadius: 4
          },
          standardError: {
            padding: 0,
            backgroundColor: "transparent",
            color: paletteOptions?.error?.main
          },
          filledSuccess: {
            backgroundColor: paletteOptions?.success?.dark
          },
          filledInfo: {
            backgroundColor: paletteOptions?.background?.archived
          },
          icon: {
            fontSize: 24,
            padding: 0,
            marginRight: 8
          },
          message: {
            padding: 0,
            marginTop: 2,
            whiteSpace: "pre-line"
          }
        }
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? grey[800] : grey[200],
            "&.lightColor": {
              backgroundColor: paletteOptions?.background?.tertiary
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "13px 20px",
            borderBottom: "1px solid",
            borderBottomColor: darkMode ? grey[800] : grey[200],
            WebkitTapHighlightColor: "transparent"
          },
          head: {
            color: paletteOptions?.text?.disabled
          },
          sizeSmall: {
            padding: "0 8px 0 20px"
          },
          paddingNone: {
            padding: "8px 8px"
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.MuiTableRow-hover:hover": {
              backgroundColor: darkMode ? "rgba(49, 47, 55, .3)!important" : "rgba(247, 246, 250, .3)!important"
            }
          }
        }
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: paletteOptions?.text?.secondary,
            "&.Mui-active": {
              "& .MuiTableSortLabel-icon": {
                opacity: 1,
                color: paletteOptions?.primary?.main
              }
            }
          },
          icon: {
            marginBottom: "2px",
            marginLeft: 0,
            marginRight: 0,
            fontSize: "20px"
          }
        }
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            width: "1px",
            backgroundColor: darkMode ? grey[800] : grey[200]
          }
        }
      },
      MuiTimelineDot: {
        styleOverrides: {
          filledGrey: {
            color: darkMode ? grey[500] : grey[400],
            backgroundColor: "transparent"
          }
        }
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            paddingTop: "16px"
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthMd: {
            width: "576px",
            maxHeight: "calc(100% - 48px)",
            padding: "24px"
          },
          paperWidthXs: {
            maxWidth: "400px",
            width: "100%",
            padding: "24px"
          },
          scrollPaper: {
            alignItems: "flex-start"
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            maxHeight: "237px",
            padding: 0,
            marginTop: 20,
            marginBottom: 24
          },
          dividers: {
            margin: "0 24px 16px",
            padding: "24px 0",
            borderBottom: "none"
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: 0,
            marginBottom: 12
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 0
          },
          spacing: {
            "& > :not(:first-of-type)": {
              marginLeft: 0
            }
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          outlinedPrimary: {
            color: darkMode ? "#FFF" : grey[600],
            border: "1px solid #3592FA"
          },
          colorPrimary: {
            backgroundColor: paletteOptions?.info?.main
          },
          // for superscript
          colorSecondary: {
            backgroundColor: paletteOptions?.primary?.main
          },
          sizeSmall: {
            lineHeight: 1.3,
            fontSize: "0.813rem",
            marginBottom: "0",
            height: "20px",
            fontWeight: 700
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: grey[600]
          },
          arrow: {
            "&::before": {
              backgroundColor: grey[600]
            }
          }
        }
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: paletteOptions?.primary?.main,
            position: "absolute"
          },
          colorSecondary: {
            color: darkMode ? paletteOptions?.info?.main : paletteOptions?.info?.light
          }
        }
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: paletteOptions?.info?.main,
            height: 7,
            padding: 0
          },
          rail: {
            opacity: 1,
            backgroundColor: paletteOptions?.info?.veryDark
          },
          thumb: {
            height: 23,
            width: 23,
            "&:focus, &:hover, &.Mui-active": {
              boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)"
              }
            }
          },
          valueLabel: {
            fontSize: 13.8,
            bottom: 0,
            backgroundColor: grey[700],
            padding: "5px",
            top: -5,
            borderRadius: "4px",
            transform: "translateY(0) scale(0)",
            "&:before": { top: 0, bottom: "unset", transform: "translate(-50%, -50%) rotate(45deg)" },
            "&.MuiSlider-valueLabelOpen": {
              transform: "translateY(130%) scale(1)"
            }
          },
          mark: {
            backgroundColor: paletteOptions?.info?.veryDark
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            margin: 0,
            color: paletteOptions?.text?.primary,
            fontFamily: "Inter,sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            backgroundColor: paletteOptions?.background?.default
          },
          a: {
            borderBottom: "1px solid ",
            borderColor: paletteOptions?.primary?.main,
            cursor: "pointer",
            WebkitTransition: "color 0.3s",
            transition: "color 0.3s",
            "&:hover": {
              textDecoration: "none!important",
              color: paletteOptions?.primary?.main
            }
          },
          // Styles for Markdown Editor
          "& .w-md-editor": {
            boxShadow: "none!important",
            backgroundColor: "transparent!important"
          },
          "& .w-md-editor-toolbar": {
            "li > button": {
              color: paletteOptions?.text?.disabled + "!important",
              height: "auto!important",
              padding: "0 4px!important"
            },
            "li > button:hover, li > button:focus": {
              color: paletteOptions?.text?.primary + "!important",
              backgroundColor: "transparent!important"
            },
            "li > button:disabled:hover": {
              color: paletteOptions?.text?.disabled + "!important"
            },
            "li.active > button": {
              color: paletteOptions?.text?.primary + "!important",
              backgroundColor: "transparent!important"
            },
            "li > button > .md-tab": {
              border: "1px solid transparent",
              padding: "12px",
              marginLeft: "-5px",
              marginRight: "-7px",
              borderRadius: "4px 4px 0 0"
            },
            "li.active > button > .md-tab": {
              border: `1px solid ${paletteOptions?.background?.cardBorder}`,
              padding: "12px",
              marginLeft: "-5px",
              marginRight: "-7px",
              borderRadius: "4px 4px 0 0",
              borderBottomColor: paletteOptions?.background?.default,
              backgroundColor: paletteOptions?.background?.default
            },
            zIndex: 1,
            backgroundColor: "transparent!important",
            flexDirection: "row-reverse",
            padding: "0!important",
            borderBottom: "none!important",
            "@media (hover: none)": {
              flexDirection: "column-reverse",
              alignItems: "flex-start!important",
              ul: {
                marginBottom: "16px!important",
                "&:first-of-type": {
                  width: "100%",
                  marginTop: "-16px",
                  paddingTop: "16px",
                  borderTop: "1px solid",
                  borderTopColor: paletteOptions?.background?.cardBorder,
                  display: "flex",
                  justifyContent: "space-between"
                }
              }
            }
          },
          "& .w-md-editor-content": {
            fontSize: "0.9375rem!important",
            letterSpacing: "-0.009em",
            marginTop: "-1px",
            border: "1px solid",
            borderRadius: "0 4px 4px!important",
            borderColor: paletteOptions?.background?.cardBorder
          },
          "& .w-md-editor-preview": {
            fontSize: "0.9375rem!important",
            letterSpacing: "-0.009em",
            lineHeight: "24px!important",
            fontFamily: ["Inter", "sans-serif"].join(",") + "!important",
            padding: "20px 16px!important"
          },
          "& .w-md-editor-text": {
            fontSize: "0.9375rem!important",
            letterSpacing: "-0.009em",
            lineHeight: "24px!important",
            fontFamily: ["Inter", "sans-serif"].join(",") + "!important",
            padding: "20px 16px!important",
            "& .w-md-editor-text-pre > code": {
              fontSize: "0.9375rem!important",
              letterSpacing: "-0.009em",
              lineHeight: "24px!important",
              fontFamily: ["Inter", "sans-serif"].join(",") + "!important"
            },
            "& .w-md-editor-text-input": {
              fontSize: "0.9375rem!important",
              letterSpacing: "-0.009em",
              lineHeight: "24px!important",
              fontFamily: ["Inter", "sans-serif"].join(",") + "!important",
              padding: "20px 16px!important"
            }
          },
          "& .w-md-editor-text > .w-md-editor-text-input:empty": {
            WebkitTextFillColor: paletteOptions?.text?.secondary + " !important"
          },
          "& .wmde-markdown": {
            fontSize: "0.9375rem!important",
            letterSpacing: "-0.009em",
            fontFamily: ["Inter", "sans-serif"].join(",") + "!important",
            backgroundColor: "transparent!important",
            h1: {
              fontSize: "36px!important",
              marginBottom: "16px!important",
              paddingBottom: "0!important",
              borderBottom: "none!important"
            },
            h2: {
              marginTop: "0!important",
              marginBottom: "16px!important",
              paddingTop: "0!important",
              paddingBottom: "0!important",
              borderBottom: "none!important"
            },
            h3: {
              marginTop: "0!important",
              marginBottom: "16px!important",
              paddingTop: "0!important",
              paddingBottom: "0!important",
              borderBottom: "none!important"
            },
            code: {
              fontFamily: ["JetBrains Mono", "monospace"].join(",") + "!important"
            },
            a: {
              color: "inherit!important",
              "&:hover": {
                textDecoration: "none!important"
              }
            }
          },
          "& .w-md-editor-bar": {
            display: "none"
          },
          // To target Preview in Gallery
          "&.md-gallery > .w-md-editor > .w-md-editor-content": {
            border: "none!important",
            padding: "0!important"
          }
        }
      }
    }
  } as ThemeOptions;
};

// export default theme;
