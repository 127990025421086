import React from "react";
import { AxiosError } from "axios";
import { Box, Typography, Button, Link, Skeleton, Table, TableHead, TableRow, TableCell, TableBody, Theme, TextField, CircularProgress, Tabs, Tab, IconButton, Collapse, Tooltip } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { EmptySpace, SupportScreenWrapper } from "../../components/common";
import CopyBox from "../../components/common/InfoDrawer/CopyBox";
import { deleteWebhook, generateApiKey, createWebhook, getApiKey, getWebhookLogs, getWebhooks } from "../../helpers/api-helpers";
import makeStyles from "@mui/styles/makeStyles";
import { grey } from "@akord/ui";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { CloseInCircleIcon, InfoIcon, TickInCircleIcon } from "@akord/addon-icons";
import dayjs from "dayjs";
import ReactJson from "react-json-view";
import { ZenObservable } from "zen-observable-ts";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import GraphQLAPI from "@aws-amplify/api-graphql";
import { GraphQLSubscription } from "@aws-amplify/api";
import { subscriptions } from "@akord/gql";

type WebhookLogsTableProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, WebhookLogsTableProps>(theme => ({
  paper: {
    width: "200px",
    minWidth: "100%",
    backgroundColor: ({ darkMode }) => (darkMode ? grey[800] : grey[100]),
    boxShadow: "none",
    border: ({ darkMode }) => (darkMode ? "none" : `1px solid ${grey[200]}`)
  },
  cell: {
    whiteSpace: "nowrap",
    borderBottomColor: ({ darkMode }) => (darkMode ? grey[700] : grey[200])
  },
  cellHead: {
    padding: "0 20px"
  },
  cellBody: {
    padding: "12px 20px 12px"
  },
  rowHover: {
    "&.MuiTableRow-hover": {
      "&:hover": {
        backgroundColor: ({ darkMode }) => (darkMode ? "#403F4630!important" : "#E8E6EF30!important")
      }
    },
    "&:last-child td": {
      borderBottom: 0
    }
  },
  buttonProgress: {
    position: "absolute"
  }
}));

function a11yProps(index: number) {
  return {
    id: `dev-tab-${index}`,
    "aria-controls": `dev-tabpanel-${index}`
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`dev-tabpanel-${index}`} aria-labelledby={`dev-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ m: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DeveloperSettings: React.FC<RouteComponentProps> = ({ history }) => {
  const { darkMode, isMobile, userAttributes } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const classes = useStyles({ darkMode: darkMode });
  
  const [tabValue, setTabValue] = React.useState(0);

  const [apiKey, setApiKey] = React.useState<any>();
  const [apiKeyGenerating, setApiKeyGenerating] = React.useState(false);
  const [apiKeyLoaded, setApiKeyLoaded] = React.useState(false);

  const [webhook, setWebhook] = React.useState<any>();
  const [webhookUpdating, setWebhookUpdating] = React.useState(false);
  const [webhookLoaded, setWebhookLoaded] = React.useState(false);

  const [webhookUrl, setWebhookUrl] = React.useState<string>("");

  const [webhookLogs, setWebhookLogs] = React.useState<any[]>([]);
  const [webhookLogsLoaded, setWebhookLogsLoaded] = React.useState(false);
  const [webhookLogsLoadingMore, setWebhookLogsLoadingMore] = React.useState(false);
  const [webhookLogsToken, setWebhookLogsToken] = React.useState("");

  React.useEffect(() => {
    const getKey = async () => {
      try {
        const apiKey = await getApiKey();
        if (apiKey) setApiKey(apiKey);
        setApiKeyLoaded(true);
      } catch (e) {
        console.warn(e);
        setApiKeyLoaded(true);
      }
    };
    getKey();
  }, []);

  const generateKey = async () => {
    setApiKeyGenerating(true);
    try {
      await generateApiKey();
      const apiKey = await getApiKey();
      setApiKey(apiKey);
    } catch (e: unknown) {
      console.error("Error generating API Key: ", e);
      if((e instanceof AxiosError) && e?.response?.data?.message) {
        onSnackbarToShow("apiKeyGenerateFailed", e?.response?.data?.message, "error");
      } else {
        onSnackbarToShow("apiKeyGenerateFailed", "Error generating API Key", "error");
      }
    }
    setApiKeyGenerating(false);
  };

  React.useEffect(() => {
    const getWebhook = async () => {
      try {
        const webhooks = await getWebhooks();
        if (webhooks?.items?.length) setWebhook(webhooks.items[0]);
        setWebhookLoaded(true);
      } catch (e) {
        console.warn(e);
        setWebhookLoaded(true);
      }
    };
    getWebhook();
  }, []);

  const addWebhook = async () => {
    setWebhookUpdating(true);
    try {
      await createWebhook(webhookUrl);
      const webhooks = await getWebhooks();
      if (webhooks?.items?.length) setWebhook(webhooks.items[0]);
    } catch (e) {
      console.warn(e);
      if((e instanceof AxiosError) && e?.response?.data?.message) {
        onSnackbarToShow("webhookAddFailed", e?.response?.data?.message, "error");
      } else {
        onSnackbarToShow("webhookAddFailed", "Error adding webhook", "error");
      }
    }
    setWebhookUpdating(false);
  };

  const deleteCurrentWebhook = async () => {
    setWebhookUpdating(true);
    try {
      await deleteWebhook(webhook.id);
      setWebhook(null);
      setWebhookUrl("");
    } catch (e) {
      console.warn(e);
      if((e instanceof AxiosError) && e?.response?.data?.message) {
        onSnackbarToShow("webhookRemoveFailed", e?.response?.data?.message, "error");
      } else {
        onSnackbarToShow("webhookRemoveFailed", "Error removing webhook", "error");
      }
    }
    setWebhookUpdating(false);
  };

  const handleWebhookUrlChanged = () => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target } = event;
    setWebhookUrl(target.value);
  };

  React.useEffect(() => {
    const loadWebhookLogs = async () => {
      const logs = await getLogs({ limit: 10, nextToken: ""});
      if (!logs) return;
      setWebhookLogs(logs.items);
      setWebhookLogsToken(logs.nextToken);
    }
    loadWebhookLogs();
  }, []);

  const getLogs = async (params: { limit: number, nextToken: string }) => {
    let logs = null;
    try {
      logs = await getWebhookLogs(params);
    } catch (e) {
      console.warn(e);
    }
    setWebhookLogsLoaded(true);
    return logs;
  };

  const handleLoadMore = async () => {
    setWebhookLogsLoadingMore(true);
    const logs = await getLogs({ limit: 10, nextToken: webhookLogsToken });
    setWebhookLogsLoadingMore(false);
    if (!logs || !logs.itmes) return;
    setWebhookLogs([...webhookLogs, ...logs.items]);
    setWebhookLogsToken(logs.nextToken!);
  };

  const handleChange = (_: any, newValue: number) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
      let onCreateWebhookLog: ZenObservable.Subscription;
      const setupSubscription = async () => {
        try {
          onCreateWebhookLog = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
            authMode: 'AWS_LAMBDA',
            authToken: 'custom',
            query: subscriptions.onCreateWebhookLog,
            variables: {
              filter: {
                address: { eq: userAttributes.storageAddress }
              }
            }
            // @ts-ignore
          }).subscribe({
            next: async ({ value }: any) => {
              const newWebhookLog = value.data.onCreateWebhookLog;
              setWebhookLogs([newWebhookLog, ...webhookLogs]);
            },
            error: () => {
              console.warn("err");
            }
          });
  
        } catch (err) {
          console.warn("Subscription error: ", err);
        }
      };
      if (window.navigator.onLine && userAttributes && userAttributes.storageAddress) setupSubscription();
      return () => {
        if (onCreateWebhookLog) onCreateWebhookLog.unsubscribe();
      };
    }, [userAttributes, webhookLogs]);

  return (
    <SupportScreenWrapper title="Developers" route="/account" hideDivider>
      <Box zIndex="100">
        <Tabs
          color="text.secondary"
          value={tabValue}
          onChange={handleChange}
          aria-label="storage tabs"
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          <Tab disableRipple label="API key" {...a11yProps(0)} sx={{ pl: 4 }} />
          <Tab disableRipple label="Webhook" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Box mt={5}>
          <Typography variant="h3">
            API key
          </Typography>
          {apiKeyLoaded ? (
            <>
              <Box mb={3} mt={5} display="flex" justifyContent="space-between" alignItems="center">
                {apiKey ?
                  <Typography variant="body2" color="text.primary">
                    Use your key for direct access to the Akord API.
                  </Typography>
                  :
                  <Typography variant="body2" color="text.primary">
                    Generate your key for direct access to the Akord API.
                </Typography>
                }
                <Button
                  variant={apiKey ? "outlined" : "contained"}
                  size="xs"
                  disableElevation
                  color={apiKey ? "error" : "primary"}
                  component="button"
                  sx={{ minWidth: "auto" }}
                  onClick={generateKey}
                  disabled={apiKeyGenerating}
                >
                  {apiKey ? "Rotate" : "Generate"}
                  {apiKeyGenerating && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>

              </Box>
              {apiKey && (
                <Box>
                  <CopyBox dataValue={apiKey.key} marginbottom={3} />
                  <Box mt={2}>
                    <Typography variant="caption">
                      API key usage: {apiKey.used} / {apiKey.total || apiKey.usagePlan?.limit || 100} per {apiKey.usagePlan?.period?.toLowerCase()}.
                    </Typography>
                    <Tooltip title="API key usage shows data with a ~15 minutes delay" arrow>
                      <span>
                        <InfoIcon fontSize="small" sx={{ marginLeft: 2 }}/>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box mt={5}>
              <Skeleton height={32} width="100%" />
            </Box>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box mt={5}>
          <Typography variant="h3">
            Webhook
          </Typography>
          {webhookLoaded ? (
          <>
            <Box mb={3} mt={5}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2" color="text.primary">
                  {webhook ? "Webhook call to your API is active." : "Add your API endpoint to receive webhook events."}
                </Typography>
                <Button
                  variant={webhook ? "outlined" : "contained"}
                  size="xs"
                  disableElevation
                  color={webhook ? "error" : "primary"}
                  component="button"
                  sx={{ minWidth: "auto" }}
                  disabled={webhookUpdating || (!webhook && (!webhookUrl?.startsWith("https://") || !(webhookUrl?.length > 8)))}
                  onClick={webhook ? deleteCurrentWebhook : addWebhook}
                >
                  {webhook ? "Remove" : "Configure"}
                  {webhookUpdating && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              </Box>
              <Box mt={3}>
                <TextField
                  margin="none"
                  variant="outlined"
                  disabled={!!webhook}
                  hiddenLabel={!!webhook}
                  required={!webhook}
                  fullWidth
                  id={`webhook-url`}
                  name="Webhook-URL"
                  type="text"
                  label="Webhook URL"
                  placeholder="https://your-webhook-url.com"
                  title={webhook ? webhook.url : webhookUrl}
                  size="small"
                  sx={{ marginRight: "40px" }}
                  value={webhook ? webhook.url : webhookUrl}
                  onChange={handleWebhookUrlChanged()}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box mt={5}>
            <Skeleton height={32} width="100%" />
          </Box>
        )}
        </Box>
          {(!webhookLogsLoaded || webhookLogs.length > 0) && (
              <Box>
              <Table className={classes.table} aria-label="caption table">
                <TableHead>
                  <TableRow>
                      <TableCell className={[classes.cell, classes.cellHead].join(" ")}>
                          <Typography variant="caption" className="small" color="text.secondary">
                            Delivery
                          </Typography>
                      </TableCell>
                      <TableCell className={[classes.cell, classes.cellHead].join(" ")}>
                          <Typography variant="caption" className="small" color="text.secondary">
                            Event
                          </Typography>
                      </TableCell>
                      <TableCell className={[classes.cell, classes.cellHead].join(" ")}>
                          <Typography variant="caption" className="small" color="text.secondary">
                            Timestamp
                          </Typography>
                      </TableCell>
                      <TableCell className={[classes.cell, classes.cellHead].join(" ")}/>
                  </TableRow>
                </TableHead>
                <TableBody>
                {!webhookLogsLoaded
                ? Array.from(new Array(3)).map((i, idx) => (
                    <TableRow key={idx}>
                      {Array.from(new Array(4)).map((i, idx) => (
                        <TableCell key={idx} sx={{ padding: "12px 0" }}>
                          <Skeleton height={20} width="80%" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                  :
                  webhookLogs.map((log: any, index: number) => (
                    <Row key={log.id} row={log} />
                  ))}
                </TableBody>
              </Table>
              {webhookLogsToken && (
                <Box pt={5} pb={isMobile ? 10 : 5} display="flex" justifyContent="center">
                  {!webhookLogsLoadingMore ? 
                    <Link variant="body2" className="small" onClick={() => handleLoadMore()} underline="always" color="text.secondary">
                      See more logs...
                    </Link>
                    :
                      <CircularProgress size={12} className={classes.buttonProgress} sx={{marginLeft: 2}} />
                    }
                </Box>
              )}
            </Box>
            )}
            {webhookLogsLoaded && webhookLogs.length === 0 && (
              <EmptySpace dataText="emptyWobhookLogs" />
            )}
      </TabPanel>
    </SupportScreenWrapper>
  );
};

function Row(props: { row: any }) {
  const { darkMode } = useGlobalContext();

  const classes = useStyles({ darkMode: darkMode });
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const mapEvent =(event: string) => {
    return event.toLowerCase().replace(/_/g, ".");
  }

  const isJson =(str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell className={[classes.cell, classes.cellBody].join(" ")} sx={{ minWidth: 100, maxWidth: 200 }}>
          {row.deliveryStatus ? <TickInCircleIcon fontSize="small" sx={{ color: "success.main" }}/> : <CloseInCircleIcon fontSize="small" color="error" /> }
        </TableCell>
        <TableCell className={[classes.cell, classes.cellBody].join(" ")} sx={{ minWidth: 100, maxWidth: 200 }}>
          <Typography variant="body2" className="small" color="text.primary">
            {mapEvent(row.event)}
          </Typography>
        </TableCell>
        <TableCell className={[classes.cell, classes.cellBody].join(" ")} sx={{ minWidth: 100, maxWidth: 200 }}>
          <Typography variant="body2" className="small" color="text.primary" align="right">
            {dayjs(row.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          {row.deliveryStatus && 
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
        </TableCell>
      </TableRow>
      {row.deliveryStatus && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 3 }}>
                <Box width="100%" overflow="auto">
                  <Typography 
                    component="span"
                    variant="body2"
                    className="strong">
                    Request
                  </Typography>
                  <Box display="flex" justifyContent="left" minWidth={200}>
                      <Typography gutterBottom component="span" fontSize="small" mr={2}>
                        URL:
                      </Typography>
                      <Typography gutterBottom component="span" fontSize="small">
                        {row.url}
                      </Typography>
                    </Box>
                    {isJson(row.requestBody) ? (
                      <ReactJson
                        name={false}
                        src={JSON.parse(row.requestBody)}
                        theme={darkMode ? "summerfruit" : "summerfruit:inverted"}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        style={{
                          backgroundColor: "transparent",
                          fontFamily:
                            "'SFMono-Regular',Consolas,'Liberation Mono', Menlo, Courier,monospace",
                          fontSize: "14px"
                        }}
                      />
                    ) : 
                    <Typography gutterBottom component="span" fontSize="small">
                      {row.requestBody}
                    </Typography>
                    }
                </Box>
                <Box textAlign="left" width="100%" overflow="auto" mt={2}>
                    <Typography 
                      component="span"
                      variant="body2"
                      className="strong">
                      Response
                    </Typography>
                    <Box display="flex" justifyContent="left">
                      <Typography gutterBottom component="span" fontSize="small" mr={2}>
                        HTTP Status Code:
                      </Typography>
                      <Typography gutterBottom component="span" fontSize="small">
                        {row.responseStatusCode}
                      </Typography>
                    </Box>
                    {isJson(row.responseBody) ? (
                      <ReactJson
                        name={false}
                        src={row.responseBody ? JSON.parse(row.responseBody) : {}}
                        theme={darkMode ? "summerfruit" : "summerfruit:inverted"}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        style={{
                          backgroundColor: "transparent",
                          fontFamily:
                            "'SFMono-Regular',Consolas,'Liberation Mono', Menlo, Courier,monospace",
                          fontSize: "14px"
                        }}
                      />
                    ) : 
                    <Typography gutterBottom component="span" fontSize="small">
                      {row.responseBody}
                    </Typography>
                  }
                  </Box>
                </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default withRouter(DeveloperSettings);
