import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, Theme } from "@mui/material";
import { CloseIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

type CloseButtonProps = {
  action: () => void;
  positionOffTop: boolean;
  positionAbsolute?: boolean;
};
type CloseButtonStyleProps = {
  isMobile: boolean;
  darkMode: boolean;
  positionAbsolute: boolean;
  positionOffTop: boolean;
};

const useStyles = makeStyles<Theme, CloseButtonStyleProps>({
  highlightedIcon: {
    padding: "8px",
    backgroundColor: ({ isMobile, darkMode }) => (isMobile ? "transparent" : darkMode ? "rgb(255, 255, 255, 0.1)" : " #F5F2F1"),
    "&:hover": {
      backgroundColor: ({ darkMode, isMobile }) => (isMobile ? "transparent" : darkMode ? "#FFF" : " #787574"),
      "& $iconHover": {
        color: ({ darkMode, isMobile }) => (darkMode ? (isMobile ? "#FFF" : "#333") : isMobile ? "#333" : "#FFF")
      }
    },
    position: ({ positionAbsolute }) => (positionAbsolute ? "absolute" : "inherit"),
    right: ({ isMobile }) => (isMobile ? "12px" : "16px"),
    top: ({ positionOffTop }) => (positionOffTop ? "68px" : "12px")
  },
  iconHover: {
    color: ({ darkMode }) => (darkMode ? "#A3A09F" : " #787574"),
    "&:hover": {
      "@media(hover: none)": {
        color: "#A3A09F!important"
      }
    }
  }
});

const CloseButton: React.FC<CloseButtonProps> = ({ action, positionOffTop, positionAbsolute = true }) => {
  const { darkMode, isMobile } = useGlobalContext();
  const classes = useStyles({
    darkMode: darkMode,
    isMobile: isMobile,
    positionOffTop: positionOffTop,
    positionAbsolute: positionAbsolute
  });

  return (
    <IconButton edge="start" onClick={() => action()} aria-label="close" className={classes.highlightedIcon} size="large">
      <CloseIcon color="disabled" classes={{ root: classes.iconHover }} />
    </IconButton>
  );
};

export default CloseButton;
