import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, FormLabel, FormControl, FormGroup, FormControlLabel, Collapse, Checkbox, IconButton, Box, Link } from "@mui/material";
import { filtersListMap, storageColorMap } from "./filterMap";
import { CheckboxTickIcon, CheckboxEmptyIcon, PlusIcon, MinusIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
    marginBottom: "16px"
  },
  formControlLabelRoot: {
    marginBottom: "12px"
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  collapseWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  boxChecked: {
    "&:hover": {
      backgroundColor: "transparent!important"
    }
  },
  label: {
    alignSelf: "flex-end"
  }
});

type FilterActionsDesktopProps = {
  filtersList: string[];
  hasTrueKeys: boolean;
  actionFilters: string[];
  onActionFilters: (filters: string[]) => void;
};

const FilterActionsDesktop: React.FC<FilterActionsDesktopProps> = ({ filtersList, hasTrueKeys, onActionFilters, actionFilters }) => {
  const [showMore, setShowMore] = React.useState(false);

  const { darkMode } = useGlobalContext();

  const classes = useStyles({
    hasTrueKeys: hasTrueKeys,
    darkMode: darkMode
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) onActionFilters([...actionFilters, event.target.name]);
    else {
      const updatedActionFilters = actionFilters.filter(item => item !== event.target.name);
      onActionFilters(updatedActionFilters);
    }
  };

  const filtersPartOne = filtersList.slice(0, 5);
  const filtersPartTwo = filtersList.slice(5, filtersList.length);

  const location = useLocation();
  const isTimeline = location.pathname.match("/timeline");
  const isChat = location.pathname.match("/chat");
  const isStorage = location.pathname.match("/storage");

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend" sx={{ marginBottom: 2 }}>
        <Typography variant="body2" className="strong" color="text.primary">
          {isTimeline ? "Action" : isChat ? "Members" : "File type"}
        </Typography>
      </FormLabel>
      <FormGroup>
        {filtersPartOne.map((filter, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                disableRipple
                icon={<CheckboxEmptyIcon />}
                checkedIcon={<CheckboxTickIcon />}
                size="small"
                classes={{ checked: classes.boxChecked }}
                className={classes.checkbox}
                checked={actionFilters.includes(filter)}
                onChange={handleChange}
                name={filter}
              />
            }
            label={
              <Box display="flex" alignItems="center">
                {isStorage && (
                  <Box sx={{ width: "7px", height: "7px", backgroundColor: storageColorMap[filter], borderRadius: "50%" }} mr={2} />
                )}
                <Typography variant="body2" className="small" sx={{ lineHeight: "1rem", paddingTop: "2px" }}>
                  {filtersListMap[filter] || (filter?.split("/")[1] && filter?.split("/")[1].toUpperCase()) || filter}
                </Typography>
              </Box>
            }
            classes={{ label: classes.label }}
            className={classes.formControlLabelRoot}
          />
        ))}

        {filtersPartTwo.length > 0 && (
          <>
            <Collapse in={showMore} timeout="auto" unmountOnExit classes={{ wrapperInner: classes.collapseWrapper }}>
              {filtersPartTwo.map((filter, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        disableRipple
                        icon={<CheckboxEmptyIcon />}
                        checkedIcon={<CheckboxTickIcon />}
                        size="small"
                        classes={{ checked: classes.boxChecked }}
                        className={classes.checkbox}
                        checked={actionFilters.includes(filter)}
                        onChange={handleChange}
                        name={filter}
                      />
                    }
                    label={
                      <Typography variant="body2" className="small" sx={{ lineHeight: "1rem" }}>
                        {filtersListMap[filter] || (filter.split("/")[1] && filter.split("/")[1].toUpperCase()) || filter}
                      </Typography>
                    }
                    classes={{ label: classes.label }}
                    className={classes.formControlLabelRoot}
                  />
                );
              })}
            </Collapse>
            <Box onClick={() => setShowMore(!showMore)}>
              <IconButton size="large">
                {showMore ? <MinusIcon fontSize="small" sx={{ marginRight: 2 }} /> : <PlusIcon fontSize="small" sx={{ marginRight: 2 }} />}
              </IconButton>
              <Link underline="none" variant="body2" className="small" color="text.secondary">
                {showMore ? "Show fewer" : "Show more"}
              </Link>
            </Box>
          </>
        )}
      </FormGroup>
    </FormControl>
  );
};

export default FilterActionsDesktop;
