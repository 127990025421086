type InfoModalDataProps = {
  [key: string]: InfoModalDataValuesProps;
};

type InfoModalDataValuesProps = {
  modalTitle: string;
  modalDescription: string;
  modalListTexts?: ModalListTextsProps[];
  linkedData?: LinkedDataProps;
};

type ModalListTextsProps = {
  boldText: string;
  listText: string;
};
type LinkedDataProps = {
  linkedText: string;
  linkedUrl: string;
};

export const infoModalData: InfoModalDataProps = {
  accessFee: {
    modalTitle: "Access",
    modalDescription:
      "If no access fee is specified, by default access to the data is free. If a value is specified, then the currency can be specified in the field below. By default the currency will be the $U token.",
  },
  unknownUsageRights: {
    modalTitle: "Unknown rights",
    modalDescription:
      "Including unknown rights means that if there are new digital formats, types of products, emerging technologies and developments of this nature, then the usage rights will be included. For example, in the future there may be a new way to display digital art that wasn't known when you this agreement was committed on-chain. If 'Unknown Rights' are included, the company or individual might automatically have the right to use your artwork in this new format.",
  },
  derivations: {
    modalTitle: "Derivations",
    modalDescription:
      "Derivations grant the right to create derivative works, and they are not granted by default. There are several grant options:",
    modalListTexts: [
      {
        boldText: "With Credit:",
        listText:
          "grants derivation rights, but only if the creator receives named credit",
      },
      {
        boldText: "With Indication:",
        listText:
          "grants derivation rights, but with the requirement that the derived work indicates the source content. This indication helps identify the source, different from the 'Credit' option that names the creator",
      },
      {
        boldText: "With License Passthrough:",
        listText:
          "grants derivation rights, but only if the license parameters of the original content are preserved in the derivative work",
      },
      {
        boldText: "With Revenue Share:",
        listText:
          "you can allow derivations if the derived content contributes a portion of its revenue to the content owner. You can also define a percentage for this revenue share",
      },
      {
        boldText: "With One-Time Fee:",
        listText:
          "you allow derivations only with a one-time payment. The currency is specified in a later field, and by default is the $U token",
      },
      {
        boldText: "With Monthly Fee:",
        listText:
          "you allow derivations only with a monthly payment. The currency is specified in a later field, and by default is the $U token",
      },
    ],
    linkedData: {
      linkedText: "Read more on the Arweave wiki",
      linkedUrl:
        "https://arwiki.wiki/#/en/Universal-Data-License-How-to-use-it#toc_Derivations",
    },
  },
  commercialUse: {
    modalTitle: "Commercial use",
    modalDescription:
      "Commercial use is not granted by default, and there are two grant options:",
    modalListTexts: [
      {
        boldText: "Allowed:",
        listText:
          "grant licensees the right to use the content for commercial purposes",
      },
      {
        boldText: "Allowed With Credit:",
        listText:
          "grant licensees the right to use the content for commercial purposes, but only if creator credit is given",
      },
      {
        boldText: "With Revenue Share:",
        listText:
          "commercial Use is only allowed with revenue sharing and discretionary percentage",
      },
      {
        boldText: "With One-Time Fee:",
        listText:
          "you allow commercial use only with a one-time payment. The currency is specified in a later field, and by default is the $U token",
      },
      {
        boldText: "With Monthly Fee:",
        listText:
          "you allow commercial use only with a monthly payment. The currency is specified in a later field, and by default is the $U token.",
      },
    ],
    linkedData: {
      linkedText: "Read more on the Arweave wiki",
      linkedUrl:
        "https://arwiki.wiki/#/en/Universal-Data-License-How-to-use-it#toc_Commercial_Use",
    },
  },
  dataModelTrainings: {
    modalTitle: "Data Model Training",
    modalDescription:
      "By default usage for data model training is not allowed. If it is allowed there are the following options.",
    modalListTexts: [
      {
        boldText: "With One-Time Fee:",
        listText:
          "you allow data model training use only with a one-time payment. The currency is specified in a later field, and by default is the $U token.",
      },
      {
        boldText: "With Monthly Fee:",
        listText:
          "you allow data model training with a monthly payment. The currency is specified in a later field, and by default is the $U token.",
      },
    ],
  },
  currency: {
    modalTitle: "Currency",
    modalDescription:
      "Currency paramater specifies the denomination or type of currency in which payment should be made. It is crucial to ensure that the chosen currency aligns with the owner address(es) associated with the asset. There are two scenarios:",
    modalListTexts: [
      {
        boldText: "Currency Not Provided:",
        listText:
          "if the currency parameter is not present, the default currency for payment is assumed to be the $U token on the Arweave network. In this case, the owner address(es) should be Arweave network addresses, and payments will be made in $U tokens unless specified otherwise",
      },
      {
        boldText: "Currency Provided:",
        listText:
          "if the currency parameter is present, it overrides the default currency. This means that the payment terms, such as license fees, should be paid in the specified currency, and the corresponding owner addresses should be compatible with that currency. Example: 'Currency': 'AR' overrides the default $U token as the currency and denominates the payment terms in AR token",
      },
    ],
    linkedData: {
      linkedText: "Read more on the Arweave wiki",
      linkedUrl:
        "https://arwiki.wiki/#/en/Universal-Data-License-How-to-use-it#toc_Currency_Parameter",
    },
  },
  duration: {
    modalTitle: "Duration",
    modalDescription:
      "The duration parameter is designated in years and allows you to indicate the duration for which the license terms are valid",
    linkedData: {
      linkedText: "Read more on the Arweave wiki",
      linkedUrl:
        "https://arwiki.wiki/#/en/Universal-Data-License-How-to-use-it#toc_License_Rights_Duration(s)",
    },
  },
  paymentAddress: {
    modalTitle: "Payment address",
    modalDescription:
      "The UDL supports a variety of payment methods and means of indicating which owner addresses should receive payments:",
    modalListTexts: [
      {
        boldText: "",
        listText:
          "By default, if no payment address parameter is present, the address to receive payment is the one that signed the transaction that posted the content to Arweave",
      },
      {
        boldText: "",
        listText:
          "if payment address parameter is present, it overrides the default payment recipient. This allows you to specify a separate Arweave wallet address for receiving payments, different from the address used to post the content transaction",
      },
      {
        boldText: "",
        listText:
          "Additionally, when a smart contract is attached to the content transaction, the contract&apos;s tags are considered authoritative regarding the current ownership status, including proportional ownership if the contract is tokenized",
      },
    ],
    linkedData: {
      linkedText: "Read more on the Arweave wiki",
      linkedUrl:
        "https://arwiki.wiki/#/en/Universal-Data-License-How-to-use-it#toc_Payment_Addresses",
    },
  },
  paymentMode: {
    modalTitle: "Payment mode",
    modalDescription:
      "The payment mode parameter is used to specify how license fees and revenue shares are distributed when a smart contract is attached to the content transaction, and there are multiple owner addresses with claims to the funds. The two available payment modes are:",
    modalListTexts: [
      {
        boldText: "Random Distribution:",
        listText:
          "a random address is selected from the attached contract, and that address is paid the full fee due. This randomness should ideally result in a pro rata distribution based on the portion of the asset each address owns. The contract may define a distribution function that the licensee can invoke",
      },
      {
        boldText: "Global Distribution:",
        listText:
          "the fee due is split up proportionally among the ownership stakes of each address in the content. An individual payment is made to each address based on the allocated amount. The contract may define a distribution function that the licensee can invoke",
      },
    ],
    linkedData: {
      linkedText: "Read more on the Arweave wiki",
      linkedUrl:
        "https://arwiki.wiki/#/en/Universal-Data-License-How-to-use-it#toc_Payment_Mode",
    },
  },
};
