import { UDL } from "@akord/akord-js";
import { Box, Typography, OutlinedInput, FormHelperText } from "@mui/material";
import * as React from "react";

type OutlinedInputWithErrorAndDescriptionProps = {
  errorFields: React.MutableRefObject<string[]>;
  onFormChange: (prop: keyof UDL) => (event: any) => void;
  udlField: keyof UDL;
  subFields: string;
  defaultValue: any;
  percentageInput?: boolean;
};

const OutlinedInputWithErrorAndDescription: React.FC<OutlinedInputWithErrorAndDescriptionProps> = ({
  errorFields,
  onFormChange,
  udlField,
  subFields,
  defaultValue,
  percentageInput = false
}) => {
  return (
    <Box mt={5}>
      <Typography variant="body2" className="small" sx={{ mb: 3 }}>
        {percentageInput ? "Enter a number 1–100 to indicate the percentage share:" : "Enter a number to indicate the fee:"}
      </Typography>
      <OutlinedInput
        error={errorFields.current.includes(udlField.concat(".", subFields))}
        defaultValue={defaultValue || "None"}
        id={udlField.concat("-", subFields)}
        name={subFields}
        type="number"
        required
        placeholder="Fee value"
        inputProps={percentageInput ? { min: 1, max: 100 } : { min: 1 }}
        onChange={onFormChange(udlField)}
        sx={{ width: "140px" }}
      />
      {errorFields.current.includes(udlField.concat(".", subFields)) && (
        <FormHelperText error id={udlField.concat("-", subFields, "-error")}>
          Enter a number
        </FormHelperText>
      )}
    </Box>
  );
};

export default OutlinedInputWithErrorAndDescription;
