import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputAdornment,
  Link,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import FormSubmit from "../FormSubmit";
import { useFormModalOptions } from "./formModalOptions";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { FormModelType } from "../../../hooks/useModal";

type FormModalStyleProps = {
  isWide: boolean;
  isDesktop: boolean;
};

const useStyles = makeStyles<Theme, FormModalStyleProps>({
  dialogTitle: {
    display: "flex"
  },
  textField: {
    marginBottom: 0
  },
  extraTextField: {
    marginBottom: 16
  },
  root: {
    height: "48px!important",
    fontSize: ({ isWide }) => (isWide ? "0.9375rem" : "inherit")
  },
  icon: {
    marginRight: "8px"
  },
  button: {
    minWidth: "auto",
    marginLeft: ({ isDesktop }) => (isDesktop ? "24px!important" : "0!important"),
    "&:last-child": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : "16px")
    }
  },
  paper: {
    margin: "128px 16px",
    maxWidth: ({ isWide }) => (isWide ? "480px" : "400px")
  },
  buttonProgress: {
    position: "absolute"
  }
});

const shareCases = ["shareFile", "shareVault", "shareNft"];

function FormModal() {
  const [validationError, setValidationError] = React.useState(false);
  const [extraInputValidationError, setExtraInputValidationError] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const { onSnackbarToShow } = useSnackbarContext();
  const { vault } = useVaultContext();
  const { modal, notificationData, onNotificationData } = useNotificationsContext();
  const { options } = useFormModalOptions();
  const { isMobile } = useGlobalContext();

  const modalType = modal.confirmModalFormType! as FormModelType;

  const {
    title,
    secondaryText,
    placeholder,
    helperJSXElement,
    nextButton,
    isWide = false,
    isDataValid,
    validationErrorText,
    icon: MenuIcon,
    extraInput = false,
    extraInputPlaceholder,
    extraInputValidationErrorText,
    isExtraDataValid
  } = options[modalType];

  const classes = useStyles({ isDesktop: !isMobile, isWide: isWide });

  const handleClose = () => {
    modal.onModalVisibility(null, modalType);
    onNotificationData(null);
    setDisabledButton(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNotificationData({ ...notificationData, dataRoomId: vault.id, [event.target.name]: event.target.value });

    // Checks extra field (u amount only)
    if (isExtraDataValid && event.target.name === "hash") {
      if (isExtraDataValid(event.target.value)) {
        setExtraInputValidationError(false);
        // If its an extra field, check if the other is filled and checked
        if (notificationData.title && isDataValid && isDataValid(notificationData.title)) setDisabledButton(false);
      } else if (!event.target.value) {
        setExtraInputValidationError(false);
        setDisabledButton(true);
      } else {
        setExtraInputValidationError(true);
        setDisabledButton(true);
      }
    }
    // Checks main field (ar address and ar tx length)
    if (isDataValid && event.target.name === "title") {
      if (isDataValid(event.target.value)) {
        setValidationError(false);
        // If an extra field present activate button if both checks are passed
        if (notificationData.hash && isExtraDataValid && isExtraDataValid(notificationData.hash)) setDisabledButton(false);
        if (!isExtraDataValid) setDisabledButton(false);
      } else if (!event.target.value) {
        setValidationError(false);
        setDisabledButton(true);
      } else {
        setValidationError(true);
        setDisabledButton(true);
      }
    }
    // all other cases
    if (!isDataValid && !isExtraDataValid) {
      setDisabledButton(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!shareCases.includes(modalType)) {
        setDisabledButton(true);
        setLoading(true);
        await options[modalType].action();
        modal.onModalVisibility(null, modalType);
        onSnackbarToShow(modalType);
        setLoading(false);
      } else {
        await options[modalType].action();
        modal.onModalVisibility(null, modalType);
        onSnackbarToShow(modalType);
      }
      onNotificationData(null);
    } catch (err) {
      console.log("Error: ", err);
      modal.onModalVisibility(null, modalType);
      //TODO better check if exists on union type
      if (modalType === "importArweave") onSnackbarToShow(`${modalType}Error`, null, "error");
      setLoading(false);
      onNotificationData(null);
    }
  };

  const handleMaxAmount = () => {
    // When MAX amount used - provide exact amount that in the wallet, without rounding
    const uBalanceDecimal = notificationData.uBalance ? notificationData.uBalance / Math.pow(10, 6) : 0;
    setExtraInputValidationError(false);
    if (notificationData.title && isDataValid && isDataValid(notificationData.title)) setDisabledButton(false);
    onNotificationData({ ...notificationData, hash: uBalanceDecimal.toString() });
  };

  return (
    <Dialog
      open={modal.isModalVisible[modalType]}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      classes={{
        paper: classes.paper
      }}
      TransitionProps={{
        onEntered: () => {
          (document?.getElementById(extraInput ? "hash" : "title") as HTMLInputElement).select();
        }
      }}
    >
      <FormSubmit
        onSubmit={async () => {
          await handleSubmit();
        }}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <MenuIcon fontSize="medium" color="disabled" className={classes.icon} />
          <Typography className="strong" color="text.primary">
            {title}
          </Typography>
        </DialogTitle>
        {secondaryText && (
          <DialogContent>
            <Typography variant="body2" color="text.tertiary" style={{ whiteSpace: "pre-line" }} noWrap>
              {secondaryText}
            </Typography>
          </DialogContent>
        )}
        <DialogContent style={{ overflowY: "initial" }}>
          {extraInput && (
            <Box mb={5}>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                id="hash"
                name="hash"
                value={notificationData.hash || ""}
                placeholder={extraInputPlaceholder}
                type="text"
                className={classes.textField}
                InputProps={{
                  readOnly: shareCases.includes(modalType),
                  classes: {
                    root: classes.root
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Link variant="caption" color="text.tertiary" onClick={handleMaxAmount}>
                        MAX
                      </Link>
                    </InputAdornment>
                  )
                }}
                onChange={handleChange}
                error={extraInputValidationError}
              />
              {extraInputValidationError && extraInputValidationErrorText && (
                <FormHelperText sx={{ margin: "8px 0 0" }}>
                  <Typography variant="caption" color="error.main">
                    {extraInputValidationErrorText}
                  </Typography>
                </FormHelperText>
              )}
            </Box>
          )}
          <TextField
            autoFocus={!extraInput}
            fullWidth
            variant="outlined"
            id="title"
            name="title"
            value={notificationData.title || ""}
            placeholder={placeholder}
            type="text"
            className={classes.textField}
            InputProps={{
              readOnly: shareCases.includes(modalType),
              classes: {
                root: classes.root
              }
            }}
            onChange={handleChange}
            error={validationError}
          />
          {validationError && validationErrorText && (
            <FormHelperText sx={{ margin: "8px 0 0" }}>
              <Typography variant="caption" color="error.main">
                {validationErrorText}
              </Typography>
            </FormHelperText>
          )}
          {helperJSXElement && (
            <Box display="flex" mt={2}>
              {helperJSXElement()}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ flexDirection: isMobile ? "column-reverse" : "row" }}>
          <Button
            onClick={handleClose}
            color="primary"
            // label="cancel"
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth={isMobile ? true : false}
            disabled={disabledButton && !shareCases.includes(modalType)}
            type="submit"
            // label="submit"
            disableElevation
            className={classes.button}
          >
            {nextButton}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </DialogActions>
      </FormSubmit>
    </Dialog>
  );
}

export default FormModal;
