import React, { useEffect } from "react";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Card, CardContent, FormControl, FormGroup, FormControlLabel, Theme } from "@mui/material";
import { ButtonGroup, StandAloneScreenWrapper, AkordCheckbox } from "../../components/common";
import createLinkDecorator from "../timeline/timeline-actions/LinkDecorator";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { grey } from "../../theme/colors";
import LoadingScreen from "../../components/common/LoadingScreen";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Membership, Vault, RoleType } from "@akord/akord-js";

type AcceptInviteStyleProps = {
  darkMode: boolean;
};

type TermsDataProps = {
  hasText: boolean;
  editorState: EditorState;
};

const useStyles = makeStyles<Theme, AcceptInviteStyleProps>({
  card: {
    background: ({ darkMode }) => (darkMode ? grey[800] : grey[100]),
    border: "none"
  },
  cardContent: {
    padding: 16,
    "&:last-child": {
      paddingBottom: 16
    }
  },
  termsLabel: {
    color: ({ darkMode }) => (darkMode ? grey[300] : grey[600])
  }
});

const AcceptInvite: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { akord, isMobile, darkMode, onGetVaults } = useGlobalContext();

  const classes = useStyles({ darkMode: darkMode });

  const [acceptTermsCheck, setAcceptTermsCheck] = React.useState(false);
  const [membership, setMemebership] = React.useState<Membership>();
  const [vault, setVault] = React.useState<Vault>();
  const [termsData, setTermsData] = React.useState<TermsDataProps>({} as TermsDataProps);
  const [loading, setLoading] = React.useState(false);
  const [rejectLoading, setRejectLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  useEffect(() => {
    if (!location.search) {
      history.push("/");
    }
    const getMembership = async () => {
      try {
        const { membershipId } = queryString.parse(location.search);
        if (!membershipId || typeof membershipId !== "string") return;
        const membership = await akord?.membership.get(membershipId);

        if (!membership) return;
        const vault = await akord?.vault.get(membership.vaultId);

        //if a user clicks on invitation link after was accepted
        if (membership && membership.status === "ACCEPTED") history.push(`/vaults/active/${membership.vaultId}/assets`);

        //if a user clicks on invitation link after was revoked
        if (membership && membership.status === "REVOKED")
          history.push({
            pathname: "/reject-invite",
            state: { from: "REVOKED" }
          });

        //if a user clicks on invitation link after they rejected
        //TODO check if still have usecase
        // if (membership && membership.status === "REJECTED")
        //   history.push({
        //     pathname: "/reject-invite",
        //     state: { from: "REJECTED" }
        //   });

        setMemebership(membership);
        setVault(vault);
      } catch (err) {
        console.log("Error fetching Membership: ", err);
      }
    };
    if (akord) getMembership();
  }, [akord, location.search]);

  useEffect(() => {
    if (membership && vault) {
      // const termsOfAccess = membership.state.termsOfAccess
      //   ? base64ToJson(membership.state.termsOfAccess)
      //   : { termsOfAccess: null, hasTerms: false };
      //auto-accept invite if no terms of access
      if (!vault.termsOfAccess) {
        acceptInvite();
        return;
      }

      const decorator = createLinkDecorator();
      try {
        const contentState = convertFromRaw(JSON.parse(vault.termsOfAccess));
        // const contentState = convertFromRaw(
        //   JSON.parse(data.membershipReview.termsOfAccess)
        // )
        const editorState = EditorState.createWithContent(contentState, decorator);
        const hasText = editorState.getCurrentContent().hasText();
        setTermsData({ hasText: hasText, editorState: editorState });
      } catch (error) {
        // ignore terms in insupported format
        acceptInvite();
        return;
      }
    }
  }, [membership, vault]);

  const getRoleText = (role: RoleType) => {
    switch (role) {
      case "CONTRIBUTOR":
        return (
          <>
            <Typography variant="body2" color="text.primary" className="strong">
              Contribution permissons
            </Typography>
            <Typography variant="body2">You can upload files, request data and delete files.</Typography>
          </>
        );
      case "VIEWER":
        return (
          <>
            <Typography variant="body2" color="text.primary" className="strong">
              View only permissons
            </Typography>
            <Typography variant="body2">You can view and download content in the vault.</Typography>
          </>
        );

      default:
        return (
          <Typography variant="body2" color="text.primary" className="strong">
            No role has been set.
          </Typography>
        );
    }
  };

  const acceptInvite = async () => {
    try {
      if (!membership) return;
      setLoading(true);
      await akord?.membership.accept(membership.id);
      //refetch all the vaults
      //TODO: Should we fetch just one vault by id, instead of refetching all the vaults
      onGetVaults();
      setLoading(false);
      history.push(`/vaults/active/${membership.vaultId}/assets`);
    } catch (err) {
      console.log("Failed to accept terms: ", err);
      setLoading(false);
      setError("Something went wrong!");
      history.push(`/vaults/active`);
    }
  };

  const rejectTerms = async () => {
    try {
      if (!membership) return;
      setRejectLoading(true);
      await akord?.membership.reject(membership.id);
      setRejectLoading(false);
      history.push("/reject-invite");
    } catch (err) {
      console.log("Failed to reject terms: ", err);
      history.push(`/vaults/active`);
    }
  };

  // TODO Model is different - check how it is supposed to work
  // const membershipOwnerDetails = membership?.dataRoom?.members?.items?.filter(member => {
  //   return member.state.role === "OWNER";
  // })[0].state.memberDetails;

  if (!membership) return <LoadingScreen />;
  // auto-accept the invite
  if (!termsData.hasText) return <LoadingScreen />;

  return (
    <StandAloneScreenWrapper title={termsData.hasText ? "Terms of access" : "Invitation"} route="/">
      <Box
        className={classes.box}
        height={termsData.hasText ? "90%" : "auto"}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box height="88%">
          <Box mb={6}>
            <Card square className={classes.card}>
              <CardContent className={classes.cardContent}>{getRoleText(membership.role)}</CardContent>
            </Card>
          </Box>
          {termsData.hasText ? (
            <Box mb={8} maxHeight={isMobile ? "65%" : "75%"} overflow="auto">
              <Editor editorState={termsData.editorState} readOnly={true} onChange={() => null} />
            </Box>
          ) : (
            <Box>
              {/* // TODO Model is different - check how it is supposed to work - was using membershipOwnerDetails.email before */}
              <Typography variant="body2">You have been invited by {membership.email} to their vault.</Typography>
            </Box>
          )}
        </Box>
        {termsData.hasText && (
          <Box>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  style={{ alignItems: "normal" }}
                  classes={{ label: classes.termsLabel }}
                  control={
                    <AkordCheckbox
                      inputProps={{ "aria-label": "acceptTerms" }}
                      checked={acceptTermsCheck}
                      onChange={() => setAcceptTermsCheck(!acceptTermsCheck)}
                      name="acceptTerms"
                    />
                  }
                  label="By checking this box you are agreeing to digitally sign and accept the terms."
                />
              </FormGroup>
            </FormControl>
          </Box>
        )}
      </Box>
      <ButtonGroup
        noMinWidth={true}
        errorText={error}
        nextDiasabled={(!acceptTermsCheck && termsData.hasText) || loading}
        handleNext={() => acceptInvite()}
        handleBack={() => rejectTerms()}
        disableBackButton={rejectLoading}
        nextText={termsData.hasText ? "Accept terms" : "Accept invitation"}
        backText="Reject terms"
        backButtonOutlined={true}
        hideBackButton={false}
        fullWidthNextButton={false}
        hideIcon={true}
        showButtonInColumn={!termsData.hasText}
        loading={loading}
        backLoading={rejectLoading}
      />
    </StandAloneScreenWrapper>
  );
};

export default withRouter(AcceptInvite);
