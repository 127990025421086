import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowRightIcon, BellIcon, CloseIcon, InfoIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import { Types } from "@akord/gql";
import { Props } from "@akord/addon-icons/lib/components/Icon";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

type PriorityNotificationProps = {
  notification?: Types.Notification;
};

type PrioratyNotificationsDataProps = {
  Icon: (props: Props) => React.ReactElement<any, any>;
  actionText: string;
  text: string;
  action?: () => void;
};

const PriorityNotification: React.FC<PriorityNotificationProps> = ({ notification }) => {
  const history = useHistory();
  const { isMobile } = useGlobalContext();
  const { onRead } = useInAppNotificationsContext();

  type NotificationEvents = "USER_VERIFIED" | "SERIOUS_ISSUE";

  const prioratyNotificationsData: { [Event in NotificationEvents]: PrioratyNotificationsDataProps } = {
    USER_VERIFIED: {
      Icon: BellIcon,
      actionText: "Action required!",
      text: "Confirm access to someone you invited",
      action: () => {
        onRead({ id: notification?.id });
        history.push(`/confirm/?membershipId=${notification?.objectId}`);
      }
    },
    SERIOUS_ISSUE: {
      Icon: InfoIcon,
      actionText: "We’re having some issues. ",
      text: "You may find some features in the app are not working. Don’t worry, we’re on it!"
    }
  };

  const handleClosePriorityNotification = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRead({ id: notification?.id });
  };

  const { event } = notification as Types.Notification;

  if (!event || !prioratyNotificationsData[event as NotificationEvents]) return null;

  const { Icon: PriorityIcon, actionText, text, action } = prioratyNotificationsData[event as NotificationEvents];
  const isSeriousIssue = event === "SERIOUS_ISSUE";
  return (
    <Box
      position="fixed"
      key={notification?.id}
      width={isMobile ? "100%" : "calc(100% - 250px)"}
      style={{
        top: isMobile ? "44px" : 0
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        mx={5}
        mt={4}
        component="div"
        onClick={action && action}
        sx={{
          backgroundColor: "background.secondary",
          padding: "4px",
          borderRadius: "6px",
          border: "1px solid",
          borderColor: isSeriousIssue ? "secondary.main" : "background.cardBorder",
          transition: "0.3s ease",
          cursor: isSeriousIssue ? "auto" : "pointer",
          "&:hover": {
            borderColor: "secondary.main"
          }
        }}
      >
        <Box display="flex">
          <PriorityIcon fontSize="small" color="warning" sx={{ marginRight: "4px" }} />
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Typography variant="body2" className={["small", "strong"].join(" ")} color="primary.light" sx={{ whiteSpace: "pre" }}>
              {actionText}{" "}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" className="small">
                {text}
              </Typography>
              {action && <ArrowRightIcon color="info" sx={{ fontSize: "14px", marginLeft: "4px" }} />}
            </Box>
          </Box>
        </Box>
        {action && (
          <IconButton onClick={e => handleClosePriorityNotification(e)} sx={{ zIndex: 100 }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default PriorityNotification;
