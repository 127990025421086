import React from "react";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { RoleType } from "@akord/akord-js";
import { useVaultContext } from "../../contexts/VaultContextProvider";

type DropZoneProps = {
  userRole: RoleType;
};

const DropZone: React.FC<DropZoneProps> = ({ children, userRole }) => {
  const [dragedOver, setDragedOver] = React.useState(false);

  const { readOnlyMode } = useGlobalContext();
  const { onUploadFiles, uploadEventRef } = useNotificationsContext();
  const { isVaultPublic } = useVaultContext();

  const handleOnDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);

    const dtItemList: DataTransferItemList = event.dataTransfer.items;
    // creating [DirectoryEntry] so we can convert it later in UploadModalContent.js
    const directoryEntry = [...dtItemList]
      .map(item => (item.webkitGetAsEntry ? item.webkitGetAsEntry() : null))
      .filter((entry): entry is FileSystemEntry => !!entry);
    onUploadFiles(directoryEntry);
    setDragedOver(false);
    dtItemList.clear();
    uploadEventRef.current = event;
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(false);
  };
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(true);
  };

  const stopEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return userRole !== "VIEWER" && !readOnlyMode ? (
    <div
      onDrop={handleOnDrop}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      style={{
        background: dragedOver ? (isVaultPublic ? "rgb(29, 39, 114, 0.5)" : "rgb(219, 64, 15, 0.2)") : "inherit",
        height: "100%",
        overflowY: "auto",
        padding: "3px"
      }}
      className="dropzone"
    >
      {children}
    </div>
  ) : (
    <div style={{ height: "100%", padding: "3px" }}>{children}</div>
  );
};

export default DropZone;
