import { Box } from "@mui/material";
import React from "react";
import { useOrgContext } from "../../contexts/OrgContextProvider";

const LoadingScreen: React.FC = () => {
  const { org } = useOrgContext();

  return (
    <Box
      sx={{
        backgroundImage: org?.name ? "url(/img/loader.gif)" : "url(/img/branded-loader-dm.gif)",
        backgroundRepeat: "no-repeat",
        width: "75px",
        height: "75px",
        position: "absolute",
        backgroundSize: "contain",
        top: "calc(50% - 4em)",
        left: "calc(50% - 2.5em)",
        transition: "display 0.2s",
        display: "block"
      }}
    />
  );
};

export default LoadingScreen;
