import React, { useState, useEffect } from "react";
import { Stack } from "@akord/akord-js";
import { useStackMenuOptions } from "./MenuOptions";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import MoreMenuRoot from "./MoreMenuRoot";
import MoreMenuItem from "./MoreMenuItem";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";

type MenuStackProps = {
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  handleMenuClose: () => void;
  openMenu: boolean;
  stackId?: string;
  vaultId?: string;
  passedStack?: Stack;
};

const MenuStack: React.FC<MenuStackProps> = ({ anchorEl, handleMenuClose, openMenu, stackId = "", vaultId = "", passedStack }) => {
  const { akord } = useGlobalContext();
  const {
    // onCurrentStack,
    onVault
  } = useVaultContext();
  const { options: stackOptions } = useStackMenuOptions();
  const [stack, setStack] = useState<Stack>();

  useEffect(() => {
    const loadData = async () => {
      const stack = await akord!.stack.get(stackId);

      setStack(stack);
      // onCurrentStack(stack);
      if (vaultId) {
        const vault = await akord!.vault.get(vaultId);
        onVault(vault);
      }
    };
    if (!stack && stackId && akord) {
      loadData();
    } else {
      setStack(passedStack);
    }
  }, [akord]);

  return (
    <MoreMenuRoot anchorEl={anchorEl} handleMenuClose={handleMenuClose} openMenu={openMenu} id="file-menu">
      <div>
        {stackOptions(stack, handleMenuClose)?.map((menuItem, index) => {
          return menuItem.show && <MoreMenuItem key={index} menuItem={menuItem} />;
        })}
      </div>
    </MoreMenuRoot>
  );
};

export default MenuStack;
