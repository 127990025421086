import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip, TableRow, TableCell, Box, Checkbox, Typography, IconButton } from "@mui/material";
import { FolderIcon, TickIcon } from "@akord/addon-icons";
import Skeleton from "@mui/material/Skeleton";
import { Folder } from "@akord/akord-js";
import { formatDate, getVaultStatus, getVaultId } from "../../helpers/helpers";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { FolderDrawer } from "../../components/common";
import MoreActionButton from "../../components/common/MoreActionButton";
import MenuFolder from "../../components/common/MoreMenu/MenuFolder";
import { bytesToGb } from "../storage/storage-helper";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { grey } from "../../theme/colors";

type FolderItemStyleProps = {
  archived: boolean;
  itemChecked: boolean;
  iconHovered: boolean;
  isVaultPublic: boolean;
};

const useStyles = makeStyles<Theme, FolderItemStyleProps>(theme => ({
  iconBackground: {
    boxSizing: "content-box",
    marginRight: "15px",
    borderRadius: "2px",
    background: ({ archived, itemChecked, iconHovered, isVaultPublic }) =>
      archived
        ? itemChecked
          ? grey[400]
          : iconHovered
          ? theme.palette.background.archived
          : "transparent"
        : isVaultPublic
        ? itemChecked
          ? theme.palette.info.light
          : iconHovered
          ? theme.palette.background.stack
          : "transparent"
        : itemChecked
        ? theme.palette.primary.light
        : iconHovered
        ? theme.palette.background.active
        : "transparent",
    color: ({ archived, itemChecked, iconHovered, isVaultPublic }) =>
      archived
        ? itemChecked
          ? "#FFF"
          : iconHovered
          ? grey[500]
          : grey[400]
        : isVaultPublic
        ? itemChecked
          ? "#FFF"
          : iconHovered
          ? theme.palette.info.main
          : theme.palette.info.light
        : itemChecked
        ? "#FFF"
        : iconHovered
        ? theme.palette.primary.main
        : theme.palette.primary.light
  },
  toolLine: {
    display: "flex",
    justifyContent: "space-between",
    height: "56px",
    alignItems: "center"
  },
  checkBox: {
    padding: 0
  }
}));

const columnsToShow = (
  folder: Folder,
  width?: "sm" | "xs" | "md" | "lg" | "xl",
  folderSizeToShow?: {
    size: number;
    sizeType: string;
  }
) => {
  switch (true) {
    case width === "xs":
      return null;
    case !!folder:
      switch (true) {
        case width === "sm":
          return (
            <TableCell align="left">
              <Typography variant="body2" color="text.primary" noWrap>
                {formatDate(folder.updatedAt || folder.modifiedAt)}
              </Typography>
            </TableCell>
          );
        default:
          return (
            <>
              <TableCell align="left">
                <Typography variant="body2" color="text.primary" noWrap>
                  {formatDate(folder.updatedAt || folder.modifiedAt)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                {folderSizeToShow?.size ? folderSizeToShow.size + folderSizeToShow.sizeType.toUpperCase() : "-"}
              </TableCell>
              <TableCell align="left"></TableCell>
            </>
          );
      }
    default:
      return (
        <>
          <TableCell>
            <Skeleton style={{ height: "30px" }} />
          </TableCell>
          {width === "sm" ? (
            <TableCell />
          ) : (
            <TableCell>
              <Skeleton style={{ height: "30px" }} />
            </TableCell>
          )}
        </>
      );
  }
};

interface LocationState extends Location {
  itemId?: string;
}

type FolderItemProps = {
  folder: Folder;
  revoked?: boolean;
  isPublicRoute?: boolean;
  forwardRef: (ref: HTMLElement) => void;
  forwardProps: any;
  forwardStyle?: {};
  forceHighlight: boolean;
};

const FolderItem: React.FC<FolderItemProps> = ({
  folder,
  revoked = false,
  isPublicRoute = false,
  forwardRef,
  forwardProps,
  forwardStyle,
  forceHighlight
}) => {
  const { width } = useGlobalContext();
  const { isVaultPublic, isRoomArchived } = useVaultContext();
  const { onSelectedItems, onLastSelectedItem, selectedItemsMap } = useAssetsContext();

  const location = useLocation<LocationState>();
  const history = useHistory();
  const theme = useTheme();
  const itemRef = useRef<HTMLElement | null>(null);
  const itemId = location.state?.itemId;

  const itemChecked = selectedItemsMap?.has(folder?.id);

  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const openMenuFolder = Boolean(anchorEl);

  const [iconHovered, setIconHovered] = React.useState(false);
  const handleIconHovered = (action: boolean) => setIconHovered(action);

  useEffect(() => {
    if (itemId && folder && itemId === folder.id && itemRef?.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
  }, [folder, itemRef.current]);

  useEffect(() => {
    if (folder && forceHighlight) {
      hightlightStyle();
    } else {
      defaultStyle();
    }
  }, [folder, forceHighlight]);

  const setFolderRef = (element: HTMLElement) => {
    itemRef.current = element;
    if (forwardRef) {
      forwardRef(element);
    }
  };

  const highlightItem = () => {
    hightlightStyle();
    setTimeout(() => {
      defaultStyle();
    }, 1000);
  };

  const hightlightStyle = () => {
    if (itemRef?.current) {
      const item = itemRef.current;
      item.style.transition = "background-color 0.5s ease-in";
      item.style.background = theme.palette.background.stack!;
    }
  };

  const defaultStyle = () => {
    if (itemRef?.current) itemRef.current.style.background = "inherit";
  };

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  const classes = useStyles({
    archived: isRoomArchived || revoked,
    itemChecked: itemChecked,
    iconHovered: iconHovered,
    isVaultPublic: isVaultPublic
  });

  const toggleIcon = () => handleIconHovered(!iconHovered);

  const handleSelectFolder = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    onSelectedItems(name, checked, revoked);
    onLastSelectedItem(name);
  };

  const folderSizeToShow = bytesToGb(folder.size);

  return (
    <>
      <TableRow hover={!!folder} ref={setFolderRef} {...forwardProps} sx={{ ...forwardStyle }}>
        {folder ? (
          <TableCell size="medium" sx={{ cursor: "pointer" }} scope="row">
            <Tooltip title={folder.name}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {iconHovered || itemChecked ? (
                  <Checkbox
                    onMouseLeave={toggleIcon}
                    onMouseEnter={toggleIcon}
                    onChange={handleSelectFolder}
                    checked={itemChecked}
                    name={folder.id}
                    className={classes.checkBox}
                    icon={<TickIcon fontSize="medium" classes={{ root: classes.iconBackground }} sx={{ padding: "3px" }} />}
                    checkedIcon={<TickIcon fontSize="medium" classes={{ root: classes.iconBackground }} sx={{ padding: "3px" }} />}
                  />
                ) : (
                  <IconButton onMouseEnter={toggleIcon}>
                    <FolderIcon fontSize="large" classes={{ root: classes.iconBackground }} />
                  </IconButton>
                )}
                <div
                  style={{ display: "grid" }}
                  onClick={() =>
                    revoked
                      ? history.push(
                          `/vaults/${getVaultStatus(history.location.pathname)}/${getVaultId(
                            history.location.pathname
                          )}/revoked-files/folders/${folder.id}`
                        )
                      : isPublicRoute
                      ? history.push(
                          `/public/vaults/${getVaultStatus(history.location.pathname)}/${getVaultId(
                            history.location.pathname
                          )}/assets/folders/${folder.id}`
                        )
                      : history.push(
                          `/vaults/${getVaultStatus(history.location.pathname)}/${getVaultId(history.location.pathname)}/assets/folders/${
                            folder.id
                          }`
                        )
                  }
                >
                  <Typography variant="body2" color="text.primary" noWrap>
                    {folder.name}
                  </Typography>
                </div>
              </div>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell scope="row" size="medium">
            <Box display="flex">
              <Skeleton variant="rectangular" width={30} height={30} sx={{ marginRight: "15px" }} />
              <Skeleton sx={{ flex: "1" }} />
            </Box>
          </TableCell>
        )}

        {columnsToShow(folder, width, folderSizeToShow)}

        {folder ? (
          <TableCell align="right" size="medium">
            <Box display="flex" justifyContent="end">
              <MoreActionButton
                handleClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  handleMenu(e);
                  // onCurrentStack(folder);
                }}
                menuActive={menuActive}
                arialLabel="display more actions folder"
              />
            </Box>
          </TableCell>
        ) : width === "xs" || width === "sm" ? null : (
          <TableCell />
        )}
      </TableRow>
      {width === "xs" ? (
        <FolderDrawer folderId={folder.id} openDrawer={openMenuFolder} handleMenuClose={handleMenuClose} />
      ) : (
        <MenuFolder folderId={folder.id} anchorEl={anchorEl} openMenu={openMenuFolder} handleMenuClose={handleMenuClose} />
      )}
    </>
  );
};

export default FolderItem;
