import { apiConfig } from "@akord/akord-js/lib/api/config";
import { ClientConfig } from "@akord/akord-js/lib/config";

/* eslint-disable no-undef */
export const AKORD_ENV: "v2" | "dev" = (process.env.REACT_APP_AKORD_ENV as "v2" | "dev" | undefined) || "dev";
export const CLIENT_CONFIG: ClientConfig = {
  env: AKORD_ENV
};

export const GQL_API_URL =
  AKORD_ENV === "dev"
    ? "https://7doygkohfjbp7ma6bab5ryxabi.appsync-api.eu-central-1.amazonaws.com/graphql"
    : "https://gkce35zhtvfllkbnc3oy2zs6ty.appsync-api.eu-central-1.amazonaws.com/graphql";
export const ROOT_DOMAIN = AKORD_ENV === "dev" ? "akord.link" : "akord.com";
export const API_URL = `https://api.${ROOT_DOMAIN}`;

export const GOOGLE_IMPORT_ENABLED = (process.env.REACT_APP_GOOGLE_IMPORT_ENABLED || "true") === "true";
export const GOOGLE_API_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_API_CLIENT_ID || "80966871066-8pp4iuna22ta96cqam9ehjs8ll6ej9vv.apps.googleusercontent.com";
export const GOOGLE_API_DEVELOPER_KEY = process.env.REACT_APP_GOOGLE_API_DEVELOPER_KEY || "AIzaSyClALc23AW7pgrVDKc3FOYRUFOs5W2pL7Q";
export const DROPBOX_IMPORT_ENABLED = (process.env.REACT_APP_DROPBOX_IMPORT_ENABLED || "true") === "true";
export const DROPBOX_APP_ID = process.env.REACT_APP_DROPBOX_APP_ID || "iivnxiowoadlnti";
export const CACHE_ONLY_SUBDOMAINS = process.env.REACT_APP_CACHE_ONLY_SUBDOMAINS || ["cloud"];
export const READ_ONLY_MODE = process.env.REACT_APP_READ_ONLY_MODE || "false";
export const SERIOUS_ISSUE_MODE = process.env.REACT_APP_SERIOUS_ISSUE_MODE || "false";
export const DESCRIPTION_OVERFLOW_LIMIT = process.env.REACT_APP_DESCRIPTION_OVERFLOW_LIMIT || "300";

export const WHITE_LABEL_SUPPORT_SUBDOMAIN = process.env.REACT_APP_WHITE_LABEL_SUPPORT || ["superscript", "zephyr"];

export const SIGNUP_VERIFY_REDIRECT_URL = process.env.REACT_APP_SIGNUP_VERIFY_REDIRECT_URL || " ";

export const STORAGE_URL = `${apiConfig(AKORD_ENV).apiurl}/files`;
export const AVATAR_URL = `${apiConfig(AKORD_ENV).apiurl}/avatars`;

export const SEARCH_INDEXING_ENABLED = true;
export const MAINTENANCE_MODE_ENABLED = process.env.REACT_APP_MAINTENANCE_MODE_ENABLED || "false";
