import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Link, Radio, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { GlobeIcon, PadlockIcon, RadioEmptyIcon, RadioCheckedIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultCreateContext } from "../../contexts/VaultCreateContextProvider";
import { akordLinks } from "../../helpers/akordTexts";
import { useKeyPress } from "../../hooks/useKeyPress";
import Label from "../../components/common/Label";

type EventLikeObject = {
  target: { value: "Private" | "Public" };
};

const useStyles = makeStyles<Theme>(theme => ({
  box: {
    border: "1px solid",
    borderColor: theme.palette.background.cardBorder,
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "16px",
    cursor: "pointer"
  },
  radio: {
    marginTop: "4px",
    padding: "0 16px 0 0",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-checked:hover": {
      backgroundColor: "transparent"
    }
  }
}));

function CreateVaultPrivacyStep() {
  const [selectedValue, setSelectedValue] = React.useState<"Private" | "Public">("Private");

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp") handleChange({ target: { value: "Private" } });
    if (event.key === "ArrowDown") handleChange({ target: { value: "Public" } });
    if (event.key === "ArrowLeft") history.push("/create-vault/storage");
    if (event.key === "ArrowRight") history.push("/create-vault");
  };

  useKeyPress(["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"], onKeyPress);

  const { isMobile } = useGlobalContext();
  const { onVaultIsPublicChange, vaultCreateFormData } = useVaultCreateContext();

  const classes = useStyles();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | EventLikeObject) => {
    setSelectedValue(event.target.value as "Private" | "Public");
    if (event.target.value === "Public") {
      onVaultIsPublicChange(true);
    } else {
      onVaultIsPublicChange(false);
    }
  };

  return (
    <SupportScreenWrapper title="Create new vault" subtitle="Step 2 of 3" hideChevron spaceBetween fullHeight>
      <Box height="auto" display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"}>
        <Box mt={4} mb={4}>
          <Typography variant="body2" component="span">
            Choose the privacy setting for your vault.
          </Typography>{" "}
          <Link variant="body2" color="text.tertiary" onClick={() => window.open(akordLinks.vaultExplained, "_blank")}>
            Learn more about vaults here.
          </Link>
        </Box>
        <Box>
          <Box
            className={classes.box}
            onClick={() => handleChange({ target: { value: "Private" } })}
            sx={{ backgroundColor: !vaultCreateFormData.isPublic ? "background.card" : "transparent" }}
          >
            <Radio
              checked={!vaultCreateFormData.isPublic}
              onChange={handleChange}
              value="Private"
              size="small"
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              name="radio-buttons"
              inputProps={{ "aria-label": "Private" }}
            />
            <Box>
              <Box display="flex" marginBottom="8px">
                <PadlockIcon fontSize="small" sx={{ margin: "0 8px 0 4px" }} />
                <Typography variant="body2" className="strong" color="text.primary">
                  Private
                </Typography>
                <Label text="Personal files" secondary smallLabel />

                {vaultCreateFormData.cloud ? (
                  <Label text="Business files" secondary smallLabel />
                ) : (
                  <Label text="Token-gated access" secondary smallLabel />
                )}
              </Box>
              <Typography variant="body2" className="small" color="text.secondary">
                All data is end-to-end encrypted. Keep it personal and private, or invite others to share digital valuables in a secure
                space.{" "}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{ backgroundColor: vaultCreateFormData.isPublic ? "background.card" : "transparent" }}
            className={classes.box}
            onClick={() => handleChange({ target: { value: "Public" } })}
          >
            <Radio
              checked={vaultCreateFormData.isPublic}
              onChange={handleChange}
              value="Public"
              size="small"
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              name="radio-buttons"
              inputProps={{ "aria-label": "Public" }}
            />
            <Box>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <GlobeIcon fontSize="small" sx={{ margin: "0 8px 0 4px" }} />
                <Typography variant="body2" className="strong" color="text.primary">
                  Public
                </Typography>
                <Label text="Unencrypted" secondary smallLabel highlight />
                {!vaultCreateFormData.cloud && (
                  <>
                    <Label text="Atomic NFTs" secondary smallLabel />
                    <Label text="NFT assets" secondary smallLabel />
                  </>
                )}
              </Box>
              <Typography variant="body2" className="small" color="text.secondary">
                Data is sent to {vaultCreateFormData.cloud ? `the cloud` : `Arweave`} without encryption, and could be accessed by anyone.
                Suitable for NFTs and other public facing projects.
              </Typography>
            </Box>
          </Box>
        </Box>
        <ButtonGroup
          nextDiasabled={!selectedValue}
          handleBack={() => history.push("/create-vault/storage")}
          handleEnd={() => history.push("/create-vault")}
          nextText="Next"
        />
      </Box>
    </SupportScreenWrapper>
  );
}

export default CreateVaultPrivacyStep;
