import { grey } from "@akord/ui";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";

type DropZoneTextProps = {
  isVisible: boolean;
  isVaultPublic: boolean;
};

const DropZoneInfoText: React.FC<DropZoneTextProps> = ({ children, isVisible, isVaultPublic }) => {
  const { isMobile } = useGlobalContext();
  const color = isVaultPublic ? "info.main" : "primary.main";
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      sx={{ borderStyle: "dashed", borderWidth: "1px", borderColor: isVisible && !isMobile ? color : "transparent" }}
    >
      {isVisible && !isMobile && (
        <Box sx={{ backgroundColor: color, borderRadius: "6px 6px 0 0" }} position="absolute" bottom="3px" alignSelf="center">
          <Typography variant="caption" className={["small", "strong"].join(" ")} px={4} color={grey[800]}>
            Drag & Drop a file or folder
          </Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default DropZoneInfoText;
