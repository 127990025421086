import React, { useEffect, useState } from "react";
import { SupportScreenWrapper } from "../../components/common";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import BillingForm from "../../components/payment/BillingForm";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { Stripe } from "@stripe/stripe-js";

const BillingAndPaymentDetails: React.FC = () => {
  const { stripePk } = usePaymentContext();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (stripePk) {
      const stripePromise = loadStripe(stripePk);
      setStripePromise(stripePromise);
    }
  }, [stripePk]);

  return (
    <SupportScreenWrapper title="Billing and payment details" route="/account/plans-and-payments">
      <Elements stripe={stripePromise}>
        <BillingForm />
      </Elements>
    </SupportScreenWrapper>
  );
};

export default BillingAndPaymentDetails;
