import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { FormLabel, FormControl, FormGroup, FormControlLabel, Divider, Collapse, Checkbox, Typography, Box, Theme } from "@mui/material";
import { ChevronUpIcon, ChevronDownIcon, CheckboxTickIcon, CheckboxEmptyIcon } from "@akord/addon-icons";
import { filtersListMap } from "./filterMap";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const useStyles = makeStyles<Theme>(theme => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(5)
  },
  formControlLabelRoot: {
    justifyContent: "space-between",
    marginBottom: theme.spacing(5)
  },
  labelPlacementStart: {
    marginLeft: 0,
    marginRight: "-11px"
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  divider: {
    marginLeft: -20,
    marginRight: -20
  }
}));

type FilterActionsMobileProps = {
  filtersList: string[];
  hasTrueKeys: boolean;
  keepOpen: boolean;
};

const FilterActionsMobile: React.FC<FilterActionsMobileProps> = ({ filtersList, hasTrueKeys, keepOpen }) => {
  const [openActions, setOpenActions] = React.useState(false);

  const { actionFilters, onActionFilters } = useAssetsContext();

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) onActionFilters([...actionFilters, event.target.name]);
    else {
      const updatedActionFilters = actionFilters.filter(item => item !== event.target.name);
      onActionFilters(updatedActionFilters);
    }
  };

  const handleClick = () => {
    setOpenActions(!openActions);
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="div" className={classes.label} onClick={handleClick}>
        <Box mb={5} display="flex" justifyContent="space-between">
          <Typography variant="body2" className="strong" color="text.primary">
            Action
          </Typography>
          {openActions ? <ChevronUpIcon color="disabled" /> : <ChevronDownIcon color="disabled" />}
        </Box>
      </FormLabel>
      <Collapse in={openActions || keepOpen} timeout="auto" unmountOnExit>
        <FormGroup>
          {filtersList.map((filter, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={actionFilters.includes(filter)}
                  icon={<CheckboxEmptyIcon />}
                  checkedIcon={<CheckboxTickIcon />}
                  onChange={handleChange}
                  name={filter}
                />
              }
              labelPlacement="start"
              label={<Typography variant="body2">{filtersListMap[filter] || filter?.split("/")[1]?.toUpperCase() || filter}</Typography>}
              classes={{
                labelPlacementStart: classes.labelPlacementStart
              }}
              className={classes.formControlLabelRoot}
            />
          ))}
        </FormGroup>
      </Collapse>
      <Divider className={classes.divider} />
    </FormControl>
  );
};

export default FilterActionsMobile;
