import React, { useState, useContext, useEffect } from "react";
import zxcvbn from "zxcvbn";
import { AkordWallet } from "@akord/crypto";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { MissingWords, SignUpFormDataProps, SignupProps, ZXCVBNResultExtended } from "../types/signUpTypes";
import { base64ToJson } from "@akord/crypto";

const SignupContext = React.createContext<SignupProps>({} as SignupProps);

const regexPassword = /^[\S]+.*[\S]+$/;

const SignupContextProvider: React.FC<React.ReactNode & RouteComponentProps> = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });

  const [wallet, setWallet] = useState<AkordWallet>({} as AkordWallet);
  const handleWallet = (newWallet: AkordWallet) => setWallet(newWallet);

  const [error, setError] = useState(null);
  const handleError = (error: any) => setError(error);

  const [passEvaluation, setPassEvaluation] = useState<ZXCVBNResultExtended>({} as ZXCVBNResultExtended);
  const handlePassEvaluation = (evaluation: ZXCVBNResultExtended) => setPassEvaluation(evaluation);

  const [missingWords, setMissingWords] = useState<MissingWords>({} as MissingWords);
  const handleMissingWords = (words: MissingWords) => setMissingWords(words);

  const [formData, setFormData] = useState<SignUpFormDataProps>({
    userEmail: "",
    userPassword: "",
    confirmUserPassword: "",
    getNewsletter: false,
    acceptTerms: false,
    acceptPhrase: false,
    referrerId: null,
    withdrawalRight: false
  });

  const handleFormChange = (formType: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (formType === "userPassword") {
      let evaluation: ZXCVBNResultExtended = zxcvbn(value.toString());
      // AWS restrictions
      if (!regexPassword.test(value.toString()) && value.toString().length >= 2) evaluation.error = true;

      setPassEvaluation(evaluation);
    }
    setFormData({
      ...formData,
      [formType]: value
    });
  };

  const setFromUri = () => {
    const data = { ...formData };

    const urlParams = new URLSearchParams(location.search);
    const referrerId = urlParams.get("referrerId");
    if (referrerId) {
      data.referrerId = referrerId;
    }
    const uid = urlParams.get("uid");
    const email = uid ? (base64ToJson(uid) as any).email : null;
    if (email) {
      data.userEmail = email;
    }
    setFormData(data);
  };

  useEffect(() => {
    setFromUri();
  }, [location.search]);

  // Clear errors on changing the input
  useEffect(() => {
    handleError(null);
  }, [formData.userEmail, missingWords]);

  // Clear filled in words on page change
  useEffect(() => {
    handleMissingWords({});
  }, [location.pathname]);

  return (
    <SignupContext.Provider
      value={{
        missingWords: missingWords,
        onMissingWords: handleMissingWords,
        formData: formData,
        onFormData: setFormData,
        handleFormChange: handleFormChange,
        passEvaluation: passEvaluation,
        handlePassEvaluation: handlePassEvaluation,
        error: error,
        handleError: handleError,
        isDesktop: isDesktop,
        handleWallet: handleWallet,
        wallet: wallet
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};
export default withRouter(SignupContextProvider);

export const useSignupContext = () => useContext(SignupContext);
