import { InfoIcon } from "@akord/addon-icons";
import { Box, Typography } from "@mui/material";
import React from "react";

type InfoWarningBoxProps = {
  text: string;
};

const InfoWarningBox: React.FC<InfoWarningBoxProps> = ({ text }) => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderRadius: "4px",
        borderColor: "background.secondary",
        backgroundColor: "primary.iconInvert"
      }}
      p={3}
      mb={6}
      display="flex"
      alignItems="flex-start"
    >
      <InfoIcon color="disabled" sx={{ mr: 2 }} />
      <Typography variant="body2" className="small">
        {text}
      </Typography>
    </Box>
  );
};

export default InfoWarningBox;
