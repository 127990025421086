import React from "react";
import { Auth } from "@akord/akord-js";
import { Box } from "@mui/material";
import { AkordWallet } from "@akord/crypto";
import SecurityPassword from "./SecurityPassword";
import SecurityPhrase from "./SecurityPhrase";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { SupportScreenWrapper, ButtonGroup, FormSubmit } from "../../components/common";

const SecurityBackup: React.FC<RouteComponentProps> = ({ history }) => {
  const { isMobile } = useGlobalContext();
  const [screen, setScreen] = React.useState<"reveal" | "password">("password");
  const [backupPhrase, setBackupPhrase] = React.useState<string>();
  const [userEmail, setUserEmail] = React.useState<string>();
  const [formData, setFormData] = React.useState({
    userPassword: ""
  });
  const [error, setError] = React.useState<any>();

  const isDisabled = () => !(formData.userPassword.length >= 8);

  const getErrorText = () => {
    if (error && error.message) return "Your login details are not recognised. Please check your password.";
  };

  const getBackupPhrase = async () => {
    try {
      const attributes = await Auth.getUserAttributes();
      const encBackupPhrase = attributes["custom:encBackupPhrase"];
      const importWallet = await AkordWallet.importFromEncBackupPhrase(formData.userPassword, encBackupPhrase);
      setUserEmail(attributes["email"]);
      setBackupPhrase(importWallet.backupPhrase);
      setScreen("reveal");
    } catch (err) {
      setError(err);
    }
  };

  const redirectToSecurity = () => {
    history.push("/account/security");
  };

  return (
    <SupportScreenWrapper title="Reveal recovery phrase" route="/account/security">
      <FormSubmit onSubmit={screen === "password" ? getBackupPhrase : redirectToSecurity}>
        <Box mt={5}>
          {screen === "password" ? (
            <SecurityPassword setFormData={setFormData} formData={formData} />
          ) : (
            <SecurityPhrase backupPhrase={backupPhrase?.split(" ")!} userEmail={userEmail!} />
          )}
        </Box>
        {screen === "password" && (
          <ButtonGroup
            type="submit"
            noMinWidth={true}
            nextText="Reveal recovery phrase"
            nextDiasabled={isDisabled()}
            hideBackButton={true}
            fullWidthNextButton={isMobile ? true : false}
            hideIcon={true}
            errorText={getErrorText()}
            disabledRed={false}
          />
        )}
      </FormSubmit>
    </SupportScreenWrapper>
  );
};

export default withRouter(SecurityBackup);
