import React from "react";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";

type ErrorAlertProps = {
  getErrorText: string;
  darkMode?: boolean;
};

const ErrorAlert: React.FC<ErrorAlertProps> = ({ getErrorText, darkMode }) => {
  return (
    // for signup alert if phrease is wrong
    <Box mt={2} mb={darkMode ? 2 : 0}>
      <Alert variant={darkMode ? "filled" : "standard"} severity="error">
        {getErrorText}
      </Alert>
    </Box>
  );
};

export default ErrorAlert;
