import React from "react";
// import { makeStyles, createStyles } from '@mui/material/styles'
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

// const useStyles = makeStyles(theme => createStyles({}))

function VaultRename(props) {
  // const classes = useStyles()
  const { positionedBeforeDate, operation, color } = props;

  return (
    <>
      <OperationTitleWithIcon actionRef={operation.actionRef} color={color} />
      {/* <div style={{ display: "flex" }}>
        <Typography variant="body2" color="text.secondary" className="small">
          {"From:"}
        </Typography>
        <Typography
          variant="body2"
          className="small"
          noWrap
          style={{ whiteSpace: "pre" }}
        >
          {" "}
          {operation.prevTitle}
        </Typography>
      </div> */}
      <div
        style={{
          marginBottom: positionedBeforeDate ? "74px" : "16px",
          display: "flex"
        }}
      >
        <Typography variant="body2" color="text.secondary" className="small">
          {"Name:"}
        </Typography>
        <Typography
          variant="body2"
          className="small"
          noWrap
          style={{ whiteSpace: "pre" }}
        >
          {" "}
          {operation.vault.name}
        </Typography>
      </div>
    </>
  );
}

export default VaultRename;
