import React from "react";
import { useParams } from "react-router-dom";
// import AccountBackup from "./AccountBackup";
import BackUpPhrase from "./BackUpPhrase";
// import Verify from "./Verify";
import AccountCreated from "./AccountCreated";
import BackupInfo from "./BackupInfo";
import { SignUpStepsOptions } from "../../types/globalTypes";

const SignupSteps = () => {
  const { step }: { step: SignUpStepsOptions } = useParams();

  const renderStep = () => {
    switch (step) {
      case "backup-phrase":
        return <BackUpPhrase />;
      case "account-created":
        return <AccountCreated />;
      case "backup-info":
        return <BackupInfo />;
      default:
        return null;
    }
  };

  return renderStep();
};

export default SignupSteps;
