import React from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Divider
} from "@mui/material";
import { CloseIcon } from "@akord/addon-icons";
import makeStyles from "@mui/styles/makeStyles";
import { Editor } from "draft-js";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";

const useStyles = makeStyles(theme => ({
  closeButton: {
    padding: 0
  },
  divider: {
    margin: "0 24px"
  },
  editorText: {
    "& div.DraftEditor-editorContainer": {
      fontSize: "1rem",
      lineHeight: "26px",
      color: theme.palette.text.tertiary
    }
  }
}));

function TermsModal({ operation, openModal, handleOpenModal, editorState }) {
  const { isMobile } = useGlobalContext();
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="md"
      onClose={() => handleOpenModal(false)}
      aria-labelledby="terms-of-access-dialog"
      open={openModal}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2">Terms of access</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleOpenModal(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {operation.title}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginBottom: 0 }}>
        {editorState ? (
          <div className={classes.editorText}>
            <Editor editorState={editorState} readOnly={true} />
          </div>
        ) : (
          <Typography variant="body2">{operation.termsOfAccess}</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default TermsModal;
