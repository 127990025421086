import React from "react";
import { AppBar, Typography, Toolbar, IconButton, Badge, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { VaultIcon, BellIcon, StorageV2Icon, RewardsIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { getVaultStatus } from "../../../helpers/helpers";
import { useInAppNotificationsContext } from "../../../contexts/InAppNotificationsContext";
import { useStorageContext } from "../../../contexts/StorageContextProvider";

type BottomBarSmProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, BottomBarSmProps>({
  appBottomBar: {
    top: "auto",
    bottom: 0,
    borderTop: "1px solid",
    borderColor: ({ darkMode }) => (darkMode ? "#434140" : "#D5D5D5"),
    height: "60px"
  },
  appBarTop: {
    height: "54px"
  },
  iconWithText: {
    flexDirection: "column"
  },
  grow: {
    flexGrow: 1
  },
  container: {
    top: "54px",
    position: "relative"
  }
});

const BottomBarSm: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { darkMode, userAttributes } = useGlobalContext();
  const { notifications } = useInAppNotificationsContext();
  const { txRewards } = useStorageContext();

  const classes = useStyles({ darkMode: darkMode });

  return (
    <AppBar elevation={0} position="fixed" color="secondary" className={classes.appBottomBar}>
      <Toolbar style={{ paddingTop: "4px" }}>
        <IconButton
          onClick={() => {
            history.push("/vaults/active");
          }}
          classes={{ root: classes.iconWithText }}
          size="large"
        >
          <VaultIcon
            fontSize="medium"
            color={location.pathname === `/vaults/${getVaultStatus(location.pathname)}` ? "action" : "disabled"}
            sx={{ marginBottom: "4px" }}
          />
          <Typography
            variant="caption"
            className="small"
            color={location.pathname.match(`/vaults/${getVaultStatus(location.pathname)}`) ? "text.primary" : "text.secondary"}
          >
            Vaults
          </Typography>
        </IconButton>
        {!userAttributes.passwordless && (
          <IconButton
            onClick={() => {
              history.push("/storage");
            }}
            classes={{ root: classes.iconWithText }}
            size="large"
          >
            <StorageV2Icon color={location.pathname === "/storage" ? "action" : "disabled"} sx={{ marginBottom: "4px" }} />
            <Typography variant="caption" className="small" color={location.pathname === "/storage" ? "text.primary" : "text.secondary"}>
              Storage
            </Typography>
          </IconButton>
        )}
        {!userAttributes.passwordless && (
          <IconButton
            onClick={() => {
              history.push("/rewards");
            }}
            classes={{ root: classes.iconWithText }}
            size="large"
          >
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              color="warning"
              variant="dot"
              overlap="circular"
              invisible={!txRewards.size}
            >
              <RewardsIcon color={location.pathname.match("/rewards") ? "action" : "disabled"} sx={{ marginBottom: "4px" }} />
            </Badge>
            <Typography variant="caption" className="small" color={location.pathname.match("/rewards") ? "text.primary" : "text.secondary"}>
              Rewards
            </Typography>
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            history.push("/notifications");
          }}
          classes={{ root: classes.iconWithText }}
          size="large"
        >
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            color="warning"
            variant="dot"
            overlap="circular"
            invisible={!notifications || notifications?.filter((notification) => notification.status === "UNREAD").length === 0}
          >
            <BellIcon sx={{ marginBottom: "4px" }} color={location.pathname === "/notifications" ? "action" : "disabled"} />
          </Badge>
          <Typography
            variant="caption"
            className="small"
            color={location.pathname === "/notifications" ? "text.primary" : "text.secondary"}
          >
            Notifications
          </Typography>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(BottomBarSm);
