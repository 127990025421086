import { Badge, Box, Tooltip, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { memoize } from "lodash";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AkordAvatarIcon, AvatarIcon, PadlockIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { formatDate, getResourceUri, sortedItemsByDate } from "../../helpers/helpers";
import { getTimelineAction } from "./timeline-actions";
import { colorMap } from "./timeline-actions/colorMap";
import { STORAGE_URL } from "../../helpers/env";
import Avatar from "../../components/common/Avatar/Avatar";

const useStyles = makeStyles({
  missingOppositeContent: {
    "&:before": {
      flex: "none",
      padding: 0
    }
  },
  dot: {
    padding: 0,
    margin: 0,
    borderWidth: "1px",
    boxShadow: "none",
    border: "none"
  },
  content: {
    padding: "2px 8px 2px 4px",
    maxWidth: "calc(100% - 50px)"
  },
  iconExtraLarge: {
    fontSize: "38px",
    margin: 3
  },
  iconExtraSmall: {
    fontSize: "13px"
  },
  lockBoxLength: {
    minHeight: "28px"
  },
  lockPosition: {
    marginLeft: "16px"
  },
  dateBadge: {
    padding: "14px",
    border: "none",
    fontSize: "14px",
    borderRadius: "30px",
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontWeight: 400
  },
  badgePosition: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  // lastItemLength: {
  //   minHeight: '56px',
  // },
  lastItemBeforeDateLength: {
    minHeight: "84px"
  },
  avatar: {
    width: "38px",
    height: "38px",
    borderRadius: "50%",
    margin: "3px"
  }
});

const memoGetTimelineAction = memoize(getTimelineAction);
const memoSortedItemsByDate = memoize(sortedItemsByDate);

function ShowTimeline({
  timelineItems,
  timelineEndRef,
  scrollToBottom,
  lastItemRef,
  loadMoreTimelineItems,
  timelineItemsNumber,
  timelineItemsLimit,
  isFiltered
}) {
  const { membersMap, cancelledTimelineItemsCount } = useVaultContext();

  const location = useLocation();
  const itemId = location.state?.itemId;

  const classes = useStyles();
  const itemRef = useRef(null);
  const countRef = useRef([]);

  useEffect(() => {
    if (!itemId) scrollToBottom();
  }, [timelineItems, cancelledTimelineItemsCount]);

  // Reseting counter array on every change of location and filtering
  // We show "Load More" based on that value
  useEffect(() => {
    countRef.current = [];
  }, [location.pathname, isFiltered]);

  useEffect(() => {
    if (itemRef && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
  }, [timelineItems, itemRef]);

  const highlightItem = () => {
    const container = itemRef.current.querySelector("#memo-container");
    if (container) {
      const color = container.getAttribute("data-color");
      const initialBackground = "inherit";
      container.style.transition = "background-color 1s ease-in";
      container.style.background = color;
      setTimeout(() => {
        container.style.background = initialBackground;
      }, 1000);
    }
  };

  // We paginate already groupped items
  // so we will not break apart groupRef
  const paginatePreparedTimeline = timeline => {
    let mapped = new Map();
    // Keep track of amount of items we have
    let count = 1;

    // Need to reverse to start counting from the end
    const reversedKeys = new Map(Array.from(timeline).reverse());
    for (const [key, value] of reversedKeys) {
      // We update count after adding the items
      // and update the state with `timelineItemsNumber`
      // later, so pagination can move in a faster pace
      // depending on the timline structure
      // (again, we don't want to break groupRef items)

      let limitedItemsForDate = [];
      // Reversing the content for the same reason - counting from the bottom
      for (const item of value.reverse()) {
        if (count <= timelineItemsNumber) {
          limitedItemsForDate.push(item);
          count++;
        }
      }
      if (limitedItemsForDate.length > 0) mapped.set(key, limitedItemsForDate.reverse());
    }
    // To avoid adding same count because of rerenders
    if (!countRef.current.includes(count) && count > 0) countRef.current.push(count);
    const reversedOutput = new Map(Array.from(mapped).reverse());
    return reversedOutput;
  };

  const preparedTimelineMap = memoSortedItemsByDate(timelineItems);
  // Length of all items in the timeline
  const timelineItemsLength = Array.from(preparedTimelineMap.values()).flat().length;
  const paginatedTimelineMap = paginatePreparedTimeline(preparedTimelineMap);
  const paginatedTimelineArray = Array.from(paginatedTimelineMap);
  const paginatedTimelineEntriesLength = paginatedTimelineMap.size;

  // Paginated items for later matching
  const paginatedTimelineItems = Array.from(paginatedTimelineMap?.values()).flat();

  // To find which item should be added the REF (top item in the previous view)
  // we keep track of the indexes of these items and calculate here based on
  // last too indexes
  const calcItemIndex = () => {
    const currIndex = countRef.current.length - 1;
    const prevIndex = countRef.current.length - 2;
    return countRef.current[currIndex] - countRef.current[prevIndex];
  };

  // We need to match items when we render either by groupRef or id
  // for that we keep `paginatedTimelineItems` array of all items
  // and use `calcItemIndex` to get an index and match to the rendered item
  const matchItem = item => {
    if (item.groupRef) return paginatedTimelineItems[calcItemIndex()]?.groupRef === item.groupRef;
    else if (!item.groupRef && item.id) return paginatedTimelineItems[calcItemIndex()]?.id === item.id;
    else return null;
  };

  const renderTimelineAction = (item, index, length, key) => {
    return memoGetTimelineAction(
      item,
      index === length - 1 && key !== paginatedTimelineEntriesLength - 1,
      item.actionRef === "USER_VERIFIED" ? "secondary.main" : item.ownerInfo ? colorMap[membersMap[item.ownerInfo.address]] : "#A2A2A2"
    );
  };

  return (
    <>
      {countRef.current[countRef.current.length - 1] < timelineItemsLength && (
        <Box mb={6} display="flex" justifyContent="center">
          <Button
            size="small"
            onClick={() => loadMoreTimelineItems(countRef.current[countRef.current.length - 1])}
            color="primary"
            variant="outlined"
            type="submit"
            label="submit"
            disableElevation
            className={classes.buttonGroup}
          >
            Load more activity...
          </Button>
        </Box>
      )}
      <Box>
        <Timeline align="left">
          <TimelineItem className={classes.lockBoxLength} classes={{ missingOppositeContent: classes.missingOppositeContent }}>
            <TimelineSeparator className={classes.lockPosition}>
              <TimelineDot className={classes.dot}>
                <PadlockIcon classes={{ root: classes.iconExtraSmall }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
          </TimelineItem>
          {paginatedTimelineArray.map(([date, timelineArray], key) => {
            return (
              <span key={key}>
                <Badge
                  variant="standard"
                  color="secondary"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={date}
                  classes={{ badge: classes.dateBadge }}
                  className={classes.badgePosition}
                  style={{ top: key === 0 ? "0px" : "-10px", zIndex: 0 }}
                />
                {timelineArray.map((item, index, { length }) => {
                  const timelineItem = renderTimelineAction(item, index, length, key);
                  if (!timelineItem || item.isCancelled) {
                    return null;
                  }
                  return (
                    <TimelineItem
                      key={index}
                      className={
                        index === timelineArray.length - 1 && key !== paginatedTimelineEntriesLength - 1
                          ? classes.lastItemBeforeDateLength
                          : key === paginatedTimelineEntriesLength - 1 && index === timelineArray.length - 1
                          ? classes.lastItemLength
                          : null
                      }
                      classes={{
                        missingOppositeContent: classes.missingOppositeContent
                      }}
                      id={item.groupRef || item.id}
                      ref={el => {
                        if (location.state?.itemId && item.modelId == location.state?.itemId) itemRef.current = el;
                        else if (timelineItemsNumber - timelineItemsLimit > 0 && matchItem(item)) lastItemRef.current = el;
                      }}
                    >
                      <TimelineSeparator>
                        <TimelineDot className={classes.dot} variant="outlined">
                          {item.ownerInfo?.address ? (
                            <Avatar
                              url={`${STORAGE_URL}/${item.ownerInfo.address}`}
                              imageClassName={classes.avatar}
                              avatarClasses={{
                                fontSizeLarge: classes.iconExtraLarge
                              }}
                              avatarSx={{
                                color: "secondary.main"
                              }}
                            />
                          ) : item.actionRef === "USER_VERIFIED" ? (
                            <AkordAvatarIcon
                              fontSize="large"
                              sx={{
                                color: "secondary.main"
                              }}
                              classes={{
                                fontSizeLarge: classes.iconExtraLarge
                              }}
                            />
                          ) : (
                            <AvatarIcon
                              fontSize="large"
                              style={{
                                color: item.ownerInfo ? colorMap[membersMap[item.ownerInfo.address]] : "#A2A2A2"
                              }}
                              classes={{
                                fontSizeLarge: classes.iconExtraLarge
                              }}
                            />
                          )}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent className={classes.content}>
                        {item.actionRef !== "MEMO_CREATE" && (
                          <Box display="flex" flexDirection="row" alignItems="baseline">
                            <Tooltip title={item?.ownerInfo?.email ? item.ownerInfo.email : "Akord"} arrow>
                              <Typography variant="body2" className="small strong" color="text.primary" sx={{ marginRight: 2 }} noWrap>
                                {item.actionRef === "USER_VERIFIED" ? "Akord" : item.ownerInfo?.name || item.ownerInfo?.email}
                              </Typography>
                            </Tooltip>
                            <Typography variant="caption" className="small" color="text.secondary">
                              {formatDate(item.createdAt || item.postedAt, true)}
                            </Typography>
                          </Box>
                        )}
                        {timelineItem}
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </span>
            );
          })}
          <TimelineItem classes={{ missingOppositeContent: classes.missingOppositeContent }} style={{ minHeight: "auto" }}>
            <TimelineSeparator className={classes.lockPosition}>
              <TimelineDot className={classes.dot}>
                <PadlockIcon classes={{ root: classes.iconExtraSmall }} />
              </TimelineDot>
            </TimelineSeparator>
          </TimelineItem>
        </Timeline>
        <div id="timeline-end" ref={timelineEndRef} />
      </Box>
    </>
  );
}

export default ShowTimeline;
