import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { EmptySpace, SupportScreenWrapper } from "../../components/common";
import { formatDate } from "../../helpers/helpers";
import { DownloadIcon } from "@akord/addon-icons";
import { getInvoices } from "../../helpers/api-helpers";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { Invoice } from "../../types/stripeTypes";

const NO_OF_INVOICES_PER_PAGE = 5;

const tableHeading = [
  { title: "Date", width: "20%" },
  { title: "Description", width: "40%" },
  { title: "Amount", width: "20%" },
  { title: "Status", width: "10%" }
];

const proccessStatus = (status: string) => {
  switch (status) {
    case "COMMITTED":
      return "Paid";

    default:
      return "Paid";
  }
};

const getDescription = (invoice: Invoice) => {
  if (invoice.subscription) {
    if (invoice.lines.data[0].proration) {
      return `Upgrade to ${invoice.subscription.plan.product.name}`;
    }
    return `${invoice.subscription.plan.product.name} (${getInterval(invoice)})`;
  } else {
    if (invoice.metadata?.quantity) {
      return `${invoice.metadata.quantity}GB (top up)`;
    } else {
      return "Blockchain storage (top up)";
    }
  }
};

const getInterval = (invoice: Invoice) => {
  switch (invoice.subscription.plan.interval) {
    case "day":
      return "daily";
    case "year":
      return "yearly";
    case "month":
    default:
      return "monthly";
  }
};

const StyledCell: React.FC<React.ReactNode> = ({ children }) => (
  <TableCell align="left" sx={{ padding: "12px 0" }}>
    <Typography variant="body2" className="small" color="text.primary" sx={{ display: "flex" }}>
      {children}
    </Typography>
  </TableCell>
);

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoicesLoading, setInvoicesLoading] = useState(true);
  const [invoiceIdDownloading, setInvoiceIdDownloading] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const { wallet } = useGlobalContext();
  const { getCurrencySymbol } = usePaymentContext();

  const loadMore = async () => {
    if (!wallet && !invoices) return;
    const lastInvoice = invoices![invoices!.length - 1];
    try {
      const invoicesData = await getInvoices({ limit: NO_OF_INVOICES_PER_PAGE, lastInvoiceId: lastInvoice.id });
      if (!invoicesData) return;
      setInvoices([...invoices, ...invoicesData.data]);
      setHasMore(invoicesData.has_more);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const getInvoicesData = async () => {
      setInvoicesLoading(true);
      const invoicesData = await getInvoices({ limit: NO_OF_INVOICES_PER_PAGE });
      setInvoices(invoicesData?.data || []);
      setHasMore(invoicesData?.has_more || false);
      setInvoicesLoading(false);
    };

    wallet && getInvoicesData();
  }, [wallet]);

  const downloadInvoice = async (invoice: any) => {
    setInvoiceIdDownloading(invoice.number);
    const invoiceName = `Akord_Invoice_${invoice.number}`;
    const linkSource = invoice.invoice_pdf;
    const downloadLink = document.createElement("a");
    const fileName = `${invoiceName}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setTimeout(() => setInvoiceIdDownloading(null), 2500);
  };

  return (
    <SupportScreenWrapper title="Invoices" route="/account">
      {(invoicesLoading || invoices?.length > 0) && (
        <Box mt={6} width="100%">
          <Table aria-label="invoice table">
            <TableHead>
              <TableRow>
                {tableHeading.map((heading, index) => (
                  <TableCell key={index} align="left" sx={{ padding: 0, width: heading.width }}>
                    <Typography variant="caption" color="text.secondary">
                      {heading.title}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicesLoading
                ? Array.from(new Array(3)).map((i, idx) => (
                    <TableRow key={idx}>
                      {Array.from(new Array(4)).map((i, idx) => (
                        <TableCell key={idx} sx={{ padding: "12px 0" }}>
                          <Skeleton height={20} width="80%" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : invoices?.map((row: any, index: number) => (
                    <TableRow key={row.created + index}>
                      <StyledCell>{formatDate((row.created * 1000).toString(), false, false, true)}</StyledCell>
                      <StyledCell>{getDescription(row)}</StyledCell>
                      <StyledCell>
                        {getCurrencySymbol(row.currency)}
                        {row.amount_due / 100}
                      </StyledCell>
                      <StyledCell>
                        {proccessStatus(row.status)}
                        {invoiceIdDownloading === row.number ? (
                          <Box sx={{ marginLeft: 2, position: "inherit" }} component="span">
                            <CircularProgress size={16} />
                          </Box>
                        ) : (
                          <IconButton sx={{ marginLeft: 2 }} onClick={() => downloadInvoice(row)}>
                            <DownloadIcon fontSize="small" />
                          </IconButton>
                        )}
                      </StyledCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {hasMore && (
            <Box pt={5} pb={5} display="flex" justifyContent="center">
              <Link variant="body2" className="small" onClick={() => loadMore()} underline="always" color="text.secondary">
                Load more invoices...
              </Link>
            </Box>
          )}
        </Box>
      )}
      {invoices?.length === 0 && !invoicesLoading && <EmptySpace dataText="emptyInvoices" />}
    </SupportScreenWrapper>
  );
};

export default Invoices;
