import React, { useEffect, useState } from "react";
import { FolderIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useFolderMenuOptions } from "../MoreMenu/MenuOptions";
import MoreDrawerRoot from "./MoreDrawerRoot";
import MoreDrawerItem from "./MoreDrawerItem";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { Folder } from "@akord/akord-js";

type FolderDrawerProps = {
  openDrawer: boolean;
  handleMenuClose: () => void;
  folderId: string;
};

const FolderDrawer: React.FC<FolderDrawerProps> = ({ openDrawer, handleMenuClose, folderId }) => {
  const [folder, setFolder] = useState<Folder>();

  const { akord } = useGlobalContext();
  const { isRoomArchived } = useVaultContext();
  const { onDrawerActionType } = useAssetsContext();

  const { options: folderOptions } = useFolderMenuOptions();

  useEffect(() => {
    const loadData = async () => {
      const folder = await akord?.folder.get(folderId);
      setFolder(folder);
    };
    if (folderId) loadData();
  }, [folderId]);

  if (!folder || !folderOptions) {
    return null;
  }

  return (
    <MoreDrawerRoot
      header={folder.name}
      icon={FolderIcon}
      openDrawer={openDrawer}
      handleMenuClose={handleMenuClose}
      isRoomArchived={isRoomArchived}
      isFolder
    >
      {folderOptions(folder, handleMenuClose).map((menuItem, index) => {
        return menuItem.show && <MoreDrawerItem key={index} menuItem={menuItem} onDrawerActionType={onDrawerActionType} />;
      })}
    </MoreDrawerRoot>
  );
};

export default FolderDrawer;
