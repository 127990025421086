import React, { useEffect, useState } from "react";
import MoreDrawerRoot from "./MoreDrawerRoot";
import MoreDrawerItem from "./MoreDrawerItem";
import { useVaultMenuOptions } from "../MoreMenu/MenuOptions";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { Membership, Vault } from "@akord/akord-js";

type RoomDrawerProps = {
  openDrawer: boolean;
  handleMenuClose: () => void;
  vault?: Vault;
  currentMembership?: Membership;
  members: Membership[];
  vaultId?: string;
};

const RoomDrawer: React.FC<RoomDrawerProps> = ({ openDrawer, handleMenuClose, vault, currentMembership, members, vaultId = "" }) => {
  const [vaultData, setVaultData] = useState<Vault>();
  const [membershiptData, setMembershipData] = useState<Membership>();
  const [membersData, setMembersData] = useState<Membership[]>();

  const { options: vaultOptions } = useVaultMenuOptions();
  const { akord, userAttributes } = useGlobalContext();

  const isRoomArchived = vault?.status === "ARCHIVED";

  useEffect(() => {
    const loadData = async () => {
      const vault = await akord?.vault.get(vaultId);
      setVaultData(vault);
      const memberships = await akord?.membership.listAll(vaultId);
      setMembersData(memberships);

      const member = memberships?.find(membership => membership.address === userAttributes.address);
      if (member) {
        setMembershipData(member);
      }
    };
    if (vaultId) {
      loadData();
    } else {
      setVaultData(vault);
      setMembersData(members);
      setMembershipData(currentMembership);
    }
  }, [vaultId, vault, currentMembership]);

  return (
    <MoreDrawerRoot
      header={vault?.name || ""}
      openDrawer={openDrawer}
      handleMenuClose={handleMenuClose}
      isRoomArchived={isRoomArchived}
      isVaultPublic={vault?.public}
    >
      {vaultOptions(vaultData!, membershiptData!, membersData!, handleMenuClose).map(
        (menuItem, index) => menuItem.show && <MoreDrawerItem key={index} menuItem={menuItem} />
      )}
    </MoreDrawerRoot>
  );
};

export default RoomDrawer;
