import React from "react";
import { Box } from "@mui/material";
import EmptySpace from "../../components/common/EmptySpace/EmptySpace";
import { useNftContext } from "../../contexts/NftContextProvider";
import NftGridList from "../nft/NftGridList";
import { filterNfts, PeriodFilterKeys } from "../../helpers/stack-helpers";
import { Pagination } from "../../components/common";
import { usePagination } from "../../hooks/usePagination";
import { Collection, NFT } from "@akord/akord-js";

type TabNftsProps = {
  periodFilter: PeriodFilterKeys | null;
  actionFilters: string[];
};

const TabNfts: React.FC<TabNftsProps> = ({ periodFilter, actionFilters }) => {
  const [pageCollectionsAndNfts, setPageCollectionsAndNfts] = React.useState<(Collection | NFT)[]>();

  const { nfts, isNftLoading } = useNftContext();

  const filteredNfts = React.useMemo(
    () => (nfts?.length > 0 ? filterNfts(nfts, periodFilter, actionFilters) : []),
    [nfts, periodFilter, actionFilters]
  );
  const { pageNumber, pageCount, changePage, pageData, nextPage, previousPage } = usePagination(filteredNfts, 40);

  React.useEffect(() => {
    setPageCollectionsAndNfts(pageData());
  }, [pageNumber, pageData]);

  return (!nfts || nfts?.length === 0) && !isNftLoading ? (
    <EmptySpace dataText={"newAtomicNft"} />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      px={5}
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        paddingTop: "20px",
        position: "absolute"
      }}
    >
      <NftGridList isLoading={isNftLoading} nfts={pageCollectionsAndNfts} />
      <Box display="flex" justifyContent="center" my={5}>
        <Pagination
          bottomPosition
          pages={pageCount}
          currentPage={pageNumber}
          onGoToNextPage={nextPage}
          onGoToPreviousPage={previousPage}
          onChangePage={changePage}
        />
      </Box>
    </Box>
  );
};

export default TabNfts;
