import React from "react";

interface LogoProps {
  className?: string;
  width: string;
  height?: string;
  onClick?: () => void;
}

const Logo: React.FC<LogoProps> = props => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 77 30" {...props}>
      <path d="M16.4019 3.22894H20.7009V17.3543L25.627 10.5597H30.7322L25.8062 17.6224L31.6279 27.2777H26.7019L22.761 20.9303L20.7009 23.6123V27.3672H16.4019V3.22894Z" />
      <path d="M39.5098 10.2916C41.8384 10.2022 44.1672 11.0962 45.7793 12.7054C49.0932 16.1921 49.0932 21.7349 45.7793 25.2216C42.1966 28.44 36.7332 28.44 33.2401 25.2216C29.9262 21.6455 29.9262 16.1921 33.2401 12.7054C34.9418 11.0961 37.181 10.2022 39.5096 10.2916H39.5098ZM39.5994 14.1358C38.435 14.1358 37.2707 14.5828 36.4646 15.4769C35.6585 16.4602 35.2107 17.6225 35.2107 18.8741C35.1211 20.1257 35.5689 21.3774 36.4646 22.3608C37.2707 23.2549 38.435 23.7019 39.5994 23.7019C40.7637 23.7019 41.928 23.2549 42.7341 22.3608C43.5402 21.3775 43.988 20.2152 43.988 18.8741C44.0776 17.6225 43.6298 16.3709 42.8237 15.3874C41.928 14.5828 40.7637 14.1358 39.5994 14.1358Z" />
      <path d="M59.2142 10.4704V14.7616C58.0499 14.6722 56.8856 15.0299 55.9003 15.745C55.0046 16.5496 54.4672 17.6224 54.5568 18.874V27.3671H50.2577V10.6491H53.9298L54.288 13.3312C55.0046 11.3643 56.6167 10.4703 59.2141 10.4703L59.2142 10.4704Z" />
      <path d="M72.2908 3.22894H76.5899V27.2779H72.9178L72.5596 25.0428C71.3953 26.7414 69.3352 27.7248 67.2752 27.6354C65.1256 27.6354 63.0655 26.7414 61.7222 25.1322C58.7666 21.5561 58.7666 16.3708 61.7222 12.7054C63.0657 11.0961 65.1257 10.1128 67.2752 10.2022C69.2456 10.0234 71.0369 10.9174 72.2908 12.3479V3.22894ZM68.1707 14.3147C67.0064 14.3147 65.9315 14.7617 65.1256 15.6557C63.5133 17.6226 63.5133 20.4835 65.1256 22.4503C65.9317 23.3444 67.0064 23.7913 68.1707 23.7913C69.2455 23.7913 70.3204 23.3444 71.1263 22.5397C72.0221 21.6457 72.4698 20.3941 72.3802 19.1424V18.9636C72.3802 17.712 71.9325 16.5498 71.1263 15.6557C70.3202 14.7617 69.2455 14.3147 68.1707 14.3147Z" />
      <path d="M8.25122 4.1228H3.9236H3.54382L3.95213 5.46383L7.71385 17.4437H3.9236V21.3773H8.87817L10.759 27.3673H15.5061L8.25122 4.1228Z" />
      <path d="M1.27747 0.0501709V29.9118" stroke="#FB7439" strokeWidth="0.8" strokeMiterlimit="10" />
    </svg>
  );
};

export default Logo;
