import { Switch } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

export const AkordSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 0,
    left: 3,
    top: 2,
    color: theme.palette.background.default,
    "&$checked": {
      transform: "translateX(15px)",
      color: theme.palette.background.default,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      },
      "&:hover": {
        color: theme.palette.background.default
      }
    },
    "&:hover": {
      color: theme.palette.background.default
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    // border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: theme.palette.background.switch
  },
  checked: {}
}))(Switch);
