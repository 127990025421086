import React from "react";
import { Switch, Route, useRouteMatch, withRouter, RouteComponentProps } from "react-router-dom";
import SignupSteps from "./SignupSteps";
import CreateAccount from "./CreateAccount";
import { PageWrapper } from "../../components/common";
import { stepsData } from "./stepsData";
import { useSignupContext } from "../../contexts/SignupContext";
import { SignUpStepsOptions } from "../../types/globalTypes";
import { History } from "history";
import { AkordWallet } from "@akord/crypto";
import { MissingWords, SignUpFormDataProps } from "../../types/signUpTypes";

const steps = ["signup", "backup-phrase", "account-created", "backup-info"];

export interface HandleNextProps {
  steps: string[];
  currentStep: SignUpStepsOptions;
  path: string;
  history: History;
  formData: SignUpFormDataProps;
  handleError: (error: any) => void;
  handleWallet: (wallet: AkordWallet) => void;
  wallet: AkordWallet;
  missingWords: MissingWords;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignUpPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { formData, handleError, error, passEvaluation, wallet, handleWallet, missingWords } = useSignupContext();

  const { path } = useRouteMatch();
  const match: { params: { step: SignUpStepsOptions } } | null = useRouteMatch(`${path}/:step`);

  const currentStep: SignUpStepsOptions = !match ? "signup" : match?.params?.step;

  const handleNextData = {
    steps,
    currentStep,
    path,
    history,
    formData,
    handleError,
    handleWallet,
    wallet,
    missingWords,
    setIsLoading
  };

  const {
    darkMode,
    showBottomNav,
    nextText,
    inverted,
    isButtonDisabled = () => undefined,
    handleBack,
    handleNext,
    hideBackButton,
    fullWidthNextButton,
    hideIcon,
    handleEnd
  } = stepsData[currentStep];

  return (
    <PageWrapper
      error={error}
      errorText={error?.message}
      darkMode={darkMode}
      showBottomNav={showBottomNav}
      nextText={nextText}
      inverted={inverted}
      nextDiasabled={isButtonDisabled(formData, passEvaluation) || isLoading}
      handleBack={() => handleBack(history)}
      handleNext={() => handleNext(handleNextData)}
      hideBackButton={hideBackButton}
      fullWidthNextButton={fullWidthNextButton}
      hideIcon={hideIcon}
      handleEnd={handleEnd ? () => handleEnd(history) : undefined}
      pageTitle="Create account"
      loading={isLoading}
    >
      <Switch>
        <Route path="/signup/:step">
          <SignupSteps />
        </Route>
        <Route path="/signup">
          <CreateAccount />
        </Route>
      </Switch>
    </PageWrapper>
  );
};

export default withRouter(SignUpPage);
