import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useHistory, useLocation } from "react-router-dom";
import SearchBox from "../../components/search/SearchBox";
import SearchResults from "../../components/search/SearchResults";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSearchContext } from "../../contexts/SearchContextProvider";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    fab: {
      position: "fixed",
      right: "16px",
      bottom: "76px"
    },
    headerBox: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between"
    },
    indexingProgress: {
      color: theme.palette.text.disabled,
      position: "inherit",
      marginRight: "10px"
    }
  })
);

const ShowSearchResults: React.FC = () => {
  const [numberOfResults, setNumberOfResults] = useState(0);

  const { isMobile } = useGlobalContext();
  const { searchResults, cachedSearchResults, isIndexing, indexedVaultsCount, totalVaultsCount } = useSearchContext();

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (Array.isArray(searchResults)) {
      setNumberOfResults(searchResults.length);
    } else {
      setNumberOfResults(getCachedResults().length);
    }
  }, [searchResults, location.pathname]);

  const getCachedResults = () => {
    const urlParams = new URLSearchParams(location.search);
    const term = urlParams.get("term");
    if (term) {
      return cachedSearchResults[term] || [];
    }
    return [];
  };

  return (
    <>
      <Box position="fixed" width={isMobile ? "100%" : "calc(100% - 250px)"} zIndex="1">
        <Box mx={5} mt={4} mb={!isMobile ? 1 : 3} className={classes.headerBox}>
          {!isMobile && (
            <Box width="20%">
              <Box>
                <Typography variant="h2">{numberOfResults === 1 ? `${numberOfResults} result` : `${numberOfResults} results`}</Typography>
              </Box>
              {isIndexing && (
                <Box display="inline-flex" justifyContent="center" alignItems="center">
                  <CircularProgress size="1rem" className={classes.indexingProgress} />
                  <Typography variant="body2" color="text.secondary">
                    Indexing vaults ({indexedVaultsCount}/{totalVaultsCount})
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          <Box flexGrow="1" display="flex" justifyContent="flex-end">
            <SearchBox showRecentSearchTerm={true} forceActive={isMobile}></SearchBox>
          </Box>
          {!isMobile && (
            <Box marginLeft="15px">
              <Button variant="contained" size="small" color="primary" onClick={() => history.push("/create-vault/setup")}>
                New vault
              </Button>
            </Box>
          )}
        </Box>
        {isMobile && (
          <Box mx={5} mt={4} mb={3}>
            <Typography variant="h2">{numberOfResults === 1 ? `${numberOfResults} result` : `${numberOfResults} results`}</Typography>
          </Box>
        )}
      </Box>
      <Box
        flex="1"
        height={isMobile ? "calc(100% - 126px)" : "calc(100% - 100px)"}
        position="absolute"
        top="100px"
        width={isMobile ? "100%" : "calc(100% - 250px)"}
      >
        <SearchResults />
      </Box>
    </>
  );
};

export default ShowSearchResults;
