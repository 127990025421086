import React, { useState, createContext, useContext } from "react";
import { AlertColor } from "@mui/material";
import { SnackbarOptions } from "../types/globalTypes";


interface SnackbarContextProps {
  snackbarToShow: SnackbarToShowProps;
  onSnackbarToShow: (type: SnackbarOptions | null, dynamicData?: any | null, severity?: AlertColor | null) => void;
  onSnackbarClose: () => void;
}

interface SnackbarToShowProps {
  open: boolean;
  type: SnackbarOptions | null;
  dynamicData: any | null;
  severity: AlertColor | null;
}

const Context = createContext({} as SnackbarContextProps);

const SnackbarContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [snackbarToShow, setSnackbarToShow] = useState<SnackbarToShowProps>({
    open: false,
    type: null,
    dynamicData: null,
    severity: null
  });

  const handleSnackbarToShow = (type: SnackbarOptions | null = null, dynamicData: any | null = null, severity: AlertColor | null = null) => {
    setSnackbarToShow({
      open: true,
      type: type,
      dynamicData: dynamicData,
      severity: severity
    });
  };

  const handleSnackbarClose = () => setSnackbarToShow({ ...snackbarToShow, open: false });

  return (
    <Context.Provider
      value={{
        snackbarToShow: snackbarToShow,
        onSnackbarToShow: handleSnackbarToShow,
        onSnackbarClose: handleSnackbarClose
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SnackbarContextProvider;

export const useSnackbarContext = (): SnackbarContextProps => useContext(Context);
