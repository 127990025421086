import React from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { createpaletteOptions, DefaultPaletteOptions, theme as akordTheme } from "../../../theme/mui";
import { orgPalette } from "../../../theme/orgPalette";
import { generateColorPalette } from "./theme-helper";
import { useOrgContext } from "../../../contexts/OrgContextProvider";
import { useRootContext } from "../../../contexts/RootContext";

type ThemeWrapperProps = {
  darkMode: boolean;
};

const ThemeWrapper: React.FC<React.ReactNode & ThemeWrapperProps> = ({ children, darkMode }) => {
  const { org, loadingOrg } = useOrgContext();
  const { subdomain } = useRootContext();
  const akordPalette = createpaletteOptions(darkMode);
  let mergedPalettes = akordPalette;

  const primary = org?.primaryColor || akordPalette.primary!.main;
  const secondary = org?.secondaryColor || akordPalette.info!.main;

  if (org?.subdomain === subdomain) {
    const orgPaletteReady = orgPalette(darkMode, primary, secondary);
    const mainColor = orgPaletteReady?.primary?.main || akordPalette.primary!.main;
    const secondaryColor = orgPaletteReady?.info?.main || akordPalette.info!.main;
    const fullSubPalette = generateColorPalette(mainColor, secondaryColor, orgPaletteReady);
    mergedPalettes = deepmerge(akordPalette, fullSubPalette);
  }

  const theme = createTheme(akordTheme(darkMode, mergedPalettes));

  // avoid layout jump on org loading
  if (subdomain && loadingOrg) return null;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeWrapper;
