import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonLink from "../../components/common/ButtonLink";
import { CssBaseline } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../../components/common/logo/logoOrange";
import ThemeWrapper from "../../components/common/Wrappers/ThemeWrapper";

const TeaPotPage = () => {
  const isMobile = useMediaQuery("(max-width:668px)");
  const loader = document.querySelector(".preloader");
  const showPreLoader = () => loader?.classList.remove("preloader");
  const addClass = () => loader?.classList.add("loader-hide");

  React.useEffect(() => {
    showPreLoader();
    addClass();
  }, []);

  return (
    <ThemeWrapper darkMode={true}>
      <CssBaseline />
      <Box display="flex" height="auto" width="100%" justifyContent="center">
        <Box
          mb={8}
          mx={5}
          display="flex"
          justifyContent="top"
          flexDirection="column"
          alignItems={isMobile ? "left" : "center"}
          maxWidth="544px"
          width="100%"
        >
          <Box mt={7} mb={8}>
            <Logo width="85" height="26" />
          </Box>

          <Box mb={2}>
            <Typography variant="h1" className="xxl">
              Sorry, but we’re blocked!
            </Typography>
          </Box>

          <Box mb={7}>
            <Typography
              variant="body2"
              style={{ textAlign: isMobile ? "left" : "center" }}
            >
              Unfortunately, privacy settings in this browser window block one
              of our APIs, preventing the app from running. You can always use
              Akord in private mode for Brave, Chrome or Safari.
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" mb={5} width="100%">
            <ButtonLink
              variant="outlined"
              onClick={() => window.location.replace("https://www.akord.com")}
              width={isMobile ? "xs" : "md"}
            >
              Back to akord.com
            </ButtonLink>
          </Box>
          <Box display="flex" justifyContent="center" mb={5} width="100%">
            <ButtonLink
              variant="outlined"
              onClick={() =>
                window.location.replace(
                  "https://discord.com/channels/890229689019432981/890867508716904479"
                )
              }
              width={isMobile ? "xs" : "md"}
            >
              Check out the Discord
            </ButtonLink>
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <ButtonLink
              variant="outlined"
              onClick={() =>
                window.location.replace("https://twitter.com/AkordTeam")
              }
              width={isMobile ? "xs" : "md"}
            >
              See what we’re tweeting about
            </ButtonLink>
          </Box>
        </Box>
      </Box>
    </ThemeWrapper>
  );
};

export default TeaPotPage;
