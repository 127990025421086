import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter } from "react-router-dom";
import { Membership } from "@akord/akord-js";
import { AvatarIcon } from "@akord/addon-icons";
import { Box, Typography, Button, CircularProgress, Theme, SelectChangeEvent } from "@mui/material";
import { colorMap } from "../../timeline/timeline-actions/colorMap";
import { ManageAccessStatusCase } from "./ManageAccessStatusCase";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { SupportScreenWrapper } from "../../../components/common";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { formatDate } from "../../../helpers/helpers";
import { memberStatusEnum } from "../../../helpers/akord-enums";
import { AVATAR_URL } from "../../../helpers/env";
import Avatar from "../../../components/common/Avatar/Avatar";

const useStyles = makeStyles<Theme>(theme => ({
  containedPrimary: {
    marginTop: theme.spacing(7),
    minWidth: "auto"
  },
  iconExtraLarge: {
    fontSize: "38px"
  },
  avatar: {
    width: "38px",
    height: "38px",
    borderRadius: "50%"
  },
  buttonProgress: {
    position: "absolute"
  }
}));

const ManageAccess: React.FC = () => {
  const { akord, isMobile, darkMode, onTxSpinner } = useGlobalContext();
  const { vault, currentMembership, members, onVaultUpdate: onVaultMembershipData } = useVaultContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { modal, onNotificationData } = useNotificationsContext();

  const [memberRole, setMemberRole] = useState<Membership[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const isRoomArchived = vault?.status === "ARCHIVED";
  const userRole = currentMembership?.role;
  const classes = useStyles({ darkMode: darkMode });

  React.useEffect(() => {
    const decryptAndSet = async () => {
      const activeMembers = members.filter(
        member =>
          member.role !== "OWNER" &&
          (member.status === memberStatusEnum.ACCEPTED ||
            member.status === memberStatusEnum.REVOKED ||
            member.status === memberStatusEnum.PENDING ||
            member.status === memberStatusEnum.REJECTED ||
            member.status === memberStatusEnum.INVITED)
      );
      activeMembers.map(member => (member.originalRole = member.role));
      setMemberRole(activeMembers);
    };

    if (members && members.length) decryptAndSet();
  }, [currentMembership, members]);

  const isButtonDisabled = () => {
    return !memberRole.some(member => member.role !== member.originalRole) || buttonLoading;
  };

  const handleFormChange = (e: SelectChangeEvent<any>, index: number, member: Membership) => {
    const updatedMemberRole = memberRole.map(memberState =>
      memberState?.email === member?.email
        ? {
            ...memberState,
            [e.target.name]: e.target.value
          }
        : memberState
    );
    setMemberRole(updatedMemberRole as Membership[]);
  };

  // To keep member role in sync in the state
  // Refetch will not happen
  // const updateMemberRoleState = (memberId, role) => {
  //   setMemberRole(
  //     memberRole.map(member =>
  //       member?.id === memberId
  //         ? {
  //             ...member,
  //             originalRole: role
  //           }
  //         : member
  //     )
  //   );
  // };

  const updateMembersRole = async () => {
    const changedRoleToSave = memberRole
      .filter(member => member.role !== member.originalRole)
      .map(member => {
        return { id: member.id, role: member.role, member: member };
      });

    changedRoleToSave.map(async member => {
      //@ts-ignore
      if (member.role === "REVOKED") {
        // Revoke access through Modal Confirm
        //TODO Cleanup data we sent for revoke user
        onNotificationData({
          //@ts-ignore
          memberToRevoke: { user: member.member.memberDetails },
          id: member.id
        });
        modal.onConfirmModalType("revoke");
        modal.onModalVisibility(null, "revoke");
      } else {
        onTxSpinner(true);
        setButtonLoading(true);
        // Change Access
        await akord?.membership.changeRole(member.id, member.role);
        // Change role optimistically
        // updateMemberRoleState(member.id, member.role);
        onVaultMembershipData();
        setButtonLoading(false);
        onTxSpinner(false);
        onSnackbarToShow("dataRoomAccessUpdate");
      }
    });
  };

  // if (!members || !members.length) return null;

  const filteredSortedMembers = members
    ?.sort((a, b) => {
      if (a.createdAt > b.createdAt) return 1;
      if (a.createdAt < b.createdAt) return -1;
      return 0;
    })
    //@ts-ignore
    .filter(item => item.status !== "REVOKED" || item.status !== "REJECTED");

  return (
    <SupportScreenWrapper title="Manage access" subtitle={vault?.name}>
      {filteredSortedMembers?.length > 0 && (
        <>
          <Box mt={6}>
            {filteredSortedMembers.map((member, index) => {
              if (userRole === "VIEWER" && member.status === "PENDING")
                //don't show pending for viewer
                return null;
              else
                return (
                  <Box
                    width="100%"
                    mb={6}
                    key={index}
                    display="flex"
                    alignItems={member?.memberDetails?.profileName ? "start" : "center"}
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center" width="75%">
                      {/* on small screens truncate long emails with width */}
                      {member.memberDetails?.address ? (
                        <Avatar
                          url={`${AVATAR_URL}/${member.memberDetails.address}`}
                          imageClassName={classes.avatar}
                          avatarClasses={{ fontSizeLarge: classes.iconExtraLarge }}
                          avatarFontSize="large"
                          avatarSx={{
                            color: member.status !== "PENDING" ? colorMap[index] : "#A3A09F",
                            opacity: member.status === "REVOKED" ? "40%" : "100%"
                          }}
                          imageStyle={{ opacity: member.status === "REVOKED" ? "40%" : "100%" }}
                        />
                      ) : (
                        <AvatarIcon
                          fontSize="large"
                          classes={{
                            fontSizeLarge: classes.iconExtraLarge
                          }}
                          sx={{
                            color: member.status !== "PENDING" ? colorMap[index] : "#A3A09F",
                            opacity: member.status === "REVOKED" ? "40%" : "100%"
                          }}
                        />
                      )}
                      <Box ml={3} width="75%">
                        {/* on small screens truncate long emails with width */}
                        <Typography component="p" variant="caption" color={member?.status === "REVOKED" ? "textSecondary" : "textPrimary"}>
                          {member?.memberDetails?.profileName || member?.email}
                        </Typography>
                        {member?.memberDetails?.profileName && !member?.expiresAt && (
                          <Typography variant="caption" color="textSecondary" component="p" noWrap>
                            {member?.email}
                          </Typography>
                        )}
                        {member?.expiresAt && (
                          <Typography variant="caption" color="textSecondary" component="p" noWrap>
                            Expires at {formatDate(new Date(member.expiresAt).getTime().toString(), false, false, true)}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      {memberRole && member.role !== "OWNER" ? (
                        <ManageAccessStatusCase
                          member={member}
                          memberRole={memberRole}
                          userRole={userRole}
                          index={index}
                          handleFormChange={handleFormChange}
                          isRoomArchived={isRoomArchived}
                        />
                      ) : (
                        // manageAccessStatusCase(member, memberRole, userRole, index, handleFormChange, isRoomArchived)
                        <Typography variant="caption" color="textSecondary">
                          Owner
                        </Typography>
                      )}
                    </Box>
                  </Box>
                );
            })}
          </Box>
          {userRole === "OWNER" && (
            <Button
              variant="contained"
              color="primary"
              type="button"
              disableElevation
              classes={{
                containedPrimary: classes.containedPrimary
              }}
              disabled={isButtonDisabled()}
              fullWidth={isMobile}
              onClick={updateMembersRole}
            >
              Save changes
              {buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          )}
        </>
      )}
    </SupportScreenWrapper>
  );
};

export default withRouter(ManageAccess);
