import React from 'react'
import MobileStepper from '@mui/material/MobileStepper'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 10,
    marginBottom: 8,
    background: 'none'
  }
}))

function Stepper({ current, total, ...rest }) {
  const classes = useStyles()

  return (
    <MobileStepper
      variant="dots"
      position="static"
      className={classes.root}
      backButton={<div />}
      nextButton={<div />}
      activeStep={current}
      steps={total}
      {...rest}
    />
  )
}

export default Stepper
