import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AssetsContextProvider from "../../contexts/AssetsContextProvider";
import InAppNotificationsContextProvider from "../../contexts/InAppNotificationsContext";
import NotificationsContextProvider from "../../contexts/NotificationsContextProvider";
import ProgressContextProvider from "../../contexts/ProgressContext";
import VaultContextProvider from "../../contexts/VaultContextProvider";
import StorageContextProvider from "../../contexts/StorageContextProvider";
import Notifications from "../../pages/notifications";
import ShowSearch from "../../pages/search/ShowSearch";
import ShowSearchResults from "../../pages/search/ShowSearchResults";
import Settings from "../../pages/account";
import SettingsDisplay from "../../pages/account/Display";
import SettingsNotifications from "../../pages/account/Notifications";
import SettingsSecurity from "../../pages/account/Security";
import SettingsDeveloper from "../../pages/account/DeveloperSettings";
import SecurityBackup from "../../pages/account/SecurityBackup";
import SecurityChangePassword from "../../pages/account/SecurityChangePassword";
import Storage from "../../pages/storage";
import { SafeWrapper } from "../common/";
import NotificationsWrapper from "../common/Wrappers/NotificationsWrapper";
import RoutesRooms from "./RoutesRooms";
import RoutesVaultCreate from "./RoutesVaultCreate";
import ChatContextProvider from "../../contexts/ChatContextProvider";
import SecurityToggleMfa from "../../pages/account/SecurityToggleMfa";
import SecurityPhoneNumberMfa from "../../pages/account/SecurityPhoneNumberMfa";
import SecurityPhoneNumberMfaVerify from "../../pages/account/SecurityPhoneNumberMfaVerify";
import SecurityTotpMfa from "../../pages/account/SecurityTotpMfa";
import SecurityTotpMfaVerify from "../../pages/account/SecurityTotpMfaVerify";
import Invoices from "../../pages/account/Invoices";
import Organisation from "../../pages/account/Organisation";
import OrganisationMembers from "../../pages/account/OrganisationMembers";
import OrganisationInvite from "../../pages/account/OrganisationInvite";
import OrgContextProvider from "../../contexts/OrgContextProvider";
import PlansAndPayments from "../../pages/account/PlansAndPayments";
import PricingPlans from "../../pages/account/PricingPlans";
import BillingAndPaymentDetails from "../../pages/account/BillingAndPaymentDetails";
import PaymentContextProvider from "../../contexts/PaymentContext";
import SubscriptionSetup from "../../pages/payments/SubscriptionSetup";
import OneTimeTopUp from "../../pages/payments/OneTimeTopUp";
import OrganisationDetails from "../../pages/account/OrganisationDetails";
import Rewards from "../../pages/rewards";
import RewardsContext from "../../contexts/RewardsContext";

function Home(props) {
  return (
    <NotificationsContextProvider>
      <StorageContextProvider>
        <PaymentContextProvider>
          <VaultContextProvider>
            <ChatContextProvider>
              <ProgressContextProvider>
                <AssetsContextProvider>
                  <InAppNotificationsContextProvider>
                    <OrgContextProvider>
                      <SafeWrapper>
                        <NotificationsWrapper>
                          <Switch>
                            <Route path="/vaults" render={() => <RoutesRooms />} />
                            <Route path="/create-vault" render={() => <RoutesVaultCreate />} />
                            <Route path="/search" render={() => <ShowSearch />} />
                            <Route path="/search-results" render={() => <ShowSearchResults />} />
                            <Route path="/storage" exact render={() => <Storage />} />
                            <Route path="/top-up" exact render={() => <OneTimeTopUp />} />
                            <Route path="/notifications" exact render={() => <Notifications />} />
                            <Route
                              path="/rewards"
                              exact
                              render={() => (
                                <RewardsContext>
                                  <Rewards />
                                </RewardsContext>
                              )}
                            />
                            <Route path="/account" exact render={() => <Settings />} />
                            <Route path="/account/notifications" exact render={() => <SettingsNotifications {...props} />} />
                            <Route path="/account/display" exact render={() => <SettingsDisplay {...props} />} />
                            <Route path="/account/security" exact render={() => <SettingsSecurity {...props} />} />
                            <Route path="/account/organisation" exact render={() => <Organisation />} />
                            <Route path="/account/organisation/details" exact render={() => <OrganisationDetails />} />
                            <Route path="/account/organisation/members" exact render={() => <OrganisationMembers />} />
                            <Route path="/account/organisation/invite" exact render={() => <OrganisationInvite />} />
                            <Route path="/account/developers" exact render={() => <SettingsDeveloper />} />
                            <Route path="/account/plans-and-payments" exact render={() => <PlansAndPayments />} />
                            <Route path="/account/plans-and-payments/billing" exact render={() => <SubscriptionSetup />} />
                            <Route path="/account/plans-and-payments/pricing-plans" exact render={() => <PricingPlans />} />
                            <Route path="/account/plans-and-payments/invoices" exact render={() => <Invoices />} />
                            <Route
                              path="/account/plans-and-payments/billing-and-payment-details"
                              exact
                              render={() => <BillingAndPaymentDetails />}
                            />
                            <Route path="/account/security/backup-phrase" exact render={() => <SecurityBackup {...props} />} />
                            <Route path="/account/security/change-password" exact render={() => <SecurityChangePassword {...props} />} />
                            <Route
                              path="/account/security/two-factor-authentication"
                              exact
                              render={() => <SecurityToggleMfa {...props} />}
                            />
                            <Route
                              path="/account/security/two-factor-authentication/phone-number"
                              exact
                              render={() => <SecurityPhoneNumberMfa {...props} />}
                            />
                            <Route
                              path="/account/security/two-factor-authentication/phone-number/verify"
                              exact
                              render={() => <SecurityPhoneNumberMfaVerify {...props} />}
                            />
                            <Route
                              path="/account/security/two-factor-authentication/totp"
                              exact
                              render={() => <SecurityTotpMfa {...props} />}
                            />
                            <Route
                              path="/account/security/two-factor-authentication/totp/verify"
                              exact
                              render={() => <SecurityTotpMfaVerify {...props} />}
                            />
                            {/* If no route found redirect */}
                            <Route path="/" exact render={() => <Redirect to="/vaults/active" />} />
                            <Redirect to="/404" />
                          </Switch>
                        </NotificationsWrapper>
                      </SafeWrapper>
                    </OrgContextProvider>
                  </InAppNotificationsContextProvider>
                </AssetsContextProvider>
              </ProgressContextProvider>
            </ChatContextProvider>
          </VaultContextProvider>
        </PaymentContextProvider>
      </StorageContextProvider>
    </NotificationsContextProvider>
  );
}

export default withRouter(Home);
