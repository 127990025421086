import React, { createContext, useContext, useEffect, useState } from "react";
import { GraphQLSubscription } from "@aws-amplify/api";
import { GraphQLAPI } from "@aws-amplify/api-graphql";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation, useHistory } from "react-router-dom";
import { AkordWallet, base64ToJson } from "@akord/crypto";
import { Akord, Auth, Membership, Vault } from "@akord/akord-js";
import { memberStatusEnum } from "../helpers/akord-enums";
import { actionRefs } from "@akord/akord-js/lib/constants";
import { ZenObservable } from "zen-observable-ts";
import { Types, subscriptions } from "@akord/gql";
import ContractsWorker from "worker-loader!../workers/contracts-worker.js";
import { AKORD_ENV, CACHE_ONLY_SUBDOMAINS, CLIENT_CONFIG } from "../helpers/env";
import { KEEP_SIGNED_IN } from "../handlers/auth/constants";
import * as helpers from "../helpers/helpers";
import { READ_ONLY_MODE, SERIOUS_ISSUE_MODE, MAINTENANCE_MODE_ENABLED } from "../helpers/env";
import { signInWithWallet } from "../handlers/auth";
import { useRootContext } from "./RootContext";
import { useOrgContext } from "./OrgContextProvider";
import { ColorModeProps, GlobalDataProps, Order, OrderBy, UserAttributesProps } from "../types/globalDataTypes";
import { getInvites } from "../helpers/api-helpers";

interface LocationState extends Location {
  wallet?: AkordWallet;
}

const readOnlyMode = /true/.test(READ_ONLY_MODE);
const seriousIssueMode = /true/.test(SERIOUS_ISSUE_MODE);
const maintenanceModeEnabled = /true/.test(MAINTENANCE_MODE_ENABLED);
const VAULT_ACTIONS = [actionRefs.VAULT_RENAME, actionRefs.VAULT_ARCHIVE, actionRefs.VAULT_RESTORE, actionRefs.VAULT_DELETE];

const Context = createContext<GlobalDataProps>({} as GlobalDataProps);

const GlobalDataProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handleIsAuthenticated = (value: boolean) => setIsAuthenticated(value);

  const [akord, setAkord] = useState<Akord>();
  const handleAkord = (value: Akord) => setAkord(value);

  const [contractsWorker, setContractsWorker] = useState<BroadcastChannel>();
  const handleContractsWorker = (value: BroadcastChannel) => setContractsWorker(value);

  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const handleIsAuthLoaded = (value: boolean) => setIsAuthLoaded(value);

  const [isDarkMode, setIsDarkMode] = useState(false);
  const handleIsDarkMode = (value: boolean) => setIsDarkMode(value);

  const [activeVaults, setActiveVaults] = useState<Vault[]>([]);
  const handleActiveVaults = (value: Vault[]) => {
    setActiveVaults(value);
  };

  const [archivedVaults, setArchivedVaults] = useState<Vault[]>([]);
  const handleArchivedVaults = (value: Vault[]) => setArchivedVaults(value);

  const [processingVaults, setProcessingVaults] = useState<Vault[]>([]);
  const handleProcessingVaults = (vaults: Vault[]) => setProcessingVaults(vaults);

  const [vaultsIsLoading, setVaultsIsLoading] = useState(true);
  const handleVaultsIsLoading = (isLoading: boolean) => setVaultsIsLoading(isLoading);

  const [pendingMemberships, setPendingMemberships] = useState<Membership[]>([]);
  const handlePendingMemberships = (memberships: Membership[]) => setPendingMemberships(memberships);

  const [pendingVaults, setPendingVaults] = useState<Vault[]>([]);
  const handlePendingVaults = (vaults: Vault[]) => setPendingVaults(vaults);

  const [profileDetails, setProfileDetails] = useState<Types.User>();
  const handleProfileDetails = (value: Types.User) => setProfileDetails(value);

  const [notification, setNotification] = useState<any>();
  const handleNotification = (value: any) => setNotification(value);

  const [userColorMode, setUserColorMode] = useState<ColorModeProps>("auto");
  const handleUserColorMode = (mode: ColorModeProps) => setUserColorMode(mode);

  const [userAttributes, setUserAttributes] = useState<UserAttributesProps>({} as UserAttributesProps);
  const handleUserAttributes = (attribute: UserAttributesProps) => {
    setUserAttributes({ ...userAttributes, ...attribute });
  };

  const [error, setError] = useState();
  const handleError = (error: any) => setError(error);

  const [wallet, setWallet] = useState<AkordWallet>();
  const handleWallet = (wallet?: AkordWallet) => setWallet(wallet);

  const [screenWidth, setScreenWidth] = useState<"xs" | "sm" | "md" | "lg" | "xl" | undefined>();

  const [txSpinner, setTxSpinner] = useState(false);
  const handleTxSpinner = (mode: boolean) => setTxSpinner(mode);

  const [decrptSpinner, setDecrptSpinner] = useState(false);
  const handleDecrptSpinner = (mode: boolean) => setDecrptSpinner(mode);
  // Show info Drawer
  const [showInfo, setShowInfo] = useState(false);
  const handleShowInfo = (value: boolean) => setShowInfo(value);
  // Show tags and description Drawer
  const [showDescriptionDrawer, setShowDescriptionDrawer] = useState(false);
  const handleShowDescriptionDrawer = (value: boolean) => setShowDescriptionDrawer(value);

  const [orderVaults, setOrderVaults] = useState<Order>("asc");
  const [orderVaultsBy, setOrderVaultsBy] = useState<OrderBy>("name");

  const location = useLocation<LocationState>();
  const [roomsMenuOpen, setRoomsMenuOpen] = useState<boolean>(!!location?.pathname && !!location.pathname.match("/vaults"));

  const history = useHistory();
  const { subdomain } = useRootContext();
  const { getUserOrg } = useOrgContext();

  const isPublicRoute = !!location?.pathname.match("/public/"); // All anonymous routes have `/public` prefix

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const xs = useMediaQuery("(max-width:668px)");
  const sm = useMediaQuery("(max-width:960px)");
  const md = useMediaQuery("(max-width:1280px)");
  const lg = useMediaQuery("(max-width:1920px)");
  const xl = useMediaQuery("(min-width:1920px)");

  const archivedVaultsRef = React.useRef<Vault[]>([]);
  const activeVaultsRef = React.useRef<Vault[]>([]);
  /**
   * Tries to authenticate the user.
   * Authentication retry is used to support multi tab light session.
   * Light session -> data stored in SessionStorage.
   * Information about light app session opened in other tab is auxiliary (local storage events) thus cannot be synchronized
   */
  useEffect(() => {
    let retry = true;
    const authenticate = async () => {
      if (sessionStorage.getItem(KEEP_SIGNED_IN) === "false") {
        Auth.configure({ env: AKORD_ENV, storage: window.sessionStorage });
      }
      await authenticateUser();
      await loadUserData();
    };

    const authenticateUser = async () => {
      try {
        const attributes = await Auth.getUserAttributes();
        if (!attributes["custom:publicSigningKey"] || !attributes["custom:address"]) {
          throw Error("User not initialized");
        }
        handleIsAuthenticated(true);
        handleIsAuthLoaded(true);
      } catch (err) {
        if (retry) {
          setTimeout(() => {
            authenticate();
            retry = false;
          }, 500);
        } else {
          await AkordWallet.clear();
          localStorage.clear();
          sessionStorage.clear();
          handleIsAuthenticated(false);
          handleIsAuthLoaded(true);
        }
      }
    };
    const loadUserData = async () => {
      try {
        const attributes = await Auth.getUserAttributes();
        const { session } = await Auth.getCurrentSessionUser();
        const groups = session.getIdToken().payload["cognito:groups"] || [];
        getUserOrg();

        const email = attributes["email"];
        const publicSigningKey = attributes["custom:publicSigningKey"];
        const address = attributes["custom:address"];
        const storageAddress = attributes["custom:storageAddress"] || address;
        const userColorMode: ColorModeProps = attributes["custom:mode"];
        const userLegacyVaultsNotified = attributes["custom:legacyVaultsNotified"];
        const userReferralId = attributes["custom:referralId"];
        const userReferrerId = attributes["custom:referrerId"];
        // const userOnboarding = /true/.test(attributes["custom:onboarding"]);
        const userUploadNotification = /true/.test(attributes["custom:notifyOnUpload"]);
        const referrals = attributes["custom:referrals"] || "0";
        const passwordless = /true/.test(attributes["custom:passwordless"]);

        if (location?.state?.wallet && location.state.wallet instanceof AkordWallet) {
          // On first airdrop link we get AkordWallet from Login Context (signInWithWallet)
          handleWallet(location.state.wallet);
        } else if (sessionStorage.getItem("AirdropLocation")) {
          // On reload import wallet from the storage
          const airdropLocation = JSON.parse(sessionStorage.getItem("AirdropLocation")!);
          const fromUrlParams = new URLSearchParams(airdropLocation?.search);
          const walletPhrase = fromUrlParams.get("wallet");
          const savedWallet = await signInWithWallet(walletPhrase);
          handleWallet(savedWallet);
          // On reusing shared airdrop link redirect
          if (location.search.includes("?wallet=")) history.push(location.pathname);
        } else {
          const userWallet = await AkordWallet.importFromKeystore(attributes["custom:encBackupPhrase"]);
          handleWallet(userWallet);
        }
        handleUserColorMode(userColorMode);
        handleUserAttributes({
          email: email,
          publicSigningKey: publicSigningKey,
          address: address,
          storageAddress: storageAddress,
          notifyOnUpload: userUploadNotification,
          mode: userColorMode,
          legacyVaultsNotified: userLegacyVaultsNotified?.split(",") || [],
          userOnboarding: false,
          userReferralId: userReferralId,
          userReferrerId: userReferrerId,
          referrals: parseInt(referrals),
          passwordless: passwordless,
          groups: groups
        });
      } catch (err) {
        await Auth.signOut();
        // if user is not logged in redirect here
        // pass the original url to redirect after the login ex /storage
        history.push({ pathname: maintenanceModeEnabled ? "/maintenance" : "/login", state: { from: location } });
      }
    };

    !isPublicRoute && authenticate();

    return () => {
      handleWallet();
      handleUserColorMode("auto");
    };
  }, []);

  /**
   * Validate authenticated user agains uid query param.
   * UID could be passed e.g. from email in magic link - this is to avoid using different Akord account than dedicated in magic link
   */
  useEffect(() => {
    if (isAuthenticated) {
      validateAuthSession();
    }
  }, [isAuthenticated]);

  /**
   * Initializes the akord-js client
   */
  useEffect(() => {
    const setupAkordClient = async () => {
      const akordClient = new Akord(isPublicRoute ? undefined : wallet!, CLIENT_CONFIG);
      handleAkord(akordClient);
    };
    if ((isAuthenticated && wallet) || isPublicRoute) {
      setupAkordClient();
    }
  }, [wallet, isAuthenticated]);

  /**
   * Loads app profile data (with retry to handle first login case when profile is creating as a background process)
   */
  useEffect(() => {
    let retry = true;
    const loadUserData = async () => {
      try {
        const user = (await akord!.profile.get()) as Types.User;
        handleProfileDetails(user);
      } catch (e) {
        console.log(e);
        if (retry) {
          setTimeout(() => {
            loadUserData();
            retry = false;
          }, 5000);
        }
      }
    };
    if (akord && isAuthenticated) {
      loadUserData();
    }
  }, [akord, isAuthenticated]);

  /**
   * Loads app vaults list
   */
  useEffect(() => {
    const loadData = async () => {
      if (isAuthenticated && Object.keys(userAttributes).length) {
        await getVaults();
        await getPendingVaults();
      }
    };
    if (akord) {
      loadData();
    }
  }, [akord, isAuthenticated, userAttributes]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const refresh = urlParams.get("refresh");
    if (refresh && location.pathname.match("/vaults/active")) {
      getVaults();
      history.push(location.pathname);
    }
  }, [location.search, location.pathname]);

  /**
   * Init app cache building (in web worker as background process)
   */
  useEffect(() => {
    const loadData = async () => {
      const worker: BroadcastChannel = new ContractsWorker();
      handleContractsWorker(worker);
      const { jwt, backupPhrase } = await getAuthData();
      worker.postMessage({
        backupPhrase: backupPhrase,
        jwt: jwt,
        config: CLIENT_CONFIG
      });
    };
    if (wallet) {
      loadData();
    }
  }, [wallet]);

  /**
   * Debounced vault cache update
   */
  useEffect(() => {
    const debouncedUpdateVaultCache = setTimeout(async () => {
      if (contractsWorker && notification) {
        const { jwt, backupPhrase } = await getAuthData();
        contractsWorker.postMessage({
          backupPhrase: backupPhrase,
          jwt: jwt,
          config: CLIENT_CONFIG,
          vaultId: notification.vaultId
        });
      }
    }, 5000);

    return () => clearTimeout(debouncedUpdateVaultCache);
  }, [contractsWorker, notification]);

  /**
   * Sets up app data subscription
   */
  useEffect(() => {
    let subscription: ZenObservable.Subscription;
    const setupSubscription = async () => {
      try {
        if (!isAuthenticated || !wallet || Object.entries(wallet).length === 0) return;
        const address = await wallet.getAddress();
        subscription = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onCreateNotification,
          variables: {
            filter: {
              toAddress: { eq: address }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: any) => {
            const notification: any = value.data.onCreateNotification;
            handleNotification(notification);
            if (VAULT_ACTIONS.includes(notification.event) || notification.event === actionRefs.MEMBERSHIP_ACCEPT) {
              await getVaults();
            } else if (notification.event === actionRefs.MEMBERSHIP_INVITE || notification.event === actionRefs.MEMBERSHIP_CONFIRM) {
              getPendingVaults();
            }
          },
          error: (e: any) => {
            console.log(e);
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Subscription error: ", err);
      }
    };
    if (window.navigator.onLine && !isPublicRoute && wallet && akord) setupSubscription();
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [isPublicRoute, akord, wallet]);

  /**
   * Sets up vaults data subscription to account for size updates
   * This limits the number of requests when uploading multiple files
   */
  useEffect(() => {
    let subscription: ZenObservable.Subscription;
    const setupSubscription = async () => {
      try {
        if (!isAuthenticated || !wallet || Object.entries(wallet).length === 0) return;
        const address = await wallet.getAddress();
        subscription = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onUpdateVault,
          variables: {
            filter: {
              owner: { eq: address }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: any) => {
            // With this sub we just want to update vault's size intead of full refetch to speed things up
            const vault: Vault = value.data.onUpdateVault;
            if (vault.status !== "ARCHIVED") updateVaultSize(vault);
          },
          error: (e: any) => {
            console.log(e);
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Subscription error: ", err);
      }
    };
    if (window.navigator.onLine && !isPublicRoute && wallet && akord) setupSubscription();
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [isPublicRoute, akord, wallet]);

  /**
   * Sets up user data subscription
   */
  useEffect(() => {
    let subscription: ZenObservable.Subscription;
    const setupSubscription = async () => {
      try {
        if (!isAuthenticated || !wallet || Object.entries(wallet).length === 0) return;
        const address = await wallet.getAddress();
        subscription = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onUpdateUser,
          variables: {
            filter: {
              address: { eq: address }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: any) => {
            const user: any = value.data.onUpdateUser;
            handleProfileDetails(user);
          },
          error: (e: any) => {
            console.log(e);
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Subscription error: ", err);
      }
    };
    if (window.navigator.onLine && !isPublicRoute && wallet && akord) setupSubscription();
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [isPublicRoute, akord, wallet]);

  /**
   * Reacts on dark/light mode preference change
   */
  useEffect(() => {
    const loadColorMode = async () => {
      //get display mode
      try {
        const attributes = await Auth.getUserAttributes();
        const mode = attributes["custom:mode"];
        if (mode === "dark") {
          handleIsDarkMode(true);
        } else if (mode === "light") {
          handleIsDarkMode(false);
        } else {
          handleIsDarkMode(prefersDarkMode);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isAuthenticated) {
      loadColorMode();
    } else {
      handleIsDarkMode(true);
    }
  }, [isAuthenticated, prefersDarkMode]);

  /**
   * Turn off the spinner on location change
   */
  useEffect(() => {
    handleDecrptSpinner(false);
  }, [location]);

  const getAuthData = async () => {
    if (!isPublicRoute) {
      const jwt = await Auth.getAuthToken();
      return {
        jwt: jwt,
        backupPhrase: wallet?.backupPhrase
      };
    }
    return { jwt: null, encBackupPhrase: null };
  };

  const getVaults = async () => {
    handleVaultsIsLoading(true);
    const allVaults = await akord?.vault.listAll();
    const active: Vault[] = [];
    const archived: Vault[] = [];
    allVaults
      ?.filter(vault => {
        if (subdomain && CACHE_ONLY_SUBDOMAINS.includes(subdomain)) {
          return vault.cloud;
        } else {
          return true;
        }
      })
      ?.forEach(vault => {
        if (vault.status === "ACTIVE") {
          active.push(vault);
        } else if (vault.status === "ARCHIVED") {
          archived.push(vault);
        }
      });
    archivedVaultsRef.current = archived;
    activeVaultsRef.current = active;
    handleActiveVaults(active);
    handleArchivedVaults(archived);
    handleVaultsIsLoading(false);
  };

  const updateVault = (vaultData: Vault) => {
    const allVaults = [...activeVaults, ...archivedVaults];
    const active: Vault[] = [];
    const archived: Vault[] = [];
    allVaults?.forEach(vault => {
      if (vault.id === vaultData.id) {
        vault = vaultData;
      }
      if (vault.status === "ACTIVE") {
        active.push(vault);
      } else if (vault.status === "ARCHIVED") {
        archived.push(vault);
      }
    });
    archivedVaultsRef.current = archived;
    activeVaultsRef.current = active;
    handleActiveVaults(active);
    handleArchivedVaults(archived);
  };

  const updateVaultSize = (updatedVault: Vault) => {
    //Only update active vaults, since archived vaults can't be updated (//TODO double check)
    const existingVaultIndex = activeVaultsRef.current.findIndex(activeVault => activeVault?.id === updatedVault.id);
    if (existingVaultIndex !== -1) {
      if (updatedVault.updatedAt > activeVaultsRef.current[existingVaultIndex].updatedAt) {
        activeVaultsRef.current[existingVaultIndex] = { ...activeVaultsRef.current[existingVaultIndex], size: updatedVault.size } as Vault;
        handleActiveVaults(activeVaultsRef.current);
      }
    }
  };

  const deleteVault = (id: string) => {
    archivedVaultsRef.current = archivedVaultsRef.current.filter(vault => vault.id !== id);
    handleArchivedVaults(archivedVaultsRef.current);
  };

  // Sorting vaults handler
  const handleRequestSortVaults = (event: React.MouseEvent<HTMLAnchorElement>, property: OrderBy) => {
    const isAsc = orderVaultsBy === property && orderVaults === "asc";
    setOrderVaults(isAsc ? "desc" : "asc");
    setOrderVaultsBy(property);
  };
  // Centralized sorted active vaults to use in the menu and vaults list
  const sortedActiveVaults = helpers.stableSort(activeVaults, helpers.getComparator(orderVaults, orderVaultsBy)) as Vault[];

  const getPendingVaults = async () => {
    const attributes: UserAttributesProps = await Auth.getUserAttributes();
    const memberships = await getInvites(attributes.email);
    if (!memberships) return;
    // Don't fetch vaults for INVITED - we don't have keys yet
    const pendingMemberships = memberships.filter(membership => membership.status !== memberStatusEnum.INVITED || membership.vault.public);
    if (pendingMemberships.length > 0 && akord) {
      const pendingVaults = [];
      for (const membership of pendingMemberships) {
        const vault = new Vault(membership.vault, membership.keys);
        await vault.decrypt();
        pendingVaults.push(vault);
      }
      handlePendingVaults(pendingVaults);
    }
    handlePendingMemberships(memberships);
  };

  /**
   *
   * Force logout when
   * - cognito uid does not match currently authenticated user
   */
  const validateAuthSession = async () => {
    const user = await Auth.getUser();
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.has("uid")) {
      const uid = urlParams.get("uid");
      const { email } = base64ToJson(uid!) as any;
      if (user.attributes.email !== email) {
        handleIsAuthenticated(false);
        await Auth.signOut();
        history.push("/login?uid=" + uid);
      }
    } else if (urlParams.has("wallet")) {
      const wallet = urlParams.get("wallet");
      handleIsAuthenticated(false);
      await Auth.signOut();
      history.push("/login?wallet=" + wallet);
    }
  };

  const screenWidthCalc = () => {
    if (xs) return "xs";
    if (sm) return "sm";
    if (md) return "md";
    if (lg) return "lg";
    if (xl) return "xl";
  };

  useEffect(() => {
    setScreenWidth(screenWidthCalc());
  });

  // DESKTOP LEFT SIDEBAR ROOMS VIEW
  const onDataRoomsExpand = () => {
    setRoomsMenuOpen(!roomsMenuOpen);
  };

  const withTxSpinner = React.useCallback(async (func: () => Promise<any>) => {
    handleTxSpinner(true);
    try {
      return await func();
    } catch (e) {
      console.warn(e);
      // throw new Error(e);
    } finally {
      handleTxSpinner(false);
    }
  }, []);

  return (
    <Context.Provider
      value={{
        isAuthenticated: isAuthenticated,
        onIsAuthenticated: handleIsAuthenticated,
        akord: akord,
        contractsWorker: contractsWorker,
        isAuthLoaded: isAuthLoaded,
        error: error,
        onError: handleError,
        profileDetails: profileDetails,
        onGetVaults: getVaults,
        archivedVaults: archivedVaults,
        activeVaults: activeVaults,
        onActiveVaults: handleActiveVaults,
        activeVaultsRef: activeVaultsRef,
        onVaultUpdate: updateVault,
        onVaultDelete: deleteVault,
        sortedActiveVaults: sortedActiveVaults,
        onRequestSortVaults: handleRequestSortVaults,
        orderVaults: orderVaults,
        orderVaultsBy: orderVaultsBy,
        vaultsIsLoading: vaultsIsLoading,
        pendingVaults: pendingVaults,
        pendingMemberships: pendingMemberships,
        onPendingMemberships: handlePendingMemberships,
        onProcessingVaults: handleProcessingVaults,
        processingVaults: processingVaults,
        wallet: wallet,
        handleWallet: handleWallet,
        width: screenWidth,
        isMobile: screenWidth === "xs",
        onIsDarkMode: handleIsDarkMode,
        darkMode: isDarkMode,
        onUserColorMode: handleUserColorMode,
        userColorMode: userColorMode,
        userAttributes: userAttributes,
        onUserAttributes: handleUserAttributes,
        roomsMenu: {
          roomsMenuOpen: roomsMenuOpen,
          onDataRoomsExpand: onDataRoomsExpand
        },
        onTxSpinner: handleTxSpinner,
        txSpinner: txSpinner,
        withTxSpinner: withTxSpinner,
        onDecrptSpinner: handleDecrptSpinner,
        decrptSpinner: decrptSpinner,
        notification: notification,
        onShowInfo: handleShowInfo,
        showInfo: showInfo,
        onShowDescriptionDrawer: handleShowDescriptionDrawer,
        showDescriptionDrawer: showDescriptionDrawer,
        readOnlyMode: readOnlyMode,
        seriousIssueMode: seriousIssueMode
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default GlobalDataProvider;

export const withWallet = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{wallet => <Component {...props} {...wallet} />}</Context.Consumer>;

export const useGlobalContext = () => useContext(Context);
