import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { getStorage } from "../../helpers/api-helpers";
import FileDropArea from "../../components/common/FileDropArea/FileDropArea";
import InfoWarningBox from "../../components/common/InfoWarningBox/InfoWarningBox";
import { useNftContext } from "../../contexts/NftContextProvider";
import useMint from "../../hooks/useMint";
import { getVaultId } from "../../helpers/helpers";
import ProgressBarMint from "../../components/common/ProgressBars/ProgressBarMint";

const CollectionDisplayForm: React.FC<RouteComponentProps> = ({ history }) => {
  const [minting, setMinting] = useState(false);
  const [notEnoughStorage, setNotEnoughStorage] = useState(false);

  const vaultId = getVaultId(location.pathname);
  const { mintCollection } = useMint(vaultId);

  const { isMobile } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { onNFTMetadata, nftMetadata, refreshNfts, filesCollection } = useNftContext();

  const onHandleMint = async () => {
    try {
      setMinting(true);

      if (!filesCollection?.length) {
        throw new Error("Failed uploading the file");
      }
      const storage: any = await getStorage(); //check if there is a storage type
      const calculatedFilesSize =
        filesCollection?.reduce((acc, file) => acc + file.size, 0) + nftMetadata.thumbnail?.size! + nftMetadata.banner?.size!;
      // Check if a user has enough storage available
      if (calculatedFilesSize > storage?.storage_available) {
        console.error("Not enough storage available.");
        setMinting(false);
        setNotEnoughStorage(true);
      } else {
        await mintCollection(filesCollection!);
        onSnackbarToShow("nftCollectionMinted");
        setMinting(false);
        const abortController = new AbortController();
        refreshNfts(abortController);
        history.push(`/vaults/active/${vaultId}/nfts`);
      }
    } catch (error) {
      console.error(error);
      setMinting(false);
      onSnackbarToShow("genericError", null, "error");
      history.push(`/vaults/active/${vaultId}/nfts`);
    }
  };

  const handleSingleFileUpdate = (keyValue: string) => (file: File) => {
    onNFTMetadata({
      ...nftMetadata,
      [keyValue]: file
    });
  };

  return (
    <SupportScreenWrapper title="Collection display" hideChevron fullHeight>
      <Box height="auto" display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"} mt={6}>
        <InfoWarningBox text="Thumbnail and banner images help display your collection on any marketplaces where your collection may be listed. These files will be stored permanently on-chain so you will need to pay for storage." />
        <Box mb={2}>
          <Box display="flex" alignItems="flex-start" mb={1}>
            <Typography variant="h3">Thumbnail image (optional)</Typography>
          </Box>
          <Typography variant="body2" className="small">
            Recommended size 200x200 pixels.
          </Typography>
        </Box>
        <FileDropArea onSetFile={handleSingleFileUpdate("thumbnail")} width="155px" height="155px" />
        <Box mb={2} mt={7}>
          <Box display="flex" alignItems="flex-start" mb={1}>
            <Typography variant="h3">Banner image (optional)</Typography>
          </Box>
          <Typography variant="body2" className="small">
            Recommended size 1600x900 pixels.
          </Typography>
        </Box>
        <FileDropArea onSetFile={handleSingleFileUpdate("banner")} width="335px" height="188px" />
        {notEnoughStorage && (
          <Box>
            <Typography variant="caption" color="error">
              This file exceeds the storage limit on your account. You can{" "}
              <Link onClick={() => window.open("https://v2.akord.com/top-up")}>top up</Link> or{" "}
              <Link onClick={() => window.open("https://v2.akord.com/account/plans-and-payments/pricing-plans")}>upgrade</Link> your
              subscription plan to add more storage.
            </Typography>
          </Box>
        )}
        {minting ? (
          <ProgressBarMint />
        ) : (
          <ButtonGroup
            nextDiasabled={minting || notEnoughStorage}
            loading={minting}
            handleEnd={() => onHandleMint()}
            handleBack={() => history.goBack()}
            nextText="Mint collection"
            hideIcon
          />
        )}
      </Box>
    </SupportScreenWrapper>
  );
};

export default withRouter(CollectionDisplayForm);
