import { akordLinks } from "../../../helpers/akordTexts";
import { getVaultStatus, getVaultId, getFolderId } from "../../../helpers/helpers";
import {
  AvatarIcon,
  LogoutIcon,
  SupportIcon,
  DiscordIcon,
  UploadIcon,
  NoteIcon,
  FolderOutlineIcon,
  UploadFolderIcon,
  ImportIcon,
  DropboxIcon,
  ArweaveIcon,
  SettingsIcon,
  CreditCardIcon,
  CodeIcon,
  ZipIcon
} from "@akord/addon-icons";
import { AddToDrive } from "@mui/icons-material";
import { Auth } from "@akord/akord-js";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useGDriveImporter } from "../../file/FileGDriveImporter";
import { useDropboxImporter } from "../../file/FileDropboxImporter";
import { GOOGLE_IMPORT_ENABLED, DROPBOX_IMPORT_ENABLED } from "../../../helpers/env";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { Props } from "@akord/addon-icons/lib/components/Icon";

export type DropDownMenuProps = {
  icon: (props: Props) => React.ReactElement<any, any>;
  text: string;
  action: React.MouseEventHandler<HTMLLIElement>;
  disabled?: boolean;
  divider?: boolean;
  hidden?: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
  upload?: boolean;
  isNested?: boolean;
  children?: DropDownMenuProps[];
};

export const useAvatarMenuOptions = () => {
  const { onIsAuthenticated, userAttributes } = useGlobalContext();
  const history = useHistory();

  const avatarOptions = (onClose: () => void): DropDownMenuProps[] => {
    return [
      {
        icon: AvatarIcon,
        text: "Profile",
        action: () => {
          history.push("/profile");
          onClose();
        },
        hidden: !!userAttributes.passwordless
      },
      {
        icon: SettingsIcon,
        text: "Account",
        action: () => {
          history.push("/account");
          onClose();
        },
        hidden: !!userAttributes.passwordless
      },
      {
        icon: CreditCardIcon,
        text: "Plans and billing",
        action: () => {
          history.push("/account/plans-and-payments");
          onClose();
        },
        hidden: !!userAttributes.passwordless
      },
      {
        icon: CodeIcon,
        text: "Developers",
        action: () => {
          history.push("/account/developers");
          onClose();
        },
        hidden: !!userAttributes.passwordless
      },
      {
        icon: SupportIcon,
        text: "Support",
        // for email use location.replace
        action: () => {
          window.location.replace(akordLinks["support"]);
          onClose();
        },
        hidden: !!userAttributes.passwordless
      },
      // TODO Referral abuse
      // {
      //   icon: TicketIcon,
      //   text: "Referral reward",
      //   action: () => {
      //     history.push("/referral");
      //     onClose();
      //   },
      //   disabled: userAttributes.referrals ? userAttributes.referrals >= 10 : false,
      //   showTooltip: userAttributes.referrals ? userAttributes.referrals >= 10 : false,
      //   tooltipText: "No referral rewards available",
      //   hidden: !!userAttributes.passwordless
      // },
      {
        icon: DiscordIcon,
        text: "Join our Discord",
        action: () => {
          window.open(akordLinks["discord"]);
          onClose();
        },
        divider: true,
        hidden: userAttributes.passwordless
      },
      {
        icon: LogoutIcon,
        text: "Log out",
        action: async () => {
          try {
            await Auth.signOut();
            sessionStorage.clear();
            onIsAuthenticated(false);
            history.push("/login");
          } catch (error) {
            console.error("SIGN OUT ERROR: ", error);
          }
        }
      }
    ];
  };
  return { avatarOptions };
};

export const usePlusMenuOptions = () => {
  const { modal, onNotificationData, notificationData } = useNotificationsContext();
  const { onShowUpload, onFolderUpload, onVersionUpload, onZipUpload } = useAssetsContext();
  const { isVaultPublic, isCloudStorage } = useVaultContext();
  const { importFromGDrive } = useGDriveImporter();
  const { importFromDropbox } = useDropboxImporter();

  const history = useHistory();
  const vaultId = getVaultId(history.location.pathname);
  const vaultStatus = getVaultStatus(history.location.pathname);
  const folderId = getFolderId(history.location.pathname);

  const plusOptions = (onClose: () => void): DropDownMenuProps[] => {
    return [
      {
        icon: UploadIcon,
        text: "Upload file",
        action: () => {
          onVersionUpload(false);
          onFolderUpload(false);
          onZipUpload(false);
          onShowUpload(true);
          onClose && onClose();
        },
        upload: true
      },
      {
        icon: UploadFolderIcon,
        text: "Upload folder",
        action: () => {
          onVersionUpload(false);
          onFolderUpload(true);
          onZipUpload(false);
          onShowUpload(true);
          onClose && onClose();
        },
        upload: true
      },
      {
        icon: ZipIcon,
        text: "Upload zip & unpack",
        action: () => {
          onVersionUpload(false);
          onFolderUpload(false);
          onZipUpload(true);
          onShowUpload(true);
          onClose && onClose();
        },
        upload: true,
        hidden: !isVaultPublic
      },
      {
        icon: NoteIcon,
        text: "Create note",
        action: () => {
          history.push({
            pathname: `/vaults/${vaultStatus}/${vaultId}/assets/create-note`,
            state: { folderId: folderId }
          });
        }
      },
      {
        text: folderId ? "Create sub folder" : "Create folder",
        icon: FolderOutlineIcon,
        action: () => {
          if (!vaultId) {
            console.error("VaultId is required to Create folder!");
            return;
          }
          modal.onConfirmModalFormType("folderCreate");
          modal.onModalVisibility(null, "folderCreate");
          onNotificationData({ ...notificationData, title: "", dataRoomId: vaultId, folderId: folderId });
          onClose && onClose();
        }
      },
      {
        icon: ImportIcon,
        text: "Import",
        isNested: true,
        children: [
          {
            icon: ArweaveIcon,
            text: "Arweave transaction",
            action: () => {
              if (!vaultId) {
                console.error("VaultId is required to Import!");
                return;
              }
              modal.onConfirmModalFormType("importArweave");
              modal.onModalVisibility(null, "importArweave");
              onNotificationData({ ...notificationData, title: "", dataRoomId: vaultId, folderId: folderId });
              onClose && onClose();
            },
            // disabled: !isVaultPublic || isCloudStorage
            hidden: !isVaultPublic || isCloudStorage
          },
          {
            icon: DropboxIcon,
            text: "Dropbox",
            action: () => {
              importFromDropbox();
              onClose && onClose();
            },
            hidden: !DROPBOX_IMPORT_ENABLED
          },
          {
            //TODO Icon needs to be added to Akord lib
            //@ts-ignore
            icon: AddToDrive,
            text: "Google Drive",
            action: () => {
              importFromGDrive();
              onClose && onClose();
            },
            hidden: !GOOGLE_IMPORT_ENABLED
          }
        ]
      }
    ];
  };
  return { plusOptions };
};
