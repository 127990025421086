import React from "react";
import { Switch } from "react-router-dom";
import LoggedInRoute from "./LoggedInRoute";
import RoutesHome from "./RoutesHome";
import GlobalDataProvider from "../../contexts/GlobalDataProvider";
import Profile from "../../pages/vault-options/Profile";
import AcceptInvite from "../../pages/vault-options/AcceptInvite";
import ConfirmAccess from "../../pages/vault-options/ConfirmAccess";
import RejectInvite from "../../pages/vault-options/RejectInvite";
import SnackBarWrapper from "../common/Wrappers/SnackBarWrapper";
import Referral from "../../pages/vault-options/Referral";
import SearchContextProvider from "../../contexts/SearchContextProvider";
import VaultsSelectionContextProvider from "../../contexts/VaultsSelectionContextProvider";

function SecureRoutes(props) {
  return (
    <GlobalDataProvider>
      <VaultsSelectionContextProvider>
        <SnackBarWrapper>
          <SearchContextProvider>
            <Switch>
              <LoggedInRoute path="/reject-invite" exact component={RejectInvite} {...props} />
              <LoggedInRoute path="/profile" exact component={Profile} />
              {/* // TODO Referral abuse */}
              {/* <LoggedInRoute path="/referral" exact component={Referral} /> */}
              <LoggedInRoute path="/invite" exact component={AcceptInvite} {...props} />
              {/* //TODO Is this route still in use */}
              <LoggedInRoute path="/confirm" exact component={ConfirmAccess} />
              <LoggedInRoute path="/" component={RoutesHome} {...props} />
            </Switch>
          </SearchContextProvider>
        </SnackBarWrapper>
      </VaultsSelectionContextProvider>
    </GlobalDataProvider>
  );
}

export default SecureRoutes;
