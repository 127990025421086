import React, { useEffect, useState } from "react";
import { ImageIcon, DocumentIcon, StackIcon, NoteIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useStackMenuOptions } from "../MoreMenu/MenuOptions";
import MoreDrawerRoot from "./MoreDrawerRoot";
import MoreDrawerItem from "./MoreDrawerItem";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { Stack } from "@akord/akord-js";

type StackDrawerPRops = {
  openDrawer: boolean;
  handleMenuClose: () => void;
  stackId?: string;
  vaultId?: string;
};

const StackDrawer: React.FC<StackDrawerPRops> = ({ openDrawer, handleMenuClose, stackId = "", vaultId = "" }) => {
  const { akord } = useGlobalContext();
  const { vault, onVault, currentStack, onCurrentStack } = useVaultContext();
  const { onDrawerActionType } = useAssetsContext();
  const { options } = useStackMenuOptions();
  const [stack, setStack] = useState<Stack>();

  const isRoomArchived = vault?.status === "ARCHIVED";

  useEffect(() => {
    const loadData = async () => {
      const stack = await akord?.stack.get(stackId);
      if (!stack) return;
      setStack(stack);
      onCurrentStack(stack);
      if (vaultId) {
        const vault = await akord?.vault.get(vaultId);
        if (!vault) return;
        onVault(vault);
      }
    };
    if (!stack && stackId) {
      loadData();
    } else {
      setStack(currentStack);
    }
  }, [stackId, currentStack]);

  if (!stack || !stack.versions) {
    return null;
  }

  // select icon for a file
  const fileType = stack?.versions && stack.versions[0] ? stack.versions[0].type : "";

  const FileIcon =
    stack?.versions?.length > 1 ? StackIcon : /image/.test(fileType) ? ImageIcon : /markdown/.test(fileType) ? NoteIcon : DocumentIcon;

  // if its a currentStack we need to show ext from the latest vers.
  const createStackTitle = () => {
    const splitOriginalTitle = stack.name.split(".");
    splitOriginalTitle.pop();
    const latestTitleArray = stack.versions[stack.versions.length - 1].name.split(".");
    const originalExtension = latestTitleArray[latestTitleArray.length - 1];
    return splitOriginalTitle.join(".") + "." + originalExtension;
  };

  return (
    <MoreDrawerRoot
      openDrawer={openDrawer}
      header={stack.versions.length === 1 ? stack.name : createStackTitle()}
      icon={FileIcon}
      handleMenuClose={handleMenuClose}
      isRoomArchived={isRoomArchived}
      isVaultPublic={vault.public}
      isStack
    >
      {options(stack, handleMenuClose).map((menuItem, index) => {
        return menuItem.show && <MoreDrawerItem key={index} menuItem={menuItem} onDrawerActionType={onDrawerActionType} />;
      })}
    </MoreDrawerRoot>
  );
};

export default StackDrawer;
