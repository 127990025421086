import React from "react";
import { Box, Typography, Divider, Link } from "@mui/material";
import { ShieldIcon, DisplayIcon, BellIcon, ReceiptIcon, OrganisationIcon, CodeIcon } from "@akord/addon-icons";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { akordLinks } from "../../helpers/akordTexts";
import { SupportScreenWrapper } from "../../components/common";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useOrgContext } from "../../contexts/OrgContextProvider";
import HoverableIconWithText from "../../components/common/HoverableIconWithText/HoverableIconWithText";
import { Types } from "@akord/gql";

const Settings: React.FC<RouteComponentProps> = ({ history }) => {
  const { isFeatureAllowed } = usePaymentContext();
  const { profileDetails } = useGlobalContext();
  const { isOrgInvitee } = useOrgContext();
  const settingsOptions = [
    {
      title: "Organisation",
      Icon: OrganisationIcon,
      onClick: () => {
        history.push("/account/organisation");
      },
      isActive: isFeatureAllowed("Organisation account") && profileDetails?.orgRole !== Types.OrganisationRole.MEMBER
    },
    {
      title: "Plans and Payments",
      Icon: ReceiptIcon,
      onClick: () => {
        history.push("/account/plans-and-payments");
      },
      isActive: !isOrgInvitee
    },
    {
      title: "Security and Privacy",
      Icon: ShieldIcon,
      onClick: () => {
        history.push("/account/security");
      },
      isActive: true
    },
    {
      title: "Notifications",
      Icon: BellIcon,
      onClick: () => {
        history.push("/account/notifications");
      },
      isActive: true
    },
    {
      title: "Display",
      Icon: DisplayIcon,
      onClick: () => {
        history.push("/account/display");
      },
      isActive: true
    },
    {
      title: "Developers",
      Icon: CodeIcon,
      onClick: () => {
        history.push("/account/developers");
      },
      isActive: true
    }
  ];

  return (
    <SupportScreenWrapper title="Account" hideChevron>
      <Box mb={6}>
        {settingsOptions.map(
          ({ title, Icon, onClick, isActive }, index) =>
            isActive && (
              <div key={index}>
                <HoverableIconWithText title={title} Icon={Icon} onClick={onClick} />
                <Divider />
              </div>
            )
        )}
      </Box>
      <Typography variant="body2" className="small" color="text.secondary">
        <Link href={akordLinks.privacy} underline="none" color="text.secondary">
          Privacy policy
        </Link>{" "}
        and{" "}
        <Link href={akordLinks.terms} underline="none" color="text.secondary">
          Terms of Service
        </Link>
      </Typography>
    </SupportScreenWrapper>
  );
};

export default withRouter(Settings);
