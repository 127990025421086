import React, { forwardRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Gallery } from "@akord/ui";
import { CircularProgress, Dialog, Slide } from "@mui/material";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { getAllFoldersRecursively, getComparator, getVaultId, getVaultStatus, getFolderId, stableSort } from "../../helpers/helpers";
import { filterStacks } from "../../helpers/stack-helpers";
import { getResourceUri } from "../../helpers/helpers";
import { AssetsGalleryNavigation } from "./AssetsGalleryNavigation";
import { akordLinks } from "../../helpers/akordTexts";
import "@akord/ui/dist/esm/index.css";
import { useDownloader } from "../../components/file/FileDownloader";
import ThemeWrapper from "../../components/common/Wrappers/ThemeWrapper";
import { createStackTitleWithOriginalExtension } from "./gallery-helpers";

const CHUNK_SIZE = 50;
const BUFFER_CHUNK_SIZE = 5;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { host, protocol } = window.location;
const baseUrl = `${protocol}//${host}`;

function AssetsGallery({ isPublicRoute = false }) {
  const { activeStacks, folders, actionFilters, periodFilter } = useAssetsContext();
  const { vault, isVaultPublic, isCloudStorage } = useVaultContext();
  const { akord, darkMode } = useGlobalContext();
  const { onBinary } = useDownloader({ useLoader: false });

  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startIndex, setStartIndex] = useState();
  const [endIndex, setEndIndex] = useState();
  const [visibleFilesCount, setVisibleFilesCount] = useState();
  const [isDarkTheme, setIsDarkTheme] = useState(darkMode);

  const handleColorModeSwitch = () => setIsDarkTheme(prevState => !prevState);
  const folderId = getFolderId(history.location.pathname);
  const vaultId = getVaultId(history.location.pathname);
  const resourceUri = history.location.hash ? history.location.hash.replace("#", "") : null;
  const mode = history.location.search ? new URLSearchParams(history.location.search).get("mode") : "single";
  const dataRoomStatus = getVaultStatus(history.location.pathname);
  const closeUrl = history.location.pathname.replace("gallery", "assets");
  const isPublicShareRoute = history.location.pathname.startsWith("/public/");

  useEffect(() => {
    if (activeStacks?.length && Object.keys(vault).length) showFiles(0, resourceUri);
  }, [activeStacks, vault]);

  useEffect(() => {
    setIsDarkTheme(darkMode);
  }, [darkMode]);

  const showFiles = (startIdx, resourceUri) => {
    const visibleStacks = stableSort(
      filterStacks(filterCurrentFolderAssets(activeStacks), periodFilter, actionFilters),
      getComparator("asc", "name")
    );
    setVisibleFilesCount(visibleStacks.length);
    let idx = startIdx;
    if (resourceUri) {
      const activeIndex = visibleStacks.findIndex(stack => stack.versions.some(version => version.id === resourceUri));
      if (activeIndex < 0) {
        return;
      }
      idx = activeIndex - BUFFER_CHUNK_SIZE;
    }
    const startPos = Math.max(0, idx);
    const endPos = Math.min(startPos + CHUNK_SIZE, visibleStacks.length);
    setFiles(mapToFiles(visibleStacks.slice(startPos, endPos)));
    setStartIndex(startPos);
    setEndIndex(endPos);
  };
  /**
   * Function to load the file: downloads and decrypts the file based on metadata.
   * Called by the gallery when file is in the viewport
   *
   * @param {*} file
   * @returns Resolved file - downloaded & decrypted
   */
  const getFile = async file => {
    return {
      src: file.external ? `https://arweave.net/${file.txId}` : await akord.stack.download(file.id, file.version, { skipSave: true }),
      type: file.type || getTypeByExtension(file.name),
      fileName: file.name
    };
  };

  const onLast = async () => {
    if (endIndex < visibleFilesCount - 1) {
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 100));
      showFiles(endIndex - BUFFER_CHUNK_SIZE);
      setLoading(false);
    }
  };

  const onFirst = async () => {
    if (startIndex > 0) {
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 100));
      showFiles(startIndex - CHUNK_SIZE + BUFFER_CHUNK_SIZE);
      setLoading(false);
    }
  };

  //With dragNdrop some files loose the type (mov, etc)
  const getTypeByExtension = fileName => {
    const ext = (fileName.split(".").pop() || "").toLowerCase();
    switch (ext) {
      case "mov":
        return "video/quicktime";

      default:
        return ext;
    }
  };

  const getNote = async note => {
    const { data } = await onBinary(note.id, note.version);
    return Promise.resolve({
      src: await data.text(),
      type: note.type || "text/markdown",
      fileName: note.name
    });
  };

  const mapToFiles = assets => {
    const viewawbleFiles = [];
    assets.map((asset, idx) => {
      return asset.versions.forEach((version, versionIdx) => {
        if (versionIdx === asset.versions.length - 1) {
          version.hash = asset.hash;
        }
        viewawbleFiles.push({
          hash: version.id,
          id: asset.id,
          txId: getResourceUri(version.resourceUri, "arweave"),
          group: idx.toString(),
          version: versionIdx,
          toLoad: {
            ...version,
            name: createStackTitleWithOriginalExtension(asset, version),
            id: asset.id,
            version: versionIdx,
            txId: getResourceUri(version.resourceUri, "arweave")
          },
          size: version.size,
          // use to download files
          onDownload: () => {
            if (version.external) {
              const a = document.createElement("a");
              a.download = createStackTitleWithOriginalExtension(asset, version);
              a.href = `https://ar-io.dev/${getResourceUri(version.resourceUri, "arweave")}`;
              console.log(a.download);
              a.click();
            } else {
              akord.stack.download(asset.id, versionIdx);
            }
          },
          // don't load oversized files
          onLoad: /md/.test(version.name) || /markdown/.test(version.type) ? getNote : getFile //TODO: handle rtf in gallery
        });
      });
    });
    return viewawbleFiles;
  };

  const filterCurrentFolderAssets = files => {
    return files?.filter(stack => stack.parentId == folderId);
  };

  const navigation = () => {
    const currentFolder = folders?.filter(folder => folder.id === folderId)[0];
    const parentFolders = currentFolder ? getAllFoldersRecursively(folders, currentFolder) : [];

    const dataRoomTitle = vault.name;

    return (
      <AssetsGalleryNavigation
        dataRoomId={vaultId}
        dataRoomStatus={dataRoomStatus}
        dataRoomTitle={dataRoomTitle}
        currentFolder={currentFolder}
        parentFolders={parentFolders}
        folderId={folderId}
        isPublicRoute={isPublicRoute}
      />
    );
  };

  const key = files && files.length ? files[0].id : "";

  return React.useMemo(() => {
    if (!files.length || !Object.keys(vault).length) {
      return null;
    }

    return (
      <ThemeWrapper darkMode={isDarkTheme}>
        {loading ? (
          <CircularProgress></CircularProgress>
        ) : (
          <Dialog
            fullScreen
            open
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                overflowY: "hidden"
              }
            }}
          >
            <Gallery
              key={files[0].id}
              navigationComponent={navigation()}
              onThemeSwitch={handleColorModeSwitch}
              onClose={() => history.push(closeUrl, { isFromGallery: true })}
              width="auto"
              height="auto"
              mode={mode}
              loaderText={isVaultPublic || isPublicShareRoute ? "Loading" : "Decrypting"}
              transactionBaseUrl={akordLinks.transactionBaseUrl}
              darkMode={darkMode}
              printEnabled
              loaderEnabled
              downloadEnabled
              themeSwitchEnabled
              groupFilesEnabled
              isCloudVault={isCloudStorage}
              viewTransactionEnabled={!isCloudStorage}
              publicShareEnabled={isVaultPublic}
              publicShareBaseUrl={`${baseUrl}/public`}
              vaultId={vaultId}
              folderId={folderId}
              previewLimitSize={Number.POSITIVE_INFINITY}
              files={files}
              onLast={onLast}
              onFirst={onFirst}
              totalFilesCount={visibleFilesCount}
              startPosition={startIndex}
            ></Gallery>
          </Dialog>
        )}
      </ThemeWrapper>
    );
  }, [key, loading, isDarkTheme, folders, vault]);
}

export default AssetsGallery;
