import React from "react";
import { Box, Typography, IconButton, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChevronRightIcon } from "@akord/addon-icons";

const useStyles = makeStyles<Theme>(theme => ({
  hoverOver: {
    cursor: "pointer",
    "&:hover": {
      "& $icon": {
        color: theme.palette.text.primary
      }
    }
  },
  icon: {
    transition: ".3s ease"
  }
}));

type HoverableIconWithTextProps = {
  onClick: () => void;
  title: string;
  Icon?: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
};

const HoverableIconWithText: React.FC<HoverableIconWithTextProps> = ({ onClick, title, Icon }) => {
  const classes = useStyles();

  return (
    <Box my={4} display="flex" justifyContent="space-between" onClick={onClick} className={classes.hoverOver}>
      <Box display="flex">
        {Icon && <Icon color="disabled" sx={{ marginRight: "12px" }} classes={{ root: classes.icon }} />}
        <Typography variant="body2" color="text.primary">
          {title}
        </Typography>
      </Box>
      <IconButton style={{ padding: 0 }} onClick={onClick} size="large">
        <ChevronRightIcon classes={{ root: classes.icon }} />
      </IconButton>
    </Box>
  );
};

export default HoverableIconWithText;
