import React from "react";
import { Typography, Box, Tooltip } from "@mui/material";
import { grey } from "../theme/colors";
import { bytesToGb } from "../pages/storage/storage-helper";
import { useGlobalContext } from "../contexts/GlobalDataProvider";

type HorizontalBarProps = {
  data: ListBar[];
  id: string;
  height?: number;
  showTextIn?: boolean;
  showTextUp?: boolean;
  showTextDown?: boolean;
  showTextWithValue?: boolean;
  showValueIn?: boolean;
  showValueUp?: boolean;
  showValueDown?: boolean;
  fontColor?: string;
  onClick?: (even: React.MouseEvent<SVGGElement>, barData: ListBar) => void;
  outlineWidth?: number;
  outlineColor?: string;
};

export type ListBar = {
  description: string;
  value: number;
  color: string;
  barWidth?: string;
  widthTotal?: number;
  position?: string;
};

//  Returns a new calculated rgb color
const randomColor = () => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);

  return `rgb(${r}, ${g}, ${b})`;
};

export const HorizontalBar: React.FC<HorizontalBarProps> = ({
  data,
  height = 30,
  outlineWidth = 0,
  outlineColor = "black",
  id = "hsbar",
  onClick
}) => {
  const [listBars, setListBars] = React.useState<ListBar[]>([]);
  const { darkMode } = useGlobalContext();

  // Make the calculus of total width
  const calcWidthTotal = React.useCallback(() => {
    let widthTotal = 0;
    data?.forEach(bar => {
      if (bar.value) widthTotal = widthTotal + bar.value;
    });
    return widthTotal;
  }, [data]);

  // Returns the same list of bars with position and barWidth
  const getListBarWithOtherParameters = React.useCallback(() => {
    const widthTotal = calcWidthTotal();
    let position = (outlineWidth * 2) / 100;
    let barWidth = 0;
    const listBars = data?.map(bar => {
      if (bar.value) {
        position = position + barWidth;
        barWidth = (bar.value * 100) / widthTotal - (outlineWidth * 100) / bar.value / 100;
        bar = Object.assign({ position: position, barWidth: barWidth, widthTotal: widthTotal }, bar);
        return bar;
      } else return {} as ListBar;
    });
    return listBars?.filter(bar => bar);
  }, [data, calcWidthTotal, outlineWidth]);

  React.useEffect(() => {
    setListBars(getListBarWithOtherParameters());
  }, [getListBarWithOtherParameters]);

  const renderBars = () => {
    const listBarsUpdate = [];

    listBarsUpdate.push(
      listBars?.map((bar, index) => {
        return (
          <g key={index} onClick={e => onClick && onClick(e, bar)}>
            <Tooltip
              title={
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="body2" className="xs strong" sx={{ color: "#FFF" }}>
                    {bar.description}
                  </Typography>
                  <Typography variant="body2" component="p" className="xs" sx={{ color: "#FFF" }}>
                    {bytesToGb(bar.value).size}
                    {bytesToGb(bar.value).sizeType?.toUpperCase()}
                  </Typography>
                </Box>
              }
              arrow
            >
              <rect
                width={`${bar.barWidth || 0 + 0.1}%`}
                height={height}
                style={{
                  fill: bar.color || randomColor(),
                  strokeWidth: `${(outlineWidth * 100) / (bar.widthTotal || 0 / data.length)}%`,
                  stroke: outlineColor
                }}
                x={`${bar.position || 0}%`}
              />
            </Tooltip>
          </g>
        );
      })
    );
    return listBarsUpdate;
  };

  return (
    <svg
      id={id}
      width="100%"
      height={height}
      style={{
        borderRadius: "8px",
        background: darkMode ? "linear-gradient(90deg, #222126 0%, #312F37 100%)" : "linear-gradient(90deg, #FFFFFF 0%, #F7F6FA 100%)",
        border: "1px solid",
        borderColor: darkMode ? grey[700] : grey[200],
        boxSizing: "border-box"
      }}
    >
      {renderBars()}
    </svg>
  );
};
