//if its a stack we need to show ext from the latest vers.
export const createStackTitleWithOriginalExtension = (asset, version) => {
  const splitOriginalTitle = asset.name.split(".");
  splitOriginalTitle.pop();

  if (!version.name) return asset.name;

  const latestTitleArray = version.name.split(".");
  const originalExtension = latestTitleArray[latestTitleArray.length - 1];
  return splitOriginalTitle.join(".") + "." + originalExtension;
};
