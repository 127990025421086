import React from "react";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton, Divider, Theme } from "@mui/material";
import { ChevronRightIcon } from "@akord/addon-icons";
import { useLocation } from "react-router-dom";
import FilterActionsDesktop from "./FilterActionsDesktop";
import FilterActionsMobile from "./FilterActionsMobile";
import FilterPeriodMobile from "./FilterPeriodMobile";
import FilterPeriodDesktop from "./FilterPeriodDesktop";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { filtersToShow, PeriodFilterKeys } from "../../../helpers/stack-helpers";
import { filtersListMap } from "./filterMap";
import { getFolderId } from "../../../helpers/helpers";
import { useTimelineContext } from "../../../contexts/TimelineContextProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useChatContext } from "../../../contexts/ChatContextProvider";
import { grey } from "../../../theme/colors";
import { useStorageContext } from "../../../contexts/StorageContextProvider";
import { useNftContext } from "../../../contexts/NftContextProvider";

type FilterDrawerStyleProps = {
  isMobile: boolean;
  hasTrueKeys: boolean;
  topHeight: number;
  lightPaperColor: boolean;
};

type FilterDrawerProps = {
  topHeight: number;
  showFilter: boolean;
  onShowFilter: (show: boolean) => void;
  lightPaperColor?: boolean;
  actionFilters: string[];
  periodFilter: PeriodFilterKeys | null;
  onActionFilters: (filters: string[]) => void;
  onPeriodFilter: (filters: PeriodFilterKeys | null) => void;
};

const useStyles = makeStyles<Theme, FilterDrawerStyleProps>(theme => ({
  paperAnchorRight: {
    width: ({ isMobile }) => (isMobile ? "85%" : "336px")
  },
  backDrop: {
    top: ({ isMobile, topHeight }) => (isMobile ? 0 : `${topHeight + 90}px`),
    left: ({ isMobile }) => (isMobile ? 0 : "250px")
  },
  paper: {
    top: ({ isMobile, topHeight }) => (isMobile ? 0 : `${topHeight + 90}px`),
    backgroundColor: ({ lightPaperColor }) => (lightPaperColor ? grey[900] : "none")
  },
  divider: {
    marginLeft: ({ isMobile }) => (isMobile ? -20 : 0),
    marginRight: ({ isMobile }) => (isMobile ? -20 : 0)
  }
}));

const FilterDrawer: React.FC<FilterDrawerProps> = ({
  topHeight,
  showFilter,
  onShowFilter,
  lightPaperColor = false,
  actionFilters,
  periodFilter,
  onPeriodFilter,
  onActionFilters
}) => {
  const { isMobile } = useGlobalContext();
  const { activeStacks, folders } = useAssetsContext();
  const { decryptedTimeline } = useTimelineContext() || {};
  const { memos } = useChatContext() || {};
  const { transactions, actionStorageFilters, onActionStorageFilters, periodStorageFilter, onPeriodStorageFilter } = useStorageContext();
  const { nfts } = useNftContext();
  // const hasTrueKeys = Object.keys(filters).some(k => filters[k])
  const location = useLocation();
  const isFiltering = actionFilters.length > 0 || !!periodFilter;
  const classes = useStyles({
    isMobile: isMobile,
    hasTrueKeys: isFiltering,
    topHeight: topHeight,
    lightPaperColor: lightPaperColor
  });

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>) => {
    if (event instanceof KeyboardEvent)
      if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }

    onShowFilter(open);
  };

  const resetFilter = () => {
    onActionFilters([]);
    onPeriodFilter(null);
  };

  const isTimeline = !!location.pathname.match("/timeline");
  const isChat = !!location.pathname.match("/chat");
  const isStorage = !!location.pathname.match("/storage");
  const isNfts = !!location.pathname.match("/nfts");

  let assets = [...(activeStacks ? activeStacks : []), ...(folders ? folders : [])];

  const folderId = getFolderId(location.pathname);
  assets = assets.filter(item => {
    if (item.parentId === undefined && !folderId) return item;
    else return item.parentId === folderId;
  });

  const filtersList = filtersToShow(
    isChat,
    isTimeline,
    isStorage,
    isNfts,
    decryptedTimeline,
    filtersListMap,
    assets,
    memos,
    transactions,
    nfts
  );

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={showFilter}
      onClose={toggleDrawer(false)}
      BackdropProps={{ className: classes.backDrop, sx: { backgroundColor: "transparent" } }}
      PaperProps={{ className: classes.paper }}
      classes={{
        paperAnchorRight: classes.paperAnchorRight
      }}
    >
      <Box ml={5} mr={5} mt={3} height="calc(100% - 88px)">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography
            variant="body2"
            className="small"
            color={isFiltering ? "secondary.main" : "text.secondary"}
            onClick={resetFilter}
            sx={{
              cursor: isFiltering ? "pointer" : "inherit"
            }}
          >
            Reset
          </Typography>
          <Typography variant="h3" color="text.primary">
            Filters
          </Typography>
          <IconButton sx={{ padding: 0 }} onClick={toggleDrawer(false)} size="large">
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Divider className={classes.divider} />
        <Box pt={5} height="calc(100% - 47px)" sx={{ overflowY: !isMobile ? "auto" : "none" }}>
          {isMobile ? (
            <>
              <FilterActionsMobile keepOpen={actionFilters.length > 0} hasTrueKeys={isFiltering} filtersList={filtersList} />
              <FilterPeriodMobile keepOpen={!!periodFilter} />
            </>
          ) : (
            <>
              <FilterActionsDesktop
                hasTrueKeys={isFiltering}
                filtersList={filtersList}
                onActionFilters={isStorage ? onActionStorageFilters : onActionFilters}
                actionFilters={isStorage ? actionStorageFilters : actionFilters}
              />
              <FilterPeriodDesktop
                periodFilter={isStorage ? periodStorageFilter : periodFilter}
                onPeriodFilter={isStorage ? onPeriodStorageFilter : onPeriodFilter}
              />
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
