import React, { useContext } from "react";

const REGISTERED_SUBDOMAINS = ["v2", "dev", "pr-"];

interface RootProps {
  subdomain: string | null;
}

const RootContext = React.createContext<RootProps>({} as RootProps);

const RootContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const hostnameArray = window.location.hostname.split(".");
  const subdomain =
    hostnameArray.length > 1 && !REGISTERED_SUBDOMAINS.includes(hostnameArray[0]) && !hostnameArray[0].startsWith("pr-")
      ? hostnameArray[0]
      : null;
  return <RootContext.Provider value={{ subdomain: subdomain }}>{children}</RootContext.Provider>;
};

export const useRootContext = (): RootProps => useContext(RootContext);

export default RootContextProvider;
