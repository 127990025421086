import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Link, Radio, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { RadioEmptyIcon, RadioCheckedIcon, InfinityIcon, CloudIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultCreateContext } from "../../contexts/VaultCreateContextProvider";
import { akordLinks } from "../../helpers/akordTexts";
import { useKeyPress } from "../../hooks/useKeyPress";
import Label from "../../components/common/Label";

type EventLikeObject = {
  target: { value: "Cloud" | "Permanent" };
};

const useStyles = makeStyles<Theme>(theme => ({
  box: {
    border: "1px solid",
    borderColor: theme.palette.background.cardBorder,
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "16px",
    cursor: "pointer"
  },
  badgeBox: {
    border: "1px solid",
    borderColor: theme.palette.primary.light,
    borderRadius: "4px",
    padding: "0 6px 3px"
  },
  radio: {
    marginTop: "4px",
    padding: "0 16px 0 0",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-checked:hover": {
      backgroundColor: "transparent"
    }
  }
}));

const CreateVaultStorageStep: React.FC = () => {
  const [selectedValue, setSelectedValue] = React.useState<"Cloud" | "Permanent">("Cloud");

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp") handleChange({ target: { value: "Permanent" } });
    if (event.key === "ArrowDown") handleChange({ target: { value: "Cloud" } });
    if (event.key === "ArrowLeft") history.push("/vaults/active");
    if (event.key === "ArrowRight") history.push("/create-vault/privacy");
  };

  useKeyPress(["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"], onKeyPress);

  const { isMobile } = useGlobalContext();
  const { onVaultIsCloudChange, vaultCreateFormData } = useVaultCreateContext();

  const classes = useStyles();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | EventLikeObject) => {
    setSelectedValue(event.target.value as "Cloud" | "Permanent");
    if (event.target.value === "Cloud") {
      onVaultIsCloudChange(true);
    } else {
      onVaultIsCloudChange(false);
    }
  };

  return (
    <SupportScreenWrapper title="Create new vault" subtitle="Step 1 of 3" hideChevron spaceBetween fullHeight>
      <Box height="auto" display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"}>
        <Box mt={4} mb={4}>
          <Typography variant="body2" component="span">
            Choose the type of storage for your vault.
          </Typography>{" "}
          <Link variant="body2" color="text.tertiary" onClick={() => window.open(akordLinks.vaultExplained, "_blank")}>
            Learn more about vaults here.
          </Link>
        </Box>
        <Box>
          <Box
            className={classes.box}
            onClick={() => handleChange({ target: { value: "Permanent" } })}
            sx={{ backgroundColor: !vaultCreateFormData.cloud ? "background.card" : "transparent" }}
          >
            <Radio
              checked={!vaultCreateFormData.cloud}
              onChange={handleChange}
              value="Permanent"
              size="small"
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              name="radio-buttons"
              inputProps={{ "aria-label": "Permanent" }}
            />
            <Box>
              <Box display="flex" marginBottom="8px">
                <InfinityIcon fontSize="small" sx={{ margin: "0 8px 0 4px" }} />
                <Typography variant="body2" className="strong" color="text.primary">
                  Permanent storage
                </Typography>
                <Label text="Arweave" secondary smallLabel />
              </Box>
              <Typography variant="body2" className="small" color="text.secondary">
                All data is sent to the blockchain. Data is permanent and cannot be deleted. You’ll never lose access to any of your
                uploads.
              </Typography>
            </Box>
          </Box>
          <Box
            className={classes.box}
            onClick={() => handleChange({ target: { value: "Cloud" } })}
            sx={{ backgroundColor: vaultCreateFormData.cloud ? "background.card" : "transparent" }}
          >
            <Radio
              checked={vaultCreateFormData.cloud}
              onChange={handleChange}
              value="Cloud"
              size="small"
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              name="radio-buttons"
              inputProps={{ "aria-label": "Cloud" }}
            />
            <Box>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <CloudIcon fontSize="small" sx={{ margin: "0 8px 0 4px" }} />
                <Typography variant="body2" className="strong" color="text.primary">
                  Cloud storage
                </Typography>
                <Label text="AWS" secondary smallLabel />
              </Box>
              <Typography variant="body2" className="small" color="text.secondary">
                Upload files are stored on the cloud and can be deleted. If you delete your account or cancel a subscription, data will be
                deleted.
              </Typography>
            </Box>
          </Box>
        </Box>
        <ButtonGroup
          nextDiasabled={!selectedValue}
          handleBack={() => history.push("/vaults/active")}
          handleEnd={() => history.push("/create-vault/privacy")}
          nextText="Next"
        />
      </Box>
    </SupportScreenWrapper>
  );
};

export default CreateVaultStorageStep;
