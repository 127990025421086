import { ChevronRightIcon } from "@akord/addon-icons";
import { Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Theme, Tooltip, Typography } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { DropDownMenuProps } from "./MenuOptions";
import { Box } from "@mui/system";

type NestedMenuItemStyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles<Theme, NestedMenuItemStyleProps>(theme => ({
  paper: {
    backgroundColor: theme.palette.background.secondary,
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2)
  },
  menuItem: {
    // We need more padding before the divider for the last element in the menu
    // Comment out until figure out where is used

    // "&:last-of-type": {
    //   paddingTop: ({ isMobile }) => (isMobile ? theme.spacing(4) : theme.spacing(3))
    // },
    "&:hover": {
      "& $icon": {
        color: theme.palette.text.primary
      }
    }
  },
  menuItemDisabled: {
    // We need more padding before the divider for the last element in the menu
    "&:last-of-type": {
      paddingTop: ({ isMobile }) => (isMobile ? theme.spacing(4) : theme.spacing(3))
    },
    cursor: "auto"
  },
  icon: {
    transition: ".3s ease",
    marginBottom: "2px"
  }
}));

type NestedMenuItemProps = {
  item: DropDownMenuProps;
  childrenItems?: DropDownMenuProps[];
};

const NestedMenuItem = React.forwardRef<HTMLLIElement, NestedMenuItemProps>((props, ref) => {
  const { item, childrenItems: parentChildrenItems = [] } = props;

  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLLIElement) | null>(null);

  const { isMobile } = useGlobalContext();
  const { showFolderUpload, showZipUpload } = useAssetsContext();
  const classes = useStyles({ isMobile: isMobile });
  const isSubMenuOpen = Boolean(anchorEl);
  const hasChildrenItems = parentChildrenItems?.length || false;

  const handleMouseEnter = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem
        ref={ref}
        disableGutters
        className={item.disabled ? classes.menuItemDisabled : classes.menuItem}
        onClick={item.disabled ? undefined : item.action}
        onMouseEnter={item.isNested ? handleMouseEnter : undefined}
        onMouseLeave={item.isNested ? handleClose : undefined}
        disabled={item.disabled}
      >
        {item.upload ? (
          <label
            htmlFor={showFolderUpload ? "contained-button-folder" : showZipUpload ? "upload-file-zip" : "contained-button-file"}
            style={{ display: "flex", cursor: "pointer" }}
          >
            <ListItemIcon>
              <item.icon fontSize="small" color="disabled" classes={{ root: classes.icon }} />
            </ListItemIcon>
            <Typography variant="body2" color="text.primary" sx={{ marginRight: 4 }}>
              {item.text}
            </Typography>
          </label>
        ) : item.showTooltip ? (
          <Tooltip title={item.tooltipText} arrow>
            <span style={{ display: "flex" }}>
              <ListItemIcon>
                <item.icon fontSize="small" color="disabled" classes={{ root: classes.icon }} />
              </ListItemIcon>
              <Typography variant="body2" color={item.disabled ? "text.secondary" : "text.primary"} sx={{ marginRight: 4 }}>
                {item.text}
              </Typography>
            </span>
          </Tooltip>
        ) : (
          <>
            <ListItemIcon>
              <item.icon fontSize="small" color="disabled" classes={{ root: classes.icon }} />
            </ListItemIcon>
            <Typography variant="body2" color="text.primary" sx={{ marginRight: 4 }}>
              {item.text}
            </Typography>
            {hasChildrenItems && <ChevronRightIcon fontSize="small" sx={{ marginLeft: "auto" }} />}
            {hasChildrenItems && (
              <>
                <Menu
                  // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
                  sx={{ pointerEvents: "none" }}
                  anchorEl={anchorEl}
                  open={isSubMenuOpen}
                  // getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  PaperProps={{
                    elevation: 8
                  }}
                  classes={{ paper: classes.paper }}
                >
                  {/* reset pointer event here so that the menu items could receive mouse events */}
                  <div style={{ pointerEvents: "auto" }}>
                    {parentChildrenItems.map((item, index) => !item.hidden && <NestedMenuItem item={item} key={index} />)}
                  </div>
                </Menu>
              </>
            )}
          </>
        )}
      </MenuItem>
      {item.divider && <Divider light />}
    </>
  );
});

export default NestedMenuItem;
