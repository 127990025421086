import React from "react";
import zxcvbn from "zxcvbn";
import { Auth } from "@akord/akord-js";
import { AkordWallet } from "@akord/crypto";
import { Box, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, Typography, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ButtonGroup, PasswordMeter, FormSubmit, ErrorAlert, RevealToggler, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { ZXCVBNResultExtended } from "../../types/signUpTypes";

const useStyles = makeStyles<Theme>(theme => ({
  formCustom: {
    marginBottom: theme.spacing(7)
  },
  notchedOutline: {
    borderColor: "#4CAF57!important"
  },
  notchedOutlineWarning: {
    borderColor: "#DB443C!important"
  },
  label: {
    color: "#4CAF57!important"
  },
  labelWarning: {
    color: "#DB443C!important"
  }
}));

export type RevealPasswordFormData = {
  currentPassword: string;
  userPassword: string;
};

const SecurityChangePassword: React.FC = () => {
  const { handleWallet, isMobile } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const classes = useStyles();
  const [values, setValues] = React.useState({
    showPassword: true
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>(null);

  const [formData, setFormData] = React.useState<RevealPasswordFormData>({
    currentPassword: "",
    userPassword: ""
  });

  const [correctPassword, setCorrectPassword] = React.useState(false);
  const [passEvaluation, setPassEvaluation] = React.useState<ZXCVBNResultExtended>({} as ZXCVBNResultExtended);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
  };

  const handleFormChange = (prop: string) => async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { target } = event;
    const value = target.value;

    if (prop === "userPassword") {
      const evaluation = zxcvbn(value);
      setPassEvaluation(evaluation);
    }
    setFormData({
      ...formData,
      [prop]: value
    });
  };

  React.useEffect(() => {
    const checkCurrentPassword = async () => {
      try {
        const attributes = await Auth.getUserAttributes();
        const encBackupPhrase = attributes["custom:encBackupPhrase"];
        await AkordWallet.importFromEncBackupPhrase(formData.currentPassword, encBackupPhrase);
        setCorrectPassword(true);
      } catch (err) {
        setCorrectPassword(false);
      }
    };
    checkCurrentPassword();
    setError(null);
  }, [formData]);

  const isDisabled = () => {
    if (correctPassword && formData.userPassword.length >= 8 && formData.currentPassword !== formData.userPassword && !loading)
      return false;
    else return true;
  };

  const changePassword = async () => {
    try {
      setLoading(true);
      const session = await Auth.changePassword(formData.currentPassword, formData.userPassword);
      handleWallet(session.wallet);
      setFormData({ currentPassword: "", userPassword: "" });
      setPassEvaluation({} as ZXCVBNResultExtended);
      setLoading(false);
      onSnackbarToShow("passwordChange");
    } catch (err: any) {
      setLoading(false);
      setError(err.message);
      console.log(err);
    }
  };

  return (
    <SupportScreenWrapper title="Change password" route="/account/security">
      <Box mt={5} mb={6}>
        <Typography variant="body2">Enter your current password first.</Typography>
      </Box>
      <FormSubmit onSubmit={changePassword}>
        <Box width={isMobile ? "100%" : "400px"}>
          <FormControl fullWidth>
            <FormControl variant="outlined" className={classes.formCustom}>
              <InputLabel
                classes={{
                  root: correctPassword
                    ? classes.label
                    : !correctPassword && formData.currentPassword.length >= 6
                    ? classes.labelWarning
                    : undefined
                }}
              >
                Current password
              </InputLabel>
              <OutlinedInput
                label="Current password"
                name="currentPassword"
                value={formData.currentPassword}
                type={values.showPassword ? "text" : "password"}
                onChange={handleFormChange("currentPassword")}
                classes={{
                  notchedOutline: correctPassword
                    ? classes.notchedOutline
                    : !correctPassword && formData.currentPassword.length >= 6
                    ? classes.notchedOutlineWarning
                    : undefined
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      <RevealToggler showPassword={values.showPassword} />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!correctPassword && formData.currentPassword.length >= 6 && <ErrorAlert getErrorText="This is not your current password." />}
            </FormControl>
            <PasswordMeter
              padding={false}
              values={values}
              formData={formData}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              handleFormChange={handleFormChange}
              passEvaluation={passEvaluation}
              newPasswordText
            />
          </FormControl>
        </Box>
        <ButtonGroup
          loading={loading}
          type="submit"
          noMinWidth={true}
          nextText="Change password"
          nextDiasabled={isDisabled()}
          hideBackButton={true}
          fullWidthNextButton={isMobile ? true : false}
          hideIcon={true}
          errorText={error}
          disabledRed={false}
        />
      </FormSubmit>
    </SupportScreenWrapper>
  );
};

export default SecurityChangePassword;
