export const documentStatus = {
  ACTIVE: "active",
  ARCHIVED: "archived"
};

export const fromStateStatus = (status: string) => {
  switch (status) {
    case "ARCHIVED":
    case "REVOKED":
      return documentStatus.ARCHIVED;
    default:
      return documentStatus.ACTIVE;
  }
};
