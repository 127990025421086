import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, DialogTitle, DialogContent, Typography, Theme, Box } from "@mui/material";
import { confirmationModalOptions } from "./confirmation-modal-options";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import TwoButtonConfirm from "./TwoButtonConfirm";
import ThreeButtonConfirm from "./ThreeButtonConfirm";
import MoveModalContent from "./MoveModalContent";
import UploadModalContent from "./UploadModalContent";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { FILE_SIZE_LIMIT_UPLOAD } from "../../../helpers/akord-enums";
import ConfirmationError from "../ConfirmationError";
import { ModalType } from "../../../hooks/useModal";
import { ConfirmationErrorTypes } from "./confirmation-errors-texts";
import { FileWithAction } from "../../file/FileUploader";
import Label from "../Label";
import ManifestOptionsContent from "./ManifestOptionsContent";

type ConfirmationModalStyleProps = {
  alignRight: boolean;
  isDesktop: boolean;
};

const useStyles = makeStyles<Theme, ConfirmationModalStyleProps>(theme => ({
  dialogActions: {
    justifyContent: ({ alignRight }) => (alignRight ? "flex-end" : "flex-end"),
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column-reverse")
  },
  dialogContent: {
    marginTop: 0
  },
  dialogTitle: {
    display: "flex",
    marginBottom: theme.spacing(4),
    justifyContent: "space-between"
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  paperFullWidth: {
    width: "calc(100% - 32px)"
  },
  paper: {
    margin: ({ isDesktop }) => (isDesktop ? "128px 16px 16px" : "64px 16px 16px")
  },
  buttonGroup: {
    minWidth: "auto",
    marginLeft: ({ isDesktop }) => (isDesktop ? "24px!important" : "inherit"),
    "&:last-child": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
    }
  },
  buttonProgress: {
    position: "absolute"
  }
}));

const ConfirmationModal: React.FC = () => {
  const [uploadFileIndex, setUploadFileIndex] = useState(0);
  const handleUploadFileIndex = (num: number) => setUploadFileIndex(num);

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const handleSelectedFolderId = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const folderId = !event ? null : event.target.value;
    setSelectedFolderId(folderId);
  };

  const [uploadedAndConfirmedFiles, setUploadedAndConfirmedFiles] = useState<File[] | null>(null);
  const handleUploadedAndConfirmedFiles = (files: File[] | null) => setUploadedAndConfirmedFiles(files);

  const [confirmationError, setConfirmationError] = useState<any>(null);
  const handleConfirmationError = (error: any) => setConfirmationError(error);

  const [selectedManifestValue, setSelectedManifestValue] = useState<"Arweave" | "Opensea">();

  const isProccessingFilesForUploadModalRef = React.useRef(false);

  let filesWithAction = React.useRef<FileWithAction[]>([]);
  const { width } = useGlobalContext();
  const { modal, notificationData, duplicateFiles } = useNotificationsContext();
  const { isVaultPublic } = useVaultContext();

  //Clear index for the next check to work correctly
  React.useEffect(() => {
    handleUploadFileIndex(0);
  }, [duplicateFiles]);

  const confirmModalType = modal.confirmModalType as ModalType;

  const { showCancel, icon: IconForTitle, title, modalType, createText } = confirmationModalOptions[confirmModalType];

  const isDesktop = width !== "xs";
  const classes = useStyles({
    isDesktop: isDesktop,
    alignRight: showCancel
  });

  //TODO update the assignment of notification data
  if (isVaultPublic) Object.assign(notificationData, { isVaultPublic: true });
  else Object.assign(notificationData, { isVaultPublic: false });

  const threeGbFileLimitCheck = uploadedAndConfirmedFiles
    ? Array.from(uploadedAndConfirmedFiles)?.some(file => file.size >= FILE_SIZE_LIMIT_UPLOAD)
    : false;

  const handleModalClose = () => {
    modal.onModalVisibility(null, confirmModalType);
    if (confirmationError) handleConfirmationError(null);
  };

  return (
    <Dialog
      open={modal.isModalVisible[confirmModalType]}
      onClose={() => handleModalClose()}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      classes={{
        paperFullWidth: classes.paperFullWidth,
        paper: classes.paper
      }}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <Box display="flex">
          <IconForTitle color="disabled" className={classes.icon} />
          <Typography className="strong" color="text.primary">
            {title(notificationData)}
          </Typography>
        </Box>
        {isVaultPublic && modalType === "upload" && <Label text="Unencrypted" smallLabel highlight />}
      </DialogTitle>
      {modalType === "move" ? (
        <MoveModalContent onSelectedFolderId={handleSelectedFolderId} selectedFolderId={selectedFolderId} />
      ) : modalType === "upload" ? (
        <UploadModalContent
          handleUploadedAndConfirmedFiles={handleUploadedAndConfirmedFiles}
          uploadedAndConfirmedFiles={uploadedAndConfirmedFiles}
          isSizeExceedLimit={threeGbFileLimitCheck}
          filesWithAction={filesWithAction}
          isProccessingFilesForUploadModalRef={isProccessingFilesForUploadModalRef}
        />
      ) : (
        <DialogContent className={classes.dialogContent} sx={{ maxHeight: "100%" }}>
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }} noWrap>
            {duplicateFiles.length > 0 ? createText(null, duplicateFiles, uploadFileIndex) : createText(notificationData)}
          </Typography>
          {confirmModalType === "createManifest" && (
            <ManifestOptionsContent selectedManifestValue={selectedManifestValue} onSelectedManifestValue={setSelectedManifestValue} />
          )}
        </DialogContent>
      )}
      {duplicateFiles.length > 0 && modalType !== "upload" ? (
        <ThreeButtonConfirm filesWithAction={filesWithAction} uploadFileIndex={uploadFileIndex} onUploadFileIndex={handleUploadFileIndex} />
      ) : (
        <TwoButtonConfirm
          filesWithAction={filesWithAction}
          handleUploadedAndConfirmedFiles={handleUploadedAndConfirmedFiles}
          uploadedAndConfirmedFiles={uploadedAndConfirmedFiles}
          // uploadFileIndex={uploadFileIndex} // not used!?
          onUploadFileIndex={handleUploadFileIndex}
          selectedFolderId={selectedFolderId}
          confirmationType={confirmModalType}
          isSizeExceedLimit={threeGbFileLimitCheck}
          onConfirmationError={handleConfirmationError}
          confirmationError={confirmationError}
          isProccessingFilesForUploadModalRef={isProccessingFilesForUploadModalRef}
          selectedManifestValue={selectedManifestValue}
        />
      )}

      {confirmationError && (
        <ConfirmationError error={confirmationError} data={notificationData} type={confirmModalType as ConfirmationErrorTypes} />
      )}
    </Dialog>
  );
};

export default ConfirmationModal;
