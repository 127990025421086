import { filterActionRefs } from "./akord-enums";
import { Stack, Folder, NFT, Collection, Membership } from "@akord/akord-js";
import { MemoWithOwnerInfo } from "../contexts/ChatContextProvider";
import { Types } from "@akord/gql";
import { StorageTransaction } from "../contexts/StorageContextProvider";

const dateMap = {
  TWENTY_FOUR_HOURS: 60 * 60 * 24 * 1000,
  FORTY_EIGHT_HOURS: 60 * 60 * 24 * 1000 * 2,
  SEVEN_DAYS: 60 * 60 * 24 * 1000 * 7,
  THIRTY_DAYS: 60 * 60 * 24 * 1000 * 30
};

export type PeriodFilterKeys = keyof typeof dateMap | null;

export const filterMemos = (memos: MemoWithOwnerInfo[], periodFilter: PeriodFilterKeys, actionFilters: string[]) => {
  let filteredMemos = memos
    .filter(item => {
      if (!periodFilter) return true;
      else return new Date().valueOf() - new Date(parseInt(item.createdAt, 10)).valueOf() <= dateMap[periodFilter];
    })
    .filter(item => {
      if (!actionFilters.length) {
        return true;
      } else {
        return actionFilters.indexOf(item.ownerInfo?.profileName || "") !== -1 || actionFilters.indexOf(item.ownerInfo?.email || "") !== -1;
      }
    });

  return filteredMemos;
};

export const filterNfts = (nfts: (NFT | Collection)[], periodFilter: PeriodFilterKeys, actionFilters: string[]) => {
  let filteredMemos = nfts
    .filter(item => {
      if (!periodFilter) return true;
      else return new Date().valueOf() - new Date(parseInt(item.createdAt, 10)).valueOf() <= dateMap[periodFilter];
    })
    .filter(nft => {
      if (!actionFilters.length) {
        return true;
      } else {
        // Single NFTs dont have type - fake it
        if (!nft.type) nft.type = "NFT";
        return actionFilters.indexOf(nft.type) !== -1;
      }
    });

  return filteredMemos;
};

export const filterTransactions = (transactions: StorageTransaction[], periodFilter: PeriodFilterKeys, actionFilters: string[]) => {
  let filteredTransactions = transactions
    ?.filter(item => {
      if (!periodFilter) return true;
      else return new Date().valueOf() - new Date(parseInt(item.createdAt || "0", 10)).valueOf() <= dateMap[periodFilter];
    })
    .filter(item => {
      if (!actionFilters.length) return true;
      else return actionFilters.indexOf(item.status || "PENDING") !== -1;
    });

  return filteredTransactions;
};

export const filterTimeline = (timeline: any, periodFilter: PeriodFilterKeys, actionFilters: string[]) => {
  let filteredTimeline = timeline
    .filter((item: any) => {
      if (!periodFilter) return true;
      else return new Date().valueOf() - new Date(item.createdAt).valueOf() <= dateMap[periodFilter];
    })
    .filter((item: any) => {
      if (!actionFilters.length) return true;
      else return actionFilters.indexOf(item.actionRef) !== -1;
    });

  return filteredTimeline;
};

export const filterStacks = (stacks: (Stack | Folder)[], periodFilter: PeriodFilterKeys, actionFilters: string[]) => {
  let filteredStacks = stacks
    .filter(item => {
      if (!periodFilter) return true;
      else {
        return (
          new Date().valueOf() - new Date(parseInt(item.createdAt, 10) || parseInt(item.updatedAt, 10)).valueOf() <= dateMap[periodFilter]
        );
      }
    })
    .filter(item => {
      if (!actionFilters || !actionFilters.length) return true;
      else
        return (
          actionFilters.indexOf(
            item.versions ? item.versions[0]?.type : item instanceof Folder ? "Folder" : item instanceof Stack ? "Stack" : "Other"
          ) !== -1
        );
    });

  return filteredStacks;
};

export const createMembersMap = (members: Membership[]) => {
  if (!members || !members.length) {
    return {};
  }
  return members
    .filter(member => member.status !== "INVITED")
    .sort((a, b) => {
      if (a.createdAt > b.createdAt) return 1;
      if (a.createdAt < b.createdAt) return -1;
      return 0;
    })
    .reduce((acc, item, index) => {
      return { ...acc, [item.address]: index };
    }, {});
};

export const filtersToShow = (
  isChat: boolean,
  isTimeline: boolean,
  isStorage: boolean,
  isNfts: boolean,
  decryptedTimeline: any,
  filtersListMap: { [key: string]: string },
  assets?: (Stack | Folder)[],
  memos?: MemoWithOwnerInfo[],
  transactions?: Types.StorageTransaction[],
  nfts?: (NFT | Collection)[]
) => {
  if (isTimeline) {
    const filteredRefs = decryptedTimeline
      .map((operation: any) => operation.actionRef)
      .filter((item: any) => !filterActionRefs.includes(item) && item !== "STACK_UPLOAD_REVISION" && item !== "USER_VERIFIED");
    const sortedRefs = filteredRefs.sort((a: any, b: any) => (filtersListMap[a] < filtersListMap[b] ? -1 : 1));
    return [...new Set(sortedRefs)] as string[];
  }

  if (isChat) {
    const chatOwners = memos?.map(memo => memo?.ownerInfo?.profileName || memo?.ownerInfo?.email);
    return [...new Set(chatOwners)] as string[];
  }

  if (isStorage) {
    const transationsStatus = transactions?.map(transaction => transaction.status);
    return [...new Set(transationsStatus)] as string[];
  }

  if (isNfts) {
    // Single NFTs dont have type
    const nftType = nfts?.map(nft => (nft.type ? nft.type : "NFT"));
    return [...new Set(nftType)] as string[];
  }

  const assetTypes = assets?.map(asset =>
    asset.versions?.[0]?.type ? asset.versions?.[0]?.type : asset instanceof Folder ? "Folder" : null
  );
  return [...new Set(assetTypes)] as string[];
};
