import React from "react";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";

const LoadingNotification: React.FC = () => {
  return (
    <Box display="flex" mb={6}>
      <Box mr={4}>
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={40} sx={{ borderRadius: 1 }} />
    </Box>
  );
};

export default LoadingNotification;
