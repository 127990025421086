export const searchResultType = {
  DATA_ROOM: "DATA_ROOM",
  MEMO: "MEMO",
  STACK: "STACK",
  STACK_DOCUMENT: "STACK_DOCUMENT",
  STACK_IMAGE: "STACK_IMAGE",
  FOLDER: "FOLDER",
  NOTE: "NOTE",
  isStackType(documentType: string) {
    return (
      documentType === searchResultType.STACK ||
      documentType === searchResultType.STACK_DOCUMENT ||
      documentType === searchResultType.STACK_IMAGE
    );
  }
};
