import React, { ReactElement } from "react";
import {
  PadlockIcon,
  BellIcon,
  InactiveIcon,
  WavingHandIcon,
  RefferalIcon,
  VaultIcon,
  TeamIcon,
  GlobeIcon,
  ReceiptIcon,
  StorageIcon,
  DocumentIcon,
  InfinityIcon,
  PasswordMaskIcon,
  NftIcon,
  CloudIcon
} from "@akord/addon-icons";
import { getVaultStatus, getVaultId } from "../../../helpers/helpers";
import { Typography } from "@mui/material";
import { History } from "history";
import { RoleType } from "@akord/akord-js";

interface EmptySpaceProps {
  [key: string]: EmptySpaceItemProps;
}

interface EmptySpaceItemProps {
  title: string;
  getTextEl: (userRole: RoleType, subdomain: string | null, cloud?: boolean) => ReactElement | null;
  getTextElSecond?: () => ReactElement;
  buttonText?: (userRole?: RoleType) => string | null;
  icon: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
  iconSecond?: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
  handleClick?: (history: History, currentMembership?: any) => void;
  topMargin: string;
  topMarginXS?: string;
  bottomMarginXS?: string;
  welcomeScreen?: boolean;
  noMinWidth?: boolean;
  tipText?: string;
  keyboardShortcuts?: { [shortcut: string]: (props: any) => void };
}

export const emptySpaceData: EmptySpaceProps = {
  newRoomWelcome: {
    title: "Welcome, Akordian.",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          To realise all the possibilities of the social vault, don’t forget you’ll need to invite others.
        </Typography>
      );
    },
    getTextElSecond: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          Invite someone new to Akord from within a vault, or send them a referral code from.
        </Typography>
      );
    },
    buttonText: () => "Create a new vault",
    icon: WavingHandIcon,
    iconSecond: RefferalIcon,
    handleClick: history => {
      history.push("/create-vault/setup");
    },
    topMargin: "52px",
    topMarginXS: "40px",
    bottomMarginXS: "56px",
    welcomeScreen: true
  },
  newRoom: {
    title: "Time to secure your data – permanently.",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          Create your first vault, invite collaborators, message and store assets permanently with end - to - end encryption.
        </Typography>
      );
    },
    buttonText: () => "Create a new vault",
    icon: PadlockIcon,
    handleClick: history => {
      history.push("/create-vault/setup");
    },
    topMargin: "52px",
    topMarginXS: "40px"
  },
  newRoomOnBoard: {
    title: "Secure files forever and earn rewards",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          Create a vault using permanent storage to start uploading to Arweave.
        </Typography>
      );
    },
    buttonText: () => "Create vault",
    icon: InfinityIcon,
    handleClick: history => history.push("/create-vault/storage"),
    tipText: "Pssst! pressing v is the shortcut.",
    topMargin: "52px",
    topMarginXS: "140px",
    keyboardShortcuts: { v: history => history.push("/create-vault/storage") }
  },
  newFile: {
    title: "Secure a file forever",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          Upload a file or folder from your device, or secure something from the cloud.
        </Typography>
      );
    },
    buttonText: () => "Upload a file",
    icon: InfinityIcon,
    noMinWidth: true,
    topMargin: "52px"
  },
  newCloudFile: {
    title: "Upload with end-to-end encryption",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          Upload a file or folder from your device, or secure something from the cloud.
        </Typography>
      );
    },
    buttonText: () => "Upload a file",
    icon: PadlockIcon,
    noMinWidth: true,
    topMargin: "52px"
  },
  newPublicFile: {
    title: "Secure a file forever",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        Upload a file or folder from your device, or import an existing file on Arweave.
      </Typography>
    ),
    buttonText: () => "Upload a file",
    icon: InfinityIcon,
    noMinWidth: true,
    topMargin: "52px"
  },
  newCloudPublicFile: {
    title: "Upload a public file to the cloud",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        Upload a file or folder from your device, or import an existing file on cloud.
      </Typography>
    ),
    buttonText: () => "Upload a file",
    icon: CloudIcon,
    noMinWidth: true,
    topMargin: "52px"
  },
  newAtomicNft: {
    title: "Mint an Atomic NFT",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        Asset, metadata and contract on Arweave in one transaction.
      </Typography>
    ),
    buttonText: () => "Mint Atomic NFT",
    handleClick: history => {
      history.push(`/vaults/${getVaultStatus(history.location.pathname)}/${getVaultId(history.location.pathname)}/nfts/mint/choose-type`);
    },
    icon: NftIcon,
    noMinWidth: true,
    topMargin: "52px"
  },
  notifications: {
    title: "All clear",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          You have no notifications.
        </Typography>
      );
    },
    icon: BellIcon,
    topMargin: "52px"
  },
  archivedRooms: {
    title: "No active vaults",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        You have either deactivated or left all your vaults
      </Typography>
    ),
    buttonText: () => "Create your first vault",
    icon: PadlockIcon,
    handleClick: history => {
      history.push("/create-vault/setup");
    },
    topMargin: "52px",
    tipText: "Pssst! pressing v is the shortcut.",
    keyboardShortcuts: { v: history => history.push("/create-vault/setup") }
  },
  archive: {
    title: "All clear",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        You have no inactive vaults.
      </Typography>
    ),
    icon: InactiveIcon,
    topMargin: "52px"
  },
  archiveStack: {
    title: "All clear",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        You have no hidden files.
      </Typography>
    ),
    icon: PasswordMaskIcon,
    topMargin: "52px"
  },
  pendingVault: {
    title: "Pending vault",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        You’ve been invited to this vault, but your access is pending a final access confirmation.
      </Typography>
    ),
    icon: VaultIcon,
    topMargin: "52px"
  },
  emptyMemos: {
    title: "A social vault",
    getTextEl: (_, userRole) => (
      <Typography variant="body2" align="center" component="span">
        {userRole === "OWNER"
          ? `Invite someone to your vault, if you haven’t already. Your messages will be
        stored onchain and protected with end-to-end encryption.`
          : `Akord vault’s are not just for storing files – chat with the other members of this vault using the message bar below.`}
      </Typography>
    ),
    icon: TeamIcon,
    topMargin: "52px",
    buttonText: userRole => (userRole === "OWNER" ? "Send an invite" : null),
    handleClick: (history, currentMembership) => {
      history.push({
        pathname: `/vaults/${getVaultStatus(history.location.pathname)}/${getVaultId(history.location.pathname)}/invite-to-vault`,
        state: currentMembership
      });
    }
  },
  emptyPublicVault: {
    title: "This public vault is empty",
    getTextEl: () => null,
    icon: GlobeIcon,
    topMargin: "52px"
  },
  emptyInvoices: {
    title: "No invoices",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          After you subscribe or top up, your invoices will appear here.
        </Typography>
      );
    },
    icon: ReceiptIcon,
    topMargin: "0"
  },
  emptyTransactions: {
    title: "No Arweave transactions",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          After you make your first uploads to a permanent storage vault, you will see the transactions here.
        </Typography>
      );
    },
    icon: StorageIcon,
    topMargin: "36px",
    bottomMarginXS: "48px"
  },
  emptyWobhookLogs: {
    title: "No webhook logs",
    getTextEl: () => {
      return (
        <Typography variant="body2" align="center" component="span">
          After you add your webhook, logs will appear here.
        </Typography>
      );
    },
    icon: DocumentIcon,
    topMargin: "0"
  }
};
