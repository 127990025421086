import React from "react";
import Button, { ButtonOwnProps } from "@mui/material/Button";
import { GlobalDataProps } from "../../types/globalDataTypes";

type ButtonLinkProps = {
  width: GlobalDataProps["width"];
  onClick?: () => void;
};

const ButtonLink: React.FC<ButtonOwnProps & ButtonLinkProps> = ({ children, ...rest }) => (
  <Button
    size={rest.size}
    variant={rest.variant ? rest.variant : "contained"}
    color="primary"
    fullWidth={rest.width === "xs" || !rest.width ? true : false}
    disableElevation
    type="button"
    style={{ minWidth: "auto" }}
    disabled={rest.disabled}
    {...rest}
  >
    {children}
  </Button>
);

export default ButtonLink;
