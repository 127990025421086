import { Box, DialogContent, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { InfoIcon, MinusInCircleIcon, NoteIcon } from "@akord/addon-icons";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { getUploadFileIcon, getTotalUploadSize } from "../../../helpers/helpers";
import { bytesToGb } from "../../../pages/storage/storage-helper";
import { useStorageContext } from "../../../contexts/StorageContextProvider";
import { confirmationModalOptions } from "./confirmation-modal-options";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { FILE_SIZE_LIMIT_UPLOAD } from "../../../helpers/akord-enums";
import { convertDirectoryEntrytoList } from "../../../helpers/filesystem-helper";
import { FileWithAction } from "../../file/FileUploader";
import { ModalType } from "../../../hooks/useModal";
import { TableVirtuoso, TableComponents } from "react-virtuoso";

const TABLE_MAX_HEIGHT = 237;
const ROW_HEIGHT = 57;

type UploadModalContentProps = {
  handleUploadedAndConfirmedFiles: (files: File[]) => void;
  uploadedAndConfirmedFiles: File[] | null;
  isSizeExceedLimit: boolean;
  filesWithAction: React.MutableRefObject<FileWithAction[]>;
  isProccessingFilesForUploadModalRef: React.MutableRefObject<boolean>;
};

const VirtuosoTableComponents: TableComponents<File> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => <TableContainer {...props} ref={ref} />),
  Table: props => <Table {...props} stickyHeader style={{ tableLayout: "fixed" }} />,
  TableRow: ({ item: _item, ...props }) => (
    <TableRow
      {...props}
      hover
      sx={{ "&:first-child td, &:first-child th": { borderTop: "1px solid ", borderColor: "background.archived" } }}
    />
  ),
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />)
};

const UploadModalContent: React.FC<UploadModalContentProps> = ({
  handleUploadedAndConfirmedFiles,
  uploadedAndConfirmedFiles,
  isSizeExceedLimit,
  filesWithAction,
  isProccessingFilesForUploadModalRef
}) => {
  const { uploadedFiles, notificationData, modal } = useNotificationsContext();
  const { globalStorage } = useStorageContext();
  const { vault } = useVaultContext();

  const isNote = notificationData?.fileType === "note";

  const isFileSystemEntry = (entry: File | FileSystemEntry): entry is FileSystemEntry => {
    return (entry as FileSystemEntry).filesystem !== undefined;
  };

  useEffect(() => {
    const proccessUpload = async () => {
      isProccessingFilesForUploadModalRef.current = true;
      let uploadedFileList: File[] = [];

      // If a Folder Struct was dropped it has a different struct
      // We want to change it to file list with paths
      if (isFileSystemEntry(uploadedFiles[0])) {
        uploadedFileList = await convertDirectoryEntrytoList(uploadedFiles as FileSystemEntry[]);
      } else {
        // Create an array from uploaded files
        // If an upload was done from a button
        // For DnD we filter out while converting to an array
        uploadedFileList = Array.from(uploadedFiles as File[]).filter(file => file.name !== ".DS_Store");
      }
      // Sort names correctly
      uploadedFileList.sort(function (a, b) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base"
        });
      });
      isProccessingFilesForUploadModalRef.current = false;
      if (uploadedFileList.length > 0) handleUploadedAndConfirmedFiles(uploadedFileList);
    };
    if (uploadedFiles?.length > 0) {
      proccessUpload();
    }
  }, [uploadedFiles]);

  // if (!uploadedAndConfirmedFiles && notificationData.fileType !== "note") return null;

  const confirmModalType = modal.confirmModalType as ModalType;
  const { createText } = confirmationModalOptions[confirmModalType];

  const handleRemoveFile = (index: number) => {
    const uploadedFilesCopy = [...uploadedAndConfirmedFiles!];
    uploadedFilesCopy.splice(index, 1);
    handleUploadedAndConfirmedFiles(uploadedFilesCopy);
  };

  // Filtered (skipped) files to show in Upload dialog correctly (without skipped)
  const filteredUploadedAndConfirmedFiles =
    (filesWithAction.current.length > 0
      ? uploadedAndConfirmedFiles?.filter((_, index) => {
          // IF a file is not present in filesWithAction Ref, assume its a new download and add it to the filtered array
          if (filesWithAction.current.every(file => file.index !== index)) return true;
          else return filesWithAction.current.some(actionFile => actionFile.index === index && actionFile.action !== "Skip");
        })
      : uploadedAndConfirmedFiles) || [];

  const noteSize = new Blob([JSON.stringify(notificationData?.noteText)]).size;

  const totalUploadingSize = isNote ? bytesToGb(noteSize).size : getTotalUploadSize(filteredUploadedAndConfirmedFiles);

  const showStorageAvailability = () => {
    if (!vault) {
      return null;
    }
    const storageAllowance = (vault.cloud ? globalStorage?.cloud_storage_available : globalStorage?.storage_available) || 0;

    if (storageAllowance < totalUploadingSize) {
      return (
        <Typography variant="body2" className="small" color="error">
          <InfoIcon fontSize="small" sx={{ marginRight: 1 }} />
          Not enough available space: {bytesToGb(storageAllowance).size || 0} {bytesToGb(storageAllowance).sizeType?.toUpperCase()}
        </Typography>
      );
    } else {
      return (
        <Typography variant="body2" className="small">
          Available: {bytesToGb(storageAllowance).size} {bytesToGb(storageAllowance).sizeType?.toUpperCase()}
        </Typography>
      );
    }
  };

  const rowContent = (index: number, file: File) => {
    const FileIcon = getUploadFileIcon(file);
    return (
      <React.Fragment>
        <TableCell padding="none">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "90%",
                alignItems: "center"
              }}
            >
              <FileIcon color={file.size > FILE_SIZE_LIMIT_UPLOAD ? "error" : "secondary"} />
              <Box
                sx={{
                  width: "90%",
                  marginLeft: 1
                }}
              >
                <Typography variant="body2" className="small" noWrap component="p">
                  {file.name}
                </Typography>
                <Typography variant="caption" color="text.secondary" component="p">
                  {/* // For retry use nested file obj */}
                  {bytesToGb(file.size).size} {bytesToGb(file.size).sizeType.toUpperCase()}
                  {/* //TODO check why it was file.file? */}
                  {/* {bytesToGb(file.file?.size || file.size).size}
                  {/* {bytesToGb(file.file?.size || file.size).sizeType.toUpperCase()} */}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginRight: 1 }}>
              <IconButton onClick={() => handleRemoveFile(index)} size="large">
                <MinusInCircleIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </TableCell>
      </React.Fragment>
    );
  };

  return (isProccessingFilesForUploadModalRef.current || !uploadedAndConfirmedFiles) && !isNote ? (
    <Box mb={4}>
      <Typography variant="body2" className="small">
        One moment, we&apos;re just preparing all your files...
      </Typography>
    </Box>
  ) : (
    <>
      <Box mb={4}>
        <Typography variant="body2" className="small">
          {createText(notificationData, undefined, undefined, vault?.public, vault?.cloud)}
        </Typography>
      </Box>
      {!isProccessingFilesForUploadModalRef.current && (
        <DialogContent style={{ marginTop: 8 }}>
          {isNote ? (
            <>
              <Divider />
              <TableContainer>
                <Table stickyHeader style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow hover>
                      <TableCell padding="none">
                        <Box
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center"
                            }}
                          >
                            <NoteIcon color="secondary" />
                            <Box
                              style={{
                                width: "100%",
                                marginLeft: 4,
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <Typography variant="body2" className="small" noWrap component="p">
                                {notificationData.title}
                              </Typography>
                              <Typography variant="caption" color="text.secondary" component="p">
                                {bytesToGb(noteSize).size} {bytesToGb(noteSize).sizeType.toUpperCase()}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <TableVirtuoso
              style={{ height: Math.min(TABLE_MAX_HEIGHT, ROW_HEIGHT * filteredUploadedAndConfirmedFiles.length) }}
              data={filteredUploadedAndConfirmedFiles}
              components={VirtuosoTableComponents}
              itemContent={rowContent}
            />
          )}
        </DialogContent>
      )}
      {modal.confirmModalType === "confirmUpload" && (
        <Box mb={6} mt={4} textAlign="right">
          <Typography variant="body2" className="small strong" color="text.primary">
            {filteredUploadedAndConfirmedFiles!.length > 0 &&
              "Total upload: " +
                bytesToGb(totalUploadingSize).size +
                " " +
                bytesToGb(totalUploadingSize).sizeType.toUpperCase() +
                "/" +
                filteredUploadedAndConfirmedFiles?.length +
                " files"}
          </Typography>
          {isSizeExceedLimit && (
            <Typography variant="body2" className="small" color="error">
              <InfoIcon fontSize="small" sx={{ marginRight: 1 }} />
              We cannot accept file uploads larger than {bytesToGb(FILE_SIZE_LIMIT_UPLOAD).size}{" "}
              {bytesToGb(FILE_SIZE_LIMIT_UPLOAD).sizeType.toUpperCase()}
            </Typography>
          )}
          {showStorageAvailability()}
        </Box>
      )}
    </>
  );
};

export default UploadModalContent;
