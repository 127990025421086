import React, { ReactElement } from "react";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Zoom } from "@mui/material";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

type HideOnScrollProps = {
  window: HTMLDivElement | null;
  fab: boolean;
  bottomBar?: boolean;
  children: ReactElement<any, any>;
};

const HideOnScroll: React.FC<HideOnScrollProps> = ({ children, window, fab, bottomBar = false }) => {
  const { isMobile } = useGlobalContext();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window : undefined
  });

  return (
    <>
      {fab ? (
        <Zoom in={!trigger} style={{ transitionDelay: "0ms" }}>
          {children}
        </Zoom>
      ) : isMobile ? (
        <Slide appear={false} direction={bottomBar ? "up" : "down"} in={!trigger}>
          {children}
        </Slide>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
export default HideOnScroll;
