import React, { useState, createContext, useContext } from 'react'
const Context = createContext()

function FileViewerContextProvider({ children }) {
  const [fileViewerData, setFileViewerData] = useState({})
  const handleFileViewerData = data => setFileViewerData(data)

  const [activeStack, setActiveStack] = useState([])
  const handleActiveStack = stack => setActiveStack(stack)

  const [showFileViewDrawer, setFileViewDrawer] = useState(false)
  const handleFileViewDrawer = value => setFileViewDrawer(value)

  const [openFileList, setOpenFileList] = useState(false)
  const handleOpenFileList = value => setOpenFileList(value)

  return (
    <Context.Provider
      value={{
        fileViewerData: fileViewerData,
        onFileViewerData: handleFileViewerData,
        activeStack: activeStack,
        onActiveStack: handleActiveStack,
        showFileViewDrawer: showFileViewDrawer,
        onFileViewDrawer: handleFileViewDrawer,
        openFileList: openFileList,
        onOpenFileList: handleOpenFileList
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default FileViewerContextProvider

export const withFileViewerContext = Component => props =>
  (
    <Context.Consumer>
      {fileViewerContext => <Component {...props} {...fileViewerContext} />}
    </Context.Consumer>
  )

export const useFileViewerContext = () => useContext(Context)
