import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, Box, Typography, Fab, Tooltip, CircularProgress, Button, Theme, Link } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getFolderId, getAllFoldersRecursively, getVaultId } from "../../../helpers/helpers";
import { FilterIcon, PlusIcon, FiberManualRecordIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import MenuRoom from "../MoreMenu/MenuRoom";
import { RoomDrawer } from "..";
import { grey } from "../../../theme/colors";
import MoreActionButton from "../MoreActionButton";
import TopBarTitleMobile from "./TopBarTitleMobile";
import TopBarTitleDesktop from "./TopBarTitleDesktop";
import TransactionSpinner from "../TransactionSpinner";
import DropdownMenu from "./DropdownMenu";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";

type TopBarSecondaryStyleProps = {
  isMobile: boolean;
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, TopBarSecondaryStyleProps>(theme => ({
  toolBarTop: {
    marginTop: ({ isMobile }) => (isMobile ? 0 : "20px"),
    justifyContent: "space-between",
    minHeight: "34px"
  },
  grow: {
    flexGrow: 1
  },
  iconHeader: {
    marginRight: "8px"
  },
  fabIcon: {
    cursor: "pointer"
  },
  fab: {
    "&.Mui-disabled": {
      color: ({ darkMode }) => (darkMode ? `${grey[500]}!important` : `${grey[400]}!important`),
      backgroundColor: ({ darkMode }) => (darkMode ? `${grey[700]}!important` : `${grey[200]}!important`)
    }
  },
  paddingForToolTip: {
    paddingRight: theme.spacing(2),
    paddingLeft: ({ isMobile }) => (isMobile ? 0 : theme.spacing(3))
  },
  filterActive: {
    flexDirection: "column"
  },
  dotMark: {
    fontSize: "8px",
    position: "absolute",
    bottom: "-8px"
  },
  fabProgress: {
    color: "white",
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  }
}));

type CircularProgressWithLabelProps = {
  value: number;
};

const CircularProgressWithLabel = React.forwardRef<HTMLDivElement, CircularProgressWithLabelProps>((props, ref) => {
  const { value } = props;
  return (
    <Box
      ref={ref}
      position="relative"
      display="inline-flex"
      style={{
        width: "34px",
        height: "34px",
        background: "secondary.main",
        borderRadius: "50%",
        verticalAlign: "middle",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "6px"
      }}
    >
      <CircularProgress size={28} variant="determinate" style={{ color: "white" }} value={value} />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="caption" style={{ fontSize: "11px", color: "#FFF" }}>{`${Math.round(value)}`}</Typography>
      </Box>
    </Box>
  );
});

type TopBarSecondaryProps = {
  isPublicRoute?: boolean;
  filterActive: boolean;
};

const TopBarSecondary: React.FC<RouteComponentProps & TopBarSecondaryProps> = ({ filterActive, history, isPublicRoute = false }) => {
  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [anchorElDropDown, setAnchorElDropDown] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);

  const openDropDown = Boolean(anchorElDropDown);
  const openMenu = Boolean(anchorEl);

  const { progress } = useProgressContext();
  const { isMobile, darkMode, txSpinner, decrptSpinner, readOnlyMode } = useGlobalContext();
  const { vault, currentMembership, members, userRole } = useVaultContext();
  const { showFilter, onShowFilter, folders } = useAssetsContext();
  const { modal, onNotificationData, notificationData } = useNotificationsContext();

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  const handleDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDropDown(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorElDropDown(null);
  };

  const classes = useStyles({
    darkMode: darkMode,
    isMobile: isMobile
  });

  const isNftTab = location.pathname.includes("/nfts");

  const folderId = getFolderId(location.pathname);
  const currentFolder = folders?.filter(folder => folder.id === folderId)[0];

  const parentFolders = currentFolder ? getAllFoldersRecursively(folders, currentFolder) : [];

  const handleManifest = () => {
    modal.onConfirmModalType("createManifest");
    modal.onModalVisibility(null, "createManifest");
    onNotificationData({ ...notificationData, dataRoomId: vault.id, folderId: folderId });
  };

  return (
    <Box mt={4} mx={5} display="flex" justifyContent="space-between" height={40}>
      {isMobile ? (
        <TopBarTitleMobile
          vault={vault}
          currentFolder={currentFolder}
          parentFolders={parentFolders}
          folderId={folderId}
          isPublicRoute={isPublicRoute}
        />
      ) : (
        <TopBarTitleDesktop
          variant="h2"
          color="text.secondary"
          vault={vault}
          currentFolder={currentFolder}
          parentFolders={parentFolders}
          folderId={folderId}
        />
      )}

      <Box display="flex" alignItems="center">
        {txSpinner && <TransactionSpinner isMobile={isMobile} darkMode={darkMode} />}
        {decrptSpinner && !txSpinner && <TransactionSpinner isMobile={isMobile} darkMode={darkMode} text="Decripting" mobileText="" />}
        <Box display="flex" ml={4}>
          {vault.public && !vault.cloud && userRole !== "VIEWER" && vault?.status !== "ARCHIVED" && !readOnlyMode && !isNftTab && (
            <Box mr={5}>
              <Link variant="body2" className="small" color="text.secondary" onClick={handleManifest}>
                {isMobile ? "JSON" : "Generate JSON metadata"}
              </Link>
            </Box>
          )}
          {isNftTab && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => history.push(`/vaults/active/${getVaultId(location.pathname)}/nfts/mint/choose-type`)}
              sx={{ minWidth: "auto", mr: 3 }}
              disabled={userRole === "VIEWER" || vault?.status === "ARCHIVED" || readOnlyMode}
            >
              {isMobile ? "Mint" : "Mint NFT"}
            </Button>
          )}
          {!isMobile && !isNftTab && (
            <>
              <Tooltip title="Add an asset" arrow>
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    cursor: userRole === "VIEWER" || vault?.status === "ARCHIVED" ? "not-allowed" : "inherit",
                    display: "inline-block"
                  }}
                >
                  {progress > 0 && location.pathname.match("/chat") ? (
                    <CircularProgressWithLabel value={progress} />
                  ) : (
                    <Fab
                      component="button"
                      disabled={userRole === "VIEWER" || vault?.status === "ARCHIVED" || readOnlyMode}
                      className={classes.fab}
                      size="small"
                      color="primary"
                      onClick={handleDropDown}
                    >
                      <PlusIcon classes={{ root: [classes.fabIcon, "step3"].join(" ") }} />
                    </Fab>
                  )}
                </div>
              </Tooltip>
              <DropdownMenu
                type="plusMenu"
                anchorEl={anchorElDropDown}
                // onAnchorEl={setAnchorElDropDown}
                onClose={handleCloseDropDown}
                openMenu={openDropDown}
                position="right"
              />
            </>
          )}
          {!isNftTab && (
            <Tooltip title="Filter the timeline" arrow>
              <IconButton
                disableRipple
                aria-label="filter"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // classes={{ label: classes.filterActive }}
                className={classes.paddingForToolTip}
                onClick={() => onShowFilter(!showFilter)}
              >
                <FilterIcon classes={{ root: "step4" }} />
                {filterActive && <FiberManualRecordIcon color="action" classes={{ root: classes.dotMark }} />}
              </IconButton>
            </Tooltip>
          )}
          <MoreActionButton menuActive={menuActive} handleClick={handleMenu} onboarding />
        </Box>
        {isMobile ? (
          <RoomDrawer
            vault={vault}
            currentMembership={currentMembership}
            members={members}
            // memberships={members}
            openDrawer={openMenu}
            handleMenuClose={handleMenuClose}
            // history={history}
            // isPublicRoute={isPublicRoute}
          />
        ) : (
          <MenuRoom
            anchorEl={anchorEl}
            openMenu={openMenu}
            handleMenuClose={handleMenuClose}
            vault={vault}
            currentMembership={currentMembership}
            memberships={members}
            // history={history}
            // isPublicRoute={isPublicRoute}
          />
        )}
      </Box>
    </Box>
  );
};

export default withRouter(TopBarSecondary);
