import React from "react";
import { Box, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import RevealToggler from "../../components/common/RevealToggler";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

type SecurityPasswordProps = {
  setFormData: (props: any) => void;
  formData: {
    userPassword: string;
  };
};

const SecurityPassword: React.FC<SecurityPasswordProps> = ({ setFormData, formData }) => {
  const { isMobile } = useGlobalContext();

  const [values, setValues] = React.useState({
    showPassword: false
  });

  const handleFormChange = (prop: string) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { target } = event;
    const value = target.value;
    setFormData({ ...formData, [prop]: value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };

  return (
    <>
      <Box mb={6}>
        <Typography variant="body2">To reveal your recovery phrase, please enter your password.</Typography>
      </Box>
      <Box mt={3} width={isMobile ? "100%" : "400px"}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="password-input">Password</InputLabel>
          <OutlinedInput
            spellCheck="false"
            id="password-input"
            aria-describedby="password-input"
            value={formData.userPassword}
            label="Password"
            onChange={handleFormChange("userPassword")}
            type={values.showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="large"
                >
                  <RevealToggler showPassword={values.showPassword} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </>
  );
};

export default SecurityPassword;
