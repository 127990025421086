import React, { useState, useContext, useEffect } from "react";
import { Auth } from "@akord/akord-js";
import { StaticContext } from "react-router";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Location } from "history";
import { useSnackbarContext } from "./SnackbarContextProvider";
import { confirmSignIn, signIn, signInWithWallet } from "../handlers/auth";
import { AkordWallet, base64ToJson } from "@akord/crypto";
import { SIGNUP_VERIFY_REDIRECT_URL } from "../helpers/env";

type LocationState = {
  from?: Location;
  wallet?: AkordWallet;
};

interface LoginContextProps {
  formData: { userEmail: string; userPassword: string; keepSignedIn: boolean; otp?: string };
  onLoginFormChange: () => (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: any;
  handleError: (error: any) => void;
  handleTextClicked: (value: boolean) => void;
  textClicked: boolean;
  resendEmail: () => Promise<void>;
  getErrorText: () => any;
  onSignIn: () => Promise<void>;
  onConfirmSignIn: () => Promise<void>;
  loading: boolean;
  handleClickShowPassword: () => void;
  showPassword: boolean;
  otp?: string;
  onOtp: (value: string) => void;
  mfaType?: MfaType; // export from Auth: declare type MfaType = "BACKUP_PHRASE" | "SMS" | "TOTP";
  mfaDevice?: string;
}

type MfaType = "BACKUP_PHRASE" | "SMS" | "TOTP";

const LoginContext = React.createContext({} as LoginContextProps);

const LoginContextProvider: React.FC<React.ReactNode & RouteComponentProps<{}, StaticContext, LocationState>> = ({
  location,
  history,
  children
}) => {
  const { onSnackbarToShow } = useSnackbarContext();
  const [formData, setFormData] = useState({
    userEmail: "",
    userPassword: "",
    keepSignedIn: false,
    otp: undefined
  });

  const handleLoginFormChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setFormData({ ...formData, [target.name]: value });
  };

  const [error, setError] = useState<any>(null);
  const handleError = (error: any) => setError(error);

  const [textClicked, setTextClicked] = useState(false);
  const handleTextClicked = (value: boolean) => setTextClicked(value);

  const [loading, setLoading] = useState(false);
  const handleLoading = (value: boolean) => setLoading(value);

  const [otp, setOtp] = useState<string>();
  const handleOtp = (value: string) => setOtp(value);

  const [mfaType, setMfaType] = useState<MfaType>();

  const [mfaDevice, setMfaDevice] = useState<string>();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setUsernameFromUid();
  }, [location.search]);

  useEffect(() => {
    handleError(null);
  }, [formData]);

  const loader = document.querySelector(".preloader");
  const main = document.querySelector("#main");
  const addClass = () => {
    loader?.classList.add("loader-hide");
    main?.classList.add("app-show");
  };

  useEffect(() => {
    // const { host, protocol } = window.location;
    const urlParams = new URLSearchParams(location.search);
    const encoded = urlParams.get("data");
    const code = urlParams.get("code");

    const fromUrlParams = new URLSearchParams(location?.state?.from?.search);
    const walletPhrase = fromUrlParams.get("wallet");

    async function verifyEmail() {
      try {
        const decoded = JSON.parse(atob(encoded!));
        const { userName } = decoded;
        await Auth.verifyAccount(userName, code!);
        if (SIGNUP_VERIFY_REDIRECT_URL?.length > 1) {
          const windowOpen = window.open(SIGNUP_VERIFY_REDIRECT_URL, "_self"); // Redirect to marketing website
          if (!windowOpen) addClass(); // If opening a link was blocked, remove the loaders and show normal flow
        } else onSnackbarToShow("userVerified");
      } catch (err: any) {
        console.warn(err);
        if (err.message.endsWith("Current status is CONFIRMED")) {
          if (SIGNUP_VERIFY_REDIRECT_URL?.length > 1) {
            const windowOpen = window.open(SIGNUP_VERIFY_REDIRECT_URL, "_self"); // Redirect to marketing website
            if (!windowOpen) addClass(); // If opening a link was blocked, remove the loaders and show normal flow
          } else onSnackbarToShow("userVerified"); // if already confirmed, ignore error and show message again
        } else {
          handleError(err);
        }
      }
    }

    async function signInPasswordless() {
      try {
        const wallet = await signInWithWallet(walletPhrase);
        history.push({ pathname: location?.state?.from?.pathname, state: { wallet: wallet } });
      } catch (err) {
        console.log("Error at signInPasswordless: ", err);
        handleError(err);
      }
    }

    if (encoded && code) {
      verifyEmail();
    } else if (walletPhrase) {
      signInPasswordless();
    }
  }, [location.search]);

  const getErrorText = () => {
    if (error?.code === "UserNotConfirmedException")
      return (
        <div>
          Your email is not yet verified. <button onClick={resendEmail}>{textClicked ? "Email sent!" : "Resend email."}</button>
        </div>
      );
    else if (error?.code === "CodeMismatchException")
      return (
        <div>
          Your verification link is not the latest one sent.{" "}
          <button onClick={resendEmail}>{textClicked ? "Email sent!" : "Resend email."}</button>
        </div>
      );
    else if (error?.message?.endsWith("Current status is CONFIRMED")) return;
    else if (error?.code === "LimitExceededException") return error.message;
    else if (error?.message) return "Your login details are not recognised. Please check your email and password.";
    else return;
  };

  const resendEmail = async () => {
    const { host, protocol } = window.location;
    const verifyUrl = `${protocol}//${host}/login`;
    const username = usernameFromUrl() || usernameFromForm();

    handleTextClicked(true);
    setTimeout(() => {
      handleTextClicked(false);
    }, 1500);

    await Auth.resendCode(username, { verifyUrl }); // second arg is missing
  };

  const usernameFromForm = () => {
    return formData.userEmail;
  };

  const usernameFromUrl = () => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.has("data")) {
      try {
        const encoded = urlParams.get("data");
        const decoded: any = base64ToJson(encoded!);
        const { userName } = decoded;
        return userName;
      } catch (err) {
        console.error("Login ERROR: ", err);
      }
    }
    return null;
  };

  const setUsernameFromUid = () => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.has("uid")) {
      try {
        const user = urlParams.get("uid");
        const decoded: any = base64ToJson(user!);
        const { email } = decoded;
        if (email) {
          setFormData({ ...formData, userEmail: email });
        }
      } catch (err) {
        console.error("Login ERROR: ", err);
      }
    }
  };

  const handleSignIn = async () => {
    try {
      handleLoading(true);
      const userEmail = formData.userEmail.toLowerCase().trim();
      const password = formData.userPassword;
      const keepSignedIn = formData.keepSignedIn;
      const otp = formData.otp;
      await signIn(userEmail, password, keepSignedIn, otp);
      handleLoading(false);
      if (location && location.state && location.state.from && location.state.from.pathname !== "/login") {
        history.push(location.state.from.pathname + location.state.from.search);
      } else history.push("/vaults/active");
    } catch (err: any) {
      if (err.mfaRequired) {
        setMfaType(err.mfaType);
        setMfaDevice(err.challengeParameters?.FRIENDLY_DEVICE_NAME);
        history.push("/verify");
      } else {
        handleError(err);
      }
      handleLoading(false);
    }
  };

  const handleConfirmSignIn = async () => {
    try {
      handleLoading(true);
      const userEmail = formData.userEmail.toLowerCase().trim();
      const password = formData.userPassword;
      const keepSignedIn = formData.keepSignedIn;
      await confirmSignIn(userEmail, password, keepSignedIn, mfaType, otp);
      handleLoading(false);
      if (location && location.state && location.state.from && location.state.from.pathname !== "/login") {
        history.push(location.state.from.pathname + location.state.from.search);
      } else history.push("/vaults/active");
    } catch (err: any) {
      console.log(err);
      if (err.mfaRequired) {
        history.push("/verify");
      }
      handleLoading(false);
      handleError(err);
    }
  };

  return (
    <LoginContext.Provider
      value={{
        formData: formData,
        onLoginFormChange: handleLoginFormChange,
        error: error,
        handleError: handleError,
        handleTextClicked: handleTextClicked,
        textClicked: textClicked,
        resendEmail: resendEmail,
        getErrorText: getErrorText,
        onSignIn: handleSignIn,
        onConfirmSignIn: handleConfirmSignIn,
        loading: loading,
        handleClickShowPassword: handleClickShowPassword,
        showPassword: showPassword,
        otp: otp,
        onOtp: handleOtp,
        mfaType: mfaType,
        mfaDevice: mfaDevice
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
export default withRouter(LoginContextProvider);

export const useLoginContext = () => useContext(LoginContext);
