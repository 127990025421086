import React, { useState } from "react";
import { Box, TableContainer, Table, TableBody } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableLocation,
  Combine,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
  DropResult
} from "react-beautiful-dnd";
import { DataRoomFilter, ProgressBar, ErrorAlert, DropZone, EmptySpace, AssetsPagination } from "../../components/common";
import StackItem from "./StackItem";
import FolderItem from "./FolderItem";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { Folder, Stack } from "@akord/akord-js";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { OrderBy } from "../../types/globalDataTypes";
import { useKeyPress } from "../../hooks/useKeyPress";
import { useHistory } from "react-router-dom";
import DropZoneInfoText from "../../components/common/EmptySpace/DropZoneInfoText";
type TabDocumentsProps = {
  docsRef?: React.RefObject<HTMLDivElement>;
  isPublicRoute?: boolean;
};

const TabDocuments: React.FC<TabDocumentsProps> = ({ docsRef, isPublicRoute = false }) => {
  const { vault, currentMembership, userRole, isVaultPublic, isCloudStorage } = useVaultContext();
  const {
    assets,
    axiosError,
    assetsDecrypted,
    filteredSortedActiveAssets,
    order,
    onOrder,
    orderBy,
    onOrderBy,
    getPaginatedData,
    onStackUpdate,
    onFolderUpdate
  } = useAssetsContext();

  const [dragOverId, setDragOverId] = useState<string | null>(null);
  const [draggingIndex, setDraggingIndex] = useState<number | null>();

  const visibleAssets = getPaginatedData();

  const { akord, readOnlyMode } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const history = useHistory();

  const handleRequestSort = (event: React.MouseEvent<HTMLAnchorElement>, property: OrderBy) => {
    const isAsc = orderBy === property && order === "asc";
    onOrder(isAsc ? "desc" : "asc");
    onOrderBy(property);
  };

  const onDragStart = ({ source }: { source: DraggableLocation }) => {
    setDraggingIndex(source.index);
  };

  const onDragUpdate = ({
    combine,
    destination
  }: {
    combine: Combine | null | undefined;
    destination: DraggableLocation | null | undefined;
  }) => {
    if (combine) {
      setDragOverId(combine.draggableId);
    } else if (destination && destination.index + 1 < assets.length) {
      const assetIdx = draggingIndex && draggingIndex <= destination.index ? destination.index + 1 : destination.index;
      const asset = visibleAssets[assetIdx];
      setDragOverId(asset.id);
    } else {
      setDragOverId(null);
    }
  };

  const onDragEnd = async (result: DropResult) => {
    if (result.draggableId && dragOverId) {
      const destination = dragOverId;
      const source = result.draggableId;
      const distinationFolder = visibleAssets.find(asset => asset.id === destination && asset instanceof Folder);
      if (distinationFolder) {
        const sourceAsset = visibleAssets.find(asset => asset.id === source);
        if (sourceAsset) sourceAsset.parentId = distinationFolder.id;
        if (sourceAsset instanceof Stack) {
          onStackUpdate(sourceAsset);
          await akord?.stack.move(sourceAsset.id, distinationFolder.id, vault.id);
          onSnackbarToShow("fileMove");
        } else if (sourceAsset instanceof Folder) {
          onFolderUpdate(sourceAsset);
          await akord?.folder.move(sourceAsset.id, distinationFolder.id, vault.id);
          onSnackbarToShow("folderMove");
        }
      }
    }
    setDragOverId(null);
    setDraggingIndex(null);
  };

  const getStyle = (style: DraggingStyle | NotDraggingStyle | undefined, snapshot: DraggableStateSnapshot) => {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      transitionDuration: `0.001s`
    };
  };

  const onKeyPress = (event: KeyboardEvent) => {
    history.push("/vaults/active");
  };

  useKeyPress(["Escape"], onKeyPress);

  return (
    <Box>
      <div
        ref={docsRef}
        style={{
          width: "100%",
          height: "100%",
          paddingTop: 0,
          position: "absolute"
        }}
      >
        <DropZone userRole={userRole}>
          {/* HOC to show DND option on empty vault state */}
          <DropZoneInfoText
            isVaultPublic={isVaultPublic}
            isVisible={
              !(filteredSortedActiveAssets.length > 0 || (filteredSortedActiveAssets.length > 0 && isPublicRoute) || !assetsDecrypted)
            }
          >
            <ProgressBar vaultId={vault.id} />
            {axiosError && (
              <Box my={4} mx={5}>
                <ErrorAlert getErrorText={axiosError} darkMode={true} />
              </Box>
            )}
            {filteredSortedActiveAssets.length > 0 || (filteredSortedActiveAssets.length > 0 && isPublicRoute) || !assetsDecrypted ? (
              <TableContainer sx={{ marginTop: 3 }}>
                <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
                  <DataRoomFilter order={order} orderBy={orderBy} data={visibleAssets} onRequestSort={handleRequestSort} />
                  <DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-list" isCombineEnabled>
                      {provided => (
                        <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                          {filteredSortedActiveAssets.length > 0
                            ? visibleAssets.map((item, index) => (
                                <Draggable draggableId={item.id} index={index} key={item.id} isDragDisabled={readOnlyMode}>
                                  {({ innerRef, draggableProps, dragHandleProps }, snapshot) =>
                                    item instanceof Stack ? (
                                      <StackItem
                                        forwardRef={innerRef}
                                        forwardProps={{ ...draggableProps, ...dragHandleProps }}
                                        forwardStyle={getStyle(draggableProps.style, snapshot)}
                                        key={index}
                                        stack={item}
                                        isPublicRoute={isPublicRoute}
                                      />
                                    ) : item instanceof Folder ? (
                                      <FolderItem
                                        forceHighlight={dragOverId === item.id}
                                        forwardRef={innerRef}
                                        forwardProps={{ ...draggableProps, ...dragHandleProps }}
                                        forwardStyle={getStyle(draggableProps.style, snapshot)}
                                        key={index}
                                        folder={item}
                                        isPublicRoute={isPublicRoute}
                                      />
                                    ) : (
                                      <React.Fragment />
                                    )
                                  }
                                </Draggable>
                              ))
                            : Array.from(new Array(8)).map((item, index) => <StackItem key={index} />)}
                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
                <Box display="flex" justifyContent="center" sx={{ minHeight: "48px" }}>
                  <AssetsPagination bottomPosition />
                </Box>
              </TableContainer>
            ) : (
              <EmptySpace
                dataText={
                  isPublicRoute
                    ? "emptyPublicVault"
                    : isVaultPublic
                    ? isCloudStorage
                      ? "newCloudPublicFile"
                      : "newPublicFile"
                    : isCloudStorage
                    ? "newCloudFile"
                    : "newFile"
                }
                spaceData={currentMembership}
              />
            )}
          </DropZoneInfoText>
        </DropZone>
      </div>
    </Box>
  );
};

export default TabDocuments;
