import { ChevronLeftIcon, DoubleChevronLeftIcon } from "@akord/addon-icons";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

export const AssetsGalleryNavigation = ({
  dataRoomId,
  dataRoomTitle,
  dataRoomStatus,
  folderId,
  currentFolder,
  parentFolders,
  isPublicRoute = false
}) => {
  const { isMobile } = useGlobalContext();
  const history = useHistory();
  const urlPrefix = isPublicRoute ? "/public/vaults" : "/vaults";

  if (isMobile) {
    return (
      <Box maxWidth="95%" minWidth="90%" display="flex">
        <IconButton
          disableRipple
          size="small"
          edge="end"
          aria-label="back"
          aria-controls="menu-appbar"
          onClick={() =>
            history.push(
              parentFolders.length
                ? `${urlPrefix}/${dataRoomStatus}/${dataRoomId}/gallery`
                : `${urlPrefix}/${dataRoomStatus}/${dataRoomId}/assets`
            )
          }
        >
          {parentFolders.length ? (
            <DoubleChevronLeftIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
        <Box display="flex" minWidth="40%" maxWidth="95%">
          {!parentFolders.length ? (
            folderId ? (
              <Box maxWidth="50%" display="flex">
                <Typography
                  variant="h2"
                  noWrap
                  className="regular"
                  sx={{
                    borderBottom: "none",
                    "&:hover": {
                      color: "text.primary"
                    }
                  }}
                  color="text.secondary"
                  component="a"
                  onClick={() =>
                    history.push(`${urlPrefix}/active/${dataRoomId}/gallery`)
                  }
                >
                  {dataRoomTitle}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" width="100%">
                <Typography variant="h2" noWrap color="text.primary">
                  {dataRoomTitle}
                </Typography>
              </Box>
            )
          ) : null}
          {parentFolders.map((parentFolder, index) =>
            index === parentFolders.length - 1 ? (
              <Typography
                key={index}
                variant="h2"
                noWrap
                className="regular"
                color="text.secondary"
                onClick={() =>
                  history.push(
                    `${urlPrefix}/${dataRoomStatus}/${dataRoomId}/gallery/folders/${parentFolder.id}`
                  )
                }
              >
                ...
              </Typography>
            ) : null
          )}
          {folderId && (
            <>
              <Typography
                variant="h2"
                className="regular"
                color="text.secondary"
                style={{ whiteSpace: "pre" }}
              >
                {" / "}
              </Typography>
              <Typography variant="h2" noWrap color="text.primary">
                {currentFolder?.name}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box display="flex" width="100%" style={{ marginTop: 0 }}>
        {folderId ? (
          parentFolders.length < 2 ? (
            <Typography
              variant="h2"
              noWrap
              className="regular"
              sx={{
                borderBottom: "none",
                "&:hover": {
                  color: "text.primary"
                }
              }}
              color="text.secondary"
              component="a"
              onClick={() =>
                history.push(
                  `${urlPrefix}/${dataRoomStatus}/${dataRoomId}/gallery`
                )
              }
            >
              {dataRoomTitle}
            </Typography>
          ) : (
            <IconButton
              disableRipple
              aria-label="back"
              aria-controls="menu-appbar"
              onClick={() =>
                history.push(
                  `${urlPrefix}/${dataRoomStatus}/${dataRoomId}/gallery`
                )
              }
              style={{ alignItems: "flex-start", padding: 0 }}
              size="large"
            >
              <DoubleChevronLeftIcon fontSize={"default"} />
            </IconButton>
          )
        ) : (
          <>
            <Typography
              variant="h2"
              noWrap
              color="text.primary"
              sx={{
                borderBottom: "none",
                "&:hover": {
                  color: "text.primary"
                }
              }}
            >
              {dataRoomTitle}
            </Typography>
          </>
        )}
        {parentFolders.map((parentFolder, index) =>
          index >= parentFolders.length - 2 ? (
            <Box key={index} display="flex">
              {index !== parentFolders.length - 2 && (
                <Typography
                  variant="h2"
                  className="regular"
                  color="text.secondary"
                  sx={{
                    whiteSpace: "pre-wrap",
                    borderBottom: "none"
                  }}
                >
                  {" / "}
                </Typography>
              )}
              <Typography
                variant="h2"
                className="regular"
                color="text.secondary"
                sx={{
                  whiteSpace: "pre-wrap",
                  borderBottom: "none",
                  "&:hover": {
                    color: "text.primary"
                  }
                }}
                component="a"
                onClick={() =>
                  history.push(
                    `${urlPrefix}/${dataRoomStatus}/${dataRoomId}/gallery/folders/${parentFolder.id}`
                  )
                }
              >
                {parentFolder.name}
              </Typography>
            </Box>
          ) : null
        )}
        {folderId && (
          <>
            <Typography
              variant="h2"
              className="regular"
              color="text.secondary"
              sx={{
                whiteSpace: "pre-wrap",
                borderBottom: "none"
              }}
            >
              {" / "}
            </Typography>
            <Typography variant="h2" noWrap color="text.primary">
              {currentFolder?.name}
            </Typography>
          </>
        )}
      </Box>
    );
  }
};
