import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { status } from "@akord/akord-js/lib/constants";
import { Auth, Membership, RoleType, Stack, Vault } from "@akord/akord-js";
import { EncryptedKeys } from "@akord/crypto";
import { getVaultId } from "../helpers/helpers";
import { useGlobalContext } from "./GlobalDataProvider";
import { createMembersMap } from "../helpers/stack-helpers";
import { refreshSize } from "../helpers/api-helpers";

type VaultContextProps = {
  currentMembership?: Membership;
  currentMembershipRef: Membership;
  onMembers: (members: Membership[]) => void;
  members: Membership[];
  membersMap: Record<string, number>;
  keys: EncryptedKeys[];
  userRole: RoleType;
  vault: Vault;
  onVault: (vault: Vault) => void;
  onVaultUpdate: (id?: string) => Promise<void>;
  currentStack?: Stack;
  onCurrentStack: (stack: Stack) => void;
  onFileFolderId: (folderId: string) => void;
  fileFolderId?: string;
  newTimelineRef: React.MutableRefObject<boolean>;
  newAssetsRef: React.MutableRefObject<boolean>;
  isRoomArchived: boolean;
  isVaultPublic: boolean;
  isCloudStorage: boolean;
};

const Context = React.createContext<VaultContextProps>({} as VaultContextProps);

const VaultContextProvider: React.FC<RouteComponentProps> = ({ children, location }) => {
  const currentMembershipRef = React.useRef<Membership>({} as Membership);

  const [currentMembership, setCurrentMembership] = useState<Membership>();
  const handleCurrentMembership = (membership: Membership) => setCurrentMembership(membership);

  const [vault, setVault] = useState<Vault>({} as Vault);
  const handleVault = (vault: Vault) => setVault(vault);

  const [members, setMembers] = useState<Membership[]>([]);
  const handleMembers = (members: Membership[]) => setMembers(members);

  const [membersMap, setMembersMap] = useState<Record<string, number>>({});
  const handleMembersMap = (membersMap: Record<string, number>) => setMembersMap(membersMap);

  const [keys, setKeys] = useState<EncryptedKeys[]>([] as EncryptedKeys[]);
  const handleKeys = (keys: EncryptedKeys[]) => setKeys(keys);

  // Saving folder for File viewer to know
  // which files to show on the next/previous click
  // and a way back on close
  const [fileFolderId, setFileFolderId] = useState<string>();
  const handleFileFolderId = (folderId: string) => setFileFolderId(folderId);

  const [currentStack, setCurrentStack] = useState<Stack>();
  const handleCurrentStack = (stack: Stack) => setCurrentStack(stack);

  // const [cancelledTimelineItemsCount, setCancelledTimelineItemsCount] = useState(0);
  // const handleCancelledTimelineItemsCount = cancelledTimelineItemsCount => setCancelledTimelineItemsCount(cancelledTimelineItemsCount);

  const { akord } = useGlobalContext();

  const isPublicRoute = !!location.pathname.match("/public/"); // All anonymous routes have `/public` prefix
  const isRoomArchived = vault?.status === "ARCHIVED";
  const vaultId = getVaultId(location.pathname);

  const newTimelineRef = React.useRef(true);
  const newAssetsRef = React.useRef(true);
  const history = useHistory();

  const setVaultMembershipData = React.useCallback(
    async (id?: string) => {
      try {
        handleVault({} as Vault);
        handleMembers([]);

        if (!id && !vaultId) return;
        //@ts-ignore
        const vault = await akord?.vault.get(id || vaultId, { shouldDecrypt: !isPublicRoute });

        if (!vault) return;
        handleVault(vault);

        if (!isPublicRoute) {
          const attributes = await Auth.getUserAttributes();
          const memberships = await akord?.membership.listAll(vault.id, {
            shouldDecrypt: true,
            filter: {
              or: [
                { status: { eq: status.ACCEPTED } },
                { status: { eq: status.PENDING } },
                { status: { eq: status.INVITED } }
                //  { status: { eq: status.REVOKED } },
              ]
            }
          });
          handleMembers(memberships || []);
          handleMembersMap(createMembersMap(memberships || []));
          const membership = memberships?.find(
            membership =>
              membership.memberPublicSigningKey === attributes["custom:publicSigningKey"] ||
              membership.address === attributes["custom:address"] ||
              membership.email === attributes["email"]
          );
          handleCurrentMembership(membership || ({} as Membership));
          currentMembershipRef.current = membership || ({} as Membership);
          handleKeys(membership?.keys || []);
        }
      } catch (err) {
        console.warn("setVaultMembershipData error", err);
        history.push("/404");
      }
    },
    [akord, isPublicRoute, vaultId, history]
  );

  useEffect(() => {
    //if (isPublicRoute) setPublicVault();
    if (vaultId && akord) {
      setVaultMembershipData();
    }
    return () => {
      newTimelineRef.current = true;
      newAssetsRef.current = true;
      currentMembershipRef.current = {} as Membership;
      handleVault({} as Vault);
      handleMembers([]);
    };
  }, [akord, vaultId, setVaultMembershipData]);

  useEffect(() => {
    if (vaultId) {
      refreshSize(vaultId, 'vault');
    }
  }, [location.pathname, vaultId]);
  
  return (
    <Context.Provider
      value={{
        currentMembership: currentMembership,
        currentMembershipRef: currentMembershipRef.current,
        onMembers: handleMembers,
        members: members,
        membersMap: membersMap,
        keys: keys,
        userRole: currentMembership?.role || "VIEWER",
        vault: vault,
        onVault: handleVault,
        onVaultUpdate: setVaultMembershipData,
        currentStack: currentStack,
        onCurrentStack: handleCurrentStack,
        onFileFolderId: handleFileFolderId,
        fileFolderId: fileFolderId,
        // onCancelledTimelineItemsCount: handleCancelledTimelineItemsCount,
        // cancelledTimelineItemsCount: cancelledTimelineItemsCount,
        newTimelineRef: newTimelineRef,
        newAssetsRef: newAssetsRef,
        isRoomArchived: isRoomArchived,
        isVaultPublic: vault?.public,
        isCloudStorage: vault?.cloud || false
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default withRouter(VaultContextProvider);

export const withVaultContext = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{stackContext => <Component {...props} {...stackContext} />}</Context.Consumer>;

export const useVaultContext = () => useContext(Context);
