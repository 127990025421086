import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

export const useKeyPress = (keys: string[], callback: (event: KeyboardEvent) => void, node = null) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    event => {
      if (keys.some((key: string) => event.key === key)) {
        callbackRef.current(event);
      }
      // if (event.shiftKey === true) {
      //   if (keys.some((key: string) => event.key === key)) {
      //     callbackRef.current(event);
      //   }
      // }
    },
    [keys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};
