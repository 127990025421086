import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Grid, TextField, Box, InputAdornment } from "@mui/material";
import { AccountBackupIcon } from "@akord/addon-icons";
import { useRecoverAccountContext } from "../../contexts/RecoverAccountContext";
import { MissingWords } from "../../types/signUpTypes";

const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonGrid: {
    margin: 0
  },
  inputLabel: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontWeight: 700,
    textAlign: "center",
    padding: "12px 14px",
    marginLeft: "-20px"
  },
  xsGrid: {
    flexBasis: "33%",
    maxWidth: "33%"
  },
  inputAdornment: {
    fontSize: "10px",
    alignItems: "flex-end"
  },
  inputAdornedStart: {
    paddingLeft: 8
  }
});

const RecoverPhrase: React.FC<RouteComponentProps> = ({ location }) => {
  const classes = useStyles();
  const { handlebackupPhrase, backupPhrase, formData, setFormData, onPasteBackupPhrase } = useRecoverAccountContext();
  // get code from a magic link
  React.useEffect(() => {
    async function verifyEmail() {
      const decoded: { userName: string; address: string; clientId: string; region: string } = JSON.parse(atob(encoded!));
      const { userName, address } = decoded;
      setFormData({ ...formData, code: code!, userName, address });
    }

    const urlParams = new URLSearchParams(location.search);
    const encoded = urlParams.get("data");
    const code = urlParams.get("code");
    if (encoded && code) {
      verifyEmail();
    }
  }, [location.search]);

  const splitOnCopy = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const data = e.clipboardData.getData("text/plain");
    const newArray = data.trim().split(/\s/g);
    const backupPhraseObject = newArray.reduce((acc: MissingWords, cur, idx) => {
      acc[idx] = cur;
      return acc;
    }, {});
    onPasteBackupPhrase(backupPhraseObject);
    e.preventDefault();
  };

  return (
    <>
      <Box mb="12px" className={classes.rootWithIcon}>
        <Typography variant="h1">Recover account</Typography>
        <AccountBackupIcon fontSize="large" color="primary" />
      </Box>

      <Box className={classes.root}>
        <Box mb="20px">
          <Typography variant="body2">Please enter your 12-word recovery phrase to recover your account.</Typography>
        </Box>
        <Box>
          <Grid container justifyContent="space-between" spacing={2}>
            {Array.from({ length: 12 }).map((word, index) => {
              return (
                <Grid item xs={3} key={index} className={classes.xsGrid}>
                  <TextField
                    id={"outlined-basic-" + index}
                    variant="outlined"
                    className={classes.buttonGrid}
                    value={backupPhrase[index] || ""}
                    onChange={e => handlebackupPhrase(e, index)}
                    onPaste={splitOnCopy}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          {index + 1}
                        </InputAdornment>
                      ),
                      classes: {
                        input: classes.inputLabel,
                        adornedStart: classes.inputAdornedStart
                      }
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default withRouter(RecoverPhrase);
