import { Folder, Memo, Stack, Vault } from "@akord/akord-js";
import { Note } from "@akord/akord-js/lib/types/note";
import { documentAction, fromStatus } from "./document-action";
import { documentStatus, fromStateStatus } from "./document-status";
import { searchResultType } from "./search-result-type";

export const fromVaults = (vaults: Vault[]) => {
  if (Array.isArray(vaults) && vaults.length > 0) {
    return vaults.map(vault =>
      new Document()
        .getId(vault.id)
        .getType(searchResultType.DATA_ROOM)
        .getName(vault.name)
        .getActive(isActive(vault))
        .getUrl(`${roomUrl(vault.status, vault.id)}/assets`)
        .getSourceId(vault.id)
        .getPublic(vault.public)
        .getSourceName(null)
        .getSourceUrl(`${roomsUrl(vault.status)}`)
        .getAction(fromStatus(vault.status))
        .build()
    );
  }
};

export const fromStacks = (stacks: Stack[], vault: Vault) => {
  if (Array.isArray(stacks) && stacks.length > 0) {
    return stacks.map(stack =>
      new Document()
        .getId(stack.id)
        .getType(stackType(stack))
        .getName(stack.name)
        .getActive(isActive(stack))
        .getUrl(`${roomUrl(vault.status, vault.id)}/assets/${stack.id}`)
        .getAction(fromStatus(stack.status))
        .getSourceId(vault.id)
        .getSourceName(vault.name)
        .getPublic(vault.public)
        .getSourceUrl(`${roomUrl(vault.status, vault.id)}/${leafLocation(stack)}${inFolderLocation(stack)}`)
        .build()
    );
  }
};

export const fromFolders = (folders: Folder[], vault: Vault) => {
  if (Array.isArray(folders) && folders.length > 0) {
    return folders.map(folder =>
      new Document()
        .getId(folder.id)
        .getType(searchResultType.FOLDER)
        .getName(folder.name)
        .getActive(isActive(folder))
        .getUrl(`${roomUrl(vault.status, vault.id)}/assets/folders/${folder.id}`)
        .getAction(fromStatus(folder.status))
        .getSourceId(vault.id)
        .getSourceName(vault.name)
        .getPublic(vault.public)
        .getSourceUrl(`${roomUrl(vault.status, vault.id)}/${leafLocation(folder)}${inFolderLocation(folder)}`)
        .build()
    );
  }
};

export const fromNotes = (notes: Note[], vault: Vault) => {
  if (Array.isArray(notes) && notes.length > 0) {
    return notes.map(note =>
      new Document()
        .getId(note.id)
        .getType(searchResultType.NOTE)
        .getName(note.name)
        .getActive(isActive(note))
        .getUrl(`${roomUrl(vault.status, vault.id)}/assets/folders/${note.id}`)
        .getAction(fromStatus(note.status))
        .getSourceId(vault.id)
        .getSourceName(vault.name)
        .getPublic(vault.public)
        .getSourceUrl(`${roomUrl(vault.status, vault.id)}/${leafLocation(note)}${inFolderLocation(note)}`)
        .build()
    );
  }
};

export const fromMemos = (memos: Memo[], vault: Vault) => {
  if (Array.isArray(memos) && memos.length > 0) {
    return memos
      .map(memo => {
        return new Document()
          .getId(memo.id)
          .getType(searchResultType.MEMO)
          .getName(memo.versions[0]?.message)
          .getUrl(`${roomUrl(vault.status, vault.id)}/chat`)
          .getActive(isActive(memo))
          .getAction(documentAction.PUT)
          .getSourceId(vault.id)
          .getSourceName(vault.name)
          .getPublic(vault.public)
          .getSourceUrl(`${roomUrl(vault.status, vault.id)}/chat`)
          .build();
      })
      .filter(item => item);
  }
};

export class Document {
  id: string = "";
  type: string = "";
  name: string = "";
  url: string = "";
  isActive: boolean = false;
  isPublic: boolean = false;
  action: string = "";
  sourceId: string = "";
  sourceName: string | null = null;
  sourceUrl: string = "";

  getId(id: string) {
    this.id = id;
    return this;
  }

  getType(type: string) {
    this.type = type;
    return this;
  }

  getName(name: string) {
    this.name = name;
    return this;
  }

  getUrl(url: string) {
    this.url = url;
    return this;
  }

  getActive(isActive: boolean) {
    this.isActive = isActive;
    return this;
  }

  getPublic(isPublic: boolean) {
    this.isPublic = isPublic;
    return this;
  }

  getAction(action: string) {
    this.action = action;
    return this;
  }

  getSourceId(sourceId: string) {
    this.sourceId = sourceId;
    return this;
  }

  getSourceName(sourceName: string | null) {
    if (sourceName) {
      if (!this.isActive) {
        this.sourceName = `in ${sourceName} -> Hidden files`;
      } else {
        this.sourceName = `in ${sourceName}`;
      }
    } else {
      this.sourceName = null;
    }
    return this;
  }

  getSourceUrl(sourceUrl: string) {
    this.sourceUrl = sourceUrl;
    return this;
  }

  build() {
    if (this.id) {
      return this;
    }
    return null;
  }
}

const stackType = (stack: Stack) => {
  const fileType = stack?.versions && stack.versions[0] ? stack.versions[0].type : "";
  return stack?.versions?.length > 1
    ? searchResultType.STACK
    : fileType && fileType.match("image")
    ? searchResultType.STACK_IMAGE
    : searchResultType.STACK_DOCUMENT;
};

const roomUrl = (status: string, id: string) => {
  return `/vaults/${fromStateStatus(status)}/${id}`;
};

const roomsUrl = (status: string) => {
  return `/vaults/${fromStateStatus(status)}`;
};

const isActive = (model: Stack | Folder | Memo | Note | Vault) => {
  return fromStateStatus(model.status) === documentStatus.ACTIVE;
};

const leafLocation = (model: Stack | Folder | Memo | Note) => {
  if (!isActive(model)) {
    return "revoked-files";
  }
  return "assets";
};

const inFolderLocation = (model: Stack | Folder | Memo | Note) => {
  if (model.parentId && model.parentId !== "null") {
    return `/folders/${model.parentId}`;
  }
  return "";
};
