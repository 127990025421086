import React from "react";
import {
  AddMemberIcon,
  InactiveIcon,
  AvatarIcon,
  VaultLeaveIcon,
  TrashIcon,
  DownloadIcon,
  FileRestoreIcon,
  FolderOutlineIcon,
  FolderRestoreIcon,
  InfoIcon,
  InvitationAcceptIcon,
  KeyIcon,
  LetterIcon,
  MinusInCircleIcon,
  MoveIcon,
  NoteIcon,
  PaperPlaneIcon,
  PenIcon,
  RestoreIcon,
  StackIcon,
  TicketIcon,
  TickIcon,
  TickInCircleIcon,
  UploadIcon,
  CloseInCircleIcon,
  LinkIcon,
  CloseIcon,
  ManifestIcon,
  ListIcon,
  OrganisationIcon,
  UTokenIcon,
  VaultAnimatedIcon,
  ThumbsUpAnimatedIcon,
  InfinityAnimatedIcon,
  TickAnimatedIcon,
  PasswordMaskIcon,
  MintIcon,
  ZipIcon
} from "@akord/addon-icons";

interface SnackBarProps {
  [key: string]: SnackBarPropsItemProps;
}

interface SnackBarPropsItemProps {
  text: string;
  altText?: string;
  checkBoxText?: string;
  linkText?: string;
  Icon: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
  error?: boolean;
}

export const snackbarOptions: SnackBarProps = {
  dataRoomCreate: {
    text: "Your vault was successfully created.",
    Icon: VaultAnimatedIcon
  },
  dataRoomArchive: {
    text: "Your vault was successfully deactivated.",
    Icon: InactiveIcon
  },
  dataRoomRename: {
    text: "Your vault was successfully renamed.",
    Icon: PenIcon
  },
  dataRoomInvite: {
    text: "Your invitation was successfully sent.",
    altText: "new invitations were successfully sent.",
    Icon: AddMemberIcon
  },
  dataRoomRestore: {
    text: "Your vault was successfully restored.",
    Icon: RestoreIcon
  },
  vaultsBatchRestore: {
    text: "Your vaults were successfully restored.",
    Icon: RestoreIcon
  },
  vaultsBatchDelete: {
    text: "Your vaults were successfully removed.",
    Icon: MinusInCircleIcon
  },
  dataRoomDelete: {
    text: "Your vault was successfully removed.",
    Icon: MinusInCircleIcon
  },
  dataRoomConfirm: {
    text: "Access to your vault was successfully confirmed.",
    Icon: KeyIcon
  },
  dataRoomAccessUpdate: {
    text: "Access to your vault was successfully updated.",
    Icon: KeyIcon
  },
  dataRoomAccessPending: {
    text: "",
    altText: "has already had their access confirmed.",
    Icon: TickInCircleIcon
  },
  dataRoomAccessConfirm: {
    text: "",
    altText: "has had their access confirmed.",
    Icon: TickInCircleIcon
  },
  dataRoomAccessAccepted: {
    text: "",
    altText: "has already had their access confirmed.",
    Icon: InvitationAcceptIcon
  },
  dataRoomAccessRevoked: {
    text: "",
    altText: "has had their access revoked.",
    Icon: CloseInCircleIcon
  },
  dataRoomAccessReject: {
    text: "",
    altText: "has rejected the invite.",
    Icon: CloseInCircleIcon
  },
  discountApplied: {
    text: "Discount applied",
    Icon: TickInCircleIcon
  },
  discountRejected: {
    text: "Incorrect discount code",
    Icon: CloseInCircleIcon
  },
  memberRevoke: {
    text: "A member of your vault was successfully revoked.",
    Icon: KeyIcon
  },
  notAllowedToAccessOrg: {
    text: "You are not allowed to access this organization.",
    Icon: OrganisationIcon
  },
  orgCreated: {
    text: "Your organization details were successfully added.",
    Icon: OrganisationIcon
  },
  orgCreateFailed: {
    text: "",
    altText: "",
    Icon: CloseInCircleIcon
  },
  orgInvite: {
    text: "Your invitation was successfully sent.",
    Icon: AddMemberIcon
  },
  uploadPending: {
    text: "Your uploads are pending.",
    altText:
      "It may take a while to commit files to the blockchain depending on their size. You can still view, rename and move files while they’re pending.",
    checkBoxText: "Don’t show me this message again.",
    Icon: InfoIcon
  },
  uploadFailed: {
    text: "A file failed to upload to the blockchain.",
    linkText: "View transactions",
    Icon: InfoIcon,
    error: true
  },
  fileUpload: {
    text: "A new file saved forever! $U tokens will be awarded.",
    altText: "new files saved forever! $U tokens will be awarded.",
    Icon: InfinityAnimatedIcon
  },
  fileUploadCloud: {
    text: "A new file was successfully uploaded.",
    altText: "new files were successfully uploaded.",
    Icon: UploadIcon
  },
  fileDownload: {
    text: "Preparing your file for download. Large files may take a while.",
    altText: "file is being prepared for download",
    Icon: DownloadIcon
  },
  versionUpload: {
    text: "A new version was successfully uploaded.",
    Icon: StackIcon
  },
  zipUpload: {
    text: "A zip file was successfully uploaded",
    Icon: ZipIcon
  },
  fileRename: {
    text: "Your file was successfully renamed.",
    Icon: PenIcon
  },
  fileRevoked: {
    text: "Your file was successfully hidden.",
    Icon: PasswordMaskIcon
  },
  batchRevoked: {
    text: "Your items were successfully hidden.",
    Icon: PasswordMaskIcon
  },
  fileRestore: {
    text: "Your file was successfully restored.",
    Icon: FileRestoreIcon
  },
  batchRestore: {
    text: "Your items were successfully restored.",
    Icon: FileRestoreIcon
  },
  fileRemove: {
    text: "Your file was successfully cleared.",
    Icon: MinusInCircleIcon
  },
  batchRemove: {
    text: "Your items were successfully cleared.",
    Icon: MinusInCircleIcon
  },
  batchDelete: {
    text: "Your items were successfully deleted.",
    Icon: MinusInCircleIcon
  },
  restoreFileWithMove: {
    text: "Your file was successfully restored.",
    Icon: FileRestoreIcon
  },
  fileDelete: {
    text: "Your file was successfully deleted.",
    Icon: TrashIcon
  },
  passwordChange: {
    text: "Your password was successfully changed.",
    Icon: TickIcon
  },
  mfaEnabled: {
    text: "2FA was successfully added to your account.",
    Icon: TickIcon
  },
  mfaDisabled: {
    text: "Two-factor authentication was successfully disabled.",
    Icon: TickIcon
  },
  profileUpdate: {
    text: "Your profile was successfully updated.",
    Icon: AvatarIcon
  },
  userVerified: {
    text: "Your email was successfully verified.\nPlease enter your email and password to log in.",
    Icon: ThumbsUpAnimatedIcon
  },
  linkToVerify: {
    text: "Check your email for a verification link.",
    Icon: LetterIcon
  },
  folderCreate: {
    text: "Your folder was successfuly created.",
    Icon: FolderOutlineIcon
  },
  folderRename: {
    text: "Your folder was successfully renamed.",
    Icon: PenIcon
  },
  folderRevoke: {
    text: "Your folder was successfully hidden.",
    Icon: PasswordMaskIcon
  },
  folderRestore: {
    text: "Your folder was successfully restored.",
    Icon: FolderRestoreIcon
  },
  restoreFolderWithMove: {
    text: "Your folder was successfully restored.",
    Icon: FolderRestoreIcon
  },
  folderDelete: {
    text: "Your folder was successfully deleted.",
    Icon: TrashIcon
  },
  fileMove: {
    text: "Your file was successfully moved.",
    Icon: MoveIcon
  },
  folderMove: {
    text: "Your folder was successfully moved.",
    Icon: MoveIcon
  },
  batchMove: {
    text: "Your items were successfully moved.",
    Icon: MoveIcon
  },
  noteMove: {
    text: "Your note was successfully moved.",
    Icon: MoveIcon
  },
  topUpSuccess: {
    text: "Your top up was successfully processed.",
    Icon: TickAnimatedIcon
  },
  topUpFailure: {
    text: "",
    altText: "",
    Icon: CloseInCircleIcon
  },
  paymentFailed: {
    text: "Payment request failed. Please try again..",
    Icon: CloseInCircleIcon
  },
  paymentTermsNotAccepted: {
    text: "Accepting the terms and conditions is mandatory before signing up for the plan.",
    Icon: CloseInCircleIcon
  },
  subscriptionSetupSuccess: {
    text: "Your subscription was successfully setup.",
    Icon: TickIcon
  },
  referralInvite: {
    text: "Your referral invite was successfully sent.",
    Icon: TicketIcon
  },
  referralInvites: {
    text: "Your referral invites were successfully sent.",
    Icon: TicketIcon
  },
  membershipLeave: {
    text: "You have successfully left the vault.",
    Icon: VaultLeaveIcon
  },
  resendInvite: {
    text: "Your invite was successfully resent.",
    Icon: PaperPlaneIcon
  },
  revokeNote: {
    text: "Your note was successfully hidden.",
    Icon: PasswordMaskIcon
  },
  restoreNote: {
    text: "Your note was successfully restored.",
    Icon: FileRestoreIcon
  },
  saveNote: {
    text: "Your note was successfully saved.",
    Icon: NoteIcon
  },
  reviseNote: {
    text: "A new version of your note was successfully saved.",
    Icon: NoteIcon
  },
  noteRemove: {
    text: "Your note was successfully cleared.",
    Icon: MinusInCircleIcon
  },
  actionFailure: {
    text: "Something went wrong.",
    Icon: CloseInCircleIcon
  },
  shareFile: {
    text: "The link to this image was copied to your clipboard",
    Icon: LinkIcon
  },
  shareVault: {
    text: "The link to this vault was copied to your clipboard",
    Icon: LinkIcon
  },
  shareNft: {
    text: "The link to this Atomic NFT was copied to your clipboard",
    Icon: LinkIcon
  },
  importArweave: {
    text: "Your file was successfully imported.",
    Icon: TickIcon
  },
  importArweaveError: {
    text: "Your import failed. Please try again.",
    Icon: CloseIcon
  },
  createManifest: {
    text: "The manifest was successfully added to your vault. ",
    Icon: ManifestIcon
  },
  deleteAccount: {
    text: "Request to delete the account was successfully sent. ",
    Icon: AvatarIcon
  },
  vaultUpdate: {
    text: "Your vault's metadata was updated.",
    Icon: ListIcon
  },
  billingSaved: {
    text: "Your billing details were successfully changed.",
    Icon: TickIcon
  },
  billingUpdateFailure: {
    text: "",
    altText: "",
    Icon: CloseInCircleIcon
  },
  apiKeyGenerateFailed: {
    text: "",
    altText: "",
    Icon: CloseInCircleIcon
  },
  webhookAddFailed: {
    text: "",
    altText: "",
    Icon: CloseInCircleIcon
  },
  webhookRemoveFailed: {
    text: "",
    altText: "",
    Icon: CloseInCircleIcon
  },
  sendToken: {
    text: "Your tokens were successfully sent.",
    Icon: UTokenIcon
  },
  nftMinted: {
    text: "Your Atomic NFT was successfully minted.",
    Icon: MintIcon
  },
  nftCollectionMinted: {
    text: "Your Atomic NFT collection was successfully minted.",
    Icon: MintIcon
  },
  genericError: {
    text: "Something went wrong, please try again later.",
    Icon: CloseInCircleIcon
  }
};
