import React, { useEffect, useState } from "react";
import MoreMenuRoot from "./MoreMenuRoot";
import MoreMenuItem from "./MoreMenuItem";
import { useVaultMenuOptions } from "./MenuOptions";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { Membership, Vault } from "@akord/akord-js";

type MenuRoomProps = {
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  handleMenuClose: () => void;
  openMenu: boolean;
  vaultId?: string;
  vault?: Vault;
  currentMembership?: Membership;
  memberships: Membership[];
};

const MenuRoom: React.FC<MenuRoomProps> = ({ anchorEl, openMenu, handleMenuClose, vault, currentMembership, memberships, vaultId }) => {
  const [vaultData, setVaultData] = useState<Vault>();
  const [membershipData, setMembershipData] = useState<Membership>();
  const [membersData, setMembersData] = useState<Membership[]>();

  const { akord, userAttributes } = useGlobalContext();
  const { options: vaultOptions } = useVaultMenuOptions();

  useEffect(() => {
    const loadData = async () => {
      const vault = await akord!.vault.get(vaultId!);
      setVaultData(vault);
      const members = await akord!.membership.listAll(vaultId!);
      setMembersData(members);

      const member = members.find(membership => membership.address === userAttributes.address);
      if (member) {
        setMembershipData(member);
      }
    };
    if (vaultId && akord) {
      loadData();
    } else {
      setVaultData(vault);
      setMembershipData(currentMembership);
      setMembersData(memberships);
    }
  }, [vaultId, vault, currentMembership, akord]);

  return (
    <MoreMenuRoot anchorEl={anchorEl} handleMenuClose={handleMenuClose} openMenu={openMenu} id="room-menu">
      <div>
        {vaultOptions(vaultData!, membershipData!, membersData!, handleMenuClose).map(
          (menuItem, index) => menuItem.show && <MoreMenuItem key={index} menuItem={menuItem} />
        )}
      </div>
    </MoreMenuRoot>
  );
};

export default MenuRoom;
