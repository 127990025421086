import React from "react";
import { Box, Breakpoint, Container, createTheme, CssBaseline, Theme } from "@mui/material";
import { Helmet } from "react-helmet";
import makeStyles from "@mui/styles/makeStyles";
import ButtonGroup from "../ButtonGroup";
import { theme as akordTheme } from "../../../theme/mui";
import { withRouter, RouteComponentProps } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ThemeWrapper from "./ThemeWrapper";
import { Location } from "history";
import { useOrgContext } from "../../../contexts/OrgContextProvider";
import { ButtonGroupProps } from "../../../types/globalTypes";

interface PageWrapperProps extends ButtonGroupProps {
  children?: React.ReactNode;
  className?: string;
  darkMode?: boolean;
  location?: Location;
  mainClassName?: string;
  maxWidth?: Breakpoint;
  pageTitle?: string;
  showBottomNav?: boolean;
  width?: string;
}

type PageWrapperStylesProps = {
  isDesktop: boolean;
  showButtonInColumn?: boolean;
};

const useStyles = makeStyles<Theme, PageWrapperStylesProps>({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: ({ isDesktop, showButtonInColumn }) => (isDesktop || showButtonInColumn ? "flex-start" : "space-between"),
    paddingTop: "20px",
    paddingBottom: "25px"
  }
});

const PageWrapper: React.FC<PageWrapperProps & RouteComponentProps> = ({
  backLoading,
  children,
  className = "",
  darkMode = false,
  disableBackButton,
  disabledRed,
  error,
  errorText,
  fullWidthNextButton,
  handleBack,
  handleEnd,
  handleNext,
  hideBackButton,
  hideIcon,
  inverted,
  loading,
  location,
  mainClassName = "",
  maxWidth = "xs",
  nextDiasabled,
  nextText,
  noMinWidth = false,
  pageTitle = "Akord",
  showBottomNav = false,
  showButtonInColumn,
  width,
  ...rest
}) => {
  const { org } = useOrgContext();
  const theme = createTheme(akordTheme());
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const classes = useStyles({
    isDesktop: isDesktop,
    showButtonInColumn: showButtonInColumn
  });
  const fullWidth = width === "xs";

  const { host, protocol } = window.location;
  const baseUrl = `${protocol}//${host}`;
  const pathname = location.pathname;
  const brandName = org?.name ?? "Akord";

  return (
    <ThemeWrapper darkMode={darkMode}>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${pageTitle} | ${brandName}`}</title>
        <link rel="canonical" href={`${baseUrl}${pathname}`} />
      </Helmet>
      <Box className={`${className}`} height="100%">
        <Container className={`${classes.main} ${mainClassName}`} maxWidth={fullWidth ? "lg" : maxWidth} sx={{ minHeight: "100%" }}>
          {children}
          {showBottomNav ? (
            <ButtonGroup
              noMinWidth={noMinWidth}
              error={error}
              errorText={errorText}
              nextDiasabled={nextDiasabled}
              handleNext={handleNext}
              handleBack={handleBack}
              handleEnd={handleEnd}
              disableBackButton={disableBackButton}
              nextText={nextText}
              inverted={inverted}
              hideBackButton={hideBackButton}
              fullWidthNextButton={fullWidthNextButton}
              hideIcon={hideIcon}
              disabledRed={disabledRed}
              showButtonInColumn={showButtonInColumn}
              loading={loading}
              backLoading={backLoading}
              {...rest}
            />
          ) : null}
        </Container>
      </Box>
    </ThemeWrapper>
  );
};

export default withRouter(PageWrapper);
