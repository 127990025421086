import React from "react";
import { Auth } from "@akord/akord-js";
import { Box, Typography } from "@mui/material";
import { ButtonGroup, ErrorAlert, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { useHistory, useLocation } from "react-router-dom";

interface LocationState extends Location {
  deviceName?: string;
}

function SecurityPhoneNumberMfaVerify() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [otp, setOtp] = React.useState("");

  const history = useHistory();
  const location = useLocation<LocationState>();
  const deviceName = location.state?.deviceName;

  const { isMobile } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const handleChange = (newValue: string) => {
    setError(false);
    setOtp(newValue);
  };

  const isDisabled = () => {
    return otp?.length !== 6 || loading;
  };

  const verify = async () => {
    try {
      if (!otp && !deviceName) return;
      setLoading(true);
      await Auth.verifySoftwareToken(otp, deviceName!);
      await Auth.enableMFA("TOTP");
      setLoading(false);
      onSnackbarToShow("mfaEnabled");
      history.push("/account/security/two-factor-authentication");
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  return (
    <SupportScreenWrapper title="Verify authenticator app" route="/account/security/two-factor-authentication/totp">
      <Box mt={5} mb={6}>
        <Typography component="span" variant="body2" color="text.primary" sx={{ marginBottom: 4 }}>
          Enter the 6-digit code from your authenticator app.
        </Typography>
      </Box>
      <Box mt={3} width={isMobile ? "100%" : "400px"}>
        {/* @ts-ignore */}
        <MuiOtpInput length={6} value={otp} onChange={handleChange} autoFocus />
      </Box>
      <Box mt={2}>
        <ButtonGroup
          loading={loading}
          noMinWidth={true}
          nextText="Verify authenticator"
          nextDiasabled={isDisabled()}
          handleNext={() => verify()}
          hideBackButton={true}
          fullWidthNextButton={isMobile ? true : false}
          hideIcon={true}
          disabledRed={false}
          noMarginTop={true}
        />
      </Box>
      {error && <ErrorAlert getErrorText="Wrong code. Please check and try again." />}
    </SupportScreenWrapper>
  );
}

export default SecurityPhoneNumberMfaVerify;
