import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ArweaveIcon, FilterIcon } from "@akord/addon-icons";
import { Box, Button, Typography, Link, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import TransactionsTable from "./TransactionsTable";
import { prepareDataForBar, prepareDataForCloudBar } from "./storage-helper";
import { StorageTransaction, useStorageContext } from "../../contexts/StorageContextProvider";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { getStorageTransactions } from "../../helpers/api-helpers";
import { useOrgContext } from "../../contexts/OrgContextProvider";
import StorageDataBlock from "./StorageBlock";

const Storage: React.FC = () => {
  const [loadingMore, setLoadingMore] = React.useState(false);

  const { isMobile } = useGlobalContext();
  const {
    globalStorage,
    transactionNextToken,
    transactions,
    onTransactions,
    onTransactionNextToken,
    refreshStorage,
    loading,
    onShowTransactionsFilter,
    showTransactionsFilter,
    actionStorageFilters,
    periodStorageFilter
  } = useStorageContext();
  const { fetchSubscriptionPlans } = usePaymentContext();
  const { isCurrentUserOrgAdmin, isOrg } = useOrgContext();

  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);

  const history = useHistory();

  const transactionRef = React.useRef<HTMLDivElement>();

  const verfifyBalance = () => {
    return (
      <IconButton
        onClick={() => refreshStorage()}
        aria-label="close"
        title="The balance can be out of sync if you recently had a failed upload."
        sx={{ justifyContent: "end", mx: 4 }}
      >
        <Typography variant="caption" className="small" color="text.secondary" sx={{ marginRight: 6 }}>
          Verify balance
        </Typography>
        <CircularProgress
          variant={loading ? "indeterminate" : "determinate"}
          value={loading ? undefined : 75}
          size={14}
          sx={{
            color: "text.disabled"
          }}
        />
      </IconButton>
    );
  };

  const handleLoadMore = async () => {
    setLoadingMore(true);
    const results = await getStorageTransactions({ limit: 20, nextToken: transactionNextToken });
    setLoadingMore(false);

    if (!results) return;
    const transactionsCopy = [...transactions!];
    const updatedTransactions = transactionsCopy?.concat(results.items as StorageTransaction[]);
    onTransactions(updatedTransactions);
    onTransactionNextToken(results.nextToken!);
  };

  const { storage_available, storage_total, cloud_storage_available, cloud_storage_total } = globalStorage || {};

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={4}>
        <Box>
          <Typography variant="h2">Storage</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          {!isMobile && verfifyBalance()}
          <Button
            disabled={!isCurrentUserOrgAdmin && isOrg}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => history.push("/top-up")}
            sx={{ marginRight: 3 }}
          >
            Top up
          </Button>
          <Button
            disabled={!isCurrentUserOrgAdmin && isOrg}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => history.push("/account/plans-and-payments/pricing-plans")}
          >
            Upgrade
          </Button>
        </Box>
      </Box>
      {isMobile && (
        <Box display="flex" justifyContent="end">
          {verfifyBalance()}
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" flexDirection={isMobile ? "column" : "row"} mt={5} mx={4} gap={isMobile ? 0 : 4}>
        <StorageDataBlock
          storageType="permanent"
          storageAvailable={storage_available || 0}
          storageUsed={storage_total && storage_total - (storage_available || 0) > 0 ? storage_total - (storage_available || 0) : 0}
          storageBarData={globalStorage ? prepareDataForBar(globalStorage) : undefined}
        />
        {isMobile && <Box mb={4} />}
        <StorageDataBlock
          storageType="cloud"
          storageAvailable={cloud_storage_available || 0}
          storageUsed={
            cloud_storage_total && cloud_storage_total - (cloud_storage_available || 0) > 0
              ? cloud_storage_total - (cloud_storage_available || 0)
              : 0
          }
          storageBarData={globalStorage ? prepareDataForCloudBar(globalStorage) : undefined}
        />
      </Box>

      <Box display="flex" mt={3} mx={4}>
        {globalStorage &&
          prepareDataForBar(globalStorage)?.map(
            (item, index) =>
              item.description !== "Free" && (
                <Box key={index} display="flex" alignItems="baseline" mr={4}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      backgroundColor: item.color,
                      marginRight: 1
                    }}
                  />
                  <Typography variant="caption" className="small" color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
              )
          )}
      </Box>

      <Box mt={7} mb={2} mx={4} display="flex" alignItems="center">
        <ArweaveIcon fontSize="small" sx={{ marginRight: 2 }} />
        <Typography variant="h3" id="tableTitle" component="div">
          Arweave transactions
        </Typography>
        <Tooltip title="Filter the transactions" arrow>
          <IconButton
            disableRipple
            aria-label="filter"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            sx={{ marginLeft: 2, flexDirection: "column" }}
            onClick={() => onShowTransactionsFilter(!showTransactionsFilter)}
          >
            <FilterIcon fontSize="small" />
            {(actionStorageFilters.length || periodStorageFilter) && (
              <FiberManualRecord color="action" sx={{ fontSize: "8px", position: "absolute", bottom: "-8px" }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box mb={7} ref={transactionRef}>
        <TransactionsTable boxRef={transactionRef.current} />
      </Box>

      {transactionNextToken && transactions && transactions.length > 0 && (
        <Box pb={isMobile ? 10 : 7} display="flex" justifyContent="center">
          {loadingMore ? (
            <Typography variant="body2" className="small" color="text.secondary">
              Loading...
            </Typography>
          ) : (
            <Link variant="body2" className="small" onClick={() => handleLoadMore()} underline="always" color="text.secondary">
              Load more transactions...
            </Link>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Storage;
