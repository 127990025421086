import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { getStorage } from "../../helpers/api-helpers";
import { useNftContext } from "../../contexts/NftContextProvider";
import FileDropArea from "../../components/common/FileDropArea/FileDropArea";
import InfoWarningBox from "../../components/common/InfoWarningBox/InfoWarningBox";
import FilesCollectionDropArea from "../../components/common/FileDropArea/FilesCollectionDropArea";
import useMint from "../../hooks/useMint";
import { AssetType } from "@akord/akord-js";
import AutocompleteWithTags, { AutocompleteOptionsProps } from "../../components/common/AutocompletWithTags/AutocompleteWithTags";
import { getVaultId } from "../../helpers/helpers";

const fileTypes: AutocompleteOptionsProps[] = [
  { value: "app", text: "App" },
  { value: "audio", text: "Audio" },
  { value: "blog-post", text: "Blog post" },
  { value: "collection", text: "Collection" },
  { value: "contract", text: "Contract" },
  { value: "document", text: "Document" },
  { value: "image", text: "Image" },
  { value: "meme", text: "Meme" },
  { value: "music", text: "Music" },
  { value: "podcast", text: "Podcast" },
  { value: "presentation", text: "Presentation" },
  { value: "profile", text: "Profile" },
  { value: "social-post", text: "Social post" },
  { value: "token", text: "Token" },
  { value: "video", text: "Video" },
  { value: "web-page", text: "Web page" },
  { value: "other", text: "Other" }
];

const FileForm: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [file, setFile] = useState<File>();

  const [minting, setMinting] = useState(false);
  const [preloadError, setPreloadError] = useState<"NOT_ENOUGH_STORAGE" | "NAME_TOO_LONG">();

  const { isMobile } = useGlobalContext();
  const { refreshNfts, onNFTMetadata, nftMetadata, onFilesCollection, filesCollection } = useNftContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const isCollection = location.pathname.includes("/collection/");
  const vaultId = getVaultId(location.pathname);
  const { mintSingle } = useMint(vaultId);

  const onHandleCollection = () => {
    //File name without ext should be less than 150 chars
    const namesLengthPassed = filesCollection?.every(file => file.name.replace(/\.[^/.]+$/, "").length <= 150);
    if (!namesLengthPassed) setPreloadError("NAME_TOO_LONG");
    else history.push(`/vaults/active/${vaultId}/nfts/mint/collection/display`);
  };

  const onHandleMint = async () => {
    try {
      setMinting(true);

      if (!file) {
        throw new Error("Failed uploading the file");
      }
      const storage: any = await getStorage(); //check if there is a storage type
      const calculatedFilesSize = file?.size! + nftMetadata.thumbnail?.size! || 0;
      //File name without ext should be less than 150 chars
      const namesLengthPassed = file.name.replace(/\.[^/.]+$/, "").length <= 150;
      // Check if a user has enough storage available
      if (calculatedFilesSize > storage?.storage_available || !namesLengthPassed) {
        setMinting(false);
        if (!namesLengthPassed) {
          console.error("Name is too long: ", file.name.replace(/\.[^/.]+$/, ""));
          setPreloadError("NAME_TOO_LONG");
        } else {
          console.error("Not enough storage available: ", storage?.storage_available);
          setPreloadError("NOT_ENOUGH_STORAGE");
        }
      } else {
        await mintSingle(file!);
        onSnackbarToShow("nftMinted");
        setMinting(false);
        const abortController = new AbortController();
        refreshNfts(abortController);
        history.push(`/vaults/active/${vaultId}/nfts`);
      }
    } catch (error) {
      console.error("Handle minting error: ", error);
      setMinting(false);
      onSnackbarToShow("genericError", null, "error");
      history.push(`/vaults/active/${vaultId}/nfts`);
    }
  };

  const handleSingleFileUpdate = (keyValue: string) => (file: File) => {
    onNFTMetadata({
      ...nftMetadata,
      [keyValue]: file
    });
  };

  const handleAutocompleteTypes = (types: string[]) => {
    onNFTMetadata({
      ...nftMetadata,
      types: types as AssetType[]
    });
  };

  return (
    <SupportScreenWrapper title={isCollection ? "Collection assets" : "Upload asset"} hideChevron fullHeight>
      <Box height="auto" display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"} mt={6}>
        {isCollection && (
          <InfoWarningBox text="File names are used in the metadata as the name of the Atomic NFT. Make sure all your files names are no longer than 150 characters." />
        )}
        <Box mb={4}>
          <Box display="flex" alignItems="flex-start" mb={1}>
            <Typography variant="h3">{isCollection ? "Select the collection assets" : "Select your Atomic NFT asset"}</Typography>
          </Box>
          <Typography variant="body2" className="small">
            {isCollection
              ? "Final step, select the files you would like to mint as NFTs in your collection."
              : "Final step, select the file you would like to mint into an Atomic NFT."}
          </Typography>
        </Box>
        {isCollection ? (
          <FilesCollectionDropArea onSetFiles={onFilesCollection} />
        ) : (
          <FileDropArea onSetFile={setFile} file={file} id="asset" />
        )}
        {preloadError === "NOT_ENOUGH_STORAGE" && (
          <Box>
            <Typography variant="caption" color="error">
              This file exceeds the storage limit on your account. You can{" "}
              <Link onClick={() => window.open("https://v2.akord.com/top-up")}>top up</Link> or{" "}
              <Link onClick={() => window.open("https://v2.akord.com/account/plans-and-payments/pricing-plans")}>upgrade</Link> your
              subscription plan to add more storage.
            </Typography>
          </Box>
        )}
        {preloadError === "NAME_TOO_LONG" && (
          <Box>
            <Typography variant="caption" color="error">
              File name should not exceed 150 characters.
            </Typography>
          </Box>
        )}
        <Box my={7}>
          <Box mb={4}>
            <Box display="flex" alignItems="flex-start" mb={1}>
              <Typography variant="h3">Asset tags (optional)</Typography>
            </Box>
            <Typography variant="body2" className="small">
              Add one or a few tags to help define what type of asset you’re uploading.
            </Typography>
          </Box>
          <AutocompleteWithTags
            options={fileTypes}
            // defaultValue={[{ value: "other", text: "Other" }] as AutocompleteOptionsProps[]}
            onChange={handleAutocompleteTypes}
          />
        </Box>
        {!isCollection && (
          <>
            <Box mb={2}>
              <Box display="flex" alignItems="flex-start" mb={1}>
                <Typography variant="h3">Thumbnail image (optional)</Typography>
              </Box>
              <Typography variant="body2" className="small">
                If you’re not minting an image file as the NFT itself, it’s a good idea to upload a thumbnail image. We recommend the size
                to be at least 300x300px.
              </Typography>
            </Box>
            <FileDropArea onSetFile={handleSingleFileUpdate("thumbnail")} width="155px" height="155px" id="asset" />
          </>
        )}

        <ButtonGroup
          nextDiasabled={minting || !!preloadError || (isCollection ? !filesCollection?.length : !file)}
          loading={minting}
          handleEnd={() => (isCollection ? onHandleCollection() : onHandleMint())}
          handleBack={() => history.goBack()}
          nextText={isCollection ? "Next" : "Mint Atomic NFT"}
          hideIcon={!isCollection}
        />
      </Box>
    </SupportScreenWrapper>
  );
};

export default withRouter(FileForm);
