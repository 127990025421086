export const confirmationErrorTexts = {
  archive: "There was an error archiving the vault.",
  batchMove: "There was an error moving files.",
  batchRemove: "There was an error removing files.",
  batchRemoveVaults: "There was an error removing vaults.",
  batchRestore: "There was an error restoring files.",
  batchRestoreVaults: "There was an error restoring vaults.",
  batchRevoke: "There was an error revoking files.",
  confirmUpload: "There was an error uploading files.",
  confirmUploadRetry: "There was an error uploading files.",
  createManifest: "There was an error creating the manifest.",
  delete: "There was an error deleting the folder.",
  deleteFile: "There was an error creating the manifest.",
  duplicateFile: "There was an error.",
  fileMove: "There was an error moving your file.",
  folderDelete: "There was an error deleting the folder.",
  folderMove: "There was an error moving the folder.",
  folderRestore: "There was an error restoring the folder.",
  folderRevoke: "There was an error revoking the folder.",
  leave: "There was an error leaving the vault.",
  noteMove: "There was an error moving the note.",
  removeFile: "There was an error deleting the file.",
  removeNote: "There was an error deleting the note.",
  restore: "There was an error restoring the vault.",
  restoreFile: "There was an error restoring the file.",
  restoreFileWithMove: "There was an error restoring the file.",
  restoreFolderWithMove: "There was an error restoring the folder.",
  restoreNote: "There was an error restoring the note.",
  restoreStack: "There was an error restoring the file.",
  revoke: "There was an error revoking an access.",
  revokeFile: "There was an error revoking the file.",
  revokeNote: "There was an error revoking the note.",
  revokeStack: "There was an error revoking the files.",
  saveNote: "There was an error saving the note.",
  dataRoomInvite: "There was an error sending the invitation.",
  orgInvite: "Add your organisation name before sending an invite.",
  default: "There was an error."
};

export type ConfirmationErrorTypes = keyof typeof confirmationErrorTexts
