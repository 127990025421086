import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Paper, TextField, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import { SearchIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSearchContext } from "../../contexts/SearchContextProvider";
import { grey } from "../../theme/colors";

type SearchBoxStylesProps = {
  darkMode: boolean;
  forceActive: boolean;
};

const useStyles = makeStyles<Theme, SearchBoxStylesProps>(theme => ({
  inputActive: {
    transition: "width 0.5s linear 0s"
  },
  textFieldWithButton: {
    transition: "width 0.3s linear 0s",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid",
    borderRadius: "4px",
    background: ({ darkMode }) => `linear-gradient(
      to left,
      ${darkMode ? grey[800] : grey[100]} 0px,
      ${darkMode ? grey[800] : grey[100]} 37px,
      transparent 37px,
      transparent 100%
    )`
  },
  textFieldWithButtonInactive: {
    width: "10%",
    minWidth: "115px",
    borderColor: ({ darkMode }) => (darkMode ? grey[700] : grey[300]),
    "&:hover": {
      borderColor: theme.palette.primary.main
    }
  },
  textFieldWithButtonActive: {
    width: ({ forceActive }) => (forceActive ? "100%" : "75%"),
    minWidth: "115px",
    borderColor: theme.palette.primary.main,
    padding: "0px"
  },
  textFieldWithButtonReady: {
    width: ({ forceActive }) => (forceActive ? "100%" : "75%"),
    borderColor: theme.palette.primary.main,
    padding: "0px",
    background: `linear-gradient(
      to left,
      ${theme.palette.primary.main} 0px,
      ${theme.palette.primary.main} 37px,
      transparent 37px,
      transparent 100%
    )`
  },
  textField: {
    width: "100%",
    height: "40px",
    minWidth: "70px",
    display: "flex",
    justifyContent: "flex-end",
    transition: "width 0.5s linear 0s",
    border: "none",
    marginBottom: 0,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none"
      },
      "&.Mui-focused fieldset": {
        border: "none"
      },
      "&:hover fieldset": {
        border: "none"
      }
    }
  },
  textFieldActive: {
    width: "100%"
  },
  textFieldRoot: {
    fontSize: "15px",
    height: "100%",
    border: "none",
    paddingLeft: "8px !important"
  },
  textFieldInput: {
    padding: "0px"
  },
  container: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    maxHeight: "40px"
  },
  searchButton: {
    border: "none",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: "0 8px",
    height: "38px"
  },

  searchButtonInactive: {
    "&.MuiButton-outlined": {
      border: "none"
    },
    "& .MuiButton-label": {
      color: ({ darkMode }) => (darkMode ? grey[500] : grey[400])
    }
  },
  searchButtonActive: {
    "&.MuiButton-outlinedPrimary": {
      border: "none"
    },
    "& .MuiButton-label": {
      color: ({ darkMode }) => (darkMode ? grey[500] : grey[400])
    }
  }
}));

type SearchBoxProps = {
  showRecentSearchTerm?: boolean;
  forceActive?: boolean;
};

const SearchBox: React.FC<SearchBoxProps> = ({ showRecentSearchTerm = false, forceActive = false }) => {
  const { darkMode } = useGlobalContext();
  const classes = useStyles({ forceActive: forceActive, darkMode: darkMode });
  const history = useHistory();
  const location = useLocation();
  const { autoSuggest, onSearch, searchResults, recentSearchTerm, onRecentSearchTerm, isSearchActive, onSearchActive, suggestions } =
    useSearchContext();
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchReady, setIsSearchReady] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [autoSuggestions, setAutoSuggestions] = useState<string[]>([]);

  useEffect(() => {
    window.onpopstate = () => {
      const urlParams = new URLSearchParams(location.search);
      const term = urlParams.get("term");
      if (term) {
        setSearchTerm(term);
        onSearchActive(true);
        setIsSearching(true);
        onRecentSearchTerm(term);
        onSearch(term);
      }
    };
  }, [location.search]);

  useEffect(() => {
    if (!searchTerm) {
      onSearchActive(false);
    }
  }, []);

  useEffect(() => {
    if (isSearching) {
      setIsSearching(false);
      history.push({
        pathname: "/search-results",
        search: `?term=${searchTerm}`
      });
    }
  }, [searchResults]);

  useEffect(() => {
    setSearchState();
  }, [searchTerm]);

  useEffect(() => {
    setSearchState();
  }, [isSearchActive]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const term = urlParams.get("term");
    if (term) {
      setSearchTerm(term);
      onSearchActive(true);
    } else if (showRecentSearchTerm) {
      setSearchTerm(recentSearchTerm);
      onSearchActive(true);
    }
  }, [showRecentSearchTerm, location.pathname]);

  useEffect(() => {
    if (!showRecentSearchTerm) {
      setSearchState();
    }
  }, [isSearchReady]);

  useEffect(() => {
    if (isSearchActive && searchTerm && suggestions && suggestions.length) {
      setAutoSuggestions(suggestions.map(item => item.suggestion));
    } else {
      return setAutoSuggestions([]);
    }
  }, [isSearchActive, searchTerm, suggestions]);

  const setSearchState = () => {
    const isReady = isSearchActive && !!searchTerm && searchTerm.length > 2;
    setIsSearchReady(isReady);
  };

  const searchHandler = () => () => {
    handleSearch();
  };

  const handleSearch = () => {
    if (isSearchActive && searchTerm) {
      setIsSearching(true);
      onRecentSearchTerm(searchTerm);
      onSearch(searchTerm);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    onRecentSearchTerm("");
    setIsSearchReady(false);
    history.push({
      pathname: "/"
    });
  };

  const handleSearchTermChange = () => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const term = event.target?.innerText || event.target.value;
    if (term && term.length > 0) {
      autoSuggest(term);
    }
    const isReady = !!term && term.length > 2;
    setIsSearchReady(isReady);
    setSearchTerm(term);
  };

  const onKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const onAutocompleteMatch = (event: React.SyntheticEvent<Element, Event>, term: string | null) => {
    if (!term && event.type === "click") {
      handleClearSearch();
    } else if (term) {
      setIsSearching(true);
      onRecentSearchTerm(term);
      setSearchTerm(term);
      onSearch(term);
    }
  };

  const isSearchBoxActive = () => {
    return isSearchActive || forceActive;
  };

  return (
    <div className={classes.container} tabIndex={0}>
      <Box
        className={`${classes.textFieldWithButton} ${
          isSearchReady
            ? classes.textFieldWithButtonReady
            : isSearchBoxActive()
            ? classes.textFieldWithButtonActive
            : classes.textFieldWithButtonInactive
        }`}
      >
        <Autocomplete
          id="autocomplete"
          freeSolo
          autoSelect
          selectOnFocus
          clearOnBlur
          filterOptions={x => x}
          disableClearable={!isSearchActive || !searchTerm}
          value={searchTerm}
          onChange={(event, value) => onAutocompleteMatch(event, value)}
          options={autoSuggestions}
          sx={{
            width: "100%",
            height: "100%"
          }}
          PaperComponent={params => (
            <Paper
              {...params}
              elevation={8}
              sx={{
                width: "calc(100% + 37px)",
                backgroundColor: darkMode ? grey[800] : "white",
                borderRadius: "0 0 8px 8px",
                ".MuiAutocomplete-listbox": {
                  paddingTop: "0px"
                },
                ".Mui-focused": {
                  backgroundColor: darkMode ? [grey[900], "30"].join("") + "!important" : grey[100] + "!important"
                },
                ".MuiAutocomplete-option[aria-selected='true']": {
                  backgroundColor: darkMode ? grey[800] : "white"
                }
              }}
            />
          )}
          renderInput={params => (
            <TextField
              {...params}
              onKeyDown={onKeyPressed}
              placeholder="Search"
              variant="outlined"
              name="search"
              id="search-input"
              size="small"
              className={`${classes.textField} ${isSearchBoxActive() ? classes.textFieldActive : ""}`}
              inputProps={{
                ...params.inputProps,
                margin: "dense",
                className: `${classes.input} ${isSearchBoxActive() ? classes.inputActive : ""}`
              }}
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: classes.textFieldRoot,
                  input: classes.textFieldInput
                }
              }}
              onFocus={() => onSearchActive(true)}
              onBlur={() =>
                setTimeout(() => {
                  onSearchActive(false);
                }, 100)
              }
              onChange={handleSearchTermChange()}
            />
          )}
        />
        <Button
          variant={isSearchReady ? "contained" : "outlined"}
          size="small"
          color="primary"
          className={`${classes.searchButton} ${
            !isSearchReady ? (isSearchBoxActive() ? classes.searchButtonActive : classes.searchButtonInactive) : ""
          }`}
          disabled={!isSearchReady}
          onClick={searchHandler()}
        >
          <SearchIcon fontSize="small" />
        </Button>
      </Box>
    </div>
  );
};

export default SearchBox;
