import React from "react";
import { MenuItem, ListItemIcon, Tooltip, Typography, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MenuOptionsType } from "./MenuOptions";

const useStyles = makeStyles<Theme>(theme => ({
  menuItem: {
    "&:hover": {
      "& $icon": {
        color: theme.palette.text.primary
      }
    }
  },
  icon: {
    transition: ".3s ease",
    marginBottom: "2px"
  }
}));

type ConditionalTooltipProps = {
  title?: string;
};

const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({ title, children }) =>
  title ? (
    <Tooltip title={title} arrow>
      <span style={{ display: "flex", height: "40px" }}>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );

type ItemMenuProps = {
  menuItem: MenuOptionsType;
};

const MoreMenuItem: React.FC<ItemMenuProps> = ({ menuItem }) => {
  const classes = useStyles();
  const MenuIcon = menuItem.icon;

  return (
    <ConditionalTooltip title={menuItem.disabled ? menuItem.disabledTooltipText : menuItem.tooltipText || ""}>
      <MenuItem disableGutters disabled={menuItem.disabled} className={classes.menuItem} onClick={menuItem.action}>
        {menuItem.upload ? (
          <label htmlFor="upload-file" style={{ display: "flex", cursor: "pointer" }}>
            <ListItemIcon>
              <MenuIcon fontSize="small" color="disabled" classes={{ root: classes.icon }} />
            </ListItemIcon>
            <Typography variant="body2" color="text.primary">
              {menuItem.text}
            </Typography>
          </label>
        ) : (
          <>
            <ListItemIcon>
              <MenuIcon fontSize="small" color="disabled" classes={{ root: classes.icon }} />
            </ListItemIcon>
            <Typography variant="body2" color="text.primary">
              {menuItem.text}
            </Typography>
          </>
        )}
      </MenuItem>
    </ConditionalTooltip>
  );
};

export default MoreMenuItem;
