import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Collapse, Drawer, List, ListItemIcon, ListItemText, Badge, Box, Link, Theme, ListItemButton } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import {
  VaultIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  BellIcon,
  StorageV2Icon,
  CreditCardIcon,
  FeedbackIcon,
  RewardsIcon,
  ArweaveIcon
} from "@akord/addon-icons";
import { getVaultId } from "../../../helpers/helpers";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { TopBarMain } from "..";
import LogoIcon from "../logo/logoOrange";
import LogoBase from "../logo/LogoBase";
import { useInAppNotificationsContext } from "../../../contexts/InAppNotificationsContext";
import { grey } from "../../../theme/colors";
import { useOrgContext } from "../../../contexts/OrgContextProvider";
import { Types } from "@akord/gql";
import { usePaymentContext } from "../../../contexts/PaymentContext";
import { akordLinks } from "../../../helpers/akordTexts";
import { useStorageContext } from "../../../contexts/StorageContextProvider";
import { Vault } from "@akord/akord-js";

const drawerWidth = 250;

type MenuDrawerMdStyleProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, MenuDrawerMdStyleProps>((theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      borderRadius: 0,
      width: drawerWidth,
      zIndex: 1000
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    itemIcon: {
      margin: "0 5px"
    },
    anchorOriginTopRightCircle: {
      top: "28%",
      right: "-3%",
      transform: "scale(1) translate(100%, -60%)",
      borderColor: ({ darkMode }) => (darkMode ? "transparent" : "inherit")
    },
    itemList: {
      alignItems: "center"
      // '&:hover': {
      //   '& $itemText': {
      //     // color: '#333333',
      //   },
      // },
    },
    itemText: {
      transition: ".3s ease",
      "&:hover": {
        color: ({ darkMode }) => (darkMode ? "#A3A09F" : "#333333")
      }
    },
    logo: {
      color: ({ darkMode }) => (darkMode ? "white" : theme.palette.background.secondary),
      display: "block"
    },
    iconHover: {
      "&:hover": {
        color: theme.palette.text.primary
      }
    },
    badgeDot: {
      marginTop: "1px"
    },
    logoIcon: {
      fill: "currentColor",
      color: ({ darkMode }) => (darkMode ? "white" : grey[800])
    }
  })
);

const MenuDrawerMd: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { roomsMenu, activeVaults, darkMode, isMobile, profileDetails, sortedActiveVaults, userAttributes } = useGlobalContext();
  const { notifications = [] } = useInAppNotificationsContext() || {};
  const { org } = useOrgContext();
  const { isOnFreePlan, subscriptionPlan, firstPaidPlan } = usePaymentContext();
  const { txRewards } = useStorageContext();
  const classes = useStyles({ darkMode: darkMode });

  const isSelected = (vault: Vault) => {
    return getVaultId(location.pathname) === vault.id;
  };
  const isPublicRoute = !!location.pathname.match("/public/"); // All anonymous rouse have `/public` prefix

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <TopBarMain darkMode={darkMode} isMobile={isMobile} profileDetails={profileDetails} isPublicRoute={isPublicRoute} />
      <List>
        <ListItemButton disabled={isPublicRoute}>
          <ListItemIcon>
            <VaultIcon
              fontSize="small"
              color={(location.pathname === "/" || location.pathname.match("/vaults")) && !isPublicRoute ? "action" : "disabled"}
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            onClick={() => {
              history.push("/vaults/active");
            }}
          >
            <Typography
              component="span"
              variant="body2"
              className="small"
              color={(location.pathname === "/" || location.pathname.match("/vaults")) && !isPublicRoute ? "text.primary" : "inherit"}
            >
              Vaults
            </Typography>
          </ListItemText>
          <ListItemIcon style={{ marginRight: 0 }}>
            {roomsMenu.roomsMenuOpen ? (
              <ChevronUpIcon classes={{ root: classes.iconHover }} color="disabled" onClick={roomsMenu.onDataRoomsExpand} />
            ) : (
              <ChevronDownIcon classes={{ root: classes.iconHover }} color="disabled" onClick={roomsMenu.onDataRoomsExpand} />
            )}
          </ListItemIcon>
        </ListItemButton>
        <Collapse in={roomsMenu.roomsMenuOpen} timeout="auto" unmountOnExit>
          <List component="div">
            {activeVaults &&
              sortedActiveVaults.map((vault, index) => (
                <ListItemButton
                  dense
                  disableTouchRipple
                  key={index}
                  selected={isSelected(vault)}
                  // Styles for selected item is different for white labeling
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: org ? "background.archived" : "none",
                      color: org ? (darkMode ? "none" : grey[900]) : "none",
                      "&:hover": {
                        backgroundColor: org ? "background.archived" : "none",
                        color: org ? (darkMode ? "none" : grey[900]) : "none"
                      }
                    }
                  }}
                >
                  <ListItemText disableTypography inset onClick={() => history.push(`/vaults/active/${vault.id}/assets`)}>
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      overlap="circular"
                      color="warning"
                      variant="dot"
                      classes={{ badge: classes.badgeDot }}
                      invisible={
                        !notifications ||
                        notifications?.filter(
                          (notification) => notification.status === Types.NotificationStatus.UNREAD && vault.id === notification.vaultId
                        ).length === 0
                      }
                    >
                      <Typography component="span" variant="body2" className="small" color="inherit">
                        {vault.name}
                      </Typography>
                    </Badge>
                  </ListItemText>
                </ListItemButton>
              ))}
          </List>
        </Collapse>
        <ListItemButton
          onClick={() => {
            history.push("/storage");
          }}
          disabled={isPublicRoute}
          sx={{ display: userAttributes.passwordless ? "none" : "flex" }}
        >
          <ListItemIcon>
            <StorageV2Icon fontSize="small" color={location.pathname.match("/storage") ? "action" : "disabled"} />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              component="span"
              className={["small", "step6"].join(" ")}
              variant="body2"
              color={location.pathname === "/storage" ? "text.primary" : "inherit"}
            >
              Storage
            </Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            history.push("/rewards");
          }}
          disabled={isPublicRoute}
          sx={{ display: userAttributes.passwordless ? "none" : "flex" }}
        >
          <ListItemIcon>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              color="warning"
              overlap="circular"
              variant="dot"
              invisible={!txRewards?.size}
            >
              <RewardsIcon fontSize="small" color={location.pathname.match("/rewards") ? "action" : "disabled"} />
            </Badge>
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              component="span"
              className={["small", "step7"].join(" ")}
              variant="body2"
              color={location.pathname === "/rewards" ? "text.primary" : "inherit"}
            >
              Rewards
            </Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            history.push("/notifications");
          }}
          disabled={isPublicRoute}
        >
          <ListItemIcon>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              color="warning"
              overlap="circular"
              variant="dot"
              invisible={
                !notifications ||
                notifications?.filter((notification) => notification.status === Types.NotificationStatus.UNREAD).length === 0
              }
            >
              <BellIcon fontSize="small" color={location.pathname === "/notifications" ? "action" : "disabled"} />
            </Badge>
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              component="span"
              className={["small", "step8"].join(" ")}
              variant="body2"
              color={location.pathname === "/notifications" ? "text.primary" : "inherit"}
            >
              Notifications
            </Typography>
          </ListItemText>
        </ListItemButton>
      </List>
      {isPublicRoute ? (
        <Box mt="auto">
          <Box
            width="fit-content"
            px={5}
            pb={2}
            flexDirection="row"
            marginTop="auto"
            onClick={() => window.open(akordLinks["arweaveExplained"])}
            sx={{ cursor: "pointer" }}
          >
            <ArweaveIcon fontSize="small" />
            <Link variant="caption" className="small" sx={{ borderBottom: "none", marginLeft: 2 }} color="text.secondary">
              What is Arweave?
            </Link>
          </Box>
          <Box
            width="fit-content"
            px={5}
            pb={5}
            flexDirection="row"
            marginTop="auto"
            onClick={() => window.open(akordLinks["akordDocs"])}
            sx={{ cursor: "pointer" }}
          >
            <LogoBase width="20" className={classes.logoIcon} />
            <Link variant="caption" className="small" sx={{ borderBottom: "none", marginLeft: 2 }} color="text.secondary">
              What is Akord?
            </Link>
          </Box>
        </Box>
      ) : (
        <Box mt="auto">
          {subscriptionPlan && isOnFreePlan() && !userAttributes.passwordless && (
            <Box
              width="fit-content"
              px={5}
              pb={1}
              flexDirection="row"
              marginTop="auto"
              onClick={() => history.push("/account/plans-and-payments/pricing-plans")}
              sx={{ cursor: "pointer" }}
            >
              <CreditCardIcon fontSize="small" sx={{ marginRight: 2 }} color="disabled" />
              <Link variant="caption" className="small" sx={{ borderBottom: "none" }} color="text.secondary">
                Get {firstPaidPlan?.topUpPrice?.discount}% off uploads
              </Link>
            </Box>
          )}

          <Box
            width="fit-content"
            px={5}
            pb={5}
            flexDirection="row"
            marginTop="auto"
            onClick={() => window.open(akordLinks["requestFeature"])}
            sx={{ cursor: "pointer" }}
          >
            <FeedbackIcon fontSize="small" sx={{ marginRight: 2 }} color="disabled" />
            <Link variant="caption" className="small" sx={{ borderBottom: "none" }} color="text.secondary">
              Request a feature
            </Link>
          </Box>
          {org && (
            <Box
              width="fit-content"
              px={5}
              pb={5}
              flexDirection="row"
              marginTop="auto"
              onClick={() => window.open(akordLinks["base"], "_self")}
              sx={{ cursor: "pointer" }}
            >
              <Typography variant="caption" className="small" sx={{ marginRight: 2 }}>
                Powered by
              </Typography>
              <LogoIcon width="54" className={classes.logoIcon} />
            </Box>
          )}
        </Box>
      )}
    </Drawer>
  );
};

export default withRouter(MenuDrawerMd);
