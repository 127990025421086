import React, { useEffect, useState } from "react";
import { useFolderMenuOptions } from "./MenuOptions";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import MoreMenuRoot from "./MoreMenuRoot";
import MoreMenuItem from "./MoreMenuItem";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { Folder, Stack } from "@akord/akord-js";

type MenuFolderProps = {
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  handleMenuClose: () => void;
  openMenu: boolean;
  folderId: string;
};

const MenuFolder: React.FC<MenuFolderProps> = ({ anchorEl, handleMenuClose, openMenu, folderId }) => {
  const [folder, setFolder] = useState<Folder>();

  const { akord } = useGlobalContext();
  const { currentStack } = useVaultContext();
  const { options: folderOptions } = useFolderMenuOptions();

  useEffect(() => {
    const loadData = async () => {
      setFolder(await akord?.folder.get(folderId));
    };
    if (folderId && akord) {
      loadData();
    } else {
      // setFolder(currentStack);
    }
  }, [folderId, currentStack, akord]);

  return (
    <MoreMenuRoot anchorEl={anchorEl} handleMenuClose={handleMenuClose} openMenu={openMenu} id="folder-menu">
      <div>
        {folderOptions(folder!, handleMenuClose).map((menuItem, index) => {
          return menuItem.show && <MoreMenuItem key={index} menuItem={menuItem} />;
        })}
      </div>
    </MoreMenuRoot>
  );
};

export default MenuFolder;
