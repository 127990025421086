import React, { useState, useRef, useEffect } from "react";
import { Link, Switch, Route, Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Fab, Tabs, Tab, Theme } from "@mui/material";
import {
  HideOnScroll,
  UploadButton,
  TopBarSecondary,
  FilterDrawer,
  LegacyBanner,
  BatchActionsBar,
  AddFileDrawer,
  UploadButtonSingle
} from "../../components/common";
import { PlusIcon } from "@akord/addon-icons";
import { getVaultStatus, getVaultId, getFolderId } from "../../helpers/helpers";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import TabDocuments from "./TabDocuments";
import { grey } from "../../theme/colors";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import TabChat from "./TabChat";
import UploadButtonFolder from "../../components/common/UploadButtonFolder";
import TabNfts from "./TabNfts";
import UploadButtonSingleZip from "../../components/common/UploadButtonSingleZip";

type ShowStackStyleProps = {
  darkMode: boolean;
  isVaultPublic: boolean;
};

const useStyles = makeStyles<Theme, ShowStackStyleProps>(theme => ({
  fab: {
    position: "fixed",
    right: "16px",
    bottom: "16px",
    "&.Mui-disabled": {
      backgroundColor: ({ darkMode }) => (darkMode ? `${grey[700]}!important` : `${grey[200]}!important`),
      color: ({ darkMode }) => (darkMode ? `${grey[500]}!important` : `${grey[400]}!important`)
    }
  },
  fabIcon: {
    fontSize: "30px"
  },
  indicator: {
    bottom: 1,
    backgroundColor: ({ isVaultPublic }) => (isVaultPublic ? theme.palette.info.main : "none")
  },
  tabsFlexContainer: {
    borderBottomColor: ({ isVaultPublic }) => (isVaultPublic ? theme.palette.info.main : "none")
  }
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const ShowStack: React.FC<RouteComponentProps> = ({ history }) => {
  const { currentMembership, vault, isVaultPublic, isCloudStorage } = useVaultContext();
  const {
    showFilter,
    onShowFilter,
    actionFilters,
    onActionFilters,
    periodFilter,
    onPeriodFilter,
    selectedItemsMap,
    showFolderUpload,
    showVersionUpload,
    onDrawerActionType,
    showZipUpload
  } = useAssetsContext();

  const { isMobile, darkMode, userAttributes, readOnlyMode } = useGlobalContext();

  const [bannerHeight, setBannerHeight] = useState<number>(0);
  const handleBannerHeight = (value: number) => setBannerHeight(value);

  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const openMobileMenu = Boolean(anchorEl);

  const docsRef = useRef<HTMLDivElement>(null);

  const isRoomArchived = vault?.status === "ARCHIVED";
  const dataRoomStatus = getVaultStatus(location.pathname);
  const vaultId = getVaultId(location.pathname);
  const folderId = getFolderId(location.pathname);

  const classes = useStyles({
    darkMode: darkMode,
    isVaultPublic: isVaultPublic
  });

  const handleMobileMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleMobileMenuClose = () => setAnchorEl(null);

  // Clear the filter on Tabs switch
  useEffect(() => {
    if (periodFilter || actionFilters.length) {
      onActionFilters([]);
      onPeriodFilter(null);
    }
    onShowFilter(false);
    // setPermaWarning(true)
  }, [location.pathname]);

  const isWarning = userAttributes?.legacyVaultsNotified?.includes(vaultId || "");

  const topHeight = location.pathname.includes("/assets") ? (isMobile && selectedItemsMap?.size > 0 ? 176 : 133) : 86;

  return (
    <>
      <Box position="fixed" width={isMobile ? "100%" : "calc(100% - 250px)"} height="86px" zIndex="10">
        {isWarning && (
          <LegacyBanner
            onBannerHeight={handleBannerHeight}
            // handlePermanentWarning={setPermaWarning}
            dataRoomId={vaultId}
          />
        )}
        <TopBarSecondary filterActive={!!(actionFilters.length || periodFilter)} />
        <Box zIndex="100">
          <Tabs
            color="text.secondary"
            value={location.pathname}
            aria-label="rooms tabs"
            classes={{
              indicator: classes.indicator,
              flexContainer: classes.tabsFlexContainer
            }}
          >
            <Tab
              component={Link}
              disableRipple
              label={<span className="step1">Files</span>}
              to={`/vaults/${dataRoomStatus}/${vaultId}/assets`}
              value={
                location.pathname.includes("/folders")
                  ? `/vaults/${dataRoomStatus}/${vaultId}/assets/folders/${folderId}`
                  : `/vaults/${dataRoomStatus}/${vaultId}/assets`
              }
              {...a11yProps(1)}
            />
            {isVaultPublic ? (
              isCloudStorage ? null : (
                <Tab
                  component={Link}
                  disableRipple
                  label={<span className="step2">Atomic NFTs</span>}
                  to={`/vaults/${dataRoomStatus}/${vaultId}/nfts`}
                  value={`/vaults/${dataRoomStatus}/${vaultId}/nfts`}
                  {...a11yProps(0)}
                />
              )
            ) : (
              <Tab
                component={Link}
                disableRipple
                label={<span className="step2">Chat</span>}
                to={`/vaults/${dataRoomStatus}/${vaultId}/chat`}
                value={`/vaults/${dataRoomStatus}/${vaultId}/chat`}
                {...a11yProps(0)}
              />
            )}
          </Tabs>
        </Box>
        {location.pathname.includes("/assets") && <BatchActionsBar folderId={folderId} />}
      </Box>
      {/* //ContentStart */}
      <Box
        flex="1"
        height={isWarning ? `calc(100% - ${bannerHeight + topHeight}px)` : `calc(100% - ${topHeight}px)`}
        position="absolute"
        top={isWarning ? `${bannerHeight + topHeight}px` : `${topHeight}px`}
        width={isMobile ? "100%" : "calc(100% - 250px)"}
      >
        <Switch>
          <Route
            exact
            path={`/vaults/${dataRoomStatus}/${vaultId}/chat`}
            render={() => (
              <TabChat
                periodFilter={periodFilter}
                actionFilters={actionFilters}
                // bannerHeight={isWarning ? bannerHeight : 0}
              />
            )}
          />
          <Route
            exact
            path={`/vaults/${dataRoomStatus}/${vaultId}/nfts`}
            render={() => <TabNfts periodFilter={periodFilter} actionFilters={actionFilters} />}
          />
          <Route exact path={`/vaults/${dataRoomStatus}/${vaultId}/assets`} render={() => <TabDocuments docsRef={docsRef} />} />
          <Route
            exact
            path={`/vaults/${dataRoomStatus}/${vaultId}/assets/folders/${folderId}`}
            render={() => <TabDocuments docsRef={docsRef} />}
          />
          {/* Redirect unknown paths */}
          <Redirect to={`/vaults/${dataRoomStatus}/${vaultId}/assets`} />
        </Switch>
        {showVersionUpload ? (
          <UploadButtonSingle onDrawerActionType={onDrawerActionType} />
        ) : showFolderUpload ? (
          <UploadButtonFolder isMobile={isMobile} fab={true} />
        ) : showZipUpload ? (
          <UploadButtonSingleZip />
        ) : (
          <UploadButton isMobile={isMobile} fab={true} />
        )}
        {isMobile && location.pathname.match("/assets") ? (
          <HideOnScroll window={docsRef.current} fab={true}>
            <Fab
              disabled={currentMembership?.role === "VIEWER" || isRoomArchived || readOnlyMode}
              size="medium"
              color="primary"
              className={classes.fab}
              onClick={handleMobileMenu}
            >
              <PlusIcon classes={{ root: classes.fabIcon }} />
            </Fab>
          </HideOnScroll>
        ) : null}
      </Box>
      {/* //ContentEnd */}
      <FilterDrawer
        topHeight={isWarning ? bannerHeight : 0}
        onShowFilter={onShowFilter}
        showFilter={showFilter}
        actionFilters={actionFilters}
        periodFilter={periodFilter}
        onPeriodFilter={onPeriodFilter}
        onActionFilters={onActionFilters}
      />

      {isMobile && <AddFileDrawer vault={vault} openDrawer={openMobileMenu} handleMenuClose={handleMobileMenuClose} />}
    </>
  );
};
export default withRouter(ShowStack);
