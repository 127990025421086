import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Button, Box } from "@mui/material";
import { ArrowRightIcon } from "@akord/addon-icons";
import ErrorAlert from "./ErrorAlert";
import CircularProgress from "@mui/material/CircularProgress";
import { Theme } from "@mui/system";
import { ButtonGroupProps } from "../../types/globalTypes";

type ButtonGroupStyleProps = {
  hideIcon?: boolean;
  noMarginTop: boolean;
  noMinWidth: boolean;
  showButtonInColumn: boolean;
};

const useStyles = makeStyles<Theme, ButtonGroupStyleProps>(theme => ({
  root: {
    display: "flex",
    flexDirection: ({ showButtonInColumn }) => (showButtonInColumn ? "column-reverse" : "row"),
    justifyContent: "space-between",
    marginTop: ({ noMarginTop }) => (noMarginTop ? 0 : theme.spacing(7))
  },
  disabledRed: {
    backgroundColor: "#F5A896!important"
  },
  rootButton: {
    display: ({ hideIcon }) => (hideIcon ? "inherit" : "inline"),
    marginBottom: ({ showButtonInColumn }) => (showButtonInColumn ? "16px" : "0"),
    minWidth: ({ noMinWidth }) => (noMinWidth ? "auto" : "228px")
  },
  endIcon: {
    float: "right",
    display: "inline",
    margin: "0"
  },
  buttonRoot: {
    background: "white",
    color: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: "white",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "white"
      }
    }
  },
  buttonProgress: {
    position: "absolute"
  }
}));

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  backButtonOutlined,
  backLoading,
  backText = "Back",
  disableBackButton,
  disabledRed,
  error,
  errorText,
  fullWidthNextButton,
  handleBack,
  handleEnd,
  handleNext,
  handleNextHelper,
  hideBackButton,
  hideIcon,
  inverted,
  loading,
  nextDiasabled,
  nextHelperText,
  nextText,
  noMarginTop = false,
  noMinWidth = false,
  showButtonInColumn = false,
  size = "medium",
  type,
  arialLabelNext
}) => {
  const classes = useStyles({
    noMinWidth: noMinWidth,
    hideIcon: hideIcon,
    noMarginTop: noMarginTop,
    showButtonInColumn: showButtonInColumn
  });

  return (
    <Box>
      <Container classes={{ root: classes.root }} disableGutters={true}>
        <Button
          aria-label="Previous"
          size={size}
          color="primary"
          variant={backButtonOutlined ? "outlined" : "text"}
          disabled={disableBackButton}
          disableElevation
          type="button"
          onClick={handleBack}
          sx={{
            display: hideBackButton ? "none" : "inherit"
          }}
        >
          {backText}
          {backLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
        <>
          {nextHelperText && (
            <Button
              aria-label="Next helper"
              size={size}
              variant="outlined"
              color="primary"
              disableElevation
              fullWidth={false}
              className={inverted ? classes.buttonRoot : undefined}
              classes={{
                root: classes.rootButton,
                endIcon: classes.endIcon,
                disabled: disabledRed ? classes.disabledRed : undefined
              }}
              onClick={() => handleNextHelper && handleNextHelper()}
            >
              {nextHelperText}
            </Button>
          )}
          <Button
            aria-label={arialLabelNext ? arialLabelNext : "Next"}
            size={size}
            variant="contained"
            color="primary"
            type={type ? type : "button"}
            disableElevation
            className={inverted ? classes.buttonRoot : ""}
            classes={{
              root: classes.rootButton,
              endIcon: classes.endIcon,
              disabled: disabledRed ? classes.disabledRed : undefined
            }}
            sx={error && { background: "#DB443C", borderColor: "#DB443C" }}
            disabled={nextDiasabled}
            fullWidth={fullWidthNextButton}
            onClick={handleEnd ? handleEnd : handleNext}
            endIcon={hideIcon ? null : <ArrowRightIcon />}
          >
            {nextText}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </>
      </Container>
      {errorText && <ErrorAlert getErrorText={errorText} />}
    </Box>
  );
};

export default ButtonGroup;
