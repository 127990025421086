import React from "react";

const UploadLabel = ({ children, handleOnChange }: { children: React.ReactNode; handleOnChange: (files: FileList | null) => void }) => {
  return (
    <label>
      <input type="file" accept="*" multiple style={{ display: "none" }} onChange={event => handleOnChange(event.target.files)} />
      {children}
    </label>
  );
};

export default UploadLabel;
