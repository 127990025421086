import React from "react";
import { Box, Divider } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SupportScreenWrapper } from "../../components/common";
import HoverableIconWithText from "../../components/common/HoverableIconWithText/HoverableIconWithText";

const PlansAndPayments: React.FC<RouteComponentProps> = ({ history }) => {
  const securityOptions = [
    {
      title: "Pricing plans",
      onClick: () => history.push("/account/plans-and-payments/pricing-plans")
    },
    {
      title: "Invoices",
      onClick: () => history.push("/account/plans-and-payments/invoices")
    },
    {
      title: "Billing and payment details",
      onClick: () => history.push("/account/plans-and-payments/billing-and-payment-details")
    }
  ];

  return (
    <SupportScreenWrapper title="Plans and Payments" route="/account">
      <Box mb={7}>
        {securityOptions.map(({ onClick, title }, index) => (
          <div key={index}>
            <HoverableIconWithText title={title} onClick={onClick} />
            <Divider />
          </div>
        ))}
      </Box>
    </SupportScreenWrapper>
  );
};

export default withRouter(PlansAndPayments);
