import React from "react";

type FormSubmitProps = {
  onSubmit: () => void;
};

const FormSubmit: React.FC<FormSubmitProps> = ({ children, onSubmit }) => {
  //to enable Enter submit
  return (
    <form
      action="/"
      method="POST"
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {children}
    </form>
  );
};

export default FormSubmit;
