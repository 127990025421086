import React from "react";
import { Paper, Box, Typography, Skeleton, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CloudIcon, InfinityIcon, InfoIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { grey } from "../../theme/colors";
import { bytesToGb } from "./storage-helper";
import Label from "../../components/common/Label";
import { useStorageContext } from "../../contexts/StorageContextProvider";
import { HorizontalBar, ListBar } from "../../storage-bar/HorizontalBar";

type StorageDataBlockProps = {
  storageType: "permanent" | "cloud";
  storageAvailable: number;
  storageUsed: number;
  storageBarData?: ListBar[];
};

type StorageDataBlockStyleProps = {
  darkMode: boolean;
};
const useStyles = makeStyles<Theme, StorageDataBlockStyleProps>(theme => ({
  paper: {
    // minWidth: "48%",
    width: "100%",
    backgroundColor: ({ darkMode }) => (darkMode ? grey[800] : grey[100]),
    boxShadow: "none",
    border: ({ darkMode }) => (darkMode ? "none" : `1px solid ${grey[200]}`),
    padding: "12px"
  }
}));

const ToolTipText = ({ isPermanentData, isCloudData }: { isPermanentData?: boolean; isCloudData?: boolean }) => {
  return (
    <Box m={2}>
      <Typography variant="body2" className="xs" style={{ marginBottom: 8 }} color="text.primary">
        {isPermanentData &&
          "Data is stored on the Arweave blockchain. Data is permanent and cannot be deleted. Once you use permanent storage, you can’t get it back even if you remove files from your vaults."}
        {isCloudData &&
          "Data is stored in the cloud. If you use private vaults it is more secure than Dropbox or Google drive because data is end-to-end encrypted and you control the keys. If you delete your account or cancel a subscription you will need to migrate your data off Akord or risk losing it."}
      </Typography>
    </Box>
  );
};

const StorageDataBlock: React.FC<StorageDataBlockProps> = ({ storageType, storageAvailable, storageUsed, storageBarData }) => {
  const { isMobile, darkMode } = useGlobalContext();
  const { loading } = useStorageContext();

  const classes = useStyles({ darkMode: darkMode });

  const isPermanentData = storageType === "permanent";
  const isCloudData = storageType === "cloud";

  return (
    <Paper className={classes.paper}>
      <Box display="flex" alignItems="center">
        {isPermanentData && (
          <>
            <InfinityIcon sx={{ marginRight: 2 }} />
            <Typography variant="h3">Permanent storage</Typography>
            <Tooltip placement="right-start" title={<ToolTipText isPermanentData />}>
              <span>
                <InfoIcon fontSize="small" color="secondary" sx={{ marginLeft: 1 }} />
              </span>
            </Tooltip>
          </>
        )}
        {isCloudData && (
          <>
            <CloudIcon sx={{ marginRight: 2 }} />
            <Typography variant="h3">Cloud storage</Typography>
            <Tooltip placement="right-start" title={<ToolTipText isCloudData />}>
              <span>
                <InfoIcon fontSize="small" color="secondary" sx={{ marginLeft: 1 }} />
              </span>
            </Tooltip>
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box mt={3} display="flex" flexDirection={isMobile ? "column" : "row"} flexWrap="wrap">
          <Box display="flex" alignItems="end" mr={5}>
            {!loading ? (
              <>
                <Typography
                  id={`storage-available-${storageType}`}
                  sx={{ lineHeight: "normal", fontSize: "1.575rem", fontWeight: 700 }}
                  color="text.primary"
                >
                  {bytesToGb(storageAvailable).size}
                </Typography>
                <Typography sx={{ fontSize: "1.175rem", marginLeft: 1 }} color="text.primary">
                  {bytesToGb(storageAvailable).sizeType}
                </Typography>
              </>
            ) : (
              <Skeleton variant="text" className="lightColor" height="31px" width="85px" />
            )}
            <Label text="Available" smallLabel />
          </Box>
          <Box display="flex" alignItems="end">
            {!loading ? (
              <>
                <Typography sx={{ lineHeight: "normal", fontSize: "1.575rem", fontWeight: 700 }} color="text.secondary">
                  {bytesToGb(storageUsed).size}{" "}
                </Typography>
                <Typography sx={{ fontSize: "1.175rem", marginLeft: 1 }} color="text.secondary">
                  {bytesToGb(storageUsed).sizeType}
                </Typography>
              </>
            ) : (
              <Skeleton className="lightColor" variant="text" height="31px" width="85px" />
            )}
            <Label text="Used" secondary smallLabel />
          </Box>
          {/* {subscriptionPlan?.metadata.blockchain_annual_topup_limit_gb && (
                <Box display="flex" alignItems="center">
                  <Typography variant="h1" className="xl" color="text.secondary">
                    {subscriptionPlan?.metadata.blockchain_annual_topup_limit_gb}
                  </Typography>
                  <Typography variant="h1" className="xl regular" color="text.secondary">
                    {" "}
                    GB
                  </Typography>
                  <Label text="top-up limit" secondary />
                </Box>
              )} */}
        </Box>
      </Box>

      {storageBarData && (
        <Box mt={3}>
          <HorizontalBar id="storageBar" height={48} data={storageBarData} />
        </Box>
      )}
    </Paper>
  );
};

export default StorageDataBlock;
