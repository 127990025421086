import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton } from "@mui/material";
import { getSubscriptionPlan } from "../../helpers/api-helpers";
import { useLocation, useHistory } from "react-router-dom";
import SubscriptionCardForm from "../../components/payment/SubscriptionCardForm";
import SubscriptionCheckoutSummary from "../../components/payment/SubscriptionCheckoutSummary";
import Logo from "../../components/common/logo/logoOrange";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { grey } from "../../theme/colors";
import { CloseIcon } from "@akord/addon-icons";
import { SupportScreenWrapper } from "../../components/common";
import { usePaymentContext } from "../../contexts/PaymentContext";

const useStyles = makeStyles({
  iconBig: {
    fontSize: "32px"
  },
  iconHover: {
    "&:hover": {
      color: "inherit"
    },
    padding: "0"
  },
  avatar: {
    width: "32px",
    height: "32px",
    borderRadius: "50%"
  },
  logo: {
    fill: "currentColor",
    color: ({ darkMode }) => (darkMode ? "white" : grey[800]),
    cursor: "pointer"
  },
  spinner: {
    position: "inherit"
  }
});

function SubscriptionSetup() {
  const history = useHistory();
  const location = useLocation();
  const { darkMode, isMobile } = useGlobalContext();
  const { currencyCode } = usePaymentContext();
  const classes = useStyles({ darkMode: darkMode });

  const [stripePromise, setStripePromise] = useState(null);
  const [product, setProduct] = useState();

  useEffect(() => {
    async function initializeSubscriptionSetup() {
      const urlParams = new URLSearchParams(location.search);
      const planName = urlParams.get("plan");
      const subscriptionPlansResonse = await getSubscriptionPlan(planName, currencyCode);

      const publishableKey = subscriptionPlansResonse.publishableKey;
      const product = subscriptionPlansResonse.subscriptionPlan;
      setProduct(product);

      const stripePromise = loadStripe(publishableKey);
      setStripePromise(stripePromise);
    }
    initializeSubscriptionSetup();
  }, [location.search]);

  if (isMobile) {
    return (
      <SupportScreenWrapper hideElements>
        <Box my={4}>
          <Box display="flex" justifyContent="space-between">
            <Box height="100%" left={0} flexBasis="25%">
              <Logo width="74" height="28" className={classes.logo}></Logo>
            </Box>
            <Box justifySelf="end">
              <IconButton onClick={() => history.push("/account/plans-and-payments/pricing-plans")}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box mt={6} flexBasis="100%" maxWidth="550px">
            <SubscriptionCheckoutSummary />
          </Box>
          <Box display="flex" justifyContent="center" flexBasis="100%" maxWidth="550px">
            <Elements stripe={stripePromise}>
              <SubscriptionCardForm product={product} />
            </Elements>
          </Box>
        </Box>
      </SupportScreenWrapper>
    );
  } else {
    return (
      <Box display="grid" sx={{ gridTemplateColumns: "50% 50%" }} height="100%">
        <Box px={6} py={6} display="grid" sx={{ gridTemplateRows: "1fr 11fr" }}>
          <Box>
            <Logo width="74" height="28" className={classes.logo} />
          </Box>
          <Box maxWidth="550px" width="100%" height="100%" justifySelf="end">
            <Elements stripe={stripePromise}>
              <SubscriptionCardForm product={product} />
            </Elements>
          </Box>
        </Box>
        <Box px={6} py={6} display="grid" sx={{ gridTemplateRows: "1fr 11fr" }} backgroundColor="background.card">
          <Box justifySelf="end">
            <IconButton onClick={() => history.push("/account/plans-and-payments/pricing-plans")}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box maxWidth="550px" width="100%">
            <SubscriptionCheckoutSummary />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default SubscriptionSetup;
