import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  InputAdornment,
  Theme
} from "@mui/material";
import { FiberManualRecordIcon } from "@akord/addon-icons";
import RevealToggler from "./RevealToggler";
import { SignUpFormDataProps, ZXCVBNResultExtended } from "../../types/signUpTypes";
import { RecoverAccountFormData } from "../../contexts/RecoverAccountContext";
import { RevealPasswordFormData } from "../../pages/account/SecurityChangePassword";

type PasswordMeterStyleProps = {
  padding: boolean;
};

const useStyles = makeStyles<Theme, PasswordMeterStyleProps>(theme => ({
  formCustom: {
    marginBottom: ({ padding }) => (padding ? theme.spacing(8) : 0)
  },
  // inputAdornment: {
  //   paddingRight: '5px'
  // },
  helperCustom: {
    margin: "8px 0 0",
    color: theme.palette.secondary.light,
    fontSize: "0.9375rem",
    lineHeight: 1.5
  },
  passwordMeter: {
    appearance: "none",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    width: "100%",
    height: "4px",
    marginTop: "8px",
    border: "none",
    backgroundColor: theme.palette.background.secondary,
    "&::-webkit-progress-bar": {
      backgroundColor: theme.palette.background.secondary
    },
    "&::-moz-progress-bar": {
      backgroundColor: theme.palette.background.secondary
    }
  },
  //dynamically changing the password border
  // meterOutline: {
  //   borderColor: ({ score }) =>
  //     (score.score === 0 || score.score === 1) && score.password.length
  //       ? `${theme.palette.error.main}!important`
  //       : score.score === 2 || score.score === 3
  //       ? `${theme.palette.primary.light}!important`
  //       : score.score === 4
  //       ? `${theme.palette.success.light}!important`
  //       : 'inherit'
  // },
  weak: {
    // backgroundColor: theme.palette.error.main,
    "&::-webkit-progress-value": {
      backgroundColor: theme.palette.error.main
    },
    "&::-moz-progress-bar": {
      backgroundColor: theme.palette.error.main
    }
  },
  fair: {
    // backgroundColor: theme.palette.primary.light,
    "&::-webkit-progress-value": {
      backgroundColor: theme.palette.primary.light
    },
    "&::-moz-progress-bar": {
      backgroundColor: theme.palette.primary.light
    }
  },
  strong: {
    // backgroundColor: theme.palette.success.main,
    "&::-webkit-progress-value": {
      backgroundColor: theme.palette.success.main
    },
    "&::-moz-progress-bar": {
      backgroundColor: theme.palette.success.main
    }
  },
  weakBorder: {
    borderColor: `${theme.palette.error.main}!important`
  },
  fairBorder: {
    borderColor: `${theme.palette.primary.light}!important`
  },
  strongBorder: {
    borderColor: `${theme.palette.success.main}!important`
  },
  icon: {
    fontSize: "8px"
  },
  listIcon: {
    minWidth: "14px",
    paddingTop: "6px"
  }
}));

interface PasswordMeterProps {
  values: {
    showPassword: boolean;
  };
  formData: SignUpFormDataProps | RecoverAccountFormData | RevealPasswordFormData;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: React.MouseEvent) => void;
  handleFormChange: (formType: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  passEvaluation: ZXCVBNResultExtended;
  newPasswordText?: boolean;
  padding?: boolean;
}

const PasswordMeter: React.FC<PasswordMeterProps> = ({
  values,
  formData,
  handleClickShowPassword,
  handleMouseDownPassword,
  handleFormChange,
  passEvaluation,
  newPasswordText,
  padding = true
}): JSX.Element => {
  const classes = useStyles({
    padding: padding
    // score: passEvaluation
  });

  const getStyle = (passEvaluation: ZXCVBNResultExtended, border: boolean) => {
    if (formData.userPassword.length === 0) return `${classes.passwordMeter}`;
    let score = passEvaluation?.score;
    if (passEvaluation?.password && passEvaluation?.password?.length < 12 && score === 4) score--;
    switch (score) {
      case 0:
      case 1:
        return border ? `${classes.weakBorder}` : `${classes.passwordMeter} ${classes.weak}`;
      case 2:
      case 3:
        return border ? `${classes.fairBorder}` : `${classes.passwordMeter} ${classes.fair}`;
      case 4:
      case 5:
        return border ? `${classes.strongBorder}` : `${classes.passwordMeter} ${classes.strong}`;
      default:
        return border ? `${classes.weakBorder}` : `${classes.passwordMeter} ${classes.weak}`;
    }
  };

  const createPasswordLabel = (passEvaluation: ZXCVBNResultExtended) => {
    let score = passEvaluation?.score;
    if (passEvaluation?.password && passEvaluation?.password?.length < 12 && score === 4) score--;
    switch (score) {
      case 0:
      case 1:
        return {
          text: "Weak password. Try the following before we can accept your password.",
          color: "#DB443C",
          value: 1
        };
      case 2:
        return {
          text: "Getting stronger, but still too easy to crack. Try the following before we can accept your password.",
          color: "#EA7F30",
          value: 2
        };
      case 3:
        return {
          text: "Nearly there! Level up and ensure your password won’t get cracked.",
          color: "#4CAF57",
          value: 4
        };
      case 4:
        return {
          text: "Strong password, and we’ll accept it, but we recommend levelling up for maximum security.",
          color: "#4CAF57",
          value: 5
        };
      default:
        return { text: "Weak", color: "#DB443C", value: 1 };
    }
  };

  const createPasswordExplanationTexts = (passEvaluation: ZXCVBNResultExtended) => {
    let score = passEvaluation?.score;
    if (passEvaluation?.password && passEvaluation?.password?.length < 12 && score === 4) score--;
    switch (score) {
      case 0:
      case 1:
      case 2:
        return [
          "Using a few uncommon words or phrases will create a strong password.",
          "No need for symbols, digits, or uppercase letters."
        ];
      case 3:
        return ["Try adding one more uncommon word.", "Make sure your password has at least 12 characters."];
    }
  };

  const isPaswordLengthValid = passEvaluation?.password && passEvaluation?.password?.length >= 12;

  return (
    <>
      <FormControl variant="outlined" className={classes.formCustom}>
        <InputLabel htmlFor="enter-password">{newPasswordText ? "New Password" : "Password"}</InputLabel>
        <OutlinedInput
          spellCheck="false"
          id="enter-password"
          name="userPassword"
          label={newPasswordText ? "New Password" : "Password"}
          aria-describedby="enter-password"
          type={values.showPassword ? "text" : "password"}
          value={formData.userPassword}
          onChange={handleFormChange("userPassword")}
          classes={{
            notchedOutline: formData.userPassword && `${getStyle(passEvaluation, true)}`,
            adornedEnd: classes.inputAdornment
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                size="large"
              >
                <RevealToggler showPassword={values.showPassword} />
              </IconButton>
            </InputAdornment>
          }
        />
        <progress
          className={`${getStyle(passEvaluation, false)}`}
          max="5"
          value={passEvaluation?.password ? createPasswordLabel(passEvaluation).value : undefined}
        />
        {Object.prototype.hasOwnProperty.call(passEvaluation, "score") && passEvaluation.password && (
          <FormHelperText component={"div"} className={classes.helperCustom}>
            {passEvaluation.error ? (
              <span>No spaces before or after.</span>
            ) : passEvaluation.score === 4 && isPaswordLengthValid ? (
              <>
                <span>Top score! good job. Now keep it safe.</span>
                <List>
                  <ListItem alignItems="flex-start" style={{ padding: "0 20px 0 0" }}>
                    <ListItemIcon className={classes.listIcon}>
                      <FiberManualRecordIcon classes={{ root: classes.icon }} />
                    </ListItemIcon>
                    We recommend using a password manager for storing all your passwords.
                  </ListItem>
                </List>
              </>
            ) : (
              <>
                <span>{createPasswordLabel(passEvaluation).text}</span>
                <List>
                  {createPasswordExplanationTexts(passEvaluation)?.map((text, index) => (
                    <ListItem key={index} alignItems="flex-start" style={{ padding: "0 20px 0 0" }}>
                      <ListItemIcon className={classes.listIcon}>
                        <FiberManualRecordIcon classes={{ root: classes.icon }} />
                      </ListItemIcon>
                      {text}
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default PasswordMeter;
