import React from "react";
import { Box, IconButton, Typography } from "@mui/material";

type DataBoxProps = {
  dataKey: string;
  dataValue: string;
  dataIcon?: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
};

const DataBox: React.FC<DataBoxProps> = ({ dataKey, dataValue, dataIcon: MenuIcon }) => {
  return (
    <Box pb={4} display="flex" justifyContent="space-between">
      {MenuIcon ? (
        <IconButton
          onClick={() => window.open(dataValue)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <Typography variant="body2">{dataKey}</Typography>
          <MenuIcon fontSize="small" />
        </IconButton>
      ) : (
        <Typography variant="body2">{dataKey}</Typography>
      )}
      {!MenuIcon && (
        <Typography variant="body2" color="text.secondary" className="small">
          {dataValue}
        </Typography>
      )}
    </Box>
  );
};

export default DataBox;
