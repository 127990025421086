import axios from "axios";
import mime from "mime";
import { useEffect, useCallback, useMemo } from "react";
import loadScript from "load-script";
import { useProgressContext } from "../../contexts/ProgressContext";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { DROPBOX_APP_ID } from "../../helpers/env";
import { useVaultContext } from "../../contexts/VaultContextProvider";

const DROPBOX_SDK_URL = "https://www.dropbox.com/static/api/2/dropins.js";
const DROPBOX_SCRIPT_ID = "dropboxjs";

export const useDropboxImporter = ({ onSuccess } = {}) => {
  const { vault } = useVaultContext();
  const { onDrawerActionType, onShowLoaderByVaultId, showLoaderByVaultId, onUploadCancelHook, onFilesNumber, onAxiosError } =
    useAssetsContext();
  const { onUploadFiles } = useNotificationsContext();
  const { onProgress } = useProgressContext();

  const options = useMemo(
    () => ({
      success: files => {
        importFiles(files);
        //onSuccess && onSuccess(files);
      },
      cancel: () => {
        console.log("cancel");
        onCancel();
      },
      linkType: "direct",
      multiselect: true,
      // extensions: ['.png', '.jpg', '.jpeg'],
      folderselect: false
    }),
    [onSuccess]
  );

  useEffect(() => {
    loadScript(DROPBOX_SDK_URL, {
      attrs: {
        id: DROPBOX_SCRIPT_ID,
        "data-app-key": DROPBOX_APP_ID
      }
    });
  }, []);

  const importFiles = async docs => {
    const config = {
      method: "get",
      responseType: "blob"
    };
    const totalSize = docs.reduce((acc, doc) => acc + doc.bytes, 0);
    const cancel = new AbortController();

    onUploadCancelHook(cancel);
    onDrawerActionType("import");
    onFilesNumber(docs.length);

    try {
      const files = [];
      for (const doc of docs) {
        onShowLoaderByVaultId(vault.id);
        const blob = (
          await axios({
            ...config,
            url: doc.link,
            signal: cancel.signal,
            onDownloadProgress(progressEvent) {
              onProgress(Math.round((progressEvent.loaded / totalSize) * 100));
            }
          })
        ).data;
        const mimeType = mime.getType((doc.name.split(".").pop() || "").toLowerCase());
        files.push(new File([blob], doc.name, { type: mimeType }));
      }
      onDrawerActionType("upload");
      onUploadFiles(files);
    } catch (e) {
      if (cancel.signal.aborted) {
        onCancel();
      } else {
        console.error(e);
        onError();
      }
    }

    onUploadCancelHook(null);
    if (showLoaderByVaultId.includes(vault.id)) onShowLoaderByVaultId(vault.id);
    onProgress(0);
    onDrawerActionType();
  };

  const onError = () => {
    onAxiosError(`Failed to import files`);
    setTimeout(() => {
      onAxiosError();
    }, 3000);
  };

  const onCancel = () => {
    onAxiosError(`Importing cancelled`);
    setTimeout(() => {
      onAxiosError();
    }, 3000);
  };

  const importFromDropbox = useCallback(() => {
    if (window.Dropbox) {
      window.Dropbox.choose(options);
    }
  }, [options]);

  return { importFromDropbox };
};
