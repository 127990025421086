import React from "react";

import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";

const UploadButtonSingleZip: React.FC = () => {
  const { onUploadFiles, uploadEventRef } = useNotificationsContext();
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const fileList = Array.from(e.target.files);
    onUploadFiles(fileList);
    uploadEventRef.current = e;
  };

  return (
    <input
      name="upload-file-zip"
      id="upload-file-zip"
      type="file"
      accept=".zip"
      onInput={handleUpload}
      onClick={event => {
        (event.target as HTMLInputElement).value = ""; // TODO What does it mean?
      }}
      style={{ display: "none" }}
    />
  );
};

export default UploadButtonSingleZip;
