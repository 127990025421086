import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
  Typography
} from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FileVersion } from "@akord/akord-js";
import { EncryptedKeys } from "@akord/crypto";
import { Types } from "@akord/gql";
import { ArrowUpIcon, OpenIcon, ReceiptIcon, UTokenIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { StorageTransaction, useStorageContext } from "../../contexts/StorageContextProvider";
import { formatDate, getComparator, stableSort } from "../../helpers/helpers";
import { grey } from "../../theme/colors/index";
import { akordLinks } from "../../helpers/akordTexts";
import { bytesToGb, createDataForTable, formatTransactionReference, getArweaveStatusIcon, getTypeIcon, rowNames } from "./storage-helper";
import EmptySpace from "../../components/common/EmptySpace/EmptySpace";
import CopyToClipboard from "../../components/common/CopyToClipboard/CopyToClipboard";
import { FilterDrawer } from "../../components/common";
import { Order, OrderByTransaction } from "../../types/globalDataTypes";

type TransactionsTableStylePRops = {
  darkMode: boolean;
};

export type TransactionTableData = {
  txId?: string | null;
  dataItemId?: string | null;
  type?: string | null;
  size?: number | null;
  createdAt?: string | null;
  status?: Types.StorageTransactionStatus | null;
  nodeId?: string | null;
  name?: string | null;
  txFee?: number | null;
};

type DecoratedStorageTransaction = StorageTransaction & { name?: string | null };

const useStyles = makeStyles<Theme, TransactionsTableStylePRops>(theme => ({
  paper: {
    width: "200px",
    minWidth: "100%",
    backgroundColor: ({ darkMode }) => (darkMode ? grey[800] : grey[100]),
    boxShadow: "none",
    border: ({ darkMode }) => (darkMode ? "none" : `1px solid ${grey[200]}`),
    marginRight: 4
  },
  cell: {
    paddingRight: 0,
    paddingLeft: "12px",
    whiteSpace: "nowrap",
    borderBottomColor: ({ darkMode }) => (darkMode ? grey[700] : grey[200])
  },
  cellHead: {
    padding: "0 0 0 12px",
    "&:last-of-type": {
      padding: "0 16px 0 12px"
    },
    "&:first-of-type": {
      paddingLeft: "16px"
    }
  },
  cellBody: {
    padding: "8px 0 4px 16px"
  },
  rowHover: {
    "&.MuiTableRow-hover": {
      "&:hover": {
        backgroundColor: ({ darkMode }) => (darkMode ? "#403F4630!important" : "#E8E6EF30!important")
      }
    }
    // "&:last-child td": {
    //   borderBottom: 0
    // }
  }
}));

function TransactionIconButton({ transaction }: { transaction: TransactionTableData }) {
  const [loading, setLoading] = React.useState(false);

  const handleReceiptDownload = async () => {
    setLoading(true);
    setLoading(false);
  };

  if (loading) {
    return <CircularProgress size={14} sx={{ position: "absoulute" }} />;
  }
  return (
    <>
      {transaction.type === "TOPUP" ? (
        <IconButton onClick={() => handleReceiptDownload()} size="large">
          <ReceiptIcon fontSize="small" />
        </IconButton>
      ) : (
        <Tooltip arrow title="View on Arweave blockchain explorer">
          <IconButton
            aria-label="show transaction"
            onClick={() => window.open(`${akordLinks.transactionBaseUrl}/${transaction.dataItemId}`)}
            size="large"
          >
            <OpenIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default function TransactionsTable({ boxRef }: { boxRef?: HTMLDivElement }) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<OrderByTransaction>("createdAt");
  const [assetTransactions, setAssetTransactions] = React.useState<DecoratedStorageTransaction[]>([]);
  const [assetTransactionsLoaded, setAssetTransactionsLoaded] = React.useState(false);

  const { akord, darkMode } = useGlobalContext();
  const {
    transactions,
    onShowTransactionsFilter,
    showTransactionsFilter,
    actionStorageFilters,
    periodStorageFilter,
    onActionStorageFilters,
    onPeriodStorageFilter
  } = useStorageContext();

  const handleRequestSort = (event: any, property: OrderByTransaction) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: OrderByTransaction) => (event: React.MouseEvent<HTMLSpanElement>) => {
    handleRequestSort(event, property);
  };

  const classes = useStyles({ darkMode: darkMode });

  const prepareDataForTable: TransactionTableData[] = assetTransactions
    ?.filter((transaction: DecoratedStorageTransaction) => transaction)
    ?.map((transaction: DecoratedStorageTransaction) => createDataForTable(transaction));

  React.useEffect(() => {
    const decorateWithAssetName = async () => {
      const decoratedAssetTransactions: DecoratedStorageTransaction[] = await Promise.all(
        transactions!
          .map(async transaction => {
            if (transaction && transaction.__keys__ && transaction.fileVersion) {
              try {
                const file = new FileVersion({ name: transaction.fileVersion.name }, transaction.__keys__ as EncryptedKeys[]);
                await file.decrypt();
                return { ...transaction, name: file.name };
              } catch (e) {
                console.warn(e);
                return { ...transaction, name: transaction.nodeId };
              }
            }
            return { ...transaction, name: transaction?.fileVersion?.name || transaction?.nodeId };
          })
          ?.filter(transaction => transaction)
      );

      setAssetTransactionsLoaded(true);
      setAssetTransactions(decoratedAssetTransactions);
    };

    if (akord && transactions) decorateWithAssetName();
  }, [akord, transactions]);

  const rect = boxRef?.getBoundingClientRect();

  return (
    <TableContainer>
      {assetTransactionsLoaded ? (
        assetTransactions.length > 0 ? (
          <Table aria-label="caption table">
            <TableHead>
              <TableRow>
                {rowNames.map((row, index) => (
                  <TableCell key={index} className={[classes.cell, classes.cellHead].join(" ")}>
                    <TableSortLabel
                      active={orderBy === row.key}
                      direction={orderBy === row.key ? order : "asc"}
                      onClick={row.key ? createSortHandler(row.key as OrderByTransaction) : undefined}
                      hideSortIcon={!row.key}
                      // @ts-ignore
                      IconComponent={ArrowUpIcon}
                      sx={{ cursor: row.key ? "pointer" : "inherit" }}
                    >
                      <Typography variant="caption" className="small" color="text.secondary">
                        {row.label}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(stableSort(prepareDataForTable, getComparator(order, orderBy)) as TransactionTableData[])?.map(
                (transaction: TransactionTableData, index: number) => (
                  <TableRow key={transaction.dataItemId + "_" + index} classes={{ root: classes.rowHover }} hover>
                    <TableCell className={[classes.cell, classes.cellBody].join(" ")} sx={{ minWidth: 100, maxWidth: 200 }}>
                      <Box display="flex">
                        {getTypeIcon(transaction.type || "")}
                        <Typography variant="body2" className="small" noWrap color="text.primary">
                          {transaction.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" className="small" noWrap color="text.primary">
                          {transaction.type === "TOPUP"
                            ? `#${formatTransactionReference(transaction)}`
                            : formatTransactionReference(transaction)}
                        </Typography>
                        <CopyToClipboard
                          dataToCopy={transaction.dataItemId || transaction.txId || ""}
                          tooltipText="Copy file ID to clipboard"
                        />
                      </Box>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography variant="body2" className="small" color="text.primary">
                        {formatDate(transaction?.createdAt as string, false, false, false, true)}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography variant="body2" className="small" color="text.primary">
                        {transaction.size && bytesToGb(transaction.size).size}{" "}
                        {transaction.size && bytesToGb(transaction.size).sizeType.toUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Box display="flex" alignItems="center">
                        <Tooltip
                          arrow
                          title={
                            transaction.txFee
                              ? "U " + transaction.txFee / Math.pow(10, 6)
                              : "Your reward will appear when the file is committed"
                          }
                        >
                          <span style={{ display: "flex", alignItems: "center", cursor: "help" }}>
                            <UTokenIcon
                              sx={{
                                marginRight: 2,
                                fontSize: "16px",
                                opacity: transaction.status === Types.StorageTransactionStatus.COMMITTED ? 1 : 0.5
                              }}
                            />
                            <Typography
                              variant="body2"
                              className="small"
                              color={transaction.status === Types.StorageTransactionStatus.COMMITTED ? "text.primary" : "text.secondary"}
                            >
                              {transaction.txFee
                                ? transaction.txFee < 100
                                  ? "< 0.0001"
                                  : (Math.floor((transaction.txFee / Math.pow(10, 6)) * 10000) / 10000).toFixed(4)
                                : "-"}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>

                    <TableCell className={classes.cell} sx={{ width: 50 }}>
                      {getArweaveStatusIcon(transaction.status)}
                    </TableCell>
                    <TableCell className={classes.cell} sx={{ width: 50 }}>
                      <TransactionIconButton transaction={transaction} />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        ) : (
          <Box mx={4} display="flex">
            <Paper className={classes.paper}>
              <EmptySpace dataText="emptyTransactions" />
            </Paper>
          </Box>
        )
      ) : (
        Array.from(new Array(5)).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height={36}
            className="lightColor"
            style={{ borderRadius: 4, margin: "0 18px 18px 18px" }}
          />
        ))
      )}
      <FilterDrawer
        topHeight={rect ? rect.top - 60 : 0}
        onShowFilter={onShowTransactionsFilter}
        showFilter={showTransactionsFilter}
        lightPaperColor
        actionFilters={actionStorageFilters}
        periodFilter={periodStorageFilter}
        onPeriodFilter={onPeriodStorageFilter}
        onActionFilters={onActionStorageFilters}
      />
    </TableContainer>
  );
}
