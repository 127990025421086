import React from "react";
import { Autocomplete, AutocompleteChangeReason, Box, Paper, TextField, Typography } from "@mui/material";
import { CloseInCircleIcon, TriangleDownIcon } from "@akord/addon-icons";
import { Types } from "@akord/gql";
import { InviteFormProps } from "../../../pages/vault-options/InviteToRoom";

type OrgMemberAutoCompleteSelectProps = {
  onOptionSelect?: (
    elIndex: any
  ) => (event: React.SyntheticEvent<Element, Event>, value: Types.User, reason: AutocompleteChangeReason) => void;
  disableClearable?: boolean;
  options: Types.User[];
  invitees: InviteFormProps[];
};

const OrgMemberAutoCompleteSelect: React.FC<OrgMemberAutoCompleteSelectProps> = ({
  onOptionSelect,
  disableClearable = false,
  options,
  invitees
}) => {
  return (
    <Autocomplete
      options={options}
      disableClearable={disableClearable}
      getOptionLabel={(option) => option.email || ""}
      onChange={onOptionSelect}
      popupIcon={<TriangleDownIcon />}
      clearIcon={<CloseInCircleIcon color="disabled" />}
      getOptionDisabled={(option) => invitees?.some((invite) => invite.email === option.email)}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography variant="body2">{option.email}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Organisation member"
          variant="outlined"
          // InputLabelProps={{
          //   classes: { root: classes.label }
          // }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password" // disable autocomplete and autofill
          }}
          sx={{ marginBottom: 0 }}
        />
      )}
      // dropdown background color
      PaperComponent={({ children }) => <Paper sx={{ backgroundColor: "background.secondary", marginTop: 2 }}>{children}</Paper>}
    />
  );
};

export default OrgMemberAutoCompleteSelect;
