import React from "react";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const SearchResultsFilter: React.FC = () => {
  const { isMobile } = useGlobalContext();

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell width={isMobile ? "100%" : "50%"} align="left">
            <Typography variant="caption" className="small" color="text.secondary">
              Name
            </Typography>
          </TableCell>
          <TableCell width={isMobile ? "100%" : "50%"} align="left"></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export default SearchResultsFilter;
