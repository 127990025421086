export const filtersListMap: { [key: string]: string } = {
  VAULT_ARCHIVE: "Archived vault",
  VAULT_CREATE: "Created vault",
  VAULT_RENAME: "Renamed vault",
  VAULT_RESTORE: "Restored vault",
  FOLDER_CREATE: "Created folders",
  FOLDER_DELETE: "Deleted folders",
  FOLDER_MOVE: "Moved folders",
  FOLDER_RENAME: "Renamed folders",
  FOLDER_RESTORE: "Restored folders",
  FOLDER_REVOKE: "Hidden folders",
  MEMBERSHIP_ACCEPT: "Accepted invites",
  MEMBERSHIP_CHANGE_ACCESS: "Permissions changed",
  MEMBERSHIP_CONFIRM: "Access confirmed",
  MEMBERSHIP_INVITE_NEW_USER_RESEND: "Resent invites",
  MEMBERSHIP_INVITE_NEW_USER: "Invited to vault",
  MEMBERSHIP_INVITE_RESEND: "Resent invites",
  MEMBERSHIP_INVITE: "Invited to vault",
  MEMBERSHIP_LEAVE: "Left vault",
  MEMBERSHIP_REJECT: "Rejected invites",
  MEMBERSHIP_RESTORE_ACCESS: "Access restored",
  MEMBERSHIP_REVOKE: "Hidden invites",
  MEMO_CREATE: "Messages sent",
  ROOM_INVITE: "Invited to vault",
  ROOM_SHARE: "Share",
  STACK_CREATE: "Uploaded files",
  STACK_DELETE: "Deleted files",
  STACK_DOWNLOADED: "Download",
  STACK_MOVE: "Moved files",
  STACK_REMOVE: "Removed files",
  STACK_RENAME: "Renamed files",
  STACK_UPDATE: "Renamed files",
  STACK_REQUEST: "Request",
  STACK_RESTORE: "Restored files",
  STACK_REVOKE: "Hidden files",
  STACK_UPLOAD_REVISION: "Revison",
  NOTE_CREATE: "Created notes",
  NOTE_UPLOAD_REVISION: "Notes revision",
  NOTE_REVOKE: "Hidden notes",
  NOTE_MOVE: "Moved notes",
  NOTE_REMOVE: "Removed notes",
  NOTE_RESTORE: "Restored notes",
  "application/pdf": "PDF",
  "image/jpeg": "JPEG",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DOCX",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
  "text/plain": "TEXT",
  "text/markdown": "MD/NOTE",
  folder: "Folders",
  note: "Notes",
  "": "Other",
  //Transactions
  BUNDLED: "Your file is being bundled",
  VERIFICATION: "Your file is with Akord",
  SCHEDULED: "Your file is with Akord",
  PENDING: "Your file is with Arweave",
  COMMITTED: "Successfully on Arweave",
  REGISTERED: "Your NFT was successfully minted",
  REJECTED: "Rejected by Arweave"
};

export const periodListMap: { [key: string]: string } = {
  TWENTY_FOUR_HOURS: "Last 24 hours",
  FORTY_EIGHT_HOURS: "Last 48 hours",
  SEVEN_DAYS: "Last 7 days",
  THIRTY_DAYS: "Last 30 days"
};

export const storageColorMap: { [key: string]: string } = {
  BUNDLED: "warning.main",
  VERIFICATION: "warning.main",
  SCHEDULED: "warning.main",
  PENDING: "#FFEB53",
  COMMITTED: "success.main",
  REJECTED: "error"
};
