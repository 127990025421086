import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { FormControl, RadioGroup, Radio, Box, Typography, FormControlLabel, Link } from "@mui/material";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useNftContext } from "../../contexts/NftContextProvider";
import { getVaultId, getMintType } from "../../helpers/helpers";

const UCMForm: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { isMobile } = useGlobalContext();
  const { ucm, onUCM } = useNftContext();

  const [formData, setFormData] = useState({
    ucm: ucm
  });

  const isCollection = location.pathname.includes("/collection/");
  const vaultId = getVaultId(location.pathname);
  const mintType = getMintType(location.pathname);

  const handleFormChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setFormData({
      ...formData,
      [prop]: value
    });
  };

  const redirect = () => {
    onUCM(formData.ucm);
    history.push(`/vaults/active/${vaultId}/nfts/mint/${mintType}/file`);
  };

  return (
    <SupportScreenWrapper title="Universal Content Marketplace" hideChevron fullHeight>
      <Box height="auto" display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"} mt={6}>
        <Box>
          <Box mb={7}>
            <Box mb={4}>
              <Typography variant="h3" color="text.primary">
                {isCollection ? "List your collection" : "List your NFT"}
              </Typography>
              <Typography variant="body2" className="small">
                The Universal Content Marketplace (UCM) serves as Arweave&apos;s on-chain order book contract, facilitating trustless
                trading of Atomic NFTs. Being listed on a UCM, such as{" "}
                <Link href={"https://bazar.arweave.dev/"} color="inherit" target="_blank" rel="noreferrer" underline="always">
                  BazAr
                </Link>
                , will help your NFT be discovered and traded.
              </Typography>
            </Box>
            <FormControl>
              <RadioGroup aria-labelledby="ucm" name="ucm" value={formData.ucm} onChange={handleFormChange("ucm")}>
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={isCollection ? "Yes, list my collection on the UCM" : "Yes, list my Atomic NFT on the UCM"}
                />
                <FormControlLabel value="no" control={<Radio />} label="No, don't list it" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <ButtonGroup nextDiasabled={false} handleEnd={() => redirect()} handleBack={() => history.goBack()} nextText="Next" />
      </Box>
    </SupportScreenWrapper>
  );
};

export default withRouter(UCMForm);
