import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, IconButton, CardContent, Typography, ClickAwayListener } from "@mui/material";
import { ImageIcon, DocumentIcon, MenuDotsHorizontalIcon, MinusInCircleIcon, RevokedFileIcon } from "@akord/addon-icons";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useHistory } from "react-router-dom";
import { getResourceUri, getVaultId, getVaultStatus } from "../../helpers/helpers";
import { useVaultContext } from "../../contexts/VaultContextProvider";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: ({ isMobile }) => (isMobile ? "100%" : "288px"),
    background: theme.palette.background.card,
    borderColor: `${theme.palette.background.cardBorder}!important`,
    border: "1px solid",
    boxShadow: "none",
    "&:hover": {
      "& $iconButtonThumb": {
        visibility: "visible",
        opacity: "1"
      }
    }
  },
  content: {
    display: "flex",
    alignItems: "center",
    padding: "4px",
    height: "38px",
    "&:last-child": {
      paddingBottom: "4px"
    },
    cursor: "pointer"
  },
  iconButtonThumb: {
    transition: "visibility 0.3s, opacity 0.1s linear",
    opacity: ({ buttonVisible }) => (buttonVisible ? 1 : 0),
    visibility: ({ buttonVisible }) => (buttonVisible ? "visible" : "hidden"),
    color: ({ buttonVisible }) => buttonVisible && theme.palette.primary.main,
    marginLeft: "auto",
    backgroundColor: ({ buttonVisible }) => !buttonVisible && "#7C798620",
    padding: "3px",
    "&:hover": {
      backgroundColor: "#7C798620",
      color: theme.palette.text.primary
    }
  },
  revokeButtonThumb: {
    marginLeft: "auto",
    margin: "3px 0 3px auto"
  },
  icon: {
    marginRight: "6px"
  }
}));

function NoThumbnail({ positionedBeforeDate, operation, handleMenu, stackItem, lastItem, stackStatus, stackHash }) {
  const history = useHistory();
  const roomStatus = getVaultStatus(history.location.pathname);
  const roomId = getVaultId(history.location.pathname);
  const { modal, onNotificationData } = useNotificationsContext();
  const { isMobile, darkMode } = useGlobalContext();
  const { currentMembership } = useVaultContext();

  const [buttonVisible, setButtonVisible] = React.useState(false);
  const handleButtonVisible = value => setButtonVisible(value);

  const classes = useStyles({
    darkMode: darkMode,
    isMobile: isMobile,
    buttonVisible: buttonVisible
  });

  //select icon for a file
  const fileType = operation ? operation.fileType : "";
  const FileIcon = fileType && fileType.match("image") ? ImageIcon : DocumentIcon;

  const isLegacyVault = currentMembership?.dataRoom?.state?.permanentStorage === null;

  const getStackItemPath = item => {
    const stack = item.stack;
    const folderId = stack.parentId;
    if (!stack.versions[stack.versions.length - 1].resourceUri || !stack.versions[stack.versions.length - 1].resourceUri.length === 0)
      return;
    let galleryUrl;
    galleryUrl = `/vaults/${roomStatus}/${roomId}/gallery`;
    if (folderId) {
      galleryUrl += `/folders/${folderId}`;
    }
    galleryUrl += `#${getResourceUri(stack.versions[stack.versions.length - 1].resourceUri, "s3")}`;
    return galleryUrl;
  };

  return (
    <Card
      square
      className={classes.card}
      style={{
        marginBottom: lastItem ? (positionedBeforeDate ? "74px" : "16px") : "8px"
      }}
    >
      <CardContent className={classes.content}>
        <FileIcon fontSize="medium" color="disabled" className={classes.icon} />
        <Typography
          noWrap
          variant="body2"
          className="small"
          style={{ marginTop: 4 }}
          onClick={() => {
            if (stackStatus !== "REVOKED" && stackStatus !== "DELETED") {
              history.push(getStackItemPath(stackItem));
            } else if (stackStatus === "REVOKED") {
              modal.onConfirmModalType("restoreFileFromTimeline");
              modal.onModalVisibility(null, "restoreFileFromTimeline");
              onNotificationData({
                hash: stackHash
              });
            } else if (stackStatus === "DELETED") {
              if (isLegacyVault) {
                modal.onConfirmModalType("deletedFileFromTimeline");
                modal.onModalVisibility(null, "deletedFileFromTimeline");
              } else {
                modal.onConfirmModalType("removedFileFromTimeline");
                modal.onModalVisibility(null, "removedFileFromTimeline");
              }
            }
          }}
        >
          {stackItem.stack?.name || stackItem.note?.name}
        </Typography>

        {stackItem.note ? null : stackStatus !== "REVOKED" && stackStatus !== "DELETED" ? (
          <ClickAwayListener onClickAway={() => handleButtonVisible(false)}>
            <IconButton
              aria-label="settings"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={event => {
                handleMenu(event);
                handleButtonVisible(true);
              }}
              className={classes.iconButtonThumb}
            >
              <MenuDotsHorizontalIcon />
            </IconButton>
          </ClickAwayListener>
        ) : stackStatus === "REVOKED" ? (
          <RevokedFileIcon fontSize="medium" color="disabled" className={classes.revokeButtonThumb} />
        ) : (
          <MinusInCircleIcon fontSize="medium" color="disabled" className={classes.revokeButtonThumb} />
        )}
      </CardContent>
    </Card>
  );
}

export default NoThumbnail;
