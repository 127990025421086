import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMiniSearch } from "react-minisearch-alpha";
import { fromFolders, fromVaults, fromStacks, fromMemos } from "../components/search/document";
import { documentAction } from "../components/search/document-action";
import { searchConfig } from "../components/search/search-config";
import { useGlobalContext } from "./GlobalDataProvider";
import { Folder, Memo, Stack, Vault } from "@akord/akord-js";
import { SearchOptions, Suggestion } from "minisearch";

type SearchContextProps = {
  autoSuggest: (query: string, options?: SearchOptions | undefined) => void;
  searchResults: any;
  suggestions: Suggestion[] | null;
  cachedSearchResults: any;
  onCachedSearchResults: (results: any) => void;
  onSearch: (query: string, options?: SearchOptions | undefined) => void;
  clearSearch: () => void;
  index: any;
  removeFromIndex: (id: string) => void;
  recentSearchTerm?: string;
  onRecentSearchTerm: (value: string) => void;
  isSearchActive: boolean;
  onSearchActive: (isSearchActive: boolean) => void;
  isIndexing: boolean;
  indexedVaultsCount: number;
  totalVaultsCount: number;
};

const Context = createContext<SearchContextProps>({} as SearchContextProps);

const SearchContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { contractsWorker } = useGlobalContext();

  const [recentSearchTerm, setRecentSearchTerm] = useState<string>();
  const handleRecentSearchTerm = (value: string) => setRecentSearchTerm(value);

  const [searchActive, setSearchActive] = useState(false);
  const handleSearchActive = (isSearchActive: boolean) => setSearchActive(isSearchActive);

  const [isIndexing, setIsIndexing] = useState(true);
  const handleIsIndexing = (isIndexing: boolean) => setIsIndexing(isIndexing);

  const [cachedSearchResults, setCachedSearchResults] = useState({});
  const handleCachedSearchResults = (results: any) => setCachedSearchResults(results);

  const [indexedVaultsCount, setIndexedVaultsCount] = useState(0);
  const handleIndexedVaultsCount = (value: number) => setIndexedVaultsCount(value);

  const [totalVaultsCount, setTotalVaultsCount] = useState(0);
  const handleTotalVaultsCount = (value: number) => setTotalVaultsCount(value);

  const { autoSuggest, search, rawResults, addAllAsync, miniSearch, clearSearch, removeAll, removeById, suggestions } = useMiniSearch(
    [],
    searchConfig
  );

  const location = useLocation();

  const repeatSearchIfOnSearchResults = React.useCallback(() => {
    if (location.pathname.match("search-results")) {
      const urlParams = new URLSearchParams(location.search);
      const term = urlParams.get("term") || recentSearchTerm;
      if (term) search(term);
    }
  }, [location.search, location.pathname, recentSearchTerm, search]);

  const index = React.useCallback(
    async (documents: never[]) => {
      if (Array.isArray(documents) && documents.length > 0) {
        try {
          removeAll(documents, { ignoreIfMissing: true });
          //@ts-ignore
          await addAllAsync(documents.filter(document => document && document.action === documentAction.PUT));
        } catch (e) {
          console.log(e);
        }
        repeatSearchIfOnSearchResults();
      }
    },
    [addAllAsync, removeAll, repeatSearchIfOnSearchResults]
  );

  useEffect(() => {
    if (contractsWorker) {
      contractsWorker.onmessage = (event: MessageEvent<any>) => {
        if (!event.data) {
          handleIsIndexing(false);
        } else {
          const {
            vault,
            folders,
            stacks,
            memos,
            vaultsIndex,
            totalVaultsCount
          }: { vault: Vault; folders: Folder[]; stacks: Stack[]; memos: Memo[]; vaultsIndex: number; totalVaultsCount: number } =
            event.data;
          //@ts-ignore
          index(fromVaults([vault]));
          //@ts-ignore
          index(fromFolders(folders, vault));
          //@ts-ignore
          index(fromStacks(stacks, vault));
          //@ts-ignore
          index(fromMemos(memos, vault));
          handleIndexedVaultsCount(vaultsIndex);
          handleTotalVaultsCount(totalVaultsCount);
        }
      };
    }
  }, [contractsWorker, location.pathname, index]);

  const removeFromIndex = (id: string) => {
    try {
      removeById(id);
    } catch {
      console.warn("Index: could not remove document");
    }
  };

  return (
    <Context.Provider
      value={{
        autoSuggest: autoSuggest,
        searchResults: rawResults,
        suggestions: suggestions,
        cachedSearchResults: cachedSearchResults,
        onCachedSearchResults: handleCachedSearchResults,
        onSearch: search,
        clearSearch,
        index: miniSearch,
        removeFromIndex: removeFromIndex,
        recentSearchTerm: recentSearchTerm,
        onRecentSearchTerm: handleRecentSearchTerm,
        isSearchActive: searchActive,
        onSearchActive: handleSearchActive,
        isIndexing: isIndexing,
        indexedVaultsCount: indexedVaultsCount,
        totalVaultsCount: totalVaultsCount
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SearchContextProvider;

export const useSearchContext = () => useContext(Context);
