import React from "react";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Theme, useTheme } from "@mui/material/styles";
import { Typography, Box, Container, Button } from "@mui/material";
import { RoleType } from "@akord/akord-js";
import { ButtonLink, UploadButton } from "..";
import { emptySpaceData } from "./emptySpaceData";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useRootContext } from "../../../contexts/RootContext";
import { EmptySpaceOptions } from "../../../types/globalTypes";
import { useDropboxImporter } from "../../file/FileDropboxImporter";
import { useGDriveImporter } from "../../file/FileGDriveImporter";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { getFolderId } from "../../../helpers/helpers";
import { DROPBOX_IMPORT_ENABLED, GOOGLE_IMPORT_ENABLED } from "../../../helpers/env";

type EmtySpaceProps = {
  dataText: EmptySpaceOptions;
  spaceData?: any;
  onUploadCancelHook?: () => void;
};

type StylesProps = {
  isMobile?: boolean;
  topMargin?: string;
  bottomMargin?: string;
};

const useStyles = makeStyles<Theme, StylesProps>({
  container: {
    marginTop: ({ isMobile }) => (isMobile ? 0 : "56px"),
    paddingTop: ({ topMargin }) => topMargin,
    marginBottom: ({ bottomMargin }) => bottomMargin,
    paddingBottom: "20px",
    textAlign: "center",
    overflow: ({ isMobile }) => (isMobile ? "scroll" : "auto")
  }
});

const EmptySpace: React.FC<EmtySpaceProps & RouteComponentProps> = ({ dataText, spaceData, onUploadCancelHook }) => {
  const { width, isMobile, readOnlyMode } = useGlobalContext();
  const { vault, currentMembership, isVaultPublic, isCloudStorage } = useVaultContext();
  const { subdomain } = useRootContext();
  const { modal, onNotificationData } = useNotificationsContext();

  const { importFromDropbox } = useDropboxImporter();
  const { importFromGDrive } = useGDriveImporter();

  const theme = useTheme();
  const history = useHistory();

  const userRole: RoleType = currentMembership?.role || "VIEWER";
  const isRoomArchived: boolean = vault?.status === "ARCHIVED";
  const folderId = getFolderId(history.location.pathname);

  const importFromArweave = () => {
    if (!vault.id) {
      console.error("VaultId is required to Import!");
      return;
    }
    modal.onConfirmModalFormType("importArweave");
    modal.onModalVisibility(null, "importArweave");
    onNotificationData({ title: "", dataRoomId: vault.id, folderId: folderId });
  };

  const {
    title,
    getTextEl,
    getTextElSecond,
    buttonText,
    icon: Icon,
    iconSecond: IconSecond,
    handleClick,
    topMargin,
    topMarginXS,
    bottomMarginXS,
    welcomeScreen: isWelcomeScreen,
    noMinWidth,
    tipText,
    keyboardShortcuts
  } = emptySpaceData[dataText];

  const classes = useStyles({
    topMargin: isMobile ? (topMarginXS ? topMarginXS : topMargin) : topMargin,
    bottomMargin: bottomMarginXS ? bottomMarginXS : "0px",
    isMobile: isMobile
  });

  const onKeyPress = (event: KeyboardEvent) => {
    for (const [key, action] of Object.entries(keyboardShortcuts || {})) {
      if (key) action(history);
    }
  };

  useKeyPress(Object.keys(keyboardShortcuts || {}), onKeyPress);

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box mb={4}>
        {isWelcomeScreen ? (
          <Icon sx={{ margin: "auto", fontSize: "40px" }} />
        ) : (
          <div
            style={{
              margin: "auto",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: "1px solid ",
              borderColor: isVaultPublic ? theme.palette.info.main : theme.palette.primary.main,
              display: "flex"
            }}
          >
            <Icon
              sx={{
                margin: "auto",
                color: isVaultPublic ? theme.palette.info.main : theme.palette.primary.main
              }}
            />
          </div>
        )}
      </Box>
      <Box mb={2}>
        <Typography variant="h1" color="text.primary" className={isWelcomeScreen ? "xxl" : ""}>
          {title}
        </Typography>
      </Box>
      <Box mb={5}>{getTextEl(userRole, subdomain, vault?.cloud)}</Box>
      {isWelcomeScreen && IconSecond && (
        <>
          <Box my={7}>
            <IconSecond viewBox="0 0 266 74" sx={{ fontSize: "265px", height: "auto" }} />
          </Box>
          <Box mb={5}>{getTextElSecond && getTextElSecond()}</Box>
        </>
      )}
      {dataText === "newFile" || dataText === "newPublicFile" || dataText === "newCloudFile" || dataText === "newCloudPublicFile" ? (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection={isMobile ? "column" : "row"}>
          <UploadButton disabled={userRole === "VIEWER" || isRoomArchived} noMinWidth={noMinWidth} isMobile={isMobile} buttonSize="small">
            {buttonText && buttonText(userRole)}
          </UploadButton>
          <UploadButton
            disabled={userRole === "VIEWER" || isRoomArchived}
            noMinWidth={noMinWidth}
            isMobile={isMobile}
            buttonSize="small"
            buttonVariant="outlined"
            uploadType="FOLDER"
            multiple={false}
            inputName="contained-button-folder"
            ml={2}
          >
            Upload folder
          </UploadButton>
          {isVaultPublic ? (
            <UploadButton
              disabled={userRole === "VIEWER" || isRoomArchived}
              noMinWidth={noMinWidth}
              isMobile={isMobile}
              buttonSize="small"
              buttonVariant="outlined"
              uploadType="ZIP"
              multiple={false}
              inputName="upload-file-zip"
              ml={2}
            >
              Upload zip & unpack
            </UploadButton>
          ) : (
            <>
              {GOOGLE_IMPORT_ENABLED && (
                <Button
                  onClick={() => importFromGDrive()}
                  variant="outlined"
                  color="primary"
                  component="span"
                  fullWidth={isMobile ? true : false}
                  disableElevation
                  size="small"
                  sx={{ ml: 2, my: isMobile ? 2 : 0 }}
                >
                  Import from Google Drive
                </Button>
              )}
              {DROPBOX_IMPORT_ENABLED && (
                <Button
                  onClick={() => importFromDropbox()}
                  variant="outlined"
                  color="primary"
                  component="span"
                  fullWidth={isMobile ? true : false}
                  disableElevation
                  size="small"
                  sx={{ ml: 2, my: isMobile ? 2 : 0 }}
                >
                  Import from Dropbox
                </Button>
              )}
            </>
          )}
        </Box>
      ) : (
        buttonText &&
        buttonText(userRole) && (
          <ButtonLink
            disabled={readOnlyMode || isRoomArchived}
            onClick={() => handleClick && handleClick(history, currentMembership)}
            width={width}
          >
            {buttonText(userRole)}
          </ButtonLink>
        )
      )}
      {tipText && (
        <Box mt={5}>
          <Typography variant="caption" className="small" color="text.secondary">
            {tipText}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default withRouter(EmptySpace);
