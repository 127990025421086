import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Skeleton } from "@mui/material";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StripeClimateIcon } from "@akord/addon-icons";
import QuatitySelector from "./QuantitySelector";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { grey } from "../../theme/colors";
import TopupSummaryModal from "./TopupSummaryModal";
import BillingForm from "./BillingForm";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { getBilling, initPayment, setupSubscription } from "../../helpers/api-helpers";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { SubscriptionPlan, SubscriptionPlanSetupResponse } from "../../types/stripeTypes";
import InfoBox from "../common/InfoBox/InfoBox";

type OneTimeTopUpCardFormStylesProps = {
  darkMode: boolean;
  isMobile: boolean;
};

const useStyles = makeStyles<Theme, OneTimeTopUpCardFormStylesProps>((theme) => ({
  discount: {
    backgroundColor: ({ darkMode }) => (darkMode ? "#FFF" : grey[900]),
    color: theme.palette.background.default,
    padding: `${theme.spacing(2)}`,
    borderRadius: "0 0 4px 0"
  },
  ccText: {
    fontSize: "16px",
    color: "white",
    "::placeholder": {
      color: grey[400]
    }
  },
  rowForm: {
    display: "flex",
    flexDirection: ({ isMobile }) => (isMobile ? "column" : "row")
  },
  rowField: {
    flex: "1",
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  },
  rowSkeleton: {
    marginBottom: 32,
    borderRadius: 4,
    flexGrow: 1,
    "&:nth-child(2)": {
      marginLeft: 16,
      marginRight: 16
    }
  },
  rowFields: {
    "@media (min-width: 660px)": {
      flex: 1
    },
    "@media (min-width: 766px)": {
      flex: 2
    },
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  }
}));

type OneTimeTopUpCardFormProps = {
  product?: SubscriptionPlan;
};

const OneTimeTopUpCardForm: React.FC<OneTimeTopUpCardFormProps> = ({ product }) => {
  const [confirmStripeModal, setConfirmStripeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const handleQuantityChange = (count: number) => setQuantity(count);
  const [storage, setStorage] = useState(1);
  const [amount, setAmount] = useState(1);
  const [savingsBoulderChecked, setSavingsBoulderChecked] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { isMobile, darkMode } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { modal } = useNotificationsContext();
  const { billingForm, currencyCode, getCurrencySymbol, isOnFreePlan, subscriptionPlansList } = usePaymentContext();

  const history = useHistory();
  const classes = useStyles({ isMobile: isMobile, darkMode: darkMode });

  useEffect(() => {
    if (product && quantity) {
      const storage = parseFloat(product.metadata.storage) * quantity;
      if (storage) {
        setStorage(storage);
        const amount = Math.round(product.price.currency_options[currencyCode.toLowerCase()].unit_amount * quantity);
        setAmount(amount);
      }
    } else {
      setStorage(0);
      setAmount(0);
    }
  }, [product, quantity, currencyCode]);

  const currencySymbol = getCurrencySymbol(currencyCode);
  // Boulder Prices for Savings option
  const boulderPlan = subscriptionPlansList?.find((plan) => plan.name === "Boulder");
  const boulderTopUpPrice = (boulderPlan?.topUpPrice.currency_options?.[currencyCode.toLowerCase()].unit_amount || 0) / 100;
  const boulderTiers = boulderPlan?.price.currency_options[`${currencyCode?.toLowerCase()}`].tiers;
  const boulderFlatAmount = boulderTiers?.[0].flat_amount || 0;
  const boulderUnitAmount = parseFloat(boulderTiers?.[0].unit_amount_decimal || "0");
  const boulderCloudStorage = parseFloat(boulderPlan?.metadata.cloud_gb.split(";")[0] || "0");
  const boulderPlanPrice = (boulderFlatAmount + boulderUnitAmount * boulderCloudStorage) / 100;
  const pebbleTopUpPrice = Math.round(product?.price.currency_options?.[currencyCode.toLowerCase()].unit_amount || 0 * quantity) / 100;
  const overallBoulderPrice = currencySymbol + (boulderTopUpPrice * quantity + boulderPlanPrice).toFixed(2);

  const calculateBoulderSavings = React.useCallback(() => {
    const costFree = pebbleTopUpPrice * quantity;
    const costSubscriber = boulderPlanPrice + boulderTopUpPrice * quantity;
    const savings = costFree - costSubscriber;
    return Math.round(savings);
  }, [quantity, boulderPlanPrice, boulderTopUpPrice, pebbleTopUpPrice]);

  const renderSavingsPrice = () => {
    const amount = Math.round((boulderPlan?.topUpPrice.currency_options?.[currencyCode.toLowerCase()].unit_amount || 0) * quantity) / 100;
    return `${currencySymbol}${amount.toFixed(2)}`;
  };

  // Deselect savings if a user decreased the purches amount after checking the savings box
  useEffect(() => {
    if (calculateBoulderSavings() <= 0 && savingsBoulderChecked) setSavingsBoulderChecked(false);
  }, [quantity]);

  const handleConfirmStripeModalClose = () => {
    setConfirmStripeModal(false);
  };

  const renderPrice = (plan: SubscriptionPlan) => {
    if (!plan) return "-";
    const amount = Math.round(plan.price.currency_options?.[currencyCode.toLowerCase()].unit_amount * quantity) / 100;
    return `${currencySymbol}${amount.toFixed(2)}`;
  };

  const createPaymentIntent = async () => {
    try {
      const paymentIntentResponse = await initPayment(
        {
          productId: product?.id,
          quantity: quantity,
          currencyCode: currencyCode
        },
        storage
      );

      const clientSecret = paymentIntentResponse.clientSecret;
      const paymentIntentId = paymentIntentResponse.paymentId;
      return { clientSecret, paymentIntentId };
    } catch (err: any) {
      handleConfirmStripeModalClose();
      onSnackbarToShow("topUpFailure", err.message, "error");
    }
    setLoading(false);
    return { clientSecret: null, paymentIntentId: null };
  };

  const handleBoulderSubscriptionSetup = async () => {
    if (!stripe || !elements) {
      return;
    }
    // Hardcoding 200MB storage and there is no discount coupon option for Boulder Sub in this flow
    const params: { currency: string; quantity: number; coupon?: string } = { currency: currencyCode.toLowerCase(), quantity: 200 };
    const subscription: SubscriptionPlanSetupResponse = await setupSubscription(boulderPlan?.name, params);
    const paymentIntent = subscription?.currentSubscription?.latest_invoice?.payment_intent;
    if (paymentIntent) {
      if (paymentIntent.status === "requires_action") {
        const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret);
        if (error) {
          console.log(error);
          return;
        }
      }
    }
    if (subscription?.currentSubscription) {
      onSnackbarToShow("subscriptionSetupSuccess");
    }
  };

  const handlePaymentPostProcessing = async (error: any) => {
    if (error) {
      console.info(error);
      setLoading(false);
      onSnackbarToShow("topUpFailure", error.message, "error");
      return;
    }

    setLoading(false);
    onSnackbarToShow("topUpSuccess");
    history.push("/storage");
  };

  const handleCardPayment = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    if (savingsBoulderChecked) {
      await handleBoulderSubscriptionSetup();
    }
    const { clientSecret } = await createPaymentIntent();
    if (clientSecret) {
      const billing = await getBilling();
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: billing.invoice_settings?.default_payment_method?.id || billing.default_source
      });

      handlePaymentPostProcessing(error);
    }
  };

  const handlePayment = async (isMobile: boolean) => {
    if (isMobile) {
      handleCardPayment();
    } else {
      setConfirmStripeModal(true);
    }
  };

  return (
    <>
      {confirmStripeModal && (
        <TopupSummaryModal
          formData={billingForm}
          product={product}
          quantity={quantity}
          handleCardPayment={handleCardPayment}
          openConfirmStripeModal={confirmStripeModal}
          onConfirmStripeModalClose={handleConfirmStripeModalClose}
          savingsChecked={savingsBoulderChecked}
          overallBoulderPrice={overallBoulderPrice}
          boulderPlanPrice={boulderPlanPrice}
          loading={loading}
        />
      )}
      <Box my={6} pb={isMobile ? 4 : 0}>
        {product ? (
          <>
            <Box mt={6}>
              <Box mb={4} mt={6}>
                <Typography variant="h3">Select amount</Typography>
              </Box>
              <TextField
                sx={{
                  width: "100%",
                  marginBottom: 0,
                  "& .MuiOutlinedInput-root": {
                    borderBottomRightRadius: product?.price.discount ? 0 : "initial"
                  }
                }}
                inputProps={{ sx: { fontWeight: 700, fontSize: "1.1875rem" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="h2" className="regular" color="text.tertiary">
                        GB
                      </Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="h2">{savingsBoulderChecked ? renderSavingsPrice() : renderPrice(product)}</Typography>
                      {savingsBoulderChecked && (
                        <Typography variant="caption" className="small" sx={{ whiteSpace: "pre", marginBottom: "-3px!important" }}>
                          {" "}
                          ({currencySymbol}
                          {Math.round(boulderTopUpPrice * quantity + boulderPlanPrice)} inc. plan)
                        </Typography>
                      )}
                    </InputAdornment>
                  )
                }}
                onChange={(event) => {
                  const quantityNumber = parseInt(event.target.value || "0");
                  handleQuantityChange(quantityNumber);
                }}
                value={storage}
              />
              <Box display="flex" justifyContent="space-between">
                <QuatitySelector onQuantityChange={handleQuantityChange} quantity={quantity} />
                {product?.price.discount ? (
                  <Typography variant="body2" className={[classes.discount, "strong", "small"].join(" ")}>
                    {`${product.price.discount}% off`}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            {boulderTopUpPrice > 0 && isOnFreePlan() && calculateBoulderSavings() > 0 && (
              <InfoBox
                text={`If you were subscribed to the Boulder plan, you would save ${currencySymbol}${calculateBoulderSavings()}. You can cancel a subscription at any time.`}
                title="You can save money!"
                boxChecked={savingsBoulderChecked}
                onBoxChecked={setSavingsBoulderChecked}
                offerComponent={
                  <>
                    <Typography variant="body2" className="strong" color="text.primary" sx={{ whiteSpace: "pre" }}>
                      Save {currencySymbol}
                      {calculateBoulderSavings()} today{" "}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      with a Boulder plan, ({currencySymbol}
                      {boulderPlanPrice}/m)
                    </Typography>
                  </>
                }
              />
            )}
            <Box mt={6}>
              <BillingForm
                onBillingSaved={(isMobilePayment: boolean) => handlePayment(isMobilePayment)}
                billingSaveButtonText={
                  savingsBoulderChecked
                    ? `Pay ${overallBoulderPrice} for ${quantity} GB + Boulder plan`
                    : `Pay ${renderPrice(product)} for ${quantity} GB`
                }
                submitWithoutChanges
                skipMessaging
                useMobilePayment
                mobilePaymentButtonText={`Buy ${storage} GB of perma storage`}
                mobilePaymentAmount={amount}
                mobilePaymentCurrency={currencyCode}
              />
              <Box display="flex" alignItems="center" mt={3}>
                <StripeClimateIcon fontSize="small" sx={{ marginRight: "5px" }} />
                <Typography variant="caption" color="text.tertiary" className="small">
                  Akord will contribute 1% of your purchase to remove CO2 from the atmosphere.
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <Box mt={8}>
            {Array.from(new Array(9)).map((item, index) =>
              index === 0 ? (
                <Box key={index} display="flex" flexDirection="row">
                  {Array.from(new Array(3)).map((i, idx) => (
                    <Skeleton key={idx} variant="rectangular" height={56} width="30%" className={classes.rowSkeleton} />
                  ))}
                </Box>
              ) : (
                <Skeleton key={index} variant="rectangular" height={56} style={{ marginBottom: 32, borderRadius: 4 }} />
              )
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default OneTimeTopUpCardForm;
