import { Box, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import createDOMPurify from "dompurify";
import linkifyHtml from "linkifyjs/html";
import React, { useEffect, useState } from "react";
import { AddReactionOutlinedIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { formatDate, formatDateAtTime } from "../../helpers/helpers";
import { grey } from "../../theme/colors";
import { REACTION_EMOJI_CODE_POINTS, useChatContext } from "../../contexts/ChatContextProvider";
import { Memo, MemoVersion, MemoReaction } from "@akord/akord-js";
import { reactionEmoji } from "@akord/akord-js/lib/constants";
import { IconButtonWithRef } from "../../components/common/MemoBar";

type ChatMemoItemProps = {
  positionedBeforeDate: boolean;
  memo: Memo;
  color: string;
};

type ChatMemoItemStyleProps = {
  darkMode: boolean;
};

const DOMPurify = createDOMPurify(window);

//https://github.com/cure53/DOMPurify/issues/317
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }
});

const useStyles = makeStyles<Theme, ChatMemoItemStyleProps>(theme =>
  createStyles({
    memoBubble: {
      display: "inline-block",
      lineHeight: 1.4,
      padding: "8px 12px",
      borderRadius: "4px"
    },
    link: {
      borderBottom: "none",
      borderBottomColor: theme.palette.text.primary,
      color: theme.palette.text.tertiary
    },
    reactionPicker: {
      display: "flex",
      gap: 1,
      padding: 7,
      position: "absolute",
      borderRadius: 8,
      background: ({ darkMode }) => (darkMode ? grey[800] : "#FFF"),
      filter: ({ darkMode }) => (darkMode ? "" : `drop-shadow(0 4px 4px ${grey[500]})`),
      "@media (max-width: 410px)": {
        marginRight: "70%",
        left: 0
      },
      "@media (max-width: 998px)": {
        marginRight: "10%"
      }
    },
    buttonRoot: {
      padding: "8px",
      "&:hover": {
        backgroundColor: ({ darkMode }) => (darkMode ? grey[900] : grey[200])
      }
    },
    reactionCount: {
      marginLeft: 5,
      marginBottom: 14,
      marginRight: 8,
      fontSize: 10,
      color: ({ darkMode }) => (darkMode ? grey[400] : grey[500])
    },
    spaceBetween: {
      display: "flex",
      justifyContent: "space-between"
    },
    flexEnd: {
      display: "flex",
      alignItems: "flex-end"
    }
  })
);

const ChatMemoItem: React.FC<ChatMemoItemProps> = ({ positionedBeforeDate, memo, color }) => {
  const { akord, darkMode, userAttributes } = useGlobalContext();
  const { userRole, isRoomArchived } = useVaultContext();
  const { getAuthor } = useChatContext();

  const [isMemoHovered, setIsMemoHovered] = useState(false);
  const [isComitted, setIsComitted] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const [showAddReaction, setShowAddReaction] = useState(false);

  const classes = useStyles({
    darkMode: darkMode
  });

  const memoContainer = React.useRef<HTMLDivElement>(null);
  const emojiNode = React.useRef<HTMLDivElement>(null);
  const emojiButton = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (memo.id) {
      setIsComitted(true);
    }
  }, [memo]);

  useEffect(() => {
    if (isMemoHovered && isComitted) {
      setShowAddReaction(true);
    } else {
      setShowAddReaction(false);
    }
  }, [isComitted, isMemoHovered]);

  const proccessReactions = (memoReactions: MemoReaction[]) => {
    const reactions = new Map();
    memoReactions.map(reactionData => {
      const reactionsData = reactions.get(reactionData.reaction) || [];
      reactionsData.push(reactionData);
      reactions.set(reactionData.reaction, reactionsData);
    });
    return reactions;
  };

  const isReactionAdded = (reaction: reactionEmoji) => {
    return (
      memo.versions[0].reactions &&
      memo.versions[0].reactions.some(item => item.address === userAttributes.address && item.reaction === reaction)
    );
  };

  const removeReaction = (reactionId: reactionEmoji) => {
    if (isReactionAdded(reactionId) && isComitted) {
      akord?.memo.removeReaction(memo.id, reactionId);
      memo.versions[0].reactions = memo.versions[0].reactions?.filter(
        item => !(item.owner === userAttributes.address && item.reaction === reactionId)
      );
      setIsComitted(false);
    }
  };

  const emojiFromDecimalString = (decimalString: string) => {
    const codePoints = decimalString.split(",").map(string => parseInt(string));
    try {
      return String.fromCodePoint(...codePoints);
    } catch (e) {
      return null;
    }
  };

  const addReaction = (reactionId: reactionEmoji) => {
    if (!isReactionAdded(reactionId) && isComitted) {
      akord?.memo.addReaction(memo.id, reactionId);
      memo.versions[0].reactions = memo.versions[0].reactions || [];
      memo.versions[0].reactions.push({
        owner: userAttributes.address,
        reaction: reactionId,
        // name: "You",
        createdAt: new Date().getTime().toString()
      } as MemoReaction);
      setIsComitted(false);
    }
    setShowReactions(false);
  };

  const makeEmojiBigger = (text: string) => {
    const emojiRegex = /([\ud800-\udbff])([\udc00-\udfff])/g;
    const updateText = text?.replace(
      emojiRegex,
      match =>
        `<span style=font-size:${text.length === 2 ? "32px" : "20px"};line-height:${text.length === 2 ? "32px" : "20px"}>${match}</span>`
    );
    return updateText;
  };

  const handleClick = (e: any) => {
    if ((emojiButton.current && emojiButton.current.contains(e.target)) || (emojiNode.current && emojiNode.current.contains(e.target)))
      return;

    setShowReactions(false);
  };

  const hasContainerRightSpace = () => {
    const memoContainerWidth = memoContainer?.current?.getBoundingClientRect()?.width;
    return screen.width > 920 || (memoContainerWidth && memoContainerWidth < 250);
  };

  const formatTooltipText = (reactions: MemoReaction[]) => {
    if (!reactions) {
      return null;
    }
    const tooltipText = new Map();
    reactions.forEach(reaction => {
      const date = reaction.createdAt ? formatDateAtTime(reaction.createdAt) : "";
      const memberDetails = getAuthor(reaction.address);
      if (userAttributes.address === reaction.address) {
        tooltipText.set("You", date);
      } else {
        tooltipText.set(memberDetails?.profileName, date);
      }
    });
    return Array.from(tooltipText.entries()).map((text, idx) => {
      return (
        <div key={idx}>
          <b>{text[0]}</b> {text[1]}
        </div>
      );
    });
  };

  const linkOptions = {
    defaultProtocol: "https",
    className: classes.link,
    target: { url: "_blank", email: "_blank" },
    validate: true
  };

  // If reaction is added increase margin bottom
  const isActiveReactionsPresent = (version: MemoVersion) => {
    if (version.reactions) {
      if (version.reactions?.some(reaction => reaction.status === "ACTIVE")) return true;
    } else return false;
  };

  return (
    <Box
      style={{
        marginBottom: positionedBeforeDate ? "74px" : isActiveReactionsPresent(memo.versions[0]) ? "0px" : "16px"
      }}
      onMouseEnter={() => setIsMemoHovered(true)}
      onMouseLeave={() => setIsMemoHovered(false)}
    >
      <Box className={classes.flexEnd}>
        <div
          style={{
            maxWidth: "440px"
          }}
        >
          <div
            className={classes.memoBubble}
            style={{ border: `1px solid ${color}` }}
            data-color={color}
            id="memo-container"
            ref={memoContainer}
          >
            <Box className={classes.spaceBetween}>
              <Box>
                <Tooltip title={memo.ownerInfo?.email} arrow>
                  <Typography variant="body2" component="span" className="small strong" color="text.primary" sx={{ marginRight: 2 }}>
                    {memo.ownerInfo?.profileName || memo.ownerInfo?.email || "Member"}
                  </Typography>
                </Tooltip>
                <Typography variant="caption" className="small" color="text.secondary">
                  {formatDate(memo.createdAt, true)}
                </Typography>
              </Box>

              <Box style={{ display: "flex", alignItems: "center" }}>
                {/* {!isComitted && (
                  <CircularProgress
                    component="span"
                    size={16}
                    style={{
                      marginRight: 5,
                      marginLeft: 15,
                      position: "inherit"
                    }}
                  />
                )} */}
                {/* {isDelayed && (
                  <Tooltip title="Cancel" arrow>
                    <span>
                      <IconButton
                        onClick={() => cancelTransaction()}
                        size="large"
                      >
                        <CloseInCircleIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )} */}
              </Box>
            </Box>
            <Box className={classes.spaceBetween}>
              <Typography
                paragraph
                variant="body2"
                className="small"
                style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(linkifyHtml(makeEmojiBigger(memo.versions[0].message), linkOptions))
                }}
              ></Typography>
            </Box>
          </div>
        </div>

        <Box
          style={{
            display: "flex",
            justifyContent: hasContainerRightSpace() ? "center" : "flex-end",
            minWidth: "20px"
          }}
        >
          {showAddReaction && (
            <Tooltip title="React" arrow style={{ marginLeft: "5px" }}>
              <span>
                <IconButtonWithRef
                  ref={emojiButton}
                  disabled={userRole === "VIEWER" || isRoomArchived}
                  disableRipple
                  edge="end"
                  aria-label="open emojis"
                  onClick={() => setShowReactions(!showReactions)}
                  sx={{ verticalAlign: "bottom" }}
                  size="large"
                >
                  <AddReactionOutlinedIcon fontSize="small" />
                </IconButtonWithRef>
              </span>
            </Tooltip>
          )}
          {showReactions && (
            <Box
              ref={emojiNode}
              className={classes.reactionPicker}
              sx={{
                bottom:
                  positionedBeforeDate && (memo.versions[0].reactions?.length || 0) > 0
                    ? "135px"
                    : (memo.versions[0].reactions?.length || 0) > 0
                    ? "60px"
                    : positionedBeforeDate
                    ? "100px"
                    : "40px",
                marginRight: !hasContainerRightSpace() ? "-30px" : "inherit"
              }}
            >
              {REACTION_EMOJI_CODE_POINTS.map((emojiId, index) => {
                return (
                  <IconButton
                    // variant="contained"
                    classes={{
                      root: classes.buttonRoot
                    }}
                    onClick={() => addReaction(emojiId)}
                    key={index}
                    size="large"
                  >
                    <Box fontSize={22} lineHeight={"22px"}>
                      {emojiFromDecimalString(emojiId)}
                    </Box>
                  </IconButton>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Box style={{ display: "flex", marginTop: "3px" }}>
        {memo.versions[0].reactions &&
          Array.from(proccessReactions(memo.versions[0].reactions).entries()).map((entry, index: number) => {
            const [emojiId, reactions] = entry;
            return (
              <Box className={classes.flexEnd} key={index}>
                <Tooltip
                  title={<React.Fragment>{formatTooltipText(reactions)}</React.Fragment>}
                  arrow
                  style={{ paddingLeft: 15 }}
                  key={index}
                >
                  <Typography
                    paragraph
                    variant="body2"
                    className="small"
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "break-spaces",
                      marginTop: 2
                    }}
                  >
                    <IconButton onClick={() => removeReaction(emojiId)} size="large">
                      <Box fontSize={16} lineHeight={"16px"}>
                        {emojiFromDecimalString(emojiId)}
                      </Box>
                    </IconButton>
                  </Typography>
                </Tooltip>
                <Typography variant="caption" className={classes.reactionCount}>
                  {reactions.length}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default ChatMemoItem;
