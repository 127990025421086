import React from "react";
import { Autocomplete, AutocompleteProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CloseInCircleIcon, TriangleDownIcon } from "@akord/addon-icons";
import { CountryCodeData } from "../../../helpers/akordTexts";

const useStyles = makeStyles<Theme>(theme => ({
  option: {
    fontSize: "0.9375rem",
    "& > span": {
      marginRight: "10px",
      fontSize: "18px"
    }
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: "7px 4px"
    }
  },
  paper: {
    background: theme.palette.background.card
  }
}));

const AutoCompleteSelect: React.FC<AutocompleteProps<CountryCodeData, false, boolean, false, React.ElementType<any>>> = props => {
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        option: classes.option,
        inputRoot: classes.inputRoot,
        paper: classes.paper
      }}
      autoHighlight
      popupIcon={<TriangleDownIcon />}
      clearIcon={<CloseInCircleIcon color="disabled" />}
      {...props}
    />
  );
};
export default AutoCompleteSelect;
