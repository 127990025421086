import React from "react";
import { Box, Typography, TableContainer, Table, TableBody, IconButton } from "@mui/material";
import StackItem from "../vault-pages/StackItem";
import { DataRoomFilter, EmptySpace } from "../../components/common";
import { ChevronLeftIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { withRouter } from "react-router-dom";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import FolderItem from "../vault-pages/FolderItem";
import { filterStacks } from "../../helpers/stack-helpers";
import { stableSort, getComparator, getVaultId, getVaultStatus, getRevokedFolderId, getAllFoldersRecursively } from "../../helpers/helpers";
import TopBarTitleDesktop from "../../components/common/NavBars/TopBarTitleDesktop";
import BatchActionsBar from "../../components/common/BatchActionsBar/BatchActionsBar";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import TransactionSpinner from "../../components/common/TransactionSpinner";
import { Folder, Stack } from "@akord/akord-js";

function RevokedFiles({ history }) {
  const { isMobile, darkMode, txSpinner } = useGlobalContext();
  const { vault } = useVaultContext();
  const { stacks, folders, periodFilter, actionFilters, assetsDecrypted } = useAssetsContext();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataRoomId = getVaultId(history.location.pathname);
  const folderId = getRevokedFolderId(history.location.pathname);
  const isRoomArchived = vault.status === "ARCHIVED";

  const revokedStacks = stacks?.filter(stack => stack.status === "REVOKED") || [];
  const revokedFolders = folders?.filter(folder => folder.status === "REVOKED") || [];

  let revokedStacksFoldersNotesCombined = [...revokedStacks, ...revokedFolders];

  const currentRevokedFolder = revokedFolders?.filter(folder => folder.id === folderId)[0] || {};

  const checkIfOrphan = folderId => {
    const parent = revokedFolders.filter(folder => folder.id === folderId)[0];
    return !parent;
  };

  revokedStacksFoldersNotesCombined = revokedStacksFoldersNotesCombined.filter(item => {
    if (item.parentId === undefined && !folderId) return item;
    // Add items which were revoked from within a folder and show them at the root level
    else if (item.parentId !== folderId && !folderId) return checkIfOrphan(item.parentId) && item;
    else return item.parentId === folderId;
  });

  const parentFolders = getAllFoldersRecursively(revokedFolders, currentRevokedFolder);

  return (
    <Box display={isMobile ? "flex" : "block"} flexDirection="column" height="100%">
      <Box display="flex" justifyContent="space-between">
        <Box mx={5} mt={4} mb={1} width="100%">
          <Box display="flex" justifyContent="space-between">
            <Box mb={1} display="flex" alignItems="flex-start">
              <IconButton style={{ padding: 0 }} onClick={() => history.push(`/vaults/active/${dataRoomId}/assets`)} size="large">
                <ChevronLeftIcon />
              </IconButton>
              <TopBarTitleDesktop
                variant="h2"
                color="text.secondary"
                revoked
                vault={vault}
                currentFolder={currentRevokedFolder}
                parentFolders={parentFolders}
                folderId={folderId}
                isRoomArchived={isRoomArchived}
                dataRoomStatus={getVaultStatus(history.location.pathname)}
                dataRoomId={getVaultId(history.location.pathname)}
              />
            </Box>
            <Box>{txSpinner && <TransactionSpinner isMobile={isMobile} darkMode={darkMode} />}</Box>
          </Box>
          <Typography variant="body2" color="text.secondary">
            Hidden files
          </Typography>
        </Box>
      </Box>
      <BatchActionsBar stacks={revokedStacks} folderId={folderId} revoked cloud={vault?.cloud} />
      <Box>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
            <DataRoomFilter archivedStack order={order} orderBy={orderBy} data={stacks} onRequestSort={handleRequestSort} showAllFilters />
            <TableBody>
              {!assetsDecrypted
                ? Array.from(new Array(8)).map((_, index) => <StackItem key={index} space={null} />)
                : revokedStacksFoldersNotesCombined?.length > 0 &&
                  stableSort(
                    filterStacks(revokedStacksFoldersNotesCombined, periodFilter, actionFilters),
                    getComparator(order, orderBy)
                  ).map((item, index) =>
                    item instanceof Stack ? (
                      <StackItem key={index} stack={item} revoked />
                    ) : item instanceof Folder ? (
                      <FolderItem key={index} folder={item} revoked />
                    ) : null
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        {revokedStacksFoldersNotesCombined?.length === 0 && assetsDecrypted && <EmptySpace dataText="archiveStack" />}
      </Box>
    </Box>
  );
}

export default withRouter(RevokedFiles);
