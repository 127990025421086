export type UserRoleOptions = "OWNER" | "VIEWER";

export type SnackbarOptions =
  | "dataRoomCreate"
  | "dataRoomArchive"
  | "dataRoomRename"
  | "dataRoomInvite"
  | "dataRoomRestore"
  | "vaultsBatchRestore"
  | "vaultsBatchDelete"
  | "dataRoomDelete"
  | "dataRoomConfirm"
  | "dataRoomAccessUpdate"
  | "dataRoomAccessPending"
  | "dataRoomAccessConfirm"
  | "dataRoomAccessAccepted"
  | "dataRoomAccessRevoked"
  | "dataRoomAccessReject"
  | "discountApplied"
  | "discountRejected"
  | "memberRevoke"
  | "orgInvite"
  | "uploadPending"
  | "uploadFailed"
  | "fileUpload"
  | "fileDownload"
  | "fileUploadCloud"
  | "versionUpload"
  | "fileRename"
  | "fileRevoked"
  | "batchRevoked"
  | "fileRestore"
  | "batchRestore"
  | "fileRemove"
  | "batchRemove"
  | "batchDelete"
  | "restoreFileWithMove"
  | "fileDelete"
  | "passwordChange"
  | "mfaEnabled"
  | "mfaDisabled"
  | "profileUpdate"
  | "userVerified"
  | "linkToVerify"
  | "folderCreate"
  | "folderRename"
  | "folderRevoke"
  | "folderRestore"
  | "restoreFolderWithMove"
  | "folderDelete"
  | "fileMove"
  | "folderMove"
  | "batchMove"
  | "noteMove"
  | "topUpSuccess"
  | "topUpFailure"
  | "subscriptionSetupSuccess"
  | "referralInvite"
  | "referralInvites"
  | "membershipLeave"
  | "resendInvite"
  | "revokeNote"
  | "restoreNote"
  | "saveNote"
  | "reviseNote"
  | "noteRemove"
  | "actionFailure"
  | "shareFile"
  | "shareVault"
  | "shareNft"
  | "importArweave"
  | "importArweaveError"
  | "createManifest"
  | "deleteAccount"
  | "vaultUpdate"
  | "billingSaved"
  | "billingUpdateFailure"
  | "paymentTermsNotAccepted"
  | "orgCreated"
  | "orgCreateFailed"
  | "apiKeyGenerateFailed"
  | "webhookAddFailed"
  | "webhookRemoveFailed"
  | "webhookRemoveFailed"
  | "sendToken"
  | "nftMinted"
  | "nftCollectionMinted"
  | "genericError"
  | "zipUpload";

export type EmptySpaceOptions =
  | "newRoomWelcome"
  | "newRoom"
  | "newRoomOnBoard"
  | "newFile"
  | "newPublicFile"
  | "newCloudFile"
  | "notifications"
  | "archivedRooms"
  | "archive"
  | "archiveStack"
  | "pendingVault"
  | "emptyMemos"
  | "emptyPublicVault"
  | "emptyInvoices"
  | "emptyTransactions"
  | "emptyWobhookLogs"
  | "newAtomicNft"
  | "newCloudPublicFile";

export type SignUpStepsOptions = "signup" | "backup-phrase" | "account-created" | "backup-info";

export type RecoverStepsOptions = "recover" | "backup-phrase" | "reset-password";

export interface ButtonGroupProps {
  backButtonOutlined?: boolean;
  backLoading?: boolean;
  backText?: string;
  disableBackButton?: boolean;
  disabledRed?: boolean;
  error?: any;
  errorText?: string;
  fullWidthNextButton?: boolean;
  handleBack?: () => void;
  handleEnd?: () => void;
  handleNext?: () => void;
  handleNextHelper?: () => void;
  hideBackButton?: boolean;
  hideIcon?: boolean;
  inverted?: boolean;
  loading?: boolean;
  nextDiasabled?: boolean;
  nextHelperText?: string;
  nextText?: string;
  noMarginTop?: boolean;
  noMinWidth?: boolean;
  showButtonInColumn?: boolean;
  size?: "medium" | "large" | "small" | "xs";
  type?: "button" | "reset" | "submit";
  arialLabelNext?: string;
}

export type InternalInviteForm = {
  id: number;
  email: string;
  role: string;
};

export enum OrganisationMemberRole {
  OWNER = "OWNER",
  MEMBER = "MEMBER",
  REVOKED = "REVOKED"
}
