import { Box, IconButton } from "@mui/material";
import React from "react";
import { MinusIcon, PlusIcon } from "@akord/addon-icons";

type QuatitySelectorProps = {
  onQuantityChange: (count: number) => void;
  quantity: number;
};

const QuatitySelector: React.FC<QuatitySelectorProps> = ({ onQuantityChange, quantity }) => {
  const handleIncrement = () => {
    onQuantityChange(quantity + 1);
  };

  const handleDecrement = () => {
    onQuantityChange(quantity - 1);
  };

  return (
    <Box mt={3} display="flex" alignItems="center">
      <IconButton onClick={() => handleDecrement()} disabled={quantity <= 1} aria-label="close" size="large" sx={{ marginRight: 2 }}>
        <MinusIcon fontSize="medium" />
      </IconButton>
      <IconButton onClick={() => handleIncrement()} aria-label="close" size="large">
        <PlusIcon />
      </IconButton>
    </Box>
  );
};

export default QuatitySelector;
