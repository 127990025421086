import React, { useState } from "react";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { useVaultContext } from "../../contexts/VaultContextProvider";

const ChooseMintType: React.FC<RouteComponentProps> = ({ history }) => {
  const [mintTypeChoice, setMintTypeChoise] = useState<string>();
  const { vault } = useVaultContext();

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setMintTypeChoise(target.value);
  };

  const handleNext = () => {
    if (mintTypeChoice) history.push(`/vaults/active/${vault.id}/nfts/mint/${mintTypeChoice}/metadata`);
  };

  return (
    <SupportScreenWrapper title="Single Atomic NFT or Collection" hideChevron fullHeight>
      <Box mt={6}>
        <Typography variant="body2" color="text.primary" sx={{ marginBottom: 3 }}>
          Choose whether you want to mint one NFT or a collection.
        </Typography>
        <FormControl>
          <RadioGroup aria-labelledby="udl" name="udl" onChange={handleFormChange}>
            <FormControlLabel value="single" control={<Radio />} label="Single Atomic NFT" />
            <FormControlLabel value="collection" control={<Radio />} label="Collection" />
          </RadioGroup>
        </FormControl>
      </Box>
      <ButtonGroup nextDiasabled={!mintTypeChoice} handleEnd={() => handleNext()} handleBack={() => history.goBack()} nextText="Next" />
    </SupportScreenWrapper>
  );
};

export default withRouter(ChooseMintType);
