import React from "react";
import { Box, ClassNameMap, IconButton, Tooltip } from "@mui/material";
import { FileRestoreIcon, MinusInCircleIcon, TrashIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { SelectedItem } from "../../../contexts/AssetsContextProvider";

type RevokedStackToolbarProps = {
  classes: ClassNameMap<string>;
  selectedItemsMap: Map<string, SelectedItem>;
  handleClick: (name: string) => Promise<void>;
  cloud: boolean;
};

const RevokedStackToolbar: React.FC<RevokedStackToolbarProps> = ({ classes, selectedItemsMap, handleClick, cloud }) => {
  const { readOnlyMode } = useGlobalContext();

  return (
    <>
      <Box className={classes.icons}>
        <Tooltip title="Restore" arrow>
          <span>
            <IconButton onClick={() => handleClick("restore")} disabled={selectedItemsMap.size === 0 || readOnlyMode}>
              <FileRestoreIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box>
        {cloud ? (
          <Tooltip title="Delete" arrow>
            <span>
              <IconButton onClick={() => handleClick("delete")} disabled={selectedItemsMap.size === 0 || readOnlyMode}>
                <TrashIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Clear" arrow>
            <span>
              <IconButton onClick={() => handleClick("remove")} disabled={selectedItemsMap.size === 0 || readOnlyMode}>
                <MinusInCircleIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

export default RevokedStackToolbar;
