import React from "react";
import {
  ImageIcon,
  DocumentIcon,
  PlusIcon,
  VideoIcon,
  AudioIcon,
  NoteIcon,
  DoubleTickIcon,
  TickIcon,
  CloseInCircleIcon
} from "@akord/addon-icons";
import { Tooltip } from "@mui/material";
import { Types } from "@akord/gql";
import { TransactionTableData } from "./TransactionsTable";
import { ListBar } from "../../storage-bar/HorizontalBar";

const BYTES_IN_GB = 1000000000;

export const rowNames = [
  { label: "Name", key: "name" },
  { label: "File ID", key: "dataItemId" },
  { label: "Time", key: "createdAt" },
  { label: "Size", key: "size" },
  { label: "Reward", key: "txFee" },
  { label: "Status", key: "status" },
  { label: "Viewblock", key: "" }
];

export const formatSizeFromGB = (gb: number) => {
  const size = bytesToGb(gb * BYTES_IN_GB);
  return `${size.size} ${size.sizeType}`;
};

export const bytesToSize = (bytes?: number | null, unit: "MB" | "GB" = "MB", decimals = 2) => {
  if (bytes === 0 || !bytes) return { size: 0, sizeType: unit };
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;

  let size: number;

  if (unit === "MB") {
    size = bytes / Math.pow(k, 2); // Convert bytes to megabytes
  } else if (unit === "GB") {
    size = bytes / Math.pow(k, 3); // Convert bytes to gigabytes
  } else {
    throw new Error("Unsupported unit");
  }

  return {
    size: parseFloat(size.toFixed(dm)),
    sizeType: unit
  };
};

export const sizeToBytes = (size: number, unit: "MB" | "GB", decimals = 2) => {
  if (size === 0) return { size: 0, sizeType: "B" };
  const k = 1000;

  let bytes: number;

  if (unit === "MB") {
    bytes = size * Math.pow(k, 2); // Convert megabytes to bytes
  } else if (unit === "GB") {
    bytes = size * Math.pow(k, 3); // Convert gigabytes to bytes
  } else {
    throw new Error("Unsupported unit");
  }

  return {
    size: parseFloat(bytes.toFixed(decimals)),
    sizeType: "B"
  };
};

export const bytesToGb = (bytes?: number | null, decimals = 2) => {
  if (bytes === 0 || !bytes) return { size: 0, sizeType: "B" };
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["b", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    sizeType: sizes[i]?.toUpperCase()
  };
};

const storageColors = {
  documents: "#9FF0FC",
  photos: "#F477B9",
  video: "#FFEB53",
  audio: "#8B7872",
  other: "#F3CDFE"
};

export const prepareDataForBar = (storageData: Types.Storage): ListBar[] => {
  if (!storageData) return [];
  const data = [];

  for (let key in storageData) {
    switch (key) {
      case "video":
      case "photos":
      case "audio":
      case "other":
        data.push({
          description: key?.charAt(0)?.toUpperCase() + key?.slice(1), //to uppercase first letter
          value: (storageData[key as keyof Types.Storage] as number) || 0,
          color: storageColors[key]
        });
        break;
      // Combining notes and documents
      case "documents":
        data.push({
          description: "Documents",
          value: (storageData[key as keyof Types.Storage] as number) || 0,
          color: storageColors["documents"]
        });
        break;
      // case "notes":
      //   data.find(item => {
      //     if (item.description === "Documents") item.value += storageData[key];
      //   });
      //   break;
      default:
        break;
    }
  }

  const freeSpace = storageData.storage_available && storageData.storage_available > 0 ? storageData.storage_available : 0;

  data.push({
    description: "Free",
    value: freeSpace,
    color: "transparent"
  });
  return data;
};

export const prepareDataForCloudBar = (storageData?: Types.Storage): ListBar[] => {
  if (!storageData) return [];
  const data = [];

  for (let key in storageData) {
    const storageCategory = key.replace("cloud_", "");
    switch (key) {
      case "cloud_video":
      case "cloud_photos":
      case "cloud_audio":
      case "cloud_other":
        data.push({
          description: storageCategory?.charAt(0)?.toUpperCase() + storageCategory?.slice(1), //to uppercase first letter
          value: (storageData[key as keyof Types.Storage] as number) || 0,
          color: storageColors[storageCategory as "documents" | "photos" | "video" | "audio" | "other"]
        });
        break;
      // Combining notes and documents
      case "cloud_documents":
        data.push({
          description: "Documents",
          value: (storageData[key as keyof Types.Storage] as number) || 0,
          color: storageColors["documents"]
        });
        break;
      // case "notes":
      //   data.find(item => {
      //     if (item.description === "Documents") item.value += storageData[key];
      //   });
      //   break;
      default:
        break;
    }
  }

  const freeSpace =
    storageData.cloud_storage_available && storageData.cloud_storage_available > 0 ? storageData.cloud_storage_available : 0;

  data.push({
    description: "Free",
    value: freeSpace,
    color: "transparent"
  });
  return data;
};

export const createDataForTable = ({
  txId,
  dataItemId,
  type,
  createdAt,
  status,
  nodeId,
  name,
  fileVersion,
  txFee
}: {
  txId?: string | null;
  dataItemId?: string | null;
  type?: Types.StorageTransactionType | null;
  createdAt?: string | null;
  status?: Types.StorageTransactionStatus | null;
  nodeId?: string | null;
  name?: string | null;
  fileVersion?: Types.FileVersion | null;
  txFee?: number | null;
}) => {
  return {
    txId,
    dataItemId,
    type,
    size: fileVersion?.size || 0,
    createdAt,
    status,
    nodeId,
    name,
    txFee
  };
};

export const formatTransactionReference = (transaction: TransactionTableData) => {
  const ref = transaction.dataItemId || transaction.txId;
  if (!ref) return "–";
  const formated = ref.slice(0, 4) + "..." + ref.slice(-4);
  return formated;
};

export const getTypeIconAndTitle = (type: string) => {
  switch (type) {
    case "TOPUP":
      return (
        <>
          <PlusIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />
          Top up
        </>
      );
    case "PHOTOS":
      return (
        <>
          <ImageIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />
          Photo
        </>
      );
    case "VIDEO":
      return (
        <>
          <VideoIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />
          Video
        </>
      );
    case "AUDIO":
      return (
        <>
          <AudioIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />
          Audio
        </>
      );
    case "DOCUMENTS":
      return (
        <>
          <DocumentIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />
          Document
        </>
      );
    case "NOTES":
      return (
        <>
          <NoteIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />
          Note
        </>
      );

    default:
      return (
        <>
          <DocumentIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />
          Other
        </>
      );
  }
};
export const getTypeIcon = (type: string) => {
  switch (type) {
    case "TOPUP":
      return <PlusIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />;
    case "PHOTOS":
      return <ImageIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />;
    case "VIDEO":
      return <VideoIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />;
    case "AUDIO":
      return <AudioIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />;
    case "DOCUMENTS":
      return <DocumentIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />;
    case "NOTES":
      return <NoteIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />;

    default:
      return <DocumentIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />;
  }
};

export const getArweaveStatusIcon = (status?: Types.StorageTransactionStatus | null) => {
  switch (status) {
    case "COMMITTED":
      return (
        <Tooltip title="Successfully stored on Arweave" arrow>
          <span>
            <DoubleTickIcon fontSize="small" sx={{ color: "success.main" }} />
          </span>
        </Tooltip>
      );
    case "BUNDLED":
      return (
        <Tooltip title="Your file is being bundled before being sent to Arweave" arrow>
          <span>
            <DoubleTickIcon fontSize="small" sx={{ color: "warning.main" }} />
          </span>
        </Tooltip>
      );
    case "REGISTERED":
      return (
        <Tooltip title="Your NFT was successfully minted." arrow>
          <span>
            <DoubleTickIcon fontSize="small" sx={{ color: "success.main" }} />
          </span>
        </Tooltip>
      );
    case "PENDING":
      return (
        <Tooltip title="Your file is now with Arweave, being processed by the network" arrow>
          <span>
            <DoubleTickIcon fontSize="small" sx={{ color: "#FFEB53" }} />
          </span>
        </Tooltip>
      );
    case "SCHEDULED":
    case "VERIFICATION":
      return (
        <Tooltip title="Your file is with Akord, next we’ll bundle it with other files" arrow>
          <span>
            <TickIcon fontSize="small" sx={{ color: "warning.main" }} />
          </span>
        </Tooltip>
      );
    case "REJECTED":
      return (
        <Tooltip title="Upload failed to Arweave" arrow>
          <span>
            <CloseInCircleIcon fontSize="small" color="error" />
          </span>
        </Tooltip>
      );
    case "BLOCKED":
      return (
        <Tooltip title="Your file was classified as malicious" arrow>
          <span>
            <CloseInCircleIcon fontSize="small" color="error" />
          </span>
        </Tooltip>
      );

    default:
      return (
        <Tooltip title="Upload failed to Arweave" arrow>
          <span>
            <CloseInCircleIcon fontSize="small" color="error" />
          </span>
        </Tooltip>
      );
  }
};
