import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton, Typography, Tooltip, Theme } from "@mui/material";
import { InactiveIcon, DoubleChevronLeftIcon, GlobeIcon, PadlockIcon, InfinityIcon, CloudIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { Folder, Vault } from "@akord/akord-js";
import { Variant } from "@mui/material/styles/createTypography";

const useStyles = makeStyles<Theme>(theme => ({
  roomName: {
    marginTop: 0
  },
  textLink: {
    borderBottom: "none",
    "&:hover": {
      color: theme.palette.text.primary
    }
  }
}));

type TopBarTitleDesktopProps = {
  vault: Vault;
  currentFolder: Folder;
  parentFolders: Folder[];
  folderId: string | null;
  revoked?: boolean;
  variant: Variant;
  color: string;
};

const TopBarTitleDesktop: React.FC<TopBarTitleDesktopProps> = ({
  vault,
  currentFolder,
  parentFolders,
  folderId,
  revoked = false,
  variant,
  color
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isPublicRoute = !!location.pathname.match("/public");
  const urlPrefix = isPublicRoute ? "/public/vaults" : "/vaults";

  return (
    <Box display="flex" width={revoked ? "100%" : "calc(100% - 400px)"}>
      {!vault || Object.keys(vault).length === 0 ? (
        <Skeleton variant="text" width="70%" />
      ) : (
        <>
          {vault.status === "ARCHIVED" && <InactiveIcon color="disabled" sx={{ marginRight: 2 }} />}
          <Box display="flex" className={classes.roomName} width="100%">
            {folderId ? (
              parentFolders.length < 2 ? (
                <Typography
                  variant={variant}
                  noWrap
                  className={`${classes.textLink} ${"regular"}`}
                  color={color}
                  component="a"
                  onClick={() =>
                    revoked
                      ? history.push(`/vaults/${vault?.status?.toLowerCase()}/${vault?.id}/revoked-files`)
                      : history.push(`${urlPrefix}/${vault?.status?.toLowerCase()}/${vault?.id}/assets`)
                  }
                >
                  {vault?.name}
                </Typography>
              ) : (
                <IconButton
                  disableRipple
                  aria-label="back"
                  aria-controls="menu-appbar"
                  onClick={() =>
                    revoked
                      ? history.push(`/vaults/${vault?.status.toLowerCase()}/${vault?.id}/revoked-files`)
                      : history.push(`${urlPrefix}/${vault?.status.toLowerCase()}/${vault?.id}/assets`)
                  }
                  style={{ alignItems: "flex-start", padding: 0 }}
                  size="large"
                >
                  <DoubleChevronLeftIcon fontSize={revoked ? "small" : "inherit"} />
                </IconButton>
              )
            ) : (
              <>
                <Typography variant={variant} noWrap color="text.primary">
                  {vault?.name}
                </Typography>
                <Box ml={3}>
                  {vault?.cloud ? (
                    <Tooltip title="Cloud" arrow>
                      <span>
                        <CloudIcon fontSize="small" sx={{ color: vault.public ? "info.main" : "primary.main" }} />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Permanent" arrow>
                      <span>
                        <InfinityIcon fontSize="small" sx={{ color: vault.public ? "info.main" : "primary.main" }} />
                      </span>
                    </Tooltip>
                  )}
                  {vault?.public ? (
                    <Tooltip title="Public vault" arrow>
                      <span
                        style={{
                          height: "20px",
                          marginLeft: "8px",
                          marginTop: "1px"
                        }}
                      >
                        <GlobeIcon fontSize="small" sx={{ color: vault.public ? "info.main" : "primary.main" }} />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Private vault" arrow>
                      <span
                        style={{
                          height: "20px",
                          marginLeft: "8px",
                          marginTop: "1px"
                        }}
                      >
                        <PadlockIcon fontSize="small" sx={{ color: vault.public ? "info.main" : "primary.main" }} />
                      </span>
                    </Tooltip>
                  )}
                </Box>
              </>
            )}
            {parentFolders.map((parentFolder, index) =>
              index >= parentFolders.length - 2 ? (
                <Box key={index} display="flex">
                  {index !== parentFolders.length - 2 && (
                    <Typography variant={variant} className="regular" color={color} sx={{ whiteSpace: "pre-wrap" }}>
                      {" / "}
                    </Typography>
                  )}
                  <Typography
                    variant={variant}
                    noWrap
                    className={`${classes.textLink} ${"regular"}`}
                    color={color}
                    component="a"
                    onClick={() =>
                      revoked
                        ? history.push(`/vaults/${vault?.status?.toLowerCase()}/${vault?.id}/revoked-files/folders/${parentFolder.id}`)
                        : history.push(`${urlPrefix}/${vault?.status?.toLowerCase()}/${vault?.id}/assets/folders/${parentFolder.id}`)
                    }
                  >
                    {parentFolder.name}
                  </Typography>
                </Box>
              ) : null
            )}
            {folderId && (
              <>
                <Typography variant={variant} className="regular" color={color} sx={{ whiteSpace: "pre-wrap" }}>
                  {" / "}
                </Typography>
                <Typography variant={variant} noWrap color="text.primary">
                  {currentFolder?.name}
                </Typography>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default TopBarTitleDesktop;
