import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TableRow, TableCell, TableHead, Typography } from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ArrowUpIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { getVaultStatus, getVaultId } from "../../helpers/helpers";
import { Folder, Stack, Vault } from "@akord/akord-js";
import { Order, OrderBy } from "../../types/globalDataTypes";

const useStyles = makeStyles({
  tableText: {
    fontSize: "13px"
  }
});

type DataRoomFilterProps = {
  archivedStack?: boolean;
  order: Order;
  orderBy: OrderBy;
  onRequestSort: (event: React.MouseEvent<HTMLAnchorElement>, property: OrderBy) => void;
  data: (Stack | Folder)[] | Vault[];
  showAllFilters?: boolean;
};

const DataRoomFilter: React.FC<DataRoomFilterProps & RouteComponentProps> = ({
  archivedStack,
  order,
  orderBy,
  onRequestSort,
  data,
  location,
  showAllFilters
}) => {
  const { width, isMobile } = useGlobalContext();
  const classes = useStyles();

  const createSortHandler = (property: OrderBy) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    onRequestSort(event, property);
  };

  const columnsToShow = () => {
    switch (true) {
      case isMobile ||
        (width === "sm" && !location.pathname.match(`/vaults/${getVaultStatus(location.pathname)}/${getVaultId(location.pathname)}`)):
        switch (true) {
          case data && data.length > 0:
            return <TableCell width="5%" />;
          default:
            return <TableCell width="5%" />;
        }
      case (width !== "xs" && !!location.pathname.match(`/vaults/${getVaultStatus(location.pathname)}/${getVaultId(location.pathname)}`)) ||
        showAllFilters:
        return (
          <>
            <TableCell width="25%" className={classes.tableText} align="left" sortDirection={orderBy === "updatedAt" ? order : false}>
              <TableSortLabel
                active={orderBy === "updatedAt"}
                direction={orderBy === "updatedAt" ? order : "asc"}
                onClick={createSortHandler("updatedAt")}
                // @ts-ignore
                IconComponent={ArrowUpIcon}
              >
                <Typography variant="caption" className="small" color="text.secondary">
                  {archivedStack ? "Revoked on" : "Modified on"}
                </Typography>
              </TableSortLabel>
            </TableCell>
            {width !== "sm" && (
              <>
                <TableCell width="20%" className={classes.tableText} align="left" sortDirection={orderBy === "size" ? order : false}>
                  <TableSortLabel
                    active={orderBy === "size"}
                    direction={orderBy === "size" ? order : "asc"}
                    onClick={createSortHandler("size")}
                    // @ts-ignore
                    IconComponent={ArrowUpIcon}
                  >
                    <Typography variant="caption" className="small" color="text.secondary">
                      Size
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell width="10%" className={classes.tableText} align="left" sortDirection={orderBy === "version" ? order : false}>
                  <TableSortLabel
                    active={orderBy === "version"}
                    direction={orderBy === "version" ? order : "asc"}
                    onClick={createSortHandler("version")}
                    // @ts-ignore
                    IconComponent={ArrowUpIcon}
                  >
                    <Typography variant="caption" className="small" color="text.secondary">
                      Versions
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              </>
            )}
            <TableCell width="5%" />
          </>
        );
      default:
        return (
          <>
            <TableCell width="5%" className={classes.tableText} align="left" sortDirection={orderBy === "cloud" ? order : false}>
              <TableSortLabel
                active={orderBy === "cloud"}
                direction={orderBy === "cloud" ? order : "asc"}
                onClick={createSortHandler("cloud")}
                // @ts-ignore
                IconComponent={ArrowUpIcon}
              >
                <Typography variant="caption" className="small" color="text.secondary">
                  Type
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell width="5%" className={classes.tableText} align="left" sortDirection={orderBy === "public" ? order : false}>
              <TableSortLabel
                active={orderBy === "public"}
                direction={orderBy === "public" ? order : "asc"}
                onClick={createSortHandler("public")}
                // @ts-ignore
                IconComponent={ArrowUpIcon}
              >
                <Typography variant="caption" className="small" color="text.secondary">
                  Privacy
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell width="10%" align="left" sortDirection={orderBy === "updatedAt" ? order : false}>
              <TableSortLabel
                active={orderBy === "updatedAt"}
                direction={orderBy === "updatedAt" ? order : "asc"}
                onClick={createSortHandler("updatedAt")}
                // @ts-ignore
                IconComponent={ArrowUpIcon}
              >
                <Typography variant="caption" className="small" color="text.secondary">
                  Modified on
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell width="10%" className={classes.tableText} align="left" sortDirection={orderBy === "size" ? order : false}>
              <TableSortLabel
                active={orderBy === "size"}
                direction={orderBy === "size" ? order : "asc"}
                onClick={createSortHandler("size")}
                // @ts-ignore
                IconComponent={ArrowUpIcon}
              >
                <Typography variant="caption" className="small" color="text.secondary">
                  Size
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell width="5%" />
          </>
        );
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell width={isMobile ? "100%" : "30%"} align="left" sortDirection={orderBy === "name" ? order : false}>
          <TableSortLabel
            active={orderBy === "name"}
            direction={orderBy === "name" ? order : "asc"}
            onClick={createSortHandler("name")}
            // @ts-ignore
            IconComponent={ArrowUpIcon}
          >
            <Typography variant="caption" className="small" color="text.secondary">
              Name
            </Typography>
          </TableSortLabel>
        </TableCell>
        {columnsToShow()}
      </TableRow>
    </TableHead>
  );
};

export default withRouter(DataRoomFilter);
