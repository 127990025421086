import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { AppBar, IconButton, Toolbar, Fab, TextField, Box, Theme, IconButtonProps } from "@mui/material";
import "emoji-mart/css/emoji-mart.css";
import { BaseEmoji, Picker } from "emoji-mart";
import { EmojiFaceIcon, PaperPlaneIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { grey } from "../../theme/colors";
import { MemoWithOwnerInfo, useChatContext } from "../../contexts/ChatContextProvider";
import useSearchIndex from "../../hooks/useSearchIndex";

type MemoBarStyleProps = {
  isMobile: boolean;
  darkMode: boolean;
  isMemberViewer: boolean;
};

const useStyles = makeStyles<Theme, MemoBarStyleProps>(theme => ({
  appBottomBar: {
    width: ({ isMobile }) => (!isMobile ? "calc(100% - 250px)" : "100%"),
    top: "auto",
    bottom: 0,
    borderTop: "1px solid",
    borderColor: ({ darkMode }) => (darkMode ? grey[700] : grey[300]),
    borderRadius: 0,
    minHeight: "55px",
    height: "auto"
  },
  grow: {
    flexGrow: 1
  },
  textField: {
    marginBottom: 0
  },
  outlinedInput: {
    cursor: ({ isMemberViewer }) => (isMemberViewer ? "not-allowed" : "inherit"),
    border: "none!important",
    fontSize: "15px",
    padding: "6px 12px",
    "&::placeholder": {
      color: theme.palette.text.disabled
    },
    "&.Mui-disabled": {
      background: ({ darkMode }) => (darkMode ? `${grey[700]}!important` : `${grey[200]}!important`)
    }
  },
  inputRoot: {
    fontSize: "15px",
    borderRadius: "20px",
    minWidth: "100px"
  },
  notchedOuline: {
    border: "none!important"
  },
  multiline: {
    padding: "0px",
    overflow: "hidden"
  },
  button: {
    height: "33px",
    minWidth: "auto",
    border: "none",
    borderRadius: "100px",
    padding: "0 16px",
    marginLeft: theme.spacing(3),
    marginBottom: 0
  },
  fab: {
    "&.Mui-disabled": {
      color: ({ darkMode }) => (darkMode ? `${grey[500]}!important` : `${grey[400]}!important`),
      backgroundColor: ({ darkMode }) => (darkMode ? `${grey[700]}!important` : `${grey[200]}!important`)
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  },
  emojiBox: {
    position: "absolute",
    boxShadow: "none",
    bottom: "70px",
    left: "260px",
    marginBottom: "2px"
  },
  emojiButton: {
    padding: "4px 12px"
  }
}));

export const IconButtonWithRef = React.forwardRef<HTMLButtonElement, IconButtonProps>(({ children, ...otherProps }, ref: any) => (
  <IconButton ref={ref} {...otherProps}>
    {children}
  </IconButton>
));

const MemoBar: React.FC = () => {
  const { akord, profileDetails, darkMode, isMobile, readOnlyMode } = useGlobalContext();
  const { onMemos, memos } = useChatContext();

  const { currentMembership, userRole, vault } = useVaultContext();

  const isRoomArchived = vault?.status === "ARCHIVED";
  const classes = useStyles({
    darkMode: darkMode,
    isMobile: isMobile,
    isMemberViewer: userRole === "VIEWER"
  });
  const [message, setMessage] = useState("");

  const [showEmoji, setShowEmoji] = useState(false);

  const { indexSearchData } = useSearchIndex();

  const emojiNode = React.useRef<HTMLDivElement>(null);
  const emojiButton = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleMessageSubmit = async (e: React.KeyboardEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (message === "\n" || message === "") {
      setMessage("");
      return;
    }
    try {
      const preparedMessage = message.trim();
      const newMemo = {
        versions: [
          {
            message: preparedMessage,
            owner: currentMembership?.address!,
            createdAt: new Date().getTime().toString()
          }
        ],
        ownerInfo: {
          ...profileDetails,
          id: currentMembership?.id!
        },
        owner: currentMembership?.address!,
        createdAt: new Date().getTime().toString()
        // groupRef: null
      };
      onMemos([...memos, newMemo as MemoWithOwnerInfo]);
      setMessage("");
      await akord?.memo.create(vault.id, preparedMessage);

      indexSearchData();
    } catch (err) {
      console.log("Error posting memo: ", err);
    }
  };

  const onEmojiClick = (emoji: BaseEmoji) => {
    setMessage(prevState => prevState + emoji.native);
  };

  // TODO figure out the event type
  const handleClick = (e: any) => {
    if ((emojiButton.current && emojiButton.current.contains(e.target)) || (emojiNode.current && emojiNode.current.contains(e.target)))
      return;

    // outside click
    setShowEmoji(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.nativeEvent.keyCode === 13) {
      if (e.shiftKey) return;
      else {
        if ((e.target as HTMLFormElement).value === "" || !(e.target as HTMLFormElement).value.replace(/\s/g, "").length) return;
        handleMessageSubmit(e);
      }
    }
  };

  return (
    <>
      {showEmoji && (
        <div ref={emojiNode}>
          <Picker
            theme="auto"
            showPreview={false}
            style={{
              position: "fixed",
              bottom: "70px",
              left: "260px",
              zIndex: 1
            }}
            onClick={onEmojiClick}
          />
        </div>
      )}
      <AppBar position="fixed" color="secondary" className={classes.appBottomBar}>
        <Toolbar component="form" onSubmit={handleMessageSubmit} onKeyDown={handleKeyDown}>
          <Box display="flex" flexDirection="row" width="100%" alignItems="center" paddingTop="9px" paddingBottom="10px">
            {!isMobile && (
              <div
                style={{
                  cursor: userRole === "VIEWER" || isRoomArchived ? "not-allowed" : "inherit"
                }}
              >
                <IconButtonWithRef
                  disabled={userRole === "VIEWER" || isRoomArchived}
                  ref={emojiButton}
                  disableRipple
                  edge="start"
                  aria-label="open emojis"
                  onClick={() => setShowEmoji(!showEmoji)}
                  className={classes.emojiButton}
                  size="large"
                >
                  <EmojiFaceIcon />
                </IconButtonWithRef>
              </div>
            )}
            <TextField
              disabled={userRole === "VIEWER" || isRoomArchived}
              fullWidth
              variant="outlined"
              color="secondary"
              multiline
              maxRows={5}
              value={message}
              onChange={handleMessageChange}
              placeholder="Write an encrypted message…"
              className={classes.textField}
              InputProps={{
                "aria-label": "message",
                classes: {
                  root: classes.inputRoot,
                  input: classes.outlinedInput,
                  multiline: classes.multiline,
                  notchedOutline: userRole === "VIEWER" || isRoomArchived ? classes.notchedOuline : "inherit"
                }
              }}
            />
            <div
              style={{
                cursor: userRole === "VIEWER" || isRoomArchived ? "not-allowed" : "inherit"
              }}
            >
              <Box ml={4}>
                {isMobile ? (
                  <Fab
                    disabled={userRole === "VIEWER" || isRoomArchived || readOnlyMode}
                    component="button"
                    type="submit"
                    size="small"
                    color="primary"
                    className={classes.fab}
                  >
                    <PaperPlaneIcon fontSize="small" sx={{ marginRight: 4 }} />
                  </Fab>
                ) : (
                  <>
                    <Fab
                      disabled={userRole === "VIEWER" || isRoomArchived || readOnlyMode}
                      component="button"
                      variant="extended"
                      type="submit"
                      size="small"
                      color="primary"
                      className={classes.fab}
                    >
                      <PaperPlaneIcon fontSize="small" classes={{ root: classes.extendedIcon }} />
                      Post
                    </Fab>
                  </>
                )}
              </Box>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MemoBar;
