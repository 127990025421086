export const getUniqueName = (fileName: string, allExistingNames: string[], startIndex: number): string => {
  let updatedFileName: string;
  //first run add ` copy`
  if (startIndex === 1) updatedFileName = fileName.replace(/(\.[\w\d_-]+)$/i, " copy$1");
  //second run add ` 2` to `copy` -> `copy 2`
  else if (startIndex === 2) updatedFileName = fileName.replace(/(\.[\w\d_-]+)$/i, ` ${startIndex}$1`);
  //after changer `2` to next number -> `3` , `4` etc
  else updatedFileName = fileName.replace(/(\d+)(?!.*\d)/, `${startIndex}`);
  if (allExistingNames.indexOf(updatedFileName) !== -1) {
    return getUniqueName(updatedFileName, allExistingNames, startIndex + 1);
  } else {
    return updatedFileName;
  }
};
