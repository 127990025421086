import React from "react";
import { Menu, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(theme => ({
  paper: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.spacing(2),
    minWidth: "130px"
    // left: 'calc(100% - 250px)!important'
  }
}));

type MoreMenuRootProps = {
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  openMenu: boolean;
  handleMenuClose: () => void;
  id?: string;
};

const MoreMenuRoot: React.FC<MoreMenuRootProps> = ({ children, anchorEl, openMenu, handleMenuClose, id = "drop-down-menu" }) => {
  const classes = useStyles();

  return (
    <Menu
      variant="selectedMenu"
      elevation={8}
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={openMenu}
      onClose={handleMenuClose}
      classes={{ paper: classes.paper }}
    >
      {children}
    </Menu>
  );
};

export default MoreMenuRoot;
