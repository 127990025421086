import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { ButtonGroup, Stepper } from "../../components/common";
import { AccountBackupIcon } from "@akord/addon-icons";
import { akordAbout } from "../../helpers/akordTexts";
import { useSignupContext } from "../../contexts/SignupContext";

const useStyles = makeStyles<Theme>(theme => ({
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  container: {
    background: theme.palette.background.secondary,
    borderRadius: "5px"
  }
}));

const BackupInfo: React.FC<RouteComponentProps> = ({ history }) => {
  const { isDesktop } = useSignupContext();

  const classes = useStyles();

  const [activeStepInfo, setActiveStepInfo] = useState(0);
  const [nextSlide, setNextSlide] = useState(true);

  const handleNext = () => {
    setNextSlide(true);
    setActiveStepInfo(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setNextSlide(false);
    setActiveStepInfo(prevActiveStep => prevActiveStep - 1);
  };

  const handleEnd = () => {
    history.goBack();
  };

  const renderStep = () => {
    switch (activeStepInfo) {
      case 0:
        return akordAbout[0];
      case 1:
        return akordAbout[1];
      case 2:
        return akordAbout[2];
      default:
        return { title: "Hello World?", text: ["Hello Akord!"] };
    }
  };

  const nextText = () => {
    switch (activeStepInfo) {
      case 2:
        return "Done";
      default:
        return "Next";
    }
  };

  return (
    <>
      <Box>
        <Box className={classes.rootWithIcon}>
          <Typography variant="h1" className={classes.typo}>
            Account backup info
          </Typography>
          <AccountBackupIcon fontSize="large" color="primary" />
        </Box>
        <Stepper current={activeStepInfo} total={3} />

        <TransitionGroup className={nextSlide ? "right" : "left"}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              timeout={isDesktop ? 0 : 200}
              key={activeStepInfo}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames={isDesktop ? "none" : "fade"}
            >
              <Box p={5} className={classes.container}>
                <Typography variant="h2" paragraph>
                  {renderStep().title}
                </Typography>
                {renderStep().text.map((p, i) => (
                  <Typography key={i} variant="body2" paragraph>
                    {p}
                  </Typography>
                ))}
              </Box>
            </CSSTransition>
          </SwitchTransition>
        </TransitionGroup>
      </Box>
      <ButtonGroup
        nextDiasabled={false}
        handleNext={handleNext}
        handleBack={handleBack}
        handleEnd={activeStepInfo === 2 ? handleEnd : undefined}
        nextText={nextText()}
        hideBackButton={activeStepInfo === 0}
        fullWidthNextButton={activeStepInfo === 0}
      />
    </>
  );
};

export default withRouter(BackupInfo);
