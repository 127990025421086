import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { useProgressContext } from "../../contexts/ProgressContext";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";

/**
 *
 * Vault context file downloader
 */
export const useDownloader = ({ useLoader }) => {
  const { akord, withTxSpinner } = useGlobalContext();
  const { vault } = useVaultContext();
  const { onUploadCancelHook, onDrawerActionType, onAxiosError, showLoaderByVaultId, onShowLoaderByVaultId, stacks, folders } =
    useAssetsContext();
  const { onProgress } = useProgressContext();

  let cancel;

  /**
   * All chunked files are downloaded using stream.
   * Relatively small files are handled in trivial way:
   *  - fetch file to browser memory (RAM)
   *  - save file using default browser API: 'save as' window
   * This behaviour can be altered here to e.g. always download using stream API.
   * It will be done only if stream download is tested on all major browsers.
   */
  const saveAs = async (stackId, index = 0) => {
    onStart();
    await akord.stack.download(stackId, index, { progressHook: onProgress, cancelHook: cancel });
  };

  const saveAsUrl = async (stackId, index, name) => {
    const { data } = await getBinary(stackId, { index, name });
    return URL.createObjectURL(new Blob([data]));
  };

  const saveAsZip = async stackListMap => {
    const stacksMap = stacks.reduce((acc, stack) => acc.set(stack.id, stack), new Map());
    const foldersMap = folders.reduce((acc, folder) => acc.set(folder.id, folder), new Map());

    const archiveName = vault?.name + ".zip";

    const zip = new JSZip();

    await addToZip(zip, stackListMap, stacksMap, foldersMap);

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveBlobAs(content, archiveName);
    });
  };

  const addToZip = async (zip, assets, stacksMap, foldersMap) => {
    const values = Array.from(assets.values());
    const zipSize = values?.reduce((prev, curr) => prev + curr.size, 0);
    let bytesZipped = 0;
    for (const [key, value] of assets) {
      if (value && value.objectType === "Stack") {
        const stack = stacksMap.get(key);
        const url = await saveAsUrl(stack.id);
        const file = await JSZipUtils.getBinaryContent(url);
        zip.file(stack.name, file, { binary: true });
        bytesZipped += file.byteLength;
        onProgress(Math.min(100, Math.round((bytesZipped / zipSize) * 100)));
      } else if (value && value.objectType === "Folder") {
        const folder = foldersMap.get(key);
        await zipFolderCreate(folder, zip);
      }
    }
  };

  const getBinary = async (stackId, options = { index: 0, name: '' }) => {
    const version = await akord.stack.getVersion(stackId, options.index);
    const nameSuffix = options.index ? ` (v${options.index + 1})` : "";
    const nameParts = (options.name || version.name).split(".");
    const extension = nameParts.pop();
    const name = `${nameParts.join()}${nameSuffix}.${extension}`;
    const data = new Blob([version.data], {
      type: version.type
    });
    return { data, name };
  };

  const saveBlobAs = (blob, name) => {
    const a = document.createElement("a");
    a.download = name;
    a.href = window.URL.createObjectURL(blob);
    a.click();
  };


  const zipFolderCreate = async (folder, archive) => {
    const folderInZip = archive.folder(folder.name);

    await Promise.all(
      stacks
        .filter(stack => stack.parentId === folder.id)
        .map(async stack => {
          const url = await saveAsUrl(stack.id);
          const file = await JSZipUtils.getBinaryContent(url);
          return folderInZip.file(stack.name, file, { binary: true });
        })
    );

    await Promise.all(
      folders
        .filter(item => item.parentId === folder.id)
        .map(async folder => {
          await zipFolderCreate(folder, folderInZip);
        })
    );
  };

  const tryWithSpinner = async fun => {
    try {
      return await withTxSpinner(async () => {
        const res = await fun();
        onSuccess();
        return res;
      });
    } catch (e) {
      onError(e);
    }
    return null;
  };

  const onStart = () => {
    useLoader && onShowLoaderByVaultId(vault.id);
    onDrawerActionType("download");
    cancel = new AbortController();
    onUploadCancelHook(cancel);
  };

  const onSuccess = () => {
    setTimeout(() => {
      // remove if present
      if (showLoaderByVaultId.includes(vault.id)) onShowLoaderByVaultId(vault.id);
      onProgress(0);
    }, 500);
  };

  const onError = err => {
    console.error("Error:", err);
    onAxiosError(err);
    setTimeout(() => {
      onAxiosError();
    }, 3000);
    // remove if present
    if (showLoaderByVaultId.includes(vault.id)) onShowLoaderByVaultId(vault.id);
    onProgress(0);
  };

  return {
    onSaveAs: async (stackId, index) => await tryWithSpinner(async () => await saveAs(stackId, index)),
    onSaveAsUrl: async (stackId, index) =>
      await tryWithSpinner(async () => await saveAsUrl(stackId, index)),
    onSaveAsZip: async stacks => await tryWithSpinner(async () => {
      onStart();
      await saveAsZip(stacks)
    }),
    onBinary: async (stackId, index, name) => await tryWithSpinner(async () => {
      onStart();
      return await getBinary(stackId, { index, name });
    })
  };
};
