import React from "react";
import { Box } from "@mui/material";
import SearchBox from "../../components/search/SearchBox";

const ShowSearch: React.FC = () => {
  return (
    <Box margin="10px">
      <SearchBox forceActive={true}></SearchBox>
    </Box>
  );
};

export default ShowSearch;
