import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Auth } from "@akord/akord-js";
import { SupportScreenWrapper } from "../../components/common";
import { AkordSwitch } from "../../components/common/AkordSwitch/AkordSwitch";

const Notifications: React.FC = () => {
  const [checked, setChecked] = React.useState(true);

  React.useEffect(() => {
    const getNotifications = async () => {
      const notifications = (await Auth.getUserAttributes())["custom:notifications"];
      setChecked(notifications === "true");
    };
    getNotifications();
  }, []);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    await Auth.updateUserAttribute("custom:notifications", event.target.checked.toString());
  };

  return (
    <SupportScreenWrapper title="Notifications" route="/account">
      <Box mt={6}>
        <Grid component="label" container alignItems="flex-start" spacing={2} wrap="nowrap" justifyContent="space-between">
          <Grid item>
            <Grid direction="column" container>
              <Grid item>
                <Typography component="div" variant="body2" color="text.primary">
                  Email
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  Receive an email notification any time there is activity in your vaults.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <AkordSwitch checked={checked} onChange={handleChange} name="checked" />
          </Grid>
        </Grid>
      </Box>
    </SupportScreenWrapper>
  );
};

export default Notifications;
