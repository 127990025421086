import React from "react";

interface Base64LogoProps {
  base64: string;
  className: string;
  width?: string;
  onClick: () => void;
}

const Base64Logo: React.FC<Base64LogoProps> = ({ className, base64, width, onClick, ...props }) => {
  return <img className={className} src={base64} width={width} onClick={onClick} {...props} alt="logo" />;
};

export default Base64Logo;
