import { Box, Divider, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { Theme } from "@mui/system";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { grey } from "../../theme/colors";
import { usePaymentContext } from "../../contexts/PaymentContext";
import LogoSimple from "../common/logo/logoSimple";
import { simulateSubscription } from "../../helpers/api-helpers";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { formatDate } from "../../helpers/helpers";
import { Invoice, InvoiceLinesData } from "../../types/stripeTypes";

type SubscriptionCheckoutSummaryStylesProps = {
  isMobile: boolean;
};

const useStyles = makeStyles<Theme, SubscriptionCheckoutSummaryStylesProps>(theme => ({
  promoText: {
    backgroundColor: theme.palette.info.light,
    color: grey[900],
    padding: `${theme.spacing(2)}`,
    borderRadius: "0 0 4px 0"
  },
  ccText: {
    fontSize: "16px",
    color: "white",
    "::placeholder": {
      color: grey[400]
    }
  },
  rowForm: {
    display: "flex",
    flexDirection: ({ isMobile }) => (isMobile ? "column" : "row")
  },
  rowField: {
    flex: "1",
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  },
  rowSkeleton: {
    marginBottom: 32,
    borderRadius: 4,
    flexGrow: 1,
    "&:nth-child(2)": {
      marginLeft: 16,
      marginRight: 16
    }
  },
  rowFields: {
    "@media (min-width: 660px)": {
      flex: 1
    },
    "@media (min-width: 766px)": {
      flex: 2
    },
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  }
}));

const SubscriptionCheckoutSummary = () => {
  const { isMobile } = useGlobalContext();
  const {
    currencyCode,
    renderPrice,
    discountCoupon,
    onDiscountCoupon,
    loading,
    onLoading,
    onDiscountCouponApplied,
    onUpcomingInvoice,
    quantity,
    planName
  } = usePaymentContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const [invoice, setInvoice] = useState<Invoice>();

  const classes = useStyles({ isMobile: isMobile });

  useEffect(() => {
    const simulate = async () => {
      onLoading(true);
      try {
        const params: { currency: string; quantity: number; coupon?: string } = {
          currency: currencyCode.toLowerCase(),
          quantity: quantity
        };
        if (discountCoupon) {
          params.coupon = discountCoupon;
        }
        const result = await simulateSubscription(planName, params);
        setInvoice(result.upcomingInvoice);
        onUpcomingInvoice(result.upcomingInvoice);
        if (discountCoupon) {
          onDiscountCouponApplied(true);
          onSnackbarToShow("discountApplied");
        } else {
          onDiscountCouponApplied(false);
        }
      } catch (e) {
        console.error(e);
        if (discountCoupon) {
          onSnackbarToShow("discountRejected", null, "error");
          onDiscountCouponApplied(false);
          onDiscountCoupon("");
        }
      }
      onLoading(false);
    };

    if (planName && currencyCode) {
      simulate();
    }
  }, [planName, quantity, currencyCode, discountCoupon]);

  const hasProration = () => {
    return invoice?.lines.data.some((item: InvoiceLinesData) => item.proration);
  };

  const hasDiscount = () => {
    return invoice?.discount?.coupon;
  };

  const getStartDate = () => {
    if (invoice) {
      return formatDate((invoice.period_start * 1000).toString(), false, false, true);
    }
    return "";
  };

  return (
    <Box>
      <Box>
        <Typography variant="h1">Summary</Typography>
      </Box>
      <Box mt={6}>
        <Box display="flex" alignItems="center" pb={4}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <LogoSimple width="65" height="65" className={classes.logo} />
            </Box>
            <Box ml={4}>
              <Typography variant="body2" className="strong">
                Akord {planName}
              </Typography>
              <Typography variant="body2">Monthly subscription</Typography>
              <Typography variant="body2">Effective on {getStartDate()}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      {invoice && !loading && (
        <Box>
          {hasProration() && (
            <Box>
              <Box display="flex" justifyContent="space-between" pb={2} mt={2}>
                <Typography variant="body2" sx={{ marginRight: 2 }}>
                  {invoice.lines.data[1].description}
                </Typography>
                {invoice && <Typography variant="body2">{renderPrice(invoice.lines.data[1].amount, invoice.currency)}</Typography>}
              </Box>
              <Box display="flex" justifyContent="space-between" pb={2} mt={2}>
                <Typography variant="body2" sx={{ marginRight: 2 }}>
                  {invoice.lines.data[0].description}
                </Typography>
                {invoice && <Typography variant="body2">{renderPrice(invoice.lines.data[0].amount, invoice.currency)}</Typography>}
              </Box>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" pb={2} mt={2}>
            <Typography variant="body2" sx={{ marginRight: 2 }}>
              Subtotal
            </Typography>
            {invoice && <Typography variant="body2">{renderPrice(invoice.subtotal, invoice.currency)}</Typography>}
          </Box>
          {hasDiscount() ? (
            <Box display="flex" justifyContent="space-between" pb={2} mt={2}>
              <Typography
                variant="body2"
                sx={{ marginRight: 2 }}
              >{`${invoice.discount.coupon.name} (${invoice.discount.coupon.percent_off}% off)`}</Typography>
              {invoice && (
                <Typography variant="body2">{renderPrice(-invoice.total_discount_amounts[0].amount, invoice.currency)}</Typography>
              )}
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-between" pb={2} mt={2}>
              <Typography variant="body2" sx={{ marginRight: 2 }}>
                Total excluding tax
              </Typography>
              {invoice && <Typography variant="body2">{renderPrice(invoice.subtotal_excluding_tax, invoice.currency)}</Typography>}
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" pb={2} mt={2}>
            <Typography variant="body2" sx={{ marginRight: 2 }}>
              {`VAT (20% incl. on ${renderPrice(invoice.total_tax_amounts[0]?.amount, invoice.currency)})`}{" "}
            </Typography>
            {invoice && <Typography variant="body2">{renderPrice(invoice.total_tax_amounts[0]?.amount, invoice.currency)}</Typography>}
          </Box>
          <Box display="flex" justifyContent="space-between" pb={2} mt={2}>
            <Typography variant="h3" sx={{ marginRight: 2 }}>
              Total
            </Typography>
            {invoice && <Typography variant="h3">{renderPrice(invoice.total, invoice.currency)}</Typography>}
          </Box>
          <Divider />
        </Box>
      )}
      {loading && (
        <Box>
          {Array.from(new Array(4)).map((item, index) => (
            <Skeleton key={index} variant="rectangular" height={28} sx={{ borderRadius: 0, marginTop: 2, paddingBottom: 1 }} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionCheckoutSummary;
