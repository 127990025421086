import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, FormControl, TextField, Box } from "@mui/material";
import { AccountBackupIcon } from "@akord/addon-icons";
import { useRecoverAccountContext } from "../../contexts/RecoverAccountContext";

const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: "white"
  },
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

const RecoverEmail: React.FC = () => {
  const classes = useStyles();
  const { handleFormChange, formData } = useRecoverAccountContext();

  return (
    <>
      <Box mb={3} className={classes.rootWithIcon}>
        <Typography variant="h1">Recover account</Typography>
        <AccountBackupIcon fontSize="large" color="primary" />
      </Box>

      <Box className={classes.root}>
        <Box mb={5}>
          <Typography variant="body2">
            To recover your account and reset your password, we first need to verify your email address. You will then need your 12-word
            recovery phrase to recover your account.
          </Typography>
        </Box>
        <FormControl fullWidth>
          <TextField
            label="Email address"
            variant="outlined"
            name="userEmail"
            sx={{ marginBottom: 0 }}
            value={formData.userEmail}
            onChange={handleFormChange("userEmail")}
          />
        </FormControl>
      </Box>
    </>
  );
};

export default RecoverEmail;
