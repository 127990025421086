import { AkordWallet } from "@akord/crypto";
import { Auth } from "@akord/akord-js";
import { SignUpStepsOptions } from "../../types/globalTypes";
import { History } from 'history';
import { HandleNextProps } from ".";
import { SignUpFormDataProps, ZXCVBNResultExtended } from "../../types/signUpTypes";

const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// no spaces first/last char
const regexPassword = /^[\S]+.*[\S]+$/;

type stepsDataProps = {
  [K in SignUpStepsOptions]: SignUpStepsData
}

interface SignUpStepsData {
  isButtonDisabled?: (formData: SignUpFormDataProps, passEvaluation: ZXCVBNResultExtended) => boolean
  handleNext: (props: HandleNextProps) => void
  handleBack: (history: History) => void
  handleEnd?: (history: History) => void
  nextText?: string
  inverted: boolean
  hideBackButton?: boolean
  fullWidthNextButton?: boolean
  hideIcon: boolean
  showBottomNav: boolean
  darkMode: boolean
  spaceBetween?: boolean | null
}

export const stepsData: stepsDataProps = {
  signup: {
    isButtonDisabled: (formData, passEvaluation) => {
      return !(
        formData.userEmail.length > 0 &&
        regexEmail.test(formData.userEmail) &&
        formData.userPassword.length >= 12 &&
        regexPassword.test(formData.userPassword) &&
        passEvaluation?.score >= 4 &&
        formData.acceptTerms &&
        formData.withdrawalRight
      );
    },
    handleNext: async ({ formData, handleError, handleWallet, steps, currentStep, path, history }) => {
      try {
        const res = await AkordWallet.create(formData.userPassword.trim());
        handleWallet(res);
        nextPage(steps, currentStep, path, history);
      } catch (err) {
        handleError(err);
      }
    },
    handleBack: history => history.goBack(),
    nextText: "Next",
    inverted: false,
    hideBackButton: false,
    fullWidthNextButton: false,
    hideIcon: false,
    showBottomNav: true,
    darkMode: true
  },
  "backup-phrase": {
    isButtonDisabled: () => false,
    handleNext: async ({ formData, handleError, steps, currentStep, path, wallet, history, setIsLoading }) => {
      try {
        setIsLoading(true)
        const username: string = formData.userEmail.toLowerCase().trim();
        const password: string = formData.userPassword.trim();
        const referrerId: string | undefined = formData.referrerId?.trim();
        const { host, protocol } = window.location;
        const verifyUrl = `${protocol}//${host}/login`;
        await Auth.signUp(username, password, { verifyUrl: verifyUrl, wallet: wallet, referrerId: referrerId });
        await AkordWallet.clear();
        setIsLoading(false)
        nextPage(steps, currentStep, path, history)
      } catch (err) {
        setIsLoading(false)
        handleError(err);
      }
    },
    handleBack: history => history.goBack(),
    spaceBetween: null,
    darkMode: true,
    nextText: "Setup account",
    inverted: false,
    hideBackButton: true,
    fullWidthNextButton: true,
    hideIcon: true,
    showBottomNav: true
  },
  "account-created": {
    isButtonDisabled: () => false,
    handleNext: () => null,
    handleBack: history => history.goBack(),
    handleEnd: history => history.push("/"),
    inverted: true,
    hideBackButton: true,
    fullWidthNextButton: true,
    hideIcon: true,
    showBottomNav: false,
    darkMode: true,
  },
  "backup-info": {
    isButtonDisabled: () => false,
    handleNext: () => null,
    handleBack: () => null,
    inverted: false,
    hideIcon: false,
    showBottomNav: false,
    darkMode: true
  }
};

const nextPage = (steps: string[], currentStep: string, path: string, history: History) => {
  const nextStepIndex = steps.indexOf(currentStep) + 1;
  const routeName = steps[nextStepIndex];
  history.push(`${path}/${routeName}`);
};
