import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Box, Typography, Link } from "@mui/material";
import { ClipboardIcon, DownloadIcon, AccountBackupIcon } from "@akord/addon-icons";
import { useSignupContext } from "../../contexts/SignupContext";
import { downloadTxtFile, copyToClipboard } from "../../helpers/helpers";
import BackupPhraseBox from "../../components/common/BackupPhraseBox";

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  containerText: {
    lineHeight: "32px"
  },
  rootIcon: {
    marginRight: theme.spacing(3)
  }
}));

const BackUpPhrase: React.FC<RouteComponentProps> = ({ history }) => {
  const { formData, isDesktop, wallet } = useSignupContext();

  const backupPhraseArray = wallet?.backupPhrase?.split(" ");

  const classes = useStyles();

  const [copyClick, setCopyClick] = useState(false);
  const handleCopyClick = (value: boolean) => setCopyClick(value);

  if (!backupPhraseArray) history.push("/signup");

  return (
    <Box className={classes.root} sx={{ flexGrow: !isDesktop ? 1 : "unset" }}>
      <Box mb={3} className={classes.rootWithIcon}>
        <Typography variant="h1">Recovery phrase</Typography>
        <AccountBackupIcon fontSize="large" color="primary" />
      </Box>

      <Box className={classes.root} sx={{ flexGrow: !isDesktop ? 1 : "unset" }}>
        <Box>
          <Box mb={3}>
            <Typography variant="body2">
              Below is your recovery phrase – the keys to your data and your account backup if you forget your password.
            </Typography>
          </Box>
          <Box mb={6}>
            <Link variant="body2" onClick={() => history.push("/signup/backup-info")} underline="always" color="text.tertiary">
              Learn about account backup
            </Link>
          </Box>
          <Box mb={5}>
            <BackupPhraseBox backupPhraseArray={backupPhraseArray} />
          </Box>
          <Box className={classes.containerText}>
            <DownloadIcon fontSize="small" color="action" sx={{ marginRight: theme => theme.spacing(3) }} />
            <Typography
              component="a"
              variant="body2"
              color="text.primary"
              onClick={() => {
                downloadTxtFile(backupPhraseArray, formData.userEmail);
              }}
            >
              Download
            </Typography>
            <br />
            <ClipboardIcon fontSize="small" color="action" sx={{ marginRight: theme => theme.spacing(3) }} />
            <Typography
              component="a"
              variant="body2"
              color="text.primary"
              onClick={() => copyToClipboard(backupPhraseArray?.join(" "), handleCopyClick)}
            >
              {copyClick ? "Copied!" : "Copy to clipboard"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(BackUpPhrase);
