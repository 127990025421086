import React, { useState, useContext, useCallback } from "react";
import zxcvbn from "zxcvbn";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ZXCVBNResultExtended, MissingWords } from "../types/signUpTypes";

interface RecoverAccountProps {
  formData: RecoverAccountFormData;
  handleFormChange: (formType: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFormData: React.Dispatch<React.SetStateAction<RecoverAccountFormData>>;
  passEvaluation: ZXCVBNResultExtended;
  handlePassEvaluation: (evaluation: ZXCVBNResultExtended) => void;
  handlebackupPhrase: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => void;
  onPasteBackupPhrase: (backupPhraseObject: MissingWords) => void;
  backupPhrase: MissingWords;
  checkBackupPhrase: () => boolean;
  error: any;
  handleError: (error: any) => void;
}

export interface RecoverAccountFormData {
  userEmail: string;
  userPassword: string;
  code: string;
  userName: string;
  address: string;
}

const RecoverAccountContext = React.createContext<RecoverAccountProps>({} as RecoverAccountProps);

const RecoverAccountContextProvider: React.FC<React.ReactNode & RouteComponentProps> = ({ children }) => {
  const [error, setError] = useState(null);
  const handleError = (error: any) => setError(error);

  const [passEvaluation, setPassEvaluation] = useState({} as ZXCVBNResultExtended);
  const handlePassEvaluation = (evaluation: ZXCVBNResultExtended) => setPassEvaluation(evaluation);

  const [formData, setFormData] = useState<RecoverAccountFormData>({
    userEmail: "",
    userPassword: "",
    code: "",
    userName: "",
    address: ""
  });

  const handleFormChange = (formType: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.value;
    if (formType === "userPassword") {
      const evaluation = zxcvbn(value);
      handlePassEvaluation(evaluation);
    }
    setFormData({ ...formData, [formType]: value });
  };

  const [backupPhrase, setBackupPhrase] = useState<MissingWords>({} as MissingWords);
  const handlebackupPhrase = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    setBackupPhrase({ ...backupPhrase, [index]: event.target.value.trim() });
  };
  const handlePasteBackupPhrase = (backupPhraseObject: MissingWords) => {
    setBackupPhrase(backupPhraseObject);
  };

  const checkBackupPhrase = useCallback(() => {
    const checkWordLength = !Object.values(backupPhrase).includes("");
    return Object.keys(backupPhrase).length === 12 && checkWordLength;
  }, [backupPhrase]);

  React.useEffect(() => {
    handleError(null);
  }, [backupPhrase]);

  return (
    <RecoverAccountContext.Provider
      value={{
        formData: formData,
        handleFormChange: handleFormChange,
        setFormData: setFormData,
        passEvaluation: passEvaluation,
        handlePassEvaluation: handlePassEvaluation,
        handlebackupPhrase: handlebackupPhrase,
        onPasteBackupPhrase: handlePasteBackupPhrase,
        backupPhrase,
        checkBackupPhrase,
        error: error,
        handleError: handleError
      }}
    >
      {children}
    </RecoverAccountContext.Provider>
  );
};
export default withRouter(RecoverAccountContextProvider);

export const useRecoverAccountContext = () => useContext(RecoverAccountContext);
