import React from "react";
import { Box, TextField } from "@mui/material";
import { countries } from "../../../helpers/akordTexts";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import AutoCompleteSelect from "./AutoCompleteSelect";

type CountryAutoCompleteSelectProps = {
  usePhone?: boolean;
  useLabel?: boolean;
  useFlag: boolean;
  codeValue: string;
  props: { disableClearable: boolean; onChange: (event: any, value: any) => void };
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode?.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const CountryAutoCompleteSelect: React.FC<CountryAutoCompleteSelectProps> = ({
  usePhone = false,
  useLabel = false,
  useFlag,
  codeValue,
  props = { disableClearable: false, onChange: (event: any, value: any) => {} }
}) => {
  const { isMobile } = useGlobalContext();

  return (
    <AutoCompleteSelect
      {...props}
      options={countries}
      value={codeValue ? countries.find(country => country.code === codeValue) : null}
      getOptionLabel={option => (usePhone ? option.code + "+" + option.phone : option.label)}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {useFlag && <span>{countryToFlag(option.code)}</span>}
          {useLabel && option.label}
          {usePhone && option.code + " +" + option.phone}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          required
          label={usePhone && !isMobile ? "Country code" : "Country"}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password" // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
export default CountryAutoCompleteSelect;
