import React from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import { DataRoomFilter, EmptySpace } from "../../components/common";
import VaultItem from "./ValutListItem";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { memberStatusEnum } from "../../helpers/akord-enums";
import { Vault } from "@akord/akord-js";

type ActiveVaultListProps = {
  roomRef: React.RefObject<HTMLDivElement>;
};

const ActiveVaultList: React.FC<ActiveVaultListProps> = ({ roomRef }) => {
  const {
    activeVaults,
    sortedActiveVaults,
    onRequestSortVaults,
    orderVaults,
    orderVaultsBy,
    pendingVaults,
    pendingMemberships,
    processingVaults,
    // pendingFiles,
    vaultsIsLoading,
    isMobile
  } = useGlobalContext();

  return (
    <div
      ref={roomRef}
      style={{
        width: "100%",
        height: isMobile ? "calc(100% - 59px)" : "100%",
        overflowY: "auto",
        position: "absolute",
        paddingTop: "24px"
      }}
    >
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
          <DataRoomFilter order={orderVaults} orderBy={orderVaultsBy} onRequestSort={onRequestSortVaults} data={activeVaults as Vault[]} />
          {(processingVaults?.length > 0 ||
            pendingMemberships?.length > 0 ||
            (activeVaults && activeVaults?.length > 0) ||
            vaultsIsLoading) && (
            <TableBody>
              {processingVaults?.length > 0 && processingVaults.map((vault, index) => <VaultItem key={index} vault={vault} />)}
              {pendingMemberships?.length > 0 &&
                pendingMemberships.map((membership, index) => (
                  <VaultItem key={index} membership={membership} vault={pendingVaults?.find(vault => vault.id === membership.vaultId)} />
                ))}
              {activeVaults &&
                activeVaults?.length > 0 &&
                sortedActiveVaults.map((vault, index) => <VaultItem key={index} vault={vault} />)}
              {activeVaults?.length === 0 && vaultsIsLoading && Array.from(new Array(8)).map((item, index) => <VaultItem key={index} />)}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pendingMemberships?.length > 0 &&
        activeVaults?.length === 0 &&
        pendingMemberships.some(membership => membership.status === memberStatusEnum.INVITED && !membership.dataRoom?.public) && (
          <EmptySpace dataText="pendingVault" />
        )}
      {/* {pendingFiles?.length > 0 && <EmptySpace dataText="pendingStackVersion" spaceData={pendingFiles} />} */}
      {!vaultsIsLoading && activeVaults?.length === 0 && processingVaults?.length === 0 && pendingMemberships?.length === 0 && (
        <EmptySpace dataText="archivedRooms" />
      )}
    </div>
  );
};

export default ActiveVaultList;
