import React from "react";
import { Auth } from "@akord/akord-js";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton, Theme } from "@mui/material";
import { CloseIcon } from "@akord/addon-icons";
import { blue } from "../../theme/colors/index";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

type LegacyBannerProps = {
  dataRoomId: string | null;
  onBannerHeight: (value: number) => void;
};

type LegacyBannerStylesProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, LegacyBannerStylesProps>(theme => ({
  bannerDiv: {
    backgroundColor: ({ darkMode }) => (darkMode ? blue[700] : blue[600])
  },
  bannerText: {
    color: theme.palette.primary.contrastText,
    paddingTop: 2
  },
  closeIcon: {
    color: theme.palette.primary.contrastText
  }
}));

const LegacyBanner: React.FC<LegacyBannerProps> = ({ dataRoomId, onBannerHeight }) => {
  const { darkMode, userAttributes, onUserAttributes } = useGlobalContext();
  const classes = useStyles({
    darkMode: darkMode
  });
  const bannerRef = React.useRef<HTMLLIElement>();

  const calcBannerHeight = () => {
    const bannerHeight = bannerRef?.current?.offsetHeight;
    bannerHeight && onBannerHeight(bannerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", calcBannerHeight);
  });

  const handleCloseWarning = async () => {
    if (!dataRoomId) return;
    const legacyVaultsNotifiedCopy: string[] = userAttributes.legacyVaultsNotified || [];
    legacyVaultsNotifiedCopy.push(dataRoomId);
    await Auth.updateUserAttribute("custom:legacyVaultsNotified", legacyVaultsNotifiedCopy.join(","));
    onUserAttributes({ legacyVaultsNotified: legacyVaultsNotifiedCopy });
  };

  return (
    <Box px={4} py={2} className={classes.bannerDiv} justifyContent="space-between" display="flex" alignContent="center" ref={bannerRef}>
      <Typography variant="caption" className={classes.bannerText}>
        This is a legacy vault and is not storing data permanently. Any further uploads within this vault will remain in cloud storage.
      </Typography>
      <IconButton onClick={handleCloseWarning} size="large">
        <CloseIcon classes={{ root: classes.closeIcon }} />
      </IconButton>
    </Box>
  );
};
export default LegacyBanner;
