import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";
import { withWallet, useGlobalContext } from "../../contexts/GlobalDataProvider";
import { MAINTENANCE_MODE_ENABLED } from "../../helpers/env";

function LoggedInRoute({ component: Component, location, ...rest }) {
  const { isAuthenticated, isAuthLoaded } = useGlobalContext();

  // check if its an airdrop link
  const fromUrlParams = new URLSearchParams(location?.search);
  const isPasswordlessLogin = !!fromUrlParams.get("wallet");

  if (!isAuthLoaded && !isPasswordlessLogin) return null;

  return (
    <Route
      {...rest}
      render={props => {
        if (/true/.test(MAINTENANCE_MODE_ENABLED)) {
          return <Redirect to="/maintenance" />;
        }
        const redirectPath = props.location.pathname;
        const queryToken = queryString.parse(props.location.search);
        // If user is NOT logged in, resolve
        if ((queryToken.membershipId || redirectPath) && !isAuthenticated) {
          // Set original location to the storage to use on reload
          if (props?.location?.search.includes("?wallet=")) sessionStorage.setItem("AirdropLocation", JSON.stringify(props.location));
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }
        return <Component {...props} {...rest} />;
      }}
    />
  );
}

export default withRouter(withWallet(LoggedInRoute));
