import React from "react";
import { Typography } from "@mui/material";

function ErrorOperation(props) {
  const { positionedBeforeDate } = props;

  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        className="small"
        paragraph
        style={{ marginBottom: positionedBeforeDate ? "48px" : "16px" }}
      >
        {props.operation && props.operation.actionRef
          ? props.operation.actionRef
          : "Unknown action"}
      </Typography>
    </>
  );
}

export default ErrorOperation;
