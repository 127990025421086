import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Select, SelectChangeEvent, SxProps, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TriangleDownIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { grey } from "../../../theme/colors";

export type SelectOption = {
  key: string;
  value: string;
};

type SelectProps = {
  options: SelectOption[];
  onSelect: (optionValue: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

type SelectStylesProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, SelectStylesProps>(theme => ({
  selectMenu: {
    height: "1.4375em!important",
    minHeight: "1.4375em!important",
    fontSize: "14px",
    lineHeight: "1.4375em",
    color: theme.palette.text.secondary,
    minWidth: "78px",
    border: "none"
  },
  select: {
    "&:focus": {
      background: ({ darkMode }) => (darkMode ? "#333" : "#faf7f6")
    }
  },
  selectRoot: {
    color: theme.palette.text.primary,
    paddingRight: "12px!important",
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  iconOpen: {
    color: `${theme.palette.primary.main}!important`
  },
  arrowIcon: {
    color: theme.palette.text.secondary,
    marginRight: "-6px",
    marginTop: "2px"
  },
  menuItem: {
    "&:last-of-type": {
      paddingBottom: 12
    },
    "&:first-of-type": {
      paddingTop: 12
    },
    alignItems: "start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  selected: {
    background: `${grey[700]}!important`
  },
  menuList: {
    backgroundColor: theme.palette.background.secondary,
    maxWidth: "293px"
  },
  adornedEnd: {
    paddingRight: 0
  },
  paper: {
    marginTop: theme.spacing(2)
  }
}));

const OutlinedSelect = (props: SelectProps) => {
  const { darkMode } = useGlobalContext();
  const classes = useStyles({
    darkMode: darkMode
  });

  const [value, setValue] = useState(props.options[0].value);

  useEffect(() => {
    setValue(props.defaultValue || props.options[0].value);
  }, [props.defaultValue]);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    props.onSelect(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: "auto" }} size="medium">
      <Select
        name="select"
        labelId="select-outlined-label"
        id="select-outlined"
        IconComponent={TriangleDownIcon}
        classes={{
          select: classes.selectMenu,
          outlined: classes.select,
          iconOpen: classes.iconOpen
        }}
        MenuProps={{
          elevation: 4,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          classes: {
            list: classes.menuList,
            paper: classes.paper
          }
        }}
        inputProps={{
          classes: {
            root: classes.selectRoot,
            icon: classes.arrowIcon
          }
        }}
        variant="outlined"
        value={value}
        onChange={handleChange}
        disabled={props.disabled}
        sx={{ height: 32, ...(props.sx || {}) }}
        className={classes.select}
      >
        {props.options?.map(option => (
          <MenuItem key={option.key} value={option.value} classes={{ selected: classes.selected }} className={classes.menuItem}>
            <Typography variant="body2" fontSize="small" color="text.primary">
              {option.key}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OutlinedSelect;
