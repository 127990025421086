import { ArweaveIcon, InfoIcon, OpenSeaIcon, RadioCheckedIcon, RadioEmptyIcon } from "@akord/addon-icons";
import { Box, Radio, Typography, Theme, Link, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import React from "react";
import { akordLinks } from "../../../helpers/akordTexts";
import Label from "../Label";

const useStyles = makeStyles<Theme>(theme => ({
  box: {
    border: "1px solid",
    borderColor: theme.palette.background.cardBorder,
    borderRadius: "4px",
    padding: "12px",
    display: "flex",
    alignItems: "flex-start",
    cursor: "pointer"
  },
  radio: {
    marginTop: "4px",
    padding: "0 8px 0 0",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-checked:hover": {
      backgroundColor: "transparent"
    }
  }
}));

type EventLikeObject = {
  target: { value: "Arweave" | "Opensea" };
};

type ManifestOptionsContentProps = {
  selectedManifestValue: "Arweave" | "Opensea" | undefined;
  onSelectedManifestValue: React.Dispatch<React.SetStateAction<"Arweave" | "Opensea" | undefined>>;
};

const ManifestOptionsContent: React.FC<ManifestOptionsContentProps> = ({ selectedManifestValue, onSelectedManifestValue }) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | EventLikeObject) => {
    onSelectedManifestValue(event.target.value as "Arweave" | "Opensea");
  };

  return (
    <Box>
      <Box
        mt={5}
        display="flex"
        justifyContent="space-between"
        onClick={() => handleChange({ target: { value: "Arweave" } })}
        className={classes.box}
        sx={{
          backgroundColor: selectedManifestValue === "Arweave" ? "background.card" : "transparent"
        }}
      >
        <Box display="flex" alignItems="center">
          <ArweaveIcon sx={{ marginRight: 3 }} />
          <Typography variant="body2" className="strong" sx={{ marginRight: 2 }}>
            Arweave manifest
          </Typography>
          <Tooltip
            title="On Arweave, each file gets a transaction ID by default, making it hard to find related files. Manifests link multiple transactions under one base ID with readable filenames. This grouping enables practical applications like website hosting on Arweave."
            arrow
          >
            <span style={{ display: "flex" }}>
              <InfoIcon fontSize="small" color="secondary" />
            </span>
          </Tooltip>
        </Box>
        <Radio
          checked={selectedManifestValue === "Arweave"}
          onChange={handleChange}
          value="Arweave"
          size="small"
          icon={<RadioEmptyIcon />}
          checkedIcon={<RadioCheckedIcon />}
          className={classes.radio}
          disableRipple
          disableFocusRipple
          name="radio-buttons"
          inputProps={{ "aria-label": "Arweave manifest" }}
        />
      </Box>
      <Box
        mt={5}
        display="flex"
        justifyContent="space-between"
        // onClick={() => handleChange({ target: { value: "Opensea" } })}
        className={classes.box}
        sx={{
          backgroundColor: selectedManifestValue === "Opensea" ? "background.card" : "transparent",
          cursor: "inherit!important"
        }}
      >
        <Box display="flex">
          <OpenSeaIcon sx={{ marginRight: 3 }} />
          <Typography variant="body2" className="strong" color="text.secondary">
            OpenSea (ERC721 & 1155)
          </Typography>
        </Box>
        <Label text="Soon" smallLabel secondary noMargins />
        {/* //TODO Uncomment when ready */}
        {/* <Radio
          checked={selectedManifestValue === "Opensea"}
          onChange={handleChange}
          value="Opensea"
          size="small"
          icon={<RadioEmptyIcon />}
          checkedIcon={<RadioCheckedIcon />}
          className={classes.radio}
          disableRipple
          disableFocusRipple
          name="radio-buttons"
          inputProps={{ "aria-label": "Opensea manifest" }}
        /> */}
      </Box>
      <Box display="flex" justifyContent="center" mt={5}>
        <Link variant="body2" className="small" color="text.secondary" onClick={() => window.open(akordLinks["manifestRequestForm"])}>
          Request a standard →
        </Link>
      </Box>
    </Box>
  );
};

export default ManifestOptionsContent;
