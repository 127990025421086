import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Badge, Box, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { InfoIcon, TickIcon } from "@akord/addon-icons";
import { Stack } from "@akord/akord-js";
import { formatDate, getVaultId, getVaultStatus, getFolderId, getFileIcon } from "../../helpers/helpers";
import { storageTransactionStatus } from "../../helpers/akord-enums";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { MenuStack, MoreActionButton, StackDrawer } from "../../components/common";
import { bytesToGb } from "../storage/storage-helper";
import { grey, orange } from "../../theme/colors";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";

type StackItemStylesProps = {
  archived: boolean;
  itemChecked: boolean;
  isVaultPublic: boolean;
};

const useStyles = makeStyles<Theme, StackItemStylesProps>(theme => ({
  iconBackGround: {
    background: ({ archived, itemChecked, isVaultPublic }) =>
      archived
        ? itemChecked
          ? grey[500]
          : theme.palette.background.archived
        : isVaultPublic
        ? itemChecked
          ? theme.palette.info.main
          : theme.palette.background.stack
        : itemChecked
        ? theme.palette.secondary.main
        : theme.palette.background.active,
    color: ({ archived, itemChecked, isVaultPublic }) =>
      archived
        ? itemChecked
          ? "#FFF"
          : grey[500]
        : isVaultPublic
        ? itemChecked
          ? "#FFF"
          : theme.palette.info.main
        : itemChecked
        ? "#FFF"
        : theme.palette.primary.main,
    boxSizing: "content-box",
    padding: "3px",
    marginRight: "15px",
    borderRadius: "2px"
  },
  toolLine: {
    display: "flex",
    justifyContent: "space-between",
    height: "56px",
    alignItems: "center"
  },
  orangeBadge: {
    padding: 0,
    backgroundColor: orange[400]
  },
  yellowBadge: {
    padding: 0,
    backgroundColor: "#FFEB53"
  },
  pinkBadge: {
    padding: 0,
    backgroundColor: "#F3CDFE"
  },
  redBadge: {
    padding: 0,
    backgroundColor: "#E43C33"
  },
  checkBox: {
    padding: 0
  }
}));

const columnsToShow = (stack?: Stack, stackSize?: number, width?: "sm" | "xs" | "md" | "lg" | "xl") => {
  const stackSizeToShow = bytesToGb(stack?.size ? stack.size : stackSize);

  switch (true) {
    case width === "xs":
      return null;
    case !!stack:
      switch (true) {
        case width === "sm":
          return (
            <TableCell align="left">
              <Typography variant="body2" color="text.primary" noWrap>
                {formatDate(stack?.updatedAt || stack?.modifiedAt)}
              </Typography>
            </TableCell>
          );
        default:
          return (
            <>
              <TableCell align="left">
                <Typography variant="body2" color="text.primary" noWrap>
                  {formatDate(stack?.updatedAt || stack?.modifiedAt)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                {stackSizeToShow.size ? stackSizeToShow.size + stackSizeToShow.sizeType.toUpperCase() : "-"}
              </TableCell>
              <TableCell align="left">{stack?.versions.length}</TableCell>
            </>
          );
      }
    default:
      return (
        <>
          <TableCell>
            <Skeleton style={{ height: "30px" }} />
          </TableCell>
          {width === "sm" ? (
            <TableCell />
          ) : (
            <>
              <TableCell>
                <Skeleton style={{ height: "30px" }} />
              </TableCell>
              <TableCell>
                <Skeleton style={{ height: "30px" }} />
              </TableCell>
            </>
          )}
        </>
      );
  }
};

interface LocationState extends Location {
  itemId?: string;
}

type StackItemProps = {
  stack?: Stack;
  revoked?: boolean;
  isPublicRoute?: boolean;
  forwardRef?: (ref: HTMLElement) => void;
  forwardProps?: any;
  forwardStyle?: {};
};

const StackItem: React.FC<StackItemProps> = ({ stack, revoked = false, isPublicRoute = false, forwardRef, forwardProps, forwardStyle }) => {
  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const openMenu = Boolean(anchorEl);

  const [iconHovered, setIconHovered] = React.useState(false);
  const handleIconHovered = (action: boolean) => setIconHovered(action);

  const { width, isMobile, onShowInfo } = useGlobalContext();
  const { onCurrentStack, isRoomArchived, isVaultPublic } = useVaultContext();
  const { onSelectedItems, selectedItemsMap, onLastSelectedItem } = useAssetsContext();
  const { onNotificationData, notificationData } = useNotificationsContext();

  const history = useHistory();
  const location = useLocation<LocationState>();
  const theme = useTheme();

  const roomId = getVaultId(history.location.pathname);
  const folderId = getFolderId(history.location.pathname);
  const roomStatus = getVaultStatus(history.location.pathname);
  const itemId = location.state?.itemId;
  const itemRef = useRef<HTMLElement | null>(null);
  const itemChecked = selectedItemsMap?.has(stack?.id || "");

  const classes = useStyles({
    archived: isRoomArchived || revoked,
    itemChecked: itemChecked,
    isVaultPublic: isVaultPublic
  });

  useEffect(() => {
    if (itemId && stack && itemId === stack.id && itemRef?.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
  }, [stack, itemRef.current]);

  const setStackRef = (element: HTMLElement) => {
    itemRef.current = element;
    if (forwardRef) {
      forwardRef(element);
    }
  };

  const highlightItem = () => {
    if (itemRef?.current) {
      const item = itemRef.current;
      const initialBackground = "inherit";
      item.style.transition = "background-color 1s ease-in";
      item.style.background = theme.palette.background.stack!;
      setTimeout(() => {
        item.style.background = initialBackground;
      }, 1000);
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  //select icon for a file
  const FileIcon = getFileIcon(stack);

  //if its a stack we need to show ext from the latest vers.
  const createStackTitleWithOriginalExtension = () => {
    const splitOriginalTitle = stack?.name?.split(".");
    splitOriginalTitle?.pop();

    if (!stack?.versions?.[stack?.versions.length - 1]?.name) return stack?.name;

    const latestTitleArray = stack.versions[stack.versions.length - 1].name.split(".");
    const originalExtension = latestTitleArray[latestTitleArray.length - 1];
    return splitOriginalTitle?.join(".") + "." + originalExtension;
  };
  // Calculate size of the stack by files
  const stackSize = stack?.versions?.reduce((acc, file) => acc + file.size, 0);

  const getStatusTooltipText = (stack: Stack) => {
    const stackStatus = stack?.versions?.[stack.versions.length - 1]?.status;
    if (stackStatus === storageTransactionStatus.SCHEDULED || stackStatus === storageTransactionStatus.VERIFICATION) {
      return "Your file is with Akord, next we’ll bundle it with other files";
    } else if (stackStatus === storageTransactionStatus.BUNDLED) {
      return "Your file is being bundled before being sent to Arweave";
    } else if (stackStatus === storageTransactionStatus.PENDING) {
      return "Your file is now with Arweave, being processed by the network";
    } else if (stackStatus === storageTransactionStatus.REJECTED) {
      return "The file has been rejected by Arweave. You can remove the file and try again.";
    } else if (stackStatus === storageTransactionStatus.BLOCKED) {
      return "Your file was classified as malicious";
    } else if (stackStatus === storageTransactionStatus.COMMITTED) {
      return `${stack.name} successfully committed to the blockchain.`;
    } else {
      return "";
    }
  };

  const isStatusDotVisible = (stack: Stack) => {
    const stackStatus = stack?.versions?.[0]?.status;
    return stackStatus && stackStatus !== storageTransactionStatus.COMMITTED;
  };

  const getStatusDotClass = (stack: Stack) => {
    const stackStatus = stack?.versions?.[0]?.status;

    if (stackStatus === storageTransactionStatus.SCHEDULED || stackStatus === storageTransactionStatus.VERIFICATION) {
      return classes.orangeBadge;
    } else if (stackStatus === storageTransactionStatus.BUNDLED) {
      return classes.orangeBadge;
    } else if (stackStatus === storageTransactionStatus.PENDING) {
      return classes.yellowBadge;
    } else if (stackStatus === storageTransactionStatus.REJECTED) {
      return classes.redBadge;
    } else if (stackStatus === storageTransactionStatus.BLOCKED) {
      return classes.pinkBadge;
    } else {
      return "";
    }
  };

  const toggleIcon = () => handleIconHovered(!iconHovered);

  const handleSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    onSelectedItems(name, checked, revoked);
    onLastSelectedItem(name);
  };

  return (
    <>
      <TableRow hover={!!stack} ref={setStackRef} {...forwardProps} style={{ ...forwardStyle }}>
        {stack ? (
          <TableCell size="medium" style={{ cursor: revoked ? "inherit" : "pointer" }} scope="row">
            <Tooltip title={getStatusTooltipText(stack)}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  overlap="rectangular"
                  variant="dot"
                  classes={{ badge: getStatusDotClass(stack) }}
                  invisible={!isStatusDotVisible(stack)}
                >
                  {iconHovered || itemChecked ? (
                    <Checkbox
                      onMouseLeave={toggleIcon}
                      onMouseEnter={toggleIcon}
                      onChange={handleSelectItem}
                      checked={itemChecked}
                      name={stack.id}
                      className={classes.checkBox}
                      icon={<TickIcon fontSize="medium" classes={{ root: classes.iconBackGround }} />}
                      checkedIcon={<TickIcon fontSize="medium" classes={{ root: classes.iconBackGround }} />}
                    />
                  ) : (
                    <IconButton onMouseEnter={toggleIcon}>
                      <FileIcon fontSize="medium" classes={{ root: classes.iconBackGround }} />
                    </IconButton>
                  )}
                </Badge>
                <div
                  style={{ display: "grid" }}
                  onClick={() => {
                    if (
                      !stack.versions?.[stack.versions.length - 1]?.resourceUri ||
                      // !stack.versions?.[stack.versions.length - 1]?.resourceUri?.length === 0 ||
                      revoked
                    )
                      return;
                    let galleryUrl: string = "";
                    if (isPublicRoute) {
                      galleryUrl = `/public/vaults/${roomStatus}/${roomId}/gallery`;
                    } else if (!revoked) {
                      galleryUrl = `/vaults/${roomStatus}/${roomId}/gallery`;
                    }
                    if (folderId) {
                      galleryUrl += `/folders/${folderId}`;
                    }
                    galleryUrl += `#${stack.versions[stack.versions.length - 1].id}`;
                    history.push(galleryUrl);
                  }}
                >
                  <Typography variant="body2" color="text.primary" noWrap>
                    {stack.versions.length === 1 ? stack.name : createStackTitleWithOriginalExtension()}
                  </Typography>
                </div>
              </div>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell scope="row" size="medium">
            <Box display="flex">
              <Skeleton variant="rectangular" width={30} height={30} style={{ marginRight: "15px" }} />
              <Skeleton style={{ flex: "1" }} />
            </Box>
          </TableCell>
        )}

        {columnsToShow(stack, stackSize, width)}

        {stack ? (
          <TableCell align="right" size="medium">
            <Box display="flex" justifyContent="end">
              {!stack["__cloud__"] && (
                <IconButton
                  sx={{ mr: 2 }}
                  onClick={() => {
                    onNotificationData({ stack: stack, type: "stack" });
                    onShowInfo(true);
                  }}
                >
                  <InfoIcon color="secondary" />
                </IconButton>
              )}
              <MoreActionButton
                handleClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  handleMenu(e);
                  onCurrentStack(stack);
                }}
                menuActive={menuActive}
                arialLabel="display more actions file"
              />
            </Box>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      {isMobile ? (
        <StackDrawer
          openDrawer={openMenu}
          handleMenuClose={handleMenuClose}
          // revokedStack={revoked}
          // isPublicRoute={isPublicRoute}
        />
      ) : (
        <MenuStack
          anchorEl={anchorEl}
          openMenu={openMenu}
          handleMenuClose={handleMenuClose}
          // isPublicRoute={isPublicRoute}
          // revokedStack={revoked}
          passedStack={stack}
        />
      )}
    </>
  );
};

export default StackItem;
