import React from "react";
import { Auth } from "@akord/akord-js";
import { AkordWallet } from "@akord/crypto";
import { Box, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, Typography, TextField } from "@mui/material";
import { ButtonGroup, ErrorAlert, RevealToggler, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useHistory } from "react-router-dom";
import CopyBox from "../../components/common/InfoDrawer/CopyBox";
import { KeyIcon } from "@akord/addon-icons";
import qrcode from "qrcode";

const SecurityPhoneNumberMfa: React.FC = () => {
  const history = useHistory();
  const { isMobile } = useGlobalContext();
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [correctPassword, setCorrectPassword] = React.useState(false);
  const [setupKey, setSetupKey] = React.useState("");
  const [qr, setQr] = React.useState("");
  const [deviceName, setDeviceName] = React.useState("");

  React.useEffect(() => {
    const generateSetupKey = async () => {
      const key = await Auth.associateSoftwareToken();
      const attributes = await Auth.getUserAttributes();
      qrcode.toDataURL(`otpauth://totp/${attributes.email}?secret=${key}&issuer=Akord`, (err, imageUrl) => {
        if (err) {
          console.log("Error with QR");
          return;
        }
        setQr(imageUrl);
      });
      setSetupKey(key);
    };
    generateSetupKey();
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isDisabled = () => {
    return password?.length < 6 || !setupKey?.length || loading;
  };

  const setupMFA = async () => {
    try {
      setLoading(true);
      await checkCurrentPassword();
      history.push("/account/security/two-factor-authentication/totp/verify", { deviceName: deviceName });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const checkCurrentPassword = async () => {
    try {
      const attributes = await Auth.getUserAttributes();
      const encBackupPhrase = attributes["custom:encBackupPhrase"];
      await AkordWallet.importFromEncBackupPhrase(password, encBackupPhrase);
      setCorrectPassword(true);
    } catch (err) {
      setCorrectPassword(false);
      throw new Error("Incorrect password");
    }
  };

  const handleOnDeviceNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceName(event.target.value);
  };

  return (
    <SupportScreenWrapper title="Add 2FA authenticator app" route="/account/security/two-factor-authentication">
      <Box mt={5} mb={6}>
        <Typography variant="body2" color="text.primary" sx={{ marginBottom: 4 }}>
          Register your 2FA app e.g. Authy or Google Authenticator. Use the following:
        </Typography>
        <Box display={"flex"} width={isMobile ? "100%" : "550px"}>
          <Box width="100%">
            <CopyBox dataKey="Setup key" dataValue={setupKey} Icon={KeyIcon} isMobile={isMobile} />
            <Box marginBottom="32px">
              <img src={qr} alt="totp" />
            </Box>

            <TextField
              sx={{ width: "100%" }}
              label="Device name (optional)"
              variant="outlined"
              value={deviceName}
              onChange={handleOnDeviceNameChange}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        <Typography variant="body2" color="text.primary" sx={{ marginBottom: 4, width: "100%" }}>
          Enter your password to confirm this device as your 2FA.
        </Typography>
        <Box width={isMobile ? "100%" : "450px"}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="password-input">Password</InputLabel>
            <OutlinedInput
              spellCheck="false"
              id="password-input"
              aria-describedby="password-input"
              value={password}
              label="Password"
              onChange={ev => {
                setCorrectPassword(true);
                setPassword(ev.target.value);
              }}
              type={showPassword ? "text" : "password"}
              //classes={{ adornedEnd: classes.inputAdornment }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    <RevealToggler showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <ButtonGroup
        loading={loading}
        noMinWidth={true}
        nextText="Complete setup"
        nextDiasabled={isDisabled()}
        handleNext={() => setupMFA()}
        hideBackButton={true}
        fullWidthNextButton={isMobile ? true : false}
        hideIcon={true}
        disabledRed={false}
      />
      {!correctPassword && password?.length >= 6 && <ErrorAlert getErrorText="Wrong password. Please check and try again." />}
    </SupportScreenWrapper>
  );
};

export default SecurityPhoneNumberMfa;
