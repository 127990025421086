import React from "react";
import { Box, Button, Fab, Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Redirect, withRouter, Link, Route, Switch, RouteComponentProps } from "react-router-dom";
import { PlusIcon } from "@akord/addon-icons";
import { Types } from "@akord/gql";
import { BatchActionsBar, HideOnScroll } from "../../components/common";
import SearchBox from "../../components/search/SearchBox";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import ActiveVaultList from "./ActiveVaultList";
import ArchivedVaultList from "./ArchivedVaultList";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import PriorityNotification from "../../components/common/PriorityNotification";
import { useKeyPress } from "../../hooks/useKeyPress";
import { useSearchContext } from "../../contexts/SearchContextProvider";

const useStyles = makeStyles({
  fab: {
    position: "fixed",
    right: "16px",
    bottom: "76px"
  },
  headerBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  }
});

function a11yProps(index: number) {
  return {
    id: `room-tab-${index}`,
    "aria-controls": `room-tabpanel-${index}`
  };
}

const VaultListContainer: React.FC<RouteComponentProps> = ({ history, location }) => {
  const roomRef = React.useRef<HTMLDivElement>(null);
  const archiveRef = React.useRef<HTMLDivElement>(null);

  const { activeVaults, isMobile, readOnlyMode, seriousIssueMode } = useGlobalContext();
  const { notifications } = useInAppNotificationsContext();
  const { isSearchActive } = useSearchContext();

  const classes = useStyles();

  const priorityNotification =
    notifications && notifications.length
      ? notifications.filter(notification => notification.priority && notification.status === Types.NotificationStatus.UNREAD)
      : [];

  const showPrioratyNotification = priorityNotification?.length > 0 || seriousIssueMode;

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "v" && !isSearchActive) history.push("/create-vault/setup");
  };

  useKeyPress(["v"], onKeyPress);

  return (
    <>
      {seriousIssueMode ? (
        <PriorityNotification notification={{ event: "SERIOUS_ISSUE" } as Types.Notification} />
      ) : (
        priorityNotification?.length > 0 &&
        priorityNotification?.map((notification, index) => <PriorityNotification key={index} notification={notification} />)
      )}
      <Box
        position="fixed"
        width={isMobile ? "100%" : "calc(100% - 250px)"}
        zIndex="1"
        style={{
          top: isMobile && showPrioratyNotification ? "110px" : isMobile ? "44px" : showPrioratyNotification ? "50px" : 0
        }} // on mobile we need the top menu stay fixed and move this header down
      >
        <Box mx={5} mt={4} mb={!isMobile ? 1 : 3} className={classes.headerBox}>
          <Box width="20%">
            <Typography variant="h2">Vaults</Typography>
          </Box>
          {!isMobile && (
            <Box flexGrow="1" display="flex" justifyContent="flex-end">
              <SearchBox />
            </Box>
          )}
          {!isMobile && (
            <Box marginLeft="15px">
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => history.push("/create-vault/setup")}
                disabled={readOnlyMode}
              >
                New vault
              </Button>
            </Box>
          )}
        </Box>
        <Box>
          <Tabs textColor="secondary" value={location.pathname} aria-label="rooms tabs">
            <Tab label="Active" component={Link} to="/vaults/active" value="/vaults/active" disableRipple {...a11yProps(1)} />
            <Tab component={Link} label="Inactive" value="/vaults/inactive" to="/vaults/inactive" disableRipple {...a11yProps(1)} />
          </Tabs>
        </Box>
        {location.pathname.match("/vaults/inactive") && <BatchActionsBar folderId="null" isVaultPage={true} />}
      </Box>
      <Box
        flex="1"
        height={isMobile ? "calc(100% - 125px)" : showPrioratyNotification ? "calc(100% - 136px)" : "calc(100% - 86px)"}
        position="absolute"
        top={isMobile && showPrioratyNotification ? "177px" : isMobile ? "125px" : showPrioratyNotification ? "136px" : "86px"}
        width={isMobile ? "100%" : "calc(100% - 250px)"}
      >
        <Switch>
          <Route path="/vaults/active" exact render={() => <ActiveVaultList roomRef={roomRef} />} />
          <Route path="/vaults/inactive" exact render={() => <ArchivedVaultList archiveRef={archiveRef} />} />
          <Redirect to="/404" />
        </Switch>
        {activeVaults && activeVaults?.length > 0 && isMobile && location.pathname.match("/active") && (
          <HideOnScroll window={roomRef.current} fab={true}>
            <Fab
              size="medium"
              color="primary"
              aria-label="create-vault"
              className={classes.fab}
              onClick={() => history.push("/create-vault/setup")}
              disabled={readOnlyMode}
            >
              <PlusIcon fontSize="large" />
            </Fab>
          </HideOnScroll>
        )}
      </Box>
    </>
  );
};

export default withRouter(VaultListContainer);
