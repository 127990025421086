import React from "react";
import { CloseIcon } from "@akord/addon-icons";
import { Box, Chip, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ChipStyled = styled(Chip)(
  ({ theme }) => `
  display: flex;
  font-size: 0.9375rem;
  line-height: 1.43;
  align-items: left;
  height: 34px;
  margin-right: 6px;  
  background-color: ${theme.palette.background.archived};
  border: 1px solid ${theme.palette.background.cardBorder};
  border-radius: 2px;
  box-sizing: content-box;
  outline: 0;
  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  };
  .MuiChip-deleteIcon{
    color: ${theme.palette.background.icon};
    margin: 0;
  }
  .MuiChip-label{
    padding-right: 0;
  }
`
);

const TextFieldStyled = styled(TextField)(() => {
  return `
    max-width: 100%;
    .MuiInputBase-input {
      font-size: 0.8625rem;
      line-height: 1.45
    }
    .MuiInputBase-root {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      row-gap: 5px;
      padding: 8px 8px;
      input {
        max-width: 210px;
        min-width: 20px;
        width: 20px;
        flex-grow: 1;
        text-overflow: ellipsis;
        padding: 8px;
        align-self: center;
      }
    }
  `;
});

const TagTextInput = ({ tags = [], onTagChange, isVaultOwner }) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleKeyDown = event => {
    if (event.key === ",") {
      const newSelectedItem = [...tags];
      const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim());

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }
      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedItem.push(event.target.value.trim());
      onTagChange(newSelectedItem);
      setInputValue("");
    }
    if (tags?.length && !inputValue.length && event.key === "Backspace") {
      onTagChange(tags?.slice(0, tags?.length - 1));
    }
  };

  const handleDelete = tag => () => {
    const newSelectedItem = [...tags];
    newSelectedItem.splice(newSelectedItem.indexOf(tag), 1);
    onTagChange(newSelectedItem);
  };

  const handleChange = event => {
    if (event.target.value === ",") return;
    setInputValue(event.target.value);
  };

  return (
    <Box mb={7}>
      <TextFieldStyled
        disabled={!isVaultOwner}
        id="tags-input"
        fullWidth
        variant="outlined"
        value={inputValue}
        onChange={handleChange}
        inputProps={{
          onKeyDown: handleKeyDown
        }}
        InputProps={{
          startAdornment: tags?.map(tag => <ChipStyled key={tag} label={tag} onDelete={handleDelete(tag)} deleteIcon={<CloseIcon />} />),
          placeholder: tags?.length >= 1 ? "Add a tag..." : "Add a tag (separate by comma)"
        }}
        sx={{ marginBottom: 0 }}
      />
      {inputValue && tags.length === 0 && (
        <Box width="100%" position="relative">
          <Typography
            component="span"
            variant="body2"
            className="small"
            color="primary.light"
            sx={{ marginTop: 1 }}
            position="absolute"
            left="0"
          >
            Finish entering a tag with a comma.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TagTextInput;
