import React from "react";
import { Card, CardActionArea, CardContent, CardMedia, Skeleton, Typography } from "@mui/material";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Collection, NFT } from "@akord/akord-js";
import { getCollectionId, getResourceUri, getVaultId, truncateString } from "../../helpers/helpers";
import { AudioIcon, DocumentIcon, VideoIcon } from "@akord/addon-icons";
import { STORAGE_URL } from "../../helpers/env";

type NftItemProps = {
  nft: NFT | Collection | null;
};

const NftItem: React.FC<NftItemProps & RouteComponentProps> = ({ nft, history, location }) => {
  const { width } = useGlobalContext();

  const collectionId = getCollectionId(location.pathname);
  const vaultId = getVaultId(location.pathname);

  const previewUri =
    nft instanceof Collection
      ? getResourceUri(nft.banner?.resourceUri, "s3")
      : nft?.thumbnail
      ? getResourceUri(nft.thumbnail.resourceUri, "s3")
      : getResourceUri(nft?.asset.resourceUri, "s3");
  const nftType = (nft instanceof Collection ? nft.banner?.type : nft?.thumbnail ? nft.thumbnail.type : nft?.asset.type) || "";
  const nftSource = `${STORAGE_URL}/${previewUri}`;
  const isPublicRoute = !!location?.pathname.match("/public/"); // All anonymous routes have `/public` prefix

  const nftPath = isPublicRoute ? `/public/vaults/active/${vaultId}/nfts` : `/vaults/active/${vaultId}/nfts`;

  return (
    <Card
      sx={{
        flex: width === "xs" || width === "sm" ? "1 1 200px" : "1 1 19%",
        maxWidth: width === "xs" || width === "sm" ? "auto" : "20%",
        backgroundColor: "primary.iconInvert",
        border: "none"
      }}
    >
      <CardActionArea
        disabled={!nft}
        onClick={() => {
          if (nft?.type === "Collection") history.push({ pathname: `${nftPath}/collection/${nft.id}`, state: { collection: nft } });
          else if (collectionId) history.push({ pathname: `${nftPath}/collection/${collectionId}/${nft?.id}`, state: { nft: nft } });
          else history.push({ pathname: `${nftPath}/${nft?.id}`, state: { nft: nft } });
        }}
      >
        {nft ? (
          nftType.includes("image") ? (
            <CardMedia sx={{ height: 130 }} image={nftSource} title={nft?.name} />
          ) : (
            <CardMedia
              component="div"
              sx={{ display: "flex", backgroundColor: "background.secondary", justifyContent: "center", alignItems: "center", height: 130 }}
              title={nft?.name}
            >
              {nftType.includes("audio") ? (
                <AudioIcon sx={{ fontSize: "60px" }} color="disabled" />
              ) : nftType.includes("video") ? (
                <VideoIcon sx={{ fontSize: "60px" }} color="disabled" />
              ) : (
                <DocumentIcon fontSize="large" />
              )}
            </CardMedia>
          )
        ) : (
          <Skeleton sx={{ height: 130 }} animation="wave" variant="rectangular" />
        )}

        <CardContent sx={{ p: 3 }}>
          {nft ? (
            <>
              <Typography variant="body2" className={["small", "strong"].join(" ")} color="text.primary">
                {nft.name || "-"}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {nft.items ? `${nft.items.length} Atomic NFTs` : truncateString(nft.owner, 4)}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton animation="wave" height={25} />
              <Skeleton animation="wave" height={20} width="80%" />
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default withRouter(NftItem);
