import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, SelectChangeEvent, Skeleton, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter } from "react-router-dom";
import { SupportScreenWrapper } from "../../components/common";
import { getResourceUri, truncateString } from "../../helpers/helpers";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { useOrgContext } from "../../contexts/OrgContextProvider";
import { AVATAR_URL, STORAGE_URL } from "../../helpers/env";
import { AvatarIcon } from "@akord/addon-icons";
import { Types } from "@akord/gql";
import { OrganisationMemberRole } from "../../types/globalTypes";
import SelectMenu from "../../components/common/SelectMenu";
import Avatar from "../../components/common/Avatar/Avatar";

const useStyles = makeStyles<Theme>(theme => ({
  containedPrimary: {
    marginTop: theme.spacing(7),
    minWidth: "auto"
  },
  buttonProgress: {
    position: "absolute"
  },
  avatar: {
    width: "32px",
    height: "32px",
    borderRadius: "50%"
  },
  iconBig: {
    fontSize: "32px"
  }
}));

function OrganisationMembers() {
  const { orgAdmins, orgMembers, onOrgMembers, getOrgUsers } = useOrgContext();
  const { userOrg } = useOrgContext();
  const { isMobile, darkMode } = useGlobalContext();
  const { modal, onNotificationData, notificationData } = useNotificationsContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const classes = useStyles({ darkMode: darkMode });

  useEffect(() => {
    const load = async () => {
      await getOrgUsers();
      setLoading(false);
    };
    load();
  }, []);

  const handleFormChange = (e: SelectChangeEvent<any>, elIndex: number, user: Types.User) =>
    onOrgMembers(orgMembers.map(member => (member.address === user.address ? { ...member, role: e.target.value } : member)));

  const updateMembersRole = async () => {
    const changedRoleToSave = orgMembers
      .filter(member => (member as any)["role"] === OrganisationMemberRole.REVOKED)
      .map(member => {
        return { role: OrganisationMemberRole.REVOKED, user: member };
      });
    changedRoleToSave.forEach(member => {
      if (member.role === OrganisationMemberRole.REVOKED) {
        // Revoke access through Modal Confirm
        onNotificationData({
          ...notificationData,
          memberToRevoke: {
            ...member,
            orgId: userOrg?.id,
            // need to pass an update func, to update in Modal (might need to create a Context)
            updateOrgMembers: getOrgUsers
          },
          id: member.user.address
        });
        modal.onConfirmModalType("orgRevoke");
        modal.onModalVisibility(null, "orgRevoke");
      } else {
        // onTxSpinner(true);
        setButtonLoading(true);
        setButtonLoading(false);
        // onTxSpinner(false);
        onSnackbarToShow("dataRoomAccessUpdate");
      }
    });
  };

  const isButtonDisabled = () => {
    return !orgMembers.some(member => (member as any)["role"] === "REVOKED") || buttonLoading;
  };

  return (
    <SupportScreenWrapper title="Organisation members">
      <Box mt={6}>
        {loading ? (
          <Box>
            {Array.from(new Array(3)).map((item, index) => (
              <Skeleton key={index} variant="rectangular" height={32} sx={{ borderRadius: 0, marginTop: 4, paddingBottom: 1 }} />
            ))}
          </Box>
        ) : (
          <Box>
            {orgAdmins?.map((member, index) => {
              return (
                <Box
                  width="100%"
                  mb={6}
                  key={index}
                  display="flex"
                  alignItems={member.profileName ? "start" : "center"}
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" width="75%">
                    <Box width="75%" display="flex" alignItems="center">
                      <Box mr={2}>
                        {member.avatar ? (
                          <img
                            src={`${STORAGE_URL}/${getResourceUri(member.avatar as string[], "s3")}`}
                            alt="Avatar"
                            className={classes.avatar}
                          />
                        ) : (
                          <AvatarIcon sx={{ fontSize: "32px" }} color="secondary" />
                        )}
                      </Box>
                      <Typography variant="caption" color="textPrimary">
                        {member.profileName ? truncateString(member.profileName, 25) : truncateString(member.email!, 25)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Admin
                    </Typography>
                  </Box>
                </Box>
              );
            })}
            {orgMembers?.map((member, index) => {
              return (
                <Box
                  width="100%"
                  mb={6}
                  key={index}
                  display="flex"
                  alignItems={member.profileName ? "start" : "center"}
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" width="75%">
                    <Box width="75%" display="flex" alignItems="center">
                      <Box mr={2}>
                        {member.address ? (
                          <Avatar url={`${AVATAR_URL}/${member.address}`} imageClassName={classes.avatar} avatarSx={{ fontSize: "32px" }} />
                        ) : (
                          <AvatarIcon sx={{ fontSize: "32px" }} />
                        )}
                      </Box>
                      <Typography variant="caption" color="textPrimary">
                        {member.profileName ? truncateString(member.profileName, 25) : truncateString(member.email!, 25)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      <SelectMenu member={member} outlined={false} handleFormChange={handleFormChange} index={index} isOrg={true} />
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      {orgMembers?.length > 0 && (
        <Box mb={7}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            disableElevation
            classes={{
              containedPrimary: classes.containedPrimary
            }}
            // style={error && { background: '#DB443C' }}
            disabled={isButtonDisabled()}
            fullWidth={isMobile}
            onClick={updateMembersRole}
          >
            Save changes
            {buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </Box>
      )}
    </SupportScreenWrapper>
  );
}

export default withRouter(OrganisationMembers);
