import { Box, Dialog, DialogTitle, DialogContent, IconButton, Divider, Typography } from "@mui/material";
import React from "react";
import ButtonGroup from "../common/ButtonGroup";
import { CloseIcon, StripeClimateIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { BillingFormProps, usePaymentContext } from "../../contexts/PaymentContext";
import { SubscriptionPlan } from "../../types/stripeTypes";

type TopupSummaryModalProps = {
  formData: BillingFormProps;
  product?: SubscriptionPlan;
  quantity: number;
  handleCardPayment: () => Promise<void>;
  openConfirmStripeModal: boolean;
  onConfirmStripeModalClose: () => void;
  savingsChecked: boolean;
  overallBoulderPrice: string;
  boulderPlanPrice: number;
  loading: boolean;
};

const TopupSummaryModal: React.FC<TopupSummaryModalProps> = ({
  formData,
  product,
  quantity,
  handleCardPayment,
  openConfirmStripeModal,
  onConfirmStripeModalClose,
  savingsChecked,
  overallBoulderPrice,
  boulderPlanPrice,
  loading
}) => {
  const { isMobile } = useGlobalContext();
  const { getCurrencySymbol, currencyCode } = usePaymentContext();

  const renderStorage = (plan?: SubscriptionPlan) => {
    if (!plan) return "-";
    const storage = parseFloat(plan.metadata.storage) * quantity;
    return storage;
  };

  const currencySymbol = getCurrencySymbol(currencyCode);

  const renderPrice = (plan?: SubscriptionPlan) => {
    if (!plan) return "-";
    const amount = Math.round(plan.price.currency_options[currencyCode.toLowerCase()].unit_amount * quantity);
    return `${currencySymbol}${amount / 100}`;
  };

  const renderPayButtonLabel = () => {
    return savingsChecked
      ? `Pay ${overallBoulderPrice} for ${quantity} GB + Boulder plan`
      : `Pay ${renderPrice(product)} for ${renderStorage(product)} GB`;
  };

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="md"
      onClose={onConfirmStripeModalClose}
      aria-labelledby="stripe-confirm-dialog"
      open={openConfirmStripeModal}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2">Review and pay</Typography>
          <IconButton aria-label="close" sx={{ padding: 0 }} onClick={onConfirmStripeModalClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginBottom: 0, maxHeight: "none" }}>
        <Box>
          <Box>
            <Typography variant="body2">Please check all the details carefully before confirming your purchase.</Typography>
            <Box mt={5}>
              <Typography variant="h3">Storage amount</Typography>
              <Typography variant="body2">{renderStorage(product)} GB</Typography>
            </Box>
            {savingsChecked && (
              <Box mt={5}>
                <Typography variant="h3">Subscription plan</Typography>
                <Typography variant="body2">
                  Boulder {currencySymbol}
                  {boulderPlanPrice} per month
                </Typography>
              </Box>
            )}
            <Box mt={5}>
              <Typography variant="h3">Price</Typography>
              <Typography variant="body2">{savingsChecked ? overallBoulderPrice : renderPrice(product)}</Typography>
            </Box>
            <Box mt={5}>
              <Typography variant="h3">Billing details</Typography>
              <Typography variant="body2">{formData.name}</Typography>
              <Typography variant="body2">{formData.companyName}</Typography>
              <Typography variant="body2">{formData.companyVat}</Typography>
              <Typography variant="body2">{formData.address}</Typography>
              <Typography variant="body2">{formData.city}</Typography>
              <Typography variant="body2">{formData.state}</Typography>
              <Typography variant="body2">{formData.zip}</Typography>
              <Typography variant="body2">{formData.country}</Typography>
            </Box>
          </Box>
          <Box mt={6} pb={4}>
            <ButtonGroup
              nextText={renderPayButtonLabel()}
              handleEnd={() => handleCardPayment()}
              nextDiasabled={loading || !quantity || !product}
              hideBackButton={true}
              fullWidthNextButton={true}
              hideIcon={true}
              disabledRed={false}
              loading={loading}
              noMarginTop={true}
              arialLabelNext="Next Confirm Topup"
            />
          </Box>
          <Box display="flex" alignItems="center" pb={4}>
            <StripeClimateIcon fontSize="small" sx={{ marginRight: "5px" }} />
            <Typography variant="caption" color="text.tertiary" className="small">
              Akord will contribute 1% of your purchase to remove CO2 from the atmosphere.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TopupSummaryModal;
