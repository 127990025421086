import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, CssBaseline, Box } from "@mui/material";
import ThemeWrapper from "../../components/common/Wrappers/ThemeWrapper";
import Logo from "../../components/common/logo/logoOrange";

const useStyles = makeStyles({
  logoIcon: {
    fill: "currentColor",
    color: "white"
  }
});

const MaintenancePage = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const classes = useStyles();

  return (
    <ThemeWrapper darkMode={true}>
      <CssBaseline />
      <Box height="100%">
        <Box p={2} height={isDesktop ? "60%" : "70%"} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Logo width="117" height="33" className={classes.logoIcon} />
          <Typography variant="h1" align="center" sx={{ mt: 6 }}>
            We&apos;ll be back soon!
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            Akord is currently down for scheduled maintenance.
          </Typography>
          <Typography variant="body2" align="center">
            We apologise for any inconvenience.
          </Typography>
        </Box>
      </Box>
    </ThemeWrapper>
  );
};

export default MaintenancePage;
