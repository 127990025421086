import React from "react";
import { Auth } from "@akord/akord-js";
import { useGlobalContext } from "../contexts/GlobalDataProvider";
import { CLIENT_CONFIG, SEARCH_INDEXING_ENABLED } from "../helpers/env";

function useSearchIndex() {
  const { contractsWorker, wallet } = useGlobalContext();
  const getAuthData = async () => {
    const jwt = await Auth.getAuthToken();
    return {
      jwt: jwt,
      backupPhrase: wallet?.backupPhrase
    };
  };
  const indexSearchData = async () => {
    if (!SEARCH_INDEXING_ENABLED) return;
    const { jwt, backupPhrase } = await getAuthData();
    contractsWorker?.postMessage({
      backupPhrase: backupPhrase,
      jwt: jwt,
      config: CLIENT_CONFIG
    });
  };
  return { indexSearchData };
}

export default useSearchIndex;
