import React from "react";
import { DoubleFileIcon, TickAnimatedIcon } from "@akord/addon-icons";
import { IconButton, Tooltip } from "@mui/material";
import { copyToClipboard } from "../../../helpers/helpers";

type CopyToClipboardProps = { dataToCopy: string; tooltipText?: string };

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ dataToCopy, tooltipText = "Copy to clipboard" }) => {
  const [copyClick, setCopyClick] = React.useState(false);
  const handleCopyClick = (value: boolean) => setCopyClick(value);
  return (
    <IconButton sx={{ marginLeft: 2 }} onClick={() => copyToClipboard(dataToCopy, handleCopyClick)}>
      <Tooltip title={tooltipText} arrow>
        <span>{copyClick ? <TickAnimatedIcon color="primary" fontSize="small" /> : <DoubleFileIcon fontSize="small" />}</span>
      </Tooltip>
    </IconButton>
  );
};

export default CopyToClipboard;
