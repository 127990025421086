import React from "react";
import { Auth } from "@akord/akord-js";
import { Box, Typography } from "@mui/material";
import { ButtonGroup, ErrorAlert, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { useHistory } from "react-router-dom";

const SecurityPhoneNumberMfaVerify: React.FC = () => {
  const history = useHistory();
  const { isMobile } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [textClicked, setTextClicked] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [otp, setOtp] = React.useState("");

  React.useEffect(() => {
    const getPhoneNumber = async () => {
      const user = await Auth.getUser();
      setPhoneNumber(user.attributes["phone_number"]);
    };
    getPhoneNumber();
  }, []);

  const handleChange = (newValue: string) => {
    setError(false);
    setOtp(newValue);
  };

  const isDisabled = () => {
    return otp?.length !== 6 || loading;
  };

  const resendCode = async () => {
    setTextClicked(true);
    setTimeout(() => {
      setTextClicked(false);
    }, 1500);
    await Auth.registerPhoneNumber(phoneNumber);
  };

  const verify = async () => {
    try {
      setLoading(true);
      await Auth.verifyPhoneNumber(otp);
      await Auth.enableMFA("SMS");
      setLoading(false);
      onSnackbarToShow("mfaEnabled");
      history.push("/account/security/two-factor-authentication");
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  if (!phoneNumber) {
    return null;
  }

  return (
    <SupportScreenWrapper title="Enter verification code" route="/account/security/two-factor-authentication/phone-number">
      <Box mt={5} mb={6}>
        <Typography component="span" variant="body2" color="text.primary" sx={{ marginBottom: 4 }}>
          We’ve sent a verification code to {phoneNumber}.{" "}
        </Typography>
        <Typography component="a" color="text.primary" variant="body2" onClick={() => resendCode()}>
          {textClicked ? "Code sent!" : "Resend code."}
        </Typography>
        <Typography variant="body2" color="text.primary" sx={{ marginTop: 4, marginBottom: 4 }}>
          Enter the 6-digit code below.
        </Typography>
      </Box>
      <Box mt={3} width={isMobile ? "100%" : "400px"}>
        {/* @ts-ignore */}
        <MuiOtpInput length={6} value={otp} onChange={handleChange} />
      </Box>
      <Box mt={2}>
        <ButtonGroup
          loading={loading}
          noMinWidth={true}
          nextText="Verify"
          nextDiasabled={isDisabled()}
          handleNext={() => verify()}
          hideBackButton={true}
          fullWidthNextButton={isMobile ? true : false}
          hideIcon={true}
          disabledRed={false}
          noMarginTop={true}
        />
      </Box>
      {error && <ErrorAlert getErrorText="Wrong code. Please check and try again." />}
    </SupportScreenWrapper>
  );
};

export default SecurityPhoneNumberMfaVerify;
