import React from "react";
import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type TransactionSpinnerProps = {
  isMobile: boolean;
  darkMode: boolean;
  text?: string;
  mobileText?: string;
  showText?: boolean;
};
type TransactionSpinnerStyleProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, TransactionSpinnerStyleProps>(theme => ({
  transactionBox: {
    border: `1px solid`,
    borderColor: ({ darkMode }) => (darkMode ? theme.palette.info.main : theme.palette.info.light),
    borderRadius: "4px",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  transactionText: {
    color: ({ darkMode }) => (darkMode ? theme.palette.info.light : theme.palette.info.main)
  },
  spinner: {
    position: "inherit"
  }
}));

const TransactionSpinner: React.FC<TransactionSpinnerProps> = ({
  isMobile,
  darkMode,
  text = "Loading",
  mobileText = "",
  showText = true
}) => {
  const classes = useStyles({ darkMode: darkMode });

  return (
    <Box display="flex" alignItems="center" className={classes.transactionBox}>
      {showText && (
        <Box mr={1}>
          <Typography variant="body2" className={["small", classes.transactionText].join(" ")}>
            {isMobile ? mobileText : text}
          </Typography>
        </Box>
      )}
      <CircularProgress size="1rem" color="secondary" className={classes.spinner} />
    </Box>
  );
};

export default TransactionSpinner;
