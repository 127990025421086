import React from "react";
import { DrawerActionType } from "../../contexts/AssetsContextProvider";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";

type UploadButtonSingleProps = {
  onDrawerActionType: (action?: DrawerActionType | undefined) => void;
};

const UploadButtonSingle: React.FC<UploadButtonSingleProps> = ({ onDrawerActionType }) => {
  const { onUploadFiles, uploadEventRef } = useNotificationsContext();
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const fileList = Array.from(e.target.files);
    onDrawerActionType("upload_revision");
    onUploadFiles(fileList);
    uploadEventRef.current = e;
  };

  return (
    <input
      name="upload-file"
      id="upload-file"
      type="file"
      onInput={handleUpload}
      onClick={event => {
        (event.target as HTMLInputElement).value = ""; // TODO What does it mean?
      }}
      style={{ display: "none" }}
    />
  );
};

export default UploadButtonSingle;
