import React from "react";
import { Box, Button, Paper, Skeleton, Theme, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InfoIcon, UTokenIcon } from "@akord/addon-icons";
import axios from "axios";
import { useStorageContext } from "../../contexts/StorageContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { grey } from "../../theme/colors";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { getUTokenBalance } from "../../helpers/api-helpers";
import { useRewardsContext } from "../../contexts/RewardsContext";
import { usePaymentContext } from "../../contexts/PaymentContext";
const redStoneUrl = "https://api.redstone.finance/prices?provider=redstone&symbols=U";

type TokenData = {
  providerPublicKey: string;
  permawebTx: string;
  provider: string;
  source: { permaswap: number };
  permaswap: number;
  symbol: string;
  timestamp: number;
  value: number;
  version: string;
};

type WalletProps = {};
type WalletStyleProps = { darkMode: boolean };

const useStyles = makeStyles<Theme, WalletStyleProps>((theme) => ({
  paper: {
    backgroundColor: ({ darkMode }) => (darkMode ? grey[800] : grey[100]),
    boxShadow: "none",
    border: ({ darkMode }) => (darkMode ? "none" : `1px solid ${grey[200]}`),
    padding: 16
  },
  infoBox: {
    position: "absolute",
    right: 16,
    top: 16
  }
}));

const Wallet: React.FC<WalletProps> = () => {
  const [tokenData, setTokenData] = React.useState<TokenData | null>(null);

  const { darkMode, isMobile } = useGlobalContext();
  const { onTxRewards } = useStorageContext();
  const { modal, onNotificationData } = useNotificationsContext();
  const { uBalance, loadingBalance, loadTokenBalance } = useRewardsContext();
  const { isOnFreePlan } = usePaymentContext();
  const { globalStorage } = useStorageContext();

  const classes = useStyles({ darkMode: darkMode });

  const timerIdRef = React.useRef<NodeJS.Timer>();

  React.useEffect(() => {
    const getURate = async () => {
      try {
        const {
          data: { U: tokenData }
        } = await axios.get<{ U: TokenData }>(redStoneUrl);
        if (tokenData) setTokenData(tokenData);
      } catch (err) {
        setTokenData(null);
      }
    };

    const startPolling = () => {
      getURate();
      timerIdRef.current = setInterval(getURate, 30000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    startPolling();
    onTxRewards(new Map());
    return () => {
      stopPolling();
    };
  }, []);

  const handleTokenSend = () => {
    modal.onConfirmModalFormType("sendToken");
    modal.onModalVisibility(null, "sendToken");
    onNotificationData({ uBalance: uBalance, loadUbalance: loadTokenBalance });
  };

  const uBalanceDecimal = uBalance ? uBalance / Math.pow(10, 6) : 0;
  const uBalanceDecimalFloored = Math.floor(uBalanceDecimal * 10000) / 10000;

  return (
    <Box m={4}>
      <Box display="flex" justifyContent="space-between" mb={5}>
        <Box>
          <Typography variant="h2">Rewards</Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" flexDirection={isMobile ? "column" : "row"}>
        <Box width={isMobile ? "100%" : "45%"} mb={5}>
          <Box display="flex" alignItems="end">
            {loadingBalance ? (
              <Skeleton variant="text" className="lightColor" height="54.4px" width="236px" sx={{ transform: "scale(1, 1)" }} />
            ) : (
              <Tooltip arrow title={uBalance ? "U " + uBalance / Math.pow(10, 6) : "-"}>
                <Typography sx={{ fontSize: "4.25rem", fontWeight: 700, lineHeight: 0.8, cursor: "help" }} color="text.primary">
                  {uBalance && uBalance > 0 && uBalance < 100 ? "> 0.0001" : uBalanceDecimalFloored.toFixed(4)}
                </Typography>
              </Tooltip>
            )}
            <UTokenIcon sx={{ marginLeft: 2, marginBottom: "2px" }} />
          </Box>
          <Box mt={2} maxWidth={440}>
            {tokenData?.value ? (
              <Typography variant="body1" color="text.disabled">
                ${(tokenData?.value * uBalanceDecimalFloored).toFixed(2)}
              </Typography>
            ) : (
              <Box>
                <InfoIcon fontSize="small" color="disabled" sx={{ marginRight: 1 }} />
                <Typography variant="caption" color="text.secondary">{`We're currently unable to display the dollar value`}</Typography>
              </Box>
            )}
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Every time you upload permanent data you earn the $U token. Your balance is credited after files are committed to Arweave.
            </Typography>
          </Box>
          <Button
            sx={{ minWidth: "100px", marginTop: 5 }}
            // If balance is tiny, if is on a free plan or no top up was added - we block withdraw
            disabled={uBalanceDecimalFloored < 0.0001 || (isOnFreePlan() && (globalStorage?.storage_total || 0) <= 100000000)}
            variant="contained"
            color="primary"
            component="span"
            fullWidth={false}
            disableElevation
            onClick={handleTokenSend}
          >
            Send $U
          </Button>
          {isOnFreePlan() && (globalStorage?.storage_total || 0) <= 100000000 && (
            <Box mt={3}>
              <Typography variant="caption" color="text.secondary">
                * You need to top up or subscribe before you can use withdraw.
              </Typography>
            </Box>
          )}
        </Box>

        <Box width={isMobile ? "100%" : "35%"} lineHeight="normal" className={isMobile ? "" : classes.infoBox}>
          <Paper className={classes.paper}>
            <Box display="flex" sx={{ marginBottom: 4 }}>
              <InfoIcon color="disabled" />
              <Typography variant="h3" sx={{ marginLeft: 2 }}>
                $U-niverse token
              </Typography>
              <Typography variant="body2" ml="auto" color="text.primary">
                1 $U = ${tokenData?.value.toFixed(2)}
              </Typography>
            </Box>
            <Typography variant="caption" color="text.secondary" component="div">
              $U is a simple unit of account on the Permaweb. It is a fair launch token – released to the public without pre-mining,
              pre-sale, or any form of early access to a select group of investors or developers. $U will allow users to buy, sell, and
              interact with any asset on the Permaweb.
            </Typography>
            <Typography variant="caption" color="text.secondary" component="div" mt={4}>
              You can acquire the $U token by using $AR to store data. Akord distributes the $U generated from your uploads back to you.
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default Wallet;
