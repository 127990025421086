import { Menu, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { akordLinks } from "../../helpers/akordTexts";
import { DownloadIcon, OpenIcon } from "@akord/addon-icons";
import { getResourceUri } from "../../helpers/helpers";
import { useDownloader } from "../file/FileDownloader";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.spacing(2)
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  }
}));

function OnImageMenu({ anchorEl, handleClose, operation }) {
  const { darkMode } = useGlobalContext();
  const classes = useStyles({ darkMode: darkMode });

  const open = Boolean(anchorEl);

  const stackItem = operation.stack;
  const noteItem = operation.note;

  const asset = stackItem ? stackItem : noteItem;
  const arweaveUri = getResourceUri(asset.versions[0].resourceUri, "arweave");
  const { onSaveAs } = useDownloader({ useLoader: true });


  return (
    <Menu
      elevation={8}
      variant="selectedMenu"
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      // MenuListProps={{
      //   style: { background: '#FFF', color: '#333' }
      // }}
    >
      {stackItem && (
        <MenuItem
          style={{ fontSize: "0.9375rem" }}
          onClick={() => {
            handleClose();
            onSaveAs(asset.versions[asset.versions.length - 1], asset.name);
          }}
        >
          <DownloadIcon className={classes.extendedIcon} />
          <Typography variant="body2" color="text.primary">
            Download
          </Typography>
        </MenuItem>
      )}
      {arweaveUri && (
        <MenuItem
          onClick={() =>
            window.open(`${akordLinks.transactionBaseUrl}/${arweaveUri}`)
          }
        >
          <OpenIcon className={classes.extendedIcon} />
          <Typography variant="body2" color="text.primary">
            View transaction
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
}

export default OnImageMenu;
