import React from "react";
import { SelectChangeEvent, Typography } from "@mui/material";
import SelectMenu from "../../../components/common/SelectMenu";
import SelectMenuForAccess from "./SelectMenuForAccess";
import { memberRoleEnum, memberStatusEnum } from "../../../helpers/akord-enums";
import { Membership, RoleType } from "@akord/akord-js";

type ManageAccessStatusCaseProps = {
  member: Membership;
  memberRole: Membership[];
  userRole?: RoleType;
  index: number;
  handleFormChange: (e: SelectChangeEvent<any>, index: number, member: Membership) => void;
  isRoomArchived: boolean;
  isOrg?: boolean;
};

export const ManageAccessStatusCase: React.FC<ManageAccessStatusCaseProps> = ({
  member,
  memberRole,
  userRole,
  index,
  handleFormChange,
  isRoomArchived = false,
  isOrg = false
}) => {
  const memberFromState = memberRole.filter(memberState => memberState?.email === member?.email)[0];
  const orgMemberFromState = memberRole.filter(memberState => memberState?.Username === member?.Username)[0];

  if (!isRoomArchived && !isOrg)
    switch (true) {
      case member.status === memberStatusEnum.PENDING:
        return <SelectMenuForAccess member={memberFromState} menuType="Pending" />;
      case member.status === memberStatusEnum.INVITED:
        return <SelectMenuForAccess member={memberFromState} menuType="Invited" />;
      case member.status === memberStatusEnum.REVOKED:
        return <SelectMenuForAccess member={memberFromState} menuType="Revoked" />;
      //TODO: no such status
      // case member.status === "expired":
      //   return (
      //     <Typography variant="caption" color="text.secondary">
      //       Expired
      //     </Typography>
      //   );
      case member.status === memberStatusEnum.REJECTED:
        return <SelectMenuForAccess member={memberFromState} menuType="Rejected" />;
      //don't show select menu if a user is not a vault owner
      case userRole !== memberRoleEnum.OWNER && member.role !== memberRoleEnum.OWNER:
        switch (true) {
          case member.role === memberRoleEnum.CONTRIBUTOR:
            return (
              <Typography variant="caption" color="text.secondary">
                Contributor
              </Typography>
            );
          case member.role === memberRoleEnum.VIEWER:
            return (
              <Typography variant="caption" color="text.secondary">
                View only
              </Typography>
            );
          default:
            return null;
        }

      default:
        return memberFromState ? (
          <SelectMenu member={memberFromState} outlined={false} handleFormChange={handleFormChange} index={index} />
        ) : null;
    }
  else if (isOrg)
    switch (true) {
      case member.UserStatus === "CONFIRMED":
        return <SelectMenu member={orgMemberFromState} outlined={false} handleFormChange={handleFormChange} index={index} isOrg={isOrg} />;

      default:
        return null;
    }
  else
    switch (true) {
      case member.role === memberRoleEnum.CONTRIBUTOR && member.status === memberStatusEnum.ACCEPTED:
        return (
          <Typography variant="caption" color="text.secondary">
            Contributor
          </Typography>
        );
      case member.role === memberRoleEnum.VIEWER && member.status === memberStatusEnum.ACCEPTED:
        return (
          <Typography variant="caption" color="text.secondary">
            View only
          </Typography>
        );
      case member.status === memberStatusEnum.PENDING:
        return (
          <Typography variant="caption" color="text.secondary">
            Pending
          </Typography>
        );
      case member.status === memberStatusEnum.INVITED:
        return (
          <Typography variant="caption" color="text.secondary">
            Invited
          </Typography>
        );

      default:
        return null;
    }
};
