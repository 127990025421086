import React from "react";
import { Button, ButtonOwnProps } from "@mui/material";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";

type UploadButtonProps = {
  fab?: boolean;
  disabled?: boolean;
  noMinWidth?: boolean;
  isMobile: boolean;
  buttonSize?: ButtonOwnProps["size"];
  multiple?: boolean;
  inputName?: string;
  uploadType?: "ZIP" | "FOLDER" | "FILE";
  acceptTypes?: string;
  buttonVariant?: "contained" | "text" | "outlined";
  ml?: number;
};

const UploadButton: React.FC<UploadButtonProps> = ({
  children,
  fab = false,
  disabled = false,
  noMinWidth,
  isMobile,
  buttonSize = "medium",
  multiple = true,
  inputName = "contained-button-file",
  uploadType = "FILE",
  buttonVariant = "contained",
  ml
}) => {
  const { uploadEventRef, onUploadFiles } = useNotificationsContext();
  const { onZipUpload, onFolderUpload } = useAssetsContext();
  //hack to let upload same file after cancel https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const fileList = Array.from(e.target.files);
    uploadType === "ZIP" && onZipUpload(true);
    uploadType === "FOLDER" && onFolderUpload(true);
    onUploadFiles(fileList);
    uploadEventRef.current = e;
  };

  return (
    <>
      {uploadType === "FOLDER" ? (
        <input
          name={inputName}
          id={inputName}
          multiple={multiple}
          type="file"
          //@ts-ignore
          webkitdirectory=""
          onChange={handleUpload}
          onClick={event => {
            (event.target as HTMLInputElement).value = ""; // TODO What does it mean?
          }}
          style={{ display: "none" }}
        />
      ) : (
        <input
          name={inputName}
          id={inputName}
          multiple={multiple}
          type="file"
          accept={uploadType === "ZIP" ? ".zip" : ""}
          onChange={handleUpload}
          onClick={event => {
            (event.target as HTMLInputElement).value = ""; // TODO What does it mean?
          }}
          style={{ display: "none" }}
        />
      )}
      {!fab && (
        <label htmlFor={!disabled ? inputName : "non-clickable"} style={{ width: isMobile ? "100%" : "auto" }}>
          <Button
            sx={{ minWidth: noMinWidth ? "auto" : "228", ml: ml ? ml : 0 }}
            disabled={disabled}
            variant={buttonVariant}
            color="primary"
            component="span"
            fullWidth={isMobile ? true : false}
            disableElevation
            size={buttonSize}
          >
            {children}
          </Button>
        </label>
      )}
    </>
  );
};

export default UploadButton;
