import React from "react";
import { Typography, Link } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import { PasswordMaskIcon } from "@akord/addon-icons";

function AssetRevoked({ positionedBeforeDate, operation, color }) {
  const [showFiles, setShowFiles] = React.useState(false);
  const handleShowFiles = show => setShowFiles(show);
  const handleClick = () => handleShowFiles(!showFiles);

  const revokedTitle = operation => {
    if (operation.group) {
      if (operation.group.length > 1) return `${operation?.group?.length} files hidden`;
      else return "File hidden";
    } else {
      if (operation.stack?.versions?.length > 1) return "File stack hidden";
      else return "File hidden";
    }
  };

  return (
    <>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        icon={operation?.stack?.versions?.length > 1 ? PasswordMaskIcon : null}
        title={revokedTitle(operation)}
      />
      {operation.group?.length > 1 ? (
        <div
          style={{
            marginBottom: positionedBeforeDate ? "74px" : "16px"
          }}
        >
          {showFiles ? (
            <>
              {operation.group.map((operation, index) => (
                <Typography key={index} variant="body2" className="small" color="text.secondary" noWrap style={{ whiteSpace: "pre" }}>
                  {operation?.stack ? operation?.stack?.name : operation?.note?.name}
                </Typography>
              ))}
              <Link variant="body2" className="small" underline="none" color="text.secondary" onClick={handleClick}>
                Hide files
              </Link>
            </>
          ) : (
            <Link variant="body2" className="small" underline="none" color="text.secondary" onClick={handleClick}>
              Show files
            </Link>
          )}
        </div>
      ) : (
        <div
          style={{
            marginBottom: positionedBeforeDate ? "74px" : "16px",
            display: "flex"
          }}
        >
          <Typography variant="body2" className="small" color="text.secondary" noWrap style={{ whiteSpace: "pre" }}>
            {operation.group
              ? operation?.group[0].stack
                ? operation?.group[0].stack.name
                : operation?.group[0].note.name
              : operation?.stack
              ? operation?.stack?.name
              : operation?.note?.name}
          </Typography>
        </div>
      )}
    </>
  );
}

export default AssetRevoked;
