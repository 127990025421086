export const colorMap = [
  "#7D8CFF",
  "#F3CDFE",
  "#E6F783",
  "#94F8C3",
  "#9FF1FC",
  "#F477B9",
  "#FFDDBA",
  "#8B7872",
  "#FFEB53"
];
