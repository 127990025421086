import React, { useState, createContext, useContext, useCallback } from "react";
import { NFT } from "@akord/akord-js";
import { useGlobalContext } from "./GlobalDataProvider";
import { getCollectionId, getComparator, getVaultId, stableSort } from "../helpers/helpers";

type NftCollectionContextTypes = { collectionNftsLoading: boolean; collectionNfts?: NFT[] };

const Context = createContext({} as NftCollectionContextTypes);

const NftCollectionContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [collectionNfts, setCollectionNfts] = useState<NFT[]>();
  const [collectionNftsLoading, setCollectionNftsLoading] = useState(false);

  const collectionNftsRef = React.useRef<Map<string, NFT>>(new Map());

  const { akord, withTxSpinner } = useGlobalContext();

  const vaultId = getVaultId(location.pathname);
  const collectionId = getCollectionId(location.pathname);

  const loadNfts = useCallback(
    async (token: string = "", abortController: AbortController) => {
      const listOptions = {
        parentId: collectionId!,
        shouldDecrypt: false,
        nextToken: token,
        limit: 40
      };

      const nfts = await akord!.nft.list(vaultId!, listOptions);
      if (abortController.signal.aborted) {
        return { items: [], nextToken: "" };
      }
      const nftItems = nfts.items.map(nft => {
        collectionNftsRef.current.set(nft.id, nft);
        return nft;
      });
      return { items: nftItems, nextToken: nfts.nextToken };
    },
    [akord, vaultId, collectionId]
  );

  const loadAllNfts = useCallback(
    async (token: string, abortController: AbortController) => {
      setCollectionNftsLoading(true);
      do {
        const { items, nextToken } = await loadNfts(token, abortController);
        if (!items.length) {
          setCollectionNftsLoading(false);
          break;
        }
        const collectionNftsChunk = Array.from(collectionNftsRef.current.values());
        const filteredCollectionNftsChunk = stableSort(collectionNftsChunk, getComparator("asc", "name")) as NFT[];
        setCollectionNfts(filteredCollectionNftsChunk);
        setCollectionNftsLoading(false); // start showing items after first chunk is uploaded
        token = nextToken;
      } while (token);
    },
    [loadNfts]
  );

  const fetchCollectionNfts = useCallback(
    async (abortController: AbortController) => {
      let token: string = "";

      await loadAllNfts(token, abortController);
    },
    [loadAllNfts]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    if (vaultId && collectionId && akord) withTxSpinner(() => fetchCollectionNfts(abortController));
    return () => {
      abortController.abort();
      collectionNftsRef.current = new Map();
      setCollectionNfts([]);
    };
  }, [collectionId, vaultId, akord, fetchCollectionNfts, withTxSpinner]);

  return (
    <Context.Provider value={{ collectionNftsLoading: collectionNftsLoading, collectionNfts: collectionNfts }}>{children}</Context.Provider>
  );
};

export default NftCollectionContextProvider;
export const useNftCollectionContext = () => useContext(Context);
