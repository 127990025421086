import React from "react";
import { useHistory } from "react-router-dom";
import { Box, FormControl, OutlinedInput, Typography } from "@mui/material";
import { ButtonGroup, ErrorAlert, FormSubmit, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import DescriptionAndTagsForm from "../../components/common/DescriptionDrawer/DescriptionAndTagsForm";
import { useVaultCreateContext } from "../../contexts/VaultCreateContextProvider";
import { useKeyPress } from "../../hooks/useKeyPress";

const inputIds = ["enter-title", "enter-description", "tags-input"];

const CreateRoom: React.FC = () => {
  const [creatingVault, setCreatingVault] = React.useState(false);

  const { isMobile } = useGlobalContext();

  const { onVaultCreate, vaultCreateFormData, error, onVaultNameChange, onVaultDescriptionChange, onVaultTagsChange, vaultNameError } =
    useVaultCreateContext();

  const history = useHistory();
  const vaultTemplate = history.location.state as "NFTAssets" | "PrivateFiles";

  const handleCreateVault = async () => {
    setCreatingVault(true);
    const vault = await onVaultCreate();
    setCreatingVault(false);
    if (vault) history.push(`/vaults/active/${vault.id}/assets`);
  };

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp") handleArrowMove(false);
    if (event.key === "ArrowDown") handleArrowMove(true);
  };

  useKeyPress(["ArrowUp", "ArrowDown"], onKeyPress);

  const handleArrowMove = (down: boolean) => {
    const focusedElementId = document.activeElement?.id || "";
    const focusedElementIndex = inputIds.indexOf(focusedElementId);
    document.getElementById(inputIds[down ? focusedElementIndex + 1 : focusedElementIndex - 1])?.focus();
  };

  return (
    <SupportScreenWrapper
      title={vaultTemplate ? "Add metadata" : "Create new vault"}
      subtitle={vaultTemplate ? (vaultTemplate === "NFTAssets" ? "NFT assets" : "Private files") : "Step 3 of 3"}
      hideChevron
      spaceBetween
      fullHeight
    >
      <Box height="auto" display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"} my={6}>
        <FormSubmit onSubmit={() => handleCreateVault()}>
          <Box>
            <Box mb={7}>
              <Typography variant="body2" className="strong" color="text.primary" sx={{ mb: 4 }}>
                Title (max 150 characters)
              </Typography>
              <FormControl error={!!error} variant="outlined" fullWidth>
                <OutlinedInput
                  autoFocus
                  id="enter-title"
                  name="roomTitle"
                  type="text"
                  placeholder="Title"
                  value={vaultCreateFormData.roomTitle}
                  onChange={(ev) => onVaultNameChange(ev.target.value)}
                />
                {vaultNameError && <ErrorAlert getErrorText="Title exceeds 150 characters." />}
                {error && <ErrorAlert getErrorText={error.message} />}
              </FormControl>
            </Box>
            <DescriptionAndTagsForm
              formData={vaultCreateFormData}
              onVaultDescriptionChange={onVaultDescriptionChange}
              tags={vaultCreateFormData.tags}
              handleTagChange={onVaultTagsChange}
              isVaultPage={true}
            />
          </Box>
          <ButtonGroup
            type="submit"
            nextDiasabled={!vaultCreateFormData.roomTitle || creatingVault || vaultNameError}
            handleEnd={() => handleCreateVault()}
            handleBack={() => history.goBack()}
            hideIcon
            loading={creatingVault}
            nextText="Create vault"
          />
        </FormSubmit>
      </Box>
    </SupportScreenWrapper>
  );
};

export default CreateRoom;
