import React, { useState, createContext, useContext, useEffect } from "react";
import { GraphQLAPI } from "@aws-amplify/api-graphql";
import { ZenObservable } from "zen-observable-ts";
import { subscriptions } from "@akord/gql";
import { useGlobalContext } from "./GlobalDataProvider";
import { days, getVaultId, months } from "../helpers/helpers";
import { Memo, User } from "@akord/akord-js";
import { useVaultContext } from "./VaultContextProvider";
import { reactionEmoji } from "@akord/akord-js/lib/constants";

type ChatContextProps = {
  memos: MemoWithOwnerInfo[];
  memosByDate: (memoArray: MemoWithOwnerInfo[]) => Map<string, [MemoWithOwnerInfo]>;
  onMemos: (memos: MemoWithOwnerInfo[]) => void;
  isMemoLoaded: boolean;
  dataRoomId: string | null;
  countRef: React.MutableRefObject<number[]>;
  onChatItemsNumber: (number: number) => void;
  chatItemsNumber: number;
  chatItemsLimit: number;
  newChatRef: React.MutableRefObject<boolean>;
  getAuthor: (address: string) => OwnerInfo | undefined;
};

export type OwnerInfo = User & { id: string };
export type MemoWithOwnerInfo = Memo & { ownerInfo?: OwnerInfo };

export const REACTION_EMOJI_CODE_POINTS = [
  "128514", // 'joy'
  "128562", // 'astonished'
  "128557", // 'cry'
  "10084,65039", // 'heart'
  "128293", // 'fire'
  "128077", // '+1'
  "128078", // '-1'
  "128591" // 'pray'
] as reactionEmoji[];

const Context = createContext({} as ChatContextProps);

const memoToLoadNumber = 25;

const ChatContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [memos, setMemos] = useState<MemoWithOwnerInfo[]>([]);
  const handleMemos = (memos: MemoWithOwnerInfo[]) => setMemos(memos);

  const [isMemoLoaded, setIsMemoLoaded] = useState(false);
  const handleIsMemoLoaded = (isLoaded: boolean) => setIsMemoLoaded(isLoaded);

  const [chatItemsNumber, setChatItemsNumber] = useState<number>(memoToLoadNumber);
  const handleChatItemsNumber = (number: number) => setChatItemsNumber(number);

  const { akord, onDecrptSpinner } = useGlobalContext();
  const { members, keys } = useVaultContext();

  const countRef = React.useRef<number[]>([]);
  const newChatRef = React.useRef(true);
  const memosRef = React.useRef<MemoWithOwnerInfo[]>([]);

  const vaultId = getVaultId(location.pathname);
  const isChatTab = location.pathname.includes("/chat");

  useEffect(() => {
    const getMemos = async () => {
      const memos: Memo[] = await akord!.memo.listAll(vaultId!);
      const withOwnerInfo = memos.map(memo => {
        return { ...memo, ownerInfo: getAuthor(memo.owner) };
      }) as MemoWithOwnerInfo[];
      memosRef.current = withOwnerInfo;
      handleMemos(withOwnerInfo);
      newChatRef.current = false;
      handleIsMemoLoaded(true);
      onDecrptSpinner(false);
    };
    if (isChatTab) {
      // Don't reload memos on tab switch
      if (akord && newChatRef.current && members.length && vaultId) getMemos();
    }
  }, [akord, isChatTab, vaultId, members]);

  // Clear timeline on page change
  useEffect(() => {
    return () => {
      if (!newChatRef.current) {
        handleMemos([]);
        handleIsMemoLoaded(false);
        newChatRef.current = true;
        countRef.current = [];
        handleChatItemsNumber(memoToLoadNumber);
      }
    };
  }, [vaultId]);

  //Subscription
  useEffect(() => {
    let subscriptionOnCreate: ZenObservable.Subscription;
    let subscriptiononUpdate: ZenObservable.Subscription;
    const setupSubscription = async () => {
      try {
        if (!vaultId) return;
        subscriptionOnCreate = await GraphQLAPI.graphql({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onCreateMemo,
          variables: {
            filter: {
              vaultId: { eq: vaultId }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: { value: { data: { onCreateMemo: Memo } } }) => {
            const memoProto = value.data.onCreateMemo;
            await onMemoCreate(memoProto);
          },
          error: () => {
            console.warn("err");
          }
        });
        subscriptiononUpdate = await GraphQLAPI.graphql({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onUpdateMemo,
          variables: {
            filter: {
              vaultId: { eq: vaultId }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: { value: { data: { onUpdateMemo: Memo } } }) => {
            const updatedMemo = value.data.onUpdateMemo;
            await onMemoUpdate(updatedMemo);
          },
          error: () => {
            console.warn("Memo Subscription error.");
          }
        });
      } catch (err) {
        console.log("Memo Subscription error: ", err);
      }
    };
    if (window.navigator.onLine && keys) setupSubscription();
    return () => {
      if (subscriptionOnCreate) subscriptionOnCreate.unsubscribe();
      if (subscriptiononUpdate) subscriptiononUpdate.unsubscribe();
    };
  }, [vaultId, keys]);

  const memosByDate = (memoArray: MemoWithOwnerInfo[]) => {
    const sorted = new Map<string, [MemoWithOwnerInfo]>();
    const todayFullDate = new Date();
    const todayMonth = todayFullDate.getMonth();
    const todayDate = todayFullDate.getDate();

    memoArray.sort((a, b) => {
      return parseInt(a.createdAt) - parseInt(b.createdAt);
    });

    memoArray.forEach(item => {
      const fullDate = new Date(parseInt(item.createdAt));
      const month = fullDate.getMonth();
      const date = fullDate.getDate();
      const day = fullDate.getDay();

      const dateToShow =
        todayMonth === month && todayDate === date
          ? "Today"
          : todayMonth === month && todayDate - date === 1
          ? "Yesterday"
          : `${days[day]}, ${months[month]} ${date}`;

      if (sorted.has(dateToShow)) {
        const data = sorted.get(dateToShow);
        data!.push(item);
        sorted.set(dateToShow, data!);
      } else sorted.set(dateToShow, [item]);
    });
    return sorted;
  };

  const onMemoCreate = async (memoProto: Memo) => {
    onDecrptSpinner(true);

    const memo = new Memo(memoProto, keys);
    await memo.decrypt();

    const author = getAuthor(memo.owner);
    memo.ownerInfo = author;

    const updatedMemos = [...memosRef.current, memo] as MemoWithOwnerInfo[];
    memosRef.current = updatedMemos;
    handleMemos(updatedMemos);

    newChatRef.current = false;
    handleIsMemoLoaded(true);
    onDecrptSpinner(false);
  };

  const onMemoUpdate = async (memoProto: Memo) => {
    onDecrptSpinner(true);

    const memo = new Memo(memoProto, keys);
    await memo.decrypt();

    const author = getAuthor(memo.owner);
    memo.ownerInfo = author;

    const memosRefCopy = [...memosRef.current] as MemoWithOwnerInfo[];
    const memoToUpdateIndex = memosRefCopy.findIndex(item => item.id === memo.id);
    memosRefCopy[memoToUpdateIndex] = memo as MemoWithOwnerInfo;
    memosRef.current = memosRefCopy;
    handleMemos(memosRefCopy);
    onDecrptSpinner(false);
  };

  const getAuthor = (address: string): OwnerInfo | undefined => {
    const member = members?.find(member => member.address === address);
    if (member) return { ...member.memberDetails, email: member.email, id: member.id };
  };

  return (
    <Context.Provider
      value={{
        memos: memos,
        memosByDate: memosByDate,
        onMemos: handleMemos,
        isMemoLoaded: isMemoLoaded,
        dataRoomId: vaultId,
        countRef: countRef,
        onChatItemsNumber: handleChatItemsNumber,
        chatItemsNumber: chatItemsNumber,
        chatItemsLimit: memoToLoadNumber,
        newChatRef: newChatRef,
        getAuthor: getAuthor
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ChatContextProvider;

export const withChatContext = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{chatContext => <Component {...props} {...chatContext} />}</Context.Consumer>;

export const useChatContext = () => useContext(Context);
