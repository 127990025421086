import React from "react";
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

function FolderRevoked(props) {
  const { positionedBeforeDate, operation, color } = props;

  return (
    <>
      <OperationTitleWithIcon actionRef={operation.actionRef} color={color} title="Folder hidden" />
      <div
        style={{
          marginBottom: positionedBeforeDate ? "74px" : "16px",
          display: "flex"
        }}
      >
        <Typography variant="body2" className="small" color="text.secondary" noWrap style={{ whiteSpace: "pre" }}>
          {operation?.folder ? operation.folder.name : operation.group[0].folder.name}
        </Typography>
      </div>
    </>
  );
}

export default FolderRevoked;
