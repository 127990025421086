import React, { useState } from "react";
import { Box, Typography, Link, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { EmptySpace, SupportScreenWrapper } from "../../components/common";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import LoadingNotification from "./LoadingNotification";
import LogNotification from "./LogNotification";
import { grey } from "../../theme/colors/index";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const NOTIFICATIONS_PER_PAGE = 10;

type NotificationsStyleProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, NotificationsStyleProps>({
  mainBox: {
    border: "1px solid #312F37",
    borderColor: ({ darkMode }) => (darkMode ? grey[800] : grey[200]),
    borderRadius: 4
  }
});

const Notifications: React.FC = () => {
  const [logsToShow, setLogsToShow] = useState(NOTIFICATIONS_PER_PAGE);

  const { notifications } = useInAppNotificationsContext();
  const { darkMode, isMobile } = useGlobalContext();

  const classes = useStyles({ darkMode: darkMode });

  const handleLogsToShow = () => setLogsToShow(prevState => prevState + NOTIFICATIONS_PER_PAGE);

  return (
    <SupportScreenWrapper title="Notifications" hideChevron showMenu>
      <Box mt={6}>
        {!notifications ? (
          Array.from(Array(10).keys()).map((item, index) => <LoadingNotification key={index} />)
        ) : notifications.length ? (
          <Box className={classes.mainBox}>
            {notifications.slice(0, logsToShow).map((notification, index) => (
              <LogNotification
                notification={notification}
                key={index}
                lastToShow={notifications.slice(0, logsToShow).length === index + 1}
              />
            ))}
          </Box>
        ) : (
          <EmptySpace dataText="notifications" />
        )}
      </Box>
      <Box pt={5} pb={isMobile ? 10 : 5} display="flex" justifyContent="center">
        {logsToShow < (notifications?.length || 0) && (
          <Typography variant="body2" className="small">
            <Link onClick={() => handleLogsToShow()} underline="always" color="text.secondary">
              Load more...
            </Link>
          </Typography>
        )}
      </Box>
    </SupportScreenWrapper>
  );
};

export default Notifications;
