import React, { useState, createContext, useContext, useEffect, useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { Folder, Stack } from "@akord/akord-js";
import { Types, subscriptions } from "@akord/gql";
import { itemStatusEnum } from "../helpers/akord-enums";
import { getComparator, getVaultId, getFolderId, stableSort } from "../helpers/helpers";
import { filterStacks, PeriodFilterKeys } from "../helpers/stack-helpers";
import { useGlobalContext } from "./GlobalDataProvider";
import { useStorageContext } from "./StorageContextProvider";
import { getAssetType, processFolder, processStack } from "../helpers/asset-context-helper";
import { Order, OrderBy } from "../types/globalDataTypes";
import { SelectChangeEvent } from "@mui/material";
import GraphQLAPI from "@aws-amplify/api-graphql";
import { ZenObservable } from "zen-observable-ts";
import { useVaultContext } from "./VaultContextProvider";
import { GraphQLSubscription } from "@aws-amplify/api";
import { refreshSize } from "../helpers/api-helpers";

export type DrawerActionType = "upload" | "download" | "sign" | "import" | "upload_revision";

export type NumberChangeEvent = SelectChangeEvent<number> | number;

type AssetsContextProps = {
  actionFilters: string[];
  activeFolders: Folder[];
  activeStacks: Stack[];
  assets: (Stack | Folder)[];
  assetsDecrypted: boolean;
  axiosError: any;
  currentPage: number;
  drawerActionType?: DrawerActionType;
  filesNumber: number;
  filteredSortedActiveAssets: (Stack | Folder)[];
  folders: Folder[];
  getPaginatedData: () => (Stack | Folder)[];
  isShiftDown: boolean;
  onActionFilters: (filters: string[]) => void;
  onAxiosError: (error?: any) => void;
  onChangePage: (event: NumberChangeEvent) => void;
  onDrawerActionType: (action?: DrawerActionType) => void;
  onFilesNumber: (num: number) => void;
  onFolderCreate: (folder: Folder) => void;
  onFolderDelete: (folderId: string) => void;
  onFolderUpdate: (folder: Folder) => void;
  onFolderUpload: (value: boolean) => void;
  onGoToNextPage: () => void;
  onGoToPreviousPage: () => void;
  onLastSelectedItem: (item: string) => void;
  onOrder: (order: Order) => void;
  onOrderBy: (value: OrderBy) => void;
  onPeriodFilter: (filters: PeriodFilterKeys | null) => void;
  onSelectedItems: (hash: string, checked?: boolean, revoked?: boolean) => void;
  onShowFilter: (value: boolean) => void;
  onShowLoaderByVaultId: (id: string) => void;
  onShowUpload: (value: boolean) => void;
  onStackCreate: (stack: Stack) => void;
  onStackDelete: (stackId: string) => void;
  onStackUpdate: (stack: Stack) => void;
  onUploadCancelHook: (hook: AbortController | null) => void;
  onUploadedFilesNumber: (num: number) => void;
  onVersionUpload: (value: boolean) => void;
  onZipUpload: (value: boolean) => void;
  showZipUpload: boolean;
  order: Order;
  orderBy: OrderBy;
  pages: number | null;
  periodFilter: PeriodFilterKeys | null;
  perPageStacks: Stack[];
  selectedItemsMap: Map<string, SelectedItem>;
  showFilter: boolean;
  showFolderUpload: boolean;
  showLoaderByVaultId: string[];
  showUpload: boolean;
  showVersionUpload: boolean;
  stacks: Stack[];
  uploadCancelHook: AbortController | null;
  uploadedFilesNumber: number;
};

type LocationState = {
  from?: Location;
  isFromGallery?: boolean;
};

export type SelectedItem = {
  id: string;
  size: number;
  objectType?: "Stack" | "Folder" | "Other";
};

const Context = createContext<AssetsContextProps>({} as AssetsContextProps);
const assetsLimit = 40;

const AssetsContextProvider: React.FC<React.ReactNode & RouteComponentProps<{}, StaticContext, LocationState>> = ({
  children,
  location
}) => {
  // State for stack/folder select
  const [selectedItemsMap, setSelectedItemsMap] = useState<Map<string, SelectedItem>>(new Map());
  const [lastSelectedItem, setLastSelectedItem] = useState<string>();
  const handleLastSelectedItem = (item: string) => setLastSelectedItem(item);

  const [assetsDecrypted, setAssetsDecrypted] = useState(false);
  const handleAssetsDecrypted = (value: boolean) => setAssetsDecrypted(value);

  const [stacks, setStacks] = useState<Stack[]>([]);
  const handleStacks = (stacks: Stack[]) => setStacks(stacks);

  const [folders, setFolders] = useState<Folder[]>([]);
  const handleFolders = (folder: Folder[]) => setFolders(folder);

  const [assets, setAssets] = useState<(Stack | Folder)[]>([]);
  const handleAssets = (assets: (Stack | Folder)[]) => setAssets(assets);

  const [currentPage, setCurrentPage] = React.useState(1);
  const handleCurrentPage = (page: number) => setCurrentPage(page);

  const [uploadCancelHook, setUploadCancelHook] = useState<AbortController | null>(null);
  const handleUploadCancelHook = (hook: AbortController | null) => setUploadCancelHook(hook);

  const [showLoaderByVaultId, setShowLoaderByVaultId] = useState<string[]>([]);
  const handleShowLoaderByVaultId = (id: string) => {
    const index = showLoaderByVaultId.indexOf(id);
    setShowLoaderByVaultId(prevState => {
      if (index === -1) prevState.push(id);
      else prevState.splice(index, 1);
      return prevState;
    });
  };

  const [axiosError, setAxiosError] = useState();
  const handleAxiosError = (err: any = undefined) => setAxiosError(err);

  const [filesNumber, setFilesNumber] = useState(0);
  const handleFilesNumber = (num: number) => setFilesNumber(num);

  const [uploadedFilesNumber, setUploadedFilesNumber] = useState(0);
  const handleUploadedFilesNumber = (num: number) => setUploadedFilesNumber(num);

  const [drawerActionType, setDrawerActionType] = useState<DrawerActionType>();
  const handleDrawerActionType = (action?: DrawerActionType) => setDrawerActionType(action);

  const [showUpload, setShowUpload] = useState(false);
  const handleShowUpload = (value: boolean) => setShowUpload(value);

  const [showFolderUpload, setShowFolderUpload] = useState(false);
  const handleFolderUpload = (value: boolean) => setShowFolderUpload(value);

  // indicator of adding a new version from a context menu
  const [showVersionUpload, setShowVersionUpload] = useState(false);
  const handleVersionUpload = (value: boolean) => setShowVersionUpload(value);

  // indicator of adding a zip file from a context menu
  const [showZipUpload, setZipUpload] = useState(false);
  const handleZipUpload = (value: boolean) => setZipUpload(value);

  const [showFilter, setShowFilter] = React.useState(false);
  const handleShowFilter = (value: boolean) => setShowFilter(value);

  const [actionFilters, setActionFilters] = useState<string[]>([]);
  const handleActionFilters = (filters: string[]) => setActionFilters(filters);

  const [periodFilter, setPeriodFilter] = useState<PeriodFilterKeys | null>(null);
  const handlePeriodFilter = (filters: PeriodFilterKeys | null) => setPeriodFilter(filters);

  const [order, setOrder] = useState<Order>("asc");
  const handleOrder = (order: Order) => setOrder(order);

  const [orderBy, setOrderBy] = useState<OrderBy>("name");
  const handleOrderBy = (orderBy: OrderBy) => setOrderBy(orderBy);

  const [isShiftDown, setIsShiftDown] = useState(false);

  const { akord, withTxSpinner } = useGlobalContext();
  const { unprocessedStorageTransaction } = useStorageContext() || {};
  const { keys } = useVaultContext();

  const stacksRef = React.useRef<Map<string, Stack>>(new Map());
  const foldersRef = React.useRef<Map<string, Folder>>(new Map());

  const isPublicRoute = !!location.pathname.match("/public"); // All anonymous rouse have `/public` prefix
  const vaultId = getVaultId(location.pathname);
  const folderId = getFolderId(location.pathname);
  const isRevokedAssetsTab = location.pathname.includes("/revoked-files");
  // const isAssetsTab = location.pathname.includes("/assets");
  // const isGallery = location.pathname.includes("/gallery");
  const vaultIdRef = React.useRef<string | null>(null);

  const loadStacks = React.useCallback(
    async (token: string = "", abortController: AbortController) => {
      const listOptions = {
        filter: {
          status: { ne: "DELETED" }
        },
        shouldDecrypt: !isPublicRoute,
        nextToken: token
      };

      const stacks = await akord!.stack.list(vaultId!, listOptions);
      if (abortController.signal.aborted) {
        return { items: [], nextToken: "" };
      }
      const items = stacks.items.map(s => {
        const stack = processStack(s) as Stack;
        stacksRef.current.set(stack.id, stack);
        return stack;
      });
      return { items: items, nextToken: stacks.nextToken };
    },
    [akord, vaultId, isPublicRoute]
  );

  const loadFolders = React.useCallback(
    async (token: string = "", abortController: AbortController) => {
      const listOptions = {
        filter: {
          status: { ne: "DELETED" }
        },
        shouldDecrypt: !isPublicRoute,
        nextToken: token
      };

      const folders = await akord!.folder.list(vaultId!, listOptions);
      if (abortController.signal.aborted) {
        return { items: [], nextToken: "" };
      }
      const items = folders.items.map(f => {
        const folder = processFolder(f) as Folder;
        foldersRef.current.set(folder.id, folder);
        return folder;
      });
      return { items: items, nextToken: folders.nextToken };
    },
    [akord, vaultId, isPublicRoute]
  );

  const loadAllFolders = async (token: string, abortController: AbortController) => {
    do {
      const { items, nextToken } = await loadFolders(token, abortController);
      if (!items.length || abortController.signal.aborted) {
        break;
      }
      const foldersChunk = Array.from(foldersRef.current.values());
      foldersChunk.sort((f1, f2) => (f2.name > f1.name ? -1 : 1));
      handleFolders(foldersChunk);
      handleAssetsDecrypted(true);
      token = nextToken;
    } while (token);
  };

  const loadAllStacks = async (token: string, abortController: AbortController) => {
    do {
      const { items, nextToken } = await loadStacks(token, abortController);
      if (!items.length || abortController.signal.aborted) {
        break;
      }
      const stacksChunk = Array.from(stacksRef.current.values());
      stacksChunk.sort((s1, s2) => (s2.name > s1.name ? -1 : 1));
      handleStacks(stacksChunk);
      handleAssetsDecrypted(true);
      token = nextToken;
    } while (token);
  };

  const fetchAssets = async (abortController: AbortController) => {
    let token: string = "";

    await Promise.all([loadAllFolders(token, abortController), loadAllStacks(token, abortController)]);
    handleAssetsDecrypted(true);
    handleAssets([...Array.from(foldersRef.current.values()), ...Array.from(stacksRef.current.values())]);
  };

  /**
   * Load of all assets
   */
  useEffect(() => {
    // To avoid race condition on assets loading
    // https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect#useeffect-clean-up-function-with-boolean-flag
    const abortController = new AbortController();

    handleAssetsDecrypted(false);
    handleStacks([]);
    handleFolders([]);
    stacksRef.current = new Map();
    foldersRef.current = new Map();
    // Keep current vault id fresh
    vaultIdRef.current = vaultId;
    if (akord && vaultId) {
      withTxSpinner(() => fetchAssets(abortController));
    }
    return () => {
      abortController.abort();
      handleStacks([]);
      handleFolders([]);
      stacksRef.current = new Map();
      foldersRef.current = new Map();
    };
  }, [akord, vaultId, withTxSpinner]);

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  // Empty batch selected items on route change
  // Reset Assets pagination
  useEffect(() => {
    if (!location.state?.isFromGallery && !location.pathname.includes("/gallery")) {
      handleSelectedItems("reset");
      handleCurrentPage(1);
    }
  }, [location]);

  useEffect(() => {
    const updateStackFromTransaction = (tx: Types.StorageTransaction) => {
      if (tx && tx.nodeId) {
        if (stacksRef.current.has(tx.nodeId)) {
          const stack = stacksRef.current.get(tx.nodeId);
          if (stack) {
            stack.versions[0].status = unprocessedStorageTransaction?.status as string;
            handleStackUpdate(stack);
          }
        }
      }
    };
    if (unprocessedStorageTransaction) {
      updateStackFromTransaction(unprocessedStorageTransaction);
    }
  }, [unprocessedStorageTransaction]);

  /**
   * Subscribe to new stacks to account for stacks created outside of app (CLI, API) or in async mode (zip)
   */
  useEffect(() => {
    let subscriptionOnCreate: ZenObservable.Subscription;
    const setupSubscription = async () => {
      try {
        if (!vaultId) return;
        subscriptionOnCreate = await GraphQLAPI.graphql({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onCreateStack,
          variables: {
            filter: {
              vaultId: { eq: vaultId }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: { value: { data: { onCreateStack: Types.GetStackQuery["getStack"] } } }) => {
            const stackProto = { ...value.data.onCreateStack!, versions: value.data.onCreateStack!.versions?.items };
            if (!stacksRef.current.has(stackProto.id)) {
              const stack = new Stack(stackProto, keys);
              if (!stack.__public__ && keys && keys.length) {
                await stack.decrypt();
              }
              handleStackCreate(stack);
            }
          },
          error: () => {
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Stack Subscription error: ", err);
      }
    };
    if (window.navigator.onLine && keys) setupSubscription();
    return () => {
      if (subscriptionOnCreate) subscriptionOnCreate.unsubscribe();
    };
  }, [vaultId, keys]);

  /**
   * PubSub for folders:
   * - created outside of app (CLI, API) or in async mode (zip)
   * - update size levels
   */
  useEffect(() => {
    let subscriptionOnCreate: ZenObservable.Subscription;
    let subscriptionOnUpdate: ZenObservable.Subscription;
    const setupSubscription = async () => {
      if (!vaultId) return;
      try {
        subscriptionOnCreate = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onCreateFolder,
          variables: {
            filter: {
              vaultId: { eq: vaultId }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: { value: { data: { onCreateFolder: Types.GetFolderQuery["getFolder"] } } }) => {
            const folderProto = value.data.onCreateFolder;
            if (folderProto && !foldersRef.current.has(folderProto.id)) {
              const folder = new Folder(folderProto, keys);
              if (!folder.__public__ && keys && keys.length) {
                await folder.decrypt();
              }
              handleFolderCreate(folder);
            }
          },
          error: (e: any) => {
            console.log(e);
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Subscription error: ", err);
      }
      try {
        subscriptionOnUpdate = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onUpdateFolder,
          variables: {
            filter: {
              vaultId: { eq: vaultId }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: { value: { data: { onUpdateFolder: Types.GetFolderQuery["getFolder"] } } }) => {
            const folderProto = value.data.onUpdateFolder;
            if (folderProto && foldersRef.current.has(folderProto.id)) {
              const folder = new Folder(folderProto, keys);
              if (!folder.__public__ && keys && keys.length) {
                await folder.decrypt();
              }
              handleFolderUpdate(folder);
            }
          },
          error: (e: any) => {
            console.log(e);
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Subscription error: ", err);
      }
    };
    if (window.navigator.onLine && keys) setupSubscription();
    return () => {
      if (subscriptionOnCreate) subscriptionOnCreate.unsubscribe();
      if (subscriptionOnUpdate) subscriptionOnUpdate.unsubscribe();
    };
  }, [vaultId, keys]);

  useEffect(() => {
    if (folderId) {
      refreshSize(folderId, "folder");
    }
  }, [folderId]);

  const handleFolderCreate = (folder: Folder) => {
    if (vaultIdRef.current !== folder.vaultId) return;
    foldersRef.current.set(folder.id, folder);
    const updatedFolders: Folder[] = [...Array.from(foldersRef.current.values())];
    handleFolders(updatedFolders);
    handleAssets([...stacks, ...updatedFolders]);
  };

  const handleStackCreate = (stack: Stack) => {
    // To deal with closure, checking actual vaultid against the one we upload to
    // Use case: Upload files, switch to a different vault while uploading, files would appear in the wrong vault
    if (vaultIdRef.current !== stack.vaultId) return;
    stacksRef.current.set(stack.id, stack);
    const updatedStacks = [...Array.from(stacksRef.current.values())];
    handleStacks(updatedStacks);
    handleAssets([...(folders || []), ...updatedStacks]);
  };

  /**
   * Optimistic stacks update
   */
  const handleStackUpdate = (stack: Stack) => {
    if (stacksRef.current.has(stack.id)) {
      stacksRef.current.set(stack.id, stack);
      const updatedStacks = Array.from(stacksRef.current.values());
      handleStacks(updatedStacks);
      handleAssets([...updatedStacks, ...(folders || [])]);
    }
  };

  const handleStackDelete = (id: string) => {
    stacksRef.current.delete(id);
    const updatedStacks = Array.from(stacksRef.current.values());
    handleStacks(updatedStacks);
    handleAssets([...updatedStacks, ...(folders || [])]);
  };

  /**
   * Optimistic folders update
   */
  const handleFolderUpdate = (folder: Folder) => {
    foldersRef.current.set(folder.id, folder);
    const updatedFolders = Array.from(foldersRef.current.values());
    handleFolders(updatedFolders);
    handleAssets([...updatedFolders, ...(stacks || [])]);
  };

  const handleFolderDelete = (id: string) => {
    foldersRef.current.delete(id);
    const updatedFolders = Array.from(foldersRef.current.values());
    handleFolders(updatedFolders);
    handleAssets([...updatedFolders, ...(stacks || [])]);
  };

  const revokedStacks = useMemo(() => stacks?.filter(stack => stack.status === itemStatusEnum.REVOKED) || [], [stacks]);
  const revokedFolders = useMemo(() => folders?.filter(folder => folder.status === itemStatusEnum.REVOKED) || [], [folders]);
  const revokedDecryptedAssets = useMemo(() => [...revokedStacks, ...revokedFolders], [revokedStacks, revokedFolders]);

  const handleSelectedItems = (hash: string, checked?: boolean, revoked?: boolean) => {
    if (hash === "allItemsOnPage") {
      if (checked) {
        const assetsIds = revoked
          ? revokedDecryptedAssets.reduce((acc: Map<string, SelectedItem>, item: Stack | Folder) => {
              // if (
              //   // item.folderId === folderId &&
              //   item.status === itemStatusEnum.REVOKED
              // )
              return acc.set(item.id, {
                id: item.id,
                size: item.size,
                objectType: getAssetType(item)
              });
              // else return acc;
            }, new Map())
          : getPaginatedData().reduce((acc: Map<string, SelectedItem>, item: Stack | Folder) => {
              if (item.parentId === folderId)
                return acc.set(item.id, {
                  id: item.id,
                  size: item.size,
                  objectType: getAssetType(item)
                });
              else return acc;
            }, new Map());

        setSelectedItemsMap(assetsIds);
      } else {
        setSelectedItemsMap(new Map());
      }
    } else if (hash === "allItems") {
      if (revoked) {
        if (selectedItemsMap.size < revokedDecryptedAssets.length) {
          const assetsIds = revokedDecryptedAssets.reduce((acc: Map<string, SelectedItem>, item: Stack | Folder) => {
            return acc.set(item.id, {
              id: item.id,
              size: item.size,
              objectType: getAssetType(item)
            });
          }, new Map());

          setSelectedItemsMap(assetsIds);
        } else {
          setSelectedItemsMap(new Map());
        }
      } else {
        if (selectedItemsMap.size < filteredSortedActiveAssets.length) {
          const assetsIds = filteredSortedActiveAssets.reduce((acc: Map<string, SelectedItem>, item: Stack | Folder) => {
            if (item.parentId === folderId)
              return acc.set(item.id, {
                id: item.id,
                size: item.size,
                objectType: getAssetType(item)
              });
            else return acc;
          }, new Map());
          setSelectedItemsMap(assetsIds);
        } else {
          setSelectedItemsMap(new Map());
        }
      }
    } else if (hash === "reset") {
      setSelectedItemsMap(new Map());
    } else {
      const hasBeenSelected = selectedItemsMap.has(hash);

      if (isShiftDown) {
        // Multiple items select
        const newSelectedItems = getNewSelectedItems(hash);
        let selections = new Map([...selectedItemsMap, ...newSelectedItems]);
        // Unselect
        if (hasBeenSelected) {
          selections = new Map(Array.from(selections.entries()).filter(([key]) => !newSelectedItems.has(key)));
        }
        setSelectedItemsMap(selections);
      } else {
        // Single item select
        const selectedItemsMapCopy = new Map(selectedItemsMap);
        const item = revoked
          ? revokedDecryptedAssets.filter((item: Stack | Folder) => item?.id === hash)[0]
          : getPaginatedData().filter(item => item.id === hash)[0];

        selectedItemsMapCopy.has(hash)
          ? selectedItemsMapCopy.delete(hash)
          : selectedItemsMapCopy.set(item?.id, {
              id: item?.id,
              objectType: getAssetType(item),
              size: item?.size
            });

        setSelectedItemsMap(selectedItemsMapCopy);
      }
    }
  };

  const getNewSelectedItems = (hash: string) => {
    const visibleItems = isRevokedAssetsTab ? (filteredSortedRevokedAssets as (Stack | Folder)[]) : getPaginatedData();

    const currentSelectedIndex = visibleItems.findIndex(item => item.id === hash);
    const lastSelectedIndex = visibleItems.findIndex(item => item.id === lastSelectedItem);
    return visibleItems
      .slice(Math.min(lastSelectedIndex, currentSelectedIndex), Math.max(lastSelectedIndex, currentSelectedIndex) + 1)
      .reduce((acc, item) => acc.set(item.id, { id: item.id, objectType: getAssetType(item) }), new Map());
  };

  const activeStacks = useMemo(
    () => stacks?.filter(stack => stack.status !== itemStatusEnum.REVOKED && stack.status !== itemStatusEnum.DELETED),
    [stacks]
  );

  //TODO temporary - replace with fetch per page
  const perPageStacks = useMemo(
    () =>
      activeStacks?.filter(stack => {
        if (!stack.parentId && !folderId) return true;
        else return stack.parentId === folderId;
      }),
    [activeStacks, folderId]
  );

  const activeFolders = useMemo(
    () => folders?.filter(folder => folder.status !== itemStatusEnum.REVOKED && folder.status !== itemStatusEnum.DELETED),
    [folders]
  );

  const filteredSortedActiveAssets: (Stack | Folder)[] = useMemo(() => {
    const filteredStacks = [...(activeStacks ? activeStacks : [])].filter(item => {
      if (!item.parentId && !folderId) return true;
      else return item.parentId === folderId;
    });
    const filteredFolders = [...(activeFolders ? activeFolders : [])].filter(item => {
      if (!item.parentId && !folderId) return true;
      else return item.parentId === folderId;
    });
    const sortedStacks = stableSort(filterStacks(filteredStacks, periodFilter, actionFilters), getComparator(order, orderBy)) as Stack[];
    const sortedFolders = stableSort(filterStacks(filteredFolders, periodFilter, actionFilters), getComparator(order, orderBy)) as Folder[];
    return [...sortedFolders, ...sortedStacks];
  }, [activeStacks, activeFolders, periodFilter, actionFilters, order, orderBy, folderId]);

  const filteredSortedRevokedAssets = useMemo(
    () => stableSort(filterStacks(revokedDecryptedAssets, periodFilter, actionFilters), getComparator(order, orderBy)),
    [revokedDecryptedAssets, periodFilter, actionFilters, order, orderBy]
  );

  const pages = useMemo(() => Math.ceil(filteredSortedActiveAssets.length / assetsLimit) || null, [filteredSortedActiveAssets]);

  const goToNextPage = React.useCallback(() => handleCurrentPage(currentPage + 1), [currentPage]);

  const goToPreviousPage = React.useCallback(() => handleCurrentPage(currentPage - 1), [currentPage]);

  const changePage = (event: NumberChangeEvent) => {
    const selectValue = typeof event === "number" ? event : event.target.value;
    handleCurrentPage(Number(selectValue));
  };

  const getPaginatedData = React.useCallback(() => {
    const startIndex = currentPage * assetsLimit - assetsLimit;
    const endIndex = startIndex + assetsLimit;
    return filteredSortedActiveAssets.slice(startIndex, endIndex);
  }, [filteredSortedActiveAssets, currentPage]);

  // For Batch selection
  const downHandler = ({ key }: { key: string }) => {
    if (key === "Shift") {
      setIsShiftDown(true);
    }
  };

  const upHandler = ({ key }: { key: string }) => {
    if (key === "Shift") {
      setIsShiftDown(false);
    }
  };

  return (
    <Context.Provider
      value={{
        actionFilters: actionFilters,
        activeFolders: activeFolders,
        activeStacks: activeStacks,
        assets: assets,
        assetsDecrypted: assetsDecrypted,
        axiosError: axiosError,
        currentPage: currentPage,
        drawerActionType: drawerActionType,
        filesNumber: filesNumber,
        filteredSortedActiveAssets: filteredSortedActiveAssets,
        folders: folders,
        getPaginatedData: getPaginatedData,
        isShiftDown: isShiftDown,
        onActionFilters: handleActionFilters,
        onAxiosError: handleAxiosError,
        onChangePage: changePage,
        onDrawerActionType: handleDrawerActionType,
        onFilesNumber: handleFilesNumber,
        onFolderCreate: handleFolderCreate,
        onFolderDelete: handleFolderDelete,
        onFolderUpdate: handleFolderUpdate,
        onFolderUpload: handleFolderUpload,
        onGoToNextPage: goToNextPage,
        onGoToPreviousPage: goToPreviousPage,
        onLastSelectedItem: handleLastSelectedItem,
        onOrder: handleOrder,
        onOrderBy: handleOrderBy,
        onPeriodFilter: handlePeriodFilter,
        onSelectedItems: handleSelectedItems,
        onShowFilter: handleShowFilter,
        onShowLoaderByVaultId: handleShowLoaderByVaultId,
        onShowUpload: handleShowUpload,
        onStackCreate: handleStackCreate,
        onStackDelete: handleStackDelete,
        onStackUpdate: handleStackUpdate,
        onUploadCancelHook: handleUploadCancelHook,
        onUploadedFilesNumber: handleUploadedFilesNumber,
        onVersionUpload: handleVersionUpload,
        onZipUpload: handleZipUpload,
        showZipUpload,
        order: order,
        orderBy: orderBy,
        pages: pages,
        periodFilter: periodFilter,
        perPageStacks: perPageStacks,
        selectedItemsMap: selectedItemsMap,
        showFilter: showFilter,
        showFolderUpload: showFolderUpload,
        showLoaderByVaultId,
        showUpload: showUpload,
        showVersionUpload: showVersionUpload,
        stacks: stacks,
        uploadCancelHook: uploadCancelHook,
        uploadedFilesNumber: uploadedFilesNumber
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default withRouter(AssetsContextProvider);

export const withAssetsContext = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{assetsContext => <Component {...props} {...assetsContext} />}</Context.Consumer>;

export const useAssetsContext = () => useContext(Context);
