import React from "react";
import { Box, ClassNameMap, IconButton, Tooltip } from "@mui/material";
import { FileRestoreIcon, MinusInCircleIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { Vault } from "@akord/akord-js";

type ArchivedVaultsToolbarProps = {
  classes: ClassNameMap<string>;
  selectedVaultsMap: Map<string, Vault>;
  handleClick: (name: string) => Promise<void>;
};

const ArchivedVaultsToolbar: React.FC<ArchivedVaultsToolbarProps> = ({ classes, selectedVaultsMap, handleClick }) => {
  const { readOnlyMode } = useGlobalContext();
  return (
    <>
      <Box className={classes.icons}>
        <Tooltip title="Restore" arrow>
          <span>
            <IconButton onClick={() => handleClick("restoreVaults")} disabled={selectedVaultsMap.size === 0 || readOnlyMode}>
              <FileRestoreIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Remove" arrow>
          <span>
            <IconButton onClick={() => handleClick("removeVaults")} disabled={selectedVaultsMap.size === 0 || readOnlyMode}>
              <MinusInCircleIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </>
  );
};

export default ArchivedVaultsToolbar;
