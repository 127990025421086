import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import createDOMPurify from "dompurify";
import linkifyHtml from "linkifyjs/html";
import React, { useEffect, useState } from "react";
import { useChatContext } from "../../../contexts/ChatContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { formatDate, formatDateAtTime } from "../../../helpers/helpers";
import { grey } from "../../../theme/colors";

const DOMPurify = createDOMPurify(window);

//https://github.com/cure53/DOMPurify/issues/317
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }
});

const useStyles = makeStyles(theme =>
  createStyles({
    memoBubble: {
      display: "inline-block",
      lineHeight: 1.4,
      padding: "8px 12px",
      borderRadius: "4px"
    },
    link: {
      borderBottom: "none",
      borderBottomColor: theme.palette.text.primary,
      color: theme.palette.text.tertiary
    },
    reactionPicker: {
      display: "flex",
      gap: 1,
      padding: 7,
      position: "absolute",
      borderRadius: 8,
      background: ({ darkMode }) => (darkMode ? grey[800] : "#FFF"),
      filter: ({ darkMode }) => (darkMode ? "" : `drop-shadow(0 4px 4px ${grey[500]})`),
      "@media (max-width: 410px)": {
        marginRight: "70%",
        left: 0
      },
      "@media (max-width: 998px)": {
        marginRight: "10%"
      }
    },
    buttonRoot: {
      padding: "8px",
      "&:hover": {
        backgroundColor: ({ darkMode }) => (darkMode ? grey[900] : grey[200])
      }
    },
    reactionCount: {
      marginLeft: 5,
      marginBottom: 14,
      marginRight: 8,
      fontSize: 10,
      color: ({ darkMode }) => (darkMode ? grey[400] : grey[500])
    },
    spaceBetween: {
      display: "flex",
      justifyContent: "space-between"
    },
    flexEnd: {
      display: "flex",
      alignItems: "flex-end"
    }
  })
);

function MemoWrite({ positionedBeforeDate, operation, color }) {
  const { darkMode, isMobile, userAttributes } = useGlobalContext();
  const { getAuthor } = useChatContext();

  const [memoReactions, setMemoReactions] = useState(null);

  const classes = useStyles({
    color: color,
    darkMode: darkMode,
    isMobile: isMobile
  });

  const memoContainer = React.useRef();

  useEffect(() => {
    if (operation.memo.versions[0].reactions?.length > 0) {
      const reactions = new Map();
      operation.memo.versions[0].reactions.map(reactionData => {
        const reactionsData = reactions.get(reactionData.reaction) || [];
        reactionsData.push(reactionData);
        reactions.set(reactionData.reaction, reactionsData);
      });
      setMemoReactions(reactions);
    }
  }, [operation]);

  const hasReactions = () => {
    return memoReactions && memoReactions.size;
  };

  const emojiFromDecimalString = decimalString => {
    const codePoints = decimalString.split(",").map(string => parseInt(string));
    try {
      return String.fromCodePoint(...codePoints);
    } catch (e) {
      return null;
    }
  };

  const makeEmojiBigger = text => {
    const emojiRegex = /([\ud800-\udbff])([\udc00-\udfff])/g;
    const updateText = text?.replace(
      emojiRegex,
      match =>
        `<span style=font-size:${text.length === 2 ? "32px" : "20px"};line-height:${text.length === 2 ? "32px" : "20px"}>${match}</span>`
    );
    return updateText;
  };

  const hasContainerRightSpace = () => {
    const memoContainerWidth = memoContainer?.current?.getBoundingClientRect()?.width;
    return screen.width > 920 || memoContainerWidth < 250;
  };

  const formatTooltipText = reactions => {
    if (!reactions) {
      return null;
    }
    const tooltipText = new Map();
    reactions.forEach(reaction => {
      const date = reaction.createdAt ? formatDateAtTime(reaction.createdAt) : "";
      const memberDetails = getAuthor(reaction.address);
      if (userAttributes.address === reaction.address) {
        tooltipText.set("You", date);
      } else {
        tooltipText.set(memberDetails?.name, date);
      }
    });
    return Array.from(tooltipText.entries()).map((text, idx) => {
      return (
        <div key={idx}>
          <b>{text[0]}</b> {text[1]}
        </div>
      );
    });
  };

  const linkOptions = {
    defaultProtocol: "https",
    className: classes.link,
    target: { url: "_blank", email: "_blank" },
    validate: true
  };

  return (
    <Box
      style={{
        marginBottom: positionedBeforeDate ? "74px" : hasReactions() ? "0px" : "16px"
      }}
    >
      <Box className={classes.flexEnd}>
        <div
          style={{
            maxWidth: "440px"
          }}
        >
          <div
            className={classes.memoBubble}
            style={{ border: `1px solid ${color}` }}
            data-color={color}
            id="memo-container"
            ref={memoContainer}
          >
            <Box className={classes.spaceBetween}>
              <Box>
                <Tooltip title={operation.ownerInfo?.email} arrow>
                  <Typography variant="body2" component="span" className="small strong" color="text.primary" sx={{ marginRight: 2 }}>
                    {operation.ownerInfo?.name || operation.ownerInfo?.email}
                  </Typography>
                </Tooltip>
                <Typography variant="caption" className="small" color="text.secondary">
                  {formatDate(operation.createdAt, true)}
                </Typography>
              </Box>

              <Box style={{ display: "flex", alignItems: "center" }}></Box>
            </Box>
            <Box className={classes.spaceBetween}>
              <Typography
                paragraph
                variant="body2"
                className="small"
                style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(linkifyHtml(makeEmojiBigger(operation.memo.versions[0].message), linkOptions))
                }}
              ></Typography>
            </Box>
          </div>
        </div>

        <Box
          style={{
            display: "flex",
            justifyContent: hasContainerRightSpace() ? "center" : "flex-end",
            minWidth: "20px"
          }}
        ></Box>
      </Box>
      <Box style={{ display: "flex", marginTop: "3px" }}>
        {memoReactions &&
          Array.from(memoReactions.entries()).map((entry, index) => {
            const [emojiId, reactions] = entry;
            return (
              <Box className={classes.flexEnd} key={index}>
                <Tooltip
                  title={<React.Fragment>{formatTooltipText(reactions)}</React.Fragment>}
                  arrow
                  style={{ paddingLeft: 15 }}
                  key={index}
                >
                  <Typography
                    paragraph
                    variant="body2"
                    className="small"
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "break-spaces",
                      marginTop: 2
                    }}
                  >
                    <IconButton size="large" disabled>
                      <Box fontSize={16} lineHeight={"16px"}>
                        {emojiFromDecimalString(emojiId)}
                      </Box>
                    </IconButton>
                  </Typography>
                </Tooltip>
                <Typography variant="caption" className={classes.reactionCount}>
                  {reactions.length}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}

export default MemoWrite;
