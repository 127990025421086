import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  Box,
  FormControl,
  Divider,
  Button,
  TextareaAutosize,
  Typography,
  FormControlLabel,
  Theme,
  SelectChangeEvent,
  AutocompleteChangeReason
} from "@mui/material";
import InviteToRoomForm from "./InviteToRoomForm";
import CircularProgress from "@mui/material/CircularProgress";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SupportScreenWrapper } from "../../components/common";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import ConfirmationError from "../../components/common/ConfirmationError";
import { AkordSwitch } from "../../components/common/AkordSwitch/AkordSwitch";
import AirdropForm from "./AirdropForm";
import { AkordWallet } from "@akord/crypto";
import { inviteToOrg } from "../../helpers/api-helpers";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useOrgContext } from "../../contexts/OrgContextProvider";
import { Types } from "@akord/gql";
import dayjs, { Dayjs } from "dayjs";
import { bytesToSize } from "../storage/storage-helper";
import { RoleType } from "@akord/akord-js";
import { MembershipInviteItem } from "@akord/akord-js/lib/core/batch";

export type InviteFormProps = {
  id?: number;
  email: string;
  role?: "CONTRIBUTOR" | "VIEWER" | "MEMBER" | "OWNER";
  //adding these fields which are coming from error
  //TODO needs refactor
  message?: string;
  error?: Error;
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    containedPrimary: {
      minWidth: "auto"
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute"
    },
    textArea: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontWeight: 400,
      padding: 0,
      fontSize: "1rem",
      color: theme.palette.text.primary,
      border: "none",
      "&::placeholder": {
        color: theme.palette.text.secondary
      }
    }
  })
);

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let count = 0;

const initialInvite: InviteFormProps = {
  id: 0,
  email: "",
  role: "CONTRIBUTOR"
};

interface LocationState extends Location {
  id: string;
  name: string;
}

const InviteToRoom: React.FC<RouteComponentProps> = ({ location }) => {
  const [invalidUsers, setInvalidUsers] = useState<InviteFormProps[]>([]);
  const [alreadyInvited, setAlreadyInvited] = useState<InviteFormProps[]>([]);
  const [inviteForm, setInviteForm] = useState([initialInvite]);
  const [inviteMessage, setInviteMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [inviteError, setInviteError] = useState(null);
  const [airdropChecked, setAirdropChecked] = React.useState(false);
  const [airdropInviteDetails, setAirdropInviteDetails] = React.useState<{
    emailExpirationDate: Dayjs | null;
    allowedStorage: number | null;
  }>({
    emailExpirationDate: null,
    allowedStorage: 0 // Store in bytes, so that it's easier to convert to different sizes
  });
  const [airdropUrl, setAirdropUrl] = useState<string>();
  const [dateError, setDateError] = useState();
  const handleDateError = (setError: any) => setDateError(setError);

  const roomData = (location.state as LocationState) || null;
  const { org } = useOrgContext();
  const { akord, profileDetails, isMobile, darkMode, onTxSpinner } = useGlobalContext();
  // const { isFeatureAllowed } = usePaymentContext();
  const { modal } = useNotificationsContext();
  const { onVaultUpdate: onVaultMembershipData } = useVaultContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const classes = useStyles({ darkMode: darkMode, isMobile: isMobile });

  const handleAirdropChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAirdropChecked(event.target.checked);
    // Only one email at a time can be invited with airdrop, remove the rest on airdrop on if present
    // Reset form on switching back from airdrop
    if (inviteForm.length > 1) {
      if (event.target.checked) setInviteForm((prevState) => prevState.slice(0, 1));
    } else if (!event.target.checked) setInviteForm([initialInvite]);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, elIndex: number) => {
    setInviteForm(inviteForm.map((el) => (el.id === elIndex ? { ...el, [e.target.name]: e.target.value } : el)));
  };

  const handleAutoCompleteFormChange =
    (elIndex: number) => (event: React.SyntheticEvent<Element, Event>, value: Types.User, reason: AutocompleteChangeReason) => {
      if (reason === "clear") handleEmailClear(elIndex, inviteForm[elIndex]);
      else {
        // To avoid duplicate invite
        if (inviteForm.some((invite) => invite.email === value?.email)) return;
        setInviteForm(inviteForm.map((el) => (el.id === elIndex ? { ...el, email: value?.email || "" } : el)));
      }
    };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInviteMessage(e.target.value);
  };

  const isButtonDisabled = () => {
    const allInvitesRegex = inviteForm.every(
      (invite, index) => (invite && invite.email.match(emailRegex) && invite.role) || (index !== 0 && invite.email === "")
    );
    // Check if a valid email provided for regular invited
    if (allInvitesRegex && !loading && !airdropChecked) return false;
    // Check if date and any text provided for airdrop invite
    else if (airdropChecked && !loading && inviteForm[0]?.email.length > 0 && airdropInviteDetails.emailExpirationDate && !dateError)
      return false;
    else return true;
  };

  React.useEffect(() => {
    //clearing warnings
    const invalidUsersActive = invalidUsers.filter((obj) => {
      return inviteForm.some((obj2) => {
        if (obj2) {
          return obj.email === obj2.email;
        } else return false;
      });
    });
    if (invalidUsersActive) setInvalidUsers(invalidUsersActive);
    //clearing warnings
    const alreadyInvitedActive = alreadyInvited.filter((obj) => {
      return inviteForm.some((obj2) => {
        if (obj2) {
          return obj.email === obj2.email;
        } else return false;
      });
    });
    if (alreadyInvitedActive) setAlreadyInvited(alreadyInvitedActive);
    // Disable auto appeared email fields for airdrop access - we invite one at a time
    if (!airdropChecked) {
      if (inviteForm[count] && !!inviteForm[count].email.match(emailRegex)) {
        count++;
        setInviteForm([...inviteForm, { id: count, email: "", role: "CONTRIBUTOR" }]);
      }
      if (inviteForm[count - 1] && !inviteForm[count - 1].email && count !== 0) {
        setInviteForm(inviteForm.filter((item) => item.id !== count));
        count--;
      }
    }
  }, [inviteForm]);

  const handleInviteForm = async () => {
    if (airdropChecked && airdropUrl) {
      setInviteForm([initialInvite]);
      setAirdropInviteDetails({ emailExpirationDate: null, allowedStorage: 0 });
      setAirdropUrl(undefined);
      return;
    }
    setLoading(true);
    const filteredInvitees = inviteForm.filter((invite) => invite.email);
    let response;
    let inviteUrl;
    try {
      if (airdropChecked) {
        const wallet = await AkordWallet.create();
        const address = await wallet.getAddress();
        const email = `${address}@temp.akord.com`;
        const { host, protocol } = window.location;
        const referrer = `${protocol}//${host}`;
        await akord?.membership.airdrop(roomData?.id, [
          {
            publicKey: wallet.publicKey(),
            publicSigningKey: wallet.signingPublicKey(),
            role: filteredInvitees[0].role as RoleType,
            options: {
              expirationDate: new Date(dayjs(airdropInviteDetails.emailExpirationDate).endOf("day").toISOString()),
              allowedStorage: Math.floor(bytesToSize(airdropInviteDetails.allowedStorage, "MB").size), // Send in MB - converting here
              name: filteredInvitees[0].email
            }
          }
        ]);
        if (org?.id && profileDetails?.orgRole === Types.OrganisationRole.ADMIN) {
          await inviteToOrg(org.id, email, false, referrer, true);
        }
        inviteUrl = `${referrer}/vaults/active/${roomData?.id}/assets?wallet=${btoa(wallet?.backupPhrase)}`;
      } else {
        const invitees = filteredInvitees.map((invite) => {
          return { email: invite.email, role: invite.role } as MembershipInviteItem;
        });
        response = await akord?.batch.membershipInvite(roomData?.id, invitees, { message: inviteMessage });
      }
      setLoading(false);
      if (response?.errors && response.errors.length) {
        //TODO needs refactor - looks like a hack to set error here
        setAlreadyInvited(response.errors);
        onSnackbarToShow("actionFailure", null, "error");
      } else {
        if (airdropChecked) {
          setAirdropUrl(inviteUrl);
          onVaultMembershipData();
          count = 0;
        } else {
          onSnackbarToShow("dataRoomInvite", filteredInvitees.length > 1 ? filteredInvitees.length : null);
          onVaultMembershipData();
          history.back();
          count = 0;
        }
      }
      onTxSpinner(false);
    } catch (err: any) {
      console.warn("Invitation error", err);
      if (err?.statusCode === 403) {
        modal.onConfirmModalType("membersUpgradeTier");
        modal.onModalVisibility(null, "membersUpgradeTier");
      } else {
        setInviteError(err);
        setLoading(false);
        onTxSpinner(false);
        onSnackbarToShow("actionFailure", null, "error");
      }
    }
  };

  const handleEmailClear = (elIndex: number, item: InviteFormProps) => {
    if (elIndex === 0 && inviteForm.filter((user) => user.email !== "").length === 1) {
      setInviteForm([
        {
          id: 0,
          email: "",
          role: "CONTRIBUTOR"
        }
      ]);
      count = 0;
    } else {
      setInviteForm(inviteForm.filter((el) => el.email !== item.email));
      count--;
    }
    setInviteError(null);
    setInvalidUsers(invalidUsers.filter((user) => user.email !== item.email));
    setAlreadyInvited(alreadyInvited.filter((user) => user.email !== item.email));
  };

  if (!roomData) return null;

  return (
    <SupportScreenWrapper title="Invite to vault" subtitle={roomData.name} referral={org?.subdomain}>
      <Box width={!isMobile ? "100%" : "inherit"} mt={6}>
        <Box mb={5}>
          <FormControlLabel
            sx={{ marginLeft: 0, marginBottom: 2 }}
            control={<AkordSwitch checked={airdropChecked} onChange={handleAirdropChange} name="airdropChecked" />}
            label={
              <Typography variant="body1" className="strong" color="text.primary" sx={{ marginLeft: 3 }}>
                Airdrop access
              </Typography>
            }
          />
          <Typography variant="body2" className="small">
            By selecting airdrop access, anyone you invite will not need an Akord account to access the vault.
          </Typography>
        </Box>
        {inviteForm?.map((item, index) => (
          <InviteToRoomForm
            key={index}
            index={index}
            // item={item}
            // darkMode={darkMode}
            isMobile={isMobile}
            inviteForm={inviteForm}
            handleFormChange={handleFormChange}
            onAutoCompleteFormChange={e => handleAutoCompleteFormChange(index)}
            handleEmailClear={handleEmailClear}
            invalidUsers={invalidUsers}
            alreadyInvited={alreadyInvited}
            airdropChecked={airdropChecked}
          />
        ))}
        {isMobile && <Divider style={{ marginTop: "24px" }} />}
        <Box mt={6}>
          {airdropChecked ? (
            <AirdropForm
              airdropInviteDetails={airdropInviteDetails}
              onAirdropInviteDetails={setAirdropInviteDetails}
              airdropUrl={airdropUrl}
              onDateError={handleDateError}
              dateError={dateError}
              isViewOnly={inviteForm.some(invite => invite.role === "VIEWER")}
            />
          ) : (
            <FormControl fullWidth sx={{ marginBottom: 8 }}>
              <TextareaAutosize
                id="enter-message"
                name="message"
                // type="text"
                placeholder="Add an optional message... &#13;&#10;Please note, it will be sent unencrypted in an email notification."
                onChange={handleMessageChange}
                className={classes.textArea}
              />
            </FormControl>
          )}
        </Box>
      </Box>
      <Button
        variant={airdropUrl && airdropChecked ? "outlined" : "contained"}
        color="primary"
        type="button"
        disableElevation
        classes={{
          containedPrimary: classes.containedPrimary
        }}
        // style={error && { background: '#DB443C' }}
        disabled={isButtonDisabled()}
        fullWidth={isMobile}
        onClick={handleInviteForm}
      >
        {airdropChecked ? (airdropUrl ? "Reset and generate another " : "Generate link") : "Invite to vault"}
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
      {inviteError && (
        <Box>
          <ConfirmationError error={inviteError} data={{ vaultId: roomData.id }} type="dataRoomInvite" />
        </Box>
      )}
    </SupportScreenWrapper>
  );
};

export default withRouter(InviteToRoom);
