import axios from "axios";
import { Auth, Membership } from "@akord/akord-js";
import { API_URL, AVATAR_URL } from "./env";
import { ConfirmationErrorData } from "../components/common/ConfirmationError";
import { ConfirmationErrorTypes } from "../components/common/Modals/confirmation-errors-texts";
import { Types } from "@akord/gql";
import { StorageProducts, SubscriptionPlansResponse, SubscriptionPlanSetupResponse, UserInvoiceResponse } from "../types/stripeTypes";
import { MetadataParam, PaymentMethodCreateParams } from "@stripe/stripe-js";

type AbstractObject = { [key: string]: string | number };

const stringifyError = function (err: ConfirmationErrorData) {
  var errorObj: AbstractObject = {};
  Object.getOwnPropertyNames(err).forEach(key => (errorObj[key] = err[key as keyof ConfirmationErrorData]));
  return JSON.stringify(errorObj);
};

export const sendErrorToSupport = async (
  error: ConfirmationErrorData,
  notificationData: ConfirmationErrorData | undefined,
  modal: ConfirmationErrorTypes
) => {
  try {
    const errorStringified = stringifyError(error);
    const userAgent = navigator.userAgent;
    const token = await Auth.getAuthorization();
    const response = await axios({
      url: `${API_URL}/support`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: { ...notificationData, action: modal, error: errorStringified, userAgent: userAgent },
      timeout: 50000
    });
    console.warn("Error was sent to support. Issue ID:", response.data.issueId);
  } catch (err) {
    console.error("Error sending to support: ", err);
  }
};

export const sendReferralEmail = async (emailArray: string[]) => {
  try {
    const token = await Auth.getAuthorization();
    return await axios({
      url: `${API_URL}/referrals`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: { emails: emailArray },
      timeout: 50000
    });
  } catch (err) {
    console.error("Error sending a referral: ", err);
    return;
  }
};

export const requestAccountDeletion = async (email: string) => {
  try {
    const token = await Auth.getAuthorization();
    return await axios({
      url: `${API_URL}/users/${email}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
  } catch (err) {
    console.error("Error sending an account deletion request: ", err);
    return;
  }
};

export const uploadAvatar = async (address: string, avatar: Uint8Array | null, contentType: string) => {
  const url = `${AVATAR_URL}/${address}`;
  await fetch(url, {
    method: "PUT",
    body: avatar,
    headers: {
      "Content-Type": contentType,
      "Content-Encoding": "base64"
    }
  });
};

export const isUrlCorrect = async (url: string) => {
  try {
    const res = await fetch(url, {
      method: "HEAD"
    });
    if (res.status !== 200) return false;
    else return true;
  } catch (err) {
    console.log("Unable to fetch url", err);
    return false;
  }
};

export const getStorage = async () => {
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: `${API_URL}/storage`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return storage.data as Types.Storage;
  } catch (err) {
    console.error("Error fetching storage: ", err);
    return undefined;
  }
};

export const refreshStorage = async (address: string) => {
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: `${API_URL}/storage`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: {
        message: JSON.stringify({
          eventType: 'storage-balance-refresh',
          eventId: address
        })
      },
      timeout: 50000
    });
    return storage.data as Types.Storage;
  } catch (err) {
    console.error("Error fetching storage: ", err);
    return;
  }
};

export const refreshSize = async (id: string, type: 'vault' | 'folder') => {
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: `${API_URL}/size`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: {
        message: JSON.stringify({
          eventType: `${type}-size-refresh`,
          eventId: id
        })
      },
      timeout: 50000
    });
    return storage.data as Types.Storage;
  } catch (err) {
    console.error("Error fetching storage: ", err);
    return;
  }
};

export const getStorageTransactions = async (data: { limit: number; nextToken?: string | null }) => {
  const params: { limit?: number; nextToken?: string | undefined } = {};
  if (data.limit) {
    params.limit = data.limit;
  }
  if (data.nextToken) {
    params.nextToken = data.nextToken;
  }
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      // @ts-ignore
      url: `${API_URL}/storage/transactions?${new URLSearchParams(params).toString()}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return storage.data as Types.ModelStorageTransactionConnection;
  } catch (err) {
    console.error("Error fetching storage: ", err);
    return;
  }
};

export const getOrg = async (subdomain?: string) => {
  try {
    const token = await Auth.getAuthorization();
    if (!token && !subdomain) return;
    const org = await axios({
      url: subdomain ? `${API_URL}/organisations?subdomain=${subdomain}` : `${API_URL}/organisations`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return org.data as Types.Organisation;
  } catch (err) {
    return;
  }
};

export const createOrg = async (data: Types.Organisation) => {
  const token = await Auth.getAuthorization();
  return await axios({
    url: `${API_URL}/organisations`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    data: data,
    timeout: 50000
  });
};

export const updateOrg = async (data: Types.Organisation) => {
  const params: any = {};
  Object.keys(data).forEach(key => {
    if (data[key as keyof Types.Organisation] !== null && data[key as keyof Types.Organisation] !== "") {
      params[key] = data[key as keyof Types.Organisation];
    }
  });

  const token = await Auth.getAuthorization();
  return await axios({
    url: `${API_URL}/organisations/${data.id}`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    data: params,
    timeout: 50000
  });
};

export const getUsersFromOrg = async (id: string) => {
  try {
    const token = await Auth.getAuthorization();
    const users = await axios({
      url: `${API_URL}/organisations/${id}/members`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return users.data;
  } catch (err) {
    console.error("Error users from group: ", err);
    return;
  }
};

export const inviteToOrg = async (id: string, email: string, isAdmin: boolean, referrer: string, skipEmail?: boolean) => {
  const token = await Auth.getAuthorization();
  await axios({
    url: `${API_URL}/organisations/${id}/members`,
    method: "POST",
    data: {
      email: email,
      isAdmin: isAdmin,
      referrer: referrer,
      skipEmail: skipEmail
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    timeout: 50000
  });
};

export const removeFromOrg = async (id: string, email: string) => {
  const token = await Auth.getAuthorization();
  await axios({
    url: `${API_URL}/organisations/${id}/members/${email}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    timeout: 50000
  });
};

export const getStorageProducts = async () => {
  try {
    const token = await Auth.getAuthorization();
    const products = await axios({
      url: `${API_URL}/products`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return products.data as StorageProducts;
  } catch (err) {
    console.error("Error getting storage prices: ", err);
    return;
  }
};

export const getSubscriptionPlans = async (currencyCode: string) => {
  try {
    const token = await Auth.getAuthorization();
    const res = await axios({
      url: `${API_URL}/subscription-plans?currency=${currencyCode?.toLowerCase()}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return res.data as SubscriptionPlansResponse;
  } catch (err) {
    console.error("Error getting subscription data: ", err);
    return;
  }
};

export const getSubscriptionPlan = async (name: string, currencyCode: string) => {
  try {
    const token = await Auth.getAuthorization();
    const res = await axios({
      url: `${API_URL}/subscription-plans/${name}?currency=${currencyCode?.toLowerCase()}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return res.data;
  } catch (err) {
    console.error("Error getting subscription data: ", err);
    return;
  }
};

export const setupSubscription = async (id: string | undefined, data: { currency: string; quantity?: number; coupon?: string }) => {
  const token = await Auth.getAuthorization();
  const res = await axios({
    url: `${API_URL}/subscription-plans/${id}?${new URLSearchParams(data as any).toString()}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    timeout: 50000
  });
  return res.data as SubscriptionPlanSetupResponse;
};

export const simulateSubscription = async (id: string, data: { currency: string; quantity: number; coupon?: string }) => {
  const token = await Auth.getAuthorization();
  const res = await axios({
    url: `${API_URL}/subscription-plans/${id}/simulate?${new URLSearchParams(data as any).toString()}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    timeout: 50000
  });
  return res.data;
};

export const getBilling = async (id?: string) => {
  try {
    const token = await Auth.getAuthorization();
    const billing = await axios({
      url: `${API_URL}/users/${id}/billing`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return billing.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

export const putBilling = async (
  id: string,
  billing: {
    paymentMethodId?: string | null;
    billingData: {
      billing_details: PaymentMethodCreateParams.BillingDetails;
      metadata: MetadataParam;
    };
  }
) => {
  const token = await Auth.getAuthorization();
  await axios({
    url: `${API_URL}/users/${id}/billing`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    data: billing,
    timeout: 50000
  });
};

export const getInvoices = async (data: { limit: number; lastInvoiceId?: string }) => {
  try {
    const token = await Auth.getAuthorization();
    const queryString = new URLSearchParams(data as any).toString();
    const invoices = await axios({
      url: `${API_URL}/invoices?${queryString}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return invoices.data as UserInvoiceResponse;
  } catch (err) {
    console.error(err);
    return;
  }
};

export const initPayment = async (
  data: {
    productId?: string;
    quantity: number;
    currencyCode: string;
  },
  storage: number
) => {
  const token = await Auth.getAuthorization();
  const payment = await axios({
    url: `${API_URL}/payments?storage=${storage}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    data: data,
    timeout: 50000
  });
  return payment.data;
};

export const confirmPayment = async (paymentId: string, customerData: any) => {
  try {
    const token = await Auth.getAuthorization();
    const users = await axios({
      url: `${API_URL}/payments/${paymentId}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: customerData,
      timeout: 50000
    });
    return users.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

export const getInvites = async (email?: string) => {
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: `${API_URL}/users/${email}/invites`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return storage.data as Membership[];
  } catch (err) {
    console.error("Error fetching invites: ", err);
    return;
  }
};

export const getNotifications = async () => {
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: `${API_URL}/notifications`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return storage.data as Types.ModelNotificationConnection;
  } catch (err) {
    console.error("Error fetching invites: ", err);
    return;
  }
};

export const readNotifications = async (params: { id?: string; vaultId?: string }) => {
  let url = `${API_URL}/notifications`;
  if (params?.id) {
    url += `/${params.id}`;
  }
  if (params?.vaultId) {
    url += `?vaultId=${params.vaultId}`;
  }
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: url,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return storage.data as Types.Notification[];
  } catch (err) {
    console.error("Error fetching invites: ", err);
    return;
  }
};

export const deleteNotifications = async (params: { status?: Types.NotificationStatus; id?: string }) => {
  let url = `${API_URL}/notifications`;
  if (params?.id) {
    url += `/${params.id}`;
  }
  if (params?.status) {
    url += `?status=${params.status.toLowerCase()}`;
  }
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: url,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return storage.data;
  } catch (err) {
    console.error("Error fetching invites: ", err);
    return;
  }
};

export const getApiKey = async () => {
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: `${API_URL}/api-keys`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return storage.data as string;
  } catch (err) {
    console.error("Error fetching invites: ", err);
    return;
  }
};

export const generateApiKey = async () => {
  const token = await Auth.getAuthorization();
  const apiKey = await axios({
    url: `${API_URL}/api-keys`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    timeout: 50000
  });
  return apiKey.data as string;
};

export const deleteApiKey = async () => {
  try {
    const token = await Auth.getAuthorization();
    const storage = await axios({
      url: `${API_URL}/api-keys`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return storage.data;
  } catch (err) {
    console.error("Error deleting API Key: ", err);
    return;
  }
};

export const getWebhooks = async () => {
  try {
    const token = await Auth.getAuthorization();
    const webhooks = await axios({
      url: `${API_URL}/webhooks`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return webhooks.data;
  } catch (err) {
    console.error("Error fetching webhooks: ", err);
    return;
  }
};

export const createWebhook = async (url: string) => {
  try {
    const token = await Auth.getAuthorization();
    await axios({
      url: `${API_URL}/webhooks`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: {
        url: url
      },
      timeout: 50000
    });
  } catch (err) {
    console.error("Error generating Webhook: ", err);
    return;
  }
};

export const deleteWebhook = async (id: string) => {
  try {
    const token = await Auth.getAuthorization();
    await axios({
      url: `${API_URL}/webhooks/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
  } catch (err) {
    console.error("Error deleting Webhook: ", err);
    return;
  }
};

export const getWebhookLogs = async (data: { limit: number; nextToken?: string | null }) => {
  const params: { limit?: number; nextToken?: string | undefined } = {};
  if (data.limit) {
    params.limit = data.limit;
  }
  if (data.nextToken) {
    params.nextToken = data.nextToken;
  }
  try {
    const token = await Auth.getAuthorization();
    const logs = await axios({
      // @ts-ignore
      url: `${API_URL}/webhooks/logs?${new URLSearchParams(params).toString()}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return logs.data;
  } catch (err) {
    console.error("Error fetching webhook logs: ", err);
    return;
  }
};

export const claimUToken = async (address: string, amount: number) => {
  try {
    const token = await Auth.getAuthorization();
    const result = await axios({
      url: `${API_URL}/claims`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data: { address, amount },
      timeout: 50000
    });
    return result.data?.txId;
  } catch (err: any) {
    console.error("Error claiming token: ", err?.response?.data?.message);
    return;
  }
};

export const getUTokenBalance = async () => {
  try {
    const token = await Auth.getAuthorization();
    const uBalance = await axios({
      url: `${API_URL}/utoken`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      timeout: 50000
    });
    return uBalance.data as { balance: number };
  } catch (err) {
    console.error("Error fetching token balance: ", err);
    return;
  }
};
