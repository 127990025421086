import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Skeleton, Link, Theme } from "@mui/material";
import { withRouter, useHistory } from "react-router-dom";
import { SupportScreenWrapper } from "../../components/common";
import { InfinityIcon, SecureCloudIcon, TickIcon } from "@akord/addon-icons";
import { formatSizeFromGB } from "../storage/storage-helper";
import CurrencySelect from "../../components/payment/CurrencySelect";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { setupSubscription } from "../../helpers/api-helpers";
import { formatDate } from "../../helpers/helpers";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import OutlinedSelect from "../../components/common/Select/OutlinedSelect";
import { blue } from "../../theme/colors";
import { SubscriptionPlan, ScheduledSubscriptionPlanPhase } from "../../types/stripeTypes";

const useStyles = makeStyles<Theme>(theme => ({
  icon: {
    transition: ".3s ease"
  },
  box: {
    border: "1px solid",
    borderColor: theme.palette.background.cardBorder,
    borderRadius: "8px",
    padding: theme.spacing(6),
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "16px"
  },
  discount: {
    backgroundColor: blue[500],
    color: theme.palette.background.default,
    padding: `${theme.spacing(1)}`
  }
}));

const PricingPlans: React.FC = () => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [upcomingPlan, setUpcomingPlan] = useState<ScheduledSubscriptionPlanPhase | null>(null);
  const [planExpanded, setPlanExpanded] = useState("");
  const [cloudStorage, setCloudStorage] = useState(new Map());
  const classes = useStyles();
  const history = useHistory();
  const { currencyCode, subscriptionPlan, fetchSubscriptionPlans, getCurrencySymbol, isOnPaidPlan } = usePaymentContext();
  const { modal, onNotificationData, notificationData } = useNotificationsContext();
  const { isMobile } = useGlobalContext();

  useEffect(() => {
    getPlans();
  }, [currencyCode]);

  const select = async (plan: SubscriptionPlan) => {
    const queryParams = new URLSearchParams({ plan: plan.name, quantity: cloudStorage.get(plan.name) });
    history.push(`/account/plans-and-payments/billing?${queryParams.toString()}`);
  };

  const getPlans = async () => {
    const subscriptions = await fetchSubscriptionPlans();
    setPlans(subscriptions.plans);
    subscriptions.plans.forEach(plan => {
      if (plan.subscription) {
        cloudStorage.set(plan.name, parseFloat(plan.subscription.quantity));
      } else {
        if (plan.metadata.cloud_gb.includes(";")) {
          cloudStorage.set(plan.name, parseFloat(plan.metadata.cloud_gb.split(";")[0]));
        } else {
          cloudStorage.set(plan.name, parseFloat(plan.metadata.cloud_gb));
        }
      }
    });
    setCloudStorage(new Map(cloudStorage));
    if (subscriptions.scheduledPlans && subscriptions.scheduledPlans.length) {
      setUpcomingPlan(subscriptions.scheduledPlans[0].phases[0]);
    } else {
      setUpcomingPlan(null);
    }
  };

  const cancelScheduled = async () => {
    setPlans([]);
    await setupSubscription(subscriptionPlan?.name, { currency: currencyCode.toLowerCase() });
    await getPlans();
  };

  const cancelCurrent = async () => {
    setPlans([]);
    await setupSubscription(plans[0].name, { currency: currencyCode.toLowerCase() });
    await getPlans();
  };

  const isCurrent = (plan: SubscriptionPlan) => {
    if (subscriptionPlan) {
      return plan.id === subscriptionPlan.id;
    }
    return false;
  };

  const isFree = (plan: SubscriptionPlan) => {
    const tiers = plan.price.currency_options[`${currencyCode?.toLowerCase()}`].tiers;
    return tiers && tiers[0].flat_amount === 0;
  };

  const isUpcoming = (plan: SubscriptionPlan) => {
    if (upcomingPlan?.items?.length) {
      return plan.id === upcomingPlan.items[0].price.product;
    }
    return false;
  };

  const getTopUpPrice = (plan: SubscriptionPlan) => {
    return plan.topUpPrice.currency_options[`${currencyCode?.toLowerCase()}`].unit_amount / 100;
  };

  const getPrice = (plan: SubscriptionPlan) => {
    const tiers = plan.price.currency_options[`${currencyCode?.toLowerCase()}`].tiers;
    const flatAmount = tiers?.[0].flat_amount || 0;
    const unitAmount = parseFloat(tiers?.[0].unit_amount_decimal || "0");
    return (flatAmount + unitAmount * cloudStorage.get(plan.name)) / 100;
  };

  const renderFeature = (feat: string) => (
    <Box key={feat} display="flex" mt={2}>
      <TickIcon fontSize="small" sx={{ marginRight: 2 }} color="secondary" />
      <Typography variant="body2" className="small">
        {feat}
      </Typography>
    </Box>
  );

  const subscriptionPlanTiers = subscriptionPlan?.price.currency_options[`${currencyCode?.toLowerCase()}`].tiers;

  return (
    <SupportScreenWrapper
      title="Pricing plans"
      route="/account/plans-and-payments"
      component={
        <CurrencySelect
          disabled={isOnPaidPlan()}
          disabledTile="Currency can not be changed for existing subscription. 
          To update the currency, please cancel the subscription and start it with the currency of choice"
        />
      }
    >
      <Box mb={7} mt={7}>
        {!plans.length || !plans[0].price.currency_options[`${currencyCode?.toLowerCase()}`].tiers ? (
          <Box>
            {Array.from(new Array(3)).map((_, index) => (
              <Box key={index} display="flex" flexDirection="row" marginTop={6}>
                {Array.from(new Array(3)).map((i, idx) => (
                  <Skeleton key={idx} variant="rectangular" height={100} width="100%" />
                ))}
              </Box>
            ))}
          </Box>
        ) : (
          plans.map(plan => {
            const tiers = plan.price.currency_options[`${currencyCode?.toLowerCase()}`].tiers;
            return (
              <Box key={plan.name} className={classes.box} sx={{ backgroundColor: isCurrent(plan) ? "background.card" : "initial" }}>
                <Accordion
                  sx={{
                    width: "100%",
                    backgroundColor: isCurrent(plan) ? "background.card" : "initial",
                    "& .Mui-expanded": {
                      minHeight: "48px!important",
                      margin: "0!important"
                    }
                  }}
                  expanded={planExpanded === plan.name}
                  elevation={0}
                >
                  <AccordionSummary
                    aria-controls={`${plan.name}-content`}
                    id={`${plan.name}-header`}
                    sx={{
                      padding: 0,
                      "& .MuiAccordionSummary-root": {
                        cursor: "default"
                      },
                      "& .MuiAccordionSummary-content": {
                        margin: 0
                      }
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%" sx={{ cursor: "default" }}>
                      <Box>
                        <Box display="flex" flexDirection={isMobile ? "column" : "row"} sx={{ whiteSpace: "pre" }}>
                          <Typography variant="h3" color="text.primary">
                            {plan.name}{" "}
                          </Typography>
                          <Typography variant="h3" color="text.secondary" pr={2} sx={{ fontWeight: "400" }}>
                            {tiers && tiers[0].flat_amount > 0
                              ? `${getCurrencySymbol(currencyCode)}${getPrice(plan)} / ${plan.price.recurring?.interval}`
                              : "Free"}
                          </Typography>
                        </Box>
                        {isCurrent(plan) && (
                          <Box>
                            {upcomingPlan && (
                              <Typography variant="caption" className="small">
                                Subscription ends {formatDate((parseInt(upcomingPlan.start_date) * 1000).toString(), false, false, true)}
                              </Typography>
                            )}
                          </Box>
                        )}
                        {isUpcoming(plan) && (
                          <Box>
                            <Typography variant="caption" className="small">
                              Subscription starts{" "}
                              {formatDate((parseInt(upcomingPlan?.start_date || "0") * 1000).toString(), false, false, true)}
                            </Typography>
                          </Box>
                        )}
                        {planExpanded !== plan.name && (
                          <Link
                            variant="caption"
                            className="small"
                            onClick={() => setPlanExpanded(plan.name)}
                            color="text.secondary"
                            sx={{ borderBottomColor: "text.secondary" }}
                          >
                            View details
                          </Link>
                        )}
                      </Box>
                      <Box>
                        {isCurrent(plan) ? (
                          <Box textAlign="right">
                            <Box
                              display="flex"
                              justifyContent="end"
                              flexDirection={isMobile ? "column-reverse" : "inherit"}
                              alignItems="center"
                            >
                              {!isFree(plan) && !upcomingPlan && (
                                <Button
                                  disableElevation
                                  variant="text"
                                  size="small"
                                  onClick={() => {
                                    modal.onConfirmModalType("cancelCurrentPlan");
                                    modal.onModalVisibility(null, "cancelCurrentPlan");
                                    onNotificationData({ ...notificationData, cancelCurrentPlan: cancelCurrent });
                                  }}
                                  sx={{
                                    marginRight: isMobile ? 0 : 2,
                                    marginTop: isMobile ? 3 : 0,
                                    "&.MuiButtonBase-root": {
                                      "&:hover": {
                                        backgroundColor: "background.cardBorder"
                                      }
                                    }
                                  }}
                                >
                                  Cancel
                                </Button>
                              )}
                              <Button
                                variant="outlined"
                                disableElevation
                                disabled={true}
                                size="small"
                                sx={{
                                  "&.MuiButtonBase-root": {
                                    "&.MuiButton-root": { "&.Mui-disabled": { color: "text.secondary", borderColor: "text.secondary" } }
                                  }
                                }}
                              >
                                Current plan
                              </Button>
                            </Box>
                          </Box>
                        ) : isUpcoming(plan) ? (
                          <Box textAlign="right">
                            <Button size="small" variant="text" disableElevation onClick={() => cancelScheduled()}>
                              <Typography variant="body2" className="strong" color="inherit">
                                Cancel
                              </Typography>
                            </Button>
                          </Box>
                        ) : !isFree(plan) ? (
                          <Button size="small" variant="outlined" disableElevation onClick={() => select(plan)}>
                            {subscriptionPlanTiers && tiers && subscriptionPlanTiers[0].flat_amount < tiers[0].flat_amount && "Upgrade"}
                            {subscriptionPlanTiers && tiers && subscriptionPlanTiers[0].flat_amount > tiers[0].flat_amount && "Downgrade"}
                          </Button>
                        ) : null}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {plan.metadata.blockchain_features && renderFeature(plan.metadata.blockchain_features)}
                    {plan.metadata.blockchain_airdrop_gb &&
                      renderFeature(`${formatSizeFromGB(parseFloat(plan.metadata.blockchain_airdrop_gb))} permanent storage per month`)}
                    {plan.metadata.app_vaults && renderFeature(`${plan.metadata.app_vaults} vaults`)}
                    {plan.metadata.app_members && renderFeature(`${plan.metadata.app_members} members per vault`)}
                    {plan.metadata.cloud_features && plan.metadata.cloud_features.split(";").map(feat => renderFeature(feat))}

                    <Box>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" className="strong" color="text.primary">
                          Permanent storage
                        </Typography>
                        <InfinityIcon sx={{ fontSize: "24px", marginLeft: 2 }} color="secondary" />
                      </Box>
                      <Box display="flex" alignItems="center">
                        {renderFeature(`Top up for ${getCurrencySymbol(currencyCode)}${getTopUpPrice(plan)} per GB`)}
                        {plan.topUpPrice.discount ? (
                          <Typography
                            variant="body2"
                            className={[classes.discount, "strong", "small"].join(" ")}
                            sx={{ marginLeft: 2, marginTop: 2 }}
                          >
                            {`SAVE ${plan.topUpPrice.discount}%`}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>

                    {plan.metadata.cloud_gb && (
                      <Box>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2" className="strong" color="text.primary" marginTop={4} marginBottom={2}>
                            Secure Cloud Storage
                          </Typography>
                          <SecureCloudIcon sx={{ fontSize: "24px", marginLeft: 2 }} />
                        </Box>
                        {plan.metadata.cloud_gb.includes(";") ? (
                          <OutlinedSelect
                            options={plan.metadata.cloud_gb.split(";").map(gb => ({ key: formatSizeFromGB(parseFloat(gb)), value: gb }))}
                            onSelect={value => setCloudStorage(new Map(cloudStorage.set(plan.name, value)))}
                            defaultValue={cloudStorage.get(plan.name)}
                            disabled={isCurrent(plan)}
                          />
                        ) : (
                          renderFeature(formatSizeFromGB(parseFloat(plan.metadata.cloud_gb)))
                        )}
                      </Box>
                    )}
                    {plan.metadata.app_features && (
                      <Box>
                        <Typography variant="body2" className="strong" color="text.primary" marginTop={4}>
                          Special features
                        </Typography>
                        {plan.metadata.app_features.split(";").map(feat => renderFeature(feat))}
                      </Box>
                    )}
                    {(plan.metadata.blockchain_annual_topup_limit_gb || plan.metadata.blockchain_topup_discount) && (
                      <Typography variant="body2" className="strong" color="text.primary" marginTop={4}>
                        Blockchain top ups
                      </Typography>
                    )}
                    {plan.metadata.blockchain_annual_topup_limit_gb &&
                      renderFeature(`${formatSizeFromGB(parseFloat(plan.metadata.blockchain_annual_topup_limit_gb))} annual top-up limit`)}
                    {plan.metadata.blockchain_topup_discount &&
                      renderFeature(`${plan.metadata.blockchain_topup_discount}% discount on top ups`)}
                    {plan.metadata.blockchain_features && plan.metadata.blockchain_features.split(";").map(feat => renderFeature(feat))}

                    <Typography variant="body2" className="strong" color="text.primary" marginTop={4}>
                      API
                    </Typography>
                    {plan.usagePlans?.limit &&
                      renderFeature(`${plan.usagePlans?.limit.toLocaleString()} uploads per ${plan.usagePlans?.period?.toLowerCase()}`)}
                    <Typography variant="body2" className="strong" color="text.primary" marginTop={4}>
                      Support
                    </Typography>
                    {plan.metadata.support && renderFeature(`${plan.metadata.support}`)}
                    {plan.metadata.support_features && plan.metadata.support_features.split(";").map(feat => renderFeature(feat))}

                    <Box mt={4}>
                      <Link
                        variant="caption"
                        className="small"
                        onClick={() => setPlanExpanded("")}
                        color="text.secondary"
                        sx={{ borderBottomColor: "text.secondary" }}
                      >
                        Close details
                      </Link>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })
        )}
      </Box>
    </SupportScreenWrapper>
  );
};

export default withRouter(PricingPlans);
