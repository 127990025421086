import React from 'react'
import OperationTitleWithIcon from '../../../components/common/OperationTitle/OperationTitleWithIcon'

function MembershipAccept(props) {
  const { operation, positionedBeforeDate, color } = props

  return (
    <div style={{ marginBottom: positionedBeforeDate ? '74px' : '16px' }}>
      <OperationTitleWithIcon 
          actionRef={operation.actionRef} 
          color={color} />
    </div>
  )
}

export default MembershipAccept
