import { matchPath } from "react-router-dom";
import { StackIcon, VideoIcon, AudioIcon, NoteIcon, ManifestIcon } from "@akord/addon-icons";
import { ImageIcon, DocumentIcon } from "@akord/addon-icons";
// import { filterActionRefs, filterActions } from "./akord-enums";
import { Collection, Folder, NFT, Stack, Vault } from "@akord/akord-js";
import { Order, OrderBy, OrderByTransaction } from "../types/globalDataTypes";
import { TransactionTableData } from "../pages/storage/TransactionsTable";

export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const DEFAULT_AVATAR_CONTENT_TYPE = "image/png";

export const formatDate = (
  unixTimestamp: string | undefined,
  justTime = false,
  logTime = false,
  justDate = false,
  logTimeForTransactions = false
) => {
  if (!unixTimestamp) unixTimestamp = new Date().valueOf().toString();
  const todayDate = new Date();
  const fullDate = new Date(parseInt(unixTimestamp));
  const year = fullDate.getFullYear();
  const month = (fullDate.getMonth() + 1).toString().padStart(2, "0"); //January is 0
  const date = fullDate.getDate().toString().padStart(2, "0");
  const hours = fullDate.getHours();
  const minutes = fullDate.getMinutes();
  const formatedDate = `${date}/${month}/${year} at ${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  const formatedDateWithoutHours = `${date}/${month}/${year}`;
  const formatedHours = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;

  const diffTime = todayDate.valueOf() - fullDate.valueOf();
  const diffDay = Math.floor(diffTime / 86400000); // days
  const diffHrs = Math.floor((diffTime % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diffTime % 86400000) % 3600000) / 60000); // minutes

  if (logTime) {
    if (diffDay >= 1) return `${date} ${months[parseInt(month) - 1]}`;
    if (diffHrs === 0 && diffMins < 5) return "Just now";
    else return formatedHours;
  }
  if (logTimeForTransactions) {
    if (diffHrs === 0 && diffMins <= 1) return "Just now";
    if (diffDay === 0 && diffHrs < 1) return `${diffMins} ${diffMins > 1 ? "mins" : "min"} ago`;
    if (diffDay < 1) return `${diffHrs} ${diffHrs > 1 ? "hrs" : "hr"} ago`;
    if (diffDay < 2) return "Yesterday";
    else return formatedDateWithoutHours;
  }

  if (justDate) return `${date}/${month}/${year}`;
  if (justTime) return formatedHours;
  else return formatedDate;
  // return fullDate.toLocaleString([], { hour: '2-digit', minute: '2-digit' })
};

export const formatDateAtTime = (date: string) => {
  const d = new Date(parseInt(date));
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  const ho = new Intl.DateTimeFormat("en", {
    hourCycle: "h23",
    hour: "numeric"
  }).format(d);
  const mi = new Intl.DateTimeFormat("en", {
    hourCycle: "h23",
    minute: "2-digit"
  }).format(d);
  return `${da}-${mo}-${ye} @ ${ho}:${mi}`;
};

export const descendingComparator = (
  a: StableSortItem | TransactionTableData,
  b: StableSortItem | TransactionTableData,
  orderBy: OrderBy | OrderByTransaction
): number => {
  if (a.dataItemId && b.dataItemId) {
    return (a[orderBy as OrderByTransaction] ? a[orderBy as OrderByTransaction] : 0)
      .toString()
      .localeCompare((b[orderBy as OrderByTransaction] ? b[orderBy as OrderByTransaction] : 0).toString(), undefined, {
        numeric: true,
        sensitivity: "base"
      });
  } else {
    return ((a as StableSortItem)[orderBy as OrderBy] ? (a as StableSortItem)[orderBy as OrderBy] : 0)
      .toString()
      .localeCompare(((b as StableSortItem)[orderBy as OrderBy] ? (b as StableSortItem)[orderBy as OrderBy] : 0).toString(), undefined, {
        numeric: true,
        sensitivity: "base"
      });
  }
};

export const getComparator = (order: Order, orderBy: OrderBy | OrderByTransaction) => {
  return order === "desc"
    ? (a: StableSortItem | TransactionTableData, b: StableSortItem | TransactionTableData) => -descendingComparator(a, b, orderBy)
    : (a: StableSortItem | TransactionTableData, b: StableSortItem | TransactionTableData) => descendingComparator(a, b, orderBy);
};

type StableSortItem = Stack | Folder | Vault | NFT | Collection;

export const stableSort = (
  array: StableSortItem[] | TransactionTableData[],
  comparator: (a: StableSortItem | TransactionTableData, b: StableSortItem | TransactionTableData) => number
) => {
  // if (!array) return;
  const stabilizedThis: [StableSortItem | TransactionTableData, number][] = array.map(
    (el: StableSortItem | TransactionTableData, index: number) => [el, index]
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const sortedItemsByDate = (timeline: any) => {
  const sorted = new Map();
  const todayFullDate = new Date();
  const todayMonth = todayFullDate.getMonth();
  const todayDate = todayFullDate.getDate();

  timeline.sort((a: any, b: any) => {
    return new Date(a.postedAt).valueOf() - new Date(b.postedAt).valueOf();
  });

  const arrayToGroupedObjects = timeline.reduce((acc: any, value: any) => {
    if (acc.length === 0 || acc[acc.length - 1][0].groupRef !== value.groupRef || value.groupRef === null || value.groupRef === "null") {
      return [...acc, [value]];
    } else {
      acc[acc.length - 1].push(value);
      return acc;
    }
  }, []);

  const groupedTimeline = arrayToGroupedObjects.map((arr: any) => {
    if (arr.length === 1 && (arr[0].groupRef === null || arr[0].groupRef === "null")) {
      return { ...arr[0], group: arr };
    } else {
      return {
        actionRef: arr[0].actionRef,
        groupRef: arr[0].groupRef,
        ownerInfo: arr[0].ownerInfo,
        postedAt: arr[0].postedAt,
        contextVersion: arr[0].contextVersion,
        resourceVersion: arr[0].resourceVersion,
        group: arr
      };
    }
  });

  groupedTimeline.forEach((item: any) => {
    // if (filterActionRefs.includes(item.actionRef)) return
    const fullDate = new Date(item.postedAt);
    const month = fullDate.getMonth();
    const date = fullDate.getDate();
    const day = fullDate.getDay();

    const dateToShow =
      todayMonth === month && todayDate === date
        ? "Today"
        : todayMonth === month && todayDate - date === 1
        ? "Yesterday"
        : `${days[day]}, ${months[month]} ${date}`;

    if (sorted.has(dateToShow)) {
      const data = sorted.get(dateToShow);
      data.push(item);
      sorted.set(dateToShow, data);
    } else sorted.set(dateToShow, [item]);
  });

  return sorted;
};

export const downloadTxtFile = (backupPhrase: string[], userEmail: string) => {
  const element = document.createElement("a");
  const file = new Blob(generateText(backupPhrase, userEmail), {
    type: "text/plain"
  });
  element.href = URL.createObjectURL(file);
  element.download = "Akord-backup-phrase.txt";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const generateText = (backupPhrase: string[], userEmail: string) => {
  const phrase = backupPhrase
    .map((word: string, index: number) => {
      const tabOrBrk = (index + 1) % 3 === 0 ? `\n` : `\t\t`;
      const space = index + 1 <= 9 ? " " : "";
      return `${space}${index + 1}. ${word}${tabOrBrk}`;
    })
    .join("");
  const text = `AKORD\n\nSAVE YOUR RECOVERY PHRASE\nKeep this recovery phrase somewhere safe but accessible.\n\n${phrase}\n\n(${userEmail})\n\n*Phrase was generated on ${new Date()}`;
  return [text];
};

export const copyToClipboard = (dataToCopy: string | undefined, setCopy: (value: boolean) => void) => {
  if (!dataToCopy) return;
  navigator.clipboard.writeText(dataToCopy);
  if (setCopy) {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  }
};

// export const getVaultStatusFromMembership = (membership) => {
//   switch (membership?.vault?.status) {
//     case "ACTIVE":
//       return "active";
//     case "ARCHIVED":
//       return "archived";
//     case "DELETED":
//       return "deleted";
//     default:
//       return null;
//   }
// };

export const getVaultStatus = (pathname: string) => {
  const isPublicRoute = !!pathname.match("/public");
  const matchProfile = matchPath<{ status: string }>(pathname, {
    path: isPublicRoute ? `/public/vaults/:status?` : `/vaults/:status?`
  });
  return matchProfile?.params?.status || null;
};

export const getVaultId = (pathname: string) => {
  const isPublicRoute = !!pathname.match("/public");
  const matchProfile = matchPath<{ dataRoomId: string }>(pathname, {
    path: isPublicRoute ? `/public/vaults/:status?/:dataRoomId?` : `/vaults/:status?/:dataRoomId?`
  });
  return matchProfile?.params?.dataRoomId || null;
};

export const getFolderId = (pathname: string) => {
  const isPublicRoute = !!pathname.match("/public");
  const matchProfile = matchPath<{ folderId: string }>(pathname, {
    path: isPublicRoute
      ? `/public/vaults/:status/:dataRoomId/:page/folders/:folderId`
      : `/vaults/:status/:dataRoomId/:page/folders/:folderId`
  });
  return matchProfile?.params?.folderId || null;
};

export const getStackId = (pathname: string) => {
  const matchProfile = matchPath<{ stackId: string }>(pathname, {
    path: `/vaults/:status/:dataRoomId/:roomView/stack/:stackId`
  });
  return matchProfile?.params?.stackId || null;
};

export const getNoteId = (pathname: string) => {
  const matchProfile = matchPath<{ noteId: string }>(pathname, {
    path: `/vaults/:status/:dataRoomId/:roomView/note/:noteId`
  });
  return matchProfile?.params?.noteId || null;
};

export const getRevokedFolderId = (pathname: string) => {
  const matchProfile = matchPath<{ folderId: string }>(pathname, {
    path: `/vaults/:status/:dataRoomId/revoked-files/folders/:folderId`
  });
  return matchProfile?.params?.folderId || null;
};

export const getMintType = (pathname: string) => {
  const matchProfile = matchPath<{ mintType: string }>(pathname, {
    path: `/vaults/:status/:dataRoomId/nfts/mint/:mintType`
  });
  return matchProfile?.params?.mintType || null;
};

export const getCollectionId = (pathname: string) => {
  const isPublicRoute = !!pathname.match("/public");
  const matchCollectionId = matchPath<{ collectionId: string }>(pathname, {
    path: isPublicRoute
      ? `/public/vaults/:status/:dataRoomId/nfts/collection/:collectionId`
      : `/vaults/:status/:dataRoomId/nfts/collection/:collectionId`
  });
  return matchCollectionId?.params?.collectionId || null;
};

export const getNftId = (pathname: string) => {
  const isPublicRoute = !!pathname.match("/public");
  const isCollection = !!pathname.match("/collection");
  const matchCollectionId = matchPath<{ nftId: string }>(pathname, {
    path: isPublicRoute
      ? isCollection
        ? `/public/vaults/:status/:dataRoomId/nfts/collection/:collectionId/:nftId`
        : `/public/vaults/:status/:dataRoomId/nfts/:nftId`
      : isCollection
      ? `/vaults/:status/:dataRoomId/nfts/collection/:collectionId/:nftId`
      : `/vaults/:status/:dataRoomId/nfts/:nftId`
  });
  return matchCollectionId?.params?.nftId || null;
};

// Collect all parent folders for folder's titles breadcrumbs navigation
export const getAllFoldersRecursively = (folders: Folder[], currentFolder: Folder) => {
  const folderMap: { [key: string]: Folder } = {};
  folders.forEach(folder => (folderMap[folder.id] = folder));

  let allFolders: Folder[] = [];

  const parentFoldersRecursive = (childFolder: Folder) => {
    const parentFolder = folderMap[childFolder.parentId!];
    if (parentFolder) {
      allFolders.push(parentFolder);
      parentFoldersRecursive(parentFolder);
    } else return;
  };
  parentFoldersRecursive(currentFolder);
  return allFolders.reverse();
};

const FILE_ICONS = {
  image: ImageIcon,
  video: VideoIcon,
  audio: AudioIcon,
  markdown: NoteIcon,
  note: NoteIcon,
  "manifest.json": ManifestIcon
};

export const getFileIcon = (item: Stack | undefined) => {
  if (item?.files?.length > 1) {
    return StackIcon;
  }

  const fileType = item?.versions?.[0]?.type || "";
  const icon = Object.entries(FILE_ICONS).find(([key]) => fileType.includes(key))?.[1];

  return icon || DocumentIcon;
};

export const getUploadFileIcon = (file: File) => {
  const fileType = file?.type || "";

  const icon = Object.entries(FILE_ICONS).find(([key]) => fileType.startsWith(key))?.[1];

  return icon || DocumentIcon;
};

export const getTotalUploadSize = (fileList: File[] = []) => {
  let totalSize = 0;
  for (const file of fileList) {
    totalSize += file.size || 0;
  }
  return totalSize;
};

// export const isActionFiltered = action => {
//   const matchingFilter = filterActions.find(item => action.actionRef === item.actionRef && item.filter(action));
//   return filterActionRefs.includes(action.actionRef) || !!matchingFilter;
// };

export const getResourceUri = (uris: string[] | undefined, resource: string) => {
  if (!uris) return null;
  //@ts-ignore
  const uri = uris?.findLast((uri: string) => uri.startsWith(`${resource}:`));
  if (uri) {
    return uri.replace(`${resource}:`, "");
  }
  return null;
};

export const truncateString = (value: string | undefined, partsLength: number) => {
  if (!value) return;
  if (value.length - partsLength > partsLength)
    return value?.substring(0, partsLength) + "..." + value?.substring(value.length - partsLength);
  else return value;
};

export const getContentTypeFromUrl = (photoUrl: string): string => {
  const match = photoUrl.match(/^data:([^;]+);/);
  return match ? match[1] : DEFAULT_AVATAR_CONTENT_TYPE;
};
