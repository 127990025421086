import React from "react";
import { Auth } from "@akord/akord-js";
import makeStyles from "@mui/styles/makeStyles";
import { Snackbar, Typography, Box, Checkbox, IconButton, Link } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { snackbarOptions } from "./snackbar-options";
import { AddMemberIcon, CheckboxTickIcon, CheckboxEmptyIcon, CloseIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  filledSuccess: {
    backgroundColor: "#288032"
  },
  root: {
    padding: 12,
    paddingRight: 16
  },
  icon: {
    fontSize: 24,
    padding: 0,
    marginRight: 8
  },
  warning: {
    color: ({ error }) => (error ? theme.palette.error.main : theme.palette.primary.light)
  },
  action: { alignItems: "baseline" },
  message: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontWeight: 400,
    fontSize: 16,
    padding: 0,
    marginTop: 2,
    whiteSpace: "pre-line"
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },

  boxChecked: {
    "&:hover": {
      backgroundColor: "transparent!important"
    }
  }
}));

function SnackBarConfirmUpload() {
  const { snackbarToShow, onSnackbarClose } = useSnackbarContext();
  const { userAttributes, onUserAttributes } = useGlobalContext();
  const classes = useStyles({
    error: snackbarOptions[snackbarToShow.type].error
  });

  const history = useHistory();

  const handleChange = async event => {
    const notify = event.target.checked;
    onUserAttributes({ notifyOnUpload: notify });
    await Auth.updateUserAttribute("custom:notifyOnUpload", notify.toString());
  };

  const SnackBarIcon = snackbarOptions[snackbarToShow.type]?.Icon ? snackbarOptions[snackbarToShow.type].Icon : AddMemberIcon;

  return (
    <Snackbar
      open={
        (snackbarToShow.open && userAttributes?.notifyOnUpload && snackbarToShow.type === "uploadPending") ||
        (snackbarToShow.open && snackbarToShow.type === "uploadFailed")
      }
      autoHideDuration={10000}
      onClose={() => onSnackbarClose()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      style={{ maxWidth: "544px" }}
    >
      <MuiAlert
        elevation={4}
        severity="info"
        variant="filled"
        classes={{
          filledSuccess: classes.filledSuccess,
          root: classes.root,
          icon: classes.icon,
          message: classes.message,
          action: classes.action
        }}
        iconMapping={{
          info: <SnackBarIcon fontSize="inherit" className={classes.warning} />
        }}
        action={
          <IconButton aria-label="close" onClick={() => onSnackbarClose()} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Box mb={2}>
          <Typography variant="body2" className="strong">
            {snackbarOptions[snackbarToShow.type].text}
          </Typography>
        </Box>
        {snackbarOptions[snackbarToShow.type].altText && (
          <Box mt={2}>
            <Typography variant="body2" className="small">
              {snackbarOptions[snackbarToShow.type].altText}
            </Typography>
          </Box>
        )}
        {snackbarOptions[snackbarToShow.type].linkText && (
          <Box mt={2}>
            <Typography variant="body2" className="small">
              <Link onClick={() => history.push("/storage")} underline="always" color="text.secondary" style={{ marginRight: 4 }}>
                {snackbarOptions[snackbarToShow.type].linkText}
              </Link>
            </Typography>
          </Box>
        )}
        {snackbarOptions[snackbarToShow.type].checkBoxText && (
          <Box display="flex" alignItems="end" my={2}>
            <Checkbox
              disableRipple
              icon={<CheckboxEmptyIcon />}
              checkedIcon={<CheckboxTickIcon />}
              size="small"
              classes={{ checked: classes.boxChecked }}
              className={classes.checkbox}
              checked={!userAttributes.notifyOnUpload}
              onChange={handleChange}
              // name={filter}
            />
            <Typography variant="body2" className="small">
              {snackbarOptions[snackbarToShow.type].checkBoxText}
            </Typography>
          </Box>
        )}
      </MuiAlert>
    </Snackbar>
  );
}

export default SnackBarConfirmUpload;
