import React from "react";
import { Auth } from "@akord/akord-js";
import { Box, FormControl, Typography, RadioGroup, FormControlLabel, Radio, Switch, Theme } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useHistory } from "react-router-dom";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";

const AntSwitch = withStyles((theme: Theme) => ({
  root: {
    width: 33,
    height: 16,
    padding: 0,
    display: "flex",
    marginRight: 12
  },
  switchBase: {
    padding: 0,
    left: 3,
    top: 2,
    color: theme.palette.background.default,
    "&$checked": {
      transform: "translateX(15px)",
      color: theme.palette.background.default,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      },
      "&:hover": {
        color: theme.palette.background.default
      }
    },
    "&:hover": {
      color: theme.palette.background.default
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    // border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: theme.palette.background.switch
  },
  checked: {}
}))(Switch);

export declare type UserData = {
  username: string;
  attributes: any;
  mfaType?: MfaType;
};
export declare type MfaType = "BACKUP_PHRASE" | "SMS" | "TOTP";

function SecurityToggleMfa() {
  const history = useHistory();
  const { isMobile, userAttributes } = useGlobalContext();
  const { modal } = useNotificationsContext();

  const [user, setUser] = React.useState<UserData>();
  const [mfaEnabled, setMfaEnabled] = React.useState(false);
  const [mfaType, setMfaType] = React.useState("");

  const handleMfaSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (user && isUserMfaEnabled(user)) {
      disableMfa();
    } else {
      setMfaEnabled(event.target.checked);
    }
  };

  const handleMfaTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfaType(event.target.value);
  };

  React.useEffect(() => {
    const getUserMFA = async () => {
      const user = await Auth.getUser(true);
      setUser(user);
      setMfaEnabled(isUserMfaEnabled(user));
      setMfaType(user.mfaType || "");
    };
    getUserMFA();
  }, [userAttributes.mfa]);

  const isUserMfaEnabled = (user: UserData) => user.mfaType === "SMS" || user.mfaType === "TOTP";

  const isDisabled = () => {
    if (user && mfaType && user.mfaType !== mfaType) {
      return false;
    }
    return true;
  };

  const setupMfa = () => {
    if (mfaType === "SMS") {
      history.push("/account/security/two-factor-authentication/phone-number");
    } else if (mfaType === "TOTP") {
      history.push("/account/security/two-factor-authentication/totp");
    }
  };

  const disableMfa = async () => {
    modal.onConfirmModalType("disable2FA");
    modal.onModalVisibility(null, "disable2FA");
  };

  return (
    <SupportScreenWrapper title="Two-factor authentication" route="/account/security">
      {user && (
        <Box sx={{ overflowY: "auto", marginBottom: 4 }}>
          <Box mt={5} mb={6}>
            <FormControlLabel
              sx={{ margin: 0, marginBottom: 3 }}
              control={<AntSwitch checked={mfaEnabled} onChange={handleMfaSwitchChange} />}
              // label={mfaEnabled ? "Disable two-factor authentication" : "Enable two-factor authentication"}
              label={
                <Typography variant="h3">
                  {mfaEnabled ? "Disable two-factor authentication" : "Enable two-factor authentication"}
                </Typography>
              }
            />
            <Typography variant="body2" className="small">
              Add an additional layer of security by adding two-factor authentication (2FA). If you lose access to your 2FA method, the only
              way to reset access will be through the recovery phrase.
            </Typography>
          </Box>
          {mfaEnabled && mfaType !== undefined && (
            <Box>
              <FormControl fullWidth>
                <RadioGroup
                  aria-labelledby="mfa-type-label"
                  name="row-radio-buttons-group"
                  value={mfaType}
                  onChange={handleMfaTypeChange}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Box>
                    <FormControlLabel
                      value="SMS"
                      control={<Radio />}
                      label={
                        <Typography variant="body2" color="text.primary">
                          Text message
                        </Typography>
                      }
                    />
                    <Typography variant="body2" className="small" sx={{ marginLeft: 7 }}>
                      Use your mobile phone to receive a text message with an authentication code to enter when you log in to Akord.
                    </Typography>
                  </Box>

                  <Box sx={{ marginTop: 4 }}>
                    <FormControlLabel
                      value="TOTP"
                      control={<Radio />}
                      label={
                        <Typography variant="body2" color="text.primary">
                          Authenticator app
                        </Typography>
                      }
                    />
                    <Typography variant="body2" className="small" sx={{ marginLeft: 7 }}>
                      Use a mobile authentication app to get a verification code to enter every time you log in to Akord.
                    </Typography>
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          )}
          {mfaEnabled && mfaType && (
            <ButtonGroup
              noMinWidth={true}
              nextText="Setup 2FA"
              nextDiasabled={isDisabled()}
              hideBackButton={true}
              fullWidthNextButton={isMobile ? true : false}
              hideIcon={true}
              disabledRed={false}
              handleNext={() => setupMfa()}
            />
          )}
        </Box>
      )}
    </SupportScreenWrapper>
  );
}

export default SecurityToggleMfa;
