import React, { useState, createContext, useContext } from "react";
import { useGlobalContext } from "./GlobalDataProvider";
import { useVaultContext } from "./VaultContextProvider";
import { getVaultId, isActionFiltered } from "../helpers/helpers";
import { base64ToJson } from "@akord/crypto";
const Context = createContext();

function TimelineContextProvider({ children }) {
  const { akord, onDecrptSpinner } = useGlobalContext();

  const { vault, newTimelineRef, members } = useVaultContext();
  const [timeline, setTimeline] = useState([]);
  const handleTimeline = timeline => setTimeline(timeline);

  const vaultId = getVaultId(location.pathname);

  React.useEffect(() => {
    if (members && Object.keys(members).length > 0) {
      fetchTimeline();
    }
  }, [members, vaultId]);

  // Clear timeline on page change
  React.useEffect(() => {
    return () => {
      if (!newTimelineRef.current) {
        handleTimeline([]);
        newTimelineRef.current = true;
      }
    };
  }, [vaultId]);

  const fetchTimeline = async (isSub = false) => {
    if ((members && Object.keys(members).length === 0) || (!isSub && !newTimelineRef.current)) {
      return;
    }
    onDecrptSpinner(true);

    // const timelineItems = (await paginatedTimelineQuery(vaultId))?.filter(item => !isActionFiltered(item));

    const timelineItems = []
    const timeline = await Promise.all(
      timelineItems.map(async item => {
        try {
          const decodedBody = item.encodedBody ? base64ToJson(item.encodedBody) : {};
          const decodedItem = { ...item, ...decodedBody };

          const membership = members.find(
            member => member.memberDetails.memberPublicSigningKey === item.publicSigningKey || member.address === decodedItem.address
          );

          decodedItem.vault = vault;
          if (membership) {
            decodedItem.ownerInfo = {
              id: membership.id,
              owner: membership.owner,
              address: membership.address,
              email: membership.email,
              ...membership.memberDetails
            };
          }
          if (decodedItem.stack || decodedItem.actionRef?.startsWith("STACK")) {
            const stack = await akord.stack.get(decodedItem.modelId || decodedItem.objectId);
            decodedItem.stack = stack;
            decodedItem.title = stack.name;
          } else if (decodedItem.folder || decodedItem.actionRef?.startsWith("FOLDER")) {
            const folder = await akord.folder.get(decodedItem.modelId || decodedItem.objectId);
            decodedItem.folder = folder;
            decodedItem.title = folder.name;
          } else if (decodedItem.note || decodedItem.actionRef?.startsWith("NOTE")) {
            const note = await akord.note.get(decodedItem.modelId || decodedItem.objectId);
            decodedItem.note = note;
            decodedItem.title = note.name;
          } else if (decodedItem.memo || decodedItem.actionRef?.startsWith("MEMO")) {
            const memo = await akord.memo.get(decodedItem.modelId || decodedItem.objectId);
            decodedItem.memo = memo;
          }

          return decodedItem;
        } catch (e) {
          console.log(e);
          return null;
        }
      })
    );

    newTimelineRef.current = false;
    handleTimeline(timeline.filter(item => item));
    onDecrptSpinner(false);
  };

  return (
    <Context.Provider
      value={{
        decryptedTimeline: timeline,
        onVaultUpdate: fetchTimeline
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default TimelineContextProvider;

export const withTimelineContext = Component => props =>
  <Context.Consumer>{timelineContext => <Component {...props} {...timelineContext} />}</Context.Consumer>;

export const useTimelineContext = () => useContext(Context);
