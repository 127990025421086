import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { operationDataWithTitleData } from "./OperationTitleWithIconData";
import { MinusInCircleIcon } from "@akord/addon-icons";

const useStyles = makeStyles({
  actionIcon: {
    marginRight: "8px",
    marginBottom: "3px"
  }
});

function OperationTitleWithIcon(props) {
  const { actionRef, title, color, icon } = props;
  const classes = useStyles();
  const OperationIcon = icon || operationDataWithTitleData[actionRef]?.icon || MinusInCircleIcon;
  const operationTitle = title || operationDataWithTitleData[actionRef]?.title || "No title";

  return (
    <Typography variant="body2" className="small" color="text.primary" noWrap paragraph style={{ marginBottom: "2px", whiteSpace: "pre" }}>
      <OperationIcon fontSize="small" className={classes.actionIcon} style={{ color: color }} />
      {operationTitle}
    </Typography>
  );
}

export default OperationTitleWithIcon;
