import { Box, Typography } from "@mui/material";
import React from "react";
import AkordCheckbox from "../CheckBox";

type InfoWarningBoxProps = {
  text: string;
  title: string;
  offerComponent: JSX.Element;
  boxChecked: boolean;
  onBoxChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

const InfoBox: React.FC<InfoWarningBoxProps> = ({ text, title, offerComponent, boxChecked, onBoxChecked }) => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderRadius: "4px",
        borderColor: "background.secondary",
        backgroundColor: "primary.iconInvert"
      }}
      p={3}
      mt={5}
    >
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
        {text}
      </Typography>
      <Box display="flex" alignItems="center">
        <AkordCheckbox
          style={{ paddingLeft: 0, marginTop: 0 }}
          checked={boxChecked}
          onChange={() => onBoxChecked((prev: boolean) => !prev)}
        />
        {offerComponent}
      </Box>
    </Box>
  );
};

export default InfoBox;
