import React from "react";
import { Typography } from "@mui/material";
import { PasswordRevealIcon, PasswordMaskIcon } from "@akord/addon-icons";

type RevealTogglerProps = {
  showPassword: boolean;
};

const RevealToggler: React.FC<RevealTogglerProps> = ({ showPassword }) => {
  return showPassword ? (
    <>
      <Typography variant="caption" color="text.secondary" className="small" sx={{ marginRight: "8px" }}>
        Hide
      </Typography>
      <PasswordMaskIcon color="disabled" />
    </>
  ) : (
    <>
      <Typography variant="caption" color="text.secondary" className="small" sx={{ marginRight: "8px" }}>
        Show
      </Typography>
      <PasswordRevealIcon color="disabled" />
    </>
  );
};

export default RevealToggler;
