import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Select, SelectChangeEvent, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CurrencyCode, usePaymentContext } from "../../contexts/PaymentContext";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { TriangleDownIcon } from "@akord/addon-icons";
import { grey } from "../../theme/colors";

type CurrencySelectStyleProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, CurrencySelectStyleProps>(theme => ({
  selectMenu: {
    height: "1.4375em!important",
    minHeight: "1.4375em!important",
    background: "transparent!important",
    fontSize: "14px",
    lineHeight: "1.4375em",
    color: theme.palette.text.secondary,
    minWidth: "78px",
    border: "none"
  },
  select: {
    "&:focus": {
      background: ({ darkMode }) => (darkMode ? "#333" : "#faf7f6")
    }
  },
  selectRoot: {
    paddingRight: "12px!important",
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  iconOpen: {
    color: `${theme.palette.primary.main}!important`
  },
  arrowIcon: {
    color: theme.palette.text.secondary,
    marginRight: "-6px",
    marginTop: "2px"
  },
  menuItem: {
    "&:last-of-type": {
      paddingBottom: 12
    },
    "&:first-of-type": {
      paddingTop: 12
    },
    alignItems: "start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  selected: {
    background: `${grey[700]}!important`
  },
  menuList: {
    backgroundColor: theme.palette.background.secondary,
    maxWidth: "293px"
  },
  adornedEnd: {
    paddingRight: 0
  },
  paper: {
    marginTop: theme.spacing(2)
  }
}));

type CurrencySelectProps = {
  disabled?: boolean;
  disabledTile?: string;
};

const CurrencySelect: React.FC<CurrencySelectProps> = ({ disabled = false, disabledTile }) => {
  const { darkMode } = useGlobalContext();
  const classes = useStyles({
    darkMode: darkMode
  });

  const { currencies, currencyCode, onCurrencyCode } = usePaymentContext();

  const handleChange = (event: SelectChangeEvent<"USD" | "EUR" | "GBP">, child: React.ReactNode) => {
    onCurrencyCode(event?.target?.value as CurrencyCode);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: "auto" }} size="medium">
      <Select
        name="currency"
        labelId="currency-select-outlined-label"
        id="currency-select-outlined"
        IconComponent={TriangleDownIcon}
        classes={{
          select: classes.selectMenu,
          outlined: classes.select,
          iconOpen: classes.iconOpen
        }}
        MenuProps={{
          elevation: 4,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          classes: {
            list: classes.menuList,
            paper: classes.paper
          }
        }}
        inputProps={{
          classes: {
            root: classes.selectRoot,
            icon: classes.arrowIcon
          }
        }}
        variant="standard"
        value={currencyCode}
        onChange={handleChange}
        sx={{ height: 32 }}
        className={classes.select}
        disableUnderline
        disabled={disabled}
        title={disabled ? disabledTile : ""}
      >
        {currencies.map(option => (
          <MenuItem key={option.value} value={option.value} classes={{ selected: classes.selected }} className={classes.menuItem}>
            <Typography variant="body2" color="text.primary">
              {option.label} {option.value}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CurrencySelect;
