import React from "react";
import NoThumbnail from "../../../components/common/NoThumbnail";
import OnImageMenu from "../../../components/common/OnImageMenu";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

function StackCreated({ positionedBeforeDate, operation, color }) {
  const { stacks } = useAssetsContext();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!operation || Object.keys(operation).length === 0) return null;

  //create stacks status and stack hash dictionary
  const statusDictionary = {};
  const hashesDictionary = {};
  const stackArray = stacks?.filter(stack => operation.group.map(file => file.versions?.some(item => item.name === stack.name)));
  //HACK: if a file was renamed and we want to hide it in the timeline because
  //we revoked/deleted it we will be looking in the files array
  //and pick the first one to match the names, since the stack titles won't match
  stackArray.forEach(stack => (statusDictionary[stack?.versions[0].name] = stack.status));
  stackArray.forEach(stack => (hashesDictionary[stack?.versions[0].name] = stack.hash));

  return (
    <>
      {operation?.group?.length > 1 ? (
        <>
          <OperationTitleWithIcon actionRef={operation.actionRef} color={color} title={`${operation.group.length} files uploaded`} />
          {operation?.group?.map((stackItem, index) => (
            <div key={index}>
              <NoThumbnail
                positionedBeforeDate={positionedBeforeDate}
                stackItem={stackItem}
                handleMenu={handleMenu}
                lastItem={operation.group.length - 1 === index}
                stackStatus={statusDictionary[stackItem.stack.versions[0].name]}
                stackHash={hashesDictionary[stackItem.stack.versions[0].name]}
              />
            </div>
          ))}
        </>
      ) : (
        operation?.group?.map((stackItem, index) => (
          <div key={index}>
            <OperationTitleWithIcon actionRef={operation.actionRef} color={color} title={"1 file uploaded"} />
            <NoThumbnail
              positionedBeforeDate={positionedBeforeDate}
              stackItem={stackItem}
              handleMenu={handleMenu}
              lastItem={operation.group.length - 1 === index}
              stackStatus={statusDictionary[stackItem.name]}
              stackHash={hashesDictionary[stackItem.name]}
            />
          </div>
        ))
      )}
      <OnImageMenu anchorEl={anchorEl} handleClose={handleClose} operation={operation?.group[0]} />
    </>
  );
}

export default StackCreated;
