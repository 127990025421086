import React, { useState } from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import { DataRoomFilter, EmptySpace } from "../../components/common";
import VaultItem from "./ValutListItem";
import * as helpers from "../../helpers/helpers";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { Order, OrderBy } from "../../types/globalDataTypes";
import { Vault } from "@akord/akord-js";

type ArchivedVaultListProps = {
  archiveRef: React.RefObject<HTMLDivElement>;
};

const ArchivedVaultList: React.FC<ArchivedVaultListProps> = ({ archiveRef }) => {
  const { archivedVaults } = useGlobalContext();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderBy>("name");

  const handleRequestSort = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, property: OrderBy) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div
      ref={archiveRef}
      style={{
        height: "100%",
        overflowY: "auto",
        paddingTop: "74px"
      }}
    >
      {archivedVaults && archivedVaults?.length > 0 ? (
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
            <DataRoomFilter order={order} orderBy={orderBy} onRequestSort={handleRequestSort} data={archivedVaults} />
            <TableBody>
              {(helpers.stableSort(archivedVaults, helpers.getComparator(order, orderBy)) as Vault[])
                .filter((room: Vault) => room.status === "ARCHIVED")
                .map((vault: Vault, index: number) => (
                  <VaultItem isArchived={true} key={index} vault={vault} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptySpace dataText="archive" />
      )}
    </div>
  );
};

export default ArchivedVaultList;
