import React, { useContext, useEffect, useState } from "react";
import { getOrg, getUsersFromOrg } from "../helpers/api-helpers";
import { useRootContext } from "./RootContext";
import { Auth } from "@akord/akord-js";
import { Types } from "@akord/gql";
import { useGlobalContext } from "./GlobalDataProvider";

type OrgContextProviderProps = {
  orgAdmins: Types.User[];
  orgMembers: Types.User[];
  orgUsers: Types.User[];
  onOrgMembers: React.Dispatch<React.SetStateAction<Types.User[]>>;
  getOrgUsers: () => Promise<{ admins: Types.User[]; members: Types.User[] }>;
  isOrg: boolean;
  org?: Types.Organisation;
  userOrg?: Types.Organisation;
  getUserOrg: () => Promise<any>;
  isOrgInvitee: boolean;
  isCurrentUserOrgAdmin: boolean;
  loadingOrg: boolean;
};

const OrgContext = React.createContext({} as OrgContextProviderProps);

const OrgContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [org, setOrg] = useState<Types.Organisation>();
  const [isOrg, setIsOrg] = useState(false);
  const [userOrg, setUserOrg] = useState<Types.Organisation>();
  const [admins, setAdmins] = useState<Types.User[]>([]);
  const [members, setMembers] = useState<Types.User[]>([]);
  const [isOrgInvitee, setIsOrgInvitee] = useState(false);
  const [loadingOrg, setLoadingOrg] = useState(true);

  const { subdomain } = useRootContext();
  const { profileDetails } = useGlobalContext();

  const getOrgUsers = async () => {
    try {
      if (profileDetails?.orgId) {
        const orgUsers = await getUsersFromOrg(profileDetails.orgId);
        if (orgUsers) {
          const updatedMembers = orgUsers.members.map((member: Types.User) => {
            return { ...member, originalRole: "MEMBER", role: "MEMBER" };
          });
          const updatedAdmins = orgUsers.admins.map((member: Types.User) => {
            return { ...member, originalRole: "OWNER", role: "OWNER" };
          });
          setAdmins(updatedAdmins);
          setMembers(updatedMembers);
          return { admins: updatedAdmins, members: updatedMembers };
        }
      }
    } catch (e) {
      console.error(e);
    }
    return { admins: [], members: [] };
  };

  const getOrgData = React.useCallback(async () => {
    setLoadingOrg(true);
    let currentOrg;
    if (subdomain) {
      currentOrg = await getOrg(subdomain);
      if (currentOrg) {
        setOrg(currentOrg);
      }
    }
    setLoadingOrg(false);
  }, [subdomain]);

  const getUserOrgData = async () => {
    const attributes = await Auth.getUserAttributes();
    if (!attributes) {
      return null;
    }
    let userOrgData;
    try {
      userOrgData = await getOrg();
      if (userOrgData) {
        setUserOrg(userOrgData);
        if (userOrgData?.owner !== attributes["custom:address"]) {
          setIsOrgInvitee(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
    return userOrgData;
  };

  useEffect(() => {
    getOrgData();
  }, [getOrgData]);

  useEffect(() => {
    if (profileDetails && profileDetails.orgId) {
      setIsOrg(true);
      getUserOrgData();
    }
  }, [profileDetails]);

  // check if a current user is an ORG admin
  const isCurrentUserOrgAdmin = profileDetails?.orgRole === "ADMIN" && userOrg?.email === profileDetails?.email;

  return (
    <OrgContext.Provider
      value={{
        orgAdmins: admins,
        orgMembers: members,
        orgUsers: [...admins, ...members],
        onOrgMembers: setMembers,
        getOrgUsers: getOrgUsers,
        isOrg: isOrg,
        org: org,
        userOrg: userOrg,
        getUserOrg: getUserOrgData,
        isOrgInvitee: isOrgInvitee,
        isCurrentUserOrgAdmin: isCurrentUserOrgAdmin,
        loadingOrg: loadingOrg
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};
export default OrgContextProvider;

export const useOrgContext = () => useContext(OrgContext);
