import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import TagTextInput from "../TagTextInput/TagTextInput";
import { styled } from "@mui/material/styles";
import { DESCRIPTION_OVERFLOW_LIMIT } from "../../../helpers/env";

const TextFieldStyled = styled(TextField)(() => {
  return `
    .MuiInputBase-root {
      padding: 0;
    }
    .MuiInputBase-input {
      padding: 12px;
      font-size: 0.8625rem;
      line-height: 1.43
    }
  `;
});

type DescriptionAndTagsFormProps = {
  formData: VaultCreateFormatDataProps;
  onVaultDescriptionChange: (e: string) => void;
  tags: string[];
  handleTagChange: (tags: string[]) => void;
  isVaultPage?: boolean;
  isVaultOwner?: boolean;
};

// is coming from Vault Context - needs to be moved there
type VaultCreateFormatDataProps = {
  roomDescription?: string;
  roomTitle?: string;
  roomTerms?: string;
  hasTerms?: boolean;
  isPublic?: boolean;
  cloud?: boolean;
  tags?: string[];
};

const DescriptionAndTagsForm: React.FC<DescriptionAndTagsFormProps> = ({
  formData,
  onVaultDescriptionChange,
  tags,
  handleTagChange,
  isVaultPage = false,
  isVaultOwner = true
}) => {
  const overflowedCharNumber = formData?.roomDescription
    ? parseInt(DESCRIPTION_OVERFLOW_LIMIT, 10) - formData?.roomDescription?.length
    : parseInt(DESCRIPTION_OVERFLOW_LIMIT, 10);
  const overflow = overflowedCharNumber < 0 ? overflowedCharNumber : null;

  return (
    <>
      <Box mb={7}>
        <Box mb={4}>
          <Typography variant="body2" className="strong" color="text.primary">
            Description (optional)
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Enter a description for your vault, you have a max of 300 characters. A description can help your vault be found.
          </Typography>
        </Box>
        <TextFieldStyled
          disabled={!isVaultOwner}
          fullWidth
          multiline
          rows={isVaultPage ? 4 : 5}
          id="enter-description"
          name="roomDescription"
          type="text"
          placeholder="Description (optional, max 300 characters)"
          variant="outlined"
          value={formData.roomDescription || ""}
          onChange={e => onVaultDescriptionChange(e.target.value)}
          sx={{ marginBottom: 0 }}
        />
        {overflow && (
          <Box width="100%" position="relative">
            <Typography
              component="span"
              variant="body2"
              className="small"
              color="error.main"
              sx={{ marginTop: 1 }}
              position="absolute"
              right="0"
            >
              {overflow}
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Box mb={4}>
          <Typography variant="body2" className="strong" color="text.primary">
            Tags (optional)
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Enter any number of tags for your vault. Tags can help your vault be found.
          </Typography>
        </Box>
        <TagTextInput tags={tags} onTagChange={handleTagChange} isVaultOwner={isVaultOwner} />
      </Box>
    </>
  );
};

export default DescriptionAndTagsForm;
