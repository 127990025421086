import { formatDate } from "../../../helpers/helpers";
import { bytesToGb } from "../../../pages/storage/storage-helper";
import { filtersListMap } from "../FilterItems/filterMap";
import { ViewTransactionIcon, ViewContractIcon } from "@akord/addon-icons";
import { akordLinks } from "../../../helpers/akordTexts";
import { Types } from "@akord/gql";
import { NotificationData } from "../../../contexts/NotificationsContextProvider";
import { apiConfig } from "@akord/akord-js/lib/api/config";
import { AKORD_ENV } from "../../../helpers/env";

export type InfoMapIdProps = "vault" | "stack" | "folder";

export type InfoMapProps = {
  [key in InfoMapIdProps]: InfoMapDataProps;
};

type InfoMapDataProps = {
  copyText: (data: NotificationData) => CopyTextDataProps[];
  text: (data: NotificationData) => TextDataProps[];
};

type CopyTextDataProps = {
  key: string;
  value?: string;
  tooltipText: string;
  extraActionText?: string;
  extraActionData?: string;
  show: boolean;
  arweaveStatus?: Types.StorageTransactionStatus;
  infoText?: string[];
  showStatus: boolean;
};

type TextDataProps = {
  key: string;
  icon?: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
  value: string;
  show: boolean;
};

export enum ArweaveTransactionStatus {
  PENDING = "PENDING",
  SCHEDULED = "SCHEDULED",
  REJECTED = "REJECTED",
  BLOCKED = "BLOCKED",
  COMMITTED = "COMMITTED"
}

export const infoMap: InfoMapProps = {
  vault: {
    copyText: data => [
      {
        key: "Vault ID",
        value: data?.vault?.id,
        tooltipText: "Warp contract identifier",
        extraActionText: "Copy Vault URI",
        extraActionData: `akord://${data?.vault?.id}`,
        show: !data?.vault?.cloud,
        showStatus: false
      }
    ],
    text: data => [
      {
        key: "View contract",
        icon: ViewContractIcon,
        value: `${akordLinks.contractBaseUrl}/${data.vault?.id}`,
        show: !data.vault?.cloud
      },
      {
        key: "View transaction history",
        icon: ViewTransactionIcon,
        value: `${akordLinks.viewBlockBaseUrl}/contract/${data.vault?.id}`,
        show: !data.vault?.cloud
      },
      {
        key: "Created at",
        value: formatDate(data.vault?.createdAt),
        show: true
      },
      {
        key: "Status",
        value: (data.vault?.status?.charAt(0) || "") + (data.vault?.status?.slice(1)?.toLowerCase() || ""),
        show: true
      },
      {
        key: "Size",
        value: bytesToGb(data.vault?.size)?.size + bytesToGb(data.vault?.size)?.sizeType?.toUpperCase() || "0",
        show: true
      }
    ]
  },
  stack: {
    copyText: data => [
      {
        key: apiConfig(AKORD_ENV).gatewayurl.replace(/(^\w+:|^)\/\//, ""),
        value: `${apiConfig(AKORD_ENV).gatewayurl}/${data.stack?.getUri()}`,
        tooltipText: `Access your file on the blockchain through the ${apiConfig(AKORD_ENV).gatewayurl} gateway.`,
        show: !data.stack?.["__cloud__"],
        infoText: [
          `This link will access the file from the Arweave network on ${
            apiConfig(AKORD_ENV).gatewayurl
          } – Akord’s own gateway to the permaweb.`,
          "On average it takes 5-15 minutes for a file to be available directly from the Arweave network via the gateways."
        ],
        showStatus: true
      },
      {
        key: "arweave.net",
        value: `https://arweave.net/${data.stack?.getUri()}`,
        tooltipText: "Access your file on the blockchain through the arweave.net gateway.",
        show: !data.stack?.["__cloud__"],
        arweaveStatus: data.stack?.versions[0].status as Types.StorageTransactionStatus,
        infoText: [
          "This link will access the file from the Arweave network on arweave.net – it is the gateway managed by the ar.io team.",
          "On average it takes 5-15 minutes for a file to be available directly from the Arweave network via the gateways."
        ],
        showStatus: true
      }
    ],
    text: data => [
      {
        key: "View transaction",
        icon: ViewTransactionIcon,
        value: `${akordLinks.transactionBaseUrl}/${data.stack?.getUri()}`,
        show: !data.stack?.["__cloud__"]
      },
      {
        key: "First uploaded",
        value: formatDate(data.stack?.createdAt),
        show: true
      },
      {
        key: "Last modified",
        value: formatDate(data.stack?.updatedAt),
        show: true
      },
      { key: "Version", value: data.stack?.versions.length.toString() || "", show: true },
      {
        key: "Status",
        value: (data.stack?.status.charAt(0) || "") + (data.stack?.status.slice(1).toLowerCase() || ""),
        show: true
      },
      {
        key: "Size",
        value:
          bytesToGb(data.stack?.versions[data.stack?.versions.length - 1].size).size +
          bytesToGb(data.stack?.versions[data.stack?.versions.length - 1].size).sizeType.toUpperCase(),
        show: true
      },
      {
        key: "File type",
        value:
          filtersListMap[data.stack?.versions[data.stack?.versions.length - 1].type!] ||
          data.stack?.versions[data.stack?.versions.length - 1].type!,
        show: true
      }
    ]
  },
  folder: {
    copyText: data => [
      {
        key: "Akord URI",
        // Need a better way to get a vault id (ex. from the model)
        value: `akord://${window.location.pathname.split("/")[3]}/${data.folder?.id}`,
        tooltipText: "The unique resource identifier within the context of the Akord protocol",
        show: true,
        showStatus: false
      },
      {
        key: "Metadata TX ID",
        value: data.folder?.data && data.folder?.data[0],
        tooltipText: "Identifies transaction that holds folder metadata",
        show: true,
        showStatus: false
      },
      {
        key: "Node ID",
        value: data.folder?.id,
        tooltipText: "Akord identifier of the file",
        show: true,
        showStatus: false
      }
    ],
    text: data => [
      {
        key: "Last modified",
        value: formatDate(data.folder?.updatedAt),
        show: true
      },
      {
        key: "Status",
        value: (data.folder?.status.charAt(0) || "") + (data.folder?.status.slice(1).toLowerCase() || ""),
        show: true
      },
      {
        key: "Size",
        value: bytesToGb(data.folder?.size).size + bytesToGb(data.folder?.size).sizeType.toUpperCase(),
        show: true
      }
    ]
  }
};
