import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, FormControl, OutlinedInput, InputAdornment, Theme, SelectChangeEvent, AutocompleteChangeReason } from "@mui/material";
import SelectMenu from "../../components/common/SelectMenu";
import ErrorAlert from "../../components/common/ErrorAlert";
import { CloseInCircleIcon } from "@akord/addon-icons";
import { useOrgContext } from "../../contexts/OrgContextProvider";
import OrgMemberAutoCompleteSelect from "../../components/common/AutoComplete/OrgMemberAutoCompleteSelect";
import { InviteFormProps } from "./InviteToRoom";
import { Types } from "@akord/gql";

type InviteToRoomFormProps = {
  inviteForm: InviteFormProps[];
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, elIndex: number) => void;
  onAutoCompleteFormChange?: (
    elIndex: number
  ) => (event: React.SyntheticEvent<Element, Event>, value: Types.User, reason: AutocompleteChangeReason) => void;
  handleEmailClear: (elIndex: number, item: InviteFormProps) => void;
  index: number;
  invalidUsers: InviteFormProps[];
  alreadyInvited: InviteFormProps[];
  isMobile: boolean;
  noSelect?: boolean;
  airdropChecked?: boolean;
  isOrgInvite?: boolean;
};

const useStyles = makeStyles<Theme>((theme) => ({
  formEmail: {
    marginRight: theme.spacing(3),
    flexGrow: 1
  },
  formMessage: {
    border: "none"
  },
  input: {
    fontSize: "1rem"
  },
  adornedEnd: {
    paddingRight: 0
  }
}));

const InviteToRoomForm: React.FC<InviteToRoomFormProps> = (props) => {
  const {
    inviteForm,
    handleFormChange,
    onAutoCompleteFormChange,
    handleEmailClear,
    index,
    invalidUsers,
    alreadyInvited,
    isMobile,
    noSelect,
    airdropChecked,
    isOrgInvite = false
  } = props;
  const [emailFilled, setEmailFilled] = React.useState(false);
  const { isOrg, orgMembers } = useOrgContext();
  const classes = useStyles({
    emailFilled: emailFilled
  });

  React.useEffect(() => {
    inviteForm[index].email ? setEmailFilled(true) : setEmailFilled(false);
  }, [inviteForm, index]);

  return (
    <Box mb={3} display="flex">
      <FormControl variant="outlined" className={classes.formEmail}>
        {!airdropChecked && isOrg && !isOrgInvite ? (
          <OrgMemberAutoCompleteSelect onOptionSelect={onAutoCompleteFormChange} options={orgMembers} invitees={inviteForm} />
        ) : (
          <OutlinedInput
            // required
            error={
              (invalidUsers?.length > 0 && invalidUsers?.filter((user) => user.email === inviteForm[index].email).length > 0) ||
              (alreadyInvited?.length > 0 && alreadyInvited?.filter((user) => user.email === inviteForm[index].email).length > 0)
            }
            id={`enter-email-${index}`}
            name="email"
            type="email"
            // subdomain && !isOrgInvite for white label vault invites
            placeholder={airdropChecked ? "Identifier (name, email, etc)" : "Email"}
            value={inviteForm[index].email}
            classes={{
              input: classes.input,
              adornedEnd: classes.adornedEnd,
              root: classes.root
            }}
            onChange={(e) => handleFormChange(e, index)}
            endAdornment={
              inviteForm[index].email && (
                <InputAdornment position="end" style={{ marginRight: 0 }}>
                  <CloseInCircleIcon
                    color="disabled"
                    onClick={() => handleEmailClear(index, inviteForm[index])}
                    sx={{
                      cursor: "pointer",
                      marginRight: isMobile ? "8px" : "12px"
                    }}
                  />
                </InputAdornment>
              )
            }
          />
        )}
        {invalidUsers?.length > 0 && invalidUsers?.filter((user) => user.id === inviteForm[index].id).length > 0 && (
          <ErrorAlert getErrorText="You need an Akord account to be invited." />
        )}
        {alreadyInvited?.length > 0 && alreadyInvited?.filter((user) => user.email === inviteForm[index].email).length > 0 && (
          <ErrorAlert getErrorText="This person has already been invited." />
        )}
      </FormControl>
      {!noSelect && (
        <SelectMenu
          {...{ index, emailFilled, airdropChecked, isOrgInvite, handleFormChange }}
          member={inviteForm[index]}
          outlined={true}
          fromInvite={true}
          {...props}
        />
      )}
    </Box>
  );
};

export default InviteToRoomForm;
