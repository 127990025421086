import { shadeColor } from "../components/common/Wrappers/theme-helper";
import { DefaultPaletteOptions } from "./mui";

export const orgPalette = (darkTheme = true, primaryColor: string, secondaryColor: string) => {
  return {
    primary: {
      main: primaryColor
    },
    background: {
      default: darkTheme ? "#000" : "#FFF",
      // By adding 20 we set Alpha value for the color
      stack: darkTheme ? shadeColor(secondaryColor, -70) : secondaryColor + 20,
      // stack: darkTheme ? shadeColor(secondaryColor, -70) : shadeColor(secondaryColor, 80),
      active: darkTheme ? shadeColor(primaryColor, -70) : primaryColor + 20
      // active: darkTheme ? shadeColor(primaryColor, -70) : shadeColor(primaryColor, 80)
    },
    info: {
      main: secondaryColor
    }
  } as DefaultPaletteOptions;
};
