import { Auth, Stack, Vault } from "@akord/akord-js";
import { Folder } from "@akord/akord-js";
import { useHistory } from "react-router-dom";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useNotificationsContext, NotificationData } from "../../../contexts/NotificationsContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { removeFromOrg, requestAccountDeletion } from "../../../helpers/api-helpers";
import { status } from "@akord/akord-js/lib/constants";
import { ModalTypeKeys } from "../../../hooks/useModal";

const UseConfirmModal = () => {
  const { onStackCreate, onStackUpdate, onStackDelete, onFolderUpdate, onFolderDelete, onSelectedItems } = useAssetsContext();
  const { akord, onVaultUpdate, onVaultDelete, onUserAttributes, profileDetails, activeVaults } = useGlobalContext();
  const { vault, onMembers, members, onVault } = useVaultContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { notificationData, onNotificationData } = useNotificationsContext();
  const history = useHistory();

  const handleModalConfirm = async (confirmType: ModalTypeKeys) => {
    if (!akord) return;
    let response;

    switch (confirmType) {
      case "archive":
        if (!notificationData.id) return;
        response = await akord.vault.archive(notificationData.id);
        onVaultUpdate(response.object);
        onVault && onVault({} as Vault);
        // onVault && onVault(response.object);
        history.push(`/vaults/active`);
        onSnackbarToShow("dataRoomArchive");
        break;
      case "restore":
        if (!notificationData.id) return;
        response = await akord.vault.restore(notificationData.id);
        onVaultUpdate(response.object);
        onVault && onVault(response.object);
        history.push("/vaults/active");
        onSnackbarToShow("dataRoomRestore");
        break;
      case "delete":
        if (!notificationData.id) return;
        response = await akord.vault.delete(notificationData.id);
        onVaultDelete(notificationData.id);
        onVault && onVault({} as Vault);
        onSnackbarToShow("dataRoomDelete");
        history.push(`/vaults/active`);
        break;
      case "batchRestoreVaults":
        if (!notificationData.batchItems) return;
        for (const vaultId of notificationData.batchItems.keys()) {
          response = await akord.vault.restore(vaultId);
          onVaultUpdate(response.object);
        }
        onSnackbarToShow("vaultsBatchRestore");
        onSelectedItems("reset");
        break;
      case "batchRemoveVaults":
        if (!notificationData.batchItems) return;
        await Promise.all(
          Array.from(notificationData.batchItems.keys()).map(async vaultId => {
            response = await akord.vault.delete(vaultId);
            onVaultDelete(vaultId);
            return response;
          })
        );
        onSnackbarToShow("vaultsBatchDelete");
        onSelectedItems("reset");
        break;
      case "leave":
        if (!notificationData.id) return;
        await akord.membership.leave(notificationData.id);
        history.push(`/vaults/active?refresh=true`);
        onSnackbarToShow("membershipLeave");
        break;
      case "revokeFile":
      case "revokeStack":
        if (!notificationData.id) return;
        response = await akord.stack.revoke(notificationData.id, vault.id);
        onStackUpdate(response.object);
        onSnackbarToShow("fileRevoked");
        break;
      case "batchRevoke":
        if (!notificationData.batchItems) return;
        response = await akord.batch.revoke(batchItemsGetter(notificationData));
        response?.forEach(node => (node.object instanceof Folder ? onFolderUpdate(node.object) : onStackUpdate(node.object as Stack)));
        onSnackbarToShow("batchRevoked");
        onSelectedItems("reset");
        break;
      case "batchRemove":
        if (!notificationData.batchItems) return;
        await akord.batch.delete(batchItemsGetter(notificationData));
        batchItemsGetter(notificationData).forEach(item => (item.type === "Folder" ? onFolderDelete(item.id) : onStackDelete(item.id)));
        onSnackbarToShow("batchRemove");
        onSelectedItems("reset");
        break;
      case "batchDelete":
        if (!notificationData.batchItems) return;
        await akord.batch.delete(batchItemsGetter(notificationData));
        batchItemsGetter(notificationData).forEach(item => (item.type === "Folder" ? onFolderDelete(item.id) : onStackDelete(item.id)));
        onSnackbarToShow("batchDelete");
        onSelectedItems("reset");
        break;
      case "removeFile":
        if (!notificationData.id) return;
        response = await akord.stack.delete(notificationData.id, vault.id);
        onStackDelete(notificationData.id);
        onSnackbarToShow("fileRemove");
        break;
      case "restoreFileFromTimeline":
        if (!notificationData.id) return;
        response = await akord.stack.restore(notificationData.id);
        onStackUpdate(response.object);
        onSnackbarToShow("fileRestore");
        break;
      case "deleteFile":
        if (!notificationData.id) return;
        response = await akord.stack.delete(notificationData.id, vault.id);
        onStackDelete(notificationData.id);
        onSnackbarToShow("fileDelete");
        break;
      case "restoreFile":
      case "restoreStack":
        if (!notificationData.id) return;
        response = await akord.stack.restore(notificationData.id, vault.id);
        onStackUpdate(response.object);
        onSnackbarToShow("fileRestore");
        break;
      case "batchRestore":
        if (!notificationData.batchItems) return;
        response = await akord.batch.restore(batchItemsGetter(notificationData));
        response?.forEach(node => (node.object instanceof Folder ? onFolderUpdate(node.object) : onStackUpdate(node.object as Stack)));
        onSnackbarToShow("batchRestore");
        onSelectedItems("reset");
        break;
      case "revoke":
        if (!notificationData.id) return;
        await akord.membership.revoke(notificationData.id);
        onMembers(members.filter(member => member.id !== notificationData.id));
        onSnackbarToShow("memberRevoke");
        break;
      case "orgRevoke":
        if (!notificationData.memberToRevoke) return;
        await Promise.all(
          activeVaults!.map(async vault => {
            const memberships = await akord.membership.listAll(vault.id, {
              shouldDecrypt: false,
              filter: {
                or: [{ status: { eq: status.ACCEPTED } }, { status: { eq: status.PENDING } }]
              }
            });
            // TODO: update members list
            const member = memberships.find(
              membership => membership.address === notificationData.memberToRevoke?.user.address && membership.role !== "OWNER"
            );
            if (member) {
              try {
                await akord.membership.revoke(member.id);
              } catch (e) {
                console.error(`Could not revoke member from vault: ${vault.name}, ${vault.id}`);
              }
            } else return;
          })
        );
        await removeFromOrg(notificationData.memberToRevoke.orgId!, notificationData.memberToRevoke.user.email!);
        // updating (refetching) members in OrgMemb component
        await notificationData.memberToRevoke.updateOrgMembers();
        onSnackbarToShow("memberRevoke");
        break;
      case "folderRevoke":
        if (!notificationData.id) return;
        response = await akord.folder.revoke(notificationData.id, vault.id);
        onFolderUpdate(response.object);
        onSnackbarToShow("folderRevoke");
        break;
      case "folderRestore":
        if (!notificationData.id) return;
        response = await akord.folder.restore(notificationData.id, vault.id);
        onFolderUpdate(response.object);
        onSnackbarToShow("folderRestore");
        break;
      case "folderDelete":
        if (!notificationData.id) return;
        response = await akord.folder.delete(notificationData.id, vault.id);
        onFolderDelete(notificationData.id);
        onSnackbarToShow("folderDelete");
        break;
      case "fileMove":
        if (!notificationData.id) return;
        response = await akord.stack.move(notificationData.id, notificationData.folderId, vault.id);
        onStackUpdate(response.object);
        onSnackbarToShow("fileMove");
        break;
      case "folderMove":
        if (!notificationData.id) return;
        response = await akord.folder.move(notificationData.id, notificationData.folderId, vault.id);
        onFolderUpdate(response.object);
        onSnackbarToShow("folderMove");
        break;
      case "batchMove":
        response = await akord.batch.move(batchItemsGetter(notificationData), notificationData.folderId);
        response?.forEach(node => (node.object instanceof Folder ? onFolderUpdate(node.object) : onStackUpdate(node.object as Stack)));
        onSnackbarToShow("batchMove");
        onSelectedItems("reset");
        break;
      case "noteMove":
        if (!notificationData.id) return;
        response = await akord.note.move(notificationData.id, notificationData.folderId, vault.id);
        onStackUpdate(response.object);
        onSnackbarToShow("noteMove");
        break;
      case "removeNote":
        if (!notificationData.id) return;
        response = await akord.note.delete(notificationData.id, vault.id);
        onStackDelete(notificationData.id);
        onSnackbarToShow("noteRemove");
        break;
      case "restoreFolderWithMove":
        if (!notificationData.id) return;
        response = await akord.folder.restore(notificationData.id, vault.id);
        onFolderUpdate(response.object);
        onSnackbarToShow("restoreFolderWithMove");
        break;
      case "restoreFileWithMove":
        if (!notificationData.id) return;
        response = await akord.stack.restore(notificationData.id, vault.id);
        onStackUpdate(response.object);
        onSnackbarToShow("restoreFileWithMove");
        break;
      case "duplicateFile":
        // Handle it differently needs to be revised
        // in duplicate-file-upload.js
        break;
      case "revokeNote":
        if (!notificationData.id) return;
        response = await akord.note.revoke(notificationData.id, vault.id);
        onStackUpdate(response.object);
        onSnackbarToShow("revokeNote");
        break;
      case "restoreNote":
        if (!notificationData.id) return;
        response = await akord.note.restore(notificationData.id, vault.id);
        onStackUpdate(response.object);
        onSnackbarToShow("restoreNote");
        break;
      case "createManifest":
        response = await akord.manifest.generate(vault.id);
        onStackCreate(response.object);
        onSnackbarToShow("createManifest");
        break;
      case "saveNote":
        if (!notificationData.title || !notificationData.noteText) return;
        if (notificationData.id) {
          await akord.stack.uploadRevision(
            notificationData.id,
            new File([notificationData.noteText], notificationData.title, {
              type: "text/markdown"
            })
          );
          response = await akord.stack.rename(notificationData.id, notificationData.title);
          onStackUpdate(response.object);
        } else {
          response = await akord.stack.create(
            vault.id,
            new File([notificationData.noteText], notificationData.title, {
              type: "text/markdown"
            }),
            // notificationData.title,
            {
              parentId: notificationData.folderId
            }
          );
        }
        onStackCreate(response.object);
        onNotificationData({} as NotificationData);
        onSnackbarToShow(notificationData.id ? "reviseNote" : "saveNote");
        history.push(
          notificationData.folderId
            ? `/vaults/active/${vault.id}/assets/folders/${notificationData.folderId}`
            : `/vaults/active/${vault.id}/assets`
        );
        break;
      case "disable2FA":
        await Auth.disableMFA();
        onSnackbarToShow("mfaDisabled");
        onUserAttributes({ mfa: false });
        break;
      case "deleteAccount":
        if (profileDetails?.email) {
          await requestAccountDeletion(profileDetails.email);
          onSnackbarToShow("deleteAccount");
        }
        break;
      case "vaultsUpgradeTier":
        history.push("/account/plans-and-payments/pricing-plans");
        break;
      case "membersUpgradeTier":
        history.push("/account/plans-and-payments/pricing-plans");
        break;
      case "cancelCurrentPlan":
        if (notificationData.cancelCurrentPlan) await notificationData.cancelCurrentPlan();
        break;
      case "topUpsUpgradeTier":
        history.push("/account/plans-and-payments/pricing-plans");
        break;
      default:
        break;
    }
  };

  const batchItemsGetter = (data: NotificationData) => {
    return Array.from(data.batchItems!.values()).map(item => {
      return { id: item.id, type: item.objectType };
    });
  };

  return { handleModalConfirm };
};

export default UseConfirmModal;
