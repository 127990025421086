import React from "react";
import { Box, Divider } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SupportScreenWrapper } from "../../components/common";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import HoverableIconWithText from "../../components/common/HoverableIconWithText/HoverableIconWithText";

const Security: React.FC<RouteComponentProps> = ({ history }) => {
  const { modal } = useNotificationsContext();
  const securityOptions = [
    {
      title: "Reveal recovery phrase",
      onClick: () => history.push("/account/security/backup-phrase")
    },
    {
      title: "Change password",
      onClick: () => history.push("/account/security/change-password")
    },
    {
      title: "Two-factor authentication",
      onClick: () => history.push("/account/security/two-factor-authentication")
    },
    {
      title: "Delete account",
      onClick: () => {
        modal.onConfirmModalType("deleteAccount");
        modal.onModalVisibility(null, "deleteAccount");
      }
    }
  ];
  return (
    <SupportScreenWrapper title="Security and Privacy" route="/account">
      <Box mb={7}>
        {securityOptions.map(({ onClick, title }, index) => (
          <div key={index}>
            <HoverableIconWithText title={title} onClick={onClick} />
            <Divider />
          </div>
        ))}
      </Box>
    </SupportScreenWrapper>
  );
};

export default withRouter(Security);
