import React, { useState, createContext, useContext } from "react";
import { useGlobalContext } from "./GlobalDataProvider";
const Context = createContext();

function VaultsSelectionContextProvider({ children }) {
  const [selectedVaultsMap, setSelectedVaultsMap] = useState(new Map());
  const [lastSelectedVault, setLastSelectedVault] = useState(null);
  const [isShiftDown, setIsShiftDown] = useState(false);

  const { archivedVaults } = useGlobalContext();

  const handleLastSelectedVault = item => setLastSelectedVault(item);

  const getNewSelectedVaults = hash => {
    const currentSelectedIndex = archivedVaults.findIndex(item => item.id === hash);
    const lastSelectedIndex = archivedVaults.findIndex(item => item.id === lastSelectedVault);
    return archivedVaults
      .slice(Math.min(lastSelectedIndex, currentSelectedIndex), Math.max(lastSelectedIndex, currentSelectedIndex) + 1)
      .reduce((acc, item) => acc.set(item.id, { id: item.id }), new Map());
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  // For Batch selection
  const downHandler = ({ key }) => {
    if (key === "Shift") {
      setIsShiftDown(true);
    }
  };

  const upHandler = ({ key }) => {
    if (key === "Shift") {
      setIsShiftDown(false);
    }
  };

  const handleSelectedVaults = (hash, checked) => {
    if (hash === "allItemsOnPage") {
      if (checked) {
        const vaultsIds = archivedVaults.reduce((acc, item) => {
          return acc.set(item.id, {
            id: item.id
          });
          // else return acc;
        }, new Map());

        setSelectedVaultsMap(vaultsIds);
      } else {
        setSelectedVaultsMap(new Map(selectedVaultsMap.clear()));
      }
    } else if (hash === "allItems") {
      if (selectedVaultsMap.size < archivedVaults.length) {
        const vaultsIds = archivedVaults.reduce((acc, item) => {
          return acc.set(item.id, {
            id: item.id
          });
        }, new Map());
        setSelectedVaultsMap(vaultsIds);
      } else {
        setSelectedVaultsMap(new Map(selectedVaultsMap.clear()));
      }
      // }
    } else if (hash === "reset") {
      setSelectedVaultsMap(new Map(selectedVaultsMap.clear()));
    } else {
      const hasBeenSelected = selectedVaultsMap.has(hash);

      if (isShiftDown) {
        // Multiple items select
        const newSelectedItems = getNewSelectedVaults(hash);
        let selections = new Map([...selectedVaultsMap, ...newSelectedItems]);
        // Unselect
        if (hasBeenSelected) {
          selections = new Map(Array.from(selections.entries()).filter(([key]) => !newSelectedItems.has(key)));
        }
        setSelectedVaultsMap(selections);
      } else {
        // Single item select
        const selectedItemsMapCopy = new Map(selectedVaultsMap);
        const item = archivedVaults.filter(item => item?.id === hash)[0];

        selectedItemsMapCopy.has(hash)
          ? selectedItemsMapCopy.delete(hash)
          : selectedItemsMapCopy.set(item?.id, {
              id: item?.id
            });

        setSelectedVaultsMap(selectedItemsMapCopy);
      }
    }
  };

  return (
    <Context.Provider
      value={{
        archivedVaults: archivedVaults,
        onSelectedVaults: handleSelectedVaults,
        selectedVaultsMap: selectedVaultsMap,
        onLastSelectedVault: handleLastSelectedVault
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default VaultsSelectionContextProvider;

export const withVaultsSelectionContext = Component => props =>
  <Context.Consumer>{vaultsSelectionContext => <Component {...props} {...vaultsSelectionContext} />}</Context.Consumer>;

export const useVaultsSelectionContext = () => useContext(Context);
