import {
  AddMemberIcon,
  InactiveIcon,
  BonusIcon,
  CloseInCircleIcon,
  TrashIcon,
  FolderOutlineIcon,
  InvitationAcceptIcon,
  KeyIcon,
  MoveIcon,
  NoteIcon,
  PaperPlaneIcon,
  PenIcon,
  RestoreIcon,
  StackIcon,
  StorageIcon,
  UploadIcon,
  VaultIcon,
  VaultLeaveIcon,
  AIcon,
  DiscordIcon,
  ListIcon,
  PasswordMaskIcon,
  RewardsIcon,
  ZipIcon,
  DedocoLogoIcon
} from "@akord/addon-icons";
import { Types } from "@akord/gql";

export type DataForAction = {
  action?: string;
  details?: string;
  icon?: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
  preText?: string;
  postText?: string;
  path?: string;
};

export const getDataForAction = (notification: Types.Notification & { decryptedContent?: { [key: string]: any } }): DataForAction => {
  switch (notification.event) {
    case "STACK_CREATE":
      return {
        action: " uploaded ",
        details: (notification?.count || 0) > 1 ? `${notification.count} files in ` : `"${notification.objectName ?? "files "}" in `
      };
    case "STACK_MOVE":
      return {
        action: " moved file ",
        details: (notification?.count || 0) > 1 ? `${notification.count} files in ` : `"${notification.objectName ?? "files "}" in `
      };
    case "STACK_UPDATE":
      return {
        action: " renamed ",
        details: " the stack in "
      };
    case "STACK_UPLOAD_REVISION":
      return {
        action: " added a new version ",
        details: `to "${notification.objectName ?? "stack"}" in `
      };
    case "STACK_DELETE":
      return {
        action: " deleted ",
        details: `"${notification.objectName ?? "stack"}" in `
      };
    case "FILE_SIGNED":
      return {
        action: "Signed file version is pending",
        details: ` in `,
        icon: DedocoLogoIcon
      };
    case "STACK_REVOKE":
      return {
        action: " hidden ",
        details: (notification?.count || 0) > 1 ? `${notification.count} files in ` : `"${notification.objectName ?? "file "}" from `
      };
    case "STACK_RESTORE":
      return {
        action: " restored ",
        details: (notification?.count || 0) > 1 ? `${notification.count} files in ` : `"${notification.objectName ?? "file "}" from `
      };
    case "NOTE_CREATE":
      return {
        action: " created a new note ",
        details: `"${notification.objectName ?? "note"}" in `
      };
    case "NOTE_UPLOAD_REVISION":
      return {
        action: " added a new version ",
        details: `to "${notification.objectName ?? "note"}" in `
      };
    case "NOTE_REVOKE":
      return {
        action: " hidden ",
        details: (notification?.count || 0) > 1 ? `${notification.count} notes in ` : `"${notification.objectName ?? "note "}" from `
      };
    case "NOTE_REMOVE":
      return {
        action: " cleared ",
        details: (notification?.count || 0) > 1 ? `${notification.count} notes in ` : `"${notification.objectName ?? "note "}" from `
      };
    case "NOTE_RESTORE":
      return {
        action: " restored ",
        details: (notification?.count || 0) > 1 ? `${notification.count} notes in ` : `"${notification.objectName ?? "note "}" from `
      };
    case "NOTE_MOVE":
      return {
        action: (notification?.count || 0) > 1 ? ` moved ${notification.count} notes` : " moved a note ",
        details: (notification?.count || 0) > 1 ? " from " : `"${notification.count}" from `
      };
    case "FOLDER_CREATE":
      return {
        action: " created folder ",
        details: ` "${notification.objectName ?? "folder"}" in `
      };
    case "FOLDER_UPDATE":
      return {
        action: " renamed the folder ",
        details: " in "
      };
    case "FOLDER_MOVE":
      return {
        action: " moved folder ",
        details: (notification?.count || 0) > 1 ? `${notification.count} files in ` : `"${notification.objectName ?? "folder "}" in `
      };
    case "FOLDER_REVOKE":
      return {
        action: " hidden ",
        details: (notification?.count || 0) > 1 ? `${notification.count} files in ` : `"${notification.objectName ?? "folder "}" in `
      };
    case "FOLDER_RESTORE":
      return {
        action: " restored ",
        details: (notification?.count || 0) > 1 ? `${notification.count} files in ` : `"${notification.objectName ?? "folder "}" in `
      };
    case "MEMBERSHIP_ACCEPT":
      if (!notification.vault?.termsOfAccess) {
        return {
          action: " joined ",
          details: ""
        };
      } else {
        return {
          action: " accepted the terms of access",
          details: " to "
        };
      }
    case "MEMBERSHIP_INVITE":
      return {
        action: " invited",
        details: ` ${notification.to?.profileName || notification.to?.email} to `
      };
    case "MEMBERSHIP_INVITE_RESEND":
      return {
        action: " resent an invite",
        details: ` for ${notification.to?.profileName || notification.to?.email} to `
      };
    case "MEMBERSHIP_CONFIRM":
      return {
        action: " confirmed access",
        details: ` to `
      };
    case "MEMBERSHIP_REVOKE":
      return {
        action: " revoked the invite ",
        details: `for ${notification.to?.profileName || notification.to?.email} to `
      };
    case "MEMBERSHIP_CHANGE_ACCESS":
      return {
        action: " changed role ",
        details: `for ${notification.to?.profileName || notification.to?.email} to `
      };
    case "MEMBERSHIP_INVITE_NEW_USER":
      return {
        action: " invited ",
        details: `${notification.to?.profileName || notification.to?.email} to `
      };
    case "MEMBERSHIP_LEAVE":
      return {
        action: " left ",
        details: ""
      };
    case "MEMBERSHIP_AIRDROP":
      return {
        action: " airdropped access to ",
        details: ""
      };
    case "WHITELIST_AIRDROP":
      return {
        action: " has gained access from the whitelist to ",
        details: ""
      };
    case "WHITELIST_JOIN":
      return {
        preText: "Someone",
        action: " has joined the whitelist for ",
        details: ""
      };
    case "USER_VERIFIED":
      return {
        action: " has created their account",
        details: `, you can confirm their access to `
      };
    case "MEMO_CREATE":
      return {
        action: " posted a message ",
        details: "in "
      };
    case "MEMO_ADD_REACTION":
      return {
        action: " added an emoji reaction ",
        details: "to a message "
      };
    case "MEMO_REMOVE_REACTION":
      return {
        action: " removed an emoji reaction ",
        details: "to a message "
      };
    case "VAULT_ARCHIVE":
      return {
        action: " deactivated ",
        details: ""
      };
    case "VAULT_DELETE":
      return {
        action: " removed ",
        details: "",
        path: "/vaults/active"
      };
    case "VAULT_UPDATE":
      return {
        action: " renamed ",
        details: `vault `
      };
    case "VAULT_RESTORE":
      return {
        action: " restored ",
        details: `vault `
      };
    case "VAULT_CREATE":
      return {
        action: " created ",
        details: `vault `
      };
    case "VAULT_UPDATE_METADATA":
      return {
        action: " metadata updated ",
        details: `vault `
      };
    case "REFERRAL_REWARD":
      return {
        preText: "Congrats! Someone you referred just signed up – ",
        action: " 100 MB of free storage ",
        postText: "has been added to your account!",
        icon: BonusIcon,
        path: "/storage"
      };
    case "USER_U_CLAIM":
      return {
        action: `U ${notification?.decryptedContent?.amount / Math.pow(10, 6)} `,
        postText: `was sent to a wallet `,
        path: "/rewards"
      };
    case "ZIP_DECOMPRESSED":
      return {
        action: "Zip was decompressed ",
        details: " in "
      };
    case "ZIP_SIGNED":
      return {
        action: "Zip was signed ",
        details: " in "
      };
    case "ZIP_COMMITTED":
      return {
        action: "Zip was committed ",
        details: " in "
      };
    default:
      return {
        action: notification.event || "",
        details: " in "
      };
  }
};

export const getRefIconForAction = (actionRef: string) => {
  switch (actionRef) {
    case "STACK_CREATE":
    case "FILE_SIGNED":
      return UploadIcon;
    case "STACK_MOVE":
    case "NOTE_MOVE":
      return MoveIcon;
    case "STACK_REVOKE":
    case "NOTE_REVOKE":
      return PasswordMaskIcon;
    case "STACK_DELETE":
      return TrashIcon;
    case "STACK_UPDATE":
      return PenIcon;
    case "NOTE_CREATE":
      return NoteIcon;
    case "STACK_RESTORE":
    case "NOTE_RESTORE":
      return RestoreIcon;
    case "NOTE_UPLOAD_REVISION":
      return StackIcon;
    case "MEMBERSHIP_ACCEPT":
      return InvitationAcceptIcon;
    case "MEMBERSHIP_AIRDROP":
    case "MEMBERSHIP_INVITE":
      return AddMemberIcon;
    case "MEMBERSHIP_REVOKE":
      return CloseInCircleIcon;
    case "MEMBERSHIP_CHANGE_ACCESS":
      return KeyIcon;
    case "MEMBERSHIP_INVITE_NEW_USER":
      return InvitationAcceptIcon;
    case "MEMBERSHIP_LEAVE":
      return VaultLeaveIcon;
    case "USER_VERIFIED":
      return AddMemberIcon;
    case "FOLDER_CREATE":
      return FolderOutlineIcon;
    case "VAULT_CREATE":
      return VaultIcon;
    case "VAULT_ARCHIVE":
      return InactiveIcon;
    case "VAULT_DELETE":
      return TrashIcon;
    case "VAULT_UPDATE":
      return PenIcon;
    case "VAULT_RESTORE":
      return RestoreIcon;
    case "VAULT_UPDATE_METADATA":
      return ListIcon;
    case "MEMO_CREATE":
      return PaperPlaneIcon;
    case "MEMO_ADD_REACTION":
      return PaperPlaneIcon;
    case "MEMO_REMOVE_REACTION":
      return PaperPlaneIcon;
    case "REFERRAL_REWARD":
      return StorageIcon;
    case "USER_U_CLAIM":
      return RewardsIcon;
    case "ZIP_COMMITTED":
    case "ZIP_SIGNED":
    case "ZIP_DECOMPRESSED":
      return ZipIcon;

    default:
      return AIcon;
  }
};

export const matchIcon = (icon: any) => {
  switch (icon) {
    case "BonusIcon":
      return BonusIcon;
    case "StorageIcon":
      return StorageIcon;
    case "DiscordIcon":
      return DiscordIcon;

    default:
      return AIcon;
  }
};
