import React from "react";
import { Box, IconButton, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChevronLeftIcon, ChevronRightIcon, DoubleChevronLeftIcon, DoubleChevronRightIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import PaginationRow from "./PaginationRow";
import PaginationSelect from "./PaginationSelect";
import { NumberChangeEvent } from "../../../contexts/AssetsContextProvider";

const useStyles = makeStyles<Theme>(theme => ({
  disabled: {
    "&.Mui-disabled": {
      color: theme.palette.background.tertiary
    }
  }
}));

type PaginationProps = {
  bottomPosition: boolean;
  pages: number | null;
  currentPage: number;
  onGoToNextPage: () => void;
  onGoToPreviousPage: () => void;
  onChangePage: (event: NumberChangeEvent) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  bottomPosition = false,
  pages,
  currentPage,
  onGoToNextPage,
  onGoToPreviousPage,
  onChangePage
}) => {
  const { isMobile } = useGlobalContext();
  const classes = useStyles();

  if (pages && pages <= 1) return <Box width="100px">{""}</Box>;
  if (!pages) return null;

  return (
    <Box width={isMobile ? "auto" : "100px"} mr={bottomPosition ? 0 : 6} display="flex" alignItems="center" justifyContent="flex-end">
      {bottomPosition && (
        <IconButton
          onClick={() => onChangePage(1)}
          disabled={currentPage === 1}
          className={classes.disabled}
          sx={{ marginRight: isMobile ? "12px" : "auto" }}
          size="large"
        >
          <DoubleChevronLeftIcon fontSize={isMobile ? "large" : undefined} />
        </IconButton>
      )}
      {(currentPage > 1 || bottomPosition) && (
        <IconButton
          onClick={onGoToPreviousPage}
          disabled={currentPage === 1}
          className={classes.disabled}
          sx={{ marginRight: isMobile ? "12px" : "auto" }}
          size="large"
        >
          <ChevronLeftIcon fontSize={bottomPosition ? (isMobile ? "large" : "medium") : "small"} />
        </IconButton>
      )}
      {bottomPosition ? (
        <PaginationRow pages={pages} currentPage={currentPage} onChangePage={onChangePage} />
      ) : (
        <PaginationSelect pages={pages} currentPage={currentPage} onChangePage={onChangePage} />
      )}
      <IconButton
        onClick={onGoToNextPage}
        disabled={currentPage === pages}
        className={classes.disabled}
        sx={{ marginLeft: isMobile ? "12px" : "auto" }}
        size="large"
      >
        <ChevronRightIcon fontSize={bottomPosition ? (isMobile ? "large" : "medium") : "small"} />
      </IconButton>
      {bottomPosition && (
        <IconButton
          onClick={() => onChangePage(pages)}
          disabled={currentPage === pages}
          className={classes.disabled}
          sx={{ marginLeft: isMobile ? "12px" : "auto" }}
          size="large"
        >
          <DoubleChevronRightIcon fontSize={isMobile ? "large" : undefined} />
        </IconButton>
      )}
    </Box>
  );
};

export default Pagination;
