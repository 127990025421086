import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, IconButton, Link, Skeleton, Typography } from "@mui/material";
import { SupportScreenWrapper } from "../../components/common";
import { AudioIcon, ChevronDownIcon, DocumentIcon } from "@akord/addon-icons";
import { NFT, StorageType, FileVersion, Derivation, CommercialUse, DataModelTraining } from "@akord/akord-js";
import { OpenIcon } from "@akord/addon-icons";
import { getCollectionId, getNftId, getResourceUri, getVaultId, truncateString } from "../../helpers/helpers";
import CopyToClipboard from "../../components/common/CopyToClipboard/CopyToClipboard";
import { useNftContext } from "../../contexts/NftContextProvider";
import { STORAGE_URL } from "../../helpers/env";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useNftCollectionContext } from "../../contexts/NftCollectionContextProvider";

interface CustomizedState {
  nft: NFT;
}

const getArweaveUri = (asset: FileVersion) => {
  return asset.resourceUri?.find(uri => uri.startsWith(StorageType.ARWEAVE))?.replace(StorageType.ARWEAVE, "");
};

const { host, protocol } = window.location;
const baseUrl = `${protocol}//${host}`;

const NFTItemDetails: React.FC<RouteComponentProps> = ({ location }) => {
  const { nftCollections, nfts } = useNftContext();
  const { collectionNfts } = useNftCollectionContext();

  const { modal, onNotificationData } = useNotificationsContext();

  const isPublicRoute = !!location?.pathname.match("/public/"); // All anonymous routes have `/public` prefix
  const vaultId = getVaultId(location.pathname);
  const collectionId = getCollectionId(location.pathname);
  const nftId = getNftId(location.pathname);

  let nftToShow: NFT;

  if (!location.state) {
    const foundNft = collectionId ? collectionNfts?.find(nft => nft.id === nftId) : nfts.find(nft => nft.id === nftId);
    nftToShow = foundNft as NFT;
  } else {
    const { nft } = location.state as CustomizedState;
    nftToShow = nft;
  }

  if (!nftToShow) return null;

  // Thumbnail Preview Logic
  // If imgUrl is present but no audioUrl - we render a regular NFT image as preview
  // If both imgUrl and audioUrl are present - it means nft has a thumbnail and we render it
  // if noPreviewType is available we render a default thumbnail fallback
  // else show Skeleton

  // Nft in a collection doesn't have the collection name, we need to get it from the parent collection
  const nftCollectionName = nftCollections.find(collection => collection.id === nftToShow.parentId)?.name || undefined;
  const nftSource = `${STORAGE_URL}/${getResourceUri(nftToShow.asset.resourceUri, "s3")}`;
  const nftThumbnailUri: string | undefined = getResourceUri(nftToShow.thumbnail?.resourceUri, "s3");
  const nftThumbnailSource = `${STORAGE_URL}/${nftThumbnailUri}`;

  const nftType = nftToShow.asset.type;

  const shareUrl = collectionId
    ? `${baseUrl}/public/vaults/active/${vaultId}/nfts/collection/${collectionId}/${nftId}`
    : `${baseUrl}/public/vaults/active/${vaultId}/nfts/${nftId}`;

  return (
    <SupportScreenWrapper
      title={nftToShow.name}
      subtitle={nftCollectionName}
      component={
        isPublicRoute ? undefined : (
          <Button
            variant="contained"
            size="xs"
            color="primary"
            onClick={() => {
              modal.onConfirmModalFormType("shareNft");
              modal.onModalVisibility(null, "shareNft");
              onNotificationData({ title: shareUrl });
            }}
            sx={{ minWidth: "auto" }}
          >
            Share
          </Button>
        )
      }
      route={
        collectionId
          ? isPublicRoute
            ? `/public/vaults/active/${vaultId}/nfts/collection/${collectionId}`
            : `/vaults/active/${vaultId}/nfts/collection/${collectionId}`
          : isPublicRoute
          ? `/public/vaults/active/${vaultId}/nfts`
          : `/vaults/active/${vaultId}/nfts`
      }
    >
      {nftType.includes("image") ? (
        <Box position="relative" display="inline-block">
          <Box component="img" src={nftSource} minWidth={300} maxHeight={500} my={6} maxWidth="100%" borderRadius={2} />
          <IconButton
            sx={{
              position: "absolute",
              top: "32px",
              right: "12px",
              backgroundColor: "rgb(255,255,255,60%)",
              padding: "6px",
              "&:hover": { backgroundColor: "rgb(0,0,0,60%)" }
            }}
            onClick={() => window.open(`https://arweave.net/${nftToShow.uri}`)}
          >
            <OpenIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : !nftType.includes("image") && nftThumbnailUri ? (
        <Box display="flex" flexDirection="column" position="relative" my={6} width={300} height={300}>
          <Box component="img" src={nftThumbnailSource} borderRadius={2} />
          <IconButton
            sx={{
              position: "absolute",
              top: "12px",
              right: "12px",
              backgroundColor: "rgb(255,255,255,60%)",
              padding: "6px",
              zIndex: 100,
              "&:hover": { backgroundColor: "rgb(0,0,0,60%)" }
            }}
            onClick={() => window.open(`https://arweave.net/${nftToShow.uri}`)}
          >
            <OpenIcon fontSize="small" />
          </IconButton>
          <audio
            controls
            src={nftSource}
            style={{
              alignSelf: "end",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              width: "300px",
              padding: "6px"
            }}
          >
            <source src={nftSource} />
          </audio>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            backgroundColor: "background.secondary",
            flexDirection: "column",
            height: nftType.includes("video") ? "auto" : 200, // If its a video nft we keep the source height, otherwise keep a square
            my: 6
          }}
          title={nftToShow?.name}
        >
          {nftType.includes("audio") || nftType.includes("video") ? (
            <>
              {nftType.includes("audio") && (
                <AudioIcon sx={{ fontSize: "60px", position: "absolute", alignSelf: "center", marginTop: "50px" }} color="disabled" />
              )}
              <IconButton
                sx={{
                  position: "absolute",
                  marginTop: "12px",
                  marginRight: "12px",
                  alignSelf: "end",
                  backgroundColor: "rgb(255,255,255,60%)",
                  padding: "6px",
                  "&:hover": { backgroundColor: "rgb(0,0,0,60%)" }
                }}
                onClick={() => window.open(`https://arweave.net/${nftToShow.uri}`)}
              >
                <OpenIcon fontSize="small" />
              </IconButton>
              <video
                controls
                src={nftSource}
                style={{
                  width: "100%",
                  alignSelf: "normal",
                  height: nftType.includes("video") ? "auto" : 200,
                  padding: nftType.includes("audio") ? "6px" : 0
                }}
              >
                <source src={nftSource} type={nftType} />
              </video>
            </>
          ) : (
            <DocumentIcon fontSize="large" />
          )}
        </Box>
      )}
      <Divider />
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "none"
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronDownIcon />}
          aria-controls="on-chain-data"
          id="on-chain-header"
          sx={{
            padding: 0,
            "& .MuiAccordionSummary-content": {
              margin: 0
            }
          }}
        >
          <Typography variant="h3">On-chain data</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {nftToShow.balances[nftToShow.owner] > 1 && (
            <Box mb={5} display="flex" justifyContent="space-between">
              <Typography variant="body2" className="strong" color="text.primary">
                Fractional tokens: {nftToShow.balances[nftToShow.owner]}
              </Typography>
            </Box>
          )}
          <Box mb={5}>
            <Box mb={2} display="flex" justifyContent="space-between">
              <Typography variant="body2" className="strong" color="text.primary">
                Viewblock transaction
              </Typography>
              {getArweaveUri(nftToShow.asset) && (
                <Box display="flex" alignItems="self-start">
                  <Typography variant="body2" className="small">
                    {truncateString(getArweaveUri(nftToShow.asset), 4)}
                  </Typography>
                  <CopyToClipboard dataToCopy={getArweaveUri(nftToShow.asset) || ""} />
                </Box>
              )}
            </Box>
            <Box mb={2}>
              <Typography variant="body2" className="small" color="text.secondary">
                It takes 5-15 minutes on average to confirm.
              </Typography>
            </Box>
            <Box>
              <Link
                href={`https://viewblock.io/arweave/tx/${getArweaveUri(nftToShow.asset)}`}
                color="text.secondary"
                target="_blank"
                variant="body2"
                className="small"
              >
                Check it out on Viewblock
              </Link>
              <IconButton sx={{ ml: 2 }} onClick={() => window.open(`https://viewblock.io/arweave/tx/${getArweaveUri(nftToShow.asset)}`)}>
                <OpenIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          {nftToShow.asset.ucm && (
            <Box mb={5}>
              <Box mb={2} display="flex" justifyContent="space-between">
                <Typography variant="body2" className="strong" color="text.primary">
                  BazAR
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body2" className="small" color="text.secondary">
                  It takes 5-15 minutes on average to confirm.
                </Typography>
              </Box>
              <Box mb={2}>
                <Link
                  href={`https://bazar.arweave.dev/#/asset/${getArweaveUri(nftToShow.asset)}`}
                  color="text.secondary"
                  target="_blank"
                  variant="body2"
                  className="small"
                >
                  Check it out on BazAR
                </Link>
                <IconButton
                  sx={{ ml: 2 }}
                  onClick={() => window.open(`https://bazar.arweave.dev/#/asset/${getArweaveUri(nftToShow.asset)}`)}
                >
                  <OpenIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          )}
          <Box mb={5} display="flex" justifyContent="space-between">
            <Typography variant="body2" className="strong" color="text.primary">
              Arweave gateway link
            </Typography>
            <Box display="flex" alignItems="self-start">
              <Typography variant="body2" className="small">
                arweave.net/{truncateString(nftToShow.uri, 4)}
              </Typography>
              <CopyToClipboard dataToCopy={`https://arweave.net/${nftToShow.uri}`} />
            </Box>
          </Box>
          <Box mb={5} display="flex" justifyContent="space-between">
            <Typography variant="body2" className="strong" color="text.primary">
              Owner’s wallet address
            </Typography>
            <Box display="flex" alignItems="self-start">
              <Typography variant="body2" className="small">
                {truncateString(nftToShow.owner, 4)}
              </Typography>
              <CopyToClipboard dataToCopy={nftToShow.owner} />
            </Box>
          </Box>
          <Box mb={5} display="flex" justifyContent="space-between">
            <Typography variant="body2" className="strong" color="text.primary">
              Vault ID
            </Typography>
            <Box display="flex" alignItems="self-start">
              <Typography variant="body2" className="small">
                {truncateString(nftToShow.vaultId, 4)}
              </Typography>
              <CopyToClipboard dataToCopy={nftToShow.vaultId} />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "none"
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronDownIcon />}
          aria-controls="udl-data"
          id="udl-header"
          sx={{
            padding: 0,
            "& .MuiAccordionSummary-content": {
              margin: 0
            }
          }}
        >
          <Typography variant="h3">Universal Data License</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Box mb={5} mt={2}>
            <Box mb={3}>
              <Typography variant="body2" className="strong" color="text.primary" component="span">
                Access:
              </Typography>{" "}
              <Typography variant="body2" color="text.primary" component="span">
                {nftToShow.asset.udl?.licenseFee
                  ? nftToShow.asset.udl?.licenseFee.value + " U / " + nftToShow.asset.udl?.licenseFee.type
                  : "Free"}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" className="strong" color="text.primary" component="span">
                Derivation:
              </Typography>{" "}
              <Typography variant="body2" color="text.primary" component="span">
                {nftToShow.asset.udl?.derivations && nftToShow.asset.udl?.derivations?.length > 0
                  ? nftToShow.asset.udl?.derivations?.map((derivation: Derivation) =>
                      derivation?.fee
                        ? derivation.type + ", " + derivation?.fee?.type + ", " + derivation?.fee?.value
                        : derivation.value
                        ? derivation.type + ", " + derivation.value
                        : derivation.type
                    )
                  : "Not allowed"}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" className="strong" color="text.primary" component="span">
                Commercial use:
              </Typography>{" "}
              <Typography variant="body2" color="text.primary" component="span">
                {nftToShow.asset.udl?.commercialUses && nftToShow.asset.udl?.commercialUses.length > 0
                  ? nftToShow.asset.udl?.commercialUses?.map((commercialUse: CommercialUse) =>
                      commercialUse?.fee
                        ? commercialUse.type + ", " + commercialUse?.fee?.type + ", " + commercialUse?.fee?.value
                        : commercialUse.value
                        ? commercialUse.type + ", " + commercialUse.value
                        : commercialUse.type
                    )
                  : "Not allowed"}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" className="strong" color="text.primary" component="span">
                Data modeling training:
              </Typography>{" "}
              <Typography variant="body2" color="text.primary" component="span">
                {nftToShow.asset.udl?.dataModelTrainings && nftToShow.asset.udl?.dataModelTrainings.length > 0
                  ? nftToShow.asset.udl?.dataModelTrainings?.map((dataModelTraining: DataModelTraining) =>
                      dataModelTraining?.fee
                        ? dataModelTraining.type + ", " + dataModelTraining?.fee?.type + ", " + dataModelTraining?.fee?.value
                        : dataModelTraining.type
                    )
                  : "Not allowed"}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" className="strong" color="text.primary" component="span">
                Unknown rights:
              </Typography>{" "}
              <Typography variant="body2" color="text.primary" component="span">
                {nftToShow.asset.udl?.unknownUsageRights ? nftToShow.asset.udl?.unknownUsageRights : "Included"}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" className="strong" color="text.primary" component="span">
                Currency:
              </Typography>{" "}
              <Typography variant="body2" color="text.primary" component="span">
                {nftToShow.asset.udl?.currency || "U"}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" className="strong" color="text.primary" component="span">
                Duration:
              </Typography>{" "}
              <Typography variant="body2" color="text.primary" component="span">
                {nftToShow.asset.udl?.expires || "∞"}
              </Typography>
            </Box>
            <Box mb={3} display="flex">
              <Typography variant="body2" className="strong" color="text.primary" component="span">
                Payment address:
              </Typography>
              &nbsp;
              {nftToShow.asset.udl?.paymentAddress ? (
                <Box display="flex" alignItems="self-start">
                  <Typography variant="body2" className="small">
                    {truncateString(nftToShow.asset.udl.paymentAddress, 4)}
                  </Typography>
                  <CopyToClipboard dataToCopy={nftToShow.asset.udl.paymentAddress} />
                </Box>
              ) : (
                <Typography variant="body2" color="text.primary" component="span">
                  None
                </Typography>
              )}
            </Box>
            {/* <Box mb={3}>
              <Typography
                variant="body2"
                className="strong"
                color="text.primary"
                component="span"
              >
                Payment mode:
              </Typography>{" "}
              <Typography variant="body2" color="text.primary" component="span">
                {nftToShow.asset.udl?.paymentMode || "None"}
              </Typography>
            </Box> */}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </SupportScreenWrapper>
  );
};

export default withRouter(NFTItemDetails);
