import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ChooseMintType from "../../pages/nft/ChooseMintType";
import CollectionDisplayForm from "../../pages/nft/CollectionDisplayForm";
import FileForm from "../../pages/nft/FileForm";
import MetadataForm from "../../pages/nft/MetadataForm";
import UCMForm from "../../pages/nft/UCMForm";
import UDLCustomizedForm from "../../pages/nft/UDLCustomizedForm";
import UDLForm from "../../pages/nft/UDLForm";

function RoutesMint() {
  return (
    <Switch>
      <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/mint/:mintType/metadata" exact render={() => <MetadataForm />} />
      <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/mint/:mintType/udl" exact render={() => <UDLForm />} />
      <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/mint/:mintType/udl-custom" exact render={() => <UDLCustomizedForm />} />
      <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/mint/:mintType/ucm" exact render={() => <UCMForm />} />
      <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/mint/:mintType/file" exact render={() => <FileForm />} />
      <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/mint/collection/display" exact render={() => <CollectionDisplayForm />} />
      <Route path="/vaults/:dataRoomStatus/:dataRoomId/nfts/mint/choose-type" exact render={() => <ChooseMintType />} />
    </Switch>
  );
}

export default withRouter(RoutesMint);
