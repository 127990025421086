import React from "react";
import { base64ToJson } from "@akord/crypto";
import { Box, Divider, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AkordAvatarIcon, AvatarIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import { DataForAction, getDataForAction, getRefIconForAction, matchIcon } from "./notifications-helper";
import { formatDate } from "../../helpers/helpers";
import { grey } from "../../theme/colors";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { Types } from "@akord/gql";
import { AVATAR_URL } from "../../helpers/env";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import Avatar from "../../components/common/Avatar/Avatar";

type LogNotificationProps = {
  notification: Types.Notification;
  lastToShow: boolean;
};
type LogNotificationStylesProps = {
  status?: Types.NotificationStatus | null;
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, LogNotificationStylesProps>(theme => ({
  card: {
    cursor: "pointer",
    transition: "all 0.3s",
    backgroundColor: ({ status, darkMode }) =>
      status === Types.NotificationStatus.UNREAD ? (darkMode ? theme.palette.info.veryDark : theme.palette.info.dark) : "transparent",
    "&:hover": {
      backgroundColor: ({ status, darkMode }) =>
        status === Types.NotificationStatus.UNREAD
          ? darkMode
            ? theme.palette.info.darkest
            : theme.palette.info.main
          : darkMode
          ? grey[800] + "4D" //4D - Alpha 30%
          : grey[100] + "4D"
    }
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%"
  },
  unreadBlueColor: {
    color: ({ status, darkMode }) =>
      status === Types.NotificationStatus.UNREAD ? (darkMode ? theme.palette.info.light : theme.palette.info.veryLight) : "inherit"
  }
}));

const LogNotification: React.FC<LogNotificationProps> = ({ notification, lastToShow }) => {
  const { darkMode } = useGlobalContext();
  const { onRead } = useInAppNotificationsContext();
  const classes = useStyles({ status: notification.status, darkMode: darkMode });
  const history = useHistory();

  let RefIconForNotification = getRefIconForAction(notification.event || "");

  let IconForNotification = notification.admin
    ? AkordAvatarIcon
    : getDataForAction(notification).icon
    ? getDataForAction(notification).icon
    : null;

  const dataForAction: DataForAction & { refIcon?: string; link?: string; [key: string]: any } =
    notification.event === "USER_U_CLAIM"
      ? getDataForAction({ ...notification, decryptedContent: base64ToJson(notification.content!) })
      : notification.content
      ? base64ToJson(notification.content)
      : getDataForAction(notification);

  // In case all the data including icons was sent from th BE
  // we need to use them
  if (dataForAction.icon && dataForAction.refIcon) {
    IconForNotification = matchIcon(dataForAction.icon);
    RefIconForNotification = matchIcon(dataForAction.refIcon);
  } else if (dataForAction.link?.includes("discord.gg")) RefIconForNotification = matchIcon("DiscordIcon"); // For the first BE sent notification - Akord Community on Discord

  const createLinkByModelId = (notification: Types.Notification) => {
    switch (notification.objectType?.toLowerCase()) {
      case "stack":
        if (notification.event === "STACK_REVOKE")
          history.push({
            pathname: `vaults/active/${notification.vaultId}/revoked-files`,
            state: { itemId: notification.objectId }
          });
        else {
          history.push({
            pathname: `vaults/active/${notification.vaultId}/assets`,
            state: { itemId: notification.objectId }
          });
        }
        break;
      case "memo":
        history.push({
          pathname: `vaults/active/${notification.vaultId}/chat`,
          state: { itemId: notification.objectId }
        });
        break;
      case "note":
        history.push({
          pathname: `vaults/active/${notification.vaultId}/assets`,
          state: { itemId: notification.objectId }
        });
        break;
      case "folder":
        history.push({
          pathname: `vaults/active/${notification.vaultId}/assets`,
          state: { itemId: notification.objectId }
        });
        break;
      case "dataroom":
        history.push({
          pathname: `vaults/active`,
          state: { itemId: notification.vaultId }
        });
        break;
      case "membership":
        if (notification.event === "USER_VERIFIED") {
          history.push(`confirm/?membershipId=${notification.objectId}`);
        } else if (notification.event === "MEMBERSHIP_INVITE") {
          history.push(`invite/?membershipId=${notification.objectId}`);
        } else {
          history.push({
            pathname: `vaults/active/${notification.vaultId}/assets`
          });
        }
        break;
      default:
        history.push({
          pathname: `vaults/active/${notification.vaultId}/assets`,
          state: { itemId: notification.objectId }
        });
        break;
    }
  };

  const goToNotification = () => {
    return dataForAction.link
      ? window.open(dataForAction.link, "_blank", "noopener,noreferrer")
      : dataForAction.path
      ? history.push(dataForAction.path)
      : createLinkByModelId(notification);
  };

  return (
    <>
      <Box
        px={4}
        py={4}
        className={classes.card}
        onClick={() => {
          goToNotification();
          if (notification.status === Types.NotificationStatus.UNREAD) {
            onRead({ id: notification.id });
          }
        }}
      >
        <Box display="flex">
          <Box mr={4}>
            {IconForNotification ? (
              <IconForNotification sx={{ fontSize: "40px" }} />
            ) : notification.from?.address ? (
              <Avatar url={`${AVATAR_URL}/${notification.from?.address}`} imageClassName={classes.avatar} />
            ) : (
              <AvatarIcon sx={{ fontSize: "40px" }} color="secondary" />
            )}
          </Box>
          <Box width="100%">
            <Box>
              <Typography
                component="span"
                variant="body2"
                className="small"
                sx={{
                  color: notification.status === Types.NotificationStatus.UNREAD ? grey[100] : "inherit"
                }}
              >
                {dataForAction.preText ? dataForAction.preText : notification.from?.profileName || notification.from?.email || ""}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className="small strong"
                sx={{
                  color: notification.status === Types.NotificationStatus.UNREAD ? grey[100] : "inherit"
                }}
              >
                {dataForAction.action}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className="small"
                sx={{
                  color: notification.status === Types.NotificationStatus.UNREAD ? grey[100] : "inherit"
                }}
              >
                {dataForAction.postText
                  ? dataForAction.postText
                  : `${dataForAction.details} the 
                ${notification.vault?.name} vault.`}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <RefIconForNotification fontSize="small" color="secondary" sx={{ root: classes.unreadBlueColor }} />
              </Box>
              <Typography
                variant="caption"
                className={[classes.unreadBlueColor, "small"].join(" ")}
                color="text.secondary"
                style={{
                  paddingTop: "3px"
                }}
              >
                {formatDate(notification?.createdAt || "", false, true)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {!lastToShow && <Divider />}
    </>
  );
};

export default LogNotification;
