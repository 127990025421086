import React from "react";
import {
  Switch,
  Route,
  Redirect
  // useLocation
} from "react-router-dom";
import SecureRoutes from "./RoutesSecure";
import PublicRoutes from "./PublicRoutes";
import SignupPage from "../../pages/signup";
import LoginPage from "../../pages/login";
import RecoverPage from "../../pages/recover";
import LoginContextProvider from "../../contexts/LoginContext";
import SignupContext from "../../contexts/SignupContext";
import RecoverAccountContext from "../../contexts/RecoverAccountContext";
import SnackbarContextProvider from "../../contexts/SnackbarContextProvider";
import NotFoundPage from "../../pages/not-found-page";
import TeaPotPage from "../../pages/tea-pot";
import RootContextProvider from "../../contexts/RootContext";
import VerifyPage from "../../pages/login/VerifyPage";
import OrgContextProvider from "../../contexts/OrgContextProvider";
import MaintenancePage from "../../pages/maintenance/MaintenancePage";

const Routes = props => {
  const [fontsReady, setFontsReady] = React.useState(false);
  // const location = useLocation();
  // For paswordless login don't hide the loader before login
  // const fromUrlParams = new URLSearchParams(location?.search);
  // const isPasswordlessLogin = !!fromUrlParams.get("wallet");

  const loader = document.querySelector(".preloader");
  const main = document.querySelector("#main");

  const addClass = React.useCallback(() => {
    loader?.classList.add("loader-hide");
    main?.classList.add("app-show");
  }, [loader, main]);

  React.useEffect(() => {
    addClass();
  }, [addClass]);

  document.fonts.ready.then(() => setFontsReady(true));

  if (!fontsReady) return null;

  const reload = () => window.location.reload();

  return (
    <RootContextProvider>
      <SnackbarContextProvider>
        <OrgContextProvider>
          <Switch>
            <Route
              path="/login"
              exact
              render={() => (
                <LoginContextProvider>
                  <LoginPage {...props} />
                </LoginContextProvider>
              )}
            />
            <Route exact path="/404" component={NotFoundPage} />
            <Route exact path="/418" component={TeaPotPage} />
            <Route exact path="/apple-developer-merchantid-domain-association.txt" onEnter={reload} />
            <Route path="/login" render={props => <Redirect to={{ pathname: "/login/", search: props.location.search }} />} />
            <Route
              path="/verify"
              render={() => (
                <LoginContextProvider>
                  <VerifyPage {...props} />
                </LoginContextProvider>
              )}
            />
            <Route
              path="/recover"
              render={() => (
                <RecoverAccountContext>
                  <RecoverPage {...props} />
                </RecoverAccountContext>
              )}
            />
            <Route
              path="/signup"
              render={() => (
                <SignupContext>
                  <SignupPage />
                </SignupContext>
              )}
            />
            <Route path="/public" render={() => <PublicRoutes />} />
            <Route path="/maintenance" render={() => <MaintenancePage />} />
            <Route path="/" render={() => <SecureRoutes {...props} />} />
          </Switch>
        </OrgContextProvider>
      </SnackbarContextProvider>
    </RootContextProvider>
  );
};

export default Routes;
