import { MintIcon } from "@akord/addon-icons";
import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";

type FileDropAreaProps = {
  onSetFile: (files: File) => void;
  file?: File;
  height?: string;
  width?: string;
  id?: string;
};

const FileDropArea: React.FC<FileDropAreaProps> = ({ onSetFile, file, height = "200px", width = "100%", id }) => {
  const [dragedOver, setDragedOver] = useState(false);
  const [filePreview, setFilePreview] = useState<string>();
  const handleFileForPreview = (file: File) => {
    const fileUrl = URL.createObjectURL(file);
    setFilePreview(fileUrl);
  };
  const [genericPreview, setGenericPreview] = useState(false);

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(false);
  };
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(true);
  };

  const stopEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleImageDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(false);
    const item = event.dataTransfer.items[0];
    const file = item.getAsFile();
    onSetFile(file!);
    if (file?.type.includes("image")) {
      handleFileForPreview(file!);
      setGenericPreview(false);
    } else {
      setFilePreview("");
      setGenericPreview(true);
    }
  };

  return (
    <Box
      mb={2}
      mt={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      onDrop={handleImageDrop}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      sx={{
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "background.tertiary",
        textAlign: "center",
        minHeight: height,
        height: "100%",
        overflowY: "auto",
        overflowX: "clip",
        backgroundColor: dragedOver ? "rgb(29, 39, 114, 0.5)" : "primary.iconInvert",
        "&:hover": {
          ".hidden-text": { visibility: "visible", opacity: "1", transition: "visibility 0s, opacity 0.3s linear" },
          ".image-preview": { opacity: "0.4", cursor: "pointer", transition: "opacity 0.3s" }
        }
      }}
      width={width}
      position="relative"
    >
      {!filePreview && !genericPreview && (
        <Typography variant="caption" className="small">
          Drag and drop here
        </Typography>
      )}
      <label>
        <input
          id={`${id}-upload-file`}
          type="file"
          accept="*"
          style={{ display: "none" }}
          onChange={event => {
            if (!event?.target?.files) return;
            onSetFile(event.target.files[0]);
            if (event.target.files[0].type.includes("image")) {
              handleFileForPreview(event.target.files[0]);
              setGenericPreview(false);
            } else {
              setFilePreview("");
              setGenericPreview(true);
            }
          }}
        />
        {!filePreview && !genericPreview && (
          <Link
            variant="caption"
            className="small"
            color="text.tertiary"
            sx={{
              borderBottomColor: "text.tertiary"
            }}
          >
            or select a file
          </Link>
        )}
        {filePreview && (
          <>
            <Box
              component="img"
              src={filePreview}
              height={height}
              alt="preview"
              className="image-preview"
              sx={{ objectFit: "contain", transition: "opacity 0.3s", float: "left" }}
            />
            <Link
              className={["hidden-text", "small"].join(" ")}
              variant="body2"
              color="text.tertiary"
              underline="none"
              sx={{
                borderBottomColor: "text.tertiary",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                visibility: "hidden",
                opacity: "0",
                transition: "visibility 0s, opacity 0.3s linear"
              }}
            >
              Replace file
            </Link>
          </>
        )}
        {genericPreview && (
          <>
            <Box
              height={height}
              className="image-preview"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <MintIcon sx={{ mb: 3 }} color="disabled" />
              <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>
                {file?.name}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                No preview available
              </Typography>
            </Box>
            <Link
              className={["hidden-text", "small"].join(" ")}
              variant="body2"
              color="text.tertiary"
              underline="none"
              sx={{
                borderBottomColor: "text.tertiary",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                visibility: "hidden",
                opacity: "0",
                transition: "visibility 0s, opacity 0.3s linear"
              }}
            >
              Replace file
            </Link>
          </>
        )}
      </label>
    </Box>
  );
};

export default FileDropArea;
