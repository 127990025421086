import React from "react";
import { Typography } from "@mui/material";
import { ButtonGroup, OperationTitleWithIcon } from "../../../components/common/";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useTimelineContext } from "../../../contexts/TimelineContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";

function UserVerified({ operation, positionedBeforeDate, color }) {
  const { akord } = useGlobalContext();
  const { onVaultUpdate } = useTimelineContext();
  const { onMembers, members } = useVaultContext();
  const [loading, setLoading] = React.useState(false);
  const [backLoading, setBackLoading] = React.useState(false);

  const confirmMembership = async () => {
    try {
      setLoading(true);
      akord.membership.confirm(operation.modelId);
      setLoading(false);
      onVaultUpdate();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const cancelMembership = async () => {
    try {
      setBackLoading(true);
      await akord.membership.revoke(operation.modelId);
      onMembers(members.filter(member => member.id !== operation.modelId))
      setBackLoading(false);
      onVaultUpdate();
    } catch (err) {
      setBackLoading(false);
      console.log(err);
    }
  };

  return (
    <div
      style={{
        marginBottom: positionedBeforeDate ? "74px" : "16px"
      }}
    >
      <OperationTitleWithIcon actionRef={operation.actionRef} color={color} title={`${operation?.email} was verified`} />
      <Typography noWrap variant="body2" className="small" paragraph>
        Please confirm access to the vault.
      </Typography>
      <div
        style={{
          maxWidth: "300px"
        }}
      >
        <ButtonGroup
          size="small"
          noMinWidth={true}
          handleNext={() => confirmMembership()}
          handleBack={() => cancelMembership()}
          disableBackButton={false}
          nextDiasabled={loading}
          nextText="Confirm access"
          backText="Cancel"
          inverted={false}
          hideBackButton={false}
          fullWidthNextButton={false}
          hideIcon={true}
          disabledRed={false}
          showButtonInColumn={false}
          loading={loading}
          backLoading={backLoading}
          noMarginTop={true}
        />
      </div>
    </div>
  );
}

export default UserVerified;
