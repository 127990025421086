import { MinusInCircleIcon, PlusIcon, RestoreIcon } from "@akord/addon-icons";
import { Box, IconButton, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import UploadLabel from "./UploadLabel";

type FilesCollectionDropAreaProps = {
  onSetFiles: (files: File[]) => void;
  height?: string;
  width?: string;
};

const FilesCollectionDropArea: React.FC<FilesCollectionDropAreaProps> = ({ onSetFiles, height = "200px", width = "100%" }) => {
  const [dragedOver, setDragedOver] = useState(false);
  const [filesCollectionPreview, setFilesCollectionPreview] = useState<File[]>([]);
  // const handleFilesForPreview = file => {
  //   setFilePreview(URL.createObjectURL(file));
  // };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(false);
  };
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(true);
  };

  const stopEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleImageDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(false);
    const files = event.dataTransfer.files;
    setFilesCollectionPreview(Array.from(files));
    onSetFiles(Array.from(files));
  };

  const removeFile = (file: File) => {
    setFilesCollectionPreview(filesCollectionPreview.filter(item => item.name !== file.name));
    onSetFiles(filesCollectionPreview.filter(item => item.name !== file.name));
  };

  const addMoreFiles = (files: FileList | null) => {
    if (!files) return;
    setFilesCollectionPreview([...filesCollectionPreview, ...Array.from(files)]);
    onSetFiles([...filesCollectionPreview, ...Array.from(files)]);
  };

  const replaceFiles = (files: FileList | null) => {
    if (!files) return;
    setFilesCollectionPreview(Array.from(files));
    onSetFiles(Array.from(files));
  };

  return (
    <Box>
      <Box
        mb={2}
        mt={4}
        display="flex"
        flexDirection="column"
        onDrop={handleImageDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        sx={{
          borderRadius: "4px",
          border: "1px solid",
          borderColor: "background.tertiary",
          textAlign: "center",
          minHeight: height,
          height: "100%",
          overflowY: "auto",
          backgroundColor: dragedOver ? "rgb(29, 39, 114, 0.5)" : filesCollectionPreview.length ? "inherit" : "primary.iconInvert",
          "&:hover": {
            ".hidden-text": { visibility: "visible", opacity: "1", transition: "visibility 0s, opacity 0.3s linear" },
            ".image-preview": { opacity: "0.4", cursor: "pointer", transition: "opacity 0.3s" }
          }
        }}
        width={width}
        position="relative"
      >
        {!filesCollectionPreview.length ? (
          <Box margin="auto">
            <Typography component="div" variant="caption" className="small">
              Drag and drop here
            </Typography>
            <label>
              <input
                name="upload-collection"
                id="upload-collection"
                type="file"
                accept="*"
                multiple
                style={{ display: "none" }}
                onChange={event => {
                  if (!event?.target?.files) return;
                  onSetFiles(Array.from(event.target.files));
                  setFilesCollectionPreview(Array.from(event.target.files));
                }}
              />

              <Link
                variant="caption"
                className="small"
                color="text.tertiary"
                sx={{
                  borderBottomColor: "text.tertiary"
                }}
              >
                or select files
              </Link>
            </label>
          </Box>
        ) : (
          <Box sx={{ padding: "12px", textAlign: "left" }} justifySelf="top" maxHeight={height}>
            {filesCollectionPreview.map((file, index) => (
              <Box key={index} display="flex" justifyContent="space-between">
                <Typography variant="body2" className="small">
                  {file.name}
                </Typography>
                <IconButton onClick={() => removeFile(file)}>
                  <MinusInCircleIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="end">
        <IconButton sx={{ marginRight: 4 }}>
          <UploadLabel handleOnChange={addMoreFiles}>
            <Box display="flex" sx={{ cursor: "pointer" }}>
              <PlusIcon fontSize="small" sx={{ marginRight: 2 }} />
              <Typography variant="body2" className="small">
                Add more files
              </Typography>
            </Box>
          </UploadLabel>
        </IconButton>
        <IconButton>
          <UploadLabel handleOnChange={replaceFiles}>
            <Box display="flex" sx={{ cursor: "pointer" }}>
              <RestoreIcon fontSize="small" sx={{ marginRight: 2 }} />
              <Typography variant="body2" className="small">
                Replace files
              </Typography>
            </Box>
          </UploadLabel>
        </IconButton>
      </Box>
    </Box>
  );
};

export default FilesCollectionDropArea;
