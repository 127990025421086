import React from "react";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import NoThumbnail from "../../../components/common/NoThumbnail";
// import OnImageMenu from "../../../components/common/OnImageMenu";

function NoteCreated(props) {
  const { operation, positionedBeforeDate, color } = props;
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const handleMenu = event => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  return (
    <div style={{ marginBottom: positionedBeforeDate ? "74px" : "16px" }}>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        title={
          operation?.note?.versions.length > 1
            ? `New version added  ·  version ${
                operation?.note?.versions?.length || 1
              }`
            : null
        }
        color={color}
      />
      {operation?.group ? (
        operation?.group?.map((item, index) => (
          <div key={index}>
            <NoThumbnail
              positionedBeforeDate={positionedBeforeDate}
              stackItem={item}
              // handleMenu={handleMenu}
              lastItem={operation.group.length - 1 === index}
              stackStatus={item.note.status}
              // stackHash={item.note.revisions[0].hash}
            />
          </div>
        ))
      ) : (
        <NoThumbnail
          positionedBeforeDate={positionedBeforeDate}
          stackItem={operation}
          // handleMenu={handleMenu}
          stackStatus={operation.note.status}
          // stackHash={operation.hash}
        />
      )}
      {/* <OnImageMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        operation={operation}
      /> */}
    </div>
  );
}

export default NoteCreated;
