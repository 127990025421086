import React from "react";
import { useParams } from "react-router-dom";
import { RecoverStepsOptions } from "../../types/globalTypes";
import RecoverEmail from "./RecoverEmail";
import RecoverNewPassword from "./RecoverNewPassword";
import RecoverPhrase from "./RecoverPhrase";

const RecoverSteps: React.FC = () => {
  let { step }: { step: RecoverStepsOptions } = useParams();

  const renderStep = () => {
    switch (step) {
      case "backup-phrase":
        return <RecoverPhrase />;
      case "reset-password":
        return <RecoverNewPassword />;

      default:
        return <RecoverEmail />;
    }
  };
  return renderStep();
};

export default RecoverSteps;
