import { Stack, Folder } from "@akord/akord-js";

export const processStack = (stack: Stack) => {
  return Object.defineProperties(nullifyParentId(stack), {
    size: {
      value: stack.versions.reduce((acc, version) => acc + version.size, 0)
    },
    version: {
      value: stack.versions.length
    }
  });
};

export const processFolder = (folder: Folder) => nullifyParentId(folder);

export const getAssetType = (item: Stack | Folder) => {
  if (!item) return;
  const type = item instanceof Stack ? "Stack" : item instanceof Folder ? "Folder" : "Other";
  return type;
};

// TODO: this must go away...
export const nullifyParentId = (asset: Stack | Folder) => {
  if (asset.parentId === "null") {
    asset.parentId = undefined;
  }
  return asset;
};
