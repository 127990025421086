import React from "react";
import { Box, Checkbox, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CheckboxEmptyIcon, CheckboxTickIcon } from "@akord/addon-icons";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { grey } from "../../../theme/colors";
import SelectAll from "../SelectAll";
import AssetsPagination from "../Pagination/AssetsPagination";
import { SelectedItem, useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useDownloader } from "../../file/FileDownloader";
import RevokedStackToolbar from "./RevokedStackToolbar";
import ActiveStackToolbar from "./ActiveStackToolbar";
import ArchivedVaultsToolbar from "./ArchivedVaultsToolbar";
import { useVaultsSelectionContext } from "../../../contexts/VaultsSelectionContextProvider";

type BatchActionsBarStyleProps = {
  revoked: boolean;
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, BatchActionsBarStyleProps>(theme => ({
  batchContainer: {
    borderBottom: `1px solid`,
    borderTop: ({ revoked }) => (revoked ? `1px solid` : "none"),
    borderBottomColor: ({ darkMode }) => (darkMode ? grey[800] : grey[200]),
    borderTopColor: ({ darkMode }) => (darkMode ? grey[800] : grey[200])
  },
  batchContainerBackground: {
    backgroundColor: ({ darkMode }) => (darkMode ? [grey[800], "30"].join("") : [grey[100], "30"].join(""))
  },
  iconCheck: {
    marginRight: theme.spacing(7),
    padding: 0
  },
  icons: {
    marginRight: theme.spacing(6)
  },
  disabledButton: {
    "&.MuiButtonBase-root": {
      "&.Mui-disabled": {
        cursor: "not-allowed",
        pointerEvents: "auto"
      }
    }
  }
}));

type BatchActionsBarProps = {
  folderId: string | null;
  revoked?: boolean;
  isPublicRoute?: boolean;
  isVaultPage?: boolean;
  cloud?: boolean;
};

const BatchActionsBar: React.FC<BatchActionsBarProps> = ({
  folderId,
  revoked = false,
  isPublicRoute = false,
  isVaultPage = false,
  cloud = false
}) => {
  const { darkMode, isMobile, archivedVaults } = useGlobalContext();
  const { modal, onNotificationData } = useNotificationsContext();
  const { onSelectedItems, selectedItemsMap, filteredSortedActiveAssets, folders, stacks, getPaginatedData } = useAssetsContext();
  const { onSelectedVaults, selectedVaultsMap } = useVaultsSelectionContext() || {};
  const { onSaveAsZip, onSaveAs } = useDownloader({ useLoader: true });

  const classes = useStyles({ darkMode: darkMode, revoked: revoked });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isVaultPage) onSelectedVaults(event.target.name, checked);
    else onSelectedItems(event.target.name, checked, revoked);
  };

  const handleClick = async (name: string) => {
    const items = isVaultPage ? new Map(selectedVaultsMap) : new Map(selectedItemsMap);
    const assets = Array.from(items.values()) as SelectedItem[];
    switch (name) {
      case "move":
        modal.onConfirmModalType("batchMove");
        modal.onModalVisibility(null, "batchMove");
        onNotificationData({ batchItems: items });
        break;
      case "revoke":
        modal.onConfirmModalType("batchRevoke");
        modal.onModalVisibility(null, "batchRevoke");
        onNotificationData({ batchItems: items });
        break;
      case "download":
        if (assets.length === 1 && assets[0].objectType === "Stack") {
          onSaveAs(assets[0].id);
        } else {
          onSaveAsZip(items);
        }
        break;
      case "restore":
        modal.onConfirmModalType("batchRestore");
        modal.onModalVisibility(null, "batchRestore");
        onNotificationData({
          batchItems: items,
          folderId: folderId
        });
        break;
      case "remove":
        modal.onConfirmModalType("batchRemove");
        modal.onModalVisibility(null, "batchRemove");
        onNotificationData({
          batchItems: items
        });
        break;
      case "delete":
        modal.onConfirmModalType("batchDelete");
        modal.onModalVisibility(null, "batchDelete");
        onNotificationData({
          batchItems: items
        });
        break;
      case "removeVaults":
        modal.onConfirmModalType("batchRemoveVaults");
        modal.onModalVisibility(null, "batchRemoveVaults");
        onNotificationData({
          batchItems: items
        });
        break;
      case "restoreVaults":
        modal.onConfirmModalType("batchRestoreVaults");
        modal.onModalVisibility(null, "batchRestoreVaults");
        onNotificationData({
          batchItems: items
        });
        break;

      default:
        break;
    }
  };

  // Need to select only files in a current folder
  const folderScopeStacks = filteredSortedActiveAssets.filter(asset => asset.parentId === folderId);

  const revokedScopeAssets = [...(stacks ? stacks : []), ...(folders ? folders : [])].filter(item => item.status === "REVOKED");

  const includesAllItemsOnPage = () => {
    return getPaginatedData().every(item => selectedItemsMap.has(item.id));
  };

  const allItemsInVault = revoked ? revokedScopeAssets : filteredSortedActiveAssets;

  return (
    <Box
      height={isMobile && selectedItemsMap.size > 0 ? "88px" : "48px"}
      className={classes.batchContainer}
      mb={revoked ? 3 : 0}
      sx={{ backgroundColor: "background.default" }}
    >
      <Box height="100%" display="flex" flexDirection="column" justifyContent="center" className={classes.batchContainerBackground}>
        <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" ml={6}>
            <Tooltip title="Select all" arrow>
              <Checkbox
                onChange={handleChange}
                name="allItemsOnPage"
                icon={<CheckboxEmptyIcon />}
                checkedIcon={<CheckboxTickIcon />}
                disableRipple
                className={classes.iconCheck}
                checked={
                  isVaultPage
                    ? selectedVaultsMap.size === archivedVaults?.length && selectedVaultsMap.size > 0
                    : revoked
                    ? selectedItemsMap.size === revokedScopeAssets.length && selectedItemsMap.size > 0
                    : includesAllItemsOnPage() && selectedItemsMap.size > 0
                }
              />
            </Tooltip>
            {isVaultPage ? (
              <ArchivedVaultsToolbar classes={classes} selectedVaultsMap={selectedVaultsMap} handleClick={handleClick} />
            ) : revoked ? (
              <RevokedStackToolbar classes={classes} selectedItemsMap={selectedItemsMap} handleClick={handleClick} cloud={cloud} />
            ) : (
              <ActiveStackToolbar
                classes={classes}
                selectedItemsMap={selectedItemsMap}
                folderScopeStacks={folderScopeStacks}
                folderId={folderId}
                handleClick={handleClick}
                isPublicRoute={isPublicRoute}
              />
            )}
          </Box>
          {!isMobile && selectedItemsMap.size > 0 && (
            <SelectAll revoked={revoked} allItemsInVault={allItemsInVault} isFolder={!!folderId} />
          )}
          {revoked ? <span /> : <AssetsPagination />}
        </Box>
        {isMobile && selectedItemsMap.size > 0 && (
          <Box height="100%" alignItems="center" display="flex" alignSelf="center">
            <SelectAll revoked={revoked} allItemsInVault={allItemsInVault} isFolder={!!folderId} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BatchActionsBar;
