import { DefaultPaletteOptions } from "../../../theme/mui";

export const generateColorPalette = (mainColor: string, secondaryColor: string, palette: DefaultPaletteOptions) => {
  return {
    ...palette,
    primary: {
      ...palette.primary,
      light: shadeColor(mainColor, 10),
      dark: shadeColor(mainColor, -10),
      darkest: shadeColor(mainColor, -30),
      veryDark: shadeColor(mainColor, -50)
    },
    secondary: { main: shadeColor(mainColor, 20) },
    info: {
      ...palette.info,
      light: shadeColor(secondaryColor, 10),
      dark: shadeColor(secondaryColor, -10),
      darkest: shadeColor(secondaryColor, -50),
      veryLight: shadeColor(secondaryColor, 50),
      veryDark: shadeColor(secondaryColor, -60)
    }
  };
};

export const shadeColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = (R * (100 + percent)) / 100;
  G = (G * (100 + percent)) / 100;
  B = (B * (100 + percent)) / 100;

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  let RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};
