import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { LetterIcon } from "@akord/addon-icons";
import { useSignupContext } from "../../contexts/SignupContext";
import { handleResendEmail } from "./signup-helpers";

const useStyles = makeStyles<Theme>({
  container: {
    paddingTop: "128px",
    height: "60%",
    textAlign: "center"
  }
});

const DoneSingUp: React.FC = () => {
  const { formData } = useSignupContext();
  const classes = useStyles();

  const [resendClick, setResendClick] = useState(false);
  const handleResendClick = (value: boolean) => setResendClick(value);

  return (
    <Box className={classes.container}>
      <Box
        sx={{
          margin: "auto",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: "primary.main",
          display: "flex"
        }}
      >
        <LetterIcon color="action" sx={{ margin: "auto" }} />
      </Box>
      <Box mb={2} mt={4}>
        <Typography variant="h1" align="center">
          Final step – verify your email
        </Typography>
      </Box>
      <Typography variant="body2" paragraph align="center">
        To complete your account creation, please click the link we’ve sent to your email address. If you don’t see the email, check your
        spam.
      </Typography>
      <Typography
        variant="body2"
        align="center"
        component="a"
        color="text.primary"
        onClick={() => handleResendEmail(formData, handleResendClick)}
      >
        {resendClick ? "Resent!" : "Resend email"}
      </Typography>
    </Box>
  );
};

export default DoneSingUp;
