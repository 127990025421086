import React from "react";
import { DialogActions, Button, Box, FormControl, FormGroup, FormControlLabel, Typography, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { confirmationModalOptions } from "./confirmation-modal-options";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import AkordCheckbox from "../CheckBox";
import { FileWithAction } from "../../file/FileUploader";
import { ModalType } from "../../../hooks/useModal";

type ThreeButtonConfirmStyleProps = {
  isDesktop: boolean;
};

const useStyles = makeStyles<Theme, ThreeButtonConfirmStyleProps>(theme => ({
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column-reverse")
  },
  buttonGroup: {
    minWidth: "auto",
    "&:last-of-type": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
    },
    "&:first-of-type": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4)),
      marginTop: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
    }
  },
  btnGroupTwo: {
    display: "flex",
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column-reverse"),
    width: ({ isDesktop }) => (isDesktop ? "auto" : "100%")
  }
}));

type ThreeButtonConfirmProps = {
  uploadFileIndex: number;
  onUploadFileIndex: (index: number) => void;
  filesWithAction: React.MutableRefObject<FileWithAction[]>;
};

const ThreeButtonConfirm: React.FC<ThreeButtonConfirmProps> = ({ uploadFileIndex, onUploadFileIndex, filesWithAction }) => {
  const [applyToAll, setApplyToAll] = React.useState(false);
  const { modal, duplicateFiles, uploadEventRef, clearUploadEventRef, uploadedFiles } = useNotificationsContext();
  const { width } = useGlobalContext();
  const { onDrawerActionType } = useAssetsContext();

  const isDesktop = width !== "xs";

  const confirmModalType = modal.confirmModalType as ModalType;
  const { buttonTertiary, buttonSecondary, buttonTextPrimary } = confirmationModalOptions[confirmModalType];

  const classes = useStyles({
    isDesktop: isDesktop
  });

  const onButtonClick = (action: string) => {
    if (action === "CANCEL") {
      modal.onModalVisibility(null, confirmModalType);
      if (uploadEventRef.current) clearUploadEventRef();
      return;
    }
    if (action === "Replace" || action === "Skip") {
      onDrawerActionType("upload_revision");
    }
    // Check for APPLY ALL option
    if (applyToAll) {
      // If a user skips all files whithout newly added files - dont show upload dialog
      if (action === "Skip" && duplicateFiles.length === uploadedFiles.length) {
        modal.onModalVisibility(null, confirmModalType);
        if (uploadEventRef.current) clearUploadEventRef();
        return;
      }
      while (duplicateFiles[uploadFileIndex]) {
        filesWithAction.current = [...filesWithAction.current, { ...duplicateFiles[uploadFileIndex], action: action }];
        uploadFileIndex++;
      }
    } else filesWithAction.current = [...filesWithAction.current, { ...duplicateFiles[uploadFileIndex], action: action }];

    if (duplicateFiles[uploadFileIndex + 1]) onUploadFileIndex(uploadFileIndex + 1);
    else {
      // In case if all files were skipped dont shouw upload modal
      if (filesWithAction.current.every(file => file.action === "Skip") && filesWithAction.current.length === uploadedFiles.length) {
        modal.onModalVisibility(null, confirmModalType);
        if (uploadEventRef.current) clearUploadEventRef();
        return;
      }
      modal.onModalVisibility(null, confirmModalType);
      modal.onConfirmModalType(null);
      modal.onConfirmModalType("confirmUpload");
      modal.onModalVisibility(null, "confirmUpload");
    }
  };

  return (
    <DialogActions style={{ display: "block" }}>
      <Box
        className={classes.dialogActions}
        // classes={{ spacing: classes.dialogSpacing }}
      >
        <div className={classes.btnGroupOne}>
          <Button onClick={() => onButtonClick("CANCEL")} size={isDesktop ? "xs" : "small"} variant="text" color="primary">
            Cancel
          </Button>
        </div>
        <div className={classes.btnGroupTwo}>
          {buttonTertiary && confirmModalType === "duplicateFile" && duplicateFiles.length > 1 && (
            <Button
              onClick={() => onButtonClick(buttonTertiary)}
              size={isDesktop ? "xs" : "small"}
              color="primary"
              variant="outlined"
              fullWidth={!isDesktop}
              type="submit"
              disableElevation
              className={classes.buttonGroup}
              sx={{ marginRight: isDesktop ? "12px" : 0 }}
            >
              {buttonTertiary}
            </Button>
          )}
          <Button
            size={isDesktop ? "xs" : "small"}
            onClick={() => buttonSecondary && onButtonClick(buttonSecondary)}
            color="primary"
            variant="outlined"
            fullWidth={!isDesktop}
            type="submit"
            disableElevation
            className={classes.buttonGroup}
          >
            {buttonSecondary}
          </Button>
          <Button
            onClick={() => buttonTextPrimary && onButtonClick(buttonTextPrimary)}
            size={isDesktop ? "xs" : "small"}
            color="primary"
            variant="contained"
            fullWidth={!isDesktop}
            type="submit"
            disableElevation
            className={classes.buttonGroup}
            sx={{ marginLeft: isDesktop ? "12px" : 0 }}
          >
            {buttonTextPrimary}
          </Button>
        </div>
      </Box>
      {confirmModalType === "duplicateFile" && duplicateFiles.length > 1 && (
        <Box mt={4}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                sx={{ alignItems: "normal" }}
                control={
                  <AkordCheckbox
                    inputProps={{ "aria-label": "acceptPhrase" }}
                    checked={applyToAll}
                    onChange={() => setApplyToAll(prev => !prev)}
                    name="acceptPhrase"
                  />
                }
                label={<Typography variant="body2">Apply to all</Typography>}
              />
            </FormGroup>
          </FormControl>
        </Box>
      )}
    </DialogActions>
  );
};

export default ThreeButtonConfirm;
