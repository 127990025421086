import { Collection, NFT } from "@akord/akord-js";
import { Box } from "@mui/material";
import React from "react";
import NftItem from "../../pages/nft/NftItem";

type NftGridListProps = {
  isLoading: boolean;
  nfts: (NFT | Collection)[] | undefined;
};

const NftGridList: React.FC<NftGridListProps> = ({ isLoading, nfts }) => {
  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4} mb={5}>
      {isLoading
        ? new Array(3).fill(null).map((_, index) => <NftItem key={index} nft={null} />)
        : nfts?.map((nft, index) => <NftItem key={index} nft={nft} />)}
    </Box>
  );
};

export default NftGridList;
