import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { downloadTxtFile, copyToClipboard } from "../../helpers/helpers";
import { DownloadIcon, ClipboardIcon } from "@akord/addon-icons";
import BackupPhraseBox from "../../components/common/BackupPhraseBox";

const useStyles = makeStyles<Theme>(theme => ({
  containerText: {
    lineHeight: "32px"
  },
  rootIcon: {
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main
  }
}));

type SecurityPhraseProps = {
  backupPhrase: string[];
  userEmail: string;
};

const SecurityPhrase: React.FC<SecurityPhraseProps> = ({ backupPhrase, userEmail }) => {
  const [copy, setCopy] = React.useState(false);
  const classes = useStyles();

  return (
    <Box mb={5}>
      <Typography variant="body2">Below is your unique 12-word phrase.</Typography>
      <Box my={5}>
        <BackupPhraseBox backupPhraseArray={backupPhrase} noMinHeight />
      </Box>

      <Box mb={7} className={classes.containerText}>
        <DownloadIcon fontSize="small" color="action" classes={{ root: classes.rootIcon }} />
        <Typography
          component="a"
          variant="body2"
          color="text.primary"
          onClick={() => {
            downloadTxtFile(backupPhrase, userEmail);
          }}
        >
          Download
        </Typography>
        <br />
        <ClipboardIcon fontSize="small" color="action" classes={{ root: classes.rootIcon }} />
        <Typography component="a" variant="body2" color="text.primary" onClick={() => copyToClipboard(backupPhrase.join(" "), setCopy)}>
          {copy ? "Copied!" : "Copy to clipboard"}
        </Typography>
      </Box>
    </Box>
  );
};

export default SecurityPhrase;
