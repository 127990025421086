import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getSubscriptionPlans } from "../helpers/api-helpers";
import { Invoice, ScheduledSubscriptionPlan, ScheduledSubscriptionPlanPhase, SubscriptionPlan } from "../types/stripeTypes";
import { useGlobalContext } from "./GlobalDataProvider";
import { useNotificationsContext } from "./NotificationsContextProvider";

const currencies = [
  {
    value: "EUR",
    label: "€"
  },
  {
    value: "USD",
    label: "$"
  },

  {
    value: "GBP",
    label: "£"
  }
];

type PaymentContextTypes = {
  billingForm: BillingFormProps;
  onBillingForm: React.Dispatch<React.SetStateAction<BillingFormProps>>;
  currencyCode: CurrencyCode;
  onCurrencyCode: React.Dispatch<React.SetStateAction<CurrencyCode>>;
  currencies: {
    value: string;
    label: string;
  }[];
  discountCoupon?: string;
  onDiscountCoupon: React.Dispatch<React.SetStateAction<string | undefined>>;
  isDiscountCouponApplied: boolean;
  onDiscountCouponApplied: React.Dispatch<React.SetStateAction<boolean>>;
  getCurrencySymbol: (code: string) => string | undefined;
  stripePk?: string;
  subscriptionPlan?: SubscriptionPlan;
  subscriptionPlansList?: SubscriptionPlan[];
  upcomingSubscriptionPlan?: ScheduledSubscriptionPlanPhase;
  isFeatureAllowed: (feature: string) => boolean;
  isOnFreePlan: () => boolean;
  isOnPaidPlan: () => boolean;
  fetchSubscriptionPlans: () => Promise<{
    plans: SubscriptionPlan[];
    scheduledPlans: ScheduledSubscriptionPlan[];
  }>;
  loading: boolean;
  onLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onUpcomingInvoice: React.Dispatch<Invoice>;
  upcomingInvoice?: Invoice;
  renderPrice: (unitAmount?: number, currency?: string) => string;
  planName: string;
  quantity: number;
  firstPaidPlan?: SubscriptionPlan;
  getFirstPaidPlanPrice: (plan: SubscriptionPlan) => number;
};

export type CurrencyCode = "USD" | "EUR" | "GBP";

export type BillingFormProps = {
  name: string;
  companyName: string;
  companyVat: string;
  country: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

const PaymentContext = React.createContext<PaymentContextTypes>({} as PaymentContextTypes);

const PaymentContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { isAuthenticated } = useGlobalContext();
  const { modal } = useNotificationsContext();

  const [billingForm, setBillingForm] = useState<BillingFormProps>({
    name: "",
    companyName: "",
    companyVat: "",
    country: "",
    address: "",
    city: "",
    state: "",
    zip: ""
  });
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan>();
  const [subscriptionPlansList, setSubscriptionPlansList] = useState<SubscriptionPlan[]>();
  const [upcomingSubscriptionPlan, setUpcomingSubscriptionPlan] = useState<ScheduledSubscriptionPlanPhase>();
  const [stripePk, setStripePk] = useState<string>();
  const [currencyCode, setCurrencyCode] = useState<"USD" | "EUR" | "GBP">("USD");
  const [discountCoupon, setDiscountCoupon] = useState<string>();
  const [isDiscountCouponApplied, setIsDiscountCouponApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upcomingInvoice, setUpcomingInvoice] = useState<Invoice>();
  const [planName, setPlanName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setPlanName(urlParams.get("plan") || "");
    setQuantity(parseInt(urlParams.get("quantity") || "0") || 0);
  }, [location]);

  const getCurrencySymbol = (code: string) => {
    if (!code) {
      return "";
    }
    return currencies.find((currency) => currency.value.toLowerCase() === code.toLowerCase())?.label;
  };

  const renderPrice = (unitAmount?: number, currency?: string) => {
    if (unitAmount != null && currency) {
      if (unitAmount < 0) {
        return `-${getCurrencySymbol(currency)}${(unitAmount * -1) / 100}`;
      }
      return `${getCurrencySymbol(currency)}${unitAmount / 100}`;
    }
    return "";
  };

  const fetchSubscriptionPlans = React.useCallback(async () => {
    const plansResponse = await getSubscriptionPlans(currencyCode);

    if (!plansResponse) {
      return { plans: [], scheduledPlans: [] };
    }
    setStripePk(plansResponse.publishableKey);
    const plans = plansResponse.subscriptionPlans;
    setSubscriptionPlansList(plans);
    if (plans && plans.length) {
      const activePlans = plans.filter((plan: SubscriptionPlan) => plan.active_subscription);
      if (activePlans && activePlans.length) {
        setSubscriptionPlan(activePlans[0]);
      } else {
        setSubscriptionPlan(plans[0]);
      }
    }

    const scheduledPlans = plansResponse.scheduledSubscriptionPlans;
    if (scheduledPlans && scheduledPlans.length) {
      setUpcomingSubscriptionPlan(scheduledPlans[0].phases[0]);
    }
    return { plans, scheduledPlans };
  }, [currencyCode]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchSubscriptionPlans();
    }
  }, [isAuthenticated, fetchSubscriptionPlans]);

  const isFeatureAllowed = (feature: string): boolean => {
    if (subscriptionPlan && subscriptionPlan.metadata.app_features) {
      const features = subscriptionPlan.metadata.app_features.split(";");
      return features.includes(feature);
    }
    return false;
  };

  const isOnFreePlan = () => {
    return (
      !subscriptionPlan ||
      Object.keys(subscriptionPlan.price.currency_options).some((key) => {
        const tiers = subscriptionPlan.price.currency_options[key].tiers;
        return tiers && tiers[0].flat_amount === 0;
      })
    );
  };

  const isOnPaidPlan = () => {
    return (
      !!subscriptionPlan &&
      Object.keys(subscriptionPlan.price.currency_options).some((key) => {
        const tiers = subscriptionPlan.price.currency_options[key].tiers;
        return tiers && tiers[0].flat_amount > 0;
      })
    );
  };

  const firstPaidPlan = subscriptionPlansList?.find((plan) => {
    const tiers = plan.price.currency_options[currencyCode.toLowerCase()].tiers;
    return tiers && tiers.length > 0 && tiers[0].flat_amount > 0;
  });

  const getFirstPaidPlanPrice = (plan: SubscriptionPlan) => {
    const tiers = plan.price.currency_options[currencyCode.toLowerCase()].tiers;
    const cloudStorage = parseFloat(plan.metadata.cloud_gb.split(";")[0]);
    const flatAmount = tiers?.[0].flat_amount || 0;
    const unitAmount = parseFloat(tiers?.[0].unit_amount_decimal || "0");
    return (flatAmount + unitAmount * cloudStorage) / 100;
  };

  return (
    <PaymentContext.Provider
      value={{
        billingForm: billingForm,
        onBillingForm: setBillingForm,
        currencyCode: currencyCode,
        onCurrencyCode: setCurrencyCode,
        currencies: currencies,
        discountCoupon: discountCoupon,
        onDiscountCoupon: setDiscountCoupon,
        isDiscountCouponApplied: isDiscountCouponApplied,
        onDiscountCouponApplied: setIsDiscountCouponApplied,
        getCurrencySymbol,
        stripePk,
        subscriptionPlan,
        subscriptionPlansList,
        upcomingSubscriptionPlan,
        isFeatureAllowed,
        isOnFreePlan,
        isOnPaidPlan,
        fetchSubscriptionPlans,
        loading: loading,
        onLoading: setLoading,
        onUpcomingInvoice: setUpcomingInvoice,
        upcomingInvoice: upcomingInvoice,
        renderPrice: renderPrice,
        planName: planName,
        quantity: quantity,
        firstPaidPlan,
        getFirstPaidPlanPrice
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
export default PaymentContextProvider;

export const usePaymentContext = () => useContext(PaymentContext);
