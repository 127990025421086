import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, Theme, Tooltip } from "@mui/material";
import { MenuDotsIcon, MenuDotsHorizontalIcon } from "@akord/addon-icons";

type MoreActionButtonStyleProps = {
  menuActive: boolean;
};

const useStyles = makeStyles<Theme, MoreActionButtonStyleProps>(theme => ({
  menuIcon: {
    color: ({ menuActive }) => (menuActive ? theme.palette.primary.main : ""),
    "&:hover": {
      color: ({ menuActive }) => (menuActive ? theme.palette.primary.main : "")
    }
  }
}));

type MoreActionButtonProps = {
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  menuActive: boolean;
  hidden?: boolean;
  horizontalButton?: boolean;
  onboarding?: boolean;
  disabled?: boolean;
  arialLabel?: string;
};

const MoreActionButton: React.FC<MoreActionButtonProps> = ({
  handleClick,
  menuActive,
  hidden = false,
  horizontalButton = false,
  onboarding = false,
  disabled = false,
  arialLabel = "display more actions"
}) => {
  const classes = useStyles({
    menuActive: menuActive
  });

  return (
    <Tooltip title="Menu" arrow>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <IconButton
          disabled={disabled}
          aria-label={arialLabel}
          onClick={e => (handleClick ? handleClick(e) : null)}
          className={onboarding ? [classes.menuIcon, "step5"].join(" ") : classes.menuIcon}
          sx={{ display: hidden ? "none" : "initial" }}
        >
          {horizontalButton ? <MenuDotsHorizontalIcon /> : <MenuDotsIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default MoreActionButton;
