import React from "react";
import { Helmet } from "react-helmet";
import { Box, CssBaseline } from "@mui/material";
import { TopBarMain, BottomBarSm, MenuDrawerMd } from "..";
import { withRouter } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { getVaultStatus, getVaultId } from "../../../helpers/helpers";
import Onboarding from "../Onboarding/Onboarding";
import ThemeWrapper from "./ThemeWrapper";
import InfoDrawer from "../InfoDrawer/InfoDrawer";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import DescriptionDrawer from "../DescriptionDrawer/DescriptionDrawer";
import { useOrgContext } from "../../../contexts/OrgContextProvider";

const { host, protocol } = window.location;
const baseUrl = `${protocol}//${host}`;

const SafeWrapper = (props) => {
  const { children, location, history, isPublicRoute = false } = props;
  const { org } = useOrgContext();
  const { darkMode, isMobile, userAttributes, profileDetails, showInfo, onShowInfo, showDescriptionDrawer, onShowDescriptionDrawer } =
    useGlobalContext();
  const { vault } = useVaultContext();
  const { notificationData } = useNotificationsContext();
  const brandName = org?.name ?? "Akord";

  const getPageTitle = () => {
    switch (true) {
      case location.pathname === `/vaults/${getVaultStatus(location.pathname)}/${getVaultId(location.pathname)}/manage-access`:
        return "Manage Access";
      case location.pathname === `/vaults/${getVaultStatus(location.pathname)}/${getVaultId(location.pathname)}/invite-to-vault`:
        return "Invite to vault";
      case location.pathname.includes(`/vaults/${getVaultStatus(location.pathname)}/${getVaultId(location.pathname)}`):
        return vault?.name || "Loading...";
      case location.pathname === `/vaults/${getVaultStatus(location.pathname)}`:
        return "Vaults";
      case location.pathname === "/notifications":
        return "Notifications";
      case location.pathname === "/account":
        return "Account";
      case location.pathname === "/account/notifications":
        return "Account | Notifications";
      case location.pathname === "/account/display":
        return "Account | Display";
      case location.pathname === "/account/developers":
        return "Account | Developers";
      case location.pathname === "/account/invoices":
        return "Account | Invoices";
      case location.pathname === "/account/security":
      case location.pathname === "/account/security/backup-phrase":
      case location.pathname === "/account/security/change-password":
      case location.pathname === "/account/security/two-factor-authentication":
        return "Account | Security";
      case location.pathname === "/account/organisation":
      case location.pathname === "/account/organisation/members":
      case location.pathname === "/account/organisation/invite":
        return "Account | Organisation";
      case location.pathname === "/storage":
        return "Storage";

      default:
        return brandName;
    }
  };

  const showHeader = () => {
    switch (true) {
      case location.pathname === "/account":
        return false;
      case location.pathname === "/notifications":
        return false;
      case location.pathname === `/vaults/${getVaultStatus(location.pathname)}`:
        return true;

      default:
        return false;
    }
  };

  const showNavigation = () => {
    switch (true) {
      case location.pathname === "/account/plans-and-payments/billing":
        return false;

      default:
        return true;
    }
  };

  return (
    <ThemeWrapper darkMode={darkMode}>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${getPageTitle()}`}</title>
        <link rel="canonical" href={`${baseUrl}${location.pathname}`} />
      </Helmet>
      {userAttributes.userOnboarding && location.pathname.includes("/assets") && <Onboarding />}
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        overflow="auto"
        // overflow="hidden" //no sure - allows to scroll create vault: make sure works on other screens
      >
        {isMobile && showHeader() && (
          <TopBarMain isPublicRoute={isPublicRoute} darkMode={darkMode} isMobile={isMobile} profileDetails={profileDetails} />
        )}
        <Box display="flex" flexDirection="row" height="100%" width="100%">
          {!isMobile && showNavigation() && <MenuDrawerMd />}
          <Box
            display="flex"
            flexDirection="column"
            flex="1 0"
            height="100%"
            width="100%"
            // overflow="hidden" //no sure - allows to scroll create vault: make sure works on other screens
          >
            {children}
            <InfoDrawer isMobile={isMobile} showInfo={showInfo} onShowInfo={onShowInfo} infoData={notificationData} />
            <DescriptionDrawer
              darkMode={darkMode}
              isMobile={isMobile}
              showInfo={showDescriptionDrawer}
              onShowInfo={onShowDescriptionDrawer}
              infoData={notificationData}
            />
            {isMobile &&
            !location.pathname.match(`/vaults/${getVaultStatus(location.pathname)}/${getVaultId(location.pathname)}`) &&
            !location.pathname.match("/create-vault") ? (
              <BottomBarSm />
            ) : null}
          </Box>
        </Box>
      </Box>
    </ThemeWrapper>
  );
};

export default withRouter(SafeWrapper);
