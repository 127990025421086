import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { CloseInCircleIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { Theme } from "@mui/material/styles";
import { useNftContext } from "../../../contexts/NftContextProvider";

type ProgressBarProps = {};

type Timer = ReturnType<typeof setTimeout>;

const useStyles = makeStyles<Theme>(theme => ({
  progressBox: {
    background: theme.palette.background.card,
    borderRadius: "4px"
  }
}));

const ProgressBarMint: React.FC<ProgressBarProps> = () => {
  const [useIndeterminate, setUseIndeterminate] = useState(false);

  const { darkMode } = useGlobalContext();
  const { progress } = useProgressContext();
  const { mintCollectionCancelHook } = useNftContext();

  const delay = useRef<Timer>();
  const classes = useStyles({ darkMode: darkMode });

  useEffect(() => {
    if (useIndeterminate && progress === 0) {
      setUseIndeterminate(false);
    } else if (!useIndeterminate && progress === 100) {
      delay.current = setTimeout(() => {
        setUseIndeterminate(true);
      }, 1000);
    }
    return () => clearTimeout(delay.current);
  }, [progress, useIndeterminate]);

  const diff = Math.random() * 10;

  return (
    <Box mt={7} className={classes.progressBox}>
      <Box p={2}>
        <Box display="flex" sx={{ justifyContent: useIndeterminate ? "flex-start" : "space-between" }}>
          <Box display="flex" alignContent="center">
            <Typography variant="caption" component="div">
              {useIndeterminate ? "Finalising NFT collection..." : "Minting progress..."}
            </Typography>
          </Box>
          {!useIndeterminate && (
            <IconButton onClick={() => mintCollectionCancelHook?.abort()} size="large">
              <CloseInCircleIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        <Box display="flex" alignItems="baseline">
          {!useIndeterminate && (
            <>
              <Box width="100%" mr={1}>
                <LinearProgress color="primary" variant="buffer" value={progress} valueBuffer={progress + diff} />
              </Box>
              <Box>
                <Typography variant="caption" className="small" color="text.secondary" align="right">
                  {progress}%
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBarMint;
