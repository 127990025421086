import React from "react";
import MoreMenuRoot from "./MoreMenuRoot";
import MoreMenuItem from "./MoreMenuItem";
import { useNotificationMenuOptions } from "./MenuOptions";

type MenuNotofocationsProps = {
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  handleMenuClose: () => void;
  openMenu: boolean;
};

const MenuNotifications: React.FC<MenuNotofocationsProps> = ({ anchorEl, openMenu, handleMenuClose }) => {
  const { options: notificationOptions } = useNotificationMenuOptions();

  return (
    <MoreMenuRoot anchorEl={anchorEl} openMenu={openMenu} handleMenuClose={handleMenuClose} id="notification-menu">
      <div>
        {notificationOptions(handleMenuClose).map((menuItem, index) => {
          return menuItem.show && <MoreMenuItem key={index} menuItem={menuItem} />;
        })}
      </div>
    </MoreMenuRoot>
  );
};

export default MenuNotifications;
