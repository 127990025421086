import React from "react";
import { EditorState, convertFromRaw } from "draft-js";
import createLinkDecorator from "./LinkDecorator";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "@mui/material";
import { TermsOfAccessIcon } from "@akord/addon-icons";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import TermsModal from "../../../components/common/Modals/TermsModal";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";

const useStyles = makeStyles(theme => ({
  termsIcon: {
    marginRight: theme.spacing(1)
  }
}));

function VaultCreate(props) {
  const { operation, positionedBeforeDate, color } = props;
  const { darkMode } = useGlobalContext();
  const classes = useStyles({ darkMode: darkMode });
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = value => setOpenModal(value);
  const handleClickOpen = () => {
    handleOpenModal(true);
  };
  const handleClose = () => {
    handleOpenModal(false);
  };

  let editorState;
  if (operation.vault?.termsOfAccess) {
    try {
      const decorator = createLinkDecorator();
      const contentState = convertFromRaw(
        JSON.parse(operation.vault?.termsOfAccess)
      );
      editorState = EditorState.createWithContent(contentState, decorator);
    } catch (err) {
      console.log("Rich Text Error: ", err);
    }
  }

  return (
    <div style={{ marginBottom: positionedBeforeDate ? "74px" : "16px" }}>
      <OperationTitleWithIcon actionRef={operation.actionRef} color={color} />
      {editorState && editorState.getCurrentContent().hasText() ? (
        <>
          <TermsOfAccessIcon
            fontSize="small"
            color="disabled"
            className={classes.termsIcon}
          />
          <Link
            variant="body2"
            className="small"
            underline="none"
            color="text.secondary"
            onClick={handleClickOpen}
          >
            Terms of access
          </Link>
          <TermsModal
            openModal={openModal}
            handleOpenModal={handleOpenModal}
            operation={operation}
            handleClose={handleClose}
            editorState={editorState}
          />
        </>
      ) : (
        <div style={{ marginBottom: positionedBeforeDate ? "74px" : "16px" }} />
      )}
    </div>
  );
}

export default VaultCreate;
