import React from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useProgressContext } from "../../contexts/ProgressContext";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { processFileTree, processFiles } from "../../helpers/filesystem-helper";
import { getUniqueName } from "./utils/upload/upload-helpers";
import { getFolderId } from "../../helpers/helpers";
import { useStorageContext } from "../../contexts/StorageContextProvider";
import useSearchIndex from "../../hooks/useSearchIndex";
import { StackCreateItem } from "@akord/akord-js/lib/core/batch";
import { BatchStackCreateResponse, Stack } from "@akord/akord-js";

export type FileWithAction = File & { action: string; index?: number };
export type FileWithAddOn = File & { folderId?: string };

//TODO onUploadStart/onUploadDone looks like its not used - possibly remove
export const useUploader = (onUploadStart?: () => void, onUploadDone?: () => void) => {
  const { akord } = useGlobalContext();
  const { refreshStorage } = useStorageContext();
  const { vault } = useVaultContext();
  const {
    onUploadFiles,
    clearUploadEventRef,
    uploadEventRef,
    onUploadRetry,
    uploadRetry,
    notificationData,
    onNotificationData,
    onDuplicateFiles
  } = useNotificationsContext();

  const { onProgress } = useProgressContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const {
    perPageStacks,
    onUploadCancelHook,
    onDrawerActionType,
    onAxiosError,
    showLoaderByVaultId,
    onShowLoaderByVaultId,
    onFilesNumber,
    uploadedFilesNumber,
    onUploadedFilesNumber,
    onStackCreate,
    onStackUpdate,
    showVersionUpload,
    onVersionUpload,
    onFolderCreate,
    showZipUpload
  } = useAssetsContext();
  const { indexSearchData } = useSearchIndex();
  const history = useHistory();
  const folderId = getFolderId(history.location.pathname);

  const upload = async (files: File[], fileActionReference: React.MutableRefObject<FileWithAction[]>) => {
    onUploadedFilesNumber(0);
    onProgress(0);
    onShowLoaderByVaultId(vault.id);

    const newVersionFilesForIndexes = fileActionReference.current?.filter(file => file.action === "Replace");
    const skippedFilesForIndexes = fileActionReference.current?.filter(file => file.action === "Skip");
    // showVersionUpload indicates a single new version update through the context menu
    const filesAsNewVersion = showVersionUpload
      ? files
      : newVersionFilesForIndexes?.map(versionFile => {
          return files[versionFile.index || 0]; //TODO check why index is not coming through
        });

    let uploadedFilesCopy = [...files];
    const filesWithNewNames = showVersionUpload
      ? []
      : uploadedFilesCopy.filter(
          (_, index) =>
            !newVersionFilesForIndexes?.some(filter => filter.index === index) &&
            !skippedFilesForIndexes?.some(filter => filter.index === index)
        );

    onFilesNumber(filesAsNewVersion?.length || 0 + filesWithNewNames.length);

    if (filesAsNewVersion?.length) {
      await Promise.all(
        filesAsNewVersion.map(async version => {
          // showVersionUpload indicates a single new version upload with the file to update info coming from notificationData
          const stackData = perPageStacks.filter(stack =>
            showVersionUpload ? stack.id === notificationData.id : stack.name === version.name
          )[0];
          onDrawerActionType("upload_revision");
          await createStackRevision(stackData, version);
        })
      );
      onSnackbarToShow("versionUpload");
      onUploadFiles([]);
      if (uploadEventRef.current) {
        clearUploadEventRef();
      }
    }

    if (filesWithNewNames.length > 0 || uploadRetry) {
      onDrawerActionType("upload");
      showZipUpload ? await uploadZip(filesWithNewNames) : await createStacks(filesWithNewNames);
      onUploadFiles([]);
      if (uploadEventRef.current) {
        clearUploadEventRef();
      }
    }

    cleanup();
    fileActionReference.current = [];
  };

  const uploadZip = async (files: File[]) => {
    try {
      await akord?.zip.upload(vault.id, files[0]);
      onSnackbarToShow("zipUpload");
      cleanup();
    } catch (e: any) {
      onError({ errors: [{ name: files[0].name, message: e.message }] } as BatchStackCreateResponse);
      cleanup();
    }
  };

  const createStacks = async (files: File[]) => {
    if (!akord) return;
    const cancelHook = new AbortController();
    onUploadCancelHook(cancelHook);
    let stacks: StackCreateItem[];

    const fileTree = await processFiles(files);

    // Create Folders and MAP all the files to the folderId
    let fileList: FileWithAddOn[] = [];

    fileList = await processFileTree([fileTree], akord, folderId, fileList, vault.id, onFolderCreate);

    stacks = fileList.map(file => {
      const stack = {
        file: file,
        options: {
          parentId: file.folderId,
          name: getUniqueFileName(file.name),
          overrideFileName: false
        }
      };
      delete file.folderId;
      return stack as StackCreateItem;
    });

    const res = await akord?.batch.stackCreate(vault.id, stacks, {
      progressHook: progress => onProgress(progress),
      onStackCreated: stack => onStackCreated(stack),
      cancelHook: cancelHook,
      processingCountHook: count => onUploadedFilesNumber(count)
    });

    if (res?.data.length) {
      onSuccess(res.data);
    }
    if (res?.errors.length) {
      onError(res, fileList);
    }
    if (res?.cancelled) {
      onCancel(res.cancelled);
    }
    cleanup();
  };

  const createStackRevision = async (stackData: Stack, file: File) => {
    if (!Object.keys(stackData).length || !file) {
      throw new Error("No stack data or files.");
    }
    try {
      if (!akord) return;
      const { object } = await akord.stack.uploadRevision(stackData.id, file, { progressHook: progress => onProgress(progress) });
      onStackUpdate(object);
      onUploadedFilesNumber(uploadedFilesNumber + 1);
      // indicates that a new version was added from the context menu, set to false only after success
      showVersionUpload && onVersionUpload(false);
    } catch (e: any) {
      onError({ errors: [{ name: file.name, message: e.message }] } as BatchStackCreateResponse);
    }
  };

  const getUniqueFileName = (name: string) => {
    const allExistingNames = perPageStacks.map(stack => stack.name);
    if (allExistingNames.some(existingName => existingName === name)) {
      const startIndex = 1;
      return getUniqueName(name, allExistingNames, startIndex);
    } else {
      return name;
    }
  };

  const onStackCreated = async (stack: Stack) => {
    onStackCreate(stack);
  };

  const onSuccess = (data: BatchStackCreateResponse["data"]) => {
    onSnackbarToShow(vault.cloud ? "fileUploadCloud" : "fileUpload", data.length > 1 ? data.length : null);
  };

  const onError = (res: BatchStackCreateResponse, stacks?: FileWithAddOn[]) => {
    if (stacks) {
      const retryFiles = stacks?.filter(stack => res.errors.some(error => error.name === stack.name));
      onUploadRetry(true);
      onUploadFiles(retryFiles);
      onNotificationData({ uploaded: res.data?.length, allStacks: stacks?.length });
    }
    refreshStorage();
    // Throwing an error to catch it in the modal to send to support
    throw new Error(JSON.stringify(res.errors));
  };

  const onCancel = (cancelled: BatchStackCreateResponse["cancelled"]) => {
    onAxiosError(cancelled === 1 ? `${cancelled} file was cancelled` : `${cancelled} files were cancelled`);
    setTimeout(() => {
      onAxiosError();
    }, 3000);
    refreshStorage();
  };

  const cleanup = () => {
    if (showLoaderByVaultId.includes(vault.id)) onShowLoaderByVaultId(vault.id);
    onFilesNumber(0);
    onProgress(0);
    onUploadedFilesNumber(0);
    onDrawerActionType();
    onDuplicateFiles([]);
    //Index data for search
    indexSearchData();
  };

  return { upload };
};
