import { MintCollectionResponse } from "@akord/akord-js/lib/core/collection";
import { useGlobalContext } from "../contexts/GlobalDataProvider";
import { initialNftMetadata, udlInitialState, useNftContext } from "../contexts/NftContextProvider";
import { useProgressContext } from "../contexts/ProgressContext";

const useMint = (vaultId: string | null) => {
  const { akord } = useGlobalContext();
  const { nftMetadata, udl, ucm, onNFTMetadata, onUdl, onMintCollectionCancelHook } = useNftContext();
  const { onProgress } = useProgressContext();
  if (!vaultId) {
    throw new Error("Missing vault id.");
  }

  const mintSingle = async (file: File) => {
    if (!akord) {
      throw new Error("Akord-js not initialized.");
    }

    const { nftId }: { nftId: string } = await akord.nft.mint(vaultId, file, nftMetadata, {
      udl,
      ucm: ucm === "yes" ? true : false
    });
    onNFTMetadata(initialNftMetadata);
    onUdl(udlInitialState);
    return nftId;
  };

  const mintCollection = async (files: File[]) => {
    if (!akord) {
      throw new Error("Akord-js not initialized.");
    }
    const collectionFiles = files.map((file) => {
      return {
        asset: file,
        metadata: {
          name: file.name.substr(0, file.name.lastIndexOf(".")) || file.name
        }
      };
    });
    const cancelHook = new AbortController();
    onMintCollectionCancelHook(cancelHook);

    const response: MintCollectionResponse = await akord.collection.mint(vaultId, collectionFiles, nftMetadata, {
      progressHook: (progress) => onProgress(progress),
      cancelHook: cancelHook,
      // onItemCreated: nft => onMinted(nft),
      // processingCountHook: count => onMintedFilesNumber(count)
      udl,
      ucm: ucm === "yes" ? true : false
    });
    onNFTMetadata(initialNftMetadata);
    onUdl(udlInitialState);
    onProgress(0);
    return response;
  };

  return { mintSingle, mintCollection };
};

export default useMint;
