import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useAvatarMenuOptions, usePlusMenuOptions } from "./MenuOptions";
import NestedMenuItem from "./NestedMenuItem";
import { Menu, Theme } from "@mui/material";

const useStyles = makeStyles<Theme>(theme => ({
  paper: {
    backgroundColor: theme.palette.background.secondary,
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2)
  }
}));

type DropdownMenuProps = {
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  onClose: () => void;
  openMenu: boolean;
  type: "avatarMenu" | "plusMenu";
  position: number | "right" | "left" | "center";
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ anchorEl, onClose, openMenu, type, position }) => {
  const { darkMode, isMobile } = useGlobalContext();
  const { avatarOptions } = useAvatarMenuOptions();
  const { plusOptions } = usePlusMenuOptions();
  const classes = useStyles({ darkMode: darkMode, isMobile: isMobile });
  const menuItems = type === "avatarMenu" ? avatarOptions(onClose) : plusOptions(onClose);

  return (
    <>
      <Menu
        elevation={8}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: position
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: position
        }}
        open={openMenu}
        onClose={onClose}
        classes={{ paper: classes.paper }}
      >
        {menuItems.map(
          (menuItem, index) => !menuItem.hidden && <NestedMenuItem item={menuItem} childrenItems={menuItem.children} key={index} />
        )}
      </Menu>
    </>
  );
};
export default DropdownMenu;
