import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PublicVaultRoutes from "./RoutesPublicVault";
import ThemeWrapper from "../common/Wrappers/ThemeWrapper";

function PublicRoutes() {
  return (
    <ThemeWrapper darkMode={true}>
      <Switch>
        <Route path="/public/vaults" render={() => <PublicVaultRoutes />} />
        <Redirect to="/log-in" />
      </Switch>
    </ThemeWrapper>
  );
}

export default PublicRoutes;
