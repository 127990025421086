import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Box, Typography, FormControl, FormControlLabel, InputLabel, OutlinedInput } from "@mui/material";
import PasswordMeter from "../../components/common/PasswordMeter";
import AkordCheckbox from "../../components/common/CheckBox";
import ErrorAlert from "../../components/common/ErrorAlert";
import { akordLinks } from "../../helpers/akordTexts";
import { useSignupContext } from "../../contexts/SignupContext";

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  formCustom: {
    marginBottom: theme.spacing(7)
  },
  passwordMeter: {
    appearance: "none",
    width: "100%",
    height: "4px",
    marginTop: "7px",
    background: "#D5D2D1",
    "&::-webkit-progress-bar": {
      appearance: "none",
      background: "#D5D2D1"
    }
  }
}));

const withdrawalBlock = (
  <>
    <Typography component="span" variant="body2">
      By creating your Account, you agree to waive your{" "}
    </Typography>
    <Typography component="a" variant="body2" onClick={() => window.open(akordLinks["withdrawal"])}>
      withdrawal right
    </Typography>
    <Typography component="span" variant="body2">
      , if applicable.
    </Typography>
  </>
);

const termsBlock = (
  <>
    <Typography component="span" variant="body2">
      I have read and agree to the{" "}
    </Typography>
    <Typography component="a" variant="body2" onClick={() => window.open(akordLinks["terms"])}>
      Terms of Service
    </Typography>
    <Typography component="span" variant="body2">
      {" "}
      and{" "}
    </Typography>
    <Typography component="a" variant="body2" onClick={() => window.open(akordLinks["privacy"])}>
      Privacy Policy
    </Typography>
  </>
);

const SignUpPage: React.FC = () => {
  const [values, setValues] = useState({
    showPassword: true
  });
  const { handleFormChange, formData, passEvaluation, error, isDesktop } = useSignupContext();
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const getErrorText = (errorMessage: string) => {
    if (errorMessage.toLowerCase().includes("email")) return "This email address is already registered.";
    else return "Unknown error.";
  };

  return (
    <Box className={classes.root} sx={{ flexGrow: !isDesktop ? 1 : "unset" }}>
      <Box>
        <Typography variant="h1" sx={{ marginBottom: 3 }}>
          Sign up for Akord
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 7 }}>
          Get 100 MB of permanent storage and 1 GB of cloud storage free when you create your account.
        </Typography>
        <FormControl fullWidth>
          <FormControl error={!!error} variant="outlined" className={classes.formCustom}>
            <InputLabel htmlFor="enter-email">Email address</InputLabel>
            <OutlinedInput
              spellCheck="false"
              id="enter-email"
              name="userEmail"
              type="email"
              label="Email address"
              value={formData.userEmail}
              onChange={handleFormChange("userEmail")}
            />
            {error && <ErrorAlert getErrorText={getErrorText(error.message)} />}
          </FormControl>
          <PasswordMeter
            values={values}
            formData={formData}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            handleFormChange={handleFormChange}
            passEvaluation={passEvaluation}
          />
        </FormControl>
      </Box>
      <Box>
        <Box>
          <FormControl component="fieldset">
            <FormControlLabel
              style={{ alignItems: "normal" }}
              control={
                <AkordCheckbox
                  inputProps={{ "aria-label": "acceptTerms" }}
                  checked={formData.acceptTerms}
                  onChange={handleFormChange("acceptTerms")}
                  name="terms"
                />
              }
              label={termsBlock}
            />
          </FormControl>
        </Box>
        <Box mt={5}>
          <FormControl component="fieldset">
            <FormControlLabel
              style={{ alignItems: "normal" }}
              control={
                <AkordCheckbox
                  inputProps={{ "aria-label": "withdrawalRight" }}
                  checked={formData.withdrawalRight}
                  onChange={handleFormChange("withdrawalRight")}
                  name="terms"
                />
              }
              label={withdrawalBlock}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpPage;
