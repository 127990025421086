import React from "react";
import { Box, Typography } from "@mui/material";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { Stack, Folder } from "@akord/akord-js";

type SelectAllProps = {
  revoked: boolean;
  allItemsInVault: (Stack | Folder)[];
  isFolder: boolean;
};

const SelectAll: React.FC<SelectAllProps> = ({ revoked, allItemsInVault, isFolder }) => {
  const { onSelectedItems, selectedItemsMap } = useAssetsContext();

  return (
    <Box display="flex">
      <Typography variant="body2" color="text.secondary" style={{ whiteSpace: "pre" }}>
        {selectedItemsMap.size} items selected.{" "}
      </Typography>
      <Typography component="a" variant="body2" onClick={() => onSelectedItems("allItems", undefined, revoked)}>
        {selectedItemsMap.size < allItemsInVault.length ? (
          <>
            Select all
            <Typography component="span" variant="body2" className="strong" style={{ color: "inherit" }}>
              {" "}
              {allItemsInVault.length}{" "}
            </Typography>
            items in the {isFolder ? "folder" : "vault"}.
          </>
        ) : (
          "Clear selection."
        )}
      </Typography>
    </Box>
  );
};

export default SelectAll;
