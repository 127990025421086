import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

type BackupPhraseBoxProps = {
  backupPhraseArray: string[] | undefined;
  noMinHeight?: boolean;
};

type BackupPhraseBoxStylesProps = {
  noMinHeight: boolean;
};

const useStyles = makeStyles<Theme, BackupPhraseBoxStylesProps>(theme => ({
  container: {
    background: theme.palette.background.card,
    borderRadius: "4px",
    padding: theme.spacing(4),
    border: "1px solid",
    borderColor: theme.palette.background.tertiary,
    minHeight: ({ noMinHeight }) => (!noMinHeight ? "154px" : "inherit")
  },
  phraseWords: {
    lineHeight: 2.5
  }
}));

const BackupPhraseBox: React.FC<BackupPhraseBoxProps> = ({ backupPhraseArray, noMinHeight = false }) => {
  const classes = useStyles({ noMinHeight: noMinHeight });
  return (
    <Box className={classes.container}>
      <Typography variant="body1" align="center" className={classes.phraseWords} color="text.primary">
        {backupPhraseArray?.map((word, i: number) => (
          <span key={i}>
            <sup style={{ marginRight: "4px" }}>{i + 1}</sup>
            <Typography component="span" variant="h3" sx={{ marginRight: "12px" }}>
              {word}{" "}
            </Typography>
          </span>
        ))}
      </Typography>
    </Box>
  );
};

export default BackupPhraseBox;
