import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SafeWrapper } from "../common";
import ShowPublicStack from "../../pages/public/ShowPublicStack";
import AssetsContextProvider from "../../contexts/AssetsContextProvider";
import GlobalDataProvider from "../../contexts/GlobalDataProvider";
import NotificationsContextProvider from "../../contexts/NotificationsContextProvider";
import ProgressContextProvider from "../../contexts/ProgressContext";
import SnackbarContextProvider from "../../contexts/SnackbarContextProvider";
import VaultContextProvider from "../../contexts/VaultContextProvider";
import AssetsGallery from "../../pages/gallery/AssetsGallery";
import NftContextProvider from "../../contexts/NftContextProvider";
import NFTItemDetails from "../../pages/nft/NFTItemDetails";
import NFTCollectionList from "../../pages/nft/NFTCollectionList";
import NftCollectionContextProvider from "../../contexts/NftCollectionContextProvider";

const PublicVaultRoutes: React.FC = () => {
  return (
    <SnackbarContextProvider>
      <NotificationsContextProvider>
        <GlobalDataProvider>
          <VaultContextProvider>
            <ProgressContextProvider>
              <AssetsContextProvider>
                <NftContextProvider>
                  <NftCollectionContextProvider>
                    <SafeWrapper isPublicRoute>
                      <Switch>
                        <Route path="/public/vaults/:status/:vaultId/assets" render={() => <ShowPublicStack />} />
                        <Route path="/public/vaults/:status/:vaultId/gallery" exact render={() => <AssetsGallery isPublicRoute />} />
                        <Route
                          path="/public/vaults/:status/:vaultId/gallery/folders/:folderId"
                          exact
                          render={() => <AssetsGallery isPublicRoute />}
                        />
                        <Route
                          path="/public/vaults/:status/:vaultId/nfts/collection/:collectionId/:id"
                          exact
                          render={() => <NFTItemDetails />}
                        />
                        <Route
                          path="/public/vaults/:status/:vaultId/nfts/collection/:collectionId"
                          exact
                          render={() => <NFTCollectionList />}
                        />
                        <Route path="/public/vaults/:status/:vaultId/nfts/:nftId" exact render={() => <NFTItemDetails />} />
                        <Route path="/public/vaults/:status/:vaultId/nfts" exact render={() => <ShowPublicStack />} />
                        <Redirect to="/login" />
                      </Switch>
                    </SafeWrapper>
                  </NftCollectionContextProvider>
                </NftContextProvider>
              </AssetsContextProvider>
            </ProgressContextProvider>
          </VaultContextProvider>
        </GlobalDataProvider>
      </NotificationsContextProvider>
    </SnackbarContextProvider>
  );
};

export default PublicVaultRoutes;
