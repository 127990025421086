import React from "react";
import { Checkbox, CheckboxProps, styled } from "@mui/material";
import { CheckboxTickIcon, CheckboxEmptyIcon } from "@akord/addon-icons";

const AkordCheckbox = styled((props: CheckboxProps) => (
  <Checkbox icon={<CheckboxEmptyIcon />} checkedIcon={<CheckboxTickIcon />} color="default" disableRipple {...props} />
))(({ theme }) => ({
  alignItems: "normal",
  padding: "0 8px",
  marginTop: "-3px",
  "&.Mui-checked": {
    color: theme.palette.primary.main
  }
}));

export default AkordCheckbox;
