import { PenIcon, FolderOutlineIcon, LinkIcon, ImportIcon, UTokenIcon, InfoIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import useSearchIndex from "../../../hooks/useSearchIndex";
import React from "react";
import { Link, Typography } from "@mui/material";
import { claimUToken } from "../../../helpers/api-helpers";

import { FormModelType } from "../../../hooks/useModal";
import { useRewardsContext } from "../../../contexts/RewardsContext";

export type FormModalDataProps = {
  title: string;
  icon: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
  nextButton: string;
  placeholder?: string;
  action: () => Promise<void>;
  secondaryText?: string;
  isWide?: boolean;
  isDataValid?: (data: string) => boolean;
  validationErrorText?: string;
  helperJSXElement?: () => React.ReactElement;
  extraInput?: boolean;
  extraInputPlaceholder?: string;
  extraInputValidationErrorText?: string;
  isExtraDataValid?: (data: string) => boolean;
};

export const useFormModalOptions = () => {
  const { akord, onVaultUpdate } = useGlobalContext();
  const { notificationData } = useNotificationsContext();
  const { onFolderCreate, onFolderUpdate, onStackCreate, onStackUpdate } = useAssetsContext();
  const { onVaultUpdate: onVaultMembershipData } = useVaultContext();
  const { indexSearchData } = useSearchIndex();

  // if (!akord) return null;

  const options: { [Option in FormModelType]: FormModalDataProps } = {
    folderCreate: {
      title: "Create a folder",
      icon: FolderOutlineIcon,
      nextButton: "Create",
      placeholder: "Folder name",
      action: async () => {
        if (!akord || !notificationData.dataRoomId || !notificationData.title) return;
        const { object } = await akord.folder.create(notificationData.dataRoomId, notificationData.title, {
          parentId: notificationData.folderId
        });
        onFolderCreate(object);
        indexSearchData();
      }
    },
    dataRoomRename: {
      title: "Rename vault",
      icon: PenIcon,
      nextButton: "Rename",
      action: async () => {
        if (!akord || !notificationData.id || !notificationData.title) return;
        const { object } = await akord.vault.rename(notificationData.id, notificationData.title);
        onVaultUpdate(object); // Updates vault in Global Data Provider
        onVaultMembershipData(notificationData.id); // update vault in VaultContextProvider to get the updated name
        indexSearchData();
      }
    },
    fileRename: {
      title: "Rename file",
      icon: PenIcon,
      nextButton: "Rename",
      action: async () => {
        if (!akord || !notificationData.id || !notificationData.extension || !notificationData.title) return;
        const { object } = await akord.stack.rename(notificationData.id, notificationData.title.concat(".", notificationData.extension));
        onStackUpdate(object);
        indexSearchData();
      }
    },
    folderRename: {
      title: "Rename folder",
      icon: PenIcon,
      nextButton: "Rename",
      action: async () => {
        if (!akord || !notificationData.id || !notificationData.title) return;
        const { object } = await akord.folder.rename(notificationData.id, notificationData.title);
        onFolderUpdate(object);
        indexSearchData();
      }
    },
    shareFile: {
      icon: LinkIcon,
      title: "Share public file",
      secondaryText: "Anyone you share this link with will be able to view the file.",
      nextButton: "Copy link",
      action: async () => {
        if (notificationData.title) navigator.clipboard.writeText(notificationData.title);
      }
    },
    shareVault: {
      icon: LinkIcon,
      title: "Share public vault",
      secondaryText: "Anyone you share this link with will be able to view all files in the vault.",
      nextButton: "Copy link",
      action: async () => {
        if (notificationData.title) navigator.clipboard.writeText(notificationData.title);
      }
    },
    shareNft: {
      icon: LinkIcon,
      title: "Share Atomic NFT",
      secondaryText: "Anyone you share this Atomic NFT with will also be able to view other assets in the vault.",
      nextButton: "Copy link",
      action: async () => {
        if (notificationData.title) navigator.clipboard.writeText(notificationData.title);
      }
    },
    importArweave: {
      title: "Import from Arweave TX",
      icon: ImportIcon,
      nextButton: "Import",
      placeholder: "Arweave transaction",
      isWide: true,
      isDataValid: (data: string) => data.length === 43,
      action: async () => {
        if (!akord || !notificationData.dataRoomId || !notificationData.title) return;
        const { object } = await akord.stack.import(notificationData.dataRoomId, notificationData.title, {
          parentId: notificationData.folderId
        });
        onStackCreate(object);
        indexSearchData();
      }
    },
    sendToken: {
      title: "Send $U token",
      icon: UTokenIcon,
      nextButton: "Send $U",
      placeholder: "Arweave wallet address",
      secondaryText: "Please enter the Arweave wallet address you would like us to send your $U tokens to.",
      helperJSXElement: () => (
        <>
          <InfoIcon fontSize="small" color="disabled" sx={{ marginRight: 2 }} />
          <Typography variant="caption" color="text.tertiary" style={{ whiteSpace: "pre-line" }} noWrap>
            Don’t have an Arweave wallet?{" "}
            <Link variant="caption" color="text.tertiary" onClick={() => window.open("https://www.arconnect.io/download")}>
              Setup Arconnect.
            </Link>
          </Typography>
        </>
      ),
      isWide: false,
      isDataValid: (data: string) => /[a-z0-9_-]{43}/i.test(data),
      validationErrorText: "Incorrect format for an Arweave wallet address.",
      action: async () => {
        if (!notificationData.hash || !notificationData.title) return;
        const subUnitAmount = parseFloat(notificationData.hash) * Math.pow(10, 6);
        await claimUToken(notificationData.title, subUnitAmount);
        notificationData.loadUbalance && notificationData.loadUbalance();
      },
      extraInput: true,
      extraInputPlaceholder: "Enter amount",
      isExtraDataValid: (data: string) => {
        const uBalanceDecimal = notificationData.uBalance ? notificationData.uBalance / Math.pow(10, 6) : 0;
        return !!data && parseFloat(data) <= uBalanceDecimal;
      },
      extraInputValidationErrorText: "Amount exceeds total balance."
    }
  };

  return { options };
};
