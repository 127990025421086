import {
  RevokedFileIcon,
  AddNewVersionIcon,
  DownloadIcon,
  PenIcon,
  TrashIcon,
  FolderRenameOutlineIcon,
  MoveIcon,
  FolderRestoreIcon,
  FileRestoreIcon,
  MinusInCircleIcon,
  ViewTransactionIcon,
  LinkIcon,
  InfoIcon,
  NoteEditIcon,
  AddMemberIcon,
  TeamIcon,
  KeyIcon,
  TimelineIcon,
  PlayIcon,
  RestoreIcon,
  InactiveIcon,
  VaultLeaveIcon,
  CloseIcon,
  CloseInCircleIcon,
  TickIcon,
  ListIcon,
  PasswordMaskIcon
} from "@akord/addon-icons";
import { Auth, Folder, Membership, Stack, Vault } from "@akord/akord-js";
import { useHistory, useLocation } from "react-router-dom";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useInAppNotificationsContext } from "../../../contexts/InAppNotificationsContext";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { akordLinks } from "../../../helpers/akordTexts";
import { getVaultStatus, getResourceUri } from "../../../helpers/helpers";
import { useDownloader } from "../../file/FileDownloader";
import { Types } from "@akord/gql";
import { Note } from "@akord/akord-js/lib/types/note";
import { Props } from "@akord/addon-icons/lib/components/Icon";

export type MenuOptionsType = {
  text: string;
  altText?: string;
  icon: (props: Props) => React.ReactElement<any, any>;
  action: () => void;
  disabled: boolean;
  show: boolean;
  upload: boolean;
  disabledTooltipText?: string;
  tooltipText?: string;
};

const { host, protocol } = window.location;
const baseUrl = `${protocol}//${host}`;

export const useStackMenuOptions = () => {
  const { onShowInfo, readOnlyMode, onShowDescriptionDrawer } = useGlobalContext();
  const { modal, onNotificationData, notificationData } = useNotificationsContext();
  const { vault, currentMembership } = useVaultContext();
  const { folders, onShowUpload, onVersionUpload } = useAssetsContext();
  const { onSaveAs } = useDownloader({ useLoader: true });
  const history = useHistory();
  const location = useLocation();
  const isPublicRoute = !!location.pathname.match("/public/");

  const options = (stack: Stack | undefined, onClose: () => void) => {
    if (stack?.status === "ACTIVE") {
      return [
        {
          text: "Download",
          altText: "Download latest version",
          icon: DownloadIcon,
          action: () => {
            onSaveAs(stack.id);
            onClose();
          },
          disabled: false,
          show: true,
          upload: false
        },
        {
          text: "Add new version",
          icon: AddNewVersionIcon,
          action: () => {
            onNotificationData({ ...notificationData, id: stack.id });
            onVersionUpload(true);
            onShowUpload(true);
            onClose && onClose();
          },
          disabled:
            currentMembership?.role === "VIEWER" ||
            vault?.status === "ARCHIVED" ||
            isPublicRoute ||
            stack?.name === "manifest.json" ||
            readOnlyMode,
          show: !/markdown/.test(stack?.versions && stack?.versions[0]?.type),
          upload: true
        },
        {
          text: "Edit note",
          icon: NoteEditIcon,
          action: () => {
            history.push(`/vaults/${vault?.status.toLowerCase()}/${vault.id}/assets/note/${stack.id}`);
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: /markdown/.test(stack?.versions && stack?.versions[0]?.type),
          upload: true
        },
        {
          text: "Rename",
          icon: PenIcon,
          action: () => {
            modal.onConfirmModalFormType("fileRename");
            modal.onModalVisibility(null, "fileRename");
            onNotificationData({
              ...notificationData,
              title: stack.name.split(".").slice(0, -1).join("."),
              extension: stack.name.split(".").slice(-1)[0],
              fileType: stack.versions[0].fileType,
              id: stack.id
            });
            onClose();
          },
          disabled:
            currentMembership?.role === "VIEWER" ||
            vault?.status === "ARCHIVED" ||
            isPublicRoute ||
            stack.name === "manifest.json" ||
            readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: "Move",
          icon: MoveIcon,
          action: () => {
            modal.onConfirmModalType("fileMove");
            modal.onModalVisibility(null, "fileMove");
            onNotificationData({ ...notificationData, id: stack.id });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: folders?.length > 0,
          upload: false
        },
        {
          text: "Hide",
          icon: PasswordMaskIcon,
          action: () => {
            modal.onConfirmModalType(stack.versions.length > 1 ? "revokeStack" : "revokeFile");
            modal.onModalVisibility(null, stack.versions.length > 1 ? "revokeStack" : "revokeFile");
            onNotificationData({
              ...notificationData,
              id: stack.id,
              title: stack.name.split(".").slice(0, -1).join("."),
              extension: stack.name.split(".").slice(-1)[0],
              fileType: stack.versions[0]?.fileType
            });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: "File info",
          icon: InfoIcon,
          action: () => {
            onNotificationData({ ...notificationData, stack: stack, type: "stack" });
            onShowInfo(true);
            onClose();
          },
          show: true,
          upload: false
        },
        {
          text: "Description and tags",
          icon: ListIcon,
          action: () => {
            onNotificationData({ ...notificationData, stack: stack, type: "stack" });
            onShowDescriptionDrawer(true);
            onClose();
          },
          disabled: true,
          show: false,
          upload: false
        },
        {
          text: "Share",
          icon: LinkIcon,
          action: () => {
            const shareLink = stack.parentId
              ? `${baseUrl}/public/vaults/active/${vault.id}/gallery/folders/${stack.parentId}#${getResourceUri(
                  stack?.versions[stack?.versions.length - 1].resourceUri,
                  "s3"
                )}`
              : `${baseUrl}/public/vaults/active/${vault.id}/gallery#${getResourceUri(
                  stack?.versions[stack?.versions.length - 1].resourceUri,
                  "s3"
                )}`;
            modal.onConfirmModalFormType("shareFile");
            modal.onModalVisibility(null, "shareFile");
            onNotificationData({ ...notificationData, title: shareLink });
            onClose();
          },
          disabled: vault?.status === "ARCHIVED" || isPublicRoute,
          show: vault?.public,
          upload: false
        }
      ] as MenuOptionsType[];
    } else {
      return [
        {
          text: "Restore",
          icon: FileRestoreIcon,
          action: () => {
            modal.onConfirmModalType(
              stack?.parentId ? "restoreFileWithMove" : stack?.versions?.length || 0 > 1 ? "restoreStack" : "restoreFile"
            );
            modal.onModalVisibility(
              null,
              stack?.parentId ? "restoreFileWithMove" : stack?.versions?.length || 0 > 1 ? "restoreStack" : "restoreFile"
            );
            onNotificationData({
              ...notificationData,
              folderId: stack?.parentId ? stack.parentId : null,
              id: stack?.id,
              title: stack?.name.split(".").slice(0, -1).join("."),
              extension: stack?.name.split(".").slice(-1)[0],
              fileType: stack?.versions[0].type
            });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: vault?.cloud ? "Delete" : "Clear",
          icon: vault?.cloud ? TrashIcon : MinusInCircleIcon,
          action: () => {
            const confirmationType = vault?.cloud ? "deleteFile" : "removeFile";
            modal.onConfirmModalType(confirmationType);
            modal.onModalVisibility(null, confirmationType);
            onNotificationData({
              ...notificationData,
              id: stack?.id,
              title: stack?.name.split(".").slice(0, -1).join("."),
              extension: stack?.name.split(".").slice(-1)[0],
              fileType: stack?.versions[0].type,
              cloud: vault?.cloud || false
            });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: true,
          upload: false
        }
      ] as MenuOptionsType[];
    }
  };
  return { options };
};

export const useFolderMenuOptions = () => {
  const { onShowInfo, readOnlyMode } = useGlobalContext();
  const { modal, onNotificationData, notificationData } = useNotificationsContext();
  const { vault, currentMembership } = useVaultContext();
  const isPublicRoute = !!location.pathname.match("/public/");

  const options = (folder: Folder, onClose: () => void) => {
    if (folder?.status === "ACTIVE") {
      return [
        {
          text: "Rename",
          icon: FolderRenameOutlineIcon,
          action: () => {
            modal.onConfirmModalFormType("folderRename");
            modal.onModalVisibility(null, "folderRename");
            onNotificationData({ ...notificationData, title: folder.name, id: folder.id });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: "Move",
          icon: MoveIcon,
          action: () => {
            modal.onConfirmModalType("folderMove");
            modal.onModalVisibility(null, "folderMove");
            onNotificationData({ ...notificationData, id: folder.id, title: folder.name });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: "Hide",
          icon: PasswordMaskIcon,
          action: () => {
            modal.onConfirmModalType("folderRevoke");
            modal.onModalVisibility(null, "folderRevoke");
            onNotificationData({ ...notificationData, title: folder.name, id: folder.id });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: "Folder info",
          icon: InfoIcon,
          action: () => {
            onNotificationData({ ...notificationData, folder: folder, type: "folder" });
            onShowInfo(true);
            onClose();
          },
          disabled: false,
          show: true,
          upload: false
        }
      ];
    } else {
      return [
        {
          text: "Restore folder",
          icon: FolderRestoreIcon,
          action: () => {
            modal.onConfirmModalType(folder.parentId ? "restoreFolderWithMove" : "folderRestore");
            modal.onModalVisibility(null, folder.parentId ? "restoreFolderWithMove" : "folderRestore");
            onNotificationData({ ...notificationData, title: folder.name, id: folder.id });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: "Delete folder",
          icon: TrashIcon,
          action: () => {
            modal.onConfirmModalType("folderDelete");
            modal.onModalVisibility(null, "folderDelete");
            onNotificationData({ ...notificationData, title: folder.name, id: folder.id });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || isPublicRoute || readOnlyMode,
          show: true,
          upload: false
        }
      ] as MenuOptionsType[];
    }
  };
  return { options };
};

export const useNoteMenuOptions = () => {
  const { readOnlyMode } = useGlobalContext();
  const { modal, onNotificationData, notificationData } = useNotificationsContext();
  const { vault, currentMembership } = useVaultContext();
  const { folders } = useAssetsContext();
  const history = useHistory();

  const options = (note: Note, onClose: () => void) => {
    if (note?.status === "ACTIVE") {
      return [
        {
          text: "View transaction",
          icon: ViewTransactionIcon,
          action: () => {
            const refId = note.storageTransactions?.items?.find((tr: any) => tr.refHash === note.hash)?.refId;
            window.open(`${akordLinks.transactionBaseUrl}/${refId}`, "_blank");
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED",
          show: true,
          upload: false
        },
        {
          text: "Edit note",
          icon: NoteEditIcon,
          action: () => {
            history.push(`/vaults/${vault?.status}/${vault?.id}/assets/note/${note.id}`);
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || readOnlyMode,
          show: true,
          upload: true
        },
        {
          text: "Revoke",
          icon: RevokedFileIcon,
          action: () => {
            modal.onConfirmModalType("revokeNote");
            modal.onModalVisibility(null, "revokeNote");
            onNotificationData({ ...notificationData, id: note.id, title: note.name });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: "Move",
          icon: MoveIcon,
          action: () => {
            modal.onConfirmModalType("noteMove");
            modal.onModalVisibility(null, "noteMove");
            onNotificationData({ ...notificationData, id: note.id });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || readOnlyMode,
          show: folders?.length > 0,
          upload: false
        },
        {
          text: "Share",
          icon: LinkIcon,
          action: () => {
            modal.onConfirmModalFormType("shareFile");
            modal.onModalVisibility(null, "shareFile");
            onNotificationData({
              ...notificationData,
              title: `${baseUrl}/public/${note?.id}/${getResourceUri(note?.versions[note?.versions.length - 1].resourceUri, "arweave")}`
            });
            onClose();
          },
          disabled: vault?.status === "ARCHIVED",
          show: vault?.public,
          upload: false
        }
      ] as MenuOptionsType[];
    } else {
      return [
        {
          text: "Restore",
          icon: FileRestoreIcon,
          action: () => {
            modal.onConfirmModalType("restoreNote");
            modal.onModalVisibility(null, "restoreNote");
            onNotificationData({ ...notificationData, id: note.id, title: note.name });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || readOnlyMode,
          show: true,
          upload: false
        },
        {
          text: "Clear",
          icon: MinusInCircleIcon,
          action: () => {
            modal.onConfirmModalType("removeNote");
            modal.onModalVisibility(null, "removeNote");
            onNotificationData({ ...notificationData, title: note.name, id: note.id });
            onClose();
          },
          disabled: currentMembership?.role === "VIEWER" || vault?.status === "ARCHIVED" || readOnlyMode,
          show: true,
          upload: false
        }
      ] as MenuOptionsType[];
    }
  };
  return { options };
};

export const useVaultMenuOptions = () => {
  const { onUserAttributes, onShowInfo, readOnlyMode, onShowDescriptionDrawer } = useGlobalContext();
  const { modal, onNotificationData, notificationData } = useNotificationsContext();
  const history = useHistory();
  const isPublicRoute = !!location.pathname.match("/public/");

  const options = (vault: Vault, currentMembership: Membership, members: Membership[], onClose: () => void) => {
    // if (!(vault instanceof Vault)) return [];
    return [
      {
        text: "Invite",
        icon: AddMemberIcon,
        action: () =>
          history.push({
            pathname: `/vaults/${getVaultStatus(history.location.pathname)}/${vault.id}/invite-to-vault`,
            state: vault
          }),
        disabled: currentMembership?.role !== "OWNER" || readOnlyMode,
        show: vault?.status !== "ARCHIVED"
      },
      {
        text: currentMembership?.role !== "OWNER" || vault?.status === "ARCHIVED" ? "View members" : "Manage access",
        icon: currentMembership?.role !== "OWNER" || vault?.status === "ARCHIVED" ? TeamIcon : KeyIcon,
        action: () =>
          history.push({
            pathname: `/vaults/${getVaultStatus(history.location.pathname)}/${vault.id}/manage-access`,
            state: vault
          }),
        show: true,
        disabled: isPublicRoute || readOnlyMode
      },
      {
        text: "Rename vault",
        icon: PenIcon,
        action: () => {
          modal.onConfirmModalFormType("dataRoomRename");
          modal.onModalVisibility(null, "dataRoomRename");
          onNotificationData({ ...notificationData, title: vault.name, id: vault.id });
          onClose();
        },
        disabled: currentMembership?.role !== "OWNER" || readOnlyMode,
        show: vault?.status !== "ARCHIVED"
      },
      {
        text: "View hidden files",
        icon: PasswordMaskIcon,
        action: () =>
          history.push({
            pathname: `/vaults/${getVaultStatus(history.location.pathname)}/${vault.id}/revoked-files`,
            state: vault
          }),
        show: true,
        disabled: isPublicRoute
      },
      {
        text: "Share vault",
        icon: LinkIcon,
        action: () => {
          modal.onConfirmModalFormType("shareVault");
          modal.onModalVisibility(null, "shareVault");
          onNotificationData({ ...notificationData, title: `${baseUrl}/public/vaults/${vault.status.toLowerCase()}/${vault.id}/assets` });
          onClose();
        },
        show: vault?.public,
        disabled: isPublicRoute || vault?.status === "ARCHIVED"
      },
      {
        text: "Description and tags",
        icon: ListIcon,
        action: () => {
          onNotificationData({ ...notificationData, vault: vault, membership: currentMembership, type: "vault" });
          onShowDescriptionDrawer(true);
          onClose();
        },
        show: true
      },
      {
        text: "Timeline",
        icon: TimelineIcon,
        action: () =>
          history.push({
            pathname: `/vaults/${getVaultStatus(history.location.pathname)}/${vault.id}/timeline`,
            state: vault
          }),
        show: false,
        disabled: isPublicRoute
      },
      {
        text: "Vault info",
        icon: InfoIcon,
        action: () => {
          onNotificationData({ ...notificationData, vault: vault, membership: currentMembership, type: "vault" });
          onShowInfo(true);
          onClose();
        },
        show: true
      },
      {
        text: "Onboarding tour",
        icon: PlayIcon,
        action: async () => {
          onClose();
          onUserAttributes({ userOnboarding: true });
          history.push({
            pathname: `/vaults/${getVaultStatus(history.location.pathname)}/${vault.id}/assets`
          });
          await Auth.updateUserAttribute("custom:onboarding", "true");
        },
        show: true,
        disabled: isPublicRoute
      },
      {
        text: vault?.status === "ARCHIVED" ? "Restore vault" : "Deactivate vault",
        icon: vault?.status === "ARCHIVED" ? RestoreIcon : InactiveIcon,
        action: () => {
          modal.onConfirmModalType(vault?.status === "ARCHIVED" ? "restore" : "archive");
          modal.onModalVisibility(null, vault?.status === "ARCHIVED" ? "restore" : "archive");
          onNotificationData({ ...notificationData, title: vault.name, id: vault.id, cloud: !!vault.cloud });
          onClose();
        },
        disabled: currentMembership?.role !== "OWNER" || readOnlyMode,
        show: currentMembership?.role === "OWNER"
      },
      {
        text: "Remove vault",
        icon: MinusInCircleIcon,
        action: () => {
          modal.onConfirmModalType("delete");
          modal.onModalVisibility(null, "delete");
          onNotificationData({ ...notificationData, title: vault.name, id: vault.id, cloud: !!vault.cloud });
          onClose();
        },
        disabled: currentMembership?.role !== "OWNER" || readOnlyMode,
        show: vault?.status === "ARCHIVED" && currentMembership?.role === "OWNER"
      },
      {
        text: "Leave vault",
        icon: VaultLeaveIcon,
        action: () => {
          modal.onConfirmModalType("leave");
          modal.onModalVisibility(null, "leave");
          onNotificationData({ ...notificationData, title: vault.name, id: currentMembership?.id });
          onClose();
        },
        show: members?.length > 0 && members.some(member => member.id !== currentMembership?.id && member.role === "OWNER"),
        disabled: isPublicRoute || readOnlyMode
      }
    ] as MenuOptionsType[];
  };
  return { options };
};

export const useNotificationMenuOptions = () => {
  const { readOnlyMode } = useGlobalContext();
  const { onRead, onDelete } = useInAppNotificationsContext();

  const options = (onClose: () => void) => {
    return [
      {
        text: "Mark all as read",
        icon: TickIcon,
        action: async () => {
          onClose();
          onRead({});
        },
        disabled: readOnlyMode,
        show: true,
        upload: false
      },
      {
        text: "Clear all read notifications",
        icon: CloseInCircleIcon,
        action: async () => {
          onClose();
          onDelete({ status: Types.NotificationStatus.READ });
        },
        disabled: readOnlyMode,
        show: true,
        upload: false
      },
      {
        text: "Clear all notifications",
        icon: CloseIcon,
        action: async () => {
          onClose();
          onDelete({});
        },
        disabled: readOnlyMode,
        show: true,
        upload: false
      }
    ] as MenuOptionsType[];
  };
  return { options };
};
