import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, FormControl, FormHelperText, OutlinedInput, Typography } from "@mui/material";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import InfoModal, { InfoModalTypes } from "../../components/common/InfoModal/InfoModal";
import StyledSelect from "../../components/common/SelectMenu/StyledSelect";
import StyledMenuItem from "../../components/common/SelectMenu/StyledMenuItem";
import { useNftContext } from "../../contexts/NftContextProvider";
import InfoWarningBox from "../../components/common/InfoWarningBox/InfoWarningBox";
import { CommercialUse, Derivation, Duration, LicenseFee, UDL } from "@akord/akord-js";
import { customUdlMenuData } from "./custom-udl-menu-data";
import TitleWithSubtitleBlock from "../../components/common/TitleWithSubtitleBlock/TitleWithSubtitleBlock";
import OutlinedInputWithErrorAndDescription from "../../components/common/OutlinedInputWithErrorAndDescription/OutlinedInputWithErrorAndDescription";
import { getVaultId, getMintType } from "../../helpers/helpers";
import { isValidArweaveAddress } from "./nft-helpers";

const udlVariableKeys: Array<"derivations" | "commercialUses" | "dataModelTrainings"> = [
  "derivations",
  "commercialUses",
  "dataModelTrainings"
];

const CustomizeUDLForm: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { isMobile } = useGlobalContext();
  const { onUdl, udl } = useNftContext();

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [udlFormError, setUdlFormError] = useState(false);
  const [infoModalType, setInfoModalType] = useState<InfoModalTypes>();

  const errorFields = React.useRef<Array<string>>([]);

  const handleInfoModalOpen = (boxType: InfoModalTypes) => {
    setOpenInfoModal(true);
    setInfoModalType(boxType);
  };

  const handleInfoModalClose = () => {
    setOpenInfoModal(false);
    setInfoModalType(undefined);
  };

  const vaultId = getVaultId(location.pathname);
  const mintType = getMintType(location.pathname);

  const handleFormChange = (prop: keyof UDL) => (event: any) => {
    const { target } = event;
    // prepare input
    let value: any = target.value;
    const keys: (keyof Derivation | keyof LicenseFee)[] = target.name.split(".");
    let dataToAdd;

    if (target.name.includes("value")) value = value ? parseInt(value) : undefined;

    // proccess input
    if (prop === "licenseFee") dataToAdd = value ? { ...(udl?.[prop] as UDL), [keys[0]]: value, type: "One-Time" } : undefined;
    else if (prop === "derivations" || prop === "commercialUses" || prop === "dataModelTrainings") {
      // reset errors if a change was made
      udlFormError && setUdlFormError(false);
      errorFields.current.length > 0 && (errorFields.current = []);

      const el: Derivation | CommercialUse | undefined = udl?.[prop]?.[0]; // ideally need to add DataModelTraining, but its missing `value`
      dataToAdd =
        keys.length > 1
          ? [{ ...el, [keys[0]]: { ...(el?.[keys[0]] as LicenseFee | Duration), [keys[1]]: value } }]
          : value === "Allowed-With-Fee"
          ? [{ ...el, [keys[0]]: value, fee: { type: "One-Time", value: "None" }, value: undefined }]
          : value === "Allowed-With-RevenueShare"
          ? [{ ...el, [keys[0]]: value, value: "None" }]
          : value === "None"
          ? undefined
          : typeof value === "number"
          ? [{ ...el, [keys[0]]: value }]
          : [{ [keys[0]]: value }];
    } else {
      dataToAdd = value === "None" ? undefined : value;
    }
    // assign input
    onUdl({
      ...udl,
      [prop]: dataToAdd
    });
  };

  const redirect = () => {
    const isUdlValid: boolean = isDataValid();
    isUdlValid ? history.push(`/vaults/active/${vaultId}/nfts/mint/${mintType}/ucm`) : setUdlFormError(true);
  };

  const isDataValid = () => {
    let passed = true;
    for (const key of udlVariableKeys) {
      const el: Derivation | CommercialUse | undefined = udl?.[key]?.[0];
      if (el?.type === "Allowed-With-Fee") {
        if (typeof el.fee?.value === "number" && el.fee?.value > 0) passed = true;
        else {
          const erroredField = (key as string) + ".fee.value";
          errorFields.current = [...errorFields.current, erroredField];
          passed = false;
        }
      }
      if (el?.type === "Allowed-With-RevenueShare") {
        if (typeof el.value === "number" && el.value > 0) passed = true;
        else {
          const erroredField = (key as string) + ".value";
          errorFields.current = [...errorFields.current, erroredField];
          passed = false;
        }
      }
    }
    return passed;
  };

  return (
    <SupportScreenWrapper title="Customize UDL" hideChevron fullHeight>
      <Box height="auto" display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"} mt={6}>
        <Box>
          <InfoWarningBox text="You cannot edit the terms of the UDL once the Atomic NFT is minted. Please consider your choices carefully." />

          <Box mb={7}>
            <TitleWithSubtitleBlock
              boxTitle="Access"
              boxSubtitle="Charge a one-time fee for access to your data."
              boxType="accessFee" //licenseFee
              handleModal={handleInfoModalOpen}
            />
            <FormControl variant="outlined" sx={{ width: "140px" }}>
              <OutlinedInput
                defaultValue={udl!.licenseFee}
                id="licenseFee"
                name="value"
                type="number"
                placeholder="Fee value"
                onChange={handleFormChange("licenseFee")}
              />
            </FormControl>
          </Box>

          <Box mb={7}>
            <TitleWithSubtitleBlock
              boxTitle="Derivations"
              boxSubtitle="Grant the right to create derivative works."
              boxType="derivations"
              handleModal={handleInfoModalOpen}
            />
            <FormControl variant="outlined" fullWidth>
              <StyledSelect
                fieldName="type"
                id="derivations"
                value={udl?.derivations?.[0]?.type || "None"}
                onChange={handleFormChange("derivations")}
              >
                {customUdlMenuData["derivations"].map((item, index) => (
                  <StyledMenuItem key={index} value={item.value} text={item.text}>
                    {item.text}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            {udl?.derivations?.[0]?.type === "Allowed-With-RevenueShare" && (
              <OutlinedInputWithErrorAndDescription
                udlField="derivations"
                subFields="value"
                errorFields={errorFields}
                onFormChange={handleFormChange}
                defaultValue={udl.derivations?.[0]?.value}
                percentageInput
              />
            )}
            {udl?.derivations?.[0]?.type === "Allowed-With-Fee" && (
              <>
                <Box mt={5}>
                  <Typography variant="body2" className="small" sx={{ mb: 3 }}>
                    Specify a monthly or one-time fee for use of the content.
                  </Typography>
                  <FormControl variant="outlined" sx={{ width: "140px" }}>
                    <StyledSelect
                      fieldName="fee.type"
                      id="derivation-license-fee"
                      value={udl?.derivations?.[0]?.fee?.type || "One-Time"}
                      onChange={handleFormChange("derivations")}
                    >
                      {customUdlMenuData["duration"].map((item, index) => (
                        <StyledMenuItem key={index} value={item.value} text={item.text}>
                          {item.text}
                        </StyledMenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <OutlinedInputWithErrorAndDescription
                  udlField="derivations"
                  subFields="fee.value"
                  errorFields={errorFields}
                  onFormChange={handleFormChange}
                  defaultValue={udl.derivations?.[0]?.fee?.value}
                />
              </>
            )}
          </Box>

          <Box mb={7}>
            <TitleWithSubtitleBlock
              boxTitle="Unknown rights"
              boxSubtitle="Unknown rights will cover usage for future uses that aren’t covered. "
              boxType="unknownUsageRights"
              handleModal={handleInfoModalOpen}
            />
            <FormControl variant="outlined" fullWidth>
              <StyledSelect
                fieldName="unknownUsageRights"
                id="unknownUsageRights"
                value={udl?.unknownUsageRights || "None"}
                onChange={handleFormChange("unknownUsageRights")}
              >
                {customUdlMenuData["unknownUsageRights"].map((item, index) => (
                  <StyledMenuItem key={index} value={item.value} text={item.text}>
                    {item.text}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Box>

          <Box mb={7}>
            <TitleWithSubtitleBlock
              boxTitle="Commercial use"
              boxSubtitle="Grant the right to use the content for commercial purposes."
              boxType="commercialUse"
              handleModal={handleInfoModalOpen}
            />
            <FormControl variant="outlined" fullWidth>
              <StyledSelect
                fieldName="type"
                id="commercialUses"
                value={udl?.commercialUses?.[0]?.type || "None"}
                onChange={handleFormChange("commercialUses")}
              >
                {customUdlMenuData["commercialUses"].map((item, index) => (
                  <StyledMenuItem key={index} value={item.value} text={item.text}>
                    {item.text}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            {udl?.commercialUses?.[0]?.type === "Allowed-With-RevenueShare" && (
              <OutlinedInputWithErrorAndDescription
                udlField="commercialUses"
                subFields="value"
                errorFields={errorFields}
                onFormChange={handleFormChange}
                defaultValue={udl.commercialUses?.[0]?.value}
                percentageInput
              />
            )}
            {udl?.commercialUses?.[0]?.type === "Allowed-With-Fee" && (
              <>
                <Box mt={5}>
                  <Typography variant="body2" className="small" sx={{ mb: 3 }}>
                    Specify a monthly or one-time fee for use of the content.
                  </Typography>
                  <FormControl variant="outlined" sx={{ width: "140px" }}>
                    <StyledSelect
                      fieldName="fee.type"
                      id="commercial-use-license-fee"
                      value={udl?.commercialUses?.[0]?.fee?.type || "One-Time"}
                      onChange={handleFormChange("commercialUses")}
                    >
                      {customUdlMenuData["duration"].map((item, index) => (
                        <StyledMenuItem key={index} value={item.value} text={item.text}>
                          {item.text}
                        </StyledMenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <OutlinedInputWithErrorAndDescription
                  udlField="commercialUses"
                  subFields="fee.value"
                  errorFields={errorFields}
                  onFormChange={handleFormChange}
                  defaultValue={udl.commercialUses?.[0]?.fee?.value}
                />
              </>
            )}
          </Box>

          <Box mb={7}>
            <TitleWithSubtitleBlock
              boxTitle="Data Model Training"
              boxSubtitle="Specify whether your data can be used for data model training."
              boxType="dataModelTrainings"
              handleModal={handleInfoModalOpen}
            />
            <FormControl variant="outlined" fullWidth>
              <StyledSelect
                fieldName="type"
                id="dataModelTrainings"
                value={udl?.dataModelTrainings?.[0]?.type || "None"}
                onChange={handleFormChange("dataModelTrainings")}
              >
                {customUdlMenuData["dataModelTrainings"].map((item, index) => (
                  <StyledMenuItem key={index} value={item.value} text={item.text}>
                    {item.text}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            {udl?.dataModelTrainings?.[0]?.type === "Allowed-With-Fee" && (
              <>
                <Box mt={5}>
                  <Typography variant="body2" className="small" sx={{ mb: 3 }}>
                    Specify a monthly or one-time fee for use of the content.
                  </Typography>
                  <FormControl variant="outlined" sx={{ width: "140px" }}>
                    <StyledSelect
                      fieldName="fee.type"
                      id="dataModelTrainings-fee"
                      value={udl?.dataModelTrainings?.[0]?.fee?.type || "One-Time"}
                      onChange={handleFormChange("dataModelTrainings")}
                    >
                      {customUdlMenuData["duration"].map((item, index) => (
                        <StyledMenuItem key={index} value={item.value} text={item.text}>
                          {item.text}
                        </StyledMenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <OutlinedInputWithErrorAndDescription
                  udlField="dataModelTrainings"
                  subFields="fee.value"
                  errorFields={errorFields}
                  onFormChange={handleFormChange}
                  defaultValue={udl.dataModelTrainings?.[0]?.fee?.value}
                />
              </>
            )}
          </Box>

          <Box mb={7}>
            <TitleWithSubtitleBlock
              boxTitle="Currency"
              boxSubtitle="Specify the denomination or type of currency for payments."
              boxType="currency"
              handleModal={handleInfoModalOpen}
            />
            <FormControl variant="outlined" sx={{ width: "140px" }}>
              <OutlinedInput
                defaultValue={udl?.currency}
                id="currency"
                name="currency"
                type="text"
                placeholder="Currency"
                onChange={handleFormChange("currency")}
              />
            </FormControl>
          </Box>

          <Box mb={7}>
            <TitleWithSubtitleBlock
              boxTitle="Duration"
              boxSubtitle="Specify how many years the terms are valid for. The default is set indefinitely."
              boxType="duration"
              handleModal={handleInfoModalOpen}
            />
            <FormControl variant="outlined" sx={{ width: "140px" }}>
              <OutlinedInput
                defaultValue={udl?.expires}
                id="expires"
                name="expires"
                type="number"
                placeholder="Duration"
                onChange={handleFormChange("expires")}
              />
            </FormControl>
          </Box>

          <Box mb={7}>
            <TitleWithSubtitleBlock
              boxTitle="Payment address"
              boxSubtitle="Specify the Arweave wallet address that will receive payment."
              boxType="paymentAddress"
              handleModal={handleInfoModalOpen}
            />
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                defaultValue={udl?.paymentAddress}
                id="paymentAddress"
                name="paymentAddress"
                type="text"
                placeholder="Payment Address"
                onChange={handleFormChange("paymentAddress")}
                error={!!udl?.paymentAddress && !isValidArweaveAddress(udl?.paymentAddress)}
              />
              {udl?.paymentAddress && !isValidArweaveAddress(udl?.paymentAddress) && (
                <FormHelperText sx={{ margin: "8px 0 0" }}>
                  <Typography variant="caption" color="error.main">
                    This must match the format for an Arweave wallet address.
                  </Typography>
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
        <ButtonGroup
          error={udlFormError}
          handleEnd={() => redirect()}
          handleBack={() => history.goBack()}
          hideIcon
          nextText="Next"
          nextDiasabled={udlFormError || (!!udl?.paymentAddress && !isValidArweaveAddress(udl?.paymentAddress))}
        />
      </Box>
      <InfoModal openInfoModal={openInfoModal} infoModalType={infoModalType!} handleInfoModalClose={handleInfoModalClose} />
    </SupportScreenWrapper>
  );
};

export default withRouter(CustomizeUDLForm);
