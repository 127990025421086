import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React, { useEffect, useState } from "react";
import { SupportScreenWrapper } from "../../components/common";
import { getStorageProducts } from "../../helpers/api-helpers";
import OneTimeTopUpCardForm from "../../components/payment/OneTimeTopUpCardForm";
import CurrencySelect from "../../components/payment/CurrencySelect";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { SubscriptionPlan } from "../../types/stripeTypes";
import { Stripe } from "@stripe/stripe-js";

const OneTimeTopUp: React.FC = () => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [product, setProduct] = useState<SubscriptionPlan>();
  const { currencyCode } = usePaymentContext();

  useEffect(() => {
    async function initializeTopUp() {
      const productsData = await getStorageProducts();
      const publishableKey = productsData?.publishableKey;
      const products = productsData?.products;
      setProduct(products?.[0]);

      if (!publishableKey) return;

      const stripePromise = loadStripe(publishableKey);
      setStripePromise(stripePromise);
    }
    initializeTopUp();
  }, [currencyCode]);

  return (
    <SupportScreenWrapper title="Top up permanent storage" route="/storage" component={<CurrencySelect />}>
      <Elements stripe={stripePromise}>
        <OneTimeTopUpCardForm product={product} />
      </Elements>
    </SupportScreenWrapper>
  );
};

export default OneTimeTopUp;
