import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { FormControl, Select, MenuItem, ListItemText, Radio, Divider } from "@mui/material";
import { RadioEmptyIcon, RadioCheckedIcon, TriangleDownIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    height: "1.4375em!important",
    minHeight: "1.4375em!important",
    fontSize: ({ outlined }) => (outlined ? "1rem" : "14px"),
    lineHeight: "1.4375em",
    color: theme.palette.text.secondary,
    minWidth: "78px",
    border: "none",
    padding: ({ outlined }) => !outlined && "2px 0 0"
  },
  select: {
    paddingTop: "16px",
    paddingBottom: "16px"
    // '&:focus': {
    //   background: ({ darkMode }) => (darkMode ? '#333' : '#faf7f6')
    // }
  },
  selectRoot: {
    // background: ({ emailFilled, darkMode }) =>
    //   emailFilled ? (darkMode ? '#333' : '#faf7f6') : 'inherit',
    color: ({ roleMenuOpen }) => roleMenuOpen && theme.palette.text.primary,
    paddingRight: ({ outlined }) => !outlined && "12px!important",
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  iconOpen: {
    color: `${theme.palette.primary.main}!important`
  },
  arrowIcon: {
    color: theme.palette.text.secondary,
    marginRight: ({ outlined }) => !outlined && "-6px",
    marginTop: ({ outlined }) => !outlined && "2px"
  },
  menuItem: {
    "&:last-of-type": {
      paddingBottom: 20
    },
    "&:first-of-type": {
      paddingTop: 20
    },
    // fontSize: '15px',
    // minHeight: '34px',
    alignItems: "start",
    paddingTop: theme.spacing(5),
    paddingBottom: 0,
    paddingRight: 16
  },
  selected: {
    background: "transparent!important"
  },
  menuList: {
    backgroundColor: theme.palette.background.secondary,
    maxWidth: "293px"
  },
  adornedEnd: {
    paddingRight: 0
  },
  radio: {
    marginTop: theme.spacing(1),
    padding: "0 8px 0 0",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-checked:hover": {
      backgroundColor: ({ outlined }) => (outlined ? "inherit" : "transparent")
    }
  },
  paper: {
    marginTop: theme.spacing(2)
  }
}));

function SelectMenu({
  member,
  outlined,
  handleFormChange,
  index,
  fromInvite = false,
  airdropChecked = false,
  isOrgInvite = false,
  isOrg = false
}) {
  const { darkMode } = useGlobalContext();
  const [roleMenuOpen, setRoleMenuOpen] = React.useState(false);
  const classes = useStyles({
    darkMode: darkMode,
    roleMenuOpen: roleMenuOpen,
    outlined: outlined
  });

  const selectOptionsMenu = () =>
    member.role === "CONTRIBUTOR"
      ? "Contributor"
      : member.role === "OWNER"
      ? isOrgInvite
        ? "Admin"
        : "Owner"
      : member.role === "REVOKED"
      ? "Revoked"
      : member.role === "MEMBER"
      ? "Member"
      : "View Only";

  return (
    <FormControl sx={{ width: fromInvite ? "135px" : "auto" }}>
      <Select
        variant={outlined ? "outlined" : "standard"}
        {...(!outlined && { disableUnderline: true })}
        name="role"
        labelId="permissions-select-outlined-label"
        id="permissions-select-outlined"
        value={member.role}
        IconComponent={TriangleDownIcon}
        renderValue={selectOptionsMenu}
        classes={{
          select: classes.selectMenu,
          outlined: classes.select,
          iconOpen: classes.iconOpen
        }}
        MenuProps={{
          elevation: 4,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          classes: {
            list: classes.menuList,
            paper: classes.paper
          }
        }}
        inputProps={{
          classes: {
            root: classes.selectRoot,
            icon: classes.arrowIcon
          }
        }}
        onOpen={() => setRoleMenuOpen(true)}
        onClose={() => setRoleMenuOpen(false)}
        onChange={(e) => handleFormChange(e, index, member)}
      >
        {isOrgInvite
          ? [
              <MenuItem classes={{ selected: classes.selected }} className={classes.menuItem} value="MEMBER" key="_member">
                <Radio
                  icon={<RadioEmptyIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  className={classes.radio}
                  disableRipple
                  disableFocusRipple
                  checked={member.role === "MEMBER"}
                />
                <ListItemText
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.primary"
                  }}
                  secondaryTypographyProps={{
                    variant: "body2"
                  }}
                  style={{ whiteSpace: "normal" }}
                  primary="Member"
                  secondary="Members will only see the vaults they create. They can create vaults and use the organisation’s storage."
                />
              </MenuItem>,
              <MenuItem classes={{ selected: classes.selected }} className={classes.menuItem} value="OWNER" key="_owner">
                <Radio
                  icon={<RadioEmptyIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  className={classes.radio}
                  disableRipple
                  disableFocusRipple
                  checked={member.role === "OWNER"}
                />
                <ListItemText
                  style={{ whiteSpace: "normal" }}
                  primary="Admin"
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.primary"
                  }}
                  secondaryTypographyProps={{
                    variant: "body2"
                  }}
                  secondary="Admins will see all vaults created by anyone in the organisation. Admins can invite others to the organisation."
                />
              </MenuItem>
            ]
          : isOrg
          ? [
              <MenuItem classes={{ selected: classes.selected }} className={classes.menuItem} value="MEMBER" key="_member">
                <Radio
                  icon={<RadioEmptyIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  className={classes.radio}
                  disableRipple
                  disableFocusRipple
                  checked={member.role === "MEMBER"}
                />
                <ListItemText
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.primary"
                  }}
                  secondaryTypographyProps={{
                    variant: "body2"
                  }}
                  style={{ whiteSpace: "normal" }}
                  primary="Member"
                  secondary="Members can create vaults and use the organisation’s storage."
                />
              </MenuItem>
            ]
          : [
              <MenuItem classes={{ selected: classes.selected }} className={classes.menuItem} value="CONTRIBUTOR" key="_contriburor">
                <Radio
                  icon={<RadioEmptyIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  className={classes.radio}
                  disableRipple
                  disableFocusRipple
                  checked={member.role === "CONTRIBUTOR"}
                />
                <ListItemText
                  style={{ whiteSpace: "normal" }}
                  primary="Contributor"
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.primary"
                  }}
                  secondaryTypographyProps={{
                    variant: "body2"
                  }}
                  secondary="People can upload files, send messages and delete files."
                />
              </MenuItem>,
              <MenuItem
                classes={{ selected: classes.selected }}
                className={classes.menuItem}
                value="OWNER"
                key="_owner"
                sx={{ display: airdropChecked ? "none" : "flex" }}
              >
                <Radio
                  icon={<RadioEmptyIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  className={classes.radio}
                  disableRipple
                  disableFocusRipple
                  checked={member.role === "OWNER"}
                />
                <ListItemText
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.primary"
                  }}
                  secondaryTypographyProps={{
                    variant: "body2"
                  }}
                  style={{ whiteSpace: "normal" }}
                  primary="Owner"
                  secondary="Equal rights to manage the vault and its members."
                />
              </MenuItem>,
              <MenuItem classes={{ selected: classes.selected }} className={classes.menuItem} value="VIEWER" key="_viewer">
                <Radio
                  icon={<RadioEmptyIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  className={classes.radio}
                  disableRipple
                  disableFocusRipple
                  checked={member.role === "VIEWER"}
                />
                <ListItemText
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.primary"
                  }}
                  secondaryTypographyProps={{
                    variant: "body2"
                  }}
                  style={{ whiteSpace: "normal" }}
                  primary="View only"
                  secondary="People can view and download."
                />
              </MenuItem>
            ]}
        {!fromInvite && <Divider light sx={{ paddingTop: "16px", marginTop: "0!important", marginBottom: "0!important" }} />}
        {!fromInvite && (
          <MenuItem
            classes={{ selected: classes.selected }}
            className={classes.menuItem}
            sx={{ paddingTop: "16px!important" }}
            value="REVOKED"
          >
            <Radio
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              checked={member.role === "REVOKED"}
            />
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
                color: "text.primary"
              }}
              secondaryTypographyProps={{
                variant: "body2"
              }}
              style={{ whiteSpace: "normal" }}
              primary="Revoke access"
            />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default SelectMenu;
