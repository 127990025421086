import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton, Divider, Button, CircularProgress } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { ChevronRightIcon } from "@akord/addon-icons";
import DescriptionAndTagsForm from "./DescriptionAndTagsForm";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { NotificationData, useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";

type DescriptionDrawerStyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles<Theme, DescriptionDrawerStyleProps>(theme => ({
  paperAnchorRight: {
    width: ({ isMobile }) => (isMobile ? "85%" : "480px")
  },
  backDrop: {
    top: ({ isMobile }) => (isMobile ? 0 : `86px`),
    left: ({ isMobile }) => (isMobile ? 0 : "250px")
  },
  paper: {
    top: ({ isMobile }) => (isMobile ? 0 : `86px`)
  },
  divider: {
    marginLeft: ({ isMobile }) => (isMobile ? -20 : 0),
    marginRight: ({ isMobile }) => (isMobile ? -20 : 0)
  }
}));

type DescriptionDrawerProps = {
  isMobile: boolean;
  darkMode: boolean;
  showInfo: boolean;
  onShowInfo: (value: boolean) => void;
  infoData: NotificationData;
};

const DescriptionDrawer: React.FC<DescriptionDrawerProps> = ({ isMobile, darkMode, showInfo, onShowInfo, infoData }) => {
  const { akord, onVaultUpdate } = useGlobalContext();
  const { onVaultUpdate: onVaultMembershipData } = useVaultContext();
  const { onNotificationData, notificationData } = useNotificationsContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const [formData, setFormData] = useState({
    roomDescription: ""
  });
  const [tags, setTags] = React.useState<string[]>([]);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const classes = useStyles({
    isMobile: isMobile
  });
  const currentType = infoData?.vault || infoData?.stack;
  const isVaultOwner = infoData?.membership?.role === "OWNER";

  React.useEffect(() => {
    if (!currentType) return;
    let { description = "", tags = [] } = currentType;
    // Set to default for vaults with no tags/description (pre tags vaults)
    if (!tags) tags = [];
    if (!description) description = "";
    setFormData({ roomDescription: description });
    setTags(tags);
  }, [showInfo, currentType]);

  const updateVault = async () => {
    try {
      if (!currentType?.id) return;
      setIsUpdating(true);
      const res = await akord?.vault.update(currentType?.id, { tags: tags, description: formData.roomDescription });
      if (res?.object) {
        onVaultUpdate(res.object!);
        onVaultMembershipData(currentType.id);
        // update incoming data to keep the component in sync (ex. Save button)
        onNotificationData({ ...notificationData, vault: res.object, membership: infoData?.membership, type: "vault" });
        onShowInfo(false);
        onSnackbarToShow("vaultUpdate");
      }
    } catch (err) {
      console.log("Couldn't update the vault: ", err);
      onSnackbarToShow("actionFailure");
    } finally {
      setIsUpdating(false);
    }
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) return;

    onShowInfo(open);
  };

  const handleTagChange = (newTag: string[]) => {
    setTags(newTag);
  };
  const handleFormChange = (description: string) => {
    setFormData({ ...formData, roomDescription: description });
  };

  if (!currentType) return null;

  const isSaveDisabled = () => {
    const { description: savedDescription = "", tags: savedTags = [] } = currentType;
    const { roomDescription } = formData;
    if (
      ((tags?.length === 0 || JSON.stringify(savedTags) === JSON.stringify(tags)) &&
        (roomDescription?.length === 0 || roomDescription?.length > 300 || savedDescription === roomDescription)) ||
      isUpdating ||
      !isVaultOwner
    )
      return true;
    else return false;
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={showInfo}
      onClose={toggleDrawer(false)}
      BackdropProps={{ className: classes.backDrop }}
      PaperProps={{ className: classes.paper }}
      classes={{
        paperAnchorRight: classes.paperAnchorRight
      }}
    >
      <Box ml={5} mr={5} mt={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h3" color="text.primary" noWrap>
            Description and tags
          </Typography>
          <IconButton sx={{ padding: 0 }} onClick={toggleDrawer(false)} size="large">
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Box mb={3}>
          <Typography variant="body2">{currentType.name}</Typography>
        </Box>
        <Divider className={classes.divider} />
        <Box mt={6}>
          <DescriptionAndTagsForm
            formData={formData}
            onVaultDescriptionChange={handleFormChange}
            tags={tags}
            handleTagChange={handleTagChange}
            isVaultOwner={isVaultOwner}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" size="small" color="primary" onClick={() => updateVault()} disabled={isSaveDisabled()}>
            Save
            {isUpdating && <CircularProgress size={24} sx={{ position: "absolute" }} />}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DescriptionDrawer;
