import React from "react";
import { TableCell, TableRow, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { ImageIcon, DocumentIcon, VaultIcon, FolderIcon, StackIcon, MessageIcon, NoteIcon } from "@akord/addon-icons";
import { FolderDrawer, RoomDrawer, StackDrawer } from "../common";
import MoreActionButton from "../common/MoreActionButton";
import MenuFolder from "../common/MoreMenu/MenuFolder";
import MenuRoom from "../common/MoreMenu/MenuRoom";
import MenuStack from "../common/MoreMenu/MenuStack";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

type SearchResultStylesProps = {
  isActive: boolean;
  isPublic: boolean;
  isDarkModeEnabled: boolean;
};

const useStyles = makeStyles<Theme, SearchResultStylesProps>(theme => ({
  stackIconBackGround: {
    background: ({ isActive, isPublic }) =>
      isActive ? (isPublic ? theme.palette.background.stack : theme.palette.background.active) : theme.palette.background.archived,
    boxSizing: "content-box",
    padding: "3px",
    color: ({ isActive, isPublic }) =>
      isActive ? (isPublic ? theme.palette.info.light : theme.palette.primary.main) : theme.palette.primary.main,
    marginRight: "15px"
  },
  dataRoomIconBackGround: {
    background: ({ isActive, isPublic }) =>
      isActive ? (isPublic ? theme.palette.background.stack : theme.palette.background.active) : theme.palette.background.archived,
    color: ({ isActive, isPublic }) =>
      isActive ? (isPublic ? theme.palette.info.main : theme.palette.primary.main) : theme.palette.primary.main,
    boxSizing: "content-box",
    padding: "3px",
    marginRight: theme.spacing(4)
  },
  folderIconBackGround: {
    boxSizing: "content-box",
    color: ({ isActive }) => (isActive ? "#8B7872" : theme.palette.secondary.disabled),
    marginRight: "15px"
  },
  messageIconBackGround: {
    boxSizing: "content-box",
    color: "#A167FF",
    background: ({ isDarkModeEnabled }) => (isDarkModeEnabled ? "#39078A" : "#F0E7FE"),
    padding: "3px",
    marginRight: "15px"
  }
}));

type SearchResultProps = { document: any };

const SearchResult: React.FC<SearchResultProps> = ({ document }) => {
  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);

  const history = useHistory();

  const { width, darkMode } = useGlobalContext();
  const openMenu = Boolean(anchorEl);

  const classes = useStyles({
    isActive: document.isActive,
    isPublic: document.isPublic,
    isDarkModeEnabled: darkMode
  });

  const handleMenu = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  const getIcon = (type: string, isActive: boolean) => {
    switch (type) {
      case "DATA_ROOM":
        return <VaultIcon color={isActive ? "action" : "disabled"} classes={{ root: classes.dataRoomIconBackGround }} />;
      case "FOLDER":
        return <FolderIcon color={isActive ? "action" : "disabled"} classes={{ root: classes.dataRoomIconBackGround }} />;
      case "STACK":
        return <StackIcon fontSize="medium" classes={{ root: classes.stackIconBackGround }} />;
      case "STACK_DOCUMENT":
        return <DocumentIcon fontSize="medium" classes={{ root: classes.stackIconBackGround }} />;
      case "STACK_IMAGE":
        return <ImageIcon fontSize="medium" classes={{ root: classes.stackIconBackGround }} />;
      case "NOTE":
        return <NoteIcon fontSize="medium" classes={{ root: classes.stackIconBackGround }} />;
      case "MEMO":
        return <MessageIcon fontSize="medium" classes={{ root: classes.stackIconBackGround }} />;
      default:
        break;
    }
  };

  const getMenu = (type: string) => {
    switch (type) {
      case "DATA_ROOM":
        if (width !== "xs") {
          //@ts-ignore
          //TODO Update when component is TSed
          return <MenuRoom anchorEl={anchorEl} openMenu={openMenu} handleMenuClose={handleMenuClose} vaultId={document.id} />;
        } else {
          //@ts-ignore
          //TODO Update when component is TSed
          return <RoomDrawer openDrawer={openMenu} handleMenuClose={handleMenuClose} vaultId={document.id} />;
        }
      case "STACK":
      case "STACK_DOCUMENT":
      case "STACK_IMAGE":
        if (width !== "xs") {
          return (
            <MenuStack
              anchorEl={anchorEl}
              openMenu={openMenu}
              handleMenuClose={handleMenuClose}
              stackId={document.id}
              vaultId={document.sourceId}
            />
          );
        } else {
          return <StackDrawer openDrawer={openMenu} handleMenuClose={handleMenuClose} stackId={document.id} vaultId={document.sourceId} />;
        }
      case "FOLDER":
        if (width !== "xs") {
          return <MenuFolder anchorEl={anchorEl} openMenu={openMenu} handleMenuClose={handleMenuClose} folderId={document.id} />;
        } else {
          return <FolderDrawer openDrawer={openMenu} handleMenuClose={handleMenuClose} folderId={document.id} />;
        }
      default:
        break;
    }
  };

  const decorate = (searchResult: any) => {
    searchResult.terms.forEach((matchedTerm: string) => {
      searchResult.name = searchResult.name.split(matchedTerm).join(`<b>${matchedTerm}</b>`);
    });
    return searchResult.name;
  };

  const isMoreActionsShown = (type: string) => {
    switch (type) {
      case "MEMO":
        return false;
      default:
        return true;
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell
          size="medium"
          style={{
            cursor: "pointer",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}
          scope="row"
          onClick={() =>
            history.push({
              pathname: document.sourceUrl,
              state: { itemId: document.id }
            })
          }
          width="95%"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {getIcon(document.type, document.isActive)}
            <div>
              <div style={{ display: "grid" }}>
                <Typography
                  variant="body2"
                  color="text.primary"
                  noWrap
                  className={classes.item}
                  dangerouslySetInnerHTML={{ __html: decorate(document) }}
                ></Typography>
              </div>
              {document.sourceName && (
                <div>
                  <Typography variant="caption" color="text.secondary" component="span" className={classes.source}>
                    {document.sourceName}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell align="right" size="medium" width="5%">
          <MoreActionButton
            handleClick={handleMenu}
            menuActive={menuActive}
            hidden={!isMoreActionsShown(document.type)}
            arialLabel="display more actions search"
          />
        </TableCell>
      </TableRow>
      {getMenu(document.type)}
    </>
  );
};

export default SearchResult;
