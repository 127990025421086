import {
  VaultLeaveIcon,
  TrashIcon,
  DoubleFileIcon,
  FileRestoreIcon,
  FolderRestoreIcon,
  MinusInCircleIcon,
  MoveIcon,
  RestoreIcon,
  UploadIcon,
  CloseInCircleIcon,
  InfoIcon,
  ManifestIcon,
  InactiveIcon,
  PasswordMaskIcon
} from "@akord/addon-icons";
import { NotificationData } from "../../../contexts/NotificationsContextProvider";
import { ModalType } from "../../../hooks/useModal";

type ConfirmationModalOptionsType = {
  [Option in ModalType]: ConfirmationModalDataProps;
};

type ConfirmationModalDataProps = {
  title: (notificationData?: NotificationData) => string;
  createText: (
    notificationData: NotificationData | null,
    duplicateFiles?: File[],
    fileIndex?: number,
    isPublic?: boolean,
    isCloud?: boolean
  ) => string;
  icon: (props: any) => React.ReactElement<React.ComponentProps<any>, any>;
  showCancel: boolean;
  modalType?: string; //move, upload
  buttonText?: string;
  buttonTextPrimary?: string;
  buttonSecondary?: string;
  buttonTertiary?: string;
};

export const confirmationModalOptions: ConfirmationModalOptionsType = {
  revoke: {
    title: () => "Revoke access",
    createText: notificationData => {
      return `You are about to revoke access to the vault for ${
        notificationData?.memberToRevoke?.user?.profileName // TODO changed from 'name' to 'profileName'
          ? notificationData.memberToRevoke.user?.profileName
          : notificationData?.memberToRevoke?.user?.email
      }. Are you sure?`;
    },
    buttonText: "Revoke access",
    icon: CloseInCircleIcon,
    showCancel: true
  },
  orgRevoke: {
    title: () => "Revoke access from the organization",
    createText: notificationData => {
      return `You are about to revoke access to the vault for ${notificationData?.memberToRevoke?.user?.email}. Are you sure?`;
    },
    buttonText: "Revoke access",
    icon: CloseInCircleIcon,
    showCancel: true
  },
  archive: {
    title: () => "Deactivate vault",
    createText: notificationData => {
      return `All members of the vault will be notified that no more actions can be taken.\n\nAre you sure you want to make the "${notificationData?.title}" vault inactive?`;
    },
    buttonText: "Deactivate vault",
    icon: InactiveIcon,
    showCancel: true
  },
  restore: {
    title: () => "Restore vault",
    createText: memberToRevoke => {
      return `All members of the vault will be notified the vault has been restored. Are you sure you want to restore the "${memberToRevoke?.title}" vault?`;
    },
    buttonText: "Restore vault",
    icon: RestoreIcon,
    showCancel: true
  },
  delete: {
    title: () => "Remove vault",
    createText: notificationData => {
      return notificationData?.cloud
        ? `Removing this cloud vault will mean it's deleted from the Akord app.\n\nAre you sure you want to remove the "${notificationData.title}" vault?`
        : `Removing a vault will mean it is no longer accessible through the Akord app. The data in the vault can only be found on Arweave if someone has the transaction IDs.\n\nAre you sure you want to remove the "${notificationData?.title}" vault?`;
    },
    buttonText: "Remove vault",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  batchRestoreVaults: {
    title: () => "Restore vaults",
    createText: notificationData => {
      return `All members of the vaults will be notified the vaults have been restored. Are you sure you want to restore ${notificationData?.batchItems?.size} vaults?`;
    },
    buttonText: "Restore vault",
    icon: RestoreIcon,
    showCancel: true
  },
  batchRemoveVaults: {
    title: () => "Remove vaults",
    createText: notificationData => {
      return `Removing vaults will mean they are no longer accessible within Akord app.\n\nAre you sure you want to remove ${notificationData?.batchItems?.size} vaults?`;
    },
    buttonText: "Remove vault",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  revokeFile: {
    title: () => "Hide file",
    createText: fileToRevoke => {
      return `You are about to hide "${[fileToRevoke?.title, fileToRevoke?.extension].join(
        "."
      )}". It will no longer be available to view or download. Are you sure?`;
    },
    buttonText: "Hide",
    icon: PasswordMaskIcon,
    showCancel: true
  },
  batchRevoke: {
    title: notificationData => {
      const valuesArray = Array.from(notificationData?.batchItems?.values() || []);
      return valuesArray.includes("folder") && !valuesArray.includes("stack")
        ? "Hide folders"
        : !valuesArray.includes("folder") && valuesArray.includes("stack")
        ? "Hide files"
        : "Hide items";
    },
    createText: notificationData => {
      return `You are about to hide ${notificationData?.batchItems?.size} items. They will no longer be available to view or download. Are you sure?`;
    },
    buttonText: "Hide",
    icon: PasswordMaskIcon,
    showCancel: true
  },
  revokeStack: {
    title: () => "Hide file stack",
    createText: fileToRevoke => {
      return `You are about to hide "${[fileToRevoke?.title, fileToRevoke?.extension].join(
        "."
      )}". It will no longer be available to view or download. Are you sure?`;
    },
    buttonText: "Revoke",
    icon: PasswordMaskIcon,
    showCancel: true
  },
  restoreFile: {
    title: () => "Restore file",
    createText: fileToRevoke => {
      return `Are you sure you want to restore "${[fileToRevoke?.title, fileToRevoke?.extension].join(".")}"?`;
    },
    buttonText: "Restore",
    icon: FileRestoreIcon,
    showCancel: true
  },
  batchRestore: {
    title: () => "Restore files",
    createText: notificationData => {
      return `Are you sure you want to restore ${notificationData?.batchItems?.size} files?`;
    },
    buttonText: "Restore",
    icon: FileRestoreIcon,
    showCancel: true
  },
  restoreStack: {
    title: () => "Restore file stack",
    createText: fileToRevoke => {
      return `Are you sure you want to restore "${[fileToRevoke?.title, fileToRevoke?.extension].join(".")}"?`;
    },
    buttonText: "Restore",
    icon: RestoreIcon,
    showCancel: true
  },
  deleteFile: {
    title: () => "Delete file",
    createText: fileToRemove => {
      return `Deleting a file will remove it permanently from the Akord app. You will recover the storage space.
      Are you sure you want to delete "${[fileToRemove?.title, fileToRemove?.extension].join(".")}"?`;
    },
    buttonText: "Delete",
    icon: TrashIcon,
    showCancel: true
  },
  removeFile: {
    title: () => "Clear file",
    createText: fileToRemove => {
      return `Clearing a file will mean it is no longer accessible through the Akord app. It can only be found on Arweave if someone has the transaction ID.\n\nAre you sure you want to clear "${[
        fileToRemove?.title,
        fileToRemove?.extension
      ].join(".")}"?`;
    },
    buttonText: "Clear",
    icon: MinusInCircleIcon,
    // icon: fileToRemove => (fileToRemove.cloud ? TrashIcon : MinusInCircleIcon), // TODO doesnt look its used
    showCancel: true
  },
  batchRemove: {
    title: () => "Clear files",
    createText: () => {
      return `Clearing a file will mean it is no longer accessible through the Akord app. They can only be found on Arweave if someone has the transaction ID.\n\nAre you sure you want to clear these files?`;
    },
    buttonText: "Clear",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  batchDelete: {
    title: () => "Delete files",
    createText: () => {
      return `You are about to permanently delete these files from your cloud storage.\n\nAre you sure you want to delete these files?`;
    },
    buttonText: "Delete",
    icon: TrashIcon,
    showCancel: true
  },
  restoreFileFromTimeline: {
    title: () => "Cleared file",
    createText: () => "This file was cleared. To view or download the file, you have to restore the file",
    buttonText: "Restore file",
    icon: PasswordMaskIcon,
    showCancel: true
  },
  deletedFileFromTimeline: {
    title: () => "Deleted file",
    createText: () =>
      "This file has been deleted. We keep a log of the original upload in the timeline, but it is no longer available to view or download.",
    buttonText: "Done",
    icon: TrashIcon,
    showCancel: false
  },
  removedFileFromTimeline: {
    title: () => "Removed file",
    createText: () =>
      "This file has been  removed. We keep a log of the original upload in the timeline, and it is still on the Arweave blockchain, but it is no longer available to view or download within Akord.",
    buttonText: "Done",
    icon: MinusInCircleIcon,
    showCancel: false
  },
  duplicateFile: {
    title: () => "File already exists",
    createText: (_, duplicateFiles, index = 0) =>
      `A file named " ${
        duplicateFiles?.[index] && duplicateFiles?.[index].name
      } " already exists in this vault. Do you want to append this file as a new version?`,
    buttonTextPrimary: "Replace",
    buttonSecondary: "Keep both",
    buttonTertiary: "Skip",
    icon: DoubleFileIcon,
    showCancel: false
  },
  folderRevoke: {
    title: () => "Hide folder",
    createText: folderToRevoke => {
      return `Are you sure you want to hide the folder "${folderToRevoke?.title}" and all its contents from your vault?`;
    },
    buttonText: "Hide",
    icon: PasswordMaskIcon,
    showCancel: true
  },
  folderMove: {
    title: () => "Move folder",
    createText: () => "Select destination:",
    buttonText: "Move",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  fileMove: {
    title: () => "Move item",
    createText: () => "Select destination:",
    buttonText: "Move",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  batchMove: {
    title: () => "Move items",
    createText: () => "Select destination:",
    buttonText: "Move",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  noteMove: {
    title: () => "Move items",
    createText: () => "Select destination:",
    buttonText: "Move",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  restoreFolderWithMove: {
    title: () => "Restore folder",
    createText: () => "Select destination:",
    buttonText: "Restore",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  restoreFileWithMove: {
    title: () => "Restore file",
    createText: () => "Select destination:",
    buttonText: "Restore",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  folderRestore: {
    title: () => "Restore folder",
    createText: folderToRestore => {
      return `Are you sure you want to restore "${folderToRestore?.title}"?`;
    },
    buttonText: "Restore",
    icon: FolderRestoreIcon,
    showCancel: true
  },
  folderDelete: {
    title: () => "Delete folder",
    createText: folder => {
      return `Are you sure you want to permanently delete "${folder?.title}"?`;
    },
    buttonText: "Delete",
    icon: TrashIcon,
    showCancel: true
  },
  confirmUpload: {
    title: notificationData => (notificationData?.isVaultPublic ? "Upload public files" : "Upload encrypted files"),
    createText: (_, __, ___, isVaultPublic, isVaultcloud) =>
      isVaultPublic
        ? `You are about to upload public files to ${
            isVaultcloud ? "the cloud" : "Arweave"
          }. These could be accessed by anyone. If you have any concerns, do not upload.`
        : `Please check you have the correct files before uploading to the vault’s ${isVaultcloud ? "cloud" : "permanent"} storage.`,
    buttonText: "Upload",
    icon: UploadIcon,
    showCancel: true,
    modalType: "upload"
  },
  confirmUploadRetry: {
    title: () => "Some files were not uploaded",
    createText: notificationData =>
      `${notificationData?.uploaded} files out of ${notificationData?.allStacks} files were successfully uploaded. The following files were not uploaded.`,
    buttonText: "Retry",
    icon: InfoIcon,
    showCancel: true,
    modalType: "upload"
  },
  leave: {
    title: () => "Leave vault",
    createText: notificationData => {
      return `You are about to leave the "${notificationData?.title}" vault. You will no longer be able to access the vault and its contents. Are you sure?`;
    },
    buttonText: "Leave",
    icon: VaultLeaveIcon,
    showCancel: true
  },
  revokeNote: {
    title: () => "Hide note",
    createText: fileToRevoke => {
      return `You are about to hide "${fileToRevoke?.title}". It will no longer be available to view. Are you sure?`;
    },
    buttonText: "Hide",
    icon: PasswordMaskIcon,
    showCancel: true
  },
  restoreNote: {
    title: () => "Restore note",
    createText: fileToRevoke => {
      return `Are you sure you want to restore "${fileToRevoke?.title}"?`;
    },
    buttonText: "Restore",
    icon: FileRestoreIcon,
    showCancel: true
  },
  saveNote: {
    title: () => "Save note",
    createText: () => {
      return "When you save this note it will be stored on the blockchain, using some of your available storage.";
    },
    buttonText: "Save",
    icon: UploadIcon,
    showCancel: true,
    modalType: "upload"
  },
  removeNote: {
    title: () => "Clear note",
    createText: noteToRemove => {
      return `Clearing a note will mean it is no longer accessible through the Akord app. They can only be found on Arweave if someone has the transaction ID.\n\nAre you sure you want to clear "${noteToRemove?.title}"?`;
    },
    buttonText: "Clear",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  createManifest: {
    title: () => "Create JSON metadata",
    createText: () => {
      return "We can create different types of JSON metadata files depending on your use case.";
    },
    buttonText: "Create JSON",
    icon: ManifestIcon,
    showCancel: true
  },
  disable2FA: {
    title: () => "Disable 2FA",
    createText: () => {
      return `Are you sure you want to disable your two-factor authentication?`;
    },
    buttonText: "Disable 2FA",
    icon: InfoIcon,
    showCancel: true
  },
  deleteAccount: {
    title: () => "Delete account",
    createText: () => {
      return `If you decide to delete your account, we recommend you still keep a copy of your recovery phrase to access any blockchain data. Your account will be deleted within 24 hours.`;
    },
    buttonText: "Delete",
    icon: CloseInCircleIcon,
    showCancel: true
  },
  vaultsUpgradeTier: {
    title: () => "You've hit the vault limit",
    createText: () => {
      return `You are unable to create more vaults on your current plan. Upgrade to the next tier to be able to add vaults.`;
    },
    buttonText: "Upgrade",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  membersUpgradeTier: {
    title: () => "You've hit the member limit",
    createText: () => {
      return `You are unable to invite more members to your vault on your current plan. Upgrade to the next tier to be able to invite more members.`;
    },
    buttonText: "Upgrade",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  topUpsUpgradeTier: {
    title: () => "You've hit the top up limit",
    createText: () => {
      return `You are unable to purchase selected permanent storage on your current plan. Upgrade to the next tier to get more topups.`;
    },
    buttonText: "Upgrade",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  cancelCurrentPlan: {
    title: () => "End subscription",
    createText: () =>
      "Are you sure you want to end your subscription? Your subscription will continue until the end of the current payment period.",
    buttonText: "End subscription",
    icon: CloseInCircleIcon,
    showCancel: true
  }
};
