import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, FormControl, OutlinedInput, Typography, FormHelperText, FormControlLabel } from "@mui/material";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import TagTextInput from "../../components/common/TagTextInput/TagTextInput";
import { useNftContext } from "../../contexts/NftContextProvider";
import { AkordCheckbox, ButtonGroup, SupportScreenWrapper } from "../../components/common";
import { getMintType, getVaultId } from "../../helpers/helpers";
import { isValidArweaveAddress } from "./nft-helpers";

const MetadataForm: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { isMobile } = useGlobalContext();
  const { onNFTMetadata, nftMetadata, onUdl, udl } = useNftContext();
  const isCollection = location.pathname.includes("/collection/");
  const vaultId = getVaultId(location.pathname);
  const mintType = getMintType(location.pathname);

  const handleFormChange = (prop: string) => (event: any) => {
    const { target } = event;
    let value = target ? (target.type === "checkbox" ? target.checked : target.value) : event;

    // fractionParts should be a number
    if (prop.includes("fractionParts")) value = value ? parseInt(value) : undefined;

    let metadata;
    metadata = {
      ...nftMetadata,
      [prop]: value
    };
    // assign default value if selected
    if (prop === "fractional") {
      if (value) {
        metadata.fractionParts = 100;
      } else {
        metadata.fractionParts = undefined;
      }
    }

    if (prop === "owner") {
      // Prefill Creator & Custom UDL form with the owner wallet address
      metadata.creator = value;
      onUdl({ ...udl, paymentAddress: value });
    }
    onNFTMetadata(metadata);
  };

  return (
    <SupportScreenWrapper title={isCollection ? "Collection Metadata" : "Atomic NFT Metadata"} hideChevron fullHeight>
      <Box display="flex" flexDirection="column" justifyContent={isMobile ? "space-between" : "flex-start"} mt={6}>
        <Box>
          {isCollection && (
            <Box mb={7}>
              <Box mb={4}>
                <Typography variant="body2" className="strong" color="text.primary">
                  Collection name
                </Typography>
                <Typography variant="body2" className="small">
                  If this NFT belongs to a collection, add the name of the collection here.
                </Typography>
              </Box>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  id="collection_name"
                  name="name"
                  type="text"
                  placeholder="Collection name"
                  value={nftMetadata.name}
                  onChange={handleFormChange("name")}
                />
              </FormControl>
            </Box>
          )}
          {!isCollection && (
            <Box mb={7}>
              <Box mb={4}>
                <Typography variant="body2" className="strong" color="text.primary">
                  NFT name
                </Typography>
              </Box>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  id="name"
                  name="name"
                  type="text"
                  placeholder="NFT name (max 150 characters)"
                  value={nftMetadata.name}
                  onChange={handleFormChange("name")}
                />
              </FormControl>
            </Box>
          )}
          <Box mb={7}>
            <Box mb={4}>
              <Typography variant="body2" className="strong" color="text.primary">
                Description
              </Typography>
            </Box>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                multiline
                rows={3}
                id="description"
                name="description"
                type="text"
                placeholder="Description (max 300 characters)"
                value={nftMetadata.description}
                onChange={handleFormChange("description")}
              />
            </FormControl>
          </Box>
          <Box mb={7}>
            <Box mb={4}>
              <Typography variant="body2" className="strong" color="text.primary">
                Fractional tokens (optional)
              </Typography>
              <Box mt={2}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    sx={{ alignItems: "start" }}
                    control={
                      <AkordCheckbox
                        size="small"
                        inputProps={{ "aria-label": "fractional_tokens" }}
                        checked={nftMetadata.fractional || false}
                        onChange={handleFormChange("fractional")}
                        name="fractional"
                        sx={{ marginTop: "0!important" }}
                      />
                    }
                    label={
                      <Typography variant="body2" className="small">
                        Allow multiple collectors to own fractions of the same Atomic NFT. Eg, setting 100 tokens would allow up to 100
                        people to own a piece of the same NFT.
                      </Typography>
                    }
                  />
                </FormControl>
              </Box>
              {nftMetadata.fractional && (
                <Box mt={2}>
                  <FormControl variant="outlined" sx={{ width: "140px" }}>
                    <OutlinedInput
                      defaultValue={nftMetadata.fractionParts || 100}
                      id="fractionParts"
                      name="value"
                      type="number"
                      placeholder="Fraction"
                      inputProps={{ min: 2, max: 100 }}
                      onChange={handleFormChange("fractionParts")}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
          </Box>
          <Box mb={7}>
            <Box mb={4}>
              <Typography variant="body2" className="strong" color="text.primary">
                Tags (optional)
              </Typography>
              <Typography variant="body2" className="small">
                Enter any number of tags for your NFT. Tags can help your NFT be found in the future.
              </Typography>
            </Box>
            <TagTextInput tags={nftMetadata.topics} isVaultOwner={true} onTagChange={handleFormChange("topics")} />
          </Box>
          <Box mb={7}>
            <Box mb={4}>
              <Typography variant="body2" className="strong" color="text.primary">
                Owner
              </Typography>
              <Typography variant="body2" className="small">
                Please enter a public Arweave wallet address for the owner of this NFT.
              </Typography>
            </Box>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                id="owner"
                name="owner"
                type="text"
                placeholder="Owner wallet addres"
                value={nftMetadata.owner}
                onChange={handleFormChange("owner")}
                error={!!nftMetadata.owner && !isValidArweaveAddress(nftMetadata.owner)}
              />
              {nftMetadata.owner && !isValidArweaveAddress(nftMetadata.owner) && (
                <FormHelperText sx={{ margin: "8px 0 0" }}>
                  <Typography variant="caption" color="error.main">
                    This must match the format for an Arweave wallet address.
                  </Typography>
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
        <ButtonGroup
          nextDiasabled={!nftMetadata.owner || !isValidArweaveAddress(nftMetadata.owner) || !nftMetadata.name || !nftMetadata.description}
          handleEnd={() => history.push(`/vaults/active/${vaultId}/nfts/mint/${mintType}/udl`)}
          handleBack={() => history.goBack()}
          nextText="Next"
        />
      </Box>
    </SupportScreenWrapper>
  );
};

export default withRouter(MetadataForm);
