import React from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Link,
  Theme
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LogoOrange from "../../components/common/logo/logoOrange";
import { AkordCheckbox, ButtonGroup, FormSubmit } from "../../components/common";
import { useLoginContext } from "../../contexts/LoginContext";
import RevealToggler from "../../components/common/RevealToggler";
import Base64Logo from "../../components/common/logo/Base64Logo";
import { useOrgContext } from "../../contexts/OrgContextProvider";

type LoginPageProps = {
  history: History;
};

const useStyles = makeStyles<Theme>({
  logo: {
    fill: "currentColor",
    color: "white",
    display: "block",
    margin: "0 0 24px 0",
    cursor: "pointer"
  }
});

const LoginPage: React.FC<LoginPageProps & RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { onLoginFormChange, formData, error, getErrorText, onSignIn, loading, showPassword, handleClickShowPassword } = useLoginContext();
  const { org } = useOrgContext();

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const isButtonDisabled = () => {
    return !(formData.userEmail.length > 0 && formData.userPassword.length >= 8 && !loading);
  };

  return (
    <Box>
      <FormSubmit onSubmit={() => onSignIn()}>
        <Box mb={6}>
          {org ? (
            <Box maxWidth="200px">
              <Base64Logo base64={org.logoBase64!} width="100%" className={classes.logo} onClick={() => history.push("/vaults/active")} />
            </Box>
          ) : (
            <LogoOrange width="74" height="28" className={classes.logo} onClick={() => history.push("/vaults/active")} />
          )}

          <Box mb={6}>
            <Typography variant="h1">Log in</Typography>
          </Box>
          <Box mb={6}>
            <FormControl fullWidth>
              <TextField
                key="Email address"
                error={!!error}
                label="Email address"
                variant="outlined"
                name="userEmail"
                id="email-input"
                type="email"
                value={formData.userEmail}
                onChange={onLoginFormChange()}
                style={{ marginBottom: "32px" }}
              />
              <FormControl error={!!error} variant="outlined" fullWidth>
                <InputLabel htmlFor="password-input">Password</InputLabel>
                <OutlinedInput
                  spellCheck="false"
                  id="password-input"
                  aria-describedby="password-input"
                  value={formData.userPassword}
                  name="userPassword"
                  label="Password"
                  onChange={onLoginFormChange()}
                  type={showPassword ? "text" : "password"}
                  classes={{ adornedEnd: classes.inputAdornment }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="large"
                      >
                        <RevealToggler showPassword={showPassword} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </FormControl>
          </Box>
        </Box>
        <Box mb={7}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={<AkordCheckbox inputProps={{ "aria-label": "keepSignedIn" }} name="keepSignedIn" onChange={onLoginFormChange()} />}
                label="Keep me signed in"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <ButtonGroup
          type="submit"
          nextText="Log in"
          nextDiasabled={isButtonDisabled()}
          hideBackButton={true}
          fullWidthNextButton={true}
          hideIcon={true}
          errorText={getErrorText()}
          disabledRed={false}
          loading={loading}
          noMarginTop={true}
        />
        <Box mt={6}>
          <Box>
            <Typography component="span" variant="body2">
              Forgot your password?
            </Typography>{" "}
            <Link variant="body2" onClick={() => history.push("/recover")} color="text.tertiary" sx={{ borderBottomColor: "primary.main" }}>
              Recover account
            </Link>
          </Box>
          <Box mt={1}>
            <Typography component="span" variant="body2">
              Don&apos;t have an account?
            </Typography>{" "}
            <Link variant="body2" onClick={() => history.push("/signup")} color="text.tertiary" sx={{ borderBottomColor: "primary.main" }}>
              Sign up
            </Link>
          </Box>
        </Box>
      </FormSubmit>
    </Box>
  );
};

export default withRouter(LoginPage);
