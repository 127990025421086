import React, { useState, createContext, useContext } from "react";
import { AKORD_ENV } from "../helpers/env";
import { useGlobalContext } from "./GlobalDataProvider";
import { useNotificationsContext } from "./NotificationsContextProvider";
import { useSnackbarContext } from "./SnackbarContextProvider";
import { digest } from "@akord/crypto";
import { RoleType, Vault } from "@akord/akord-js";

import { useOrgContext } from "./OrgContextProvider";
import { useRootContext } from "./RootContext";

type VaultCreateContextProviderProps = {
  onVaultCreate: () => Promise<Vault | null>;
  vaultCreateFormData: VaultCreateFormProps;
  error: any;
  vaultNameError: boolean;
  onVaultNameChange: (name: string) => void;
  onVaultDescriptionChange: (description: string) => void;
  onVaultIsPublicChange: (isPublic: boolean) => void;
  onVaultIsCloudChange: (isCloud: boolean) => void;
  onVaultFastChange: (isPublic: boolean, isCloud: boolean) => void;
  onVaultTagsChange: (tags: string[]) => void;
};

type VaultCreateFormProps = {
  roomTitle: string;
  roomTerms: string;
  hasTerms: boolean;
  isPublic: boolean;
  cloud: boolean;
  roomDescription: string;
  tags: string[];
};

type OrgAdminType = {
  publicKey: string;
  publicSigningKey: string;
  role: RoleType;
  email: string;
};

const Context = createContext({} as VaultCreateContextProviderProps);

const VaultCreateContextProvider: React.FC = ({ children }) => {
  const [formData, setFormData] = useState<VaultCreateFormProps>({
    roomTitle: "",
    roomTerms: "",
    hasTerms: false,
    isPublic: false,
    cloud: true,
    roomDescription: "",
    tags: []
  });
  const [error, setError] = useState();
  const [vaultNameError, setVaultNameError] = useState(false);

  const { subdomain } = useRootContext();
  const { isOrg, getOrgUsers } = useOrgContext();
  const { akord, userAttributes, onProcessingVaults, processingVaults, activeVaults, onActiveVaults, activeVaultsRef } = useGlobalContext();
  const { modal } = useNotificationsContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const handleVaultNameChange = (name: string) => {
    if (name.length > 150) setVaultNameError(true);
    else setVaultNameError(false);
    setFormData({ ...formData, roomTitle: name });
  };

  const handleVaultDescriptionChange = (description: string) => {
    setFormData({ ...formData, roomDescription: description });
  };

  const handleVaultIsPublicChange = (isPublic: boolean) => {
    setFormData({ ...formData, isPublic: isPublic });
  };

  const handleVaultIsCloudChange = (isCloud: boolean) => {
    setFormData({ ...formData, cloud: isCloud });
  };

  const handleVaultFastChange = (isPublic: boolean, isCloud: boolean) => {
    setFormData({ ...formData, isPublic: isPublic, cloud: isCloud });
  };

  const handleVaultTagsChange = (tags: string[]) => {
    setFormData({ ...formData, tags: tags });
  };

  const handleCreateRoom = async () => {
    const addedVault = {
      id: "null",
      status: "PROCESSING",
      public: formData.isPublic,
      cloud: formData.cloud,
      termsOfAccess: formData.roomTerms,
      name: formData.roomTitle
    } as Vault;
    onProcessingVaults([...Array.from(processingVaults), addedVault]);
    return await createVault();
  };

  const createVault = async () => {
    try {
      const { roomTitle, roomTerms, hasTerms, isPublic, cloud, tags, roomDescription } = formData;
      const result = await akord?.vault.create(roomTitle, {
        termsOfAccess: hasTerms ? roomTerms : undefined,
        public: isPublic,
        cloud: cloud,
        description: roomDescription,
        tags: tags,
        arweaveTags: subdomain && AKORD_ENV === "v2" ? [{ name: "Group-Id", value: await digest(subdomain) }] : []
      });
      const { object: vault } = result!;
      const updatedVaults = [...(activeVaults || []), vault];
      activeVaultsRef.current = updatedVaults;
      onActiveVaults(updatedVaults);
      onProcessingVaults(processingVaults);
      if (isOrg) {
        await handleOrgVaultCreate(vault.id);
      }
      onSnackbarToShow("dataRoomCreate");
      return vault;
    } catch (err: any) {
      //TODO: add support modal
      setError(err);
      onProcessingVaults(processingVaults);
      console.log(err);
      if (err?.statusCode === 403) {
        modal.onConfirmModalType("vaultsUpgradeTier");
        modal.onModalVisibility(null, "vaultsUpgradeTier");
      }
      return null;
    }
  };

  const handleOrgVaultCreate = async (vaultId: string) => {
    const orgUsers = await getOrgUsers();
    if (orgUsers.admins.length) {
      const admins = orgUsers.admins
        .map(admin => {
          if (userAttributes.publicSigningKey !== admin.publicSigningKey) {
            return {
              publicKey: admin.publicKey,
              publicSigningKey: admin.publicSigningKey,
              role: "OWNER",
              email: admin.email
            };
          } else {
            return null;
          }
        })
        .filter((admin): admin is OrgAdminType => !!admin);
      if (admins.length) {
        await akord?.membership.airdrop(vaultId, admins);
      }
    }
  };

  return (
    <Context.Provider
      value={{
        onVaultCreate: handleCreateRoom,
        vaultCreateFormData: formData,
        error: error,
        onVaultNameChange: handleVaultNameChange,
        vaultNameError: vaultNameError,
        onVaultDescriptionChange: handleVaultDescriptionChange,
        onVaultIsPublicChange: handleVaultIsPublicChange,
        onVaultIsCloudChange: handleVaultIsCloudChange,
        onVaultFastChange: handleVaultFastChange,
        onVaultTagsChange: handleVaultTagsChange
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default VaultCreateContextProvider;

export const withVaultCreateContext = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{vaultCreateContext => <Component {...props} {...vaultCreateContext} />}</Context.Consumer>;

export const useVaultCreateContext = () => useContext(Context);
