import React, { useState, useRef, useEffect } from "react";
import { Box, Fab, Tabs, Tab, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link, Switch, Route } from "react-router-dom";
import { grey } from "../../theme/colors";
import { getVaultStatus, getVaultId, getFolderId } from "../../helpers/helpers";
import { HideOnScroll, TopBarSecondary, FilterDrawer, BatchActionsBar, AddFileDrawer } from "../../components/common";
import TabDocuments from "../vault-pages/TabDocuments";
import { PlusIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import TabNfts from "../vault-pages/TabNfts";

type ShowPublicStackStyleProps = {
  darkMode: boolean;
  isVaultPublic: boolean;
};

const useStyles = makeStyles<Theme, ShowPublicStackStyleProps>(theme => ({
  fab: {
    position: "fixed",
    right: "16px",
    bottom: "16px",
    "&.Mui-disabled": {
      backgroundColor: ({ darkMode }) => (darkMode ? `${grey[700]}!important` : `${grey[200]}!important`),
      color: ({ darkMode }) => (darkMode ? `${grey[500]}!important` : `${grey[400]}!important`)
    }
  },
  fabIcon: {
    fontSize: "30px"
  },
  indicator: {
    bottom: 1,
    backgroundColor: ({ isVaultPublic }) => (isVaultPublic ? theme.palette.info.main : "inherit")
  },
  tabsFlexContainer: {
    borderBottomColor: ({ isVaultPublic }) => (isVaultPublic ? theme.palette.info.main : "inherit")
  }
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const ShowPublicStack: React.FC = () => {
  const { darkMode, isMobile } = useGlobalContext();
  const { vault, userRole, isVaultPublic } = useVaultContext();

  const { activeStacks, onShowFilter, showFilter, actionFilters, onActionFilters, periodFilter, onPeriodFilter, selectedItemsMap } =
    useAssetsContext();

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const openMobileMenu = Boolean(anchorEl);

  const docsRef = useRef(null);

  const isRoomArchived = vault?.status === "ARCHIVED";
  const dataRoomStatus = getVaultStatus(location.pathname);
  const dataRoomId = getVaultId(location.pathname);
  const folderId = getFolderId(location.pathname);

  const classes = useStyles({
    darkMode: darkMode,
    // isDisabled: userRole === "VIEWER" || isRoomArchived,
    isVaultPublic: isVaultPublic
  });

  const handleMobileMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleMobileMenuClose = () => setAnchorEl(null);

  // Clear the filter on Tabs switch
  useEffect(() => {
    if (periodFilter || actionFilters.length) {
      onActionFilters([]);
      onPeriodFilter(null);
    }
    onShowFilter(false);
  }, [location.pathname]);

  const topHeight = location.pathname.includes("/assets") ? (isMobile && selectedItemsMap?.size > 0 ? 176 : 133) : 90;

  return (
    <>
      <Box position="fixed" width={isMobile ? "100%" : "calc(100% - 250px)"} height="86px" zIndex="1">
        <TopBarSecondary filterActive={actionFilters.length > 0 || !!periodFilter} isPublicRoute />
        <Box zIndex="100">
          <Tabs
            color="text.secondary"
            value={location.pathname}
            aria-label="rooms tabs"
            classes={{
              indicator: classes.indicator,
              flexContainer: classes.tabsFlexContainer
            }}
          >
            <Tab
              component={Link}
              disableRipple
              label={<span className="step1">Files</span>}
              to={`/public/vaults/${dataRoomStatus}/${dataRoomId}/assets`}
              value={
                location.pathname.includes("/folders")
                  ? `/public/vaults/${dataRoomStatus}/${dataRoomId}/assets/folders/${folderId}`
                  : `/public/vaults/${dataRoomStatus}/${dataRoomId}/assets`
              }
              {...a11yProps(1)}
            />
            <Tab
              component={Link}
              disableRipple
              label={<span className="step2">Atomic NFTs</span>}
              to={`/public/vaults/${dataRoomStatus}/${dataRoomId}/nfts`}
              value={`/public/vaults/${dataRoomStatus}/${dataRoomId}/nfts`}
              {...a11yProps(0)}
            />
          </Tabs>
        </Box>
        {location.pathname.includes("/assets") && <BatchActionsBar folderId={folderId} isPublicRoute={true} />}
      </Box>
      {/* //ContentStart */}
      <Box
        flex="1"
        height={`calc(100% - ${topHeight}px)`}
        position="absolute"
        top={`${topHeight}px`}
        width={isMobile ? "100%" : "calc(100% - 250px)"}
      >
        <Switch>
          <Route
            exact
            path={`/public/vaults/${dataRoomStatus}/${dataRoomId}/nfts`}
            render={() => <TabNfts periodFilter={periodFilter} actionFilters={actionFilters} />}
          />
          <Route
            exact
            path={`/public/vaults/${dataRoomStatus}/${dataRoomId}/assets`}
            render={() => <TabDocuments docsRef={docsRef} isPublicRoute />}
          />
          <Route
            exact
            path={`/public/vaults/${dataRoomStatus}/${dataRoomId}/assets/folders/${folderId}`}
            render={() => <TabDocuments docsRef={docsRef} isPublicRoute />}
          />
          {/* Redirect unknown paths */}
          {/* <Redirect to={`/login`} /> */}
        </Switch>
        {activeStacks?.length > 0 && isMobile && location.pathname.match("/assets") ? (
          <HideOnScroll window={docsRef.current} fab={true}>
            <Fab
              disabled={userRole === "VIEWER" || isRoomArchived}
              size="medium"
              color="primary"
              className={classes.fab}
              onClick={handleMobileMenu}
            >
              <PlusIcon classes={{ root: classes.fabIcon }} />
            </Fab>
          </HideOnScroll>
        ) : null}
      </Box>
      {/* //ContentEnd */}
      <FilterDrawer
        topHeight={0}
        onShowFilter={onShowFilter}
        showFilter={showFilter}
        actionFilters={actionFilters}
        periodFilter={periodFilter}
        onPeriodFilter={onPeriodFilter}
        onActionFilters={onActionFilters}
      />

      {isMobile && <AddFileDrawer vault={vault} openDrawer={openMobileMenu} handleMenuClose={handleMobileMenuClose} />}
    </>
  );
};

export default ShowPublicStack;
