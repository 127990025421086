import React from "react";
import MoreDrawerRoot from "./MoreDrawerRoot";
import MoreDrawerItem from "./MoreDrawerItem";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { usePlusMenuOptions } from "../NavBars/MenuOptions";
import { Vault } from "@akord/akord-js";

type AddFileDrawerProps = {
  vault: Vault;
  openDrawer: boolean;
  handleMenuClose: () => void;
};

const AddFileDrawer: React.FC<AddFileDrawerProps> = ({ vault, openDrawer, handleMenuClose }) => {
  const { showFolderUpload, showZipUpload } = useAssetsContext();
  const { plusOptions } = usePlusMenuOptions();

  if (!vault) return null;

  return (
    <MoreDrawerRoot header={vault.name} openDrawer={openDrawer} handleMenuClose={handleMenuClose} isVaultPublic={vault.public}>
      {plusOptions(handleMenuClose).map(
        (item, index) =>
          !item.hidden && <MoreDrawerItem key={index} menuItem={item} showFolderUpload={showFolderUpload} showZipUpload={showZipUpload} />
      )}
    </MoreDrawerRoot>
  );
};

export default AddFileDrawer;
