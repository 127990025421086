import React from "react";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { Box, Typography, Tooltip } from "@mui/material";
import { BannerIcon, InfoIcon } from "@akord/addon-icons";

const ToolTipText: React.FC = () => (
  <Box m={2}>
    <Typography variant="body2" className="xs" style={{ marginBottom: 8, color: "primary.contrastText" }}>
      When someone you invite to a vault signs up to Akord for the first time, we’ll top up your account automatically with 100 MB of free
      storage.
    </Typography>
  </Box>
);

const ReferralBanner: React.FC = () => {
  const { darkMode } = useGlobalContext();
  return (
    <Box display="flex">
      <Box display="flex" justifyContent="center" alignItems="center" position="relative">
        <BannerIcon
          viewBox="0 0 174 23"
          sx={{
            width: "174px",
            color: darkMode ? "#0576F5" : "#3592FA"
          }}
        />
        <Box sx={{ position: "absolute" }} mb="4px">
          <Typography variant="caption" className="strong" sx={{ color: "primary.contrastText" }}>
            100 MB{" "}
          </Typography>
          <Typography variant="caption" sx={{ color: "primary.contrastText" }}>
            Referral Reward
          </Typography>
        </Box>
      </Box>
      <Tooltip placement="bottom-end" title={<ToolTipText />}>
        <span>
          <InfoIcon fontSize="small" color="secondary" sx={{ marginLeft: 2 }} />
        </span>
      </Tooltip>
    </Box>
  );
};

export default ReferralBanner;
