export const customUdlMenuData = {
  unknownUsageRights: [
    { value: "None", text: "Included" },
    { value: "Excluded", text: "Excluded" }
  ],
  derivations: [
    { value: "None", text: "Not allowed" },
    { value: "Allowed-With-Credit", text: "Allowed With Credit" },
    { value: "Allowed-With-Indication", text: "Allowed With Indication" },
    {
      value: "Allowed-With-License-Passthrough",
      text: "Allowed With License Passthrough"
    },
    { value: "Allowed-With-RevenueShare", text: "Allowed With Revenue Share" },
    { value: "Allowed-With-Fee", text: "Allowed With Fee" }
  ],
  commercialUses: [
    { value: "None", text: "Not allowed" },
    { value: "Allowed", text: "Allowed" },
    { value: "Allowed-With-Credit", text: "Allowed With Credit" },
    { value: "Allowed-With-RevenueShare", text: "Allowed With Revenue Share" },
    { value: "Allowed-With-Fee", text: "Allowed With Fee" }
  ],
  dataModelTrainings: [
    { value: "None", text: "Not allowed" },
    { value: "Allowed", text: "Allowed" },
    { value: "Allowed-With-Fee", text: "Allowed With Fee" }
  ],
  licenseFeeType: [
    { value: "None", text: "No fee" },
    { value: "Monthly", text: "Monthly" },
    { value: "One-Time", text: "One time" }
  ],
  duration: [
    { value: "Monthly", text: "Monthly" },
    { value: "One-Time", text: "One time" }
  ]
};
