import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  Link,
  Skeleton,
  TextField,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter } from "react-router-dom";
import { SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useOrgContext } from "../../contexts/OrgContextProvider";
import { createOrg, updateOrg } from "../../helpers/api-helpers";
import { MuiColorInput } from "mui-color-input";
import { ROOT_DOMAIN } from "../../helpers/env";
import { CloseIcon } from "@akord/addon-icons";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { Types } from "@akord/gql";

const useStyles = makeStyles<Theme>(theme => ({
  containedPrimary: {
    minWidth: "auto"
  },
  buttonProgress: {
    position: "absolute"
  },
  uploadBox: {
    borderRadius: "4px",
    padding: theme.spacing(7),
    border: "1px solid",
    borderColor: theme.palette.background.tertiary,
    textAlign: "center",
    maxHeight: "100px"
  },
  announcementBox: {
    background: theme.palette.background.card,
    borderRadius: "4px",
    padding: theme.spacing(4),
    border: "1px solid",
    borderColor: theme.palette.background.tertiary
  },
  rowSkeleton: {
    width: "100%",
    marginBottom: 32,
    borderRadius: 4,
    flexGrow: 1,
    "&:nth-child(2)": {
      marginLeft: 16,
      marginRight: 16
    }
  }
}));

function OrganisationDetails() {
  const theme = useTheme();

  const { userOrg, getUserOrg } = useOrgContext();
  const { isMobile, darkMode } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const [orgForm, setOrgForm] = useState<Types.Organisation>({
    id: "",
    name: "",
    subdomain: "",
    logoBase64: "",
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.info.main,
    createdAt: "",
    updatedAt: "",
    __typename: "Organisation"
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dragedOver, setDragedOver] = useState(false);
  const [orgStatus, setOrgStatus] = useState<"none" | "done" | "pending">("none");

  const classes = useStyles({ darkMode: darkMode });
  let subdomainCheck: NodeJS.Timer;

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    check();
    return () => {
      if (subdomainCheck) {
        clearInterval(subdomainCheck);
      }
    };
  }, [orgStatus]);

  const setup = async () => {
    setLoading(true);
    const org = await getUserOrg();
    if (org) {
      setOrgForm(withDefaults(org));
    }
    setLoading(false);
  };

  const check = async () => {
    if (orgStatus !== "done" && userOrg && userOrg.subdomain) {
      setLoading(true);
      const subdomain = userOrg.subdomain;
      const isSubdomainActive = async () => {
        try {
          const url = `https://${subdomain}.${ROOT_DOMAIN}`;
          await fetch(url, {
            method: "HEAD",
            mode: "no-cors",
            cache: "no-cache",
            referrerPolicy: "no-referrer"
          });
          setOrgStatus("done");
          setLoading(false);
          return true;
        } catch (error) {
          setOrgStatus("pending");
        }
        return false;
      };
      if (!(await isSubdomainActive())) {
        subdomainCheck = setInterval(async () => {
          if (await isSubdomainActive()) {
            clearInterval(subdomainCheck);
          }
        }, 5000);
      }
    }
  };

  const withDefaults = (org: Types.Organisation) => {
    return {
      ...org,
      primaryColor: org.primaryColor || theme.palette.primary.main,
      secondaryColor: org.secondaryColor || theme.palette.info.main
    };
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(false);
  };
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setDragedOver(true);
  };

  const stopEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const isButtonDisabled = () => {
    const { name, subdomain, logoBase64 } = orgForm;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    return (
      buttonLoading ||
      orgStatus === "pending" ||
      !name ||
      !subdomain ||
      !logoBase64 ||
      !alphanumericRegex.test(subdomain) ||
      isEqual(orgForm, userOrg!)
    );
  };

  const isEqual = (obj1: Types.Organisation, obj2: Types.Organisation) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleOrg = async () => {
    setButtonLoading(true);
    try {
      if (userOrg) {
        await updateOrg({ ...orgForm, id: userOrg.id });
      } else {
        await createOrg(orgForm);
      }
      await getUserOrg();
      setOrgStatus("pending");
      onSnackbarToShow("orgCreated");
      window.scrollTo({ top: 0, left: 0 });
    } catch (error: any) {
      const message = error?.response?.data?.msg || error?.message;
      onSnackbarToShow("orgCreateFailed", message, "error");
    }
    setButtonLoading(false);
  };

  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as any);
      reader.onerror = reject;
    });

  const handleLogoClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files) return;
    const file: File = e.target.files[0];
    const logoBase64 = await toBase64(file);
    setOrgForm({ ...orgForm, logoBase64: logoBase64 });
  };

  const handleLogoDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    const item = event.dataTransfer.items[0];
    const file = item.getAsFile();
    if (!file) return;
    const logoBase64 = await toBase64(file);
    setOrgForm({ ...orgForm, logoBase64: logoBase64 });
  };

  return (
    <SupportScreenWrapper title="Organisation details">
      <Divider />
      {orgStatus === "pending" ? (
        <Box>
          <Box mb="32px" mt={6} className={classes.announcementBox}>
            <Box display="block" lineHeight="20px">
              <Box alignItems="center" mb={2} display="flex">
                <Typography variant="h3" color="text.primary">
                  Subdomain setup
                </Typography>
                <CircularProgress size={14} sx={{ marginLeft: 4, position: "inherit" }} />
              </Box>
              <Box>
                <Typography variant="body2" className="small" component="span">
                  We are setting up your organisation page at:
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Link
                  variant="body2"
                  className={["strong", "small"].join(" ")}
                  onClick={() => window.open(`https://${orgForm.subdomain}.${ROOT_DOMAIN}`)}
                  color="text.tertiary"
                  sx={{ borderBottomColor: "primary.main" }}
                >
                  https://{orgForm.subdomain}.{ROOT_DOMAIN}
                </Link>
              </Box>
              <Box>
                <Typography variant="body2" className="small" component="span">
                  This can take a few minutes. Your organisation details will be available to edit again after the subdomain setup is
                  complete.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : loading ? (
        <Box>
          <Skeleton variant="rectangular" height={120} sx={{ borderRadius: 0, marginTop: 3, paddingBottom: 1 }} />
          {Array.from(new Array(4)).map((item, index) => (
            <Skeleton key={index} variant="rectangular" height={165} sx={{ borderRadius: 0, marginTop: 6, paddingBottom: 1 }} />
          ))}
        </Box>
      ) : (
        <Box>
          {orgStatus === "done" && (
            <Box mb={6} mt={6}>
              <Box display="block" lineHeight="20px">
                <Box>
                  <Typography variant="body2" className="small" component="span">
                    Your organisation page is available at:
                  </Typography>
                </Box>
                <Box alignItems="center">
                  <Link
                    variant="body2"
                    className={["strong", "small"].join(" ")}
                    onClick={() => window.open(`https://${userOrg?.subdomain}.${ROOT_DOMAIN}`)}
                    color="text.tertiary"
                    sx={{ borderBottomColor: "primary.main" }}
                  >
                    https://{userOrg?.subdomain}.{ROOT_DOMAIN}
                  </Link>
                </Box>
              </Box>
            </Box>
          )}
          <>
            <Box mb={4} mt={6}>
              <FormControl fullWidth>
                <TextField
                  margin="none"
                  // error={!!error}
                  label="Organisation name"
                  variant="outlined"
                  name="name"
                  disabled={buttonLoading}
                  value={orgForm.name}
                  onChange={ev => setOrgForm({ ...orgForm, name: ev.target.value })}
                />
              </FormControl>
            </Box>
            <Box mb={4} mt={6}>
              <Typography variant="h3" component="div">
                Add your subdomain
              </Typography>
              <Box mt={2} display="block" lineHeight="20px">
                <Typography variant="body2" className="small">
                  Add a custom branded domain, eg, mycompany.akord.com
                </Typography>
              </Box>
              <Box mb={4} mt={6}>
                <FormControl fullWidth>
                  <TextField
                    margin="none"
                    // error={!!error}
                    label="Subdomain"
                    variant="outlined"
                    name="subdomain"
                    disabled={buttonLoading}
                    value={orgForm.subdomain}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">.{ROOT_DOMAIN}</InputAdornment>
                    }}
                    onChange={ev => setOrgForm({ ...orgForm, subdomain: ev.target.value?.toLowerCase() })}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box mb={4} mt={2}>
              <Typography variant="h3" component="div">
                Add your logo
              </Typography>
              <Box mt={2} display="block" lineHeight="20px">
                <Typography variant="body2" className="small">
                  Make sure your logo will work against a black background. JPG, PNG or SVG files. Max size 1 MB. Recommended width is
                  250px.
                </Typography>
              </Box>
              {orgForm.logoBase64 ? (
                <Box position="relative" mt={4} pb={2}>
                  {!buttonLoading && (
                    <CloseIcon
                      sx={{ position: "absolute", zIndex: 1, top: 0, right: 0, cursor: "pointer" }}
                      onClick={() => setOrgForm({ ...orgForm, logoBase64: "" })}
                    />
                  )}
                  <img style={{ height: "100%", maxHeight: "100px" }} src={orgForm.logoBase64} alt="logo" />
                </Box>
              ) : (
                <div
                  onDrop={handleLogoDrop}
                  onDragLeave={onDragLeave}
                  onDragOver={onDragOver}
                  style={{
                    height: "100%",
                    overflowY: "auto"
                  }}
                  className="dropzone"
                >
                  <Box
                    component="div"
                    mb={2}
                    mt={4}
                    className={classes.uploadBox}
                    sx={{ backgroundColor: dragedOver ? "rgb(29, 39, 114, 0.5)" : "background.card" }}
                  >
                    <Typography variant="body2" className="small" color="text.tertiary">
                      Drag and drop your logo here
                    </Typography>
                    <label>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={event => {
                          handleLogoClick(event);
                        }}
                      />
                      <Link variant="body2" className="small" color="text.tertiary" sx={{ borderBottomColor: "text.tertiary" }}>
                        or select a file
                      </Link>
                    </label>
                  </Box>
                </div>
              )}
            </Box>
            <Box mb={4} mt={6}>
              <Typography variant="h3" component="div">
                Primary color
              </Typography>
              <Box mt={2} display="block" lineHeight="18.5px">
                <Typography variant="body2" className="small">
                  Enter the hex code of your primary brand colour. This color is used on buttons and for the icons of private vaults.
                </Typography>
              </Box>
              <Box mb={2} mt={2}>
                <MuiColorInput
                  disabled={buttonLoading}
                  format="hex"
                  value={orgForm.primaryColor!}
                  onChange={value => setOrgForm({ ...orgForm, primaryColor: value })}
                />
              </Box>
            </Box>
            <Box mb={4} mt={2}>
              <Typography variant="h3" component="div">
                Secondary color
              </Typography>
              <Box mt={2} display="block" lineHeight="18.5px">
                <Typography variant="body2" className="small">
                  Enter the hex code of your secondary brand color. This colour is used for the icons of public vaults.
                </Typography>
              </Box>
              <Box mt={2}>
                <MuiColorInput
                  disabled={buttonLoading}
                  format="hex"
                  value={orgForm.secondaryColor!}
                  onChange={value => setOrgForm({ ...orgForm, secondaryColor: value })}
                />
              </Box>
            </Box>
            <Box mb={7}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                disableElevation
                classes={{
                  containedPrimary: classes.containedPrimary
                }}
                disabled={isButtonDisabled()}
                fullWidth={isMobile}
                onClick={handleOrg}
              >
                Save changes
                {buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
            </Box>
          </>
        </Box>
      )}
    </SupportScreenWrapper>
  );
}

export default withRouter(OrganisationDetails);
