import { useState, useEffect } from "react";

export type FormModelType =
  | "fileRename"
  | "folderCreate"
  | "dataRoomRename"
  | "folderRename"
  | "shareFile"
  | "shareVault"
  | "importArweave"
  | "sendToken"
  | "shareNft";

export type ModalType =
  | "archive"
  | "batchDelete"
  | "batchMove"
  | "batchRemove"
  | "batchRemoveVaults"
  | "batchRestore"
  | "batchRestoreVaults"
  | "batchRevoke"
  | "cancelCurrentPlan"
  | "confirmUpload"
  | "createManifest"
  | "delete"
  | "deleteAccount"
  | "deletedFileFromTimeline"
  | "deleteFile"
  | "disable2FA"
  | "duplicateFile"
  | "fileMove"
  | "folderDelete"
  | "folderMove"
  | "folderRestore"
  | "folderRevoke"
  | "leave"
  | "membersUpgradeTier"
  | "noteMove"
  | "orgRevoke"
  | "removeFile"
  | "removeNote"
  | "restore"
  | "restoreFile"
  | "restoreFileFromTimeline"
  | "restoreFileWithMove"
  | "restoreFolderWithMove"
  | "restoreNote"
  | "restoreStack"
  | "revoke"
  | "revokeFile"
  | "revokeNote"
  | "revokeStack"
  | "saveNote"
  | "topUpsUpgradeTier"
  | "vaultsUpgradeTier"
  | "removedFileFromTimeline"
  | "confirmUploadRetry";

export type AllModals = { [Type in ModalType | FormModelType]: boolean };

export type ModalTypeKeys = ModalType | FormModelType;

const useModal = (initialValue = false) => {
  const initialState: AllModals = {
    archive: initialValue,
    batchDelete: initialValue,
    batchMove: initialValue,
    batchRemove: initialValue,
    batchRemoveVaults: initialValue,
    batchRestore: initialValue,
    batchRestoreVaults: initialValue,
    batchRevoke: initialValue,
    cancelCurrentPlan: initialValue,
    confirmUpload: initialValue,
    createManifest: initialValue,
    dataRoomRename: initialValue,
    delete: initialValue,
    deleteAccount: initialValue,
    deletedFileFromTimeline: initialValue,
    deleteFile: initialValue,
    disable2FA: initialValue,
    duplicateFile: initialValue,
    fileMove: initialValue,
    fileRename: initialValue,
    folderCreate: initialValue,
    folderDelete: initialValue,
    folderMove: initialValue,
    folderRename: initialValue,
    folderRestore: initialValue,
    folderRevoke: initialValue,
    leave: initialValue,
    membersUpgradeTier: initialValue,
    noteMove: initialValue,
    orgRevoke: initialValue,
    removeFile: initialValue,
    removeNote: initialValue,
    restore: initialValue,
    restoreFile: initialValue,
    restoreFileFromTimeline: initialValue,
    restoreFileWithMove: initialValue,
    restoreFolderWithMove: initialValue,
    restoreNote: initialValue,
    restoreStack: initialValue,
    revoke: initialValue,
    revokeFile: initialValue,
    revokeNote: initialValue,
    revokeStack: initialValue,
    saveNote: initialValue,
    shareFile: initialValue,
    shareVault: initialValue,
    shareNft: initialValue,
    removedFileFromTimeline: initialValue,
    topUpsUpgradeTier: initialValue,
    vaultsUpgradeTier: initialValue,
    importArweave: initialValue,
    sendToken: initialValue,
    confirmUploadRetry: initialValue
  };

  const [confirmModalFormType, setConfirmModalFormType] = useState<ModalTypeKeys>();
  const onConfirmModalFormType = (type: ModalTypeKeys) => setConfirmModalFormType(type);

  const [confirmModalType, setConfirmModalType] = useState<ModalTypeKeys | null>(null);
  const onConfirmModalType = (type: ModalTypeKeys | null) => setConfirmModalType(type);

  const [isModalVisible, setModalVisible] = useState(initialState);

  useEffect(() => {
    return () => {
      onConfirmModalType(null);
      setModalVisible(initialState);
    };
  }, []);

  const onModalVisibility = (valueForType = null, type: ModalTypeKeys) => {
    setModalVisible(prevState => {
      return {
        ...prevState,
        [type]: valueForType ? valueForType : !isModalVisible[type]
      };
    });
  };

  return [isModalVisible, onModalVisibility, confirmModalType, onConfirmModalType, confirmModalFormType, onConfirmModalFormType] as const;
};

export default useModal;
