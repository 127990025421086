import axios from "axios";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { GOOGLE_API_CLIENT_ID, GOOGLE_API_DEVELOPER_KEY } from "../../helpers/env";
import { useEffect, useState } from "react";
import { useProgressContext } from "../../contexts/ProgressContext";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";

const SCOPES = "https://www.googleapis.com/auth/drive";
const APP_ID = "akord-374920";
const FILES_URL = "https://www.googleapis.com/drive/v3/files";
const GOOGLE_APIS_SCRIPT = "https://apis.google.com/js/client.js";
const GOOGLE_ACCOUNTS_SCRIPT = "https://accounts.google.com/gsi/client";

export const useGDriveImporter = () => {
  const { darkMode } = useGlobalContext();
  const { vault } = useVaultContext();
  const { onDrawerActionType, onShowLoaderByVaultId, showLoaderByVaultId, onUploadCancelHook, onFilesNumber, onAxiosError } =
    useAssetsContext();
  const { onUploadFiles } = useNotificationsContext();
  const { onProgress } = useProgressContext();
  const [clientLoaded, setClientLoaded] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);
  const [authToken, setAuthToken] = useState();

  const { gapi, google } = window;

  useEffect(() => {
    if (clientLoaded && authLoaded) {
      loadToken();
    }
  }, [clientLoaded, authLoaded]);

  useEffect(() => {
    if (authToken) {
      createPicker();
    }
  }, [authToken]);

  const createPicker = () => {
    if (authToken) {
      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(APP_ID)
        .setOAuthToken(authToken)
        .addView(google.picker.ViewId.DOCS)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(GOOGLE_API_DEVELOPER_KEY)
        .setCallback(onDocsPicked)
        // .setSize(566, 350)
        .build();
      picker.setVisible(true);
      themePicker();
    }
  };

  const themePicker = () => {
    const elements = document.getElementsByClassName("picker-dialog-bg");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = darkMode ? "rgba(49, 47, 55, .8)" : "rgba(34, 33, 38, .5)";
    }
  };

  const loadToken = async () => {
    await new Promise(resolve => {
      gapi.load("client:auth2:picker", resolve);
    });

    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_API_CLIENT_ID,
      scope: SCOPES,
      callback: ""
    });

    tokenClient.callback = async response => {
      if (response.error) {
        throw response;
      }

      setAuthToken(response.access_token);
    };

    if (authToken === null) {
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      tokenClient.requestAccessToken({ prompt: "" });
    }
  };

  const onDocsPicked = async data => {
    if (data && data.docs) {
      await new Promise(resolve => {
        gapi.load("client", resolve);
      });
      await new Promise(resolve => {
        gapi.client.load("drive", "v3", resolve);
      });

      const config = {
        method: "get",
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        responseType: "blob"
      };

      const totalSize = data.docs.reduce((acc, doc) => acc + doc.sizeBytes, 0);
      const cancel = new AbortController();

      onUploadCancelHook(cancel);
      onDrawerActionType("import");
      onFilesNumber(data.docs.length);
      try {
        const files = [];
        for (const doc of data.docs) {
          if (doc.type === "document" || doc.type === "spreadsheet" || doc.type === "presentation") {
            const blob = (
              await axios({
                ...config,
                url: `${FILES_URL}/${doc.id}/export?mimeType=${encodeURIComponent("application/pdf")}`
              })
            ).data;
            files.push(new File([blob], doc.name, { type: "application/pdf" }));
          } else {
            onShowLoaderByVaultId(vault.id);
            const blob = (
              await axios({
                ...config,
                url: `${FILES_URL}/${doc.id}?alt=media`,
                signal: cancel.signal,
                onDownloadProgress(progressEvent) {
                  onProgress(Math.round((progressEvent.loaded / totalSize) * 100));
                }
              })
            ).data;
            files.push(new File([blob], doc.name, { type: doc.mimeType }));
          }
        }
        onDrawerActionType("upload");
        onUploadFiles(files);
      } catch (e) {
        if (cancel.signal.aborted) {
          onCancel();
        } else {
          console.error(e);
          onError();
        }
      }

      onUploadCancelHook(null);
      if (showLoaderByVaultId.includes(vault.id)) onShowLoaderByVaultId(vault.id);
      onProgress(0);
      onDrawerActionType();
    }
  };

  const onError = () => {
    onAxiosError(`Failed to import files`);
    setTimeout(() => {
      onAxiosError();
    }, 3000);
  };

  const onCancel = () => {
    onAxiosError(`Importing cancelled`);
    setTimeout(() => {
      onAxiosError();
    }, 3000);
  };

  const importFromGDrive = async () => {
    if (!clientLoaded) {
      const googleClientScript = document.createElement("script");
      googleClientScript.src = GOOGLE_APIS_SCRIPT;
      googleClientScript.onload = () => {
        setClientLoaded(true);
      };
      document.body.appendChild(googleClientScript);
    }

    if (!authLoaded) {
      const googleAccountsScript = document.createElement("script");
      googleAccountsScript.src = GOOGLE_ACCOUNTS_SCRIPT;
      googleAccountsScript.onload = () => {
        setAuthLoaded(true);
      };
      document.body.appendChild(googleAccountsScript);
    }
    if (clientLoaded && authLoaded) {
      loadToken();
    }
  };

  return { importFromGDrive };
};
