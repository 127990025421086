import React from "react";
import { Box, ClassNameMap, IconButton, Tooltip } from "@mui/material";
import { DownloadIcon, MoveIcon, PasswordMaskIcon } from "@akord/addon-icons";
import { Stack, Folder } from "@akord/akord-js";
import { bytesToGb } from "../../../pages/storage/storage-helper";
import { SIZE_LIMIT_DOWNLOAD } from "../../../helpers/akord-enums";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { SelectedItem } from "../../../contexts/AssetsContextProvider";

type ActiveStackToolbarProps = {
  classes: ClassNameMap<string>;
  selectedItemsMap: Map<string, SelectedItem>;
  folderScopeStacks: (Stack | Folder)[];
  folderId: string | null;
  handleClick: (name: string) => Promise<void>;
  isPublicRoute: boolean;
};

const ActiveStackToolbar: React.FC<ActiveStackToolbarProps> = ({
  classes,
  selectedItemsMap,
  folderScopeStacks,
  folderId,
  handleClick,
  isPublicRoute
}) => {
  const { readOnlyMode } = useGlobalContext();
  const values = Array.from(selectedItemsMap?.values());
  const overallSize = values?.reduce((prev, curr) => prev + curr.size, 0);
  const sizeLimitInGb = bytesToGb(SIZE_LIMIT_DOWNLOAD);
  const isDownloadOversized = overallSize >= SIZE_LIMIT_DOWNLOAD;

  return (
    <>
      <Box className={classes.icons}>
        <Tooltip
          title={isDownloadOversized ? `Batch downloads cannot exceed ${sizeLimitInGb.size} ${sizeLimitInGb.sizeType}` : "Download"}
          arrow
        >
          <span>
            <IconButton
              onClick={() => handleClick("download")}
              disabled={selectedItemsMap.size === 0 || isDownloadOversized || readOnlyMode}
              className={classes.disabledButton}
            >
              <DownloadIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box className={classes.icons}>
        <Tooltip title="Move" arrow>
          <span>
            <IconButton
              onClick={() => handleClick("move")}
              disabled={
                selectedItemsMap.size === 0 ||
                (selectedItemsMap.size === folderScopeStacks.length && !folderId) ||
                isPublicRoute ||
                readOnlyMode
                // disable if we are in the root and all items are selected - nowhere to move
              }
              className={classes.disabledButton}
            >
              <MoveIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Hide" arrow>
          <span>
            <IconButton
              onClick={() => handleClick("revoke")}
              disabled={selectedItemsMap.size === 0 || isPublicRoute || readOnlyMode}
              className={classes.disabledButton}
            >
              <PasswordMaskIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </>
  );
};

export default ActiveStackToolbar;
