import { useState, useCallback } from "react";
import { Collection, NFT } from "@akord/akord-js";
import { NumberChangeEvent } from "../contexts/AssetsContextProvider";

export const usePagination = (items: (Collection | NFT)[] | undefined, pageLimit: number) => {
  const [pageNumber, setPageNumber] = useState(1);

  const pageCount = items?.length ? Math.ceil(items?.length / pageLimit) : null;

  const changePage = (event: NumberChangeEvent) => {
    const page = typeof event === "number" ? event : event.target.value;
    setPageNumber(Number(page));
  };
  const pageData = useCallback(() => {
    const start = pageNumber * pageLimit - pageLimit;
    const end = start + pageLimit;
    return items?.slice(start, end);
  }, [items, pageNumber, pageLimit]);

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    setPageNumber(pageNumber - 1);
  };

  return {
    pageNumber,
    pageCount,
    changePage,
    pageData,
    nextPage,
    previousPage
  };
};
