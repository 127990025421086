export const documentAction = {
  PUT: "PUT",
  REMOVE: "REMOVE"
};

export const fromStatus = (status: string) => {
  switch (status) {
    case "DELETED":
      return documentAction.REMOVE;
    case "ACTIVE":
      return documentAction.PUT;
    default:
      return documentAction.PUT;
  }
};
