import React, { useEffect, useState, useRef } from "react";
import { Fab, Zoom } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowDownIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { filterTimeline } from "../../helpers/stack-helpers";
import ShowTimeline from "./ShowTimeline";
import { useTimelineContext } from "../../contexts/TimelineContextProvider";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";

const useStyles = makeStyles({
  fabIcon: {
    fontSize: "30px"
  },
  fabUp: {
    position: "fixed",
    right: "16px",
    bottom: "24px"
  }
});

function displayUserVerified(item, members) {
  const membershipResults = members.filter(
    membership => membership.id === item.modelId
  );
  return membershipResults[0] && membershipResults[0].status === "INVITED";
}

const timelineItemsLimit = 25;

function TimelineWrapper({ location, isFiltered }) {
  const [timelineItemsNumber, setTimelineItemsNumber] =
    useState(timelineItemsLimit);
  const handleCurrentPage = number => setTimelineItemsNumber(number);

  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleShowScrollButton = value => setShowScrollButton(value);

  const { decryptedTimeline } = useTimelineContext();
  const { vault, members, userRole } = useVaultContext();
  const { actionFilters, periodFilter } = useAssetsContext();

  const classes = useStyles();
  const history = useHistory();

  const lastItemRef = useRef(null);
  const timelineRef = useRef(null);
  const timelineEndRef = useRef(null);

  const onScroll = () => {
    const scrollTop = timelineRef.current.scrollTop;
    const scrollHeight = timelineRef.current.scrollHeight;
    const clientHeight = timelineRef.current.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      return;
    } else if (scrollTop + clientHeight < scrollHeight) {
      handleShowScrollButton(true);
    } else {
      handleShowScrollButton(false);
    }
  };

  const scrollToBottom = () => {
    timelineEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const timelineItems =
    decryptedTimeline.length > 0
      ? filterTimeline(decryptedTimeline, periodFilter, actionFilters).filter(
          item =>
            userRole === "OWNER"
              ? item.actionRef === "USER_VERIFIED" &&
                displayUserVerified(item, members)
                ? true
                : item.actionRef !== "USER_VERIFIED"
              : item.actionRef !== "USER_VERIFIED"
        )
      : [];

  const loadMoreTimelineItems = amountToLoad => {
    const nextLimitNumber =
      timelineItemsNumber + timelineItemsLimit < amountToLoad
        ? amountToLoad
        : timelineItemsNumber + timelineItemsLimit;
    handleCurrentPage(nextLimitNumber);
  };

  useEffect(() => {
    lastItemRef?.current?.scrollIntoView();
    timelineRef.current.scrollTop -= 132;
  }, [timelineItemsNumber]);

  useEffect(() => {
    setTimelineItemsNumber(timelineItemsLimit);
  }, [location.pathname]);

  useEffect(() => {
    if (vault?.status === "DELETED") {
      history.push("/vaults/active");
    }
  }, [vault]);

  return (
    <>
      <div
        ref={timelineRef}
        onScroll={onScroll}
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          paddingTop: "24px",
          position: "absolute"
        }}
      >
        <ShowTimeline
          isFiltered={isFiltered}
          timelineItems={timelineItems}
          timelineEndRef={timelineEndRef}
          scrollToBottom={scrollToBottom}
          lastItemRef={lastItemRef}
          loadMoreTimelineItems={loadMoreTimelineItems}
          timelineItemsNumber={timelineItemsNumber}
          timelineItemsLimit={timelineItemsLimit}
        />
      </div>
      <Zoom in={showScrollButton} style={{ transitionDelay: "100ms" }}>
        <Fab
          size="small"
          color="secondary"
          className={classes.fabUp}
          onClick={scrollToBottom}
        >
          <ArrowDownIcon className={classes.fabIcon} />
        </Fab>
      </Zoom>
    </>
  );
}

export default withRouter(TimelineWrapper);
