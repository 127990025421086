import React, { useContext } from 'react'
const ProgressContext = React.createContext()

function ProgressContextProvider({ children }) {
  const [progress, setProgress] = React.useState(0);
  const handleProgress = progress => setProgress(progress);

  return (
    <ProgressContext.Provider
      value={{ progress: progress, onProgress: handleProgress }}
    >
      {children}
    </ProgressContext.Provider>
  )
}
export default ProgressContextProvider

export const useProgressContext = () => useContext(ProgressContext)
