import React from "react";
import { CloseIcon, OpenIcon } from "@akord/addon-icons";
import {
  Box,
  IconButton,
  List,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import { infoModalData } from "./infoModalData";

type InfoModalProps = {
  openInfoModal: boolean;
  handleInfoModalClose: () => void;
  infoModalType: InfoModalTypes;
};

export type InfoModalTypes =
  | "derivations"
  | "commercialUse"
  | "currency"
  | "duration"
  | "paymentAddress"
  | "paymentMode"
  | "accessFee"
  | "unknownUsageRights"
  | "dataModelTrainings";

const InfoModal: React.FC<InfoModalProps> = ({
  openInfoModal,
  handleInfoModalClose,
  infoModalType,
}) => {
  if (!infoModalType) return null;

  const modalData = infoModalData[infoModalType];

  const { modalTitle, modalDescription, modalListTexts, linkedData } =
    modalData;
  return (
    <Modal
      open={openInfoModal}
      onClose={handleInfoModalClose}
      aria-labelledby="modal-info"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "primary.iconInvert",
          borderRadius: "8px",
          p: 6,
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography id="modal-modal-title" variant="h3" sx={{ mb: 2 }}>
            {modalTitle}
          </Typography>
          <IconButton onClick={handleInfoModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography id="modal-modal-description" variant="caption">
          {modalDescription}
        </Typography>
        {modalListTexts && (
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "outside",
              ml: 5,
              pt: 4,
              lineHeight: "18px",
            }}
          >
            {modalListTexts.map((listText, index) => {
              return (
                <ListItem
                  sx={{ display: "list-item", p: 0, pl: 2, pb: 2 }}
                  key={index}
                >
                  {listText.boldText && (
                    <Typography variant="caption" className="strong">
                      {listText.boldText}{" "}
                    </Typography>
                  )}
                  <Typography variant="caption">{listText.listText}</Typography>
                </ListItem>
              );
            })}
          </List>
        )}
        {linkedData && (
          <IconButton
            onClick={() => window.open(linkedData.linkedUrl)}
            sx={{ pt: 2 }}
          >
            <Typography
              component="a"
              variant="caption"
              color="text.tertiary"
              sx={{
                borderBottom: "1px solid",
                borderBottomColor: "text.secondary",
                "&:hover": {
                  borderBottomColor: "text.primary",
                  color: "text.primary",
                },
              }}
            >
              {linkedData.linkedText}
            </Typography>
            <OpenIcon fontSize="small" sx={{ ml: 2 }} />
          </IconButton>
        )}
      </Box>
    </Modal>
  );
};

export default InfoModal;
