import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, IconButton, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { ArweaveIcon, CloseInCircleIcon, GlobeIcon, PadlockIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { Theme } from "@mui/material/styles";
import { arweaveFacts } from "../../../helpers/akordTexts";

type ProgressBarProps = {
  vaultId: string;
  fromSearch?: boolean;
};

type Timer = ReturnType<typeof setTimeout>;

const useStyles = makeStyles<Theme>(theme => ({
  titleBox: {
    display: "flex",
    flexDirection: "row"
  },
  progressBox: {
    background: theme.palette.background.card,
    borderRadius: "4px"
  },
  root: {
    width: "100%"
  }
}));

const ProgressBar: React.FC<ProgressBarProps> = ({ vaultId, fromSearch = false }) => {
  const [useIndeterminate, setUseIndeterminate] = useState(false);

  const { darkMode } = useGlobalContext();
  const { progress } = useProgressContext();
  const { drawerActionType, filesNumber, uploadedFilesNumber, uploadCancelHook, showLoaderByVaultId } = useAssetsContext();
  const { isVaultPublic, isCloudStorage } = useVaultContext();

  const delay = useRef<Timer>();
  const classes = useStyles({ darkMode: darkMode });

  useEffect(() => {
    if (useIndeterminate && progress === 0) {
      setUseIndeterminate(false);
    } else if (!useIndeterminate && progress === 100 && (uploadedFilesNumber === filesNumber || !uploadedFilesNumber)) {
      delay.current = setTimeout(() => {
        setUseIndeterminate(true);
      }, 1000);
    }
    return () => clearTimeout(delay.current);
  }, [progress, useIndeterminate, uploadedFilesNumber, filesNumber]);

  const diff = Math.random() * 10;

  const arweaveRandomFact = React.useMemo(() => {
    if (uploadedFilesNumber) return arweaveFacts[Math.floor(Math.random() * arweaveFacts.length)];
  }, [uploadedFilesNumber]);

  const uploadCountStatus = () => {
    if (uploadedFilesNumber) {
      return `${uploadedFilesNumber} / ${filesNumber}`;
    }
    return filesNumber;
  };

  const uploadText =
    filesNumber > 1
      ? isVaultPublic
        ? `${uploadCountStatus()} files are uploading...`
        : `${uploadCountStatus()} files are encrypting and uploading...`
      : isVaultPublic
      ? `1 file is uploading...`
      : `1 file is encrypting and uploading...`;

  const postUploadText = isCloudStorage
    ? "Finalising your upload..."
    : filesNumber > 1
    ? "Preparing blockchain transactions... "
    : "Preparing blockchain transaction... ";

  const downloadText = isVaultPublic ? "Your file is downloading..." : "Your file is decrypting and downloading...";

  const signingText = "Preparing the file...";

  const importText = filesNumber > 1 ? "Preparing the files..." : "Preparing the file...";

  const getProgressText = () => {
    if (drawerActionType && drawerActionType.includes("upload")) {
      if (useIndeterminate) {
        return postUploadText;
      }
      return uploadText;
    } else if (drawerActionType && drawerActionType.includes("download")) {
      return downloadText;
    } else if (drawerActionType && drawerActionType.includes("sign")) {
      return signingText;
    } else if (drawerActionType && drawerActionType.includes("import")) {
      return importText;
    }
    return postUploadText;
  };

  if (!showLoaderByVaultId?.includes(vaultId) && !fromSearch) return null;

  return (
    <Box mt={4} mb={5} mx={5} className={classes.progressBox}>
      <Box p={2}>
        <Box display="flex" sx={{ justifyContent: useIndeterminate ? "flex-start" : "space-between" }}>
          <Box display="flex" alignContent="center">
            {!useIndeterminate ? (
              isVaultPublic ? (
                <GlobeIcon
                  sx={{
                    marginRight: 2,
                    fontSize: "16px"
                  }}
                />
              ) : (
                <PadlockIcon
                  sx={{
                    marginRight: 2,
                    fontSize: "16px"
                  }}
                />
              )
            ) : (
              <Box mr={2} lineHeight="normal">
                <CircularProgress size={16} sx={{ position: "relative" }} />
              </Box>
            )}
            <Typography variant="caption" component="div">
              {getProgressText()}
            </Typography>
            {useIndeterminate && !isCloudStorage && (
              <>
                <ArweaveIcon
                  sx={{
                    marginTop: "2px",
                    marginRight: 2,
                    marginLeft: 2,
                    fontSize: "16px"
                  }}
                />
                <Typography variant="caption" component="div">
                  {arweaveRandomFact}
                </Typography>
              </>
            )}
          </Box>
          {!useIndeterminate && (
            <IconButton onClick={() => uploadCancelHook?.abort()} size="large">
              <CloseInCircleIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        <Box display="flex" alignItems="baseline">
          {!useIndeterminate && (
            <>
              <Box width="100%" mr={1}>
                <LinearProgress color="primary" variant="buffer" value={progress} valueBuffer={progress + diff} />
              </Box>
              <Box>
                <Typography variant="caption" className="small" color="text.secondary" align="right">
                  {progress}%
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBar;
