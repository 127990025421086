import React, { useState, useEffect, createContext, useContext } from "react";
import { GraphQLAPI } from "@aws-amplify/api-graphql";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "./GlobalDataProvider";
import { useSnackbarContext } from "./SnackbarContextProvider";
import { getStorage, getStorageTransactions, refreshStorage } from "../helpers/api-helpers";
import { subscriptions, Types } from "@akord/gql";
import { ZenObservable } from "zen-observable-ts";
import { GraphQLSubscription } from "@aws-amplify/api";
import { filterTransactions, PeriodFilterKeys } from "../helpers/stack-helpers";

export type StorageTransaction = Types.StorageTransaction & { __keys__: Types.CryptoKeys[] };

type StorageContextProps = {
  transactions?: StorageTransaction[];
  transactionNextToken?: string | null;
  globalStorage?: Types.Storage;
  onTransactions: (transactions?: StorageTransaction[]) => void;
  onTransactionNextToken: (token: string) => void;
  unprocessedStorageTransaction?: StorageTransaction;
  refreshStorage: () => Promise<void>;
  loading: boolean;
  onShowTransactionsFilter: (show: boolean) => void;
  showTransactionsFilter: boolean;
  actionStorageFilters: string[];
  onActionStorageFilters: (filters: string[]) => void;
  periodStorageFilter: PeriodFilterKeys | null;
  onPeriodStorageFilter: (filters: PeriodFilterKeys | null) => void;
  txRewards: Map<string, boolean>;
  onTxRewards: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
};

const Context = createContext({} as StorageContextProps);

const StorageContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [transactions, setTransactions] = useState<StorageTransaction[]>();
  const handleTransactions = (transactions?: StorageTransaction[]) => setTransactions(transactions);

  const transactionLogsRef = React.useRef<StorageTransaction[]>();

  const [unprocessedStorageTransaction, setUnprocessedStorageTransaction] = useState<StorageTransaction>();
  const handleUnprocessedStorageTransaction = (tx: StorageTransaction) => setUnprocessedStorageTransaction(tx);

  const [transactionNextToken, setTransactionNextToken] = useState<string | null>();
  const handleTransactionNextToken = (token?: string | null) => setTransactionNextToken(token);

  const [globalStorage, setGlobalStorage] = useState<Types.Storage>();
  const handleGlobalStorage = (storage: Types.Storage) => setGlobalStorage(storage);

  const [loading, setLoading] = useState(false);

  const [showTransactionsFilter, setShowTransactionsFilter] = React.useState(false);
  const handleShowTransactionsFilter = (show: boolean) => setShowTransactionsFilter(show);

  const [actionStorageFilters, setActionStorageFilters] = useState<string[]>([]);
  const handleActionStorageFilters = (filters: string[]) => setActionStorageFilters(filters);

  const [periodStorageFilter, setPeriodStorageFilter] = useState<PeriodFilterKeys | null>(null);
  const handlePeriodStorageFilter = (filters: PeriodFilterKeys | null) => setPeriodStorageFilter(filters);

  const [txRewards, setTxRewards] = useState<Map<string, boolean>>(new Map());

  const location = useLocation();
  const { wallet, userAttributes, profileDetails } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const storageTransactions = React.useCallback(async (limit: number, nextToken?: string) => {
    try {
      const storageTransactions = await getStorageTransactions({ limit, nextToken });
      if (!storageTransactions) return;
      const { items: transactions, nextToken: transactionNextToken } = storageTransactions;

      transactionLogsRef.current = transactions as StorageTransaction[];
      handleTransactionNextToken(transactionNextToken);
      handleTransactions(transactions as StorageTransaction[]);
    } catch (err) {
      console.error("Error fetching transactions: ", err);
    }
  }, []);

  const loadStorage = React.useCallback(async () => {
    setLoading(true);
    try {
      const storage = await getStorage();
      if (!storage) {
        console.error("ERROR: user has no storage assigned");
        return;
      }
      handleGlobalStorage(storage);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, []);

  const refreshStorageBalance = async () => {
    setLoading(true);
    let address: string;
    if (profileDetails?.orgId) {
      address = (userAttributes.storageAddress || userAttributes.address)!;
    } else {
      address = userAttributes.address!;
    }
    try {
      await refreshStorage(address);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location.pathname === "/storage") {
      // Reload storage when on storage page
      loadStorage();
      // Fetch 5 items on initial call
      storageTransactions(20);
    }
    return () => {
      // Clear all transactions on unmount
      handleTransactions();
    };
  }, [location.pathname, loadStorage, storageTransactions]);

  /**
   * Debounced refresh of tx table when a new transaction is added
   */
  useEffect(() => {
    const debouncedRefresh = setTimeout(() => {
      if (location.pathname === "/storage") {
        storageTransactions(transactionLogsRef.current?.length || 0 + 1);
      }
    }, 2500);
    return () => clearTimeout(debouncedRefresh);
  }, [unprocessedStorageTransaction, transactionLogsRef, location.pathname, storageTransactions]);

  useEffect(() => {
    if (wallet) {
      loadStorage();
    }
  }, [wallet, loadStorage]);

  //Subscription Transactions
  useEffect(() => {
    let createTransactionSub: ZenObservable.Subscription;
    let updateTransactionSub: ZenObservable.Subscription;
    let updateStorageSub: ZenObservable.Subscription;
    let updateUtoken: ZenObservable.Subscription;
    const setupSubscription = async () => {
      let address: string;
      if (profileDetails?.orgId) {
        address = (userAttributes.storageAddress || userAttributes.address)!;
      } else {
        address = userAttributes.address!;
      }
      try {
        createTransactionSub = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onCreateStorageTransaction,
          variables: {
            filter: {
              owner: { eq: address }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: any) => {
            const newTransaction: StorageTransaction = inlineMemberKeys(value.data.onCreateStorageTransaction);
            handleUnprocessedStorageTransaction(newTransaction);
          },
          error: () => {
            console.warn("err");
          }
        });

        updateTransactionSub = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onUpdateStorageTransaction,
          variables: {
            filter: {
              owner: { eq: address }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: any) => {
            let updatedTransaction: StorageTransaction = inlineMemberKeys(value.data.onUpdateStorageTransaction);
            handleUnprocessedStorageTransaction(updatedTransaction);
            if (updatedTransaction.status === "REJECTED") {
              onSnackbarToShow("uploadFailed", updatedTransaction.txId);
            }

            // Update a transaction if we have any fetched (are on the storage page)
            // Add memberships, notes and stacks from originally fetched (not getting it from the subscription)
            if (transactionLogsRef?.current && transactionLogsRef?.current?.length > 0) {
              const updatedObjectIndex = transactionLogsRef.current?.findIndex(transaction => transaction?.id === updatedTransaction.id);
              if (updatedObjectIndex) {
                transactionLogsRef.current[updatedObjectIndex] = updatedTransaction;
                handleTransactions([...transactionLogsRef.current]);
              }
            }
          },
          error: () => {
            console.warn("err");
          }
        });

        updateStorageSub = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onUpdateStorage,
          variables: {
            filter: {
              address: { eq: address }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: any) => {
            const storage: Types.Storage = value.data.onUpdateStorage;
            handleGlobalStorage(storage);
            setLoading(false);
          },
          error: () => {
            console.warn("err");
          }
        });
        // Subscription to track when reward was added to a wallet
        // Show an orange dot
        updateUtoken = await GraphQLAPI.graphql<GraphQLSubscription<{ query: any; variables: any }>>({
          authMode: "AWS_LAMBDA",
          authToken: "custom",
          query: subscriptions.onUpdateUtoken,
          variables: {
            filter: {
              address: { eq: address }
            }
          }
          // @ts-ignore
        }).subscribe({
          next: async ({ value }: any) => {
            const uToken: Types.Utoken = value.data.onUpdateUtoken;
            if (
              uToken.balance &&
              !txRewards.has(uToken.balance.toString()) &&
              uToken.balance > 100 // Only if a reward exists
            ) {
              txRewards.set(uToken.balance.toString(), true);
              setTxRewards(txRewards);
            }
          },
          error: () => {
            console.warn("err");
          }
        });
      } catch (err) {
        console.warn("Subscription error: ", err);
      }
    };
    if (window.navigator.onLine && userAttributes && userAttributes.storageAddress) setupSubscription();
    return () => {
      if (createTransactionSub) createTransactionSub.unsubscribe();
      if (updateTransactionSub) updateTransactionSub.unsubscribe();
      if (updateStorageSub) updateStorageSub.unsubscribe();
      if (updateUtoken) updateStorageSub.unsubscribe();
    };
  }, [userAttributes, profileDetails]);

  const filteredSortedTransactions = React.useMemo(
    () => (transactions ? filterTransactions(transactions, periodStorageFilter, actionStorageFilters) : undefined),
    [transactions, actionStorageFilters, periodStorageFilter]
  );

  const inlineMemberKeys = (storageTransaction: StorageTransaction): StorageTransaction => {
    const member = storageTransaction?.members?.items?.find(member => member?.address === userAttributes?.address);
    return { ...storageTransaction, __keys__: member?.keys as Types.CryptoKeys[] };
  };

  return (
    <Context.Provider
      value={{
        transactions: filteredSortedTransactions,
        transactionNextToken: transactionNextToken,
        globalStorage: globalStorage,
        onTransactions: handleTransactions,
        onTransactionNextToken: handleTransactionNextToken,
        unprocessedStorageTransaction: unprocessedStorageTransaction,
        refreshStorage: refreshStorageBalance,
        loading: loading,
        showTransactionsFilter: showTransactionsFilter,
        onShowTransactionsFilter: handleShowTransactionsFilter,
        onActionStorageFilters: handleActionStorageFilters,
        actionStorageFilters: actionStorageFilters,
        periodStorageFilter: periodStorageFilter,
        onPeriodStorageFilter: handlePeriodStorageFilter,
        txRewards: txRewards,
        onTxRewards: setTxRewards
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default StorageContextProvider;

export const withStorageContext = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{storageContext => <Component {...props} {...storageContext} />}</Context.Consumer>;

export const useStorageContext = () => useContext(Context);
