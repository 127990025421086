import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import CreateVaultStorageStep from "../../pages/vault-options/CreateVaultStorageStep";
import CreateVaultPrivacyStep from "../../pages/vault-options/CreateVaultPrivacyStep";
import CreateVaultForm from "../../pages/vault-options/CreateVaultForm";
import VaultCreateContextProvider from "../../contexts/VaultCreateContextProvider";
import NewVaultCreateFlow from "../common/EmptySpace/NewVaultCreateFlow";

function VaultCreateRouter() {
  return (
    <VaultCreateContextProvider>
      <Switch>
        <Route path="/create-vault" exact render={() => <CreateVaultForm />} />
        <Route path="/create-vault/storage" exact render={() => <CreateVaultStorageStep />} />
        <Route path="/create-vault/privacy" exact render={() => <CreateVaultPrivacyStep />} />
        <Route path="/create-vault/setup" exact render={() => <NewVaultCreateFlow />} />
      </Switch>
    </VaultCreateContextProvider>
  );
}

export default withRouter(VaultCreateRouter);
