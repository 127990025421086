import React from "react";
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import OnImageMenu from "../../../components/common/OnImageMenu";
import NoThumbnail from "../../../components/common/NoThumbnail";

function StackRevision(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { positionedBeforeDate, operation, color, stacks } = props;

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCorrectExtensioForVersion = () => {
    const stackTitle = operation.stack.name.split(".")[0];
    const originalNameArray = operation.versions[0].name.split(".");
    return stackTitle + "." + originalNameArray[originalNameArray.length - 1];
  };

  //create stacks status and stack hash dictionary
  const statusDictionary = {};
  const hashesDictionary = {};

  const stackArray = stacks?.filter(stack =>
    stack.files.some(item => operation.stack.name === item.name)
  );

  //HACK: if a file was renamed and we want to hide it in the timeline because
  //we revoked/deleted it we will be looking in the files array
  //and pick the first one to match the names, since the stack titles won't match
  stackArray?.forEach(
    stack => (statusDictionary[operation.versions[0].name] = stack.status)
  );
  stackArray?.forEach(
    stack => (hashesDictionary[operation.versions[0].name] = stack.hash)
  );

  return (
    <>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        title={
          operation.thumbnailUrl
            ? getCorrectExtensioForVersion()
            : `New version added  ·  version ${operation.stack.versions.length}`
        }
      />
      <Typography
        variant="body2"
        paragraph
        color="text.secondary"
        className="small"
        style={{
          marginBottom: "4px",
          whiteSpace: "pre"
        }}
      >
        New version added{"  "}·{"  "}version {operation.stack.versions.length}
      </Typography>
      <NoThumbnail
        stackItem={operation}
        handleMenu={handleMenu}
        lastItem={true}
        stackStatus={statusDictionary[operation.stack.versions[0].name]}
        stackHash={hashesDictionary[operation.stack.versions[0].name]}
        positionedBeforeDate={positionedBeforeDate}
        {...props}
      />
      <OnImageMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        operation={operation}
      />
    </>
  );
}

export default StackRevision;
