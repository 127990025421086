import React, { useState } from "react";
import { Switch, Route, useRouteMatch, withRouter, RouteComponentProps } from "react-router-dom";
import { AlertColor, Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PageWrapper } from "../../components/common";
import RecoverEmail from "./RecoverEmail";
import RecoverSteps from "./RecoverSteps";
import { stepsData } from "./stepsData";
import { RecoverAccountFormData, useRecoverAccountContext } from "../../contexts/RecoverAccountContext";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import SnackBarConfirm from "../../components/common/SnackBar/SnackBarConfirm";
import { RecoverStepsOptions, SnackbarOptions } from "../../types/globalTypes";
import { MissingWords } from "../../types/signUpTypes";
import { History } from "history";

type RecoverPageProps = {
  spaceBetween: boolean;
  isDesktop: boolean;
};

export interface HandleNextProps {
  steps: string[];
  currentStep: RecoverStepsOptions;
  path: string;
  history: History;
  formData: RecoverAccountFormData;
  handleError: (error: any) => void;
  onSnackbarToShow: (type: SnackbarOptions | null, dynamicData?: any, severity?: AlertColor | null) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
  backupPhrase: MissingWords;
}

const useStyles = makeStyles<Theme, RecoverPageProps>({
  main: {
    justifyContent: ({ spaceBetween, isDesktop }) => (isDesktop && spaceBetween ? "space-between" : "initial")
  }
});

const steps = ["recover", "backup-phrase", "reset-password"];

const RecoverPageContainer: React.FC<RouteComponentProps> = ({ history }) => {
  const { formData, passEvaluation, checkBackupPhrase, backupPhrase, handleError, error } = useRecoverAccountContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const { path } = useRouteMatch();
  const match: { params: { step: any } } | null = useRouteMatch(`${path}/:step`);
  const currentStep: RecoverStepsOptions = !match ? "recover" : match.params?.step;

  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });

  const {
    spaceBetween,
    darkMode,
    showBottomNav,
    nextText,
    isButtonDisabled,
    handleNext,
    hideBackButton,
    fullWidthNextButton,
    hideIcon,
    disabledRed
  } = stepsData[currentStep];

  const classes = useStyles({
    spaceBetween: spaceBetween,
    isDesktop: isDesktop
  });

  const handleBack = () => {
    history.goBack();
  };

  const handleNextProps = {
    onSnackbarToShow,
    handleError,
    formData,
    history,
    steps,
    currentStep,
    path,
    backupPhrase,
    setLoading,
    error
  };

  const errorMessage = (error: any) => {
    if (!error) return;
    switch (error.code) {
      case "UserNotFoundException":
        return "An account with this email address does not exist.";

      default:
        return error.message;
    }
  };

  return (
    <PageWrapper
      error={error}
      errorText={errorMessage(error)}
      darkMode={darkMode}
      showBottomNav={showBottomNav}
      mainClassName={classes.main}
      nextText={nextText}
      nextDiasabled={isButtonDisabled(formData, checkBackupPhrase, loading, passEvaluation, error)}
      handleBack={handleBack}
      handleNext={() => handleNext(handleNextProps)}
      hideBackButton={hideBackButton}
      fullWidthNextButton={fullWidthNextButton}
      hideIcon={hideIcon}
      disabledRed={disabledRed}
      loading={loading}
      pageTitle="Recover account"
    >
      <Box>
        <Switch>
          <Route path="/recover/:step">
            <RecoverSteps />
          </Route>
          <Route path="/recover">
            <RecoverEmail />
          </Route>
        </Switch>
      </Box>
      <SnackBarConfirm />
    </PageWrapper>
  );
};

export default withRouter(RecoverPageContainer);
