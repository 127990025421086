import { Auth } from "@akord/akord-js";
import { AKORD_ENV } from "../../helpers/env";
import { KEEP_SIGNED_IN } from "./constants";
import { AkordWallet } from "@akord/crypto";

export const signIn = async (email, password, keepSignedIn, otp) => {
    Auth.configure({
        env: AKORD_ENV,
        storage: keepSignedIn ? window.localStorage : window.sessionStorage
    });
    sessionStorage.setItem(KEEP_SIGNED_IN, keepSignedIn);
    await Auth.signIn(email, password, otp);
}

export const signInWithWallet = async (base64Phrase) => {
    Auth.configure({
        env: AKORD_ENV,
        storage: window.sessionStorage
    });
    const backupPhrase = atob(base64Phrase)
    const wallet = await AkordWallet.importFromBackupPhrase(backupPhrase)
    sessionStorage.setItem(KEEP_SIGNED_IN, false);
    await Auth.signInWithWallet(wallet);
    return wallet;
}

export const confirmSignIn = async (email, password, keepSignedIn, mfaType, otp) => {
    Auth.configure({
        env: AKORD_ENV,
        storage: keepSignedIn ? window.localStorage : window.sessionStorage
    });
    sessionStorage.setItem(KEEP_SIGNED_IN, keepSignedIn);
    await Auth.confirmSignIn(otp, password, mfaType);
}
