import { Folder, Membership, Stack, Vault } from "@akord/akord-js";
import React, { useState, createContext, useContext } from "react";
import { InfoMapIdProps } from "../components/common/InfoDrawer/infoMap";
import useModal, { AllModals, FormModelType, ModalTypeKeys } from "../hooks/useModal";
import { Types } from "@akord/gql";
import { OrganisationMemberRole } from "../types/globalTypes";

interface NotificationsContextTypes {
  onNotificationData: (notificationData: NotificationData | null) => void;
  notificationData: NotificationData;
  modal: ModalData;
  uploadedFiles: FileSystemEntry[] | File[];
  onUploadFiles: (files: FileSystemEntry[] | File[]) => void;
  onDuplicateFiles: (files: File[]) => void;
  duplicateFiles: File[];
  onUploadRetry: (retry: boolean) => void;
  uploadRetry: boolean;
  uploadEventRef: React.MutableRefObject<React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement> | null>;
  clearUploadEventRef: () => void;
}

type MemberToRevokeProps = {
  role: OrganisationMemberRole;
  user: Types.User;
  orgId?: string;
  updateOrgMembers: () => Promise<{
    admins: Types.User[];
    members: Types.User[];
  }>;
};

export interface NotificationData {
  id?: string;
  title?: string;
  extension?: string;
  fileType?: string;
  hash?: string;
  membersArray?: any;
  //TODO Cleanup data for revoke user
  memberToRevoke?: MemberToRevokeProps;
  dataRoomId?: string;
  folderId?: any;
  batchItems?: Map<any, any>;
  noteText?: string;
  currentStack?: Stack;
  currentMembership?: Membership;
  membership?: Membership;
  type?: InfoMapIdProps;
  vault?: Vault;
  stack?: Stack;
  folder?: Folder;
  cloud?: boolean;
  uploaded?: number;
  allStacks?: number;
  cancelCurrentPlan?: () => Promise<void>;
  isVaultPublic?: boolean;
  uBalance?: number;
  loadUbalance?: () => void;
}

interface ModalData {
  isModalVisible: AllModals;
  onModalVisibility: (valueForType: null | undefined, type: ModalTypeKeys) => void;
  confirmModalType: ModalTypeKeys | null;
  onConfirmModalType: (type: ModalTypeKeys | null) => void;
  confirmModalFormType: ModalTypeKeys | undefined;
  onConfirmModalFormType: (type: FormModelType) => void;
}

const Context = createContext<NotificationsContextTypes>({} as NotificationsContextTypes);

// TODO remove and set types to optional?
const inititalNotificationState = {};

const NotificationsContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  //hack to let upload same file after cancel https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
  let uploadEventRef = React.useRef<React.DragEvent<HTMLDivElement> | null>(null);
  const clearUploadEventRef = () => {
    uploadEventRef.current = null;
  };

  const [duplicateFiles, setDuplicateFiles] = React.useState<File[]>([]);
  const handleDuplicateFiles = (files: File[]) => setDuplicateFiles(files);

  const [uploadedFiles, setUploadedFiles] = React.useState<FileSystemEntry[] | File[]>([]);
  const handleUploadFiles = (files: FileSystemEntry[] | File[]) => setUploadedFiles(files);

  const [uploadRetry, setUploadRetry] = React.useState(false);
  const handleUploadRetry = (retry: boolean) => setUploadRetry(retry);

  const [notificationData, setNotificationData] = useState(inititalNotificationState);
  const handleNotificationData = (notificationData: NotificationData | null) =>
    notificationData ? setNotificationData(notificationData) : setNotificationData(inititalNotificationState);

  const [isModalVisible, handleModalVisibility, confirmModalType, handleConfirmModalType, confirmModalFormType, onConfirmModalFormType] =
    useModal();

  return (
    <Context.Provider
      value={{
        onNotificationData: handleNotificationData,
        notificationData: notificationData,
        modal: {
          isModalVisible: isModalVisible,
          onModalVisibility: handleModalVisibility,
          confirmModalType: confirmModalType,
          onConfirmModalType: handleConfirmModalType,
          confirmModalFormType: confirmModalFormType,
          onConfirmModalFormType: onConfirmModalFormType
        },
        uploadedFiles: uploadedFiles,
        onUploadFiles: handleUploadFiles,
        onDuplicateFiles: handleDuplicateFiles,
        duplicateFiles: duplicateFiles,
        onUploadRetry: handleUploadRetry,
        uploadRetry: uploadRetry,
        uploadEventRef: uploadEventRef,
        clearUploadEventRef: clearUploadEventRef
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default NotificationsContextProvider;

export const withNotificationsContext = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{notificationsContext => <Component {...props} {...notificationsContext} />}</Context.Consumer>;

export const useNotificationsContext = () => useContext(Context);
