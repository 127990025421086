import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, CircularProgress, Theme, Typography } from "@mui/material";
import { withRouter } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { SupportScreenWrapper } from "../../components/common";
import { inviteToOrg } from "../../helpers/api-helpers";
import ConfirmationError from "../../components/common/ConfirmationError";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { useHistory } from "react-router-dom";
import InviteToRoomForm from "../vault-options/InviteToRoomForm";
import { useOrgContext } from "../../contexts/OrgContextProvider";
import { InfoIcon } from "@akord/addon-icons";
import { InviteFormProps } from "../vault-options/InviteToRoom";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles<Theme>((theme) => ({
  containedPrimary: {
    minWidth: "auto"
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute"
  }
}));

let count = 0;

const initialInvite: InviteFormProps = {
  id: 0,
  email: "",
  role: "MEMBER"
};

function OrganisationInvite() {
  const [inviteForm, setInviteForm] = useState<InviteFormProps[]>([initialInvite]);
  const [invalidUsers, setInvalidUsers] = useState<InviteFormProps[]>([]);
  const [alreadyInvited, setAlreadyInvited] = useState<InviteFormProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [inviteError, setInviteError] = useState("");
  const [orgInviteError, setOrgInviteError] = useState("");

  const history = useHistory();
  const { getUserOrg } = useOrgContext();
  const { akord, darkMode, isMobile } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const classes = useStyles();

  React.useEffect(() => {
    //clearing warnings
    const invalidUsersActive = invalidUsers.filter((obj: InviteFormProps) => {
      return inviteForm.some((obj2) => {
        if (obj2) {
          return obj.email === obj2.email;
        } else return false;
      });
    });
    if (invalidUsersActive) setInvalidUsers(invalidUsersActive);
    //clearing warnings
    const alreadyInvitedActive = alreadyInvited.filter((obj: InviteFormProps) => {
      return inviteForm.some((obj2) => {
        if (obj2) {
          return obj.email === obj2.email;
        } else return false;
      });
    });
    if (alreadyInvitedActive) setAlreadyInvited(alreadyInvitedActive);

    if (inviteForm[count] && !!inviteForm[count].email.match(emailRegex)) {
      count++;
      setInviteForm([...inviteForm, { id: count, email: "", role: "MEMBER" }]);
    }
    if (inviteForm[count - 1] && !inviteForm[count - 1].email && count !== 0) {
      setInviteForm(inviteForm.filter((item) => item.id !== count));
      count--;
    }
  }, [inviteForm]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, elIndex: number) => {
    setInviteForm(inviteForm.map((el) => (el.id === elIndex ? { ...el, [e.target.name]: e.target.value } : el)));
  };

  const handleEmailClear = (elIndex: number, item: InviteFormProps) => {
    if (elIndex === 0 && inviteForm.filter((user) => user.email !== "").length === 1) {
      setInviteForm([initialInvite]);
      count = 0;
    } else {
      setInviteForm(inviteForm.filter((el) => el.email !== item.email));
      count--;
    }
    setInviteError("");
    setInvalidUsers(invalidUsers.filter((user) => user.email !== item.email));
    setAlreadyInvited(alreadyInvited.filter((user) => user.email !== item.email));
  };

  const isButtonDisabled = () => {
    const allInvitesRegex = inviteForm.every(
      (invite, index) => (invite && invite.email.match(emailRegex) && invite.role) || (index !== 0 && invite.email === "")
    );
    // Check if a valid email provided for regular invited
    if (allInvitesRegex && !loading) return false;
    else return true;
  };

  const handleInviteForm = async () => {
    if (!akord) return;
    const { host, protocol } = window.location;
    setLoading(true);
    const filteredInvitees = inviteForm.filter((invite) => invite.email);
    try {
      const vaults = await akord.vault.listAll();
      await Promise.all(
        filteredInvitees.map(async (invitee) => {
          const isAdmin = invitee.role === "OWNER";
          let user;
          try {
            user = await akord.api.getUserPublicData(invitee.email.toLowerCase());
          } catch (e) {
            console.log(e);
            setInvalidUsers([invitee]);
            throw Error("Invalid user");
          }
          const membershipIds = [];
          if (isAdmin) {
            for (const vault of vaults) {
              try {
                const airdrop = await akord.membership.airdrop(vault.id, [
                  { publicKey: user.publicKey, publicSigningKey: user.publicSigningKey, role: "OWNER" }
                ]);
                membershipIds.push(airdrop.members[0].id);
              } catch (e) {
                console.warn(e);
              }
            }
          }
          const org = await getUserOrg();
          if (org?.id) await inviteToOrg(org.id, invitee.email.toLowerCase(), isAdmin, `${protocol}//${host}`);
          else {
            const error: any = Error("Organization is not set.");
            error.code = "ORG_DOESNT_EXISTS";
            throw error;
          }
        })
      );

      onSnackbarToShow("orgInvite");
      history.push("/account/organisation/members");
    } catch (err: any) {
      console.log(err);
      if (err.code === "ORG_DOESNT_EXISTS") setOrgInviteError(err);
      else setInviteError(err);
    }
    setLoading(false);
  };

  return (
    <SupportScreenWrapper title="Invite to organisation">
      <Box my={6} display="flex" alignItems="center">
        <InfoIcon fontSize="medium" color="secondary" sx={{ marginRight: 1 }} />
        <Typography variant="body2" className="small">
          To invite someone to your organisation, they must first have an Akord account.
        </Typography>
      </Box>
      <Box width={!isMobile ? "100%" : "inherit"} mb={6}>
        {inviteForm?.map((item, index) => (
          <InviteToRoomForm
            key={index}
            index={index}
            // item={item}
            // darkMode={darkMode}
            isMobile={isMobile}
            inviteForm={inviteForm}
            handleFormChange={handleFormChange}
            handleEmailClear={handleEmailClear}
            invalidUsers={invalidUsers}
            alreadyInvited={alreadyInvited}
            isOrgInvite={true}
          />
        ))}
      </Box>
      <Button
        variant={"contained"}
        color="primary"
        type="button"
        disableElevation
        classes={{
          containedPrimary: classes.containedPrimary
        }}
        // style={error && { background: '#DB443C' }}
        disabled={isButtonDisabled()}
        fullWidth={isMobile}
        onClick={handleInviteForm}
      >
        Invite to organisation
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
      {orgInviteError && (
        <Box>
          <ConfirmationError error={orgInviteError} type="orgInvite" />
        </Box>
      )}
      {inviteError && (
        <Box>
          <ConfirmationError error={inviteError} type="dataRoomInvite" />
        </Box>
      )}
    </SupportScreenWrapper>
  );
}

export default withRouter(OrganisationInvite);
