import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, MenuList, ListItemText, ListItemIcon, Button, Paper, Popover, Theme } from "@mui/material";
import { Membership } from "@akord/akord-js";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CloseInCircleIcon, PaperPlaneIcon, TriangleDownIcon, TriangleUpIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { memberStatusEnum } from "../../../helpers/akord-enums";
import { useVaultContext } from "../../../contexts/VaultContextProvider";

type SelectMenuForAccessProps = {
  member: Membership;
  menuType: "Pending" | "Invited" | "Revoked" | "Rejected";
};

const useStyles = makeStyles<Theme>(theme => ({
  menuItem: {
    fontSize: "1rem",
    minHeight: "34px",
    alignItems: "start",
    paddingTop: theme.spacing(5),
    paddingBottom: 0,
    "&:first-child": {
      paddingTop: theme.spacing(5),
      paddingBottom: 0
    },
    "&:last-child": {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5)
    }
  },
  selected: {
    background: "transparent!important"
  },
  menuList: {
    backgroundColor: theme.palette.background.secondary,
    maxWidth: "293px",
    minWidth: "180px"
  },
  primaryTypo: {
    fontSize: "1rem"
  },
  secondaryTypo: {
    fontSize: "1rem",
    color: theme.palette.text.secondary
  },
  multiline: {
    marginTop: 0,
    marginBottom: 0
  },
  buttonRoot: {
    height: "auto",
    "&:hover": {
      backgroundColor: "inherit"
    }
  },
  buttonText: {
    padding: 0,
    fontSize: "0.875rem",
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontWeight: 400
  },
  endIcon: {
    marginLeft: 0
  },
  iconSizeLarge: {
    "&>*:first-child": {
      fontSize: "24px",
      marginLeft: theme.spacing(1)
    }
  }
}));

const SelectMenuForAccess: React.FC<SelectMenuForAccessProps> = ({ member, menuType }) => {
  const [roleMenuOpen, setRoleMenuOpen] = React.useState(false);

  const { akord, darkMode, onTxSpinner } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { vault, onVaultUpdate: onVaultMembershipData, onMembers, members } = useVaultContext();
  const classes = useStyles({
    darkMode: darkMode,
    roleMenuOpen: roleMenuOpen
  });

  const handleToggle = () => {
    setRoleMenuOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setRoleMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setRoleMenuOpen(false);
    }
  };

  // return focus to the button when we transitioned from !roleMenuOpen -> roleMenuOpen
  const prevOpen = React.useRef(roleMenuOpen);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (prevOpen.current === true && roleMenuOpen === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = roleMenuOpen;
  }, [roleMenuOpen]);

  const handleMenuItems = async (type: "resend" | "revoke") => {
    try {
      switch (type) {
        case "resend":
          handleClose();
          onTxSpinner(true);
          await akord?.membership.inviteResend(member.id);
          onVaultMembershipData();
          onTxSpinner(false);
          onSnackbarToShow("resendInvite");
          break;
        case "revoke":
          handleClose();
          onTxSpinner(true);
          if (member.status === "INVITED") {
            await akord?.membership.revokeInvite(vault.id, member.id);
          } else {
            await akord?.membership.revoke(member.id);
          }
          onMembers(members.filter(memberhip => memberhip.id !== member.id));
          onVaultMembershipData();
          onTxSpinner(false);
          onSnackbarToShow("memberRevoke");
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
      onTxSpinner(false);
    }
  };

  const getMenus = () => {
    return (
      <MenuList onKeyDown={handleListKeyDown}>
        <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }} onClick={() => handleMenuItems("resend")}>
          <ListItemIcon>
            <PaperPlaneIcon color="disabled" classes={{ root: classes.icon }} />
          </ListItemIcon>
          <ListItemText
            classes={{ multiline: classes.multiline }}
            style={{ whiteSpace: "normal" }}
            primary="Resend"
            primaryTypographyProps={{
              classes: { body1: classes.primaryTypo }
            }}
          />
        </MenuItem>
        {member.status !== memberStatusEnum.REVOKED && (
          <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }} onClick={() => handleMenuItems("revoke")}>
            <ListItemIcon>
              <CloseInCircleIcon color="disabled" classes={{ root: classes.icon }} />
            </ListItemIcon>
            <ListItemText
              classes={{ multiline: classes.multiline }}
              style={{ whiteSpace: "normal" }}
              primary="Revoke invite"
              primaryTypographyProps={{
                classes: { body1: classes.primaryTypo }
              }}
            />
          </MenuItem>
        )}
      </MenuList>
    );
  };

  if (!member) return null;

  return (
    <>
      <Button
        disableRipple
        size="large"
        ref={anchorRef}
        aria-controls={roleMenuOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        endIcon={roleMenuOpen ? <TriangleUpIcon color="action" /> : <TriangleDownIcon color="disabled" />}
        onClick={handleToggle}
        classes={{
          root: classes.buttonRoot,
          text: classes.buttonText,
          endIcon: classes.endIcon,
          iconSizeLarge: classes.iconSizeLarge
        }}
      >
        {menuType}
      </Button>
      <Popover
        anchorEl={anchorRef.current}
        open={roleMenuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Paper className={classes.menuList}>
          <ClickAwayListener onClickAway={handleClose}>{getMenus()}</ClickAwayListener>
        </Paper>
      </Popover>
    </>
  );
};

export default SelectMenuForAccess;
