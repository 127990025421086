import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { styled, Theme } from "@mui/material/styles";
import { Box, Typography, FormControl, TextField, InputAdornment, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { ButtonGroup, CountryAutoCompleteSelect } from "../common";
import { CheckboxEmptyIcon, CheckboxTickIcon, CloseInCircleIcon, CreditCardIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import StripeInput from "./stripe/StripeInput";
import { CardElement, PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { grey } from "../../theme/colors";
import { getBilling, putBilling } from "../../helpers/api-helpers";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { akordLinks } from "../../helpers/akordTexts";
import { PaymentMethod, PaymentRequest } from "@stripe/stripe-js";
import { AxiosError } from "axios";

type BillingFormStylesProps = {
  isMobile: boolean;
};

const useStyles = makeStyles<Theme, BillingFormStylesProps>(theme => ({
  ccText: {
    fontSize: "16px",
    color: "white",
    "::placeholder": {
      color: grey[400]
    }
  },
  rowForm: {
    display: "flex",
    flexDirection: ({ isMobile }) => (isMobile ? "column" : "row")
  },
  rowField: {
    flex: "1",
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  },
  rowSkeleton: {
    marginBottom: 32,
    borderRadius: 4,
    flexGrow: 1,
    "&:nth-child(2)": {
      marginLeft: 16,
      marginRight: 16
    }
  },
  rowFields: {
    "@media (min-width: 660px)": {
      flex: 1
    },
    "@media (min-width: 766px)": {
      flex: 2
    },
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  },
  box: {
    border: "1px solid",
    borderColor: theme.palette.background.cardBorder,
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "16px",
    cursor: "pointer"
  },
  formControlLabelRoot: {
    marginBottom: "12px",
    alignItems: "start"
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  collapseWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  boxChecked: {
    "&:hover": {
      backgroundColor: "transparent!important"
    }
  },
  label: {
    alignSelf: "flex-end"
  }
}));

const TextFieldStyled = styled(TextField)(() => {
  return `
    .MuiInputBase-root {
      padding: 0;
      margin-bottom: 0;
      align-items: baseline;
    }
    .MuiInputBase-input {
      padding: 12px;
      font-size: 0.8625rem;
      line-height: 1.43;
    }
  `;
});

export type BillingFormComponentProps = {
  onBillingSaved?: (arg?: any) => any;
  billingSaveButtonText?: string;
  submitWithoutChanges?: boolean;
  skipMessaging?: boolean;
  subscribe?: boolean;
  allowCoupon?: boolean;
  useMobilePayment?: boolean;
  mobilePaymentButtonText?: string;
  mobilePaymentAmount?: number;
  mobilePaymentCurrency?: string;
};

const BillingForm: React.FC<BillingFormComponentProps> = ({
  onBillingSaved,
  billingSaveButtonText,
  submitWithoutChanges,
  skipMessaging,
  subscribe = false,
  allowCoupon = false,
  useMobilePayment,
  mobilePaymentButtonText,
  mobilePaymentAmount,
  mobilePaymentCurrency
}) => {
  const { isMobile, darkMode, akord, userAttributes } = useGlobalContext();
  const classes = useStyles({ isMobile: isMobile });
  const { onSnackbarToShow } = useSnackbarContext();
  const { billingForm, onBillingForm, discountCoupon, onDiscountCoupon, isDiscountCouponApplied, loading, onLoading } = usePaymentContext();

  const elements = useElements();
  const stripe = useStripe();

  const [cardDetails, setCardDetails] = useState({
    last4: "",
    brand: "",
    checkPassed: false
  });
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [cardEditModeEnabled, setCardEditModeEnabled] = useState(false);
  const [billingEdit, setBillingEdit] = useState(false);
  const [dataEdited, setDataEdited] = useState(true);

  const [cardComplete, setCardComplete] = useState(false);
  const [cardEmpty, setCardEmpty] = useState(true);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [customerDiscountCoupon, setCustomerDiscountCoupon] = useState<string>();

  const termsRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    // If paymentRequest exists dont create a new one
    if (!stripe || !elements || !mobilePaymentAmount || !mobilePaymentCurrency || paymentRequest) {
      return;
    }

    setPaymentRequest(null);
    const pr = stripe.paymentRequest({
      country: "US",
      currency: mobilePaymentCurrency?.toLowerCase(),
      total: {
        label: mobilePaymentButtonText || "Permanent Storage",
        amount: mobilePaymentAmount
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr);
      }
    });
  }, [stripe, elements, mobilePaymentAmount, mobilePaymentCurrency, mobilePaymentButtonText, paymentRequest]);

  useEffect(() => {
    // If paymentRequest exists update amount here
    if (paymentRequest && mobilePaymentAmount) {
      paymentRequest.update({
        total: {
          label: mobilePaymentButtonText || "Permanent Storage",
          amount: mobilePaymentAmount
        }
      });
    }
  }, [paymentRequest, mobilePaymentAmount, mobilePaymentButtonText]);

  const initBilling = React.useCallback(async () => {
    const billing = await getBilling(userAttributes.email!);
    if (billing) {
      setDataEdited(false);
      const defaultPaymentMethod = billing.invoice_settings?.default_payment_method;
      onBillingForm({
        name: defaultPaymentMethod?.billing_details?.name || billing.name || "",
        country: defaultPaymentMethod?.billing_details?.address?.country || billing.address?.country || "",
        address: defaultPaymentMethod?.billing_details?.address?.line1 || billing.address?.line1 || "",
        city: defaultPaymentMethod?.billing_details?.address?.city || billing.address?.city || "",
        state: defaultPaymentMethod?.billing_details?.address?.state || billing.address?.state || "",
        zip: defaultPaymentMethod?.billing_details?.address?.postal_code || billing.address?.postal_code || "",
        companyName: billing.metadata?.companyName,
        companyVat: billing.metadata?.companyVat
      });
      setCardDetails({
        last4: billing.invoice_settings?.default_payment_method?.card?.last4 || billing.last4,
        brand: billing.invoice_settings?.default_payment_method?.card?.brand || billing.brand,
        checkPassed: Object.values(billing.invoice_settings?.default_payment_method?.card?.checks || {}).some(check =>
          ["pass", "unavailable"].includes(check as string)
        )
      });
    }
  }, [userAttributes.email, onBillingForm]);

  useEffect(() => {
    if (elements && akord && userAttributes?.email) {
      initBilling();
    }
  }, [akord, userAttributes, elements, initBilling]);

  useEffect(() => {
    setCustomerDiscountCoupon(discountCoupon);
  }, [discountCoupon]);

  const handlePaymentRequestClicked = (event: any) => {
    if (!paymentRequest || !useMobilePayment) {
      event.preventDefault();
      return;
    }
    if ((subscribe && agreedToTerms) || !subscribe) {
      paymentRequest.on("paymentmethod", async e => {
        e.complete("success");
        saveBillingData(e.paymentMethod, true);
      });
      paymentRequest.on("cancel", () => {
        paymentRequest.off("paymentmethod");
      });
    } else {
      event.preventDefault();
      onSnackbarToShow("paymentTermsNotAccepted", null, "error");
      termsRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    return;
  };

  const saveBillingDataFromCard = async () => {
    if (cardComplete && stripe && elements) {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)!,
        billing_details: {
          name: billingForm.name,
          email: userAttributes?.email,
          address: {
            country: billingForm.country,
            city: billingForm.city,
            state: billingForm.state,
            postal_code: billingForm.zip,
            line1: billingForm.address
          }
        },
        metadata: {
          companyName: billingForm.companyName,
          companyVat: billingForm.companyVat
        }
      });

      if (error) {
        console.log("Error on createPaymentMethod: ", error);
        return;
      }
      if (paymentMethod) {
        saveBillingData(paymentMethod, false);
      }
    } else {
      saveBillingData(null, false);
    }
  };

  const saveBillingData = async (paymentMethod?: PaymentMethod | null, isMobile?: boolean) => {
    if (!userAttributes?.email) return;
    onLoading(true);
    try {
      await putBilling(userAttributes?.email, {
        paymentMethodId: paymentMethod?.id,
        billingData: {
          billing_details: {
            name: billingForm.name,
            email: userAttributes?.email,
            address: {
              country: billingForm.country,
              city: billingForm.city,
              state: billingForm.state,
              postal_code: billingForm.zip,
              line1: billingForm.address
            }
          },
          metadata: {
            companyName: billingForm.companyName,
            companyVat: billingForm.companyVat
          }
        }
      });
      if (onBillingSaved) {
        await onBillingSaved(isMobile);
      }
      if (!skipMessaging) {
        onSnackbarToShow("billingSaved");
        initBilling();
      }
    } catch (e) {
      console.log(e);
      if (e instanceof AxiosError && (e?.response?.status || 500) < 500 && e?.response?.data?.error) {
        onSnackbarToShow("billingUpdateFailure", "Error saving your biling details. " + e.response?.data.error, "error");
      } else {
        onSnackbarToShow("billingUpdateFailure", "Error saving your biling details", "error");
      }
    }
    onLoading(false);
    setCardComplete(false);
    setCardEmpty(true);
    setCardEditModeEnabled(false);
  };

  const handlePaymentDataChanged = () => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target } = event;
    onBillingForm({ ...billingForm, [target.name]: target.value });
    setDataEdited(true);
  };

  const handleCountrySelected = () => (event: any, value: any) => {
    onBillingForm({ ...billingForm, country: value ? value.code : null });
    setDataEdited(true);
  };

  const billingDataIsValid = () => {
    return (
      billingForm.name?.length > 2 &&
      billingForm.country?.length > 1 &&
      billingForm.city?.length > 2 &&
      billingForm.address?.length > 2 &&
      billingForm.zip?.length > 2 &&
      billingForm.zip?.length < 12
    );
  };

  const isButtonDisabled = () => {
    return !(
      // billing address is corresct AND
      (
        billingDataIsValid() &&
        // if coming from topup (submitWithoutChanges) and the card is present OR card was entered OR data was edited AND card existed OR was entered
        ((submitWithoutChanges && cardDetails.checkPassed) || cardComplete || (dataEdited && (cardDetails.checkPassed || cardComplete))) &&
        // If a user updates a card
        (!cardEditModeEnabled || cardComplete) &&
        // subscription and agree form was checked OR not a subscription screen
        ((subscribe && agreedToTerms) || !subscribe) &&
        !loading
      )
    );
  };

  const billingDataAsString = () => {
    let billingDataString = `${billingForm.name}\n`;
    if (billingForm.companyName) {
      billingDataString += `${billingForm.companyName}\n`;
    }
    if (billingForm.companyVat) {
      billingDataString += `${billingForm.companyVat}\n`;
    }
    billingDataString += `${billingForm.address}\n${billingForm.city}\n${billingForm.zip} ${billingForm.state}\n${billingForm.country}`;
    return billingDataString;
  };

  const showDiscountCouponState = () => {
    if (discountCoupon && !isDiscountCouponApplied) {
      return (
        <IconButton
          onClick={() => {
            setCustomerDiscountCoupon("");
            onDiscountCoupon("");
          }}
        >
          <CloseInCircleIcon fontSize="small" />
        </IconButton>
      );
    } else if (customerDiscountCoupon?.length && customerDiscountCoupon.length >= 3 && !loading) {
      return (
        <Typography
          component="span"
          color="primary.main"
          variant="body2"
          sx={{ cursor: "pointer" }}
          onClick={() => onDiscountCoupon(customerDiscountCoupon)}
        >
          Apply
        </Typography>
      );
    }
    return null;
  };

  return (
    <>
      {paymentRequest && useMobilePayment && (
        <Box mt="5%">
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  theme: !darkMode ? "dark" : "light",
                  height: "44px"
                }
              }
            }}
            onClick={handlePaymentRequestClicked}
          />
        </Box>
      )}
      <Box mb={4} mt={6}>
        <Typography variant="h3" component="div">
          Billing details
        </Typography>
      </Box>
      <Box mb={2}>
        {billingDataIsValid() && !billingEdit ? (
          <Box>
            <FormControl fullWidth>
              <TextFieldStyled
                fullWidth
                multiline
                id="billing-data"
                name="billing-data"
                type="text"
                variant="outlined"
                disabled={loading}
                sx={{ marginBottom: 0, alignItems: "baseline" }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      {!loading && (
                        <Typography
                          id="edit-billing-data"
                          component="span"
                          color="primary.main"
                          variant="body2"
                          mr={3}
                          mt={3}
                          sx={{ cursor: "pointer", position: "absolute", top: 0, right: 0 }}
                          onClick={() => setBillingEdit(true)}
                        >
                          Edit
                        </Typography>
                      )}
                    </InputAdornment>
                  )
                }}
                value={billingDataAsString()}
              />
            </FormControl>
          </Box>
        ) : (
          <Box>
            <FormControl fullWidth>
              <TextField
                margin="none"
                // error={!!error}
                label="Name"
                variant="outlined"
                name="name"
                required
                disabled={loading}
                value={billingForm.name}
                onChange={handlePaymentDataChanged()}
              />
            </FormControl>
            <Box mb={2}>
              <FormControl className={classes.rowForm}>
                <TextField
                  // error={!!error}
                  label="Company (optional)"
                  variant="outlined"
                  name="companyName"
                  disabled={loading}
                  value={billingForm.companyName}
                  onChange={handlePaymentDataChanged()}
                  className={classes.rowFields}
                />
                <TextField
                  // error={!!error}
                  label="Tax ID (optional)"
                  variant="outlined"
                  name="companyVat"
                  disabled={loading}
                  value={billingForm.companyVat}
                  onChange={handlePaymentDataChanged()}
                  className={classes.rowField}
                />
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <TextField
                  // error={!!error}
                  required
                  label="Address"
                  variant="outlined"
                  name="address"
                  disabled={loading}
                  value={billingForm.address}
                  onChange={handlePaymentDataChanged()}
                />
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl className={classes.rowForm}>
                <TextField
                  // error={!!error}
                  required
                  label="City"
                  variant="outlined"
                  name="city"
                  disabled={loading}
                  value={billingForm.city}
                  onChange={handlePaymentDataChanged()}
                  className={classes.rowField}
                />
                <TextField
                  // error={!!error}
                  required
                  label="State"
                  variant="outlined"
                  name="state"
                  disabled={loading}
                  value={billingForm.state}
                  onChange={handlePaymentDataChanged()}
                  className={classes.rowField}
                />
                <TextField
                  // error={!!error}
                  required
                  label="Zip"
                  variant="outlined"
                  name="zip"
                  disabled={loading}
                  onChange={handlePaymentDataChanged()}
                  value={billingForm.zip}
                  className={classes.rowField}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <CountryAutoCompleteSelect
                  useLabel
                  useFlag
                  codeValue={billingForm.country}
                  props={{ onChange: handleCountrySelected(), disableClearable: true }}
                />
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Box mb={4} mt={6}>
          <Typography variant="h3" component="div">
            Payment details
          </Typography>
        </Box>
        {cardDetails.last4 && !cardEditModeEnabled ? (
          <TextField
            // label="Card number"
            name="ccard"
            variant="outlined"
            value={`**** **** **** ${cardDetails.last4}`}
            fullWidth
            disabled={loading}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  {cardDetails.brand && (
                    <img
                      src={`https://github.com/aaronfagan/svg-credit-card-payment-icons/raw/main/logo/${cardDetails.brand.toLowerCase()}.svg`}
                      alt={cardDetails.brand}
                      width="44px"
                      height="22px"
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {!loading && (
                    <Typography
                      component="span"
                      color="primary.main"
                      variant="body2"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setCardEditModeEnabled(true)}
                    >
                      Edit
                    </Typography>
                  )}
                </InputAdornment>
              )
            }}
          />
        ) : (
          <TextField
            // label="Card number"
            name="ccard"
            variant="outlined"
            fullWidth
            // InputLabelProps={{ shrink: true }}
            onChange={(e: any) => {
              setCardComplete(e.complete);
              setCardEmpty(e.empty);
            }}
            onBlur={() => {
              if (cardEmpty) {
                setCardEditModeEnabled(false);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{
                    position: isMobile ? "inherit" : "absolute"
                  }}
                >
                  <CreditCardIcon color="disabled" />
                </InputAdornment>
              ),
              inputComponent: StripeInput,
              inputProps: {
                component: CardElement,
                options: {
                  hidePostalCode: true,
                  // hideIcon: true,
                  style: {
                    base: {
                      iconColor: "transparent",
                      fontFamily: ["Inter", "sans-serif"].join(","),
                      lineHeight: 1.43,
                      fontWeight: 100,
                      fontSize: "15px",
                      letterSpacing: "0.5px",
                      color: darkMode ? "white" : "inherit",
                      "::placeholder": {
                        color: grey[400]
                      }
                    }
                  }
                }
              }
            }}
          />
        )}
      </Box>
      {allowCoupon && (
        <Box>
          <FormControl className={classes.rowForm}>
            <TextField
              // error={!!error}
              label="Add discount code"
              variant="outlined"
              name="coupon"
              value={customerDiscountCoupon ? customerDiscountCoupon : ""}
              onChange={e => setCustomerDiscountCoupon(e.target.value)}
              fullWidth
              disabled={loading}
              InputProps={{
                endAdornment: <InputAdornment position="start">{showDiscountCouponState()}</InputAdornment>
              }}
            />
          </FormControl>
        </Box>
      )}
      {subscribe && (
        <Box>
          <Box mt={2} display="block" lineHeight="18.5px">
            <Typography variant="caption" className="small">
              Your Akord subscription will automatically renew each month. You can cancel at any time, but payment for that month cannot be
              refunded. Your payment data is encrypted and secure, and will be saved in your Account settings.
            </Typography>
          </Box>
          <Box mt={6} mb={6} lineHeight="18.5px">
            <FormControlLabel
              classes={{ label: classes.label }}
              className={classes.formControlLabelRoot}
              control={
                <Checkbox
                  ref={termsRef}
                  disableRipple
                  icon={<CheckboxEmptyIcon />}
                  checkedIcon={<CheckboxTickIcon />}
                  size="small"
                  classes={{ checked: classes.boxChecked }}
                  className={classes.checkbox}
                  onChange={ev => setAgreedToTerms(ev.target.checked)}
                  name="subscription-terms"
                />
              }
              label={
                <>
                  <Typography variant="caption" className="small">
                    I have read and agreed to{" "}
                  </Typography>
                  <Typography component="a" variant="caption" className="small" onClick={() => window.open(akordLinks["terms"])}>
                    Akord&apos;s terms of service
                  </Typography>
                  <Typography variant="caption" className="small" component="span">
                    {" "}
                    and understand the automatic renewal policy.
                  </Typography>
                </>
              }
            />
          </Box>
        </Box>
      )}
      <ButtonGroup
        type="submit"
        nextText={billingSaveButtonText || "Change billing data"}
        nextDiasabled={isButtonDisabled()}
        handleEnd={() => saveBillingDataFromCard()}
        hideBackButton={true}
        fullWidthNextButton={true}
        hideIcon={true}
        disabledRed={false}
        loading={loading}
        noMarginTop={true}
      />
    </>
  );
};

export default BillingForm;
