import { Akord, Folder } from "@akord/akord-js";
import mime from "mime";
import { FileWithAddOn } from "../components/file/FileUploader";

export interface FileTree {
  [name: string]: File | FileTree;
}

export const processFileTree = async (
  fileTree: FileTree[],
  akord: Akord,
  parentId: string | null,
  filesToUpload: FileWithAddOn[],
  dataRoomId: string,
  onFolderCreate: (folder: Folder) => void
) => {
  for (const item of fileTree) {
    const itemKey = Object.keys(item)[0];
    if (!(item[itemKey] instanceof File)) {
      await traverseDir(akord, item[itemKey] as FileTree, itemKey, parentId, filesToUpload, dataRoomId, onFolderCreate);
    } else {
      await traverseDir(akord, item, "ROOT", parentId, filesToUpload, dataRoomId, onFolderCreate);
    }
  }
  return filesToUpload;
};

export const processFiles = async (fileList: File[]) => {
  const fileTree: FileTree = {};

  fileList.forEach(file => {
    const path = file.webkitRelativePath ? file.webkitRelativePath : file.name;
    const pathArray = path.split("/");
    let currentNode = fileTree;
    pathArray.forEach((name, index) => {
      if (!currentNode[name]) {
        currentNode[name] = index === pathArray.length - 1 ? file : {};
      }
      currentNode = currentNode[name] as FileTree;
    });
  });

  return fileTree;
};

// export const processFiles = async (fileList: File[]) => {
//   const filePaths = fileList.map(file => (file.webkitRelativePath ? file.webkitRelativePath : file.name));
//   const filesArray = [...fileList];

//   // Files uploaded as singles do not have path - in that case just return the originals
//   // if (filePaths.length === 0) return fileList; //TODO do we need it?

//   // Create File Tree output
//   const fileTree: FileTree = {};
//   filePaths.forEach(path => {
//     const pathArray = path.split("/");
//     pathArray.reduce((acc, name, index) => {
//       // Match files by name and path to avoid mixing up files with the same name in different folders
//       const foundFile = filesArray.find(file => {
//         if (file.webkitRelativePath) return file.name === name && file.webkitRelativePath === path;
//         else return file.name === name;
//       });
//       if (!acc[name]) {
//         acc[name] = index === pathArray.length - 1 && foundFile ? foundFile : ({} as FileTree);
//       }
//       return acc[name] as FileTree;
//     }, fileTree);
//   });
//   return fileTree;
// };

// Functions for creating list of Files for Modal
export const convertDirectoryEntrytoList = async (entries: FileSystemEntry[]) => {
  const fileTree = await makeDir(entries);
  return convertFileTreeIntoList(fileTree);
};

/* constructs a simple directory view from a filesystem */
async function makeDir(entries: FileSystemEntry[]) {
  const systems = entries.map(entry => traverse(entry, {}));
  return await Promise.all(systems);

  async function traverse(entry: FileSystemEntry, fs: FileTree) {
    if (entry.isDirectory) {
      fs[entry.name] = {} as File | FileTree;
      let entries: FileSystemEntry[] = [];
      let dirReader = (entry as FileSystemDirectoryEntry).createReader();
      await new Promise((res, rej) => {
        const readEntries = async function () {
          dirReader.readEntries(async function (results) {
            if (!results.length) {
              for (let e of entries) {
                const value = fs[entry.name] as FileTree;
                await traverse(e, value);
              }
              res(null); //TODO was empty res(), what should be returned ?
            } else {
              entries = entries.concat(toArray(results));
              await readEntries();
            }
          }, rej);
        };
        readEntries();
      });
    } else if (entry.isFile) {
      fs[entry.name] = await new Promise((res, rej) => {
        (entry as FileSystemFileEntry).file(file => {
          if (!file.type) {
            Object.defineProperty(file, "type", {
              value: mime.getType((file.name.split(".").pop() || "").toLowerCase())
            });
          }
          Object.defineProperty(file, "webkitRelativePath", {
            value: entry.fullPath.substring(1)
          });
          res(file);
        }, rej);
      });
    }
    return fs;
  }
}

const traverseDir = async (
  akord: Akord,
  dir: FileTree,
  folderName: string,
  parentId: string | null,
  filesToUpload: FileWithAddOn[],
  dataRoomId: string,
  onFolderCreate: (folder: Folder) => void
) => {
  const newFolderId = folderName === "ROOT" ? parentId : await createFolder(akord, folderName, parentId, dataRoomId, onFolderCreate);

  for (const key in dir) {
    if (dir[key] instanceof File && shouldUploadFile(dir[key] as FileWithAddOn)) {
      Object.defineProperty(dir[key], "folderId", {
        value: newFolderId,
        writable: true,
        configurable: true
      });
      filesToUpload.push(dir[key] as FileWithAddOn);
    } else {
      await traverseDir(akord, dir[key] as FileTree, key, newFolderId, filesToUpload, dataRoomId, onFolderCreate);
    }
  }
};

const shouldUploadFile = (file: File) => {
  return file.name !== ".DS_Store" && file.name !== "." && file.name !== "..";
};

const createFolder = async (
  akord: Akord,
  folderName: string,
  parentId: string | null,
  dataRoomId: string,
  onFolderCreate: (folder: Folder) => void
) => {
  const { object, folderId } = await akord.folder.create(dataRoomId, folderName, { parentId: parentId ? parentId : undefined });
  // console.info(`Created folder ${folderName} (${folderId}) with parent ${parentId}`);
  onFolderCreate(object);
  return folderId;
};

const convertFileTreeIntoList = (fileTree: FileTree[]) => {
  let fileList: File[] = [];
  for (const item of fileTree) {
    const itemKey = Object.keys(item)[0];
    if (!(item[itemKey] instanceof File)) traverseFileTree(item[itemKey], fileList);
    else traverseFileTree(item, fileList);
  }
  return fileList;
};

const isFile = (entry: File | FileTree): entry is File => {
  return (entry as File).type !== undefined;
};

const traverseFileTree = (dir: FileTree | File, fileList: File[]) => {
  if (isFile(dir)) {
    if (dir.name !== ".DS_Store") fileList.push(dir);
  } else {
    for (const key in dir) {
      traverseFileTree(dir[key], fileList);
    }
  }
};

const toArray = (list: FileSystemEntry[]) => {
  return [...(list || [])];
};
