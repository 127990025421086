import { Badge, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AvatarIcon, PadlockIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { colorMap } from "../timeline/timeline-actions/colorMap";
import ChatMemoItem from "./ChatMemoItem";
import { useChatContext } from "../../contexts/ChatContextProvider";
import { AVATAR_URL } from "../../helpers/env";
import Avatar from "../../components/common/Avatar/Avatar";

const useStyles = makeStyles({
  missingOppositeContent: {
    "&:before": {
      flex: "none",
      padding: 0
    }
  },
  dot: {
    padding: 0,
    margin: 0,
    borderWidth: "1px",
    boxShadow: "none",
    border: "none"
  },
  content: {
    padding: "2px 8px 2px 4px",
    maxWidth: "calc(100% - 50px)"
  },
  iconExtraLarge: {
    fontSize: "38px",
    margin: 3
  },
  iconExtraSmall: {
    fontSize: "13px"
  },
  lockBoxLength: {
    minHeight: "28px"
  },
  lockPosition: {
    marginLeft: "16px"
  },
  dateBadge: {
    padding: "14px",
    border: "none",
    fontSize: "14px",
    borderRadius: "30px",
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontWeight: 400,
    transform: "scale(1) translate(0, -50%)"
  },
  badgePosition: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  lastItemBeforeDateLength: {
    minHeight: "84px"
  },
  avatar: {
    width: "38px",
    height: "38px",
    borderRadius: "50%",
    margin: "3px"
  }
});

interface LocationState extends Location {
  itemId?: string;
}

type VaultChatProps = {
  chatItems: any;
  chatEndRef: React.RefObject<HTMLDivElement>;
  scrollToBottom: () => void;
  topMemoRef: React.MutableRefObject<HTMLDivElement | null>;
  loadMoreChatItems: () => void;
  isMoreMemosLoadedRef: React.MutableRefObject<boolean>;
};

const VaultChat: React.FC<VaultChatProps> = ({
  chatItems,
  chatEndRef,
  scrollToBottom,
  topMemoRef,
  loadMoreChatItems,
  isMoreMemosLoadedRef
}) => {
  const { membersMap } = useVaultContext();
  const { isMemoLoaded, countRef, chatItemsNumber, chatItemsLimit, memosByDate } = useChatContext();

  const location = useLocation<LocationState>();
  const itemId = location.state?.itemId;

  const classes = useStyles();
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!itemId && !isMoreMemosLoadedRef.current) scrollToBottom();
  }, [chatItems]);

  useEffect(() => {
    if (itemRef && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
  }, [chatItems, itemRef]);

  const highlightItem = () => {
    const container = itemRef?.current?.querySelector("#memo-container") as HTMLElement;
    if (container) {
      const color = container.getAttribute("data-color");
      const initialBackground = "inherit";
      container.style.transition = "background-color 1s ease-in";
      if (color) container.style.background = color;
      setTimeout(() => {
        container.style.background = initialBackground;
      }, 2000);
    }
  };

  const chatItemsByDate = memosByDate(chatItems);
  const flattenedMemoArray = Array.from(chatItemsByDate);
  const memosEntriesLength = chatItemsByDate.size;
  const preparedMemosItems = Array.from(chatItemsByDate?.values()).flat();

  useEffect(() => {
    // To avoid adding same count because of rerenders
    if (isMemoLoaded && !countRef.current.includes(chatItemsNumber) && chatItemsNumber > 0) {
      countRef.current.push(chatItemsNumber);
    }
  }, [chatItemsNumber]);

  // To find which item should be added to the REF (top item in the previous view)
  // we keep track of the indexes of these items and calculate here based on
  // last too indexes
  const calcItemIndex = () => {
    const currIndex = countRef.current.length - 1;
    const prevIndex = countRef.current.length - 2;
    return countRef.current[currIndex] - countRef.current[prevIndex];
  };

  // We need to match items when we render either by groupRef or id
  // for that we keep `paginatedTimelineItems` array of all items
  // and use `calcItemIndex` to get an index and match to the rendered item
  const matchItem = (item: any) => {
    if (!item.groupRef && item.id) return preparedMemosItems[calcItemIndex()]?.id === item.id;
    else return null;
  };

  return (
    <>
      {/* {isMemoLoaded && (
        <Box mb={6} display="flex" justifyContent="center">
          <Button
            size="small"
            onClick={() => loadMoreChatItems()}
            color="primary"
            variant="outlined"
            type="submit"
            // label="submit"
            disableElevation
            // className={classes.buttonGroup}
          >
            Load more activity...
          </Button>
        </Box>
      )} */}
      <Box>
        <Timeline>
          <TimelineItem className={classes.lockBoxLength} classes={{ missingOppositeContent: classes.missingOppositeContent }}>
            <TimelineSeparator className={classes.lockPosition}>
              <TimelineDot className={classes.dot}>
                <PadlockIcon classes={{ root: classes.iconExtraSmall }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
          </TimelineItem>
          {isMemoLoaded
            ? flattenedMemoArray.map(([date, memoArray], key) => {
                return (
                  <span key={key}>
                    <Badge
                      variant="standard"
                      color="secondary"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={date}
                      classes={{ badge: classes.dateBadge }}
                      className={classes.badgePosition}
                      sx={{ top: key === 0 ? "0px" : "-10px", zIndex: 0 }}
                    />
                    {memoArray.map((item, index, { length }) => {
                      return (
                        <TimelineItem
                          key={index}
                          className={
                            index === memoArray.length - 1 && key !== memosEntriesLength - 1
                              ? classes.lastItemBeforeDateLength
                              : // : key === memosEntriesLength - 1 && index === memoArray.length - 1
                                // ? classes.lastItemLength
                                undefined
                          }
                          classes={{
                            missingOppositeContent: classes.missingOppositeContent
                          }}
                          id={item.groupRef || item.id}
                          ref={(el: HTMLDivElement) => {
                            if (location.state?.itemId && item.id === location.state?.itemId) itemRef.current = el;
                            else if (chatItemsNumber - chatItemsLimit >= 0 && matchItem(item)) topMemoRef.current = el;
                          }}
                        >
                          <TimelineSeparator>
                            <TimelineDot className={classes.dot} variant="outlined">
                              {item.ownerInfo?.address ? (
                                <Avatar
                                  url={`${AVATAR_URL}/${item.ownerInfo.address}`}
                                  imageClassName={classes.avatar}
                                  avatarClasses={{
                                    fontSizeLarge: classes.iconExtraLarge
                                  }}
                                  avatarFontSize="large"
                                  avatarSx={{
                                    color: item.ownerInfo.address ? colorMap[membersMap[item?.owner]] : "#A2A2A2"
                                  }}
                                />
                              ) : (
                                <AvatarIcon
                                  fontSize="large"
                                  sx={{
                                    color: item.ownerInfo?.address ? colorMap[membersMap[item?.owner]] : "#A2A2A2"
                                  }}
                                  classes={{
                                    fontSizeLarge: classes.iconExtraLarge
                                  }}
                                />
                              )}
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent className={classes.content}>
                            <ChatMemoItem
                              memo={item}
                              positionedBeforeDate={index === length - 1 && key !== memosEntriesLength - 1}
                              color={colorMap[membersMap[item.owner]] || "#A2A2A2"}
                            />
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </span>
                );
              })
            : ""}

          <TimelineItem classes={{ missingOppositeContent: classes.missingOppositeContent }} style={{ minHeight: "auto" }}>
            <TimelineSeparator className={classes.lockPosition}>
              <TimelineDot className={classes.dot}>
                <PadlockIcon classes={{ root: classes.iconExtraSmall }} />
              </TimelineDot>
            </TimelineSeparator>
          </TimelineItem>
        </Timeline>
        <div id="timeline-end" ref={chatEndRef} />
      </Box>
    </>
  );
};

export default VaultChat;
