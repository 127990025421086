import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, Divider, IconButton, Link, Typography } from "@mui/material";
import { ChevronLeftIcon, OpenIcon } from "@akord/addon-icons";
import { Collection, NFT } from "@akord/akord-js";
import NftGridList from "./NftGridList";
import { useNftCollectionContext } from "../../contexts/NftCollectionContextProvider";
import { Pagination } from "../../components/common";
import { usePagination } from "../../hooks/usePagination";
import { useNftContext } from "../../contexts/NftContextProvider";
import { getCollectionId, getVaultId } from "../../helpers/helpers";

interface CustomizedState {
  collection: Collection;
}

const NFTCollectionList: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [pageCollectionNfts, setPageCollectionNfts] = React.useState<NFT[]>();

  const { collectionNftsLoading, collectionNfts } = useNftCollectionContext();
  const { nftCollections } = useNftContext();

  const { pageNumber, pageCount, changePage, pageData, nextPage, previousPage } = usePagination(collectionNfts, 40);

  const isPublicRoute = !!location?.pathname.match("/public/"); // All anonymous routes have `/public` prefix
  const collectionId = getCollectionId(location.pathname);
  const vaultId = getVaultId(location.pathname);

  let collectionToShow: Collection;

  if (!location.state) {
    const foundCollection = nftCollections.find(collection => collection.id === collectionId);
    collectionToShow = foundCollection as Collection;
  } else {
    const { collection } = location.state as CustomizedState;
    collectionToShow = collection;
  }

  React.useEffect(() => {
    setPageCollectionNfts(pageData() as NFT[]);
  }, [pageNumber, pageData]);

  return (
    <Box display="flex" flexDirection="column" m={5}>
      <Box mb={5}>
        <Box mb={3} display="flex" justifyContent="space-between">
          <Box flexDirection="row" display="flex">
            <IconButton
              style={{ padding: 0 }}
              onClick={() =>
                isPublicRoute ? history.push(`/public/vaults/active/${vaultId}/nfts`) : history.push(`/vaults/active/${vaultId}/nfts`)
              }
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
            <Typography variant="h2" color="text.primary" style={{ whiteSpace: "pre" }}>
              {collectionToShow.name}
            </Typography>
          </Box>
          {collectionToShow?.data && collectionToShow.data[1] && collectionToShow.ucm && (
            <Box>
              <Link
                href={`https://bazar.arweave.dev/#/collection/${collectionToShow.data[1]!}`}
                color="text.secondary"
                target="_blank"
                variant="body2"
                className="small"
              >
                View on BazAR
              </Link>

              <IconButton
                sx={{ ml: 2 }}
                onClick={() =>
                  window.open(
                    collectionToShow?.data
                      ? `https://bazar.arweave.dev/#/collection/${collectionToShow.data[1]!}`
                      : `https://bazar.arweave.dev/#/collection`
                  )
                }
              >
                <OpenIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider />
      </Box>
      <NftGridList nfts={pageCollectionNfts} isLoading={collectionNftsLoading} />
      <Box display="flex" justifyContent="center" my={5}>
        <Pagination
          bottomPosition
          pages={pageCount}
          currentPage={pageNumber}
          onGoToNextPage={nextPage}
          onGoToPreviousPage={previousPage}
          onChangePage={changePage}
        />
      </Box>
    </Box>
  );
};

export default withRouter(NFTCollectionList);
