import React from "react";
import { ArweaveIcon, GlobeIcon, PadlockIcon, RadioCheckedIcon, RadioEmptyIcon, TickIcon, VaultIcon } from "@akord/addon-icons";
import { Box, Radio, Typography, Theme, Button, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useVaultCreateContext } from "../../../contexts/VaultCreateContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";

type NewVaultCreateFlowStyleProps = {
  isMobile: boolean;
};

type EventLikeObject = {
  target: { value: "NFTAssets" | "PrivateFiles" };
};

const useStyles = makeStyles<Theme, NewVaultCreateFlowStyleProps>(theme => ({
  box: {
    width: ({ isMobile }) => (isMobile ? "100%" : "380px"),
    border: "1px solid",
    borderColor: theme.palette.background.cardBorder,
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "16px",
    cursor: "pointer",
    maxWidth: "380px"
  },
  badgeBox: {
    border: "1px solid",
    borderColor: theme.palette.primary.light,
    borderRadius: "4px",
    padding: "0 6px 3px"
  },
  radio: {
    marginTop: "4px",
    padding: "0 8px 0 0",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-checked:hover": {
      backgroundColor: "transparent"
    }
  }
}));

const NewVaultCreateFlow: React.FC<RouteComponentProps> = ({ history }) => {
  const [selectedValue, setSelectedValue] = React.useState<"NFTAssets" | "PrivateFiles">();

  const { isMobile, width } = useGlobalContext();
  const { onVaultFastChange } = useVaultCreateContext();
  const classes = useStyles({ isMobile });
  const isScreenSm = width === "sm";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | EventLikeObject) => {
    setSelectedValue(event.target.value as "NFTAssets" | "PrivateFiles");
  };

  const handleVaultSetUp = () => {
    if (selectedValue === "NFTAssets") onVaultFastChange(true, false);
    else onVaultFastChange(false, false);
    history.push({ pathname: "/create-vault", state: selectedValue });
  };

  return (
    <Box
      textAlign="center"
      mx={isMobile ? 4 : 6}
      height="100%"
      display="flex"
      flexDirection="column"
      mt={isMobile ? 7 : 10}
      alignItems="center"
    >
      <Box mb={4}>
        <Box
          sx={{
            margin: "auto",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1px solid ",
            borderColor: "primary.main",
            display: "flex"
          }}
        >
          <VaultIcon
            sx={{
              margin: "auto",
              color: "primary.main"
            }}
          />
        </Box>
      </Box>
      <Box mb={7}>
        <Typography variant="h1" color="text.primary">
          What are you uploading?
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={6} flexDirection={isMobile || isScreenSm ? "column" : "row"}>
        <Box
          flexDirection="column"
          textAlign="left"
          className={classes.box}
          onClick={() => handleChange({ target: { value: "NFTAssets" } })}
          sx={{ backgroundColor: selectedValue === "NFTAssets" ? "background.card" : "transparent" }}
          mr={isMobile || isScreenSm ? 0 : 6}
        >
          <Box display="flex" alignItems="center" mb={5} width="100%">
            <Radio
              checked={selectedValue === "NFTAssets"}
              onChange={handleChange}
              value="NFTAssets"
              size="small"
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              name="radio-buttons"
              inputProps={{ "aria-label": "NFTAssets" }}
            />
            <Typography variant={isMobile ? "body2" : "body1"} className="strong" color="text.primary">
              NFT assets / public archives
            </Typography>
            <GlobeIcon fontSize="small" color="disabled" sx={{ marginLeft: "auto" }} />
            <ArweaveIcon fontSize="small" color="disabled" sx={{ marginLeft: 1 }} />
          </Box>
          <Box>
            <Typography variant="body2" color="text.primary" className="small" mb={2}>
              Files are permanently stored on the Arweave blockchain. All data is public and immutable.
            </Typography>
          </Box>
        </Box>
        <Box
          flexDirection="column"
          textAlign="left"
          className={classes.box}
          onClick={() => handleChange({ target: { value: "PrivateFiles" } })}
          sx={{ backgroundColor: selectedValue === "PrivateFiles" ? "background.card" : "transparent" }}
        >
          <Box display="flex" alignItems="center" mb={5} width="100%">
            <Radio
              checked={selectedValue === "PrivateFiles"}
              onChange={handleChange}
              value="PrivateFiles"
              size="small"
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              name="radio-buttons"
              inputProps={{ "aria-label": "PrivateFiles" }}
            />
            <Typography variant={isMobile ? "body2" : "body1"} className="strong" color="text.primary">
              Private files
            </Typography>
            <PadlockIcon fontSize="small" color="disabled" sx={{ marginLeft: "auto" }} />
            <ArweaveIcon fontSize="small" color="disabled" sx={{ marginLeft: 1 }} />
          </Box>
          <Box>
            <Typography variant="body2" className="small" color="text.primary" mb={2}>
              All data is end-to-end encrypted on Arweave. Collaborate with others in privacy.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Button
          onClick={handleVaultSetUp}
          disabled={!selectedValue}
          variant="contained"
          color="primary"
          component="span"
          disableElevation
          sx={{ minWidth: "auto" }}
        >
          Setup vault
        </Button>
        <Box mt={5}>
          <Link variant="body2" color="text.tertiary" onClick={() => history.push("/create-vault/storage")}>
            Need cloud storage? Customise your setup
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(NewVaultCreateFlow);
