import React from "react";
import { ConfirmationModal, FormModal } from "..";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { filesToUpdate } from "../../../helpers/file-helpers";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useUploader } from "../../file/FileUploader";

function NotificationsWrapper({ children }) {
  const { perPageStacks, showVersionUpload } = useAssetsContext();
  const { modal, uploadedFiles, onDuplicateFiles, uploadRetry } = useNotificationsContext();
  const { upload } = useUploader();

  React.useEffect(() => {
    const checkFilesForDuplicates = files => {
      const filesToUpdateArray = filesToUpdate(files, perPageStacks);
      if (filesToUpdateArray.length > 0) {
        onDuplicateFiles(filesToUpdateArray);
        return true;
      } else return false;
    };

    if (!uploadedFiles?.length) return;
    // showVersionUpload indicates a new version upload from the context menu, we don't need to check for duplicates
    if (checkFilesForDuplicates(uploadedFiles) && !showVersionUpload) {
      modal.onConfirmModalType("duplicateFile");
      modal.onModalVisibility(null, "duplicateFile");
    } else if (uploadRetry) {
      modal.onConfirmModalType("confirmUploadRetry");
      modal.onModalVisibility(null, "confirmUploadRetry");
    } else {
      modal.onConfirmModalType("confirmUpload");
      modal.onModalVisibility(null, "confirmUpload");
    }
  }, [uploadedFiles]);

  return (
    <>
      {modal.confirmModalFormType && <FormModal />}
      {modal.confirmModalType && <ConfirmationModal />}
      {children}
    </>
  );
}

export default NotificationsWrapper;
