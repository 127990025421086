import React from "react";
import { Auth } from "@akord/akord-js";
import { AkordWallet } from "@akord/crypto";
import { Box, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, Typography, TextField } from "@mui/material";
import { ButtonGroup, CountryAutoCompleteSelect, ErrorAlert, RevealToggler, SupportScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useHistory } from "react-router-dom";

const SecurityPhoneNumberMfa: React.FC = () => {
  const history = useHistory();
  const { isMobile } = useGlobalContext();
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneCountryPhomeCode, setPhoneCountryPhoneCode] = React.useState("");
  const [phoneCountryCode, setPhoneCountryCode] = React.useState("");
  const [correctPassword, setCorrectPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isDisabled = () => {
    return password?.length < 6 || phoneNumber?.length < 9 || !phoneCountryPhomeCode || loading;
  };

  const setupMFA = async () => {
    try {
      setLoading(true);
      await checkCurrentPassword();
      const phoneNumberString = `+${phoneCountryPhomeCode}${phoneNumber}`;
      await Auth.registerPhoneNumber(phoneNumberString);
      history.push("/account/security/two-factor-authentication/phone-number/verify");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleOnPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handleOnPhoneCountryCodeChange = (event: any, value: { phone: string; code: string }) => {
    setPhoneCountryPhoneCode(value?.phone);
    setPhoneCountryCode(value?.code);
  };

  const checkCurrentPassword = async () => {
    try {
      const attributes = await Auth.getUserAttributes();
      const encBackupPhrase = attributes["custom:encBackupPhrase"];
      await AkordWallet.importFromEncBackupPhrase(password, encBackupPhrase);
      setCorrectPassword(true);
    } catch (err) {
      setCorrectPassword(false);
      throw new Error("Incorrect password");
    }
  };

  return (
    <SupportScreenWrapper title="Add 2FA phone number" route="/account/security/two-factor-authentication">
      <Box mt={5} mb={6}>
        <Typography variant="body2" color="text.primary" sx={{ marginBottom: 4 }}>
          We’ll send a verification code to this number. You’ll need it for the next step.
        </Typography>
        <Box display={"flex"} width={isMobile ? "100%" : "450px"}>
          <Box flexBasis="38%">
            <CountryAutoCompleteSelect
              props={{ onChange: handleOnPhoneCountryCodeChange, disableClearable: true }}
              usePhone
              useFlag
              codeValue={phoneCountryCode}
            />
          </Box>
          <Box flexBasis="62%" marginLeft="16px">
            <TextField
              sx={{ width: "100%" }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              label="Phone"
              variant="outlined"
              name="phone"
              value={phoneNumber}
              onChange={handleOnPhoneChange}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        <Typography variant="body2" color="text.primary" sx={{ marginBottom: 4, width: "100%" }}>
          Enter your password to confirm this phone number as your 2FA.
        </Typography>
        <Box width={isMobile ? "100%" : "450px"}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="password-input">Password</InputLabel>
            <OutlinedInput
              spellCheck="false"
              id="password-input"
              aria-describedby="password-input"
              value={password}
              label="Password"
              onChange={ev => {
                setCorrectPassword(true);
                setPassword(ev.target.value);
              }}
              type={showPassword ? "text" : "password"}
              //classes={{ adornedEnd: classes.inputAdornment }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    <RevealToggler showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <ButtonGroup
        loading={loading}
        noMinWidth={true}
        nextText="Send code"
        nextDiasabled={isDisabled()}
        handleNext={() => setupMFA()}
        hideBackButton={true}
        fullWidthNextButton={isMobile ? true : false}
        hideIcon={true}
        disabledRed={false}
      />
      {!correctPassword && password?.length >= 6 && <ErrorAlert getErrorText="Wrong password. Please check and try again." />}
    </SupportScreenWrapper>
  );
};

export default SecurityPhoneNumberMfa;
