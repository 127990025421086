import React from "react";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import SnackBarConfirm from "../SnackBar/SnackBarConfirm";
import SnackBarConfirmUpload from "../SnackBar/SnackBarConfirmUpload";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import ThemeWrapper from "./ThemeWrapper";

function SnackBarWrapper({ children }) {
  const { darkMode } = useGlobalContext();
  const { snackbarToShow } = useSnackbarContext();

  return (
    <>
      <ThemeWrapper darkMode={darkMode}>
        {snackbarToShow?.type?.includes("upload") ? (
          <SnackBarConfirmUpload />
        ) : (
          <SnackBarConfirm />
        )}
      </ThemeWrapper>
      {children}
    </>
  );
}

export default SnackBarWrapper;
