import React from "react";
import { Box, Typography, useMediaQuery, createTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Logo from "../../components/common/logo/logoOrange";
import { ButtonGroup, ErrorAlert, FormSubmit, PageWrapper } from "../../components/common";
import { useLoginContext } from "../../contexts/LoginContext";
import { MuiOtpInput } from "mui-one-time-password-input";
import { theme as akordTheme } from "../../theme/mui";
import { akordLinks } from "../../helpers/akordTexts";

const useStyles = makeStyles({
  logo: {
    fill: "currentColor",
    color: "white",
    display: "block",
    margin: "0 0 24px 0",
    cursor: "pointer"
  },
  main: {
    justifyContent: ({ isDesktop }: { isDesktop: boolean }) => (isDesktop ? "center" : "inherit")
  },
  snackbar: {
    left: "16px",
    right: "16px",
    bottom: "16px",
    "@media (min-width: 700px)": {
      width: "400px",
      left: "50%"
    }
  }
});

const VerifyPage: React.FC<RouteComponentProps> = ({ history }) => {
  const theme = createTheme(akordTheme());
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const classes = useStyles({
    isDesktop: isDesktop
  });
  const {
    error,
    handleError,
    getErrorText,
    onSignIn,
    onConfirmSignIn,
    onOtp,
    otp,
    loading,
    handleTextClicked,
    textClicked,
    mfaType,
    mfaDevice
  } = useLoginContext();

  const isButtonDisabled = (): boolean => {
    return otp?.length !== 6 || loading;
  };

  const handleChange = (value: string) => {
    onOtp(value);
    handleError(null);
  };

  const handleResend = () => {
    handleTextClicked(true);
    setTimeout(() => {
      handleTextClicked(false);
    }, 1500);
    onSignIn();
  };

  return (
    <PageWrapper error={error} darkMode={true} showBottomNav={false} mainClassName={classes.main} pageTitle="Login">
      <Box>
        <FormSubmit onSubmit={() => onConfirmSignIn()}>
          <Box mb={6}>
            <Logo width="90" height="35" className={classes.logo} onClick={() => window.open(akordLinks.base, "_self")} />
            <Box mb={6}>
              <Typography variant="h1">Two-factor authentication</Typography>
            </Box>
          </Box>
          <Box mt={5} mb={6}>
            {mfaType === "SMS" ? (
              <>
                <Typography component="span" variant="body2" color="text.primary">
                  Enter your 6 digit code below.{" "}
                </Typography>
                <Typography component="a" color="text.primary" variant="body2" onClick={() => handleResend()}>
                  {textClicked ? "Code sent!" : "Resend code."}
                </Typography>
              </>
            ) : mfaDevice ? (
              <Typography component="span" color="text.primary" variant="body2">
                Enter the 6-digit code from: {mfaDevice} below
              </Typography>
            ) : (
              <Typography component="span" color="text.primary" variant="body2">
                Enter the 6-digit code below
              </Typography>
            )}
          </Box>
          <Box mt={3} width="100%">
            {/* @ts-ignore */}
            <MuiOtpInput length={6} value={otp} onChange={handleChange} autoFocus />
          </Box>
          <ButtonGroup
            type="submit"
            nextText="Authenticate"
            nextDiasabled={isButtonDisabled()}
            hideBackButton={true}
            fullWidthNextButton={true}
            hideIcon={true}
            errorText={getErrorText()}
            disabledRed={false}
            loading={loading}
            noMarginTop={true}
          />
          {error && <ErrorAlert getErrorText="Wrong code. Please check and try again." />}
          <Box mt={6}>
            <Box>
              <Typography component="span" variant="body2">
                {"Can't access your 2FA?"}
              </Typography>{" "}
              <Typography component="a" variant="body2" onClick={() => history.push("/recover")}>
                Recover your account
              </Typography>
            </Box>
          </Box>
        </FormSubmit>
      </Box>
    </PageWrapper>
  );
};

export default withRouter(VerifyPage);
