import React, { useState, useEffect, createContext, useContext } from "react";
import { useGlobalContext } from "./GlobalDataProvider";
import { deleteNotifications, getNotifications, readNotifications } from "../helpers/api-helpers";
import { Types } from "@akord/gql";
import { Vault } from "@akord/akord-js";
import { Encryptable, encrypted } from "@akord/crypto";
import { getVaultId } from "../helpers/helpers";

const EXCLUDED_NOTIFICATIONS = ["ZIP_COMMITTED", "ZIP_SIGNED"];

interface InAppNotificationsTypes {
  notifications: Types.Notification[] | undefined;
  onLoad: () => Promise<void>;
  onRead: (params: { id?: string; vaultId?: string }) => Promise<void>;
  onDelete: (params: { status?: Types.NotificationStatus }) => Promise<void>;
}

class InAppNotificationObject extends Encryptable {
  constructor(name: string, __keys__: any) {
    super(__keys__, "");
    this.name = name;
  }
  @encrypted() name: string;
}

const Context = createContext({} as InAppNotificationsTypes);

const InAppNotificationsContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { akord, wallet, onTxSpinner, notification } = useGlobalContext();

  const [inAppNotifications, setInAppNotifications] = useState<Types.Notification[]>();
  const handleInAppNotifications = (data: Types.Notification[]) => setInAppNotifications(data);

  useEffect(() => {
    if (wallet && akord) {
      handleLoad();
    }
  }, [akord, wallet]);

  // Reload when on notifications page
  useEffect(() => {
    if (location.pathname.match("/notifications")) {
      if (wallet && akord) {
        handleLoad({ silent: true });
      }
    }
  }, [location.pathname, akord, wallet]);

  // Read notifications when on vault page (non priority only; this is handled on API level)
  useEffect(() => {
    const vaultId = getVaultId(location.pathname);
    if (vaultId) {
      setTimeout(() => {
        handleRead({ vaultId });
      }, 3000);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (notification) {
      handleAdd(notification);
    }
  }, [notification]);

  const handleLoad = async (params: { silent: boolean } = { silent: false }) => {
    try {
      if (!akord) return;
      const notificaitonsResponse = await getNotifications();
      try {
        onTxSpinner(!params.silent);
        const notifications: Types.Notification[] = [];
        for (const notification of (notificaitonsResponse?.items || []) as Types.Notification[]) {
          if (!EXCLUDED_NOTIFICATIONS.includes(notification?.event || "")) notifications.push(await map(notification));
        }
        handleInAppNotifications(notifications);
      } catch (e) {
        console.log(e);
      }
      onTxSpinner(false);
    } catch (err) {
      console.error("Load notifications", err);
    }
  };

  const map = async (notification: Types.Notification) => {
    if (!notification.admin) {
      if (notification.vault && notification.toMember?.keys?.length) {
        try {
          const vault = new Vault(notification.vault, notification.toMember.keys as any);
          await vault.decrypt();
          notification.vault = { ...vault } as any;
        } catch (e) {
          console.warn("Failed to decrypt vault", e);
          notification.vault = null;
        }
      }
      if (notification.objectName && notification.toMember?.keys) {
        const object = new InAppNotificationObject(notification.objectName, notification.toMember.keys as any);
        try {
          await object.decrypt();
          notification.objectName = object.name;
        } catch (e) {
          console.warn("Failed to decrypt notificaiton object", e);
          notification.objectName = null;
        }
      }
    }
    return notification;
  };

  const handleAdd = async (notification: Types.Notification) => {
    handleInAppNotifications([await map(notification), ...(inAppNotifications || [])]);
  };

  const handleRead = async (params: { id?: string; vaultId?: string } = {}) => {
    handleInAppNotifications(
      (inAppNotifications || []).map(notification => {
        if (params.id && notification.id === params.id) {
          notification.status = Types.NotificationStatus.READ;
        } else if (params.vaultId && notification.vaultId === params.vaultId && notification.priority !== true) {
          notification.status = Types.NotificationStatus.READ;
        }
        return notification;
      })
    );
    await readNotifications(params);
  };

  const handleDelete = async (params: { status?: Types.NotificationStatus } = {}) => {
    handleInAppNotifications(
      (inAppNotifications || []).filter(notification => {
        if (params.status) {
          return notification.status !== params.status;
        } else {
          return notification.priority && notification.status === Types.NotificationStatus.UNREAD; //leave unread priority notifications
        }
      })
    );
    await deleteNotifications(params);
  };

  return (
    <Context.Provider
      value={{
        notifications: inAppNotifications,
        onLoad: handleLoad,
        onRead: handleRead,
        onDelete: handleDelete
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default InAppNotificationsContextProvider;

export const withInAppNotificationsContext = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{inAppNotificationsContext => <Component {...props} {...inAppNotificationsContext} />}</Context.Consumer>;

export const useInAppNotificationsContext = () => useContext(Context);
