import React from "react";
import { InfoIcon } from "@akord/addon-icons";
import { Box, IconButton, Typography } from "@mui/material";
import { InfoModalTypes } from "../InfoModal/InfoModal";

type TitleWithSubtitleBlockProps = {
  boxTitle: string;
  boxSubtitle: string;
  boxType: InfoModalTypes;
  handleModal: (boxType: InfoModalTypes) => void;
};

const TitleWithSubtitleBlock: React.FC<TitleWithSubtitleBlockProps> = ({ boxTitle, boxSubtitle, boxType, handleModal }) => {
  return (
    <Box mb={4}>
      <Box display="flex" alignItems="flex-start" mb={1}>
        <Typography variant="h3">{boxTitle}</Typography>
        <IconButton onClick={() => handleModal(boxType)}>
          <InfoIcon fontSize="small" sx={{ marginLeft: 2 }} />
        </IconButton>
      </Box>
      <Typography variant="body2" className="small">
        {boxSubtitle}
      </Typography>
    </Box>
  );
};

export default TitleWithSubtitleBlock;
