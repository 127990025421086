import React from "react";
import { DialogActions, Button, CircularProgress, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getTotalUploadSize } from "../../../helpers/helpers";
import { confirmationModalOptions } from "./confirmation-modal-options";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useHistory } from "react-router-dom";
import { useStorageContext } from "../../../contexts/StorageContextProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { FileWithAction, useUploader } from "../../file/FileUploader";
import UseConfirmModal from "./useConfirmModal";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { ModalType } from "../../../hooks/useModal";

type TwoButtonConfirmStyleProps = {
  isDesktop: boolean;
  alignRight: boolean;
};

const useStyles = makeStyles<Theme, TwoButtonConfirmStyleProps>(theme => ({
  dialogActions: {
    justifyContent: ({ alignRight }) => (alignRight ? "flex-end" : "flex-end"),
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column-reverse")
  },
  buttonGroup: {
    minWidth: "auto",
    marginLeft: ({ isDesktop }) => (isDesktop ? "24px!important" : 0),
    "&:last-child": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
    }
  },
  buttonProgress: {
    position: "absolute"
  },
  topUpButton: {
    marginRight: "auto",
    marginTop: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
  }
}));

type TwoButtonConfirmProps = {
  selectedFolderId: string | null;
  confirmationType: ModalType;
  onUploadFileIndex: (num: number) => void;
  filesWithAction: React.MutableRefObject<FileWithAction[]>;
  uploadedAndConfirmedFiles: File[] | null;
  handleUploadedAndConfirmedFiles: (files: File[] | null) => void;
  isSizeExceedLimit: boolean;
  onConfirmationError: (error: any) => void;
  confirmationError: any;
  // isProccessingFilesForUploadModal: boolean;
  isProccessingFilesForUploadModalRef: React.MutableRefObject<boolean>;
  selectedManifestValue?: "Arweave" | "Opensea";
};

const TwoButtonConfirm: React.FC<TwoButtonConfirmProps> = ({
  selectedFolderId,
  confirmationType,
  onUploadFileIndex,
  filesWithAction,
  uploadedAndConfirmedFiles,
  handleUploadedAndConfirmedFiles,
  isSizeExceedLimit,
  onConfirmationError,
  confirmationError,
  isProccessingFilesForUploadModalRef,
  selectedManifestValue
}) => {
  const [loading, setLoading] = React.useState(false);

  const { globalStorage } = useStorageContext();
  const { width, userAttributes } = useGlobalContext();
  const { modal, onNotificationData, clearUploadEventRef, onUploadRetry, onUploadFiles, notificationData } = useNotificationsContext();
  const { handleModalConfirm } = UseConfirmModal();
  const { onDrawerActionType, onShowLoaderByVaultId, showLoaderByVaultId } = useAssetsContext();
  const { vault } = useVaultContext();

  const { upload } = useUploader();

  const isDesktop = width !== "xs";
  const history = useHistory();
  const isNote = notificationData?.fileType === "note";

  const confirmModalType = modal.confirmModalType as ModalType;
  const { showCancel, buttonText } = confirmationModalOptions[confirmModalType];

  const classes = useStyles({
    isDesktop: isDesktop,
    alignRight: showCancel
  });

  React.useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  // close confirmation modal if all files were excluded
  React.useEffect(() => {
    if (!uploadedAndConfirmedFiles) return;
    if (!uploadedAndConfirmedFiles.length) {
      modal.onModalVisibility(null, confirmModalType);
      handleUploadedAndConfirmedFiles(null);
      filesWithAction.current = [];
      onUploadFileIndex(0);
    }
  }, [uploadedAndConfirmedFiles]);

  const storageAllowance = (vault?.cloud ? globalStorage?.cloud_storage_available : globalStorage?.storage_available) || 0;

  const handleConfirm = async () => {
    if (["confirmUpload", "confirmUploadRetry"].includes(confirmModalType)) {
      try {
        if (!uploadedAndConfirmedFiles) return;
        await new Promise(resolve => setTimeout(resolve, 500));
        modal.onModalVisibility(null, confirmModalType);
        await upload(uploadedAndConfirmedFiles, filesWithAction);
      } catch (error) {
        console.warn("Error on upload confirmation:", error);
        setLoading(false);
        if (showLoaderByVaultId.includes(vault.id)) onShowLoaderByVaultId(vault.id);
        onConfirmationError(error);
        // modal.onModalVisibility(null, confirmModalType); // TODO to show retry every time we dont need to close it here
      }
    } else {
      try {
        await handleModalConfirm(confirmModalType);
        modal.onModalVisibility(null, confirmModalType);
        modal.onConfirmModalType(null);
      } catch (error) {
        console.warn("Error on confirmation: ", error);
        setLoading(false);
        if (showLoaderByVaultId.includes(vault.id)) onShowLoaderByVaultId(vault.id);
        onConfirmationError(error);
      }
    }
  };

  const isButtonDisabled = () => {
    if (loading) return true;
    if (confirmationType === ("move" as ModalType) && selectedFolderId === null) return true;
    if (confirmationType === ("confirmUpload" as ModalType)) {
      if (storageAllowance < (uploadedAndConfirmedFiles ? getTotalUploadSize(uploadedAndConfirmedFiles) : 0) || isSizeExceedLimit)
        return true;
      if (isProccessingFilesForUploadModalRef.current || !uploadedAndConfirmedFiles) return true;
    }
    if (confirmModalType === "createManifest" && !selectedManifestValue) return true;

    return false;
  };

  const isLoaderVisible = () => {
    if (loading) return true;
    if (
      confirmationType === ("confirmUpload" as ModalType) &&
      !isNote &&
      (isProccessingFilesForUploadModalRef.current || !uploadedAndConfirmedFiles)
    )
      return true;
    return false;
  };

  const isPreparingButton = () => {
    if (
      confirmationType === ("confirmUpload" as ModalType) &&
      !isNote &&
      (isProccessingFilesForUploadModalRef.current || !uploadedAndConfirmedFiles)
    )
      return true;
    return false;
  };

  return (
    <DialogActions className={classes.dialogActions}>
      {confirmationType === ("confirmUpload" as ModalType) &&
        uploadedAndConfirmedFiles &&
        storageAllowance < getTotalUploadSize(uploadedAndConfirmedFiles) &&
        !isProccessingFilesForUploadModalRef.current &&
        !userAttributes.passwordless && (
          <Button
            onClick={() => {
              clearUploadEventRef();
              modal.onModalVisibility(null, confirmModalType);
              modal.onConfirmModalType(null);
              onDrawerActionType();
              history.push(vault.cloud ? "/account/plans-and-payments/pricing-plans" : "/top-up");
            }}
            variant="outlined"
            color="primary"
            fullWidth={isDesktop ? false : true}
            className={classes.topUpButton}
          >
            {vault.cloud ? "Upgrade" : "Top up"}
          </Button>
        )}
      {showCancel && (
        <Button
          onClick={() => {
            clearUploadEventRef();
            modal.onModalVisibility(null, confirmModalType);
            modal.onConfirmModalType(null);
            onDrawerActionType();
            onNotificationData(null);
            onUploadRetry(false);
            onUploadFiles([]);
            if (showLoaderByVaultId.includes(vault.id)) onShowLoaderByVaultId(vault.id);
          }}
          color="primary"
          fullWidth={isDesktop ? false : true}
          className={classes.buttonGroup}
        >
          Cancel
        </Button>
      )}
      <Button
        disabled={isButtonDisabled()}
        color="primary"
        variant="contained"
        fullWidth={!isDesktop}
        type="button"
        disableElevation
        className={classes.buttonGroup}
        onClick={() => {
          setLoading(true);
          handleConfirm();
        }}
      >
        {confirmationError ? "Retry" : isPreparingButton() ? "Preparing..." : buttonText}
        {isLoaderVisible() && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
    </DialogActions>
  );
};

export default TwoButtonConfirm;
