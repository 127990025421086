import { Stack } from "@akord/akord-js";

export const filesToUpdate = (files: File[], stacks: Stack[]) => {
  let fileList: File[] = [];
  for (let i = 0; i < files.length; i++) {
    fileList.push(files[i]);
  }

  let namesToUpdateArray: { name: string; index: number }[] = [];
  fileList.map((file, index) => {
    return stacks.map(decrypted => {
      if (file.name === decrypted.name) return namesToUpdateArray.push({ name: file.name, index: index });
      return null;
    });
  });
  return namesToUpdateArray;
};
