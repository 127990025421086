import React from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { getNoteId } from "../../../helpers/helpers";
import { Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DialogFileViewerWrapper from "./DialogFileViewerWrapper";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import MDEditor from "@uiw/react-md-editor";
import {
  bold,
  codeBlock,
  strikethrough,
  title1,
  title2,
  title3,
  unorderedListCommand,
  orderedListCommand,
  link,
  preview,
  edit
} from "./markdown-commands";
import ButtonLink from "../ButtonLink";

const defaultRTValue = "";
const regexHeadings = /(?:__|[*#])|\[(.*?)\]\(.*?\)/gm;

const useStyles = makeStyles({
  noteContainer: {
    marginLeft: ({ isMobile }) => (isMobile ? "16px" : "auto"),
    marginRight: ({ isMobile }) => (isMobile ? "16px" : "auto"),
    width: ({ isMobile }) => (isMobile ? "auto" : "640px")
  },
  bodyEditor: {
    fontSize: "18px"
  }
});

function DialogNoteViewer() {
  const [noteTitle, setNoteTitle] = React.useState("");
  const [noteText, setNoteText] = React.useState(defaultRTValue);
  const [noteTextLoaded, setNoteTextLoaded] = React.useState(true);

  const { akord, darkMode, isMobile } = useGlobalContext();
  const { notes, stacks } = useAssetsContext();

  const { modal, onNotificationData } = useNotificationsContext();
  const classes = useStyles({ darkMode: darkMode, isMobile: isMobile });
  const originalNoteTitle = React.useRef();
  const originalNote = React.useRef();

  const history = useHistory();
  const noteId = getNoteId(history.location.pathname);

  const currentOriginalNote = notes?.filter(note => note.id === noteId)[0];
  const currentNoteFromStacks = stacks?.filter(stack => stack.id === noteId)[0];

  const currentNote = currentOriginalNote || currentNoteFromStacks || {};

  const handleOnChange = state => {
    setNoteText(state);
  };

  const handleNoteTitleChange = e => {
    setNoteTitle(e.target.value);
  };

  const handleNoteSave = async () => {
    //Create a name for the note from the first line of the note
    const firstLine = noteText.split("\n")[0];
    const noteTitleToSave = noteTitle || firstLine.replace(regexHeadings, "$1").trim();
    modal.onConfirmModalType("saveNote");
    modal.onModalVisibility(null, "saveNote");
    onNotificationData({
      id: currentNote?.id,
      hash: currentNote?.hash || null,
      noteText: noteText,
      folderId: history.location.state?.folderId || null,
      fileType: "note",
      title: noteTitleToSave + ".md" || "untitled.md"
    });
  };

  const getNoteViewerData = async note => {
    setNoteTextLoaded(false);
    const version = await akord.stack.getVersion(note.id, note.originalIndex);
    const blob = new Blob([version.data], {
      type: version.type
    });
    const content = await blob.text();

    if (content) {
      setNoteTitle(currentNote.name.replace(/\.[^/.]+$/, ""));
      setNoteText(content);
      originalNoteTitle.current = note.name.replace(/\.[^/.]+$/, "");
      originalNote.current = content;
    } else {
      setNoteText("ERROR: Unsupported format. Not a mark down note!");
      originalNote.current = "ERROR: Unsupported format. Not a mark down note!";
    }
    setNoteTextLoaded(true);
  };

  const isNoteChanged = () => {
    return originalNote.current === noteText && originalNoteTitle.current === noteTitle;
  };

  return (
    <DialogFileViewerWrapper isNote overflowYAuto showDropDown={!!noteId} onViewerData={getNoteViewerData} onlyDarkMode={darkMode}>
      {noteTextLoaded ? (
        <Box className={classes.noteContainer}>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            value={noteTitle}
            placeholder="Title"
            type="text"
            onChange={handleNoteTitleChange}
          />
          <div data-color-mode={darkMode ? "dark" : "light"}>
            <MDEditor
              textareaProps={{
                placeholder: "Start writing your note..."
              }}
              preview="edit"
              value={noteText}
              onChange={value => handleOnChange(value)}
              height={window.innerHeight - 250}
              extraCommands={[edit, preview]}
              commands={[bold, codeBlock, strikethrough, title1, title2, title3, unorderedListCommand, orderedListCommand, link]}
            />
          </div>
          <Box mt={5} display="flex" justifyContent="flex-end">
            <ButtonLink
              width="auto"
              disabled={!Object.values(noteText).some(text => text) || isNoteChanged()}
              onClick={handleNoteSave}
              size="small"
            >
              {noteId ? "Save new version" : "Save note"}
            </ButtonLink>
          </Box>
        </Box>
      ) : null}
    </DialogFileViewerWrapper>
  );
}

export default DialogNoteViewer;
