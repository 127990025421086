import React from "react";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton, Divider, Theme } from "@mui/material";
import { ChevronRightIcon } from "@akord/addon-icons";
import CopyBox from "./CopyBox";
import DataBox from "./DataBox";
import { infoMap } from "./infoMap";
import { NotificationData } from "../../../contexts/NotificationsContextProvider";

type InfoDrawerProps = {
  isMobile: boolean;
  showInfo: boolean;
  onShowInfo: (value: boolean) => void;
  infoData: NotificationData;
};

type InfoDrawerStyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles<Theme, InfoDrawerStyleProps>({
  paperAnchorRight: {
    width: ({ isMobile }) => (isMobile ? "85%" : "480px")
  },
  backDrop: {
    top: ({ isMobile }) => (isMobile ? "0" : "86px"),
    left: ({ isMobile }) => (isMobile ? "0" : "250px")
  },
  paper: {
    top: ({ isMobile }) => (isMobile ? "0" : "86px")
  },
  divider: {
    marginLeft: ({ isMobile }) => (isMobile ? -20 : 0),
    marginRight: ({ isMobile }) => (isMobile ? -20 : 0)
  }
});

const InfoDrawer: React.FC<InfoDrawerProps> = ({ isMobile, showInfo, onShowInfo, infoData }) => {
  const classes = useStyles({
    isMobile: isMobile
  });

  if (!infoData) return null;

  const isVault = infoData.type === "vault";
  const isStack = infoData.type === "stack";
  const isFolder = infoData.type === "folder";
  const currentInfoType = infoData.type;

  const currentInfoMap = currentInfoType ? infoMap[currentInfoType] : null;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent | React.FormEvent) => {
    if (event?.type === "keydown" && ((event as React.KeyboardEvent)?.key === "Tab" || (event as React.KeyboardEvent).key === "Shift"))
      return;

    onShowInfo(open);
  };

  if (!currentInfoMap) return null;

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={showInfo}
      onClose={toggleDrawer(false)}
      BackdropProps={{ className: classes.backDrop }}
      PaperProps={{ className: classes.paper }}
      classes={{
        paperAnchorRight: classes.paperAnchorRight
      }}
    >
      <Box ml={5} mr={5} mt={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" color="text.primary" noWrap>
            {infoData[currentInfoType as keyof NotificationData].name}
          </Typography>
          <IconButton sx={{ padding: 0 }} onClick={toggleDrawer(false)} size="large">
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Box mb={3}>
          <Typography variant="body2">
            {isVault && "Vault info"}
            {isStack && "File info"}
            {isFolder && "Folder info"}
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <Box pt={5} height="calc(100% - 47px)" sx={{ overflowY: !isMobile ? "auto" : "none" }}>
          {currentInfoMap!.copyText(infoData).map((data, index) => {
            return data.show ? (
              <CopyBox
                key={index}
                dataKey={data.key}
                dataValue={data.value}
                tooltipText={data.tooltipText}
                isMobile={isMobile}
                extraActionText={data.extraActionText}
                extraActionData={data.extraActionData}
                arweaveStatus={data.arweaveStatus}
                showStatus={data.showStatus}
                infoText={data.infoText}
                lastItem={currentInfoMap.copyText(infoData).length === index + 1}
              />
            ) : null;
          })}
          {currentInfoMap!.text(infoData).map((data, index) => {
            return data.show ? <DataBox key={index} dataKey={data.key} dataValue={data.value} dataIcon={data.icon} /> : null;
          })}
        </Box>
      </Box>
    </Drawer>
  );
};

export default InfoDrawer;
