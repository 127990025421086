import React from "react";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import { ChevronLeftIcon, CloseIcon } from "@akord/addon-icons";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import MoreActionButton from "../MoreActionButton";
import MenuNotifications from "../MoreMenu/MenuNotifications";
import ReferralBanner from "../ReferralBanner";
import TransactionSpinner from "../TransactionSpinner";

type SupportScreenWrapperProps = {
  title: string;
  subtitle?: string;
  route?: string;
  hideChevron?: boolean;
  hideDivider?: boolean;
  showMenu?: boolean;
  hideElements?: boolean;
  showCloseButton?: boolean;
  showSaveButton?: boolean;
  referral?: string | null;
  spaceBetween?: boolean;
  component?: JSX.Element;
  fullHeight?: boolean;
};

const SupportScreenWrapper: React.FC<SupportScreenWrapperProps & RouteComponentProps & React.ReactNode> = (props) => {
  const {
    children,
    title,
    subtitle,
    route,
    hideChevron,
    hideDivider,
    history,
    showMenu,
    hideElements = false,
    showCloseButton = false,
    showSaveButton = false,
    referral,
    spaceBetween = false,
    component,
    fullHeight = false
  } = props;

  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const { isMobile, darkMode, txSpinner } = useGlobalContext();

  const openMenu = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={isMobile && !fullHeight ? "calc(100% - 59px)" : "100%"}
      position={isMobile ? "absolute" : "inherit"}
      overflow="auto"
      width="100%"
    >
      {showCloseButton && route && (
        <Box display="flex" right="0" alignSelf="flex-end" p={4} zIndex="2" mb="-56px">
          <IconButton onClick={() => history.push(route)} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box
        px={isMobile ? 5 : 4}
        width="100%"
        maxWidth="576px"
        height={!isMobile ? "auto" : spaceBetween ? "100%" : "auto"}
        alignSelf="center"
        mb={6}
      >
        {!hideElements && (
          <Box>
            <Box mt={4} mb={3} width="100%">
              <Box display="flex" alignItems={showSaveButton ? "flex-start" : "center"} justifyContent="space-between" height="31px">
                <Box flexDirection="row" display="flex">
                  {!hideChevron && (
                    <IconButton style={{ padding: 0 }} onClick={() => (route ? history.push(route) : history.goBack())} size="large">
                      <ChevronLeftIcon />
                    </IconButton>
                  )}
                  <Typography variant="h2" color="text.primary" style={{ whiteSpace: "pre" }}>
                    {title}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  {txSpinner && !referral && <TransactionSpinner isMobile={isMobile} darkMode={darkMode} />}
                  {showMenu && (
                    <Box style={{ marginLeft: "16px" }}>
                      <MoreActionButton
                        horizontalButton
                        handleClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          handleMenu(e);
                        }}
                        menuActive={menuActive}
                        arialLabel="display more actions support screen"
                      />
                    </Box>
                  )}
                  {component && (
                    <Box right={0} ml={3}>
                      {component}
                    </Box>
                  )}
                </Box>
                {!isMobile && referral && <ReferralBanner />}
              </Box>
              {subtitle && (
                <Typography variant="body2" color="text.secondary">
                  {subtitle}
                </Typography>
              )}
              {isMobile && referral && (
                <Box mt={1}>
                  <ReferralBanner />
                </Box>
              )}
            </Box>
            {!hideDivider && <Divider />}
          </Box>
        )}
        {children}
      </Box>
      <MenuNotifications anchorEl={anchorEl} openMenu={openMenu} handleMenuClose={handleMenuClose} />
    </Box>
  );
};

export default withRouter(SupportScreenWrapper);
