import React from "react";
import { Typography, ListItem, ListItemIcon, ListItemText, Box, ListItemBaseProps, Collapse, List } from "@mui/material";
import { ChevronDownIcon, ChevronUpIcon } from "@akord/addon-icons";
import { MenuOptionsType } from "../MoreMenu/MenuOptions";
import { DrawerActionType } from "../../../contexts/AssetsContextProvider";
import { DropDownMenuProps } from "../NavBars/MenuOptions";

type MoreDrawerItemProps = {
  menuItem: MenuOptionsType | DropDownMenuProps;
  onDrawerActionType?: (action?: DrawerActionType | undefined) => void;
  showFolderUpload?: boolean;
  showVersionUpload?: boolean;
  showZipUpload?: boolean;
};

const Label = React.forwardRef((props: ListItemBaseProps, ref: any) => <label htmlFor="upload-file" {...props} />);

const MoreDrawerItem: React.FC<MoreDrawerItemProps> = ({
  menuItem,
  onDrawerActionType,
  showFolderUpload,
  showVersionUpload,
  showZipUpload
}) => {
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const hasChildrenItems = (menuItem as DropDownMenuProps)?.children?.length || false;
  const handleSubMenuOpen = () => setSubMenuOpen(prev => !prev);

  return (
    <Box my={4}>
      <ListItem
        disabled={menuItem.disabled}
        disableGutters
        onClick={hasChildrenItems ? handleSubMenuOpen : menuItem.action}
        component={menuItem.upload ? Label : "li"}
      >
        {menuItem.upload && !onDrawerActionType ? (
          <label
            htmlFor={
              showFolderUpload
                ? "contained-button-folder"
                : showVersionUpload
                ? "upload-file"
                : showZipUpload
                ? "upload-file-zip"
                : "contained-button-file"
            }
            style={{ display: "flex", cursor: "pointer" }}
          >
            <ListItemIcon>
              <menuItem.icon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2" color="text.primary">
                {menuItem.text}
              </Typography>
            </ListItemText>
          </label>
        ) : (
          <>
            <ListItemIcon>
              <menuItem.icon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2" color="text.primary">
                {menuItem.text}
              </Typography>
            </ListItemText>
            {hasChildrenItems ? subMenuOpen ? <ChevronUpIcon color="disabled" /> : <ChevronDownIcon color="disabled" /> : null}
          </>
        )}
      </ListItem>
      <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
        <List component="div">
          {hasChildrenItems &&
            (menuItem as DropDownMenuProps)?.children?.map((item, index) => {
              return (
                !item.disabled && (
                  <ListItem
                    key={index}
                    onClick={item.action}
                    sx={{
                      marginTop: 4,
                      marginBottom: index === ((menuItem as DropDownMenuProps)?.children?.length || 0) - 1 ? 0 : 4,
                      paddingLeft: "32px"
                    }}
                  >
                    <ListItemIcon>
                      <item.icon fontSize="small" color="disabled" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body2" color="text.primary">
                        {item.text}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )
              );
            })}
        </List>
      </Collapse>
    </Box>
  );
};

export default MoreDrawerItem;
