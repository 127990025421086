import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";

import { withRouter } from "react-router-dom";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Button, TableCell, TableRow, Box, Typography, Badge, Tooltip, CircularProgress, Checkbox, IconButton } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { VaultIcon, InfoIcon, GlobeIcon, PadlockIcon, TickIcon, InfinityIcon, CloudIcon } from "@akord/addon-icons";
import { formatDate, getVaultStatus } from "../../helpers/helpers";
import { memberStatusEnum } from "../../helpers/akord-enums";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import MenuRoom from "../../components/common/MoreMenu/MenuRoom";
import { RoomDrawer } from "../../components/common";
import MoreActionButton from "../../components/common/MoreActionButton";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import { bytesToGb } from "../storage/storage-helper";
import { useVaultsSelectionContext } from "../../contexts/VaultsSelectionContextProvider";
import { grey } from "../../theme/colors";
import { Types } from "@akord/gql";
import { Membership, Vault } from "@akord/akord-js";

type VaultItemStyleProps = {
  isArchived: boolean;
  isPending: boolean;
  isPublic: boolean;
  vaultChecked: boolean;
};

const useStyles = makeStyles<Theme, VaultItemStyleProps>(theme => ({
  iconBackGround: {
    background: ({ isArchived, isPending, isPublic, vaultChecked }) =>
      isArchived
        ? vaultChecked
          ? grey[500]
          : theme.palette.background.archived
        : isPublic
        ? isPending
          ? theme.palette.info.main
          : theme.palette.background.stack
        : isPending
        ? theme.palette.primary.main
        : theme.palette.background.active,
    color: ({ isArchived, vaultChecked }) => (isArchived ? (vaultChecked ? "#FFF" : grey[500]) : "none"),
    boxSizing: "content-box",
    padding: "3px",
    marginRight: theme.spacing(4),
    borderRadius: "3px"
  },
  toolLine: {
    display: "flex",
    justifyContent: "space-between",
    height: "56px",
    alignItems: "center"
  },
  checkBox: {
    padding: 0
  }
}));

const ToolTipText = () => {
  return (
    <Box m={2}>
      <Typography variant="body2" className="xs" sx={{ marginBottom: 2 }} color="text.primary">
        You’ve been invited to this vault, but your access is pending a final access confirmation.
      </Typography>
      <Typography variant="body2" className="xs" sx={{ marginBottom: 2 }} color="text.primary">
        To speed up the process, you can ask the person who invited you to confirm your access. They should have email and in-app
        notifications with links to confirm.
      </Typography>
      <Typography variant="body2" className="xs" sx={{ marginBottom: 2 }} color="text.primary">
        Once they confirm you’ll be asked to view the invite and accept.
      </Typography>
      <Typography variant="body2" className="xs" color="text.primary">
        This flow enables us to complete the necessary end-to-end encryption to secure your vault.
      </Typography>
    </Box>
  );
};

type VaultItemProps = {
  vault?: Vault;
  membership?: Membership;
  isArchived?: boolean;
};

type LocationState = {
  itemId?: string;
};

const VaultItem: React.FC<RouteComponentProps<{}, StaticContext, LocationState> & VaultItemProps> = ({
  vault,
  membership,
  isArchived = false,
  history,
  location
}) => {
  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [currentMembership, setCurrentMembership] = React.useState<Membership | undefined>(membership); //keep it as a state; loaded on demand
  const [members, setMembers] = React.useState<Membership[]>([]); //keep it as a state; loaded on demand
  const [iconHovered, setIconHovered] = React.useState(false);
  const handleIconHovered = (action: boolean) => setIconHovered(action);

  const toggleIcon = () => handleIconHovered(!iconHovered);

  const openMenu = Boolean(anchorEl);
  const { akord, userAttributes, width, isMobile, onPendingMemberships, pendingMemberships } = useGlobalContext();
  const { notifications } = useInAppNotificationsContext();
  const { onSelectedVaults, selectedVaultsMap, onLastSelectedVault } = useVaultsSelectionContext();
  const vaultChecked = selectedVaultsMap?.has(vault?.id);

  const theme = useTheme();

  const itemId = location.state?.itemId;
  const itemRef = useRef<HTMLTableRowElement>(null);

  const isPublic = vault?.public ?? false;
  const isCloud = vault?.cloud ?? false;

  const isMembershipPending = membership?.status === memberStatusEnum.PENDING && !isPublic;

  const isMembershipInvited = membership?.status === memberStatusEnum.INVITED && !isPublic;

  const isMembershipProcessing = vault?.status === memberStatusEnum.PROCESSING;

  const classes = useStyles({
    isArchived: isArchived || isMembershipProcessing,
    isPending: isMembershipPending || isMembershipInvited,
    isPublic: isPublic,
    vaultChecked: vaultChecked
  });

  useEffect(() => {
    const getMembership = async (vaulId: string) => {
      const memberships = await akord!.membership.listAll(vaulId);
      setMembers(memberships);
      const membership = memberships.find(
        member =>
          member.memberPublicSigningKey === userAttributes.publicSigningKey ||
          member.address === userAttributes.address ||
          member.memberDetails.publicSigningKey === userAttributes.publicSigningKey
      );
      membership && setCurrentMembership(membership);
    };
    if (itemId && vault?.id && itemId === vault.id && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
    if (akord && vault?.id && !isMembershipPending && !isMembershipInvited && !isMembershipProcessing) {
      getMembership(vault.id);
    }
  }, [vault]);

  const highlightItem = () => {
    const initialBackground = "inherit";
    if (itemRef.current) {
      itemRef.current.style.transition = "background-color 1s ease-in";
      itemRef.current.style.background = theme.palette.background.active!;
    }
    setTimeout(() => {
      if (itemRef.current) itemRef.current.style.background = initialBackground;
    }, 1000);
  };

  const handleMenu = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // we have click event on the row, stop propagation for the button to fire independently
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  const handleSelectVault = (event: React.ChangeEvent<any>) => {
    event.stopPropagation();
    const { name, checked } = event.target;

    onSelectedVaults(name, checked);
    onLastSelectedVault(name);
  };

  const dataRoomStatus = getVaultStatus(location.pathname);

  const roomSizeToShow = bytesToGb(vault?.size);

  const hasTerms = vault?.termsOfAccess;
  const vaultId = vault?.id || membership?.id;

  return (
    <>
      <TableRow
        hover={!!vault}
        ref={itemRef}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          if (isMembershipInvited || isMembershipProcessing) {
            return;
          } else if (isMembershipPending) {
            history.push(`/invite/?membershipId=${membership?.id}`);
            const pending = pendingMemberships?.filter(member => member.id !== membership.id);
            onPendingMemberships(pending);
          } else {
            history.push(`/vaults/${dataRoomStatus}/${vaultId}/assets`);
          }
        }}
      >
        {membership || vault ? (
          <TableCell
            size="medium"
            sx={{
              // cursor: !isMembershipInvited && "pointer",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
            scope="row"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                overlap="rectangular"
                variant="dot"
                color="warning"
                invisible={
                  !notifications ||
                  notifications?.filter(log => log.status === Types.NotificationStatus.UNREAD && log.vaultId === vaultId).length === 0
                }
              >
                {isMembershipProcessing ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="24px"
                    height="24px"
                    className={classes.iconBackGround}
                  >
                    <CircularProgress
                      size="1rem"
                      color="secondary"
                      sx={{
                        color: "text.disabled"
                      }}
                    />
                  </Box>
                ) : isArchived && (iconHovered || vaultChecked) ? (
                  <Checkbox
                    onMouseLeave={toggleIcon}
                    onMouseEnter={toggleIcon}
                    onClick={handleSelectVault}
                    checked={vaultChecked}
                    name={vaultId}
                    className={classes.checkBox}
                    icon={<TickIcon fontSize="medium" classes={{ root: classes.iconBackGround }} />}
                    checkedIcon={<TickIcon fontSize="medium" classes={{ root: classes.iconBackGround }} />}
                  />
                ) : (
                  <IconButton onMouseEnter={toggleIcon}>
                    <VaultIcon
                      fontSize="medium"
                      sx={{
                        color: isArchived
                          ? "text.disabled"
                          : isMembershipPending || isMembershipInvited
                          ? "primary.iconInvert"
                          : isPublic
                          ? "info.main"
                          : "primary.main"
                      }}
                      classes={{ root: classes.iconBackGround }}
                    />
                  </IconButton>
                )}
              </Badge>
              {isMembershipInvited ? (
                <div style={{ display: "flex" }}>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {isMobile ? "Pending..." : "Pending access confirmation..."}
                    <Tooltip placement="right-start" title={<ToolTipText />}>
                      <span>
                        <InfoIcon fontSize="small" color="secondary" sx={{ marginLeft: "4px", marginTop: "-6px" }} />
                      </span>
                    </Tooltip>
                  </Typography>
                </div>
              ) : isMembershipProcessing ? (
                <div style={{ display: "grid" }}>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {vault?.name + " (Creating...)"}
                  </Typography>
                </div>
              ) : (
                <div style={{ display: "grid" }}>
                  <Typography variant="body2" color="text.primary" noWrap>
                    {vault?.name}
                  </Typography>
                </div>
              )}
            </div>
          </TableCell>
        ) : (
          <TableCell scope="row" size="medium">
            <Box display="flex">
              <Skeleton variant="rectangular" width={30} height={30} sx={{ marginRight: "15px" }} />
              <Skeleton sx={{ flex: "1" }} />
            </Box>
          </TableCell>
        )}
        {isMobile || width === "sm" ? (
          vault ? null : (
            <TableCell />
          )
        ) : vault ? (
          <>
            <TableCell align="left" size="medium">
              <Box>
                {isCloud ? (
                  <Tooltip title="Cloud" arrow>
                    <span>
                      <CloudIcon fontSize="small" />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Permanent" arrow>
                    <span>
                      <InfinityIcon fontSize="small" />
                    </span>
                  </Tooltip>
                )}
              </Box>
            </TableCell>
            <TableCell align="left" size="medium">
              <Box>
                {isPublic ? (
                  <Tooltip title="Public" arrow>
                    <span>
                      <GlobeIcon fontSize="small" />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Private" arrow>
                    <span>
                      <PadlockIcon fontSize="small" />
                    </span>
                  </Tooltip>
                )}
              </Box>
            </TableCell>
            <TableCell align="left" size="medium">
              <Typography variant="body2" color="text.primary" noWrap>
                {vault?.updatedAt ? formatDate(vault?.updatedAt) : "-"}
              </Typography>
            </TableCell>
            <TableCell align="left">{roomSizeToShow.size ? roomSizeToShow.size + roomSizeToShow.sizeType.toUpperCase() : "-"}</TableCell>
          </>
        ) : isMembershipInvited ? (
          <>
            <TableCell align="left" size="medium">
              <Typography variant="body2" color="text.secondary" noWrap>
                -
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" color="text.secondary" noWrap>
                -
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" color="text.secondary" noWrap>
                -
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" color="text.secondary" noWrap>
                -
              </Typography>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <Skeleton sx={{ height: "30px" }} />
            </TableCell>
            <TableCell>
              <Skeleton sx={{ height: "30px" }} />
            </TableCell>
            <TableCell>
              <Skeleton sx={{ height: "30px" }} />
            </TableCell>
            <TableCell>
              <Skeleton sx={{ height: "30px" }} />
            </TableCell>
          </>
        )}
        {vault || membership ? (
          isMembershipPending && hasTerms ? (
            <TableCell align="right" size="medium" sx={{ minWidth: "148px" }}>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => history.push(`/invite/?membershipId=${membership.id}`)}
                >
                  View terms
                </Button>
              </Box>
            </TableCell>
          ) : (
            <TableCell align="right" size="medium">
              <Box display="flex" justifyContent="end">
                <MoreActionButton
                  menuActive={menuActive}
                  disabled={isMembershipInvited || isMembershipProcessing}
                  handleClick={e => {
                    handleMenu(e);
                  }}
                  arialLabel="display more actions vault"
                />
              </Box>
            </TableCell>
          )
        ) : isMobile || width === "sm" ? null : (
          <TableCell sx={{ minWidth: "68px" }} />
        )}
      </TableRow>
      {isMobile ? (
        <RoomDrawer
          vault={vault}
          vaultId={vault?.id}
          currentMembership={currentMembership}
          members={members}
          openDrawer={openMenu}
          handleMenuClose={handleMenuClose}
        />
      ) : (
        <MenuRoom
          anchorEl={anchorEl}
          openMenu={openMenu}
          handleMenuClose={handleMenuClose}
          currentMembership={currentMembership}
          memberships={members}
          vault={vault}
        />
      )}
    </>
  );
};

export default withRouter(VaultItem);
