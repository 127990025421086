import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import Routes from "./components/route-wrappers/Routes";
import reportWebVitals from "./reportWebVitals";
import { Auth } from "@akord/akord-js";
import { AKORD_ENV, GQL_API_URL } from "./helpers/env";
import { KEEP_SIGNED_IN } from "./handlers/auth/constants";
import { GraphQLAPI } from "@aws-amplify/api-graphql";

GraphQLAPI.configure({
  aws_appsync_region: 'eu-central-1',
  aws_appsync_graphqlEndpoint: GQL_API_URL,
  aws_appsync_authenticationType: "AWS_LAMBDA"
});

let storage;
if (sessionStorage.getItem(KEEP_SIGNED_IN) === "false") {
  storage = sessionStorage;
} else {
  storage = localStorage;
}
Auth.configure({ env: AKORD_ENV, storage: storage });

ReactDOM.render(<App component={Routes} />, document.getElementById("main"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
