import React from "react";
interface LogoProps {
  className: string;
  width: string;
  height: string;
  onClick?: () => void;
}
const LogoSimple: React.FC<LogoProps> = props => {
  return (
    <svg viewBox="0 0 52 53" fill="none" {...props}>
      <rect y="0.685303" width="52" height="52" fill="black" />
      <clipPath id="clip0_43_228">
        <rect width="17.6514" height="31.9633" fill="white" transform="translate(19.0825 10.7036)" />
      </clipPath>
      <path d="M28.1835 13.0171H22.626L27.5505 28.6827H22.9406V33.3095H28.9229L31.1389 40.3535H36.7337L28.1835 13.0171Z" fill="white" />
      <path d="M21.0143 10.7036H19.0825V42.6669H21.0143V10.7036Z" fill="#F26D43" />
    </svg>
  );
};

export default LogoSimple;
