import { Vault } from "@akord/akord-js";

export const memberRoleEnum = {
  CONTRIBUTOR: "CONTRIBUTOR",
  OWNER: "OWNER",
  VIEWER: "VIEWER"
};

export const itemStatusEnum = {
  REVOKED: "REVOKED",
  DELETED: "DELETED",
  ARCHIVED: "ARCHIVED"
};

export const memberStatusEnum = {
  ACCEPTED: "ACCEPTED",
  INVITED: "INVITED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  REVOKED: "REVOKED",
  PROCESSING: "PROCESSING",
  ACTIVE: "ACTIVE"
};

export const notificationStatus = {
  DELETED: "DELETED",
  READ: "READ",
  UNREAD: "UNREAD"
};

export const storageTransactionStatus = {
  VERIFICATION: "VERIFICATION",
  SCHEDULED: "SCHEDULED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  BLOCKED: "BLOCKED",
  COMMITTED: "COMMITTED",
  BUNDLED: "BUNDLED"
};

export const filterActionRefs = [
  "DATAROOM_KEY_ROTATE",
  "MEMBERSHIP_KEY_ROTATE",
  "MEMBERSHIP_OWNER",
  "MEMBERSHIP_PROFILE_UPDATE",
  "REFERRAL_REWARD",
  "STACK_UPDATE_STORAGE_TRANSACTION_STATUS",
  "MEMO_ADD_REACTION",
  "MEMO_REMOVE_REACTION"
];

export const filterActions = [
  {
    actionRef: "MEMBERSHIP_ACCEPT",
    filter: (action: { vault: Vault }) => {
      return action.vault?.termsOfAccess;
    }
  }
];

// export const filterActionRefsForNotifications = [
//   'DATAROOM_KEY_ROTATE',
//   'MEMBERSHIP_KEY_ROTATE',
//   'MEMBERSHIP_OWNER',
//   'MEMBERSHIP_PROFILE_UPDATE',
//   'STACK_UPDATE_STORAGE_TRANSACTION_STATUS'
// ]

export const FILE_SIZE_LIMIT_UPLOAD = 30000000000;

export const SIZE_LIMIT_DOWNLOAD = 1000000000;
