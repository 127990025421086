import React from "react";
import { CheckboxEmptyIcon, CheckboxTickIcon, CloseInCircleIcon, TriangleDownIcon } from "@akord/addon-icons";
import { Autocomplete, Checkbox, TextField, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChipStyled } from "../TagTextInput/TagTextInput";

const useStyles = makeStyles<Theme>(theme => ({
  option: {
    "&[aria-selected=true]": {
      backgroundColor: "transparent!important"
    },
    fontSize: "0.9375rem",
    "& > span": {
      marginRight: "10px",
      fontSize: "18px"
    }
  },
  root: {
    "& .MuiOutlinedInput-root": {
      height: "100%",
      minHeight: "55px",
      padding: "6px"
    }
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: "7px 4px"
    }
  },
  paper: {
    background: theme.palette.background.card
  }
}));

export type AutocompleteOptionsProps = {
  value: string;
  text: string;
};

type AutocompleteWithTagsProps = {
  options: AutocompleteOptionsProps[];
  defaultValue?: AutocompleteOptionsProps[];
  onChange: (options: string[]) => void;
};

const AutocompleteWithTags: React.FC<AutocompleteWithTagsProps> = ({ options, onChange, defaultValue }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      classes={{
        option: classes.option,
        inputRoot: classes.inputRoot,
        paper: classes.paper,
        root: classes.root
      }}
      popupIcon={<TriangleDownIcon />}
      clearIcon={<CloseInCircleIcon color="disabled" />}
      id="assets-type"
      options={options}
      // defaultValue={defaultValue}
      disableCloseOnSelect
      onChange={(e, options) => {
        onChange(options.map(option => option.value));
      }}
      getOptionLabel={option => option.text}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => <ChipStyled {...getTagProps({ index })} label={option.text} key={index} />);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={<CheckboxEmptyIcon />} checkedIcon={<CheckboxTickIcon />} style={{ marginRight: 8 }} checked={selected} />
          {option.text}
        </li>
      )}
      renderInput={params => <TextField {...params} placeholder="Add types" />}
    />
  );
};

export default AutocompleteWithTags;
