import React from "react";
import Button from "@mui/material/Button";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";

type UploadButtonFolderProps = {
  fab?: boolean;
  disabled?: boolean;
  noMinWidth?: boolean;
  isMobile: boolean;
};

const UploadButtonFolder: React.FC<UploadButtonFolderProps> = ({
  children,
  fab = false,
  disabled = false,
  noMinWidth = false,
  isMobile
}) => {
  const { uploadEventRef, onUploadFiles } = useNotificationsContext();

  const handleUploadFolders = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const fileList = Array.from(e.target.files);
    onUploadFiles(fileList);
    uploadEventRef.current = e;
  };

  return (
    <>
      <input
        name="contained-button-folder"
        id="contained-button-folder"
        multiple
        //@ts-ignore
        webkitdirectory="true"
        type="file"
        onChange={handleUploadFolders}
        onClick={event => {
          (event.target as HTMLInputElement).value = ""; // TODO What does it mean?
        }}
        style={{ display: "none" }}
      />
      {!fab && (
        <label htmlFor={disabled ? "non-clickable" : "contained-button-folder"} style={{ width: isMobile ? "100%" : "auto" }}>
          <Button
            style={{ minWidth: noMinWidth ? "auto" : "228" }}
            disabled={disabled}
            variant="contained"
            color="primary"
            component="span"
            fullWidth={isMobile ? true : false}
            disableElevation
          >
            {children}
          </Button>
        </label>
      )}
    </>
  );
};

export default UploadButtonFolder;
