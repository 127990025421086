import React, { PropsWithChildren } from "react";
import { Select, SelectChangeEvent, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  selectMenu: {
    height: "1.4375em!important",
    minHeight: "1.4375em!important",
    fontSize: "14px",
    lineHeight: "1.4375em",
    minWidth: "78px",
    border: "none"
  },
  select: {
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  iconOpen: {
    color: `${theme.palette.primary.main}!important`
  },
  arrowIcon: {
    color: theme.palette.text.secondary
  },

  menuList: {
    backgroundColor: theme.palette.background.secondary
  },
  paper: {
    marginTop: theme.spacing(2)
  }
}));

type StyledSelectProps = {
  value: any;
  onChange: ((event: SelectChangeEvent<PropsWithChildren<StyledSelectProps>>, child: React.ReactNode) => void) | undefined;
  id: string;
  placeholder?: string;
  fieldName?: string;
};

const StyledSelect = (props: PropsWithChildren<StyledSelectProps>) => {
  const { children, value, onChange, id, placeholder, fieldName } = props;
  const classes = useStyles();
  return (
    <Select
      placeholder={placeholder}
      fullWidth
      id={id}
      name={fieldName}
      value={value}
      onChange={onChange}
      classes={{
        select: classes.selectMenu,
        outlined: classes.select,
        iconOpen: classes.iconOpen
      }}
      MenuProps={{
        elevation: 4,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        classes: {
          list: classes.menuList,
          paper: classes.paper
        }
      }}
      inputProps={{
        classes: {
          root: classes.selectRoot,
          icon: classes.arrowIcon
        }
      }}
    >
      {children}
    </Select>
  );
};

export default StyledSelect;
