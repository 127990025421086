import React, { useEffect, useState } from "react";
import { Box, Table, TableBody, TableContainer, Typography, SelectChangeEvent } from "@mui/material";
import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSearchContext } from "../../contexts/SearchContextProvider";
import { Pagination } from "../common";
import SearchResult from "./SearchResult";
import SearchResultsFilter from "./SearchResultsFilter";
import { NumberChangeEvent } from "../../contexts/AssetsContextProvider";

const resultsPerPage = 20;

const SearchResults: React.FC = () => {
  const location = useLocation();
  const [results, setResults] = useState<any>([]);
  const [paginatedResults, setPaginatedResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);

  const { isMobile } = useGlobalContext();
  const { searchResults, cachedSearchResults } = useSearchContext();

  const cacheResults = React.useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    const term = urlParams.get("term");
    if (term) {
      cachedSearchResults[term] = searchResults;
    }
  }, [cachedSearchResults, location.search, searchResults]);

  const getCachedResults = React.useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    const term = urlParams.get("term");
    if (term) {
      return cachedSearchResults[term] || [];
    }
    return [];
  }, [cachedSearchResults, location.search]);

  const getPaginatedResults = React.useCallback(() => {
    const startIndex = currentPage * resultsPerPage - resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    return results.slice(startIndex, endIndex);
  }, [currentPage, results]);

  useEffect(() => {
    if (Array.isArray(searchResults)) {
      setResults(cloneDeep(searchResults));
      cacheResults();
    } else {
      setResults(getCachedResults());
    }
  }, [searchResults, cacheResults, getCachedResults]);

  useEffect(() => {
    const pages = Math.ceil(results.length / resultsPerPage) || 1;
    setPages(pages);
    setPaginatedResults(getPaginatedResults());
  }, [results, currentPage, getPaginatedResults]);

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const changePage = (event: NumberChangeEvent) => {
    const selectValue = typeof event === "number" ? event : event.target.value;
    setCurrentPage(Number(selectValue));
  };

  return (
    <div
      style={{
        width: "100%",
        height: isMobile ? "calc(100% - 59px)" : "100%",
        overflowY: "auto",
        position: "absolute"
      }}
    >
      {paginatedResults && paginatedResults.length > 0 ? (
        <Box>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
              <SearchResultsFilter />
              <TableBody>
                {paginatedResults.map((result, i) => (
                  <SearchResult key={i} document={result} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" my={6}>
            <Pagination
              bottomPosition
              pages={pages}
              currentPage={currentPage}
              onGoToNextPage={goToNextPage}
              onGoToPreviousPage={goToPreviousPage}
              onChangePage={changePage}
            />
          </Box>
        </Box>
      ) : (
        <Box mx={5} mt={4} mb={!isMobile ? 1 : 3}>
          <Typography variant="body2" color="text.secondary">
            Sorry, there were no search results.
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default SearchResults;
