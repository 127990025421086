import React, { useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import { Box, Typography } from "@mui/material";
import { ButtonGroup, StandAloneScreenWrapper } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { Membership, Vault } from "@akord/akord-js";

const ConfirmAccess: React.FC<RouteComponentProps> = ({ location, history }) => {
  const { onSnackbarToShow } = useSnackbarContext();
  const { akord } = useGlobalContext();

  const [membership, setMemebership] = useState<Membership>();
  const [vault, setVault] = useState<Vault>();
  const [loading, setLoading] = useState(false);
  const [backLoading, setBackLoading] = useState(false);
  const [error, setError] = useState();

  const membershipId = queryString.parse(location.search).membershipId;

  useEffect(() => {
    if (!membershipId || typeof membershipId !== "string") history.push("/");

    const getMembership = async () => {
      try {
        const membership = await akord?.membership.get(membershipId as string);
        if (!membership) return;
        const vault = await akord?.vault.get(membership.vaultId);
        const roomUrl = `/vaults/active/${membership.vaultId}/assets`;
        //handle link differently depending on the status
        switch (membership.status) {
          case "INVITED":
            setMemebership(membership);
            setVault(vault);
            break;
          case "PENDING":
            onSnackbarToShow("dataRoomAccessPending", membership.email);
            history.push(roomUrl);
            break;
          case "REVOKED":
            onSnackbarToShow("dataRoomAccessRevoked", membership.email);
            history.push(roomUrl);
            break;
          case "ACCEPTED":
            onSnackbarToShow("dataRoomAccessAccepted", membership.email);
            history.push(roomUrl);
            break;
          //TODO Lost this Status
          // case "REJECTED":
          //   onSnackbarToShow("dataRoomAccessReject", membership.email);
          //   history.push(roomUrl);
          //   break;
          default:
            history.push(roomUrl);
        }
      } catch (err: any) {
        setError(err);
        console.log("Error fetching Membership: ", err);
      }
    };
    if (akord) getMembership();
  }, [akord, location.search]);

  const confirmAccess = async () => {
    try {
      if (!membership || !vault) return;
      setLoading(true);
      await akord?.membership.confirm(membership.id);
      setLoading(false);
      onSnackbarToShow("dataRoomAccessConfirm", membership.email);
      history.push(`/vaults/${vault.status}/${vault.id}/assets`);
    } catch (err) {
      console.log("Confirm access error: ", err);
      setLoading(false);
    }
  };

  const revokeAccess = async () => {
    try {
      if (!membership || !vault) return;
      setBackLoading(true);
      if (membership.status === "INVITED") {
        await akord?.membership.revokeInvite(membership.vaultId, membership.id);
      } else {
        await akord?.membership.revoke(membership.id);
      }
      setBackLoading(false);
      onSnackbarToShow("dataRoomAccessRevoked", membership.email);
      history.push(`/vaults/${vault.status}/${vault.id}/assets`);
    } catch (err: any) {
      setBackLoading(false);
      setError(err);
      console.log(err);
    }
  };

  if (!membership) return null;

  return (
    <StandAloneScreenWrapper title="Confirm access" route="/">
      <Box height="90%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box height="88%">
          <Box>
            <Typography variant="body2">
              {`You can now confirm access for ${membership.email} to your vault, as
               they have created their account.`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ButtonGroup
        errorText={error}
        nextDiasabled={loading}
        handleNext={() => confirmAccess()}
        handleBack={() => revokeAccess()}
        disableBackButton={backLoading}
        nextText="Confirm access"
        backText="Revoke"
        backButtonOutlined={true}
        hideBackButton={false}
        fullWidthNextButton={false}
        hideIcon={true}
        loading={loading}
        backLoading={backLoading}
        noMinWidth={true}
      />
    </StandAloneScreenWrapper>
  );
};

export default withRouter(ConfirmAccess);
