import { AvatarIcon } from "@akord/addon-icons";
import { SvgIconClasses, SxProps, Theme } from "@mui/material";
import React from "react";
import { isUrlCorrect } from "../../../helpers/api-helpers";

type AvatarProps = {
  url: string;
  imageClassName: string;
  avatarClasses?: Partial<SvgIconClasses>;
  avatarFontSize?: "small" | "inherit" | "large" | "medium";
  avatarSx?: SxProps<Theme>;
  imageStyle?: React.CSSProperties;
};

const Avatar: React.FC<AvatarProps> = ({ url, imageClassName, avatarClasses, avatarFontSize = "medium", avatarSx, imageStyle }) => {
  const [urlIsCorrect, setUrlIsCorrect] = React.useState(false);

  React.useEffect(() => {
    const checkUrl = async (url: string) => {
      try {
        const isCorrect = await isUrlCorrect(url);
        setUrlIsCorrect(isCorrect);
      } catch (e) {
        console.log("No avatar");
      }
    };

    checkUrl(url);
  }, [url]);

  return urlIsCorrect ? (
    <img src={url} className={imageClassName} alt="Avatar" style={imageStyle} />
  ) : (
    <AvatarIcon classes={avatarClasses} color="disabled" fontSize={avatarFontSize} sx={avatarSx} />
  );
};

export default Avatar;
