import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { InputBaseComponentProps } from "@mui/material/InputBase";

const StripeInput = forwardRef<any, InputBaseComponentProps>((props, ref) => {
  const { component: Component } = props;
  const elementRef = useRef<any>();
  useImperativeHandle(ref, () => ({
    focus: () => elementRef?.current?.focus
  }));

  return <Component onReady={(element: any) => (elementRef.current = element)} {...props} />;
});

export default StripeInput;
