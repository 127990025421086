import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, Box, Typography, Tooltip, Theme } from "@mui/material";
import { Skeleton } from "@mui/material";
import { ChevronLeftIcon, InactiveIcon, DoubleChevronLeftIcon, PadlockIcon, GlobeIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import Label from "../Label";
import { Folder, Vault } from "@akord/akord-js";

const useStyles = makeStyles<Theme>((theme) => ({
  roomName: {
    marginTop: "3px"
  },
  textLink: {
    borderBottom: "none",
    "&:hover": {
      color: theme.palette.text.primary
    }
  }
}));

type TopBarTitleMobileProps = {
  vault: Vault;
  currentFolder: Folder;
  parentFolders: Folder[];
  folderId: string | null;
  isPublicRoute: boolean;
};

const TopBarTitleMobile: React.FC<TopBarTitleMobileProps> = ({ vault, currentFolder, parentFolders, folderId, isPublicRoute }) => {
  const classes = useStyles();
  const history = useHistory();
  const urlPrefix = isPublicRoute ? "/public/vaults" : "/vaults";

  return (
    <Box display="flex" maxWidth="calc(100% - 56px)" minWidth="calc(100% - 130px)" alignItems="baseline">
      {!vault || Object.keys(vault).length === 0 ? (
        <Skeleton variant="text" width="90%" />
      ) : (
        <Box maxWidth="95%" minWidth="90%" display="flex">
          <IconButton
            disableRipple
            size="small"
            edge="end"
            aria-label="back"
            aria-controls="menu-appbar"
            onClick={() =>
              history.push(
                parentFolders.length
                  ? `${urlPrefix}/${vault.status.toLowerCase()}/${vault.id}/assets`
                  : `/vaults/${vault.status.toLowerCase()}`
              )
            }
          >
            {parentFolders.length ? <DoubleChevronLeftIcon /> : !isPublicRoute && <ChevronLeftIcon />}
          </IconButton>
          {vault.status === "ARCHIVED" && <InactiveIcon color="disabled" sx={{ marginRight: 2 }} />}
          <Box className={classes.roomName} display="flex" minWidth="40%" maxWidth="95%">
            {!parentFolders.length ? (
              folderId ? (
                <Box maxWidth="50%" display="flex">
                  <Typography
                    variant="h2"
                    noWrap
                    className={`${classes.textLink} ${"regular"}`}
                    color="text.secondary"
                    component="a"
                    onClick={() => history.push(`${urlPrefix}/${vault.status.toLowerCase()}/${vault.id}/assets`)}
                  >
                    {vault.name}
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" width="100%">
                  <Typography variant="h2" noWrap color="text.primary">
                    {vault.name}
                  </Typography>
                  {vault?.cloud && <Label secondary text="Cloud" />}
                  {vault?.public ? (
                    <Tooltip title="Public vault" arrow>
                      <span>
                        <GlobeIcon fontSize="small" color="disabled" sx={{ marginLeft: "8px", marginTop: "1px" }} />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Private vault" arrow>
                      <span>
                        <PadlockIcon fontSize="small" color="disabled" sx={{ marginLeft: "8px", marginTop: "1px" }} />
                      </span>
                    </Tooltip>
                  )}
                </Box>
              )
            ) : null}
            {parentFolders.map((parentFolder, index) =>
              index === parentFolders.length - 1 ? (
                <Typography
                  key={index}
                  variant="h2"
                  noWrap
                  className={`${classes.textLink} ${"regular"}`}
                  color="text.secondary"
                  component="a"
                  onClick={() => history.push(`${urlPrefix}/${vault.status.toLowerCase()}/${vault.id}/assets/folders/${parentFolder.id}`)}
                >
                  ...
                </Typography>
              ) : null
            )}
            {folderId && (
              <>
                <Typography variant="h2" className="regular" color="text.secondary" sx={{ whiteSpace: "pre" }}>
                  {" / "}
                </Typography>
                <Typography variant="h2" noWrap color="text.primary">
                  {currentFolder?.name}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TopBarTitleMobile;
