import { MenuItem, MenuItemProps } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/system";

const useStyles = makeStyles<Theme>(theme => ({
  menuItem: {
    "&:last-of-type": {
      paddingBottom: 20
    },
    "&:first-of-type": {
      paddingTop: 20
    },
    fontSize: "0.9375rem",
    alignItems: "start",
    paddingTop: theme.spacing(5),
    paddingBottom: 0,
    paddingRight: 16,
    backgroundColor: "inherit",
    "&:focus": {
      background: "transparent"
    }
  },
  selected: {
    background: "transparent!important"
  }
}));

type StyledMenuItemProps = {
  text: string;
};

const StyledMenuItem: React.FC<StyledMenuItemProps & MenuItemProps> = ({ text, ...rest }) => {
  const classes = useStyles();

  return (
    <MenuItem classes={{ selected: classes.selected }} className={classes.menuItem} {...rest}>
      {text}
    </MenuItem>
  );
};

export default StyledMenuItem;
