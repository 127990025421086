export interface OnboardingStep {
  title: string;
  element: string;
  intro: string;
  position: string;
  hidePrev?: boolean;
}

export const encryptedSteps: OnboardingStep[] = [
  {
    title: "1/8",
    element: ".step1",
    intro: "An easy view to manage all your vault’s digital assets. ",
    position: "bottom-middle-aligned"
  },
  {
    title: "2/8",
    element: ".step2",
    intro: "Switch to this view for private end-to-end encrypted chat.",
    position: "bottom-middle-aligned"
  },
  {
    title: "3/8",
    element: ".step3",
    intro: "You can add a file or note to your vault here.",
    position: "right"
  },
  {
    title: "4/8",
    element: ".step4",
    intro: "You can filter your view with multiple settings.",
    position: "right"
  },
  {
    title: "5/8",
    element: ".step5",
    intro: "Invite someone from this menu,  and get 100 MB of permanent storage when they join.",
    position: "right",
    hidePrev: true
  },
  {
    title: "6/8",
    element: ".step6",
    intro: "Check how much storage you’ve used and top up here.",
    position: "right"
  },
  {
    title: "7/8",
    element: ".step7",
    intro: "Check your $U token balance and send funds.",
    position: "right"
  },
  {
    title: "7/8",
    element: ".step8",
    intro: "Been a while since you last logged in? See what’s new here.",
    position: "right"
  }
];

export const publicSteps: OnboardingStep[] = [
  {
    title: "1/8",
    element: ".step1",
    intro: "An easy view to manage all your vault’s digital assets.",
    position: "bottom-middle-aligned"
  },
  {
    title: "2/8",
    element: ".step2",
    intro: "Mint Atomic NFTs - asset, metadata & contract in 1 TX.",
    position: "right"
  },
  {
    title: "3/8",
    element: ".step3",
    intro: "You can add a file or note to your vault here.",
    position: "right"
  },
  {
    title: "4/8",
    element: ".step4",
    intro: "You can filter your view with multiple settings.",
    position: "right"
  },
  {
    title: "5/8",
    element: ".step5",
    intro: "Invite someone from this menu,  and get 100 MB of permanent storage when they join.",
    position: "right",
    hidePrev: true
  },
  {
    title: "6/8",
    element: ".step6",
    intro: "Check how much storage you’ve used and top up here.",
    position: "right"
  },
  {
    title: "7/8",
    element: ".step7",
    intro: "Check your $U token balance and send funds.",
    position: "right"
  },
  {
    title: "8/8",
    element: ".step8",
    intro: "Been a while since you last logged in? See what’s new here.",
    position: "right"
  }
];

export const publicCloudSteps: OnboardingStep[] = [
  {
    title: "1/7",
    element: ".step1",
    intro: "An easy view to manage all your vault’s digital assets.",
    position: "bottom-middle-aligned"
  },
  {
    title: "2/7",
    element: ".step3",
    intro: "You can add a file or note to your vault here.",
    position: "right"
  },
  {
    title: "3/7",
    element: ".step4",
    intro: "You can filter your view with multiple settings.",
    position: "right"
  },
  {
    title: "4/7",
    element: ".step5",
    intro: "Invite someone from this menu,  and get 100 MB of permanent storage when they join.",
    position: "right",
    hidePrev: true
  },
  {
    title: "5/7",
    element: ".step6",
    intro: "Check how much storage you’ve used and top up here.",
    position: "right"
  },
  {
    title: "6/7",
    element: ".step7",
    intro: "Check your $U token balance and send funds.",
    position: "right"
  },
  {
    title: "7/7",
    element: ".step8",
    intro: "Been a while since you last logged in? See what’s new here.",
    position: "right"
  }
];
