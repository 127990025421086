import React from "react";
import { commands } from "@uiw/react-md-editor";
import {
  BoldIcon,
  BulletListIcon,
  CodeIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  LinkIcon,
  NumberedListIcon,
  StrikethroughIcon
} from "@akord/addon-icons";
import { Typography } from "@mui/material";

export const bold = {
  ...commands.bold,
  icon: <BoldIcon fontSize="small" />
};

export const codeBlock = {
  ...commands.codeBlock,
  icon: <CodeIcon fontSize="small" />
};

export const strikethrough = {
  ...commands.strikethrough,
  icon: <StrikethroughIcon fontSize="small" />
};

export const title1 = {
  ...commands.title1,
  icon: <Heading1Icon fontSize="small" />
};

export const title2 = {
  ...commands.title2,
  icon: <Heading2Icon fontSize="small" />
};

export const title3 = {
  ...commands.title3,
  icon: <Heading3Icon fontSize="small" />
};

export const unorderedListCommand = {
  ...commands.unorderedListCommand,
  icon: <BulletListIcon fontSize="small" />
};

export const orderedListCommand = {
  ...commands.orderedListCommand,
  icon: <NumberedListIcon fontSize="small" />
};

export const link = {
  ...commands.link,
  icon: <LinkIcon fontSize="small" />
};

export const preview = {
  ...commands.codePreview,
  icon: (
    <div className="md-tab">
      <Typography variant="h3" color="inherit">
        Preview
      </Typography>
    </div>
  )
};

export const edit = {
  ...commands.codeEdit,
  icon: (
    <div className="md-tab">
      <Typography variant="h3" color="inherit">
        Write
      </Typography>
    </div>
  )
};
