import { Box, Button, CircularProgress, IconButton, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import SearchTrigger from "../../search/SearchTrigger";
import LogoIcon from "../logo/logoOrange";
import { AvatarIcon } from "@akord/addon-icons";
import DropdownMenu from "./DropdownMenu";
import { grey } from "../../../theme/colors";
import { useOrgContext } from "../../../contexts/OrgContextProvider";
import Base64Logo from "../logo/Base64Logo";
import { useRootContext } from "../../../contexts/RootContext";
import { AVATAR_URL } from "../../../helpers/env";
import { Types } from "@akord/gql";
import Avatar from "../Avatar/Avatar";

type TopBarMainStyleProps = {
  darkMode: boolean;
};

const useStyles = makeStyles<Theme, TopBarMainStyleProps>({
  iconBig: {
    fontSize: "32px"
  },
  iconHover: {
    "&:hover": {
      color: "inherit"
    },
    padding: "0"
  },
  avatar: {
    width: "32px",
    height: "32px",
    borderRadius: "50%"
  },
  logo: {
    fill: "currentColor",
    color: ({ darkMode }) => (darkMode ? "white" : grey[800]),
    cursor: "pointer",
    maxHeight: 65,
    maxWidth: 150,
    width: "auto"
  },
  spinner: {
    position: "inherit"
  }
});

type TopBarMainProps = {
  profileDetails: Types.User | undefined;
  darkMode: boolean;
  isMobile: boolean;
  isPublicRoute: boolean;
};

const TopBarMain: React.FC<TopBarMainProps> = ({ profileDetails, darkMode, isMobile, isPublicRoute }) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);

  const { subdomain } = useRootContext();
  const { org } = useOrgContext();
  const classes = useStyles({ darkMode: darkMode });
  const openMenu = Boolean(anchorEl);
  const history = useHistory();

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      px={5}
      pt={isMobile ? 3 : 4}
      mb={isMobile ? 0 : 7}
      display="flex"
      justifyContent="space-between"
      alignItems="self-start"
      sx={{ position: isMobile ? "fixed" : "initial" }} // on mobile we need the top menu stay fixed
      width="100%"
      zIndex="1"
    >
      {org?.subdomain === subdomain ? (
        <Base64Logo base64={org.logoBase64!} className={classes.logo} onClick={() => history.push("/vaults/active")} />
      ) : (
        <LogoIcon width="74" height="28" className={classes.logo} onClick={() => history.push("/vaults/active")} />
      )}
      {isPublicRoute ? (
        <Button
          variant="contained"
          size="xs"
          color="primary"
          onClick={() => window.open("https://v2.akord.com/signup")}
          sx={{ minWidth: "auto" }}
        >
          Sign up
        </Button>
      ) : (
        <Box display="flex" alignItems="center">
          {isMobile && (
            <Box marginRight="15px">
              <SearchTrigger />
            </Box>
          )}
          <Tooltip title="Profile" arrow>
            <span>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                className={classes.iconHover}
                size="large"
                disabled={!profileDetails || isPublicRoute}
              >
                {!profileDetails && !isPublicRoute ? (
                  <CircularProgress size={32} className={classes.spinner} />
                ) : profileDetails?.address && !isPublicRoute ? (
                  <Avatar
                    url={`${AVATAR_URL}/${profileDetails.address}`}
                    imageClassName={classes.avatar}
                    avatarClasses={{ root: classes.iconBig }}
                  />
                ) : (
                  <AvatarIcon classes={{ root: classes.iconBig }} color="disabled" />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <DropdownMenu type="avatarMenu" anchorEl={anchorEl} onClose={handleClose} openMenu={openMenu} position="left" />
        </Box>
      )}
    </Box>
  );
};

export default TopBarMain;
